import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { db } from '../../../../firebase';
import { doc,  writeBatch,  query,  updateDoc, collection,  onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../../context/AuthContext';
import { useGlobal } from '../../../../context/GlobalContext';
export const DriverPopUpContext = createContext();

export const DriverPopUpContextProvider = ({ children }) => {

    const { gearedUser} = UserAuth();
	const { driver} = useGlobal();
	const [driverNotes, setDriverNotes] = useState([]);
	const driverNotesRef = useRef([]);
	const driverNotesListener = useRef(null);
	useEffect(()=>{
		if(driver?.ID){
			queryNotes();
		}
	},[driver?.ID]);

	const queryNotes = () =>{
        driverNotesRef.current = [];
		console.log('querying notes for driver = ' + driver?.Name)
    
        if ( driverNotesListener.current)  driverNotesListener.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Notes`;
            const q = query(collection(db, queryName), where("ParentID", "==",  driver.ID));
			driverNotesListener.current=   onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempNote= change.doc.data();
				console.log('tempNote FOUND= ', tempNote)
                tempNote.ID = change.doc.id;
                if (change.type === "added")   driverNotesRef.current.push(tempNote);
                
                if (change.type === "modified") {
                    const noteIndex =driverNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    driverNotesRef.current[noteIndex] = tempNote;
                }
                if (change.type === "removed") {
                    const noteIndex =driverNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    driverNotesRef.current.splice(noteIndex,1)
                }
                
            })
			console.log('driver notes  for driver = ' + driver?.Name + ' = 	',driverNotesRef.current)

			setDriverNotes( [...driverNotesRef.current]);
        })
    }

	return <DriverPopUpContext.Provider value={{
		driverNotes
	}}>
		{children}
	</DriverPopUpContext.Provider>;
};

export const useDriverPopUp = () => {
	return useContext(DriverPopUpContext);
};

