import React, { useState, useEffect } from 'react';
import InputTextParent from '../../InputComponents/InputTextParent'; 
import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber'; 
import DriverHoursExpansion from './DriverHoursExpansion';
import { Button } from 'primereact/button';

const DriverListItem = ({driver, onUpdateDriver, weekFreightBills ,reportWeek}) => {
    const [inputValues, setInputValues] = useState({});
    const [expanded, setExpanded] = useState(false);

	
    useEffect(() => {
        if (driver) {
            setInputValues(driver);
        }
    }, [driver]);

    const handleFieldChange = (fieldName, value) => {
        console.log('tryina set fieldname = ' + fieldName + ' equal to value = ', value)
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName] = value;
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        onUpdateDriver(tempInputValues);
    };

    const handleTotalDedChange = (fieldName, value) => {
        let tempInputValues = {...inputValues}
        tempInputValues[fieldName] = value;

        tempInputValues.NetWages = tempInputValues.allProjectsTotal - tempInputValues.TotalDed;     
        setInputValues((prev) => ({ ...prev, [fieldName]: value, NetWages: tempInputValues.NetWages }));
        onUpdateDriver(tempInputValues);
    }

    const calcTotal = (fieldName, value) => {
        let tempInputValues = {...inputValues}
        tempInputValues[fieldName] = value;
        console.log('value = ', value);
        tempInputValues.TotalDed = tempInputValues.FedTax + tempInputValues.FICA + (tempInputValues.SDI ? tempInputValues.SDI : 0) + tempInputValues.LocalTax + tempInputValues.OtherTax + tempInputValues.OtherDed;
        tempInputValues.NetWages = tempInputValues.allProjectsTotal - tempInputValues.TotalDed;

        setInputValues((prev) => ({ ...prev, [fieldName]: value, NetWages: tempInputValues.NetWages }));
        onUpdateDriver(tempInputValues);
    }

    return (
        <React.Fragment> 
            {inputValues && (
                <>
                    <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1em' }}>
                        <td style={{ width: '3%', padding: '0', textAlign: 'left' }}>
                            <Button 
								style={{ padding:'0', marginLeft:'0'}}
                                icon={expanded ? "pi pi-chevron-down" : "pi pi-chevron-right"}
                                onClick={() => setExpanded(!expanded)}
                                text
                                size="small"
                            />
                        </td>
                        <td data-label="Name" style={{ width: '10%', padding: '0' }}>
                            <InputTextParent value={inputValues.Name} style={{width:"100%"}} onChange={(e) => handleFieldChange('Name', e.target.value)} disabled={true}/>
                        </td>
                        <td data-label="FedTax" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.FedTax}   onChange={(e) => calcTotal('FedTax', e.value)}  />
                        </td>
                        <td data-label="FICA" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.FICA}   onChange={(e) => calcTotal('FICA', e.value)}  />
                        </td>
                        <td data-label="SDI" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0.00" value={inputValues.SDI}   onChange={(e) => calcTotal('SDI', e.value)}  />
                        </td>
                        <td data-label="LocalTax" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.LocalTax}   onChange={(e) => calcTotal('LocalTax', e.value)}  />
                        </td>
                        <td data-label="OtherTax" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.OtherTax}   onChange={(e) => calcTotal('OtherTax', e.value)}  />
                        </td>
                        <td data-label="OtherDed" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.OtherDed}   onChange={(e) => calcTotal('OtherDed', e.value)}  />
                        </td>
                        <td data-label="TotalDed" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.TotalDed}  disabled={true} onChange={(e) => handleTotalDedChange('TotalDed', e.value)}  />
                        </td>
                        <td data-label="projectPaid" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.projectPaid}   onChange={(e) => calcTotal('projectPaid', e.value)}  />
                        </td>
                        <td data-label="allProjectsTotal" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.allProjectsTotal}   onChange={(e) => calcTotal('allProjectsTotal', e.value)}  />
                        </td>
                        <td data-label="NetWages" style={{ width: '7%', padding: '0' }}>
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="0" value={inputValues.NetWages}   onChange={(e) => handleFieldChange('NetWages', e.value)}  />
                        </td>
                        <td data-label="CheckNo" style={{ width: '8%', padding: '0' }}>
                            <InputTextParent value={inputValues.CheckNo} style={{width:"100%"}} onChange={(e) => handleFieldChange('CheckNo', e.target.value)}/>
                        </td>
                        <td data-label="Notes" style={{ width: '9%', padding: '0' }}>
                            <InputTextParent value={inputValues.Exception} style={{width:"100%"}} onChange={(e) => handleFieldChange('Exception', e.target.value)}/>
                        </td>
                    </tr>
                    {expanded && (
                        <tr>
                            <td colSpan="14">
                                <DriverHoursExpansion 
                                    driver={driver} 
                                    weekFreightBills={weekFreightBills}
									reportWeek={reportWeek}
                                />
                            </td>
                        </tr>
                    )}
                </>
            )} 
        </React.Fragment> 
    );
};

export default DriverListItem;