import React, {useEffect, useState, useRef, useCallback} from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import mobiscroll from '@mobiscroll/react4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { doc, deleteDoc, query, where, onSnapshot, collection} from 'firebase/firestore';
import { db } from '../../../firebase';
import { UserAuth } from '../../../context/AuthContext';
import FreightSummaryLine from './FreightSummaryLine';
import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber'; 


const DispatchCard = ({ dispatch, homeFreightBills, onUpdateFreightBills, showDrivers, showAssign, onClick,
    releaseDispatch, assignTrucks ,driver, onFreightClick, deleteFreightBillsByTruck}) => {
    dispatch = dispatch.item;
    const { gearedUser,truckTypes, updateDocument, addAuditLog} = UserAuth();
    const [assign, setAssign] = useState(1);
    const [selectedTruckTypeID, setSelectedTruckTypeID] = useState(driver.TruckTypes?.[0]?.ID || '');
    const [selectedTruckType, setSelectedTruckType] = useState(driver.TruckTypes?.[0] || {})
 
    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch.ID);
    let freightBills = dispatchFreightBills.sort((a, b) => a.loadOrder - b.loadOrder);
    let driverFreights = dispatchFreightBills.filter(freightBill => freightBill.Driver === driver.Driver?.ID && freightBill.TruckType.ID===selectedTruckTypeID);
    dispatch.TrucksAssigned = 0;
    dispatch.UnreadFreights =0;
  
    for (const fb of  freightBills)  if(!fb.Received) dispatch.UnreadFreights++;
    freightBills.sort((a, b) => a.loadOrder - b.loadOrder);
    const expenseListenerRef = useRef(null);
    const expensesRef = useRef(null);
    dispatch.TrucksAssigned =freightBills.length;
    dispatch.TrucksOrdered = dispatch.TrucksOrdered || 0;
    dispatch.AccountName = dispatch.Account.Name || 'No Account';
    dispatch.LoadSiteName = dispatch.LoadSite.Name || 'No Load Site';
    dispatch.DumpSiteName = dispatch.DumpSite.Name || 'No Dump Site';

    const borderColor = dispatch.Released ? "3px solid rgb(67, 160, 71)" : "3px solid rgb(239, 108, 0)";
    let assignedColor = '';
    let unreadColor = "#43a047"
    if (dispatch.TrucksOrdered===0 || dispatch.TrucksAssigned=== dispatch.TrucksOrdered)  assignedColor = "#43a047"; else assignedColor = "#ef6c00";
    if(dispatch.UnreadFreights!==0)unreadColor = "red";

    useEffect(() => { 
        if(driver.Trucks?.[0]) {
            setSelectedTruckTypeID(driver.Trucks[0].TruckType?.ID || '')
            setSelectedTruckType(driver.Trucks[0].TruckType || '')
        }
       if(!showDrivers) queryExpenses(dispatch.ID)
    }, []);

        
    const onDragEnd = (result) => {
        if (!result.destination) return;  
        const reorderedItems = Array.from(freightBills);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        onUpdateFreightBills(reorderedItems);
        freightBills=reorderedItems;   
    };
    const assignBulkTrucks = () =>{
        console.log('selectedTruckType = ', selectedTruckType)
        assignTrucks(freightBills, dispatch, assign, selectedTruckType, expensesRef.current)
    }
    const unassignBulkTrucks = () =>{
        if(assign<=driverFreights.length){
             console.log('selectedTruckType = ', selectedTruckType)
             deleteFreightBillsByTruck(dispatch, driver.Driver.ID, assign, selectedTruckTypeID);
            // setVisible(false);
         }else alert('You do not have that many trucks with that truck type on this Dispatch');
    
     }
    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, `Organizations/${gearedUser.selectedOrgName}/FreightBills`, id));
            const updatedFreightBills =freightBills.filter(fb => fb.ID !== id);
            let tempFreightbill =freightBills.filter(fb => fb.ID === id);
            tempFreightbill = {...tempFreightbill[0]};
            let unbilledCount=0;
            let unbilledMaterialCount =0;
            let billable = false;
          
            for(let f=0; f<updatedFreightBills.length; f++){
                if(!updatedFreightBills[f].billed)unbilledCount++
                if(!updatedFreightBills[f].materialBilled && updatedFreightBills[f].MaterialCompany.ID!==dispatch.Company.ID)unbilledMaterialCount++;
            } 
            if((unbilledCount+unbilledMaterialCount)>0)billable=true;
            if(!expensesRef.current) await queryExpenses(dispatch.ID)
     
        
            console.log('unbilledMaterialCount= '+ unbilledMaterialCount)
          
            addAuditLog('DeleteFreightBillFromDispatchHome', 'FreightBills', id, tempFreightbill);
            updateDocument({TrucksAssigned:updatedFreightBills.length, unBilledMaterials: unbilledMaterialCount, unBilledFreights: unbilledCount, Billable:billable}, dispatch.ID,'Dispatches')
    
            onUpdateFreightBills(updatedFreightBills);
        } catch (error) {  console.error("Error removing document: ", error);  }
    };

    const handleTruckTypeChange = (value)=>{
        console.log('value of truck tyhep = ', value)
        setSelectedTruckTypeID(value);
        for (let i = 0; i < truckTypes.length; i++)
            if (truckTypes[i].ID === value) setSelectedTruckType(truckTypes[i])
                
    }

    const queryExpenses = useCallback((dispID) => {
        return new Promise((resolve, reject) => {
            expensesRef.current = [];
            if (expenseListenerRef.current)expenseListenerRef.current();
            console.log('I AM OPENING A LISTENER TO THISE EXPENSES ON DISPATCH CARD!!')
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
            const q = query(collection(db, queryName), where("dispatchID", "==", dispID));
            expenseListenerRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    let tempExpense = change.doc.data(); 
                    tempExpense.ID = change.doc.id;
                    if (change.type === "added") expensesRef.current.push(tempExpense);
                    
                    if (change.type === "modified") { 
                        const expenseIndex = expensesRef.current.findIndex(f => f.ID === tempExpense.ID);
                        console.log('got a MODIFIED EPXENSE for dispatch card = ', tempExpense)
                        expensesRef.current[expenseIndex] = tempExpense;
                    }
                    if (change.type === "removed") {
                        console.log('i am removing the expense  for dispatch card ', tempExpense)
                        const expenseIndex = expensesRef.current.findIndex(f => f.ID === tempExpense.ID);
                        expensesRef.current.splice(expenseIndex, 1);
                    }
                });
        
            console.log('SETTING  in dispatch card ',expensesRef.current)
            
           
            resolve();
            });
        });
    }, [gearedUser]);
    const lastIndex = freightBills.length-1;
    const columnClass =  'mbsc-col-md-6 mbsc-col-12';
    const parentStyle = showDrivers ? { padding: "0", paddingRight: ".5em", paddingBottom: ".5em", margin: "0", border: "4px solid #bcbcd1" } : { padding: "0", paddingRight: "0", paddingBottom: ".5em", margin: "0", border: "4px solid #bcbcd1" };
    const fromGroupStyle = showDrivers ? { marginLeft: ".5em", marginRight: ".5em" } : { marginLeft: ".3em", marginRight: "0" }
    return (
        <div className={columnClass} style={parentStyle} >
            <div className="mbsc-form-group-content mbsc-row" style={fromGroupStyle}>
                <Card style={{ width: "100%", paddingTop: "0px !important", borderRadius: "2em", border: borderColor }}>
                    <div className="mbsc-grid" style={{ padding: "0px", cursor: "pointer" }}>
                        <div className="mbsc-row" style={{width:"103%"}} onClick={() => onClick(dispatch, freightBills,  expensesRef.current)}>
                            <div  className="mbsc-row " style={{ padding: "0px", paddingLeft: ".5em" }}>
                                <div className="mbsc-col" style={{ fontWeight: "bold" }} >{dispatch.AccountName}</div>
                                    <div className="mbsc-col" style={{ padding: "0", fontWeight: "bold" }}>#{dispatch.JobNumber}</div>
                                    <div className="mbsc-col" style={{ padding: "0" }}>
                                        {dispatch.Released ? ( <button className="md-btn" onClick={(e)=>{e.stopPropagation();releaseDispatch(dispatch,freightBills);}} style={{ backgroundColor: "#43a047", cursor: "pointer"  }}>Released</button>
                                        ):( <button className="md-btn md-no-highlight" onClick={(e)=> {e.stopPropagation();releaseDispatch(dispatch,freightBills);}} style={{ backgroundColor: "rgb(239, 108, 0)", cursor: "pointer"  }}>
                                            {dispatch.OnHold ? (<span>On Hold</span>):(<span>Unreleased</span>)}</button>
                                        )}
                                    </div>
                                </div> 
                                <div className="mbsc-row">
                                    <div className="mbsc-col" style={{ paddingTop:".3em", paddingLeft: "1em"}} >{dispatch.LoadSiteName}</div>
                                    <div className="mbsc-col" style={{ paddingTop:".3em", paddingLeft: "1em"}} >{dispatch.DumpSiteName}</div>
                                </div> 
                                {showAssign && (<div>
                                    <div className="mbsc-row">    
                                        <div className="p-inputgroup mbsc-col-lg-10" style={{padding:"0", paddingRight:".8em", height:"2.5em"}}>
                                            <span className="p-inputgroup-addon " style={{flexBasis:"32%"}} > Assign: </span>
                                            <AutoSelectInputNumber  className='mbsc-col-lg-5' style={{padding:"0"}} minFractionDigits={0} maxFractionDigits={0}  value={assign}  onChange={(e) => setAssign(e.value)}  />
                                            <Button className=" mbsc-col-lg-3 " style={{margin:".25em" }}  onClick={(e)=>{assignBulkTrucks()}}>Assign</Button> 
                                            <Button className= "mbsc-col-lg-3" color="primary" style={{margin:".25em" }} onClick={(e)=>{unassignBulkTrucks()}}>Unassign</Button> 
                                        </div>
                                    </div>
                                    <div className="mbsc-row">  
                                        <div className="p-inputgroup mbsc-col-lg-10" style={{padding:"0", paddingRight:".8em", height:"2.5em"}}>
                                            <span className="p-inputgroup-addon" style={{flexBasis:"32%"}} >Truck Type:</span>
                                            <Dropdown  value={selectedTruckTypeID} onChange={(e) => handleTruckTypeChange(e.value)} options={truckTypes} optionLabel="Name"
                                                placeholder="Truck Type" className="w-full md:w-20rem" />
                                        </div>
                                    </div>
                                </div>)}
                            
                        </div>
                        <mobiscroll.CardHeader style={{ borderTop: "1px solid #cccccc", borderBottom: "1px solid #cccccc", cursor: "pointer", fontSize: "1em !important", marginTop: "5px", marginBottom: "5px", padding: "6px", color: "#1976d2" }}>
                            <div className="mbsc-row mbsc-justify-content-between" style={{  paddingLeft: "12px", paddingRight: "16px" }}>
                                <span style={{ fontWeight: "700" }}> {showDrivers ? (<span>Assigned</span>):(<div></div>)} Drivers</span>
                                {dispatch.TrucksOrdered > 0 && dispatch.TrucksAssigned !== dispatch.TrucksOrdered &&  ( 
                                    <button className=" md-btn md-no-highlight" disabled={true} style={{ backgroundColor: assignedColor, margin: "0 !important", cursor: "default" }}>Ordered: {dispatch.TrucksOrdered}</button>
                                )}
                                <div className='mbsc-justify-content-end mytooltip' tooltip="Assigned">
                                    <FontAwesomeIcon  icon={faUsers} style={{ color: assignedColor, paddingRight:".1em"}}/>
                                    <div style={{ color:assignedColor, fontSize: "1em", display:"inline"}}>{dispatch.TrucksAssigned}</div>
                                </div>  
                                <div className="mytooltip" tooltip="Unread" style={{textAlign:'right'}}>
                                    <div className="mbsc-ic mbsc-ic-eye-blocked" style={{ color: unreadColor, fontSize: "1em" }}>{dispatch.UnreadFreights}</div>
                                </div>
                            </div>
                        </mobiscroll.CardHeader>
                        {showDrivers && (
                        <div className="mbsc-row" style={{ width: "100%", marginLeft: ".1em"}}>
                            <DragDropContext onDragEnd={onDragEnd} > 
                                <Droppable droppableId="freightBills">
                                    {(provided) => (
                                        <ul className='tableList' style={{width:"100%"}} ref={provided.innerRef} {...provided.droppableProps}>
                                            {freightBills.map((item, index) => (
                                                <Draggable key={item.ID} draggableId={item.ID} index={index} isDragDisabled={dispatch.Released} >
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <FreightSummaryLine dispatch={dispatch} freight={{ item }} lastIndex={{lastIndex}} index={{ index }} onDelete={handleDelete} onClick={onFreightClick} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>)}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default DispatchCard;