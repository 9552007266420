
import React, {useEffect, useState} from 'react';
import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';

import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 

import { useGlobal } from '../../context/GlobalContext'
import { UserAuth } from '../../context/AuthContext'

const TabletDriverPopUp = (props) => {
    const { tabletDriverVisible, setTabletDriverVisible, tabletDriver} = useGlobal();
    const { updateDocument, drivers} = UserAuth();
    const [inputValues, setInputValues] = useState({});
    

    useEffect(()=>{
        setInputValues({...tabletDriver})
     },[tabletDriver]);

    const closeTabletDriverPopUp = () => {
        setTabletDriverVisible(false);
    };
  

console.log('device = ', inputValues)

  
    const handleSaveTabletDriver = async()=>{
        console.log('inputValues.Driver.ID =' , inputValues);
       updateDocument({deviceID:inputValues.ID}, inputValues.Driver.ID , 'Drivers');
       setTabletDriverVisible(false);
    
    }
    const changeDriver =(fieldName, value)=>{
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
    }
    const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => setTabletDriverVisible(false)} />
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Save" icon="pi pi-check" onClick={() => handleSaveTabletDriver()}  />
     
        </div>
    
    );
return(
    <Dialog header="Tablet" visible={tabletDriverVisible} style={{ width: '35vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeTabletDriverPopUp}>
        < div className="mbsc-grid" >
        <AutoCompleteInput label="Driver" fieldName="Driver" field="Name" value={inputValues.Driver} labelClass='p-inputgroup-addon comp-auto-input'  suggestions={drivers} setValue={setInputValues} handleFieldChange={changeDriver} />
        </div>       
    </Dialog>
);
};

export default TabletDriverPopUp;