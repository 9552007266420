import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useStripe } from '../../context/StripeContext';

const SubscriptionPage = () => {
    const { handleSubscription, loading, error } = useStripe();

    const plans = [
        {
            name: 'Process Payment',
            price: '$49',
            period: 'per month',
            description: 'Perfect for small operations getting started',
            features: [
                'Up to 5 drivers',
                'Basic dispatch features',
                'Standard reporting',
                'Email support',
                'Job management',
                'Basic analytics'
            ],
            priceId: 'price_basic_test123',
            highlighted:true
        },
      
    ];

    return (
        <div className="subscription-page p-5">
            <div className="text-center mb-6">
                <h1 className="text-4xl font-bold mb-4">Choose Your Plan</h1>
                <p className="text-xl text-gray-600 px-3">Select the perfect plan for your trucking operation</p>
            </div>

            <div className="grid px-8" style={{ gap: '3rem' }}>
                {plans.map((plan) => (
                    <div className="col-12 md:col-4 p-8" key={plan.name}>
                        <Card 
                            className={`h-full subscription-card ${plan.highlighted ? 'highlighted-card' : ''}`}
                            style={{ 
                                position: 'relative',
                                border: plan.highlighted ? '2px solid var(--primary-color)' : '1px solid #dee2e6',
                                borderRadius: '8px',
                                transition: 'transform 0.2s, box-shadow 0.2s',
                                cursor: 'pointer',
                                minHeight: '400px',
                                margin: '1.5rem',
                            }}
                        >
                           
                            <div className="text-center p-4">
                                <h2 className="text-2xl font-bold mb-2 text-left">{plan.name}</h2>
                                <p className="text-gray-600 mb-3 min-h-[48px] text-center">{plan.description}</p>
                                <div className="pricing mb-3">
                                    <span className="text-5xl font-bold">{plan.price}</span>
                                    <span className="text-xl text-gray-600 ml-2">{plan.period}</span>
                                </div>
                                
                                <Button 
                                    label={loading ? 'Processing...' : 'Subscribe Now'} 
                                    disabled={loading}
                                    onClick={() => handleSubscription(plan.priceId)}
                                    className={`p-button-raised ${plan.highlighted ? 'p-button-primary' : 'p-button-outlined'} w-full mb-3`}
                                    style={{ cursor: 'pointer' }}
                                />

                                <div className="features-list">
                                    <div className="grid">
                                        <div className="col-6">
                                            {plan.features.slice(0, Math.ceil(plan.features.length / 2)).map((feature, index) => (
                                                <div key={index} className="mb-2 flex align-items-center justify-content-center">
                                                    <i className="pi pi-check-circle mr-2" 
                                                       style={{ color: 'var(--primary-color)', fontSize: '1rem' }}></i>
                                                    <span className="text-gray-700 text-sm">{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6">
                                            {plan.features.slice(Math.ceil(plan.features.length / 2)).map((feature, index) => (
                                                <div key={index} className="mb-2 flex align-items-center justify-content-center">
                                                    <i className="pi pi-check-circle mr-2" 
                                                       style={{ color: 'var(--primary-color)', fontSize: '1rem' }}></i>
                                                    <span className="text-gray-700 text-sm">{feature}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                ))}
            </div>

            {error && (
                <div className="p-message p-message-error mt-4 text-center mx-4">
                    {error}
                </div>
            )}

            <style jsx>{`
                .subscription-page {
                    max-width: 1600px;
                    margin: 3rem auto;
                    padding: 3rem;
                }

                .subscription-card {
                    background: #ffffff;
                    height: 100%;
                }

                .subscription-card:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
                    cursor: pointer;
                }
                
                .highlighted-card {
                    transform: scale(1.02);
                    box-shadow: 0 4px 20px rgba(0,0,0,0.1);
                }
                
                .features-list {
                    border-top: 1px solid #dee2e6;
                    padding-top: 1rem;
                    margin-top: 1rem;
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    text-align: center;
                }

                .pricing {
                    display: flex;
                    align-items: baseline;
                    justify-content: center;
                    gap: 0.5rem;
                    padding: 1.5rem 0;
                }

                .text-gray-600 {
                    color: #666666;
                }

                .text-gray-700 {
                    color: #4a4a4a;
                }

                .card-content {
                    padding: 2rem;
                }

                .features-list .grid {
                    padding: 0 1rem;
                    text-align: center;
                }

                .features-list .col-6 {
                    padding: 0 1rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            `}</style>
        </div>
    );
};

export default SubscriptionPage; 