import React, {useState, useEffect} from 'react'

import { usePrevailingWage } from './context/PrevailingWageContext';
import { usePrevailingWageReport } from './context/PrevailingWageReportContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { UserAuth } from '../../context/AuthContext';
import { Button} from '@mobiscroll/react';

import { useFindWeekDates } from './hooks/useFindWeekDates';

import {useParams } from 'react-router-dom';
const PrevailingWageReportList = () => {
    const { queryFreightBills, freightBillsRef, prevailingWageReport, setPrevailingWageReport }= usePrevailingWage();
    const {queryWeekFreightBills, prevailingWageReportRef, fetchPrevailingWage, reportWeeks, setReportWeeks,  reportWeeksRef } = usePrevailingWageReport();
    const {findFirstSunday} = useFindWeekDates();
    const {formatDate ,updateDocument} = UserAuth();
    const { id} = useParams();

    console.log('reportWeeks in list= ', reportWeeks)
 
  
 
    useEffect(()=>{
        startReportListPage();
      
    },[])


    const startReportListPage = async()=>{
      
        console.log('we are starting the report list page', prevailingWageReport?.ID)
        console.log('and id is ', id)
       
                setReportWeeks([]);
                reportWeeksRef.current=[];
                
                try {
                    // Wait for both promises to resolve
                    await Promise.all([ fetchPrevailingWage(id), queryFreightBills(id)
                    ]);
					console.log('prevailingWageReportRef = ', prevailingWageReportRef.current)
					console.log('pfreightBillsRef.current = ', freightBillsRef.current)
                    // Now that both have resolved, we can safely run findFirstSunday
                    findFirstSunday(
                        prevailingWageReportRef.current.prevailStartDateValue, 
                        prevailingWageReportRef.current.prevailEndDateValue, 
                        freightBillsRef.current, 
                        id
                    );
					console.log('we sent the first sunday query')
                } catch (error) {
                    console.error('Error loading report data:', error);
                }
          
    
    }
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        let databaseDate = formatDate(value, '/', 'MM/DD/YYYY');
        setPrevailingWageReport((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        updateDocument({[fieldName]:databaseDate}, prevailingWageReport.ID,'PrevailingWageReports');
    };


    const buttonBodyTemplate = (rowData, buttonText) => {
        if(rowData.nonPerformance)buttonText='Show Non Performance';
        return <Button color="primary" onClick={() => viewReport(rowData)}  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>
        <span>{buttonText}</span></Button>;
       
    };

    const viewReport = (reportWeek)=>{
        console.log('reportWeek b4 querying fbs = ', reportWeek)
       queryWeekFreightBills(reportWeek, prevailingWageReport);
     
    }




    return(
        <div className="card">
          
          
          {prevailingWageReport && (<div className="mbsc-col-3" style={{paddingRight:".5em"}}>  
       
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar value={prevailingWageReport.prevailStartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'prevailStartDate',e.value )} />
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar value={prevailingWageReport.prevailEndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'prevailEndDate',e.value )} />
                </div>
            </div> )}

                    <DataTable value={reportWeeks} paginator rows={25}  header='Report Weeks' filterDisplay="row" emptyMessage="No dispatches found.">
                        <Column  header="Show Report"  body={(rowData) =>buttonBodyTemplate(rowData, 'Show Report')}  />
                        <Column  pt={{root: { 'data-label': 'Week' }}} field="name" header="Week"  />
						<Column  pt={{root: { 'data-label': 'Freight Bill Count' }}} field="JobFreightCount" header="Job Freight Bill Count"  />
                        <Column  pt={{root: { 'data-label': 'Driver Count' }}} field="DriverCount" header="Driver Count"  />
						<Column  pt={{root: { 'data-label': 'Document Count' }}} field="DocCount" header="Document Count"  />
                        
                    </DataTable>
          
    </div>
    )
}


export default PrevailingWageReportList;
