import React from 'react';


const PaymentItemList = ({ paymentItem}) => {
   

    const formattedNumber = Number(paymentItem.Amount).formatMoney(2);
	
console.log('paymentItem, =' ,paymentItem)
    return (
        <React.Fragment>
        
            <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
                <td  data-label='Date'  style={{ width: '50%', padding: '0', borderWidth:'1px 1px 1px 1px', textAlign:'center',borderColor:'#bcbcd1', borderStyle:'solid' }}>  {paymentItem.formattedDate}  </td>
                <td data-label='Amount' style={{ width: '50%', padding: '0', borderWidth:'1px 1px 1px 1px', textAlign:'center',borderColor:'#bcbcd1', borderStyle:'solid' }}>
                    <a href={paymentItem.link} target="_blank" style={{display:'block', textAlign:'right', cursor:'pointer', paddingRight:'1em', fontFamily:'arial, verdana, sans-serif'}}>${formattedNumber}</a>
                </td>
               
            </tr>           
        </React.Fragment>
    )
}

export default PaymentItemList;