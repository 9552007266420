import React,  {useState, useEffect, useRef, useCallback} from 'react';

import { Dialog } from 'primereact/dialog';
import InputTextParent from '../../InputComponents/InputTextParent'; 
import { InputText} from 'primereact/inputtext';
import { InputNumber} from 'primereact/inputnumber';
import { Panel } from 'primereact/panel';
import { TabView, TabPanel } from 'primereact/tabview';
import {Textarea} from '@mobiscroll/react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';

import { UserAuth } from '../../../context/AuthContext'
import { useGlobal } from '../../../context/GlobalContext'
import {useDriverPopUp} from './context/DriverPopUpContext';

import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import NoteList from '../../ListComponents/NoteList';
import ComplianceList from '../../ListComponents/ComplianceList';



const DriverPopUp = (props) => {
    const [phoneObject, setPhoneObject] = useState({ Phone1: '', Phone2: '', Phone3: '' });
    const [inputValues, setInputValues] = useState({});
    const [selectedTruckTypes, setSelectedTruckTypes]=useState([]);
	const { driverNotes } = useDriverPopUp();

    const { gearedUser, addDocument, updateDocument, deleteDocument, compliances, trucks, trailers, truckTypes, drivers, capabilities, driverComplianceNames} = UserAuth();
    const { driver, setDriver, driverVisible, setDriverVisible, newTruckType, showTruckPopUp, showTrailerPopUp, newCapability, formatDate} = useGlobal();
    const statuses = [ {text :'Active',value: 'Active'},{text :'Inactive',value: 'Inactive'}, {text:'Terminated', value:'Terminated'} ];
    const payTypes = [{text :'Hour',value: 'Hour'},{text :'Percent',value: 'Percent'}, ]
    const federalStatuses = [ {text :'Married',value: 'Married'},{text :'Single',value: 'Single'}, {text:'HH', value:'HH'}, {text:'Exempt', value:'Exempt'}  ];
    const payFrequencies = [ {text :'Weekly',value: 'Weekly'},{text :'Bi-Weekly',value: 'Bi-Weekly'},{text :'Monthly',value: 'Monthly'},{text :'Semi-Monthly',value: 'Semi-Monthly'} ];

    const [activeTab, setActiveTab]= useState(0);
   
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const isFormDirty = useRef(false);
    
    const saveButton = inputValues.ID ? 'Save' : 'Add';


    const driverCompliances  = driver && compliances ? compliances.filter(compliance => compliance.ParentID=== driver.ID).map((compliance, originalIndex) => ({ compliance, originalIndex })) : [];
  //  const driverTrucks = driver &&  outsideTrucks ?  outsideTrucks.map((outsideTruck, index) => ({outsideTruck, originalIndex: index })).filter(({ outsideTruck }) =>outsideTruck.DriverID === driver.ID) : [];

    // Mapping to maintain original index
    const driverRegularNotes = driverNotes ? driverNotes.map((note, index) => ({ note, originalIndex: index })).filter(({ note }) => note.noteType !== 'Schedule').sort((a, b) =>b.note.QueryDate?.localeCompare(a.note.QueryDate)) : [];
    const driverScheduleNotes = driverNotes ? driverNotes.map((note, index) => ({ note, originalIndex: index })).filter(({ note }) => note.noteType === 'Schedule').sort((a, b) =>b.note.QueryDate?.localeCompare(a.note.QueryDate)) : [];
   
    
    console.log(driverScheduleNotes .map(n => n.note.QueryDate));
    const handlePhoneChange = (field, value, nextRef) => {
        const maxLength = field === 'Phone3' ? 4 : 3;
        
        if (value.length <= maxLength) {
            const updatedPhoneObject = { ...phoneObject, [field]: value };
            isFormDirty.current=true;
            // Update the phone object state
            setPhoneObject(updatedPhoneObject);
    
            // Focus on the next field if the current one is fully filled
            if (value.length === maxLength && nextRef) {
                nextRef.current.focus();
            }
            let phone = updatedPhoneObject.Phone1+updatedPhoneObject.Phone2+  updatedPhoneObject.Phone3;
            // Check if all phone fields are filled to their max lengths
            const phoneOK = updatedPhoneObject.Phone1.length === 3 &&
                            updatedPhoneObject.Phone2.length === 3 &&
                            updatedPhoneObject.Phone3.length === 4;
    
            // Update the inputValues state with the phoneOK status
            setInputValues((prev) => ({ ...prev,Phone:phone, phoneOK }));
        }
    };



    const handleCapabilitiesChange = (selected) => {
        console.log('selected = ', selected);
        let displayCapabilities = [];
        isFormDirty.current=true;
        for (var i = 0; i < driver.TruckTypes.length; i++) displayCapabilities.push(driver.TruckTypes[i].TruckCode);
        for (var j = 0; j < selected.length; j++) displayCapabilities.push(selected[j]);
        setInputValues((prev) => ({ ...prev, Capabilities: selected, displayCapabilities:displayCapabilities }));
    };

    const handleFieldChange = (fieldName, value) => {
      
        if (fieldName === 'FirstName' || fieldName === 'LastName') {
         
            const newFirstName = fieldName === 'FirstName' ? value : inputValues.FirstName;
            const newLastName = fieldName === 'LastName' ? value :  inputValues.LastName;
            console.log(' newFirstName = '+ newFirstName )
            console.log('newLastName = '+ newLastName )
            console.log(' driver.FirstName= '+ driver.FirstName)
            console.log(' driver.LastName= '+driver.LastName)
            setInputValues((prev) => ({ ...prev, [fieldName]: value, Name: `${newFirstName} ${newLastName}` }));
        }else  setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        isFormDirty.current=true;
      
    };
    const closeDriverPopUp = () => {
      
            console.log('about to close isFormDirty = '+ isFormDirty.current)
            if(isFormDirty.current){
                if (window.confirm("Changes you made are not saved. Are you sure you want to close?"))  {
                    isFormDirty.current=false;
                    setDriverVisible(false);
                }  
            }else  setDriverVisible(false);
            
   
    };
    useEffect(()=>{
            const handleBeforeUnload = (event) => {
                if (isFormDirty.current) {
                    event.preventDefault();
                    event.returnValue = ""; // Required for some browsers
                }
            };
            window.addEventListener("beforeunload", handleBeforeUnload);
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
    },[]);
    useEffect(() => {
        if(inputValues){
            const fieldsToUpdate = ['Truck', 'Trailer'];
            const collections = {  Truck: trucks, Trailer: trailers };
        
            fieldsToUpdate.forEach(field => {
                const selectedItem = inputValues[field];
                if (selectedItem) {
                    const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                    if (updatedItem) if(updatedItem.Name!==selectedItem.Name) handleFieldChange(field, { ...updatedItem }, false);
    
                }
            });
        }
    }, [ trucks, trailers]);

    useEffect(() => {
        if (driver && Object.keys(driver).length > 0) {
            let tempInputValues={...driver};
            tempInputValues.BirthDateValue=new Date(tempInputValues.BirthDate);
            tempInputValues.HiredDateValue=new Date(tempInputValues.HiredDate);
            tempInputValues.TerminatedDateValue=new Date(tempInputValues.TerminatedDate);
            setInputValues( tempInputValues);

            if (driver?.PhoneObject) setPhoneObject({ ...driver.PhoneObject });
     
      
            let ids = driver.TruckTypes ? driver.TruckTypes.map(truckType => truckType.ID) : [];
            console.log('driver = ', driver)
            setSelectedTruckTypes(ids);

        }
    }, [driver]);


    const handleTruckTypesChange = (selected) => {
        console.log('selected = ', selected);
        let displayCapabilities = [];
        let tempTruckTypes = [];
        setSelectedTruckTypes(selected);
        isFormDirty.current=true;
        driver.TruckTypes = [];
        for (var q = 0; q < truckTypes.length; q++) {
            for (var j = 0; j < selected.length; j++) {
                if (selected[j] === truckTypes[q].ID) {
                    tempTruckTypes.push( truckTypes[q]);
                    displayCapabilities.push(truckTypes[q].TruckCode);
                }
            }
        }
        for (var i = 0; i < driver.Capabilities.length; i++) displayCapabilities.push(driver.Capabilities[i]);
        console.log('displayCapabilities = ', displayCapabilities);
        setInputValues((prev) => ({ ...prev, TruckTypes: tempTruckTypes, displayCapabilities:displayCapabilities }));
    };

    const handleAddCompliance = (event)=>{
  
        let Compliance = {
            ID:'',
            Name:{Name:driverComplianceNames[0].Name, ID:driverComplianceNames[0].ID},
            tempName:{Name:driverComplianceNames[0].Name, ID:driverComplianceNames[0].ID},
            Info:'',
            IssueDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
            ExpDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
            Type:'Driver',
            Track:false,
            ParentID:driver.ID,
            Attachment:{},
        };
        console.log('aabout to run add Copmliance for ', Compliance) 
        addDocument(Compliance, 'Compliances');

    }



    const panelHeaderTemplate = () => {
        return <div className="py-2 px-3"> </div>;
    };
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate)
        isFormDirty.current=true;
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));
       
    };

    const handleAddNote = function (noteType) {
   
        let Note = {};
     
        Note.Note = '';
        Note.noteType = noteType;
        Note.Red = true;
        Note.Yellow = false;
        Note.EndDate = formatDate(new Date(), '/', 'MM/DD/YYYY');
        Note.Date = formatDate(new Date(), '/', 'MM/DD/YYYY');
        Note.StartDate = formatDate(new Date(), '/', 'MM/DD/YYYY');
        Note.QueryDate = formatDate(new Date(), '/', 'YYYY/MM/DD');
        Note.createdBy = gearedUser.Email;
        Note.Truck = {ID:'',DriverName:'No Truck'};
		Note.ParentID = driver.ID;
		
        console.log('note we are adding = ', Note)
		addDocument(Note, 'Notes');
      
  

    };
    
    const handleDeleteNote = useCallback((index, note) => {
       if(window.confirm('Are you sure you want to delete this note?')){
			console.log('deleting note = ', note)
			deleteDocument(note, 'Notes');
	   }
       
       
    }, [driver]);

    const handleUpdateNote = useCallback((index, note) => {
		let tempNote = {...note};
		tempNote.QueryDate = formatDate(tempNote.EndDate, '/', 'YYYY/MM/DD');
        console.log('updateNote = ', note);
        updateDocument(tempNote, note.ID, 'Notes')
       
    }, [driver]);


    const checkDriver = () => {
        const driverName = inputValues.Name?.trim();

    
        // Check 1: Driver must have a name
        if (!driverName) {
            alert('Please enter an driver name before saving.');
            return;
        }
    
        // Check 2: Driver name must be unique (case-insensitive)
        const driverExists = drivers.some(
            (driver) => driver.Name.toLowerCase() === driverName.toLowerCase() && driver.ID!==inputValues.ID
        );
        if (driverExists) {
            alert('An driver with this name already exists.');
            return;
        }
    
        // Check 3: Subhauler must have a Driver Name and valid Phone Number
       
            const phoneOK = inputValues.phoneOK;
    
            if (!driverName || !phoneOK) {
                alert('Please enter a Driver Name and Phone Number before saving a Driver.');
                return;
            }
        
      handleSaveDriver();
    
    }
 
    const handleSaveDriver = () => {
        isFormDirty.current=false;
        // If all checks pass, proceed to save the driver
        let driverPriority =0;
        drivers.forEach(tempDriver => {
            if(driverPriority<=Number(tempDriver.Priority))driverPriority=tempDriver.Priority;
        })
        driverPriority++;
        let updatedObject = { ...inputValues };
        updatedObject.PhoneObject = { ...phoneObject };
        updatedObject.displayPhone = `${updatedObject.PhoneObject.Phone1}-${updatedObject.PhoneObject.Phone2}-${updatedObject.PhoneObject.Phone3}`;
        if(driver.ID)  updateDocument(updatedObject, driver.ID, "Drivers");
        else{
            console.log('driverPriority = '+ driverPriority)
            updatedObject.Priority = driverPriority;
           addDocument(updatedObject, "Drivers");
        }

        
       closeDriverPopUp();

    };


    const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
            <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => closeDriverPopUp()} />
            <Button style= {{fontSize:'1.5em', width:'9em'}} label={saveButton} icon="pi pi-check" onClick={() => checkDriver()}  />
        </div>
    
    );
    return (
        <Dialog header="Driver Details" visible={driverVisible} style={{ width: '95vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeDriverPopUp}>

        <TabView  style={{margin:"0"}} activeIndex={activeTab}  >
            <TabPanel header="Driver" style={{marginTop:"0"}}  >   
                <div className="mbsc-row mbsc-grid" >   
                    <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>   
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">First Name</span>
                            <InputTextParent value={inputValues.FirstName} onChange={(e) => handleFieldChange('FirstName', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Last Name</span>
                            <InputTextParent value={inputValues.LastName} onChange={(e) => handleFieldChange('LastName', e.target.value)} />
                        </div>
                 
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Email</span>
                            <InputTextParent value={inputValues.Email} onChange={(e) => handleFieldChange('Email', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Address</span>
                            <InputTextParent value={inputValues.Address} onChange={(e) => handleFieldChange('Address', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">City</span>
                            <InputTextParent value={inputValues.City} onChange={(e) => handleFieldChange('City', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">State</span>
                            <InputTextParent value={inputValues.State} onChange={(e) => handleFieldChange('State', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Zip Code</span>
                            <InputNumber  useGrouping={false}  value={inputValues.ZipCode} onChange={(e) => handleFieldChange('ZipCode', e.value)} />
                        </div>

                    
                    </div>
                    <div className="mbsc-col-xl-4 " style={{paddingLeft:".25em"}} > 
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Phone</span>
                            <InputText maxLength={3} value={phoneObject.Phone1} onChange={(e) => handlePhoneChange('Phone1', e.target.value, inputRef2)}  />-
                            <InputText ref={inputRef2} maxLength={3} value={phoneObject.Phone2} onChange={(e) => handlePhoneChange('Phone2', e.target.value, inputRef3)}  />-
                            <InputText ref={inputRef3} maxLength={4} value={phoneObject.Phone3} onChange={(e) => handlePhoneChange('Phone3', e.target.value, null)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Truck Types</span>
                            <MultiSelect panelHeaderTemplate={panelHeaderTemplate} value={selectedTruckTypes} onChange={(e) => handleTruckTypesChange(e.value)} options={truckTypes} optionLabel="Name"
                                placeholder="Truck Types" maxSelectedLabels={3} className="w-full md:w-20rem" />
                             <button  className="mbsc-reset mbsc-font mbsc-button mbsc-windows mbsc-ltr mbsc-button-standard"  onClick={(e) =>newTruckType()}  starticon="tag" style={{ color: "blue", margin: "0" }}>
                                    <span className='mbsc-button-icon mbsc-ltr mbsc-button-icon-start mbsc-icon mbsc-windows mbsc-font-icon mbsc-icon-tag'></span>
                                </button>
                        </div>

                        <AutoCompleteInput fieldName="Truck" field="Name" labelClass="p-inputgroup-addon freight-inputgroup" value={inputValues.Truck} suggestions={trucks} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckPopUp(inputValues.Truck)} databaseList={'Trucks'}/>

                        <AutoCompleteInput fieldName="Trailer" field="Name"  labelClass="p-inputgroup-addon freight-inputgroup" value={inputValues.Trailer} suggestions={trailers} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTrailerPopUp(inputValues.Trailer)} databaseList={'Trailers'}/>

                        <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Capabilities</span>
                                <MultiSelect panelHeaderTemplate={panelHeaderTemplate} value={inputValues.Capabilities} onChange={(e) => handleCapabilitiesChange(e.value)} options={capabilities} optionLabel="Name"
                                    placeholder="Capabilities" maxSelectedLabels={3} className="w-full md:w-20rem" />
                                <button  className="mbsc-reset mbsc-font mbsc-button mbsc-windows mbsc-ltr mbsc-button-standard"  onClick={(e) =>newCapability()}  starticon="tag" style={{ color: "blue", margin: "0" }}>
                                    <span className='mbsc-button-icon mbsc-ltr mbsc-button-icon-start mbsc-icon mbsc-windows mbsc-font-icon mbsc-icon-tag'></span>
                                </button>
                            </div>
                        <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">License #</span>
                                <InputTextParent value={inputValues.DriversLicense} onChange={(e) => handleFieldChange('DriversLicense', e.target.value)} />
                            </div>
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Hired Date</span>
                                 <Calendar value={inputValues.HiredDateValue} onChange={(e) => handleDateChange('HiredDate',e.value)} />
                            </div> 
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Date of Birth</span>
                                 <Calendar value={inputValues.BirthDateValue} onChange={(e) => handleDateChange('BirthDate',e.value)} />
                            </div> 
                    </div>
                    <div className="mbsc-col-xl-4" style={{paddingLeft:".25em"}}>  
                        
                    <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Pay Frequency</span>
                                <Dropdown value={inputValues.PayFrequency} onChange={(e) => handleFieldChange('PayFrequency', e.value)} options={payFrequencies} optionLabel="text"
                                    placeholder="Pay Frequency" className="w-full md:w-14rem" />
                        </div>
                        <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Pay Type</span>
                                <Dropdown value={inputValues.PayType} onChange={(e) => handleFieldChange('PayType', e.value)} options={payTypes} optionLabel="text"
                                    placeholder="Pay Type" className="w-full md:w-14rem" />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Pay Rate</span>
                            <InputNumber  useGrouping={false}  value={inputValues.PayRate} onChange={(e) => handleFieldChange('PayRate', e.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Travel Time Rate</span>
                            <InputNumber  useGrouping={false}  value={inputValues.TravelRate} onChange={(e) => handleFieldChange('TravelRate', e.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">SSN/Tax ID</span>
                            <InputNumber  useGrouping={false}  value={inputValues.SSN} onChange={(e) => handleFieldChange('SSN', e.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon freight-inputgroup">Dependents</span>
                            <InputNumber  useGrouping={false}  value={inputValues.Dependents} onChange={(e) => handleFieldChange('Dependents', e.value)} />
                        </div>
                        <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Fed. Filing Status</span>
                                <Dropdown value={inputValues.FederalStatus} onChange={(e) => handleFieldChange('FederalStatus', e.value)} options={federalStatuses} optionLabel="text"
                                    placeholder="Pay Type" className="w-full md:w-14rem" />
                        </div>
                        <div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Pay Type</span>
                                <Dropdown value={inputValues.Status} onChange={(e) => handleFieldChange('Status', e.value)} options={statuses} optionLabel="text"
                                    placeholder="Pay Type" className="w-full md:w-14rem" />
                        </div>
                        {inputValues.Status==='Terminated' && (<div className="p-inputgroup">
                                <span className="p-inputgroup-addon freight-inputgroup">Terminated Date</span>
                                 <Calendar value={inputValues.TerminatedDateValue} onChange={(e) => handleDateChange('TerminatedDate',e.value)} />
                            </div> )}
            

                    </div>
                </div>
                <div className="mbsc-row">
                    <div className="p-inputgroup text-area-inputgroup mbsc-col-6 ">
                        <span className="p-inputgroup-addon freight-inputgroup " >Internal Notes</span> 
                        <Textarea  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px"}} value={inputValues.Note}  onChange={(e) => handleFieldChange('Note', e.target.value)} />
                    </div>
                  
                    
                </div>
                {inputValues.ID  && (<Panel header='Compliances' style={{width:"100%"}}>
                    <button style={{ margin: '0', padding: '.5em', width:"10%" }}  onClick={(e) =>handleAddCompliance(e)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Add Compliance  </button>
                    <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                        <table style={{ marginBottom: "5px", width: "100%" }}>
                            <thead>
                                <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                    <th style={{ width: "10%" }}>Delete</th>
                                    <th style={{ width: "20%" }}>Name</th>
                                    <th style={{ width: "15%" }}>Info</th>
                                    <th style={{ width: "15%" }}>Issue Date</th>
                                    <th style={{ width: "15%" }}>Expiration Date</th>
                        
                                    <th style={{ width: "15%"}}>Download</th>
                                    <th style={{ width: "10%"}}>Upload</th>
                            
                                </tr>
                            </thead>
                            <tbody>
                                {driverCompliances.map(({ compliance, originalIndex }) => (
                                    <ComplianceList key={originalIndex} complianceNames={driverComplianceNames} compliance={compliance} formatDate={formatDate} gearedUser={gearedUser} deleteDocument={deleteDocument}  />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Panel > )}
            </TabPanel> 
     
        
            {inputValues.ID &&(
                <TabPanel header="Schedule" style={{marginTop:"0"}}  > 
                    <div className="mbsc-row" style={{ width: "100%" }}>
                        <div className="mbsc-col-md-2 mbsc-col-4"><button id="addNoteButton" className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button"  onClick={(e) =>handleAddNote('Schedule')}    >Add Schedule</button></div>
                    </div>
                    <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                        <table style={{ marginBottom: "5px", width: "100%" }}>
                            <thead>
                                <tr style={{ width: "100%", marginLeft: "1em" }}>
                                    <th style={{ width: "10%" }}>Delete</th>
                                    <th style={{ width: "15%" }}>Start Date</th>
                                    <th style={{ width: "15%" }}>End Date</th>
                                  
                                    <th style={{ width: "15%" }}>Note</th>
                                 
                                    <th style={{ width: "5%", background: "red" }}></th>
                                    <th style={{ width: "5%", background: "#ef6c00" }}></th>
                                    <th style={{ width: "20%" }}>Created By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {driverScheduleNotes.map(({ note, originalIndex }) => (
                                    <NoteList key={originalIndex} note={note} formatDate={formatDate} onDeleteNote={(deletedNote) => handleDeleteNote(originalIndex,deletedNote)}   onUpdateNote={(updatedNote) => handleUpdateNote(originalIndex, updatedNote)}   />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </TabPanel>    
            )}    
            
            {inputValues.ID && ( <TabPanel header="Notes" style={{marginTop:"0"}}  > 
                <div className="mbsc-row" style={{ width: "100%" }}>
                        <div className="mbsc-col-md-2 mbsc-col-4"><button id="addNoteButton" className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button"  onClick={(e) =>handleAddNote('Notes')}   >Add Note</button></div>
                    </div>
                    <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                        <table style={{ marginBottom: "5px", width: "100%" }}>
                            <thead>
                                <tr style={{ width: "100%", marginLeft: "1em" }}>
                                    <th style={{ width: "10%" }}>Delete</th>
                                    <th style={{ width: "15%" }}>Start Date</th>
                                    <th style={{ width: "15%" }}>End Date</th>
                                  
                                    <th style={{ width: "15%" }}>Note</th>
                                 
                                    <th style={{ width: "5%", background: "red" }}></th>
                                    <th style={{ width: "5%", background: "#ef6c00" }}></th>
                                    <th style={{ width: "20%" }}>Created By</th>
                                </tr>
                            </thead>
                            <tbody>
                                {driverRegularNotes.map(({ note, originalIndex }) => (
                                    <NoteList key={originalIndex} note={note} formatDate={formatDate} onDeleteNote={(deletedNote) => handleDeleteNote(originalIndex,deletedNote)}   onUpdateNote={(updatedNote) => handleUpdateNote(originalIndex, updatedNote)} />
                                ))}
                            </tbody>
                        </table>
                    </div>
            </TabPanel>   )} 
            
        </TabView> 
     
                
        </Dialog>
    );
};

export default DriverPopUp;