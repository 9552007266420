import React, { useEffect, useMemo, useCallback } from 'react';
import { Eventcalendar } from '@mobiscroll/react';
import { useDispatch } from '../context/DispatchContext';
import { useGlobal } from '../../../context/GlobalContext';

const DispatchCalendar = ({printAllDispatch, releaseAllDispatch}) => {
    const { homeDate, setHomeDate, queryDispatches, queryFreightBills, formatDate, homeDispatches, homeFreightBills, navigate} = useDispatch();
    const { setGlobalDispDate, setTopMenuOptions} = useGlobal();

    const myView = useMemo(() => ({ calendar: { type: 'week', labels: true } }), []);
    const orderMyEvents = useCallback((a, b) => a.order - b.order, []);
    const newLabels = {};
    useEffect(() => {
        const filteredDispatches = homeDispatches.filter(item => item.QueryDate === homeDate);
        let hasUnreleased = false;
        filteredDispatches.forEach(dispatch => {
            if (dispatch.Released === false)hasUnreleased=true;
        });
        console.log('setting top menu options !', filteredDispatches)

        const menuOptions = hasUnreleased ?  [
            {label: 'Release Dispatch', function:(e) => releaseAllDispatch(),  icon:'pi pi-send' },
            {label: 'Emaill All Dispatch', function:(e) => printAllDispatch(true),  icon:'pi pi-envelope'},
            {label: 'Print All Dispatch', function:(e) => printAllDispatch(false),  icon:'pi pi-print'}
        ] : [
            {label: 'Emaill All Dispatch', function:(e) => printAllDispatch(true),  icon:'pi pi-envelope'},
            {label: 'Print All Dispatch', function:(e) => printAllDispatch(false),  icon:'pi pi-print'}
        ]
        setTopMenuOptions(menuOptions)
     
    }, [homeDate, homeDispatches]);

    const realHomeDate = new Date(homeDate);
    console.log('home Dispatches on calendar reload! !',  homeDispatches)
    homeDispatches.forEach(dispatch => {
        const date = dispatch.QueryDate;
       // console.log('looking at this dispatch with date = '+ dispatch.QueryDate +' and the disapthc = ', dispatch)
        if (!newLabels[date]) {
            newLabels[date] = { calendarFreightBills: 0, unreadFreightBills: 0, dispatchCount: 0 };
        }
        newLabels[date].dispatchCount += 1;

        if (dispatch.Released === false) {
            newLabels[date].hasUnreleased = true;
        }

        if (dispatch.TrucksOrdered && dispatch.TrucksOrdered !== dispatch.TrucksAssigned) {
            console.log(' becuz of disptach we setting ' + date + ' has unassigned true', dispatch)
            newLabels[date].hasUnassignedTrucks = true;
        }
    });

    homeFreightBills.forEach(fb => {
        const date = fb.QueryDate;
        if (!newLabels[date]) {
            newLabels[date] = { calendarFreightBills: 0, unreadFreightBills: 0, dispatchCount: 0 };
        }
        newLabels[date].calendarFreightBills += 1;
        
        if (!fb.Received && fb.Released) {
            newLabels[date].unreadFreightBills += 1;
        }
    });

    const labelsArray = Object.keys(newLabels).flatMap(date => {
        const { calendarFreightBills, unreadFreightBills, dispatchCount, hasUnreleased, hasUnassignedTrucks } = newLabels[date];
        let tempLabels = [
            { start: new Date(date), end: new Date(date), text: `<div>Disp: ${dispatchCount}</div>`, order: 1, color: hasUnreleased ? 'orange' : 'green' },
            { start: new Date(date), end: new Date(date), text: `<div>Assign: ${calendarFreightBills}</div>`, order: 2, color: hasUnassignedTrucks ? 'orange' : 'green' }
        ];
        if (unreadFreightBills > 0) {
            tempLabels.push({ start: new Date(date), end: new Date(date), text: `<div>Unread: ${unreadFreightBills}</div>`, order: 3, color: 'red' });
        }
        return tempLabels;
    });

    const labels = labelsArray;

    const updateHomeDate = (event) => {
        console.log('event = ', event);
        if (event.date) {
            const tempDate = formatDate(event.date, '/', 'YYYY/MM/DD');
            const encodedDate = encodeURIComponent(tempDate); 
            setHomeDate(tempDate);
            setGlobalDispDate(tempDate);
            navigate('/dispatch/home/'+ encodedDate, { state: { uniqueId: 'dispatch-home' },  replace: true })
          
           
        }
         console.log('homeDate= ', homeDate);
    };

    const startWeekQuery = (startDate, endDate) => {
        console.log('STARTIN WEEK QUERYRR  '+ startDate + ' END ==' +startDate)
        queryDispatches(startDate, endDate);
        queryFreightBills(startDate, endDate);
    };

    const setWeekDates = (event) => {
        console.log('setting week dates11', event)
        const startDate = formatDate(event.firstDay, '/', 'YYYY/MM/DD');
        const endDate = formatDate(event.lastDay, '/', 'YYYY/MM/DD');
        startWeekQuery(startDate, endDate);
    };

    const initCalendar = (event, inst) => {
        console.log('HOME DAY WHEN WE INIT ', realHomeDate);

        const startDate = formatDate(inst._firstDay, '/', 'YYYY/MM/DD');
        const endDate = formatDate(new Date(inst._firstDay).setDate(inst._firstDay.getDate() + 7), '/', 'YYYY/MM/DD');
        startWeekQuery(startDate, endDate);
    };



    return (
        <div style={{  zIndex: 1 }}>
            <Eventcalendar
                theme="ios"
                themeVariant="light"
                clickToCreate={false}
                height={200}
                onInit={initCalendar}
                onPageChange={setWeekDates}
                onSelectedDateChange={updateHomeDate}
                selectedDate={realHomeDate}  // Use localHomeDate instead
                dragToCreate={false}
                dragToMove={false}
                dragToResize={false}
                eventDelete={false}
                data={labels}
                eventOrder={orderMyEvents}
                view={myView}
                style={{ zIndex: 1 }}
            />
        </div>
    );
};

export default DispatchCalendar;
