import React,{useState,useContext,createContext, useRef, useEffect} from 'react'

import { db } from '../../../firebase';
import { doc,  writeBatch,  query,  updateDoc, collection,  onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import {useDispatch} from '../../Dispatch/context/DispatchContext';
const TruckBankContext = createContext();

export const TruckBankContextProvider = ({ children }) => {
    const { homeDate } = useDispatch();
	const {gearedUser, formatDate } = UserAuth();

	const [unassignedTexts, setUnassignedTexts] = useState([]);
	const [driverNotes, setDriverNotes] = useState([]);

	const notesHomeDateRef = useRef(homeDate);

    const driverNotesRef = useRef([]);
	const driverNotesListener = useRef(null);
	
	const unassignedTextsRef =useRef(null);
	const unassignedTextsListener = useRef(null);

    useEffect(()=>{
		if(homeDate){
			if(notesHomeDateRef?.current!==homeDate && gearedUser?.selectedOrgName){
				notesHomeDateRef.current=homeDate;
				queryNotes();
				queryUnassignedTexts();
			}
		}
		
	  },[ homeDate])


	const queryNotes = () =>{
        driverNotesRef.current = [];

        console.log('gettting texts for date ' + notesHomeDateRef.current);
        if ( driverNotesListener.current)  driverNotesListener.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Notes`;
            const q = query(collection(db, queryName), where("QueryDate", ">=",  formatDate(notesHomeDateRef.current,'/','YYYY/MM/DD')));
			driverNotesListener.current=   onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempNote= change.doc.data();
          
                tempNote.ID = change.doc.id;
                if (change.type === "added")   driverNotesRef.current.push(tempNote);
                
                if (change.type === "modified") {
                    const noteIndex =driverNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    driverNotesRef.current[noteIndex] = tempNote;
                }
                if (change.type === "removed") {
                    const noteIndex =driverNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    driverNotesRef.current.splice(noteIndex,1)
                }
                
            })
            console.log('driver notes = ',driverNotesRef.current)
            setDriverNotes([...driverNotesRef.current]);
        })
    }

	
    const queryUnassignedTexts = () =>{
        unassignedTextsRef.current = [];

        console.log('gettting texts for date ' + formatDate(notesHomeDateRef.current,'/','MM/DD/YYYY'));
        if (unassignedTextsListener.current) unassignedTextsListener.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/UnassignedTexts`;
            const q = query(collection(db, queryName), where("Date", "==", formatDate(notesHomeDateRef.current,'/','MM/DD/YYYY')));
            	unassignedTextsListener.current=   onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempUnassigned = change.doc.data();
                tempUnassigned.FreightBills = [];
                tempUnassigned.ID = change.doc.id;
                if (change.type === "added")   unassignedTextsRef.current.push(tempUnassigned);
                
                if (change.type === "modified") {
                    const unassignedIndex =unassignedTextsRef.current.findIndex(d => d.ID === tempUnassigned.ID);
                    unassignedTextsRef.current[unassignedIndex] = tempUnassigned;
                }
                if (change.type === "removed") {
                    const unassignedIndex =unassignedTextsRef.current.findIndex(d => d.ID === tempUnassigned.ID);
                    unassignedTextsRef.current.splice(unassignedIndex,1)
                }
                
            })
            console.log('unassignedtexts = ',unassignedTextsRef.current)
            setUnassignedTexts([...unassignedTextsRef.current]);
        })
    }

	return (
		<TruckBankContext.Provider value={{
			driverNotes, unassignedTexts
		}}>
			{children}
		</TruckBankContext.Provider>
	);
  };
  export const useTruckBank= () => {
	  	return useContext(TruckBankContext);
  };