import React,{useEffect,useState} from 'react';
import { Checkbox } from 'primereact/checkbox';
import  TableCell  from '../../ListComponents/TableCell';
import DPSEditableLineItem from './DPSEditableLineItem';


const DPSLineItemLine = ({ lineItem, index,DPS, company, toggleOnHold, borderWidth, columns, openExpensePopUp, updateLineItem }) => {
    const [inputValues, setInputValues]= useState(null);

    lineItem.index=index;
    useEffect(() => {
        if (lineItem) {
            let tempLineItem = {...lineItem}
            if(new Date(lineItem.JobDate).toString()==='Invalid Date') tempLineItem.JobDate='';
            setInputValues(tempLineItem);
           if(lineItem.Type==='Scale Tag') console.log('setting the input Values of fb #  = ' +lineItem.FBNO +' to this onhold value= '+ lineItem.onHold)
        }
    }, [lineItem]);

    const handleUpdateLineItem = (lineItem)=>{
        console.log('tryina update Line Item = ', lineItem)
        updateLineItem(lineItem);
    }
    const handleFieldChange = ( fieldName,value ) => {
        console.log('tryina set fieldname = '+ fieldName + ' equal to value = ', value)
         setInputValues((prev) => ({ ...prev, [fieldName]: value }));
         let tempLineItem={...inputValues};
         tempLineItem[fieldName]=value;
        toggleOnHold(tempLineItem);
    };

    const openFreightBill = (lineitem)=>{
        var win = window.open('/freightbill/freightbill/'+lineitem.FreightID+'/'+lineItem.dispatchID, '_blank');
        win.focus();
        console.log('line item = ', lineItem)
    }

    let borderStyle= borderWidth ? borderWidth: "1px 1px 1px 1px"
 

    const renderCell = (columnId, values, col) => {
        switch(columnId) {
            case 'jobdate':
                return (
                    <TableCell  
                        width={col.width} 
                        value={values.JobDate} 
                        borderStyle={borderStyle}
                        dataLabel='Job Date'
                    />
                );
            case 'fbno':
                return (
                    <td data-label='FB NO' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}>
                      
                      {values.isEditable && ( <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 

                                    width:"90%" 
                                }} 
                                onClick={(e) => openExpensePopUp(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                               Open Expense
                            </button>)}
                        {!values.hideFBNO &&  (values.Type==='Freight Bill' ||  values.Type==='Expense' && !values.isEditable) && (
                            <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 
                                    width:"90%" 
                                }} 
                                onClick={(e) => openFreightBill(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                                {values.FBNO}
                            </button>
                        )}
                    </td>
                );
       
            case 'jobnumber':
                return company.ShowDPSJobNumber &&(
                    <TableCell  
                        width='6%'
                        value={values.JobNumber} 
                        borderStyle={borderStyle}
                        dataLabel='Job Number'
                    />
                );
            case 'ponumber':
                return company.ShowDPSPONumber &&(
                    <TableCell  
                        width='6%'
                        value={values.PONumber} 
                        borderStyle={borderStyle}
                        dataLabel='PO Number'
                    />
                );
            case 'description':
                return  (
                    <TableCell 
                        width={col.width} 
                        value={values.Description} 
                        borderStyle={borderStyle} 
                        dataLabel='Description'
                    />
                );
            case 'hours':
                return company.ShowDPSHours && !DPS.Subhauler && (
                    <TableCell 
                        width='4%'
                        value={values.Hours} 
                        isNumber={false}
                        borderStyle={borderStyle} 
                        dataLabel='Hours'
                    />
                );
            case 'onHold':
                return !DPS.ID &&  (
                    <td 
                        data-label='On Hold' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <Checkbox style={{ height:"2em", width: '100%', padding:"0 ", paddingLeft:"2.5em" }}  disabled={lineItem.Type==='Travel Time' || lineItem.Type==='Overtime'  && lineItem.hideFBNO}  checked={inputValues.onHold} onChange={(e) => handleFieldChange( 'onHold', e.checked)}/>                    </td>
                );
         
            case 'rateType':
                return <TableCell 
                    width={col.width} 
                    value={values.RateType} 
                    isNumber={false}
                    borderStyle={borderStyle} 
                    dataLabel='Rate Type'
                />;
            case 'qty':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    notCurrency={true} 
                    value={values.Qty} 
                    borderStyle={borderStyle} 
                    dataLabel='Qty'
                />;
            case 'rate':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    value={values.Rate} 
                    borderStyle={borderStyle} 
                    dataLabel='Rate' 
                />;
            case 'amount':
                return (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.Amount} 
                        borderStyle={borderStyle} 
                        dataLabel='Amount'
                    />
                );
        
                case 'trailerFee':
                    return DPS.showTrailerFee && (
                        <TableCell 
                            width={col.width} 
                            isNumber={true} 
                            value={values.TrailerTotal} 
                            borderStyle={borderStyle} 
                            dataLabel='Trailer Fee'
                        />
                    );
            case 'brokerFee':
                return DPS.showBrokerFee && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.BrokerTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Broker Fee'
                    />
                );
            case 'driverPercent':
                return DPS.showDriverPercent &&  (
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value={values.DriverPercent} 
                        borderStyle={borderStyle} 
                        dataLabel='Percent}'
                    />
            );
            case 'total':
                return DPS.showDriverPercent &&  (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.Total} 
                        borderStyle={borderStyle} 
                        dataLabel='Total'
                    />
            );
            default:
                return null;
        }
    }; 

    return (
        <React.Fragment>
            {inputValues && !inputValues.FreightHold && (<>
                {lineItem.isEditable && !DPS.ID ? (
                    <DPSEditableLineItem  lineItem={lineItem} columns={columns}  onUpdate={handleUpdateLineItem} /> ) : (
                    <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em'}}>
                        {columns.map(col => col.visible && renderCell(col.id, inputValues, col))}
                    </tr>)}
                            
            </>)}        
        </React.Fragment>
    )
}

export default DPSLineItemLine;