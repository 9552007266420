import React from 'react';

import AccountPopUp from './AccountPopUp/AccountPopUp';
import {AccountPopUpContextProvider} from './AccountPopUp/context/AccountPopUpContext';
import ContactPopUp from './ContactPopUp';
import EmployeePopUp from './EmployeePopUp';
import DriverPopUp from './DriverPopUp/DriverPopUp';
import {DriverPopUpContextProvider} from './DriverPopUp/context/DriverPopUpContext';
import MaterialPopUp from './MaterialPopUp';


import TruckPopUp from './TruckPopUp';
import TrailerPopUp from './TrailerPopUp';
import TruckTypePopUp from './TruckTypePopUp';
import LocationPopUp from './LocationPopUp';
import NamePopUp from './NamePopUp';
import ImagePopUp from './ImagePopUp';
import TabletDriverPopUp from './TabletDriverPopUp';
import ExpensePopUp from '../Expenses/ExpensePopUp';
import FuelCardPopUp from './FuelCardPopUp';
function PopUpParent() {

 
  return ( 

    <React.Fragment>
		<AccountPopUpContextProvider>
        	<AccountPopUp />
		</AccountPopUpContextProvider>

        <ContactPopUp />
		<EmployeePopUp />
		<ExpensePopUp />
		<DriverPopUpContextProvider>
        	<DriverPopUp />
		</DriverPopUpContextProvider>
		<FuelCardPopUp />
        <MaterialPopUp />
        <TruckPopUp />
        <TrailerPopUp />
        <TruckTypePopUp />
        <LocationPopUp />
        <NamePopUp /> 
        <ImagePopUp />
        <TabletDriverPopUp/>
   
    </React.Fragment>
 
  );
}

export default PopUpParent;