import React, { useState, useEffect,   } from 'react';
import InputTextParent from '../../InputComponents/InputTextParent'; 
import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber';



const OutsideDriverListItem = ({outsidedriver, onUpdateDriver}) => {


    const [inputValues, setInputValues] = useState({}); 

    console.log('outsidedriver = ', outsidedriver );
    
    useEffect(() => {
        if (outsidedriver) {
     
            console.log('outsidedriver = ', outsidedriver );
            setInputValues(outsidedriver);
         
        }
    }, [outsidedriver]);

    const handleFieldChange = ( fieldName,value ) => {
       console.log('tryina set fieldname = '+ fieldName + ' equal to value = ', value)
       let tempInputValues= {...inputValues};
       tempInputValues[fieldName]=value;
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        onUpdateDriver(tempInputValues);
    };

 

    return (
        <React.Fragment> 
        {inputValues && (<tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
            <td data-label="Name" style={{ width: '20%', padding: '0' }}>
                <InputTextParent value={inputValues.Name} style={{width:"100%"}} onChange={(e) =>handleFieldChange( 'Name', e.target.value)} disabled={true}/>
            </td>
			<td data-label="EquipNo" style={{ width: '15%', padding: '0' }}>
                <InputTextParent value={inputValues.EquipNo} style={{width:"100%"}} onChange={(e) =>handleFieldChange( 'EquipNo', e.target.value)}/>
            </td>
            <td data-label="CheckNo" style={{ width: '15%', padding: '0' }}>
                <InputTextParent value={inputValues.CheckNo} style={{width:"100%"}} onChange={(e) =>handleFieldChange( 'CheckNo', e.target.value)}/>
            </td>
            <td data-label="Exception" style={{ width: '15%', padding: '0' }}>
                <InputTextParent value={inputValues.Exception} style={{width:"100%"}} onChange={(e) =>handleFieldChange( 'Exception', e.target.value)}/>
            </td>
            <td data-label="Gross Pay" style={{ width: '15%', padding: '0' }}>
                <AutoSelectInputNumber value={inputValues.allProjectsTotal} style={{width:"100%"}}  isCurrency={true} onChange={(e) =>handleFieldChange( 'allProjectsTotal', e.target.value)}/>
            </td>
            <td data-label="Explanation" style={{ width: '20%', padding: '0' }}>
                <InputTextParent value={inputValues.Explanation} style={{width:"100%"}} onChange={(e) =>handleFieldChange( 'Explanation', e.target.value)}/>
            </td>
        
           
         
        
        </tr>)} 
        </React.Fragment> 
    );
};

export default OutsideDriverListItem;