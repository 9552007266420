
import React,{useState, useRef,  useCallback, useEffect} from 'react'

import { db } from '../../../firebase';
import { query, collection,  onSnapshot, where } from 'firebase/firestore';
import { getStorage, ref, uploadBytes,getDownloadURL} from "firebase/storage";
import { UserAuth } from '../../../context/AuthContext';
import AttachmentLine from '../../ListComponents/AttachmentLine';



const AttachmentList = ({ dispatch}) => {
    const [dispatchAttachments, setDispatchAttachments]=useState([]);
    const { gearedUser, addDocument } = UserAuth();
    const storage = getStorage();
    const attachmentsRef = useRef(null);
    const attachmentsListenerRef = useRef(null);
    useEffect(()=>{
        if(dispatch?.ID)queryAttachments(dispatch.ID);
    },[dispatch]);
 
    const startUpload=(event)=>{
        console.log('we have started an uplaod and event ', event)
        uploadFile(event.target.files[0])
    }
    const uploadFile = async(file)=>{
        console.log('file = ', file.name)

        let storageRef= ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/Dispatches/' + dispatch.ID + '/' + file.name);
        console.log('fdispatch.ID= '+dispatch.ID)
        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef).then((url) => {
                let newAttachment = {
                    ID: '',
                    Name:  file.name,
                    dispatchID: dispatch.ID,
                    fileName: file.name,
                    filetype:file.type,
                    url:url
                }
                addDocument(newAttachment, "Attachments")
                console.log('download url = ', url);
          });
        });
    }

    const queryAttachments = useCallback((dispID) => {
        attachmentsRef.current = [];
        if ( attachmentsListenerRef.current) attachmentsListenerRef.current();
        console.log('I AM OPENING A LISTENER TO THISE  attachment!!')
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Attachments`;
        const q = query(collection(db, queryName), where("dispatchID", "==", dispID));
        attachmentsListenerRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                let tempAttach = change.doc.data(); 
        
                tempAttach.ID = change.doc.id;
                if (change.type === "added")  attachmentsRef.current.push(tempAttach);
                if (change.type === "modified") { 
                    const attachIndex =  attachmentsRef.current.findIndex(a => a.ID === tempAttach.ID);
                    attachmentsRef.current[attachIndex]= {...tempAttach}          
                }
                if (change.type === "removed") {
                    const attachIndex =  attachmentsRef.current.findIndex(a => a.ID === tempAttach.ID);
                    attachmentsRef.current.splice(attachIndex,1);
                
                }
            });
            console.log('SETTING attachmentsRef',attachmentsRef.current)
            setDispatchAttachments([...attachmentsRef.current])
        });
    }, [gearedUser])
    return(
        <React.Fragment>
             <input type='file' id={'attachUpload'}    onChange={(event,inst) => startUpload(event)} style={{display:'none'}} base-sixty-four-input="true"/>
                <table style={{ marginBottom: "5px", width: "100%" }}>
                    <tbody>
                        {dispatchAttachments.map((item,index) => ( <AttachmentLine key={index} attachment={item} /> ))}
                    </tbody>
                </table>
        </React.Fragment>
    )
}
export default AttachmentList;