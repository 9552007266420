import React, { createContext, useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';

const StripeContext = createContext();

export function StripeContextProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const handleSubscription = async (priceId) => {
        setLoading(true);
        try {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                lineItems: [{
                    price: priceId,
                    quantity: 1,
                }],
                mode: 'subscription',
                successUrl: `${window.location.origin}/subscription/success`,
                cancelUrl: `${window.location.origin}/subscription/cancel`,
            });
            if (error) throw error;
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <StripeContext.Provider value={{ handleSubscription, loading, error }}>
            {children}
        </StripeContext.Provider>
    );
}

export const useStripe = () => useContext(StripeContext); 