import React, { useEffect, useState, useCallback } from 'react';

import InputTextParent from '../../InputComponents/InputTextParent'; 
import { Panel } from 'primereact/panel';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';

import { UserAuth } from '../../../context/AuthContext';
import { useGlobal } from '../../../context/GlobalContext';

import { db } from '../../../firebase';
import {  doc, setDoc} from 'firebase/firestore';

import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import NoteComponent from '../../MiscComponents/NoteComponent'; 

import debounce from 'lodash/debounce';
const FreightPopUp = ({ freightBill, visible, setVisible,sendText, updateLoadOrders }) => {
    const {  gearedUser, truckTypes, trucks, trailers, materials, updateDocument, deepEqual} = UserAuth();
    const {  showMaterialPopUp, showTruckTypePopUp, showTruckPopUp, showTrailerPopUp} = useGlobal();
    const [inputValues, setInputValues] = useState({}); // State to handle input values
    useEffect(() => {
        if (freightBill) {
        
            setInputValues({
                Subhauler:freightBill.Subhauler,
                driverName: freightBill.driverName,
                PhoneNumber:freightBill.PhoneNumber,
                Truck: freightBill.Truck,
                ID:freightBill.ID,
                Trailer: freightBill.Trailer,
                TruckType: freightBill.TruckType,
                Material: freightBill.Material,
                FBNoteToDriverQuill: freightBill.FBNoteToDriverQuill,
                dispatchTime:freightBill.dispatchTime,
                SetManual:freightBill.SetManual ? freightBill.SetManual : false,
                driverNote: freightBill.driverNote,
                Released:freightBill.Released,
                ReceivedTime:freightBill.ReceivedTime,
                Received:freightBill.Received

            });
            console.log('freightBill on load= ' , freightBill)
        }
    }, [freightBill]);  

    useEffect(() => {
        const fieldsToUpdate = ['TruckType', 'Truck', 'Trailer', 'Material'];
        const collections = { TruckType: truckTypes, Truck: trucks, Trailer: trailers, Material: materials };
    
        fieldsToUpdate.forEach(field => {
            const selectedItem = inputValues[field];
            if (selectedItem) {
                const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                if (updatedItem) if(updatedItem.Name!==selectedItem.Name) handleFieldChange(field, { ...updatedItem }, true);

            }
        });
    }, [truckTypes, trucks, trailers, materials]);
    //console.log('inputValues in freight= ',inputValues)
    const updateFreightBillFields=(fields, values)=>{
        const newState = inputValues ? { ...inputValues } : {};
        for (let i = 0; i < fields.length; i++) {
            newState[fields[i]] = values[i];
        }
    
        // Use newState for any other purpose here, e.g., logging or further manipulation
        console.log('New state:', newState);
    
        // Finally, set the new state in React
        updateDocument(newState, freightBill.ID, 'FreightBills');
        updateDocument(newState, freightBill.ID, 'DriverFreightBills');
        setInputValues(newState);
    }
    const formatStartTime =useCallback(async  (starttime) =>{
        console.log('wtf freightbill = ', freightBill)
        starttime= starttime.replace(/\./g,':');
        if (/^([01][0-9]|2[0-3])[0-5][0-9]$/.test(starttime)) starttime = starttime.substr(0, 2) + ':' + starttime.substr(2);
        else if (/^([0-9]|[0-3])[0-5][0-9]$/.test(starttime)) starttime= '0' + starttime.substr(0, 1) + ':' + starttime.substr(1);
        else if (/^([0-9]|[0-3]):[0-5][0-9]$/.test(starttime)) starttime = '0' + starttime.substr(0, 1) + ':' + starttime.substr(2, 3);
        setInputValues((prev) => ({ ...prev, 'dispatchTime': starttime }));
        updateDocument({'dispatchTime': starttime }, freightBill.ID, 'FreightBills');
    },[freightBill]);
    const debouncedFormatTime = useCallback(debounce(formatStartTime, 500), [formatStartTime]);

    const debouncedUpdateFreightBillFields = useCallback(debounce(updateFreightBillFields, 500), [updateFreightBillFields]);
    const handleFieldChange = (fieldName, value) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
      
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        updateDocument({[fieldName]: value }, freightBill.ID, 'FreightBills');
        updateDocument({[fieldName]: value }, freightBill.ID, 'DriverFreightBills');
    };
    const handleNoteChange =(noteField, text, quillNoteField, quill)=>{
        
        let fields =[noteField,quillNoteField];
        let values = [text,quill];
        console.log('fields = ', fields);
        console.log('values = ', values)
        debouncedUpdateFreightBillFields(fields, values );
     
    }
  const changeStartTime = (starttime) =>{
        setInputValues((prev) => ({ ...prev, dispatchTime: starttime }));
        debouncedFormatTime(starttime);
    }
    const openFreightBill = ()=>{
        setVisible(false);
        var win = window.open('/freightbill/freightbill/'+freightBill.ID+'/'+freightBill.dispatchID, '_blank');
        win.focus();
  
    }

    const openMobileFreight = ()=>{
        let orgName  = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27')
        var win = window.open('/mobile/freightbill/'+orgName+'/'+freightBill.ID, '_blank');
        win.focus();
    
    }
    const closeFreightPopUp = () => {
        setVisible(false);
        console.log('ok we are closing pop up!!');
    };
    const changeTruck = async(fieldName, value) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
         await updateDocument({[fieldName]: value }, freightBill.ID, 'FreightBills');
         await updateDocument({[fieldName]: value }, freightBill.ID, 'DriverFreightBills');
        updateLoadOrders();
    }
    const markFreightAsRead = ()=>{
        let tempDate= new Date();
        let options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          };
          let formattedDate = new Intl.DateTimeFormat('en-US', options).format(tempDate);
          console.log(formattedDate);
          let updateObject= {FreightID: freightBill.ID, Received:true, ReceivedTime:formattedDate}
          let receivedFBRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/ReceivedFreightBills`,freightBill.ID);
          setDoc(receivedFBRef, updateObject);

    }
    const textFreight = ()=>{
        var dispatchDayText = new Date(freightBill.JobDate).toLocaleDateString('en-US', { weekday: 'long' });
		console.log('dfreightBill = ', freightBill)
        let encodedOrgName  = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27')
        sendText(freightBill, dispatchDayText, encodedOrgName,"Released")
    }
    const footerContent = (
  
        <div style={{paddingTop:'.5em', textAlign:'center'}}  className="flex align-items-center gap-2 ">
            
                    <Button style= {{fontSize:'1em', width:'14em',marginTop:".2em"}} label="Open Freight Bill" onClick={() => openFreightBill()}  />
                    <Button style= {{fontSize:'1em', width:'14em',marginTop:".2em"}} label="Open Mobile FB" onClick={() => openMobileFreight()}  />
              
                    <Button style= {{fontSize:'1em', width:'9em',marginTop:".2em"}} label="Close" icon="pi pi-times"  onClick={() => setVisible(false)} />
              
        </div>
    
    );
    return(
        <Dialog header={"Freight Bill"} visible={visible} style={{ width: '50vw' }}  breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeFreightPopUp}>
            <div className="mbsc-form-group mbsc-grid" style={{padding:".5em"}}>
                <div className='mbsc-row' style={{margin:"0"}}> 
                    <div className="p-inputgroup mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <span className="p-inputgroup-addon">Name:</span>
                        <InputTextParent value={inputValues.driverName} disabled={true}  />
                    </div>
                    <div className=" mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                    <AutoCompleteInput fieldName="Material" field="Name" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)} databaseList={'Materials'}/>

                    </div>
                </div>
                <div className='mbsc-row' style={{margin:"0"}}> 
                    <div className="p-inputgroup mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <span className="p-inputgroup-addon">Phone:</span>
                        <InputTextParent value={inputValues.PhoneNumber} disabled={true}  />
                    </div>
                    <div className="mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <AutoCompleteInput label="Truck Type" fieldName="TruckType" field="Name" value={inputValues.TruckType} suggestions={truckTypes}   setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckTypePopUp(inputValues.TruckType)}  databaseList={'TruckTypes'}/>
                    </div>
                </div>
                <div className='mbsc-row' style={{margin:"0"}}> 
                    {!inputValues.Subhauler ?(<div className="mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <AutoCompleteInput fieldName="Truck" field="Name" value={inputValues.Truck} suggestions={trucks} setValue={setInputValues} handleFieldChange={changeTruck} editClick={() => showTruckPopUp(inputValues.Truck)} databaseList={'Trucks'}/>
                    </div>):
                    (<div className="p-inputgroup mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <span className="p-inputgroup-addon dispatch-inputgroup"  >Truck</span> 
                            <InputTextParent  value={inputValues.Truck.Name}  onChange={(e) => changeTruck('Truck.Name', e.target.value)} />
                        </div>)}
                    <div className="mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>           
                        <AutoCompleteInput fieldName="Trailer" field="Name" value={inputValues.Trailer} suggestions={trailers} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTrailerPopUp(inputValues.Trailer)} databaseList={'Trailers'}/>
                    </div>
                </div>
                <div className='mbsc-row' style={{margin:"0"}}> 
                    <div className="p-inputgroup mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <span className="p-inputgroup-addon dispatch-inputgroup"  >Start Time</span> 
                        <InputTextParent  value={inputValues.dispatchTime} maxLength={5} onChange={(e) =>changeStartTime(e.target.value)} disabled={!inputValues.SetManual} />
                    </div> 
                    <div className="p-inputgroup mbsc-col-lg-6" style={{paddingLeft:".1em", paddingRight:".1em"}}>
                        <span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}} >Set Manual Times</span>
                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('SetManual', e.checked)}  checked={inputValues.SetManual}  />
                    </div> 
                </div>
                
                {inputValues.Released && (<div className='mbsc-row' style={{margin:"0"}}> 
                    <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                         <Button style={{margin:"0",  paddingBottom: ".2em",  justifyContent: "center", padding: ".2em", width:"90%", height:"100%"}}label="Text Dispatch"  onClick={() => textFreight( )} />                    </div>
                   
                        
                        {inputValues.Received ? ( <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                            <span className="p-inputgroup-addon dispatch-inputgroup" >Received Time</span> 
                            <InputTextParent  value={inputValues.ReceivedTime}  onChange={(e) =>   setInputValues((prev) => ({ ...prev, ReceivedTime : e.target.value }))} disabled={true}/>
                        </div>):
                        (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <Button  color="primary" onClick={(e) => markFreightAsRead()}  style={{margin:"0",  paddingBottom: ".2em",  justifyContent: "center", paddingTop: ".2em", width:"90%", height:"100%"}}>Mark Dispatch Read</Button>  
                                </div>)}
                   
                </div>)}
                <div className='mbsc-row' style={{margin:0}}>
                    <Panel className='mbsc-col' header="Note to Driver" style={{padding:".1em"}}>
                        <NoteComponent parent={inputValues} noteType={'driverNote'} quillName={'FBNoteToDriverQuill'} onNoteChange={handleNoteChange} ></NoteComponent>      
                    </Panel>
                </div>

            </div>
        </Dialog>
    )
}
export default FreightPopUp;