
export const useFreightCalculation = (company,expenses) => {
	console.log('expenses in freight calculation = ' ,expenses)
	
	const setQtyandCalc =(FreightBill)=>{
        if (FreightBill.PayType === 'Hour' || FreightBill.PayType === 'Hour/Percent') {
            if (company.payByJobHours || FreightBill.Subhauler){
                FreightBill.paidQty = Number(FreightBill.tHours);
                FreightBill.tHoursPaid= Number(FreightBill.tHours)
            } else FreightBill.paidQty = Number(FreightBill.totalYardHours);
        }
		console.log('FreightBill.tWeight = ' + FreightBill.tWeight);
        console.log('SeFreightBill.loads = ' + FreightBill.loads);
        if (FreightBill.PayType === 'Load' || FreightBill.PayType === 'Load/Percent') FreightBill.paidQty = Number(FreightBill.loads);
        if (FreightBill.PayType === 'Ton' || FreightBill.PayType === 'Ton/Percent') FreightBill.paidQty = Number(FreightBill.tWeight);
        if (FreightBill.BillType === 'Hour') FreightBill.billedQty = Number(FreightBill.tHours);
        if (FreightBill.BillType === 'Load') FreightBill.billedQty = Number(FreightBill.loads);
        if (FreightBill.BillType === 'Ton') FreightBill.billedQty = Number(FreightBill.tWeight);
        console.log('Setting QTy for Frieghtbill with billed qty = ' + FreightBill.billedQty);

        if (company.payByJobHours ||FreightBill.Subhauler) FreightBill.tHoursPaid = FreightBill.tHours; else FreightBill.tHoursPaid = FreightBill.totalYardHours;
        FreightBill.hoursWorked = FreightBill.tHours;
        FreightBill.paidHoursWorked = FreightBill.tHoursPaid;

        return calcTotal(FreightBill);
    }

	const calcHours = (FreightBill) =>{
		if(FreightBill.hoursWorked<FreightBill.tHours){
            FreightBill.hourMinBilledQty=FreightBill.tHours-FreightBill.hoursWorked;
            FreightBill.hourMinBilled = parseFloat(Math.round(Number(FreightBill.BillRate * FreightBill.hourMinBilledQty)*100)/100);
        }else FreightBill.hourMinBilled =0; 
              
        if(FreightBill.paidHoursWorked<FreightBill.tHoursPaid){
            if (FreightBill.PayType.includes('Percent')){
                FreightBill.hourMinPaidQty=FreightBill.tHoursPaid-FreightBill.paidHoursWorked;
                FreightBill.hourMinTotalPaid = parseFloat(Math.round(Number(FreightBill.PayRate * FreightBill.hourMinPaidQty)*100)/100);
                FreightBill.hourMinPaid = parseFloat(Math.round(Number((FreightBill.PayRate * FreightBill.hourMinPaidQty) * Number(FreightBill.driverPercent / 100)))*100)/100;
            }else {
                FreightBill.hourMinPaidQty=FreightBill.tHoursPaid-FreightBill.paidHoursWorked;
                FreightBill.hourMinPaid = parseFloat(Math.round(Number(FreightBill.PayRate * FreightBill.hourMinPaidQty)*100)/100);
                FreightBill.hourMinTotalPaid = FreightBill.hourMinPaid;
            }
        }else{
            FreightBill.hourMinPaid =0;
            FreightBill.hourMinPaidQty =0;
            FreightBill.hourMinTotalPaid =0;
        }
	}

	const calcTruckingPaid = (FreightBill) =>{
		FreightBill.overtimeTotal=0;
		console.log('FreightBill.paidQTy = ' + FreightBill.paidQty)
        if (Number(FreightBill.paidQty)) {
            FreightBill.totalTruckPay = parseFloat(Math.round(Number(FreightBill.PayRate * FreightBill.paidQty)*100)/100);
            if (FreightBill.PayType.includes('Percent')){
                var tempdriverpay = Number(FreightBill.totalTruckPay  * Number(FreightBill.driverPercent / 100));
                FreightBill.totalDriverPay= parseFloat(Math.round(Number(FreightBill.totalTruckPay  * Number(FreightBill.driverPercent / 100))*100)/100);
                
            }else if(FreightBill.PayType === 'Hour' && !FreightBill.Subhauler){
				console.log('FreightBill.startTravelTime = ' + FreightBill.startTravelTime)
                FreightBill.startTravelAndJobTime=Number(FreightBill.paidQty+FreightBill.startTravelTime).toFixed(2);
                FreightBill.JobOvertimeQty=0;
                FreightBill.TravelOvertimeQty=0;
                if(FreightBill.startTravelAndJobTime>8){
                    FreightBill.JobOvertimeQty= parseFloat(Number(Number(FreightBill.startTravelAndJobTime-8)*10)/10);
                    FreightBill.paidQty= 8;
                    FreightBill.totalTruckPay = parseFloat(Math.round(Number(FreightBill.PayRate * FreightBill.paidQty)*100)/100);
                    FreightBill.TravelOvertimeQty=FreightBill.endTravelTime;
               
                }else{
                    FreightBill.totalTime=Number(Number(FreightBill.startTravelAndJobTime) + Number(FreightBill.endTravelTime)).toFixed(1);
                    if(FreightBill.totalTime>8) FreightBill.TravelOvertimeQty=FreightBill.totalTime-8
                }
            
                FreightBill.JobOvertimeTotal= parseFloat(Math.round(Number(FreightBill.JobOvertimeQty*FreightBill.JobOvertimeRate)*100)/100);
                FreightBill.TravelOvertimeTotal= parseFloat(Math.round(Number(FreightBill.TravelOvertimeQty*FreightBill.TravelOvertimeRate)*100)/100);
                FreightBill.overtimeTotal=parseFloat(Math.round(Number(FreightBill.TravelOvertimeTotal+FreightBill.JobOvertimeTotal)*100)/100);
                if( FreightBill.hourMinPaid>0)FreightBill.truckPaid= FreightBill.truckPaid-FreightBill.hourMinPaid;
                
                FreightBill.totalDriverPay= FreightBill.totalTruckPay;
            }else  FreightBill.totalDriverPay= FreightBill.totalTruckPay;
        }else {
			FreightBill.tPaid=0;
			FreightBill.totalDriverPay=0;
			FreightBill.totalTruckPay=0;
		}
	}

	const calcStandBy = (FreightBill) =>{
		if(FreightBill.paidStandExMin && FreightBill.standPR){ 
            FreightBill.standPaid = parseFloat(Math.round(Number(FreightBill.paidStandExMin*  FreightBill.standPR)*100)/100);
            FreightBill.totalStandPaid=parseFloat(Math.round(Number(FreightBill.paidStandExMin*  FreightBill.standPR)*100)/100);
            if(FreightBill.PayType.includes("Percent")) FreightBill.standPaid = parseFloat(Math.round(Number(FreightBill.standPaid) *(Number(FreightBill.driverPercent / 100))*100)/100);
        }else FreightBill.standPaid =0;

        FreightBill.standBilled= parseFloat(Math.round(Number(FreightBill.standExMin*  FreightBill.standBR)*100)/100);
        
        if(!FreightBill.UseStandBy) {
            FreightBill.standBilled= 0;
            FreightBill.standPaid= 0;
        }
	}

	const calcMaterial = (FreightBill)=>{
        if(FreightBill.MaterialRateType==='Ton' || FreightBill.MaterialRateType==='Yard'){
            if(FreightBill.tWeight)  FreightBill.MaterialQty=FreightBill.tWeight;
        }else if(FreightBill.MaterialRateType==='Load')FreightBill.MaterialQty=FreightBill.loads;
        if(FreightBill.MaterialQty && FreightBill.MaterialRate){
            FreightBill.MaterialTotal= parseFloat(Math.round(Number(FreightBill.MaterialQty* FreightBill.MaterialRate)*100)/100);
  
            if(FreightBill.MaterialTaxable && FreightBill.MaterialTaxRate){
                var tax=parseFloat(Math.round(Number(FreightBill.MaterialTotal*(FreightBill.MaterialTaxRate/100))*100)/100);
                FreightBill.MaterialTotal+=tax;
            }
        }
    }

	const calcTotal = (FreightBill) =>{
        console.log('running Calc Total for freight Bill = ', FreightBill)
        if(!FreightBill?.Account)return;
        FreightBill.matTotal = 0; 
        FreightBill.odDiff = FreightBill.odEnd - FreightBill.odStart;

        FreightBill.JobOvertimeRate=parseFloat(Math.round((Number(FreightBill.PayRate)*1.5)*100)/100);
        FreightBill.TravelOvertimeRate=parseFloat(Math.round((Number(FreightBill.travelRate)*1.5)*100)/100);
    
		
		calcHours(FreightBill);

		 //Calculate billed trucking totals
        if (Number(FreightBill.billedQty)){
            FreightBill.tBilled = Number(Number(FreightBill.BillRate) * Number(FreightBill.billedQty));
            FreightBill.tBilled=parseFloat(Math.round(FreightBill.tBilled*100)/100);
        }else FreightBill.tBilled=0;

        //Calculate paid trucking totals
		calcTruckingPaid(FreightBill);
          
        //Calculate Standby
		calcStandBy(FreightBill);
    
        //Calculate Material Fees
        if(FreightBill.SellMaterial)calcMaterial(FreightBill); 
        
        //Calculate Total number to use for % based billing charges
        if (FreightBill.UseStandBy && FreightBill.standBilled>0) FreightBill.totalForBilledPercents=Number(FreightBill.standBilled +FreightBill.tBilled);
        else FreightBill.totalForBilledPercents= FreightBill.tBilled;
        
        
        //Calculate Total number to use for % based paying charges
        if (FreightBill.UseStandBy && FreightBill.standPaid>0)  FreightBill.totalForPaidPercents=Number(FreightBill.standPaid +FreightBill.totalTruckPay );
        else FreightBill.totalForPaidPercents= FreightBill.totalTruckPay ;
    

        //Calculate Fuel Surchage
        if (FreightBill.FuelCharge>0)FreightBill.fuelBilled = Math.round(Number(FreightBill.tBilled  * (FreightBill.FuelCharge / 100))*100)/100;
         else FreightBill.fuelBilled  =0;
          
        //Calculate Billed Broker Fee
        if (FreightBill.billedBrokerPercent>0){
            if(FreightBill.billBrokerFuel)FreightBill.fuelBilled =FreightBill.fuelBilled-  Math.round((Number(FreightBill.fuelBilled  * (FreightBill.billedBrokerPercent / 100)))*100)/100;
            FreightBill.billedTruckingBrokerTotal=   Math.round(-1 * (Number(FreightBill.tBilled  * (FreightBill.billedBrokerPercent / 100)))*100)/100;
            FreightBill.bFee=  Math.round(-1 * (Number(FreightBill.totalForBilledPercents  * (FreightBill.billedBrokerPercent / 100)))*100)/100;
        }else {
            FreightBill.billedTruckingBrokerTotal=0;
            FreightBill.bFee=0;
        }	
        
        //Calculate Paid Broker Fee
        if (FreightBill.paidBrokerPercent>0){
            FreightBill.paidTruckingBrokerTotal=  Math.round(-1 * (Number(FreightBill.totalTruckPay * (FreightBill.paidBrokerPercent / 100)))*100)/100;
            FreightBill.paidBrokerFee = Math.round(-1 * (Number(FreightBill.totalForPaidPercents  * (FreightBill.paidBrokerPercent / 100)))*100)/100;
        }else{
            FreightBill.paidTruckingBrokerTotal=0;
            FreightBill.paidBrokerFee =0;
        } 

        //Calculate Trailer Fees
        if (FreightBill.trailerPercent>0){
            FreightBill.truckingTrailerTotal =  Math.round(-1 * (Number(FreightBill.totalTruckPay* (FreightBill.trailerPercent / 100))*100))/100;
            FreightBill.tFee =   Math.round(-1 * (Number(FreightBill.totalForPaidPercents  * (FreightBill.trailerPercent / 100))*100))/100;
        }else{
            FreightBill.truckingTrailerTotal=0;
            FreightBill.tFee =0;
        }
        
          //Calculate Expenses
        var billedExpenses = 0;
        var paidExpenses = 0;
        for (var i = 0; i < expenses.length; i++) {
            var expenseTotal = 0;
            console.log('bexpenses[i] = ' , expenses[i] );
            if (expenses[i].bill) billedExpenses = parseFloat(Math.round(Number(billedExpenses + expenses[i].total)*100)/100);
            if (expenses[i].pay) {
                expenseTotal = expenses[i].total;
                paidExpenses = parseFloat(Math.round(Number(paidExpenses + expenses[i].total)*100)/100);
                if (expenses[i].applyBrokerFee && FreightBill.paidBrokerPercent > 0) FreightBill.paidBrokerFee -= parseFloat(Math.round(Number(expenseTotal * (FreightBill.paidBrokerPercent / 100))*100)/100);
            }
            console.log('paidExpenses = ' + paidExpenses);
            console.log('billedExpenses = ' + billedExpenses);
        }

        //calculate Travel Pay
        if(FreightBill.travelRate && FreightBill.travelTime && !FreightBill.Subhauler && !FreightBill.PayType.includes('Percent')){
            if(FreightBill.TravelOvertimeQty>0)FreightBill.paidTravelTime=parseFloat(Math.round(Number(FreightBill.travelRate*Number(FreightBill.travelTime-FreightBill.TravelOvertimeQty))*100)/100);
            else FreightBill.paidTravelTime=parseFloat(Math.round(Number(FreightBill.travelRate*FreightBill.travelTime)*100)/100);
        }else FreightBill.paidTravelTime=0;
            
        FreightBill.paidExpenses = paidExpenses;
        FreightBill.billedExpenses = billedExpenses;
        //Calculate final totals and profit
        FreightBill.bTotal = parseFloat(Math.round(Number(Number(FreightBill.tBilled) + Number(FreightBill.matTotal) + Number(FreightBill.fuelBilled) + Number(FreightBill.billedExpenses) + Number(FreightBill.bFee))*100)/100);
        FreightBill.tPaid = parseFloat(Math.round(Number(Number(FreightBill.overtimeTotal) + Number(FreightBill.totalDriverPay) +Number( FreightBill.tFee) +Number( FreightBill.paidBrokerFee) + Number(FreightBill.paidExpenses) +Number(FreightBill.paidTravelTime) )*100)/100);
    
        console.log('p  FreightBill.bTotal= ' +   FreightBill.bTotal);
        console.log('FreightBill.tPaid = ' + FreightBill.tPaid);
    
     //   if(FreightBill.VNum>1 && FreightBill.truckingPaid)this.findAdjustmentDifference(FreightBill);
        if (FreightBill.UseStandBy) {
            FreightBill.tPaid = parseFloat(Math.round(Number(FreightBill.tPaid + FreightBill.standPaid)*100)/100);
            FreightBill.bTotal = parseFloat(Math.round(Number(FreightBill.bTotal + FreightBill.standBilled)*100)/100);
        }   
        if(FreightBill.SellMaterial && FreightBill.MaterialTotal>0){
            FreightBill.bTotal = parseFloat(Math.round(Number(FreightBill.bTotal + FreightBill.MaterialTotal)*100)/100);
        }
        if(FreightBill.Subhauler)FreightBill.hourlyRate = parseFloat(Math.round(Number(FreightBill.tPaid/FreightBill.tHours)*100)/100); 
        else if(FreightBill.totalYardHours) FreightBill.hourlyRate = parseFloat(Math.round(Number(FreightBill.tPaid/FreightBill.totalYardHours)*100)/100);
        FreightBill.profit = parseFloat(Math.round(Number(FreightBill.bTotal - FreightBill.tPaid)*100)/100);
        if(FreightBill.tPaid!==0 || FreightBill.bTotal!==0){
            FreightBill.missing=false;
            FreightBill.onHold = FreightBill.realOnHold ? true : false;
        } 
          
        
        console.log(' this is the end and we are setting the inputvalues = ', FreightBill)
        return FreightBill;
    }


	return {
		calcTotal,
		calcMaterial,
		setQtyandCalc
	};
}; 