
import { useFreightCalculation } from './useFreightCalculation';
export const useFreightTime = (company,expenses,setInputValues) => {


	const {setQtyandCalc} = useFreightCalculation(company,expenses);

    const checkGearedTime = (time) => {
        // Check if time matches HH:MM format and is a valid time
        return /^([01]\d|2[0-3]):[0-5]\d$/.test(time);
    };

	const getDifferenceInMinutes = (start, end) =>{
        console.log('start = ',start)
        console.log('enbd= ',end)
        if(checkGearedTime(start) && checkGearedTime(end)){
            const [startHour, startMinute] = start.split(':').map(Number);
            const [endHour, endMinute] = end.split(':').map(Number);
            const startTotalMinutes = startHour * 60 + startMinute;
            const endTotalMinutes = endHour * 60 + endMinute;
    
            // Calculate the difference in minutes
            let differenceMinutes = endTotalMinutes - startTotalMinutes;
    
            // Adjust for negative differences (e.g., crossing midnight)
            if (differenceMinutes < 0) {
                differenceMinutes += 1440; // Add 24 hours (1440 minutes)
            }
       
          return differenceMinutes;
        }else return 0;
    }
	

    const roundTimeToNearestTenth = ( timeValue,  minuteDifference) => {
       
        console.log('timeValue' + timeValue + ' And the minute difference = ' + minuteDifference);
        minuteDifference=Number(minuteDifference);
        var tMinutes = 0;
        if (minuteDifference === 0)  tMinutes = 0;
        if (minuteDifference > 0 && minuteDifference <= 2)tMinutes = 0;
        if (minuteDifference > 2 && minuteDifference <= 8) tMinutes = .1;
        if (minuteDifference > 8 && minuteDifference <= 14) tMinutes = .2;
        if (minuteDifference > 14 && minuteDifference <= 20) tMinutes = .3;
        if (minuteDifference > 20 && minuteDifference <= 26) tMinutes = .4;
        if (minuteDifference > 26 && minuteDifference <= 32) tMinutes = .5;
        if (minuteDifference > 32 && minuteDifference <= 38) tMinutes = .6;
        if (minuteDifference > 38 && minuteDifference <= 44) tMinutes = .7;
        if (minuteDifference > 44 && minuteDifference <= 50) tMinutes = .8;
        if (minuteDifference > 50 && minuteDifference <= 56) tMinutes = .9;
        if (minuteDifference > 56 && minuteDifference <= 60) tMinutes = 1.0;
        timeValue += Number(tMinutes);
       return Number(timeValue);
      
    }
		
	const calcTime = (start, end) => {
		if (checkGearedTime(start) && checkGearedTime(end)) {
			// Split the time strings into hours and minutes
			const [startHour, startMinute] = start.split(':').map(Number);
			const [endHour, endMinute] = end.split(':').map(Number);

			// Convert hours and minutes into total minutes
			const startTotalMinutes = startHour * 60 + startMinute;
			const endTotalMinutes = endHour * 60 + endMinute;

			// Calculate the difference in minutes
			let differenceMinutes = endTotalMinutes - startTotalMinutes;

			// Adjust for negative differences (e.g., crossing midnight)
			if (differenceMinutes < 0) {
				differenceMinutes += 1440; // Add 24 hours (1440 minutes)
			}

			// Convert total minutes to hours and minutes for rounding
			const totalHours = Math.floor(differenceMinutes / 60);
			const remainingMinutes = differenceMinutes % 60;

			// Return the time rounded to the nearest tenth
			return roundTimeToNearestTenth(totalHours, remainingMinutes);
		}

		// Return 0 if the time strings are not valid
		return 0;
	};

 	const calcExcessStandBy =(freight)=>{
        freight.totalExcessLoad=0;
        freight.totalExcessDump=0;
        freight.totalRoundTrip=0; 
        freight.fullExcessDump=0;
        freight.fullExcessLoad=0;
        var countedTrips=0;
        var countedLoadStandBy=0;
        var countedDumpStandBy=0;
     
        for (let i = 0; i < freight.Weights.length; i++) {
            let calcWeight =freight.Weights[i];
          
            calcWeight.roundTrip=0;
            calcWeight.loadTrip=0;
            calcWeight.dumpTrip=0;
            calcWeight.loadTrip=0;
     
            if( i>0 && calcWeight.loadStart && freight.Weights[i-1].dumpEnd) calcWeight.loadTrip =getDifferenceInMinutes(freight.Weights[i-1].dumpEnd,  calcWeight.loadStart);
            if(calcWeight.loadEnd && calcWeight.dumpStart)calcWeight.dumpTrip =getDifferenceInMinutes(calcWeight.loadEnd, calcWeight.dumpStart);
            console.log(' freight.standDA inside load componenet = '+ freight.standDA);
            if(!freight.standLA)freight.standLA=0;

           // calculate the raw standby
           if(calcWeight.loadStart && calcWeight.loadEnd)  calcWeight.fullExcessLoad= getDifferenceInMinutes( calcWeight.loadStart,   calcWeight.loadEnd ); else  calcWeight.fullExcessLoad=0;
           if(calcWeight.dumpStart && calcWeight.dumpEnd)  calcWeight.fullExcessDump=getDifferenceInMinutes( calcWeight.dumpStart,  calcWeight.dumpEnd ); else  calcWeight.fullExcessDump=0;
         

            if (calcWeight.fullExcessLoad <= freight.standLA) calcWeight.excessLoad = 0;
            if (calcWeight.fullExcessLoad > freight.standLA) calcWeight.excessLoad = calcWeight.fullExcessLoad- freight.standLA;
            freight.totalExcessLoad = parseFloat(Number(freight.totalExcessLoad + calcWeight.excessLoad));

            if (calcWeight.fullExcessDump <= freight.standDA ) calcWeight.excessDump = 0;
            if (calcWeight.fullExcessDump > freight.standDA) calcWeight.excessDump = calcWeight.fullExcessDump - freight.standDA;
            freight.totalExcessDump = parseFloat(Number(freight.totalExcessDump + calcWeight.excessDump));
  
            calcWeight.roundTrip=calcWeight.loadTrip + calcWeight.dumpTrip;
  
            freight.fullExcessLoad+=Number(calcWeight.fullExcessLoad);
            freight.fullExcessDump+=Number(calcWeight.fullExcessDump);

            if(calcWeight.fullExcessLoad !==0 )countedLoadStandBy++;
            if(calcWeight.fullExcessDump !==0 )countedDumpStandBy++;
         
            if(calcWeight.loadTrip !==0 && calcWeight.dumpTrip!==0){
                countedTrips++;
                freight.totalRoundTrip+=Number(calcWeight.roundTrip);   
            }
            delete calcWeight['LoadSite'];
            delete calcWeight['DumpSite'];
        
        }
        if(countedTrips>0) freight.AverageRoundTrip = Math.round(freight.totalRoundTrip/countedTrips);
        if(countedLoadStandBy>0) freight.AverageLoadTime = Math.round(freight.fullExcessLoad/countedLoadStandBy); else freight.AverageLoadTime=0;
        if(countedDumpStandBy>0) freight.AverageDumpTime = Math.round(freight.fullExcessDump/countedDumpStandBy); else  freight.AverageDumpTime=0;
        freight.standExMin = parseFloat(Number((freight.totalExcessLoad ? freight.totalExcessLoad:0) + (freight.totalExcessDump ? freight.totalExcessDump : 0 )));
        freight.paidStandExMin = parseFloat(Number((freight.totalExcessLoad ? freight.totalExcessLoad:0 )+ (freight.totalExcessDump ? freight.totalExcessDump : 0)));
   
        calcStandBy(freight);
    }

	const calcStandBy =function(FreightBill){
        // freightBill.standByIsBilled=false; 
        FreightBill.loadStandPaid= parseFloat(Number(FreightBill.totalExcessLoad*  FreightBill.standPR));
        FreightBill.loadStandBilled=parseFloat(Number(FreightBill.totalExcessLoad*  FreightBill.standBR));
    
        FreightBill.dumpStandPaid=  parseFloat(Number(FreightBill.totalExcessDump*  FreightBill.standPR));
        FreightBill.dumpStandBilled=parseFloat(Number(FreightBill.totalExcessDump*  FreightBill.standBR));
        setInputValues(setQtyandCalc(FreightBill));  
    }


    const calcRunningTime = (FreightBill) => {

        var runningMinutes =getDifferenceInMinutes(FreightBill.departRoundTrip,FreightBill.arriveRoundTrip);
        FreightBill.runningTime= calcTime(FreightBill.departRoundTrip, FreightBill.arriveRoundTrip, );
    
        if(checkGearedTime(FreightBill.endTime)) {
            const [endHour, endMinute] = FreightBill.endTime.split(':').map(Number);
           let totalMinutes = endHour* 60 +endMinute + runningMinutes;
         
           let totalHours = Math.floor(totalMinutes / 60);
           let remainingMinutes = totalMinutes % 60;
           if(remainingMinutes<10)remainingMinutes='0'+remainingMinutes.toString();
           FreightBill.endTime= totalHours.toString()+':'+remainingMinutes.toString();
 
           setInputValues(setQtyandCalc(FreightBill));
        }
    }

	const calcFreightTime = (FreightBill) => {
      
        FreightBill.totalTravelMinutes = 0;
        let travelHour= 0;
        let extraTravelMinutes=0
        console.log('startTimePaid = '+FreightBill.startTimePaid)
        console.log('FreightBill.WEights = ',FreightBill.Weights)
        if(FreightBill.startTime && FreightBill.startTimePaid){
            FreightBill.startTravelTime= calcTime(FreightBill.startTimePaid, FreightBill.startTime);
            FreightBill.travelTime = FreightBill.startTravelTime;
            FreightBill.totalTravelMinutes =getDifferenceInMinutes(FreightBill.startTimePaid,FreightBill.startTime);
            travelHour= Math.floor(Number( FreightBill.totalTravelMinutes) / 60);
            extraTravelMinutes= Number( FreightBill.totalTravelMinutes) % 60;
            FreightBill.travelTime =roundTimeToNearestTenth( travelHour, extraTravelMinutes);
            console.log('startTravelTime = '+FreightBill.startTravelTime)
        }else FreightBill.startTravelTime =0;


        if(FreightBill.endTime && FreightBill.endTimePaid){
            FreightBill.totalTravelMinutes+=getDifferenceInMinutes(FreightBill.endTime,FreightBill.endTimePaid);
            travelHour= Math.floor(Number( FreightBill.totalTravelMinutes) / 60);
            extraTravelMinutes= Number( FreightBill.totalTravelMinutes) % 60;
            FreightBill.endTravelTime= calcTime(FreightBill.endTime, FreightBill.endTimePaid);
            FreightBill.travelTime =roundTimeToNearestTenth( travelHour, extraTravelMinutes);
        }else FreightBill.endTravelTime = 0;


      if(FreightBill.startTimePaid && FreightBill.endTimePaid) FreightBill.totalYardHours = calcTime( FreightBill.startTimePaid, FreightBill.endTimePaid);

        FreightBill.grossHours = calcTime(FreightBill.startTime, FreightBill.endTime );
        FreightBill.tHours = calcTime(FreightBill.startTime, FreightBill.endTime );
		console.log('FreightBill.tHours= ' +FreightBill.tHours);
        console.log('FFreightBill.startTime,= ' +FreightBill.startTime);
		console.log('FFreightBill.endTime,= ' +FreightBill.endTime);
        console.log('eightBill.lunc ' + FreightBill.lunch);
        if (FreightBill.lunch && FreightBill.lunch !== '') {
            if (Number(FreightBill.lunch) >= 60) {
                FreightBill.lunchHours = Math.floor(Number(FreightBill.lunch) / 60);
                FreightBill.lunchRemainder = Number(FreightBill.lunch) % 60;
            } 
            else {
                FreightBill.lunchHours = 0;
                FreightBill.lunchRemainder = Number(FreightBill.lunch);
            }
            
            FreightBill.lunchHours = roundTimeToNearestTenth(FreightBill.lunchHours,  FreightBill.lunchRemainder);
            FreightBill.tHours = Number(Number(FreightBill.tHours) - Number(FreightBill.lunchHours));
            
        }else FreightBill.lunchHours = 0;
        
    
        if (FreightBill.tHours > 8 && FreightBill.BillType === 'Hour') FreightBill.OverTimeAmount = -1 * (8 - FreightBill.tHours);
    
        // FreightBill.tHours=  Number(FreightBill.tHours * 10) / 10;
        if (FreightBill.Company.payByJobHours || FreightBill.Subhauler) FreightBill.tHoursPaid = FreightBill.tHours;
        else FreightBill.tHoursPaid = FreightBill.totalYardHours;
        FreightBill.hoursWorked = FreightBill.tHours;
        FreightBill.paidHoursWorked = FreightBill.tHoursPaid;

		setInputValues(setQtyandCalc(FreightBill));

       
    }

return {
    calcRunningTime,
    calcExcessStandBy,
    calcFreightTime,
	checkGearedTime,
	calcStandBy,
	getDifferenceInMinutes
  };
}; 
