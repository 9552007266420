import React, { createContext, useContext, useEffect, useState,useCallback, useRef } from 'react';
import {  useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail} from "firebase/auth";
import { auth, db } from '../firebase';

import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { doc, getDoc, query, setDoc, collection, updateDoc, onSnapshot, addDoc, deleteDoc } from 'firebase/firestore';

import { parseIIFCustomers, parseIIFItems, parseIIFVendors } from '../utils/iifParser';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [gearedUser, setGearedUser] = useState(null);

    const storage = getStorage();

    const [truckTypes, setTruckTypes] = useState([]);
    const [materials, setMaterials] = useState([]);
    const [organizationNames, setOrganizationNames] = useState([]);
    const [trucks, setTrucks] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [outsideDrivers, setOutsideDrivers] = useState([]);
    const [contacts, setContacts] = useState([]);
	const [employees, setEmployees] = useState([]);
    const [compliances, setCompliances]= useState([]);
    const [outsideTrucks, setOutsideTrucks] = useState([]);
    const [subhaulers, setSubhaulers] = useState([]);
    const [capabilities, setCapabilities] = useState([]); 
	const [fuelCards, setFuelCards] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [companies, setCompanies] = useState([]);

    const [quickbooks, setQuickbooks] = useState([]); 
	const [quickbooksCustomers, setQuickbooksCustomers] = useState([])
	const [quickbooksVendors, setQuickbooksVendors] = useState([])
	const [quickbooksItemList, setQuickbooksItemList] = useState([]);
	

    const [company, setCompany] = useState({});
    const [materialCompany, setMaterialCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [gearedVersion, setGearedVersion] = useState('2.726');
    const [refreshCount, setRefreshCount] = useState(0);


    
    const [driverComplianceNames, setDriverComplianceNames] = useState([]);
    const [truckComplianceNames, setTruckComplianceNames] = useState([]);
    const [trailerComplianceNames, setTrailerComplianceNames] = useState([]);
    const [expenseNames, setExpenseNames] = useState([]);

    const checkingMapsAPIRef = useRef(null);
    const autocompleteServiceRef = useRef(null);
    const companiesRef= useRef(null);
    const quickbooksRef= useRef(null);

      
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    
        console.log('AuthContext loading state:', loading); 
        return () => {
            setLoading(false);
            unsubscribe();
        };
    }, []);

    const checkGoogleMapsAPI = () =>{

        if(!autocompleteServiceRef.curent && !checkingMapsAPIRef.current){
            checkingMapsAPIRef.current=true;
            if (!window.google) {
                const script = document.createElement('script');
                console.log('creating new google maps api');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBLRfT0lk65I2sQ7nJaHVWddKclD6ohiHI&libraries=places`;
                script.async = true;
                script.defer = true;
                script.onload = () => {
                
                    autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
                };
                document.head.appendChild(script);
            } else {
                console.log('creating new google maps api when its not null' ,window.google.maps);
              //  autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
                waitForPlacesLibrary();  // Wait until places is ready
            }
        }
    }


    const waitForPlacesLibrary = () => {
        const checkPlacesLibrary = setInterval(() => {
          
            if (window.google && window.google.maps && window.google.maps.places) {
                console.log('Places library is now available');
                clearInterval(checkPlacesLibrary);
                autocompleteServiceRef.current = new window.google.maps.places.AutocompleteService();
            }
        }, 500); // Check every 500ms until available
    };
    const makeSelectItem = useCallback((gearedItem, id) => {
        gearedItem.text = gearedItem.Name ? gearedItem.Name : gearedItem.DriverName;
  
        gearedItem.value = id;
        gearedItem.ID = id;
        return gearedItem;
    },[]);

    
    const makeLocationSelectItem = useCallback((gearedItem, id) => {
        gearedItem.text = gearedItem.Name;
        gearedItem.value = id;
        gearedItem.locSelect=true;
        gearedItem.ID = id;
        return gearedItem;
    },[]);

    const fetchDefaultNames = (selectedOrgName) => {
        const q = query(collection(db, `Organizations/${selectedOrgName}/DefaultNames`));
        onSnapshot(q, (querySnapshot) => {
        const driverComplianceNamesTemp = [];
        const truckComplianceNamesTemp = [];
        const trailerComplianceNamesTemp = [];
        const expenseNamesTemp = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            const item = makeSelectItem(data, doc.id);
        
            switch (data.Type) {
            case 'DriverCompliance':
                driverComplianceNamesTemp.push(item);
                break;
            case 'TruckCompliance':
                truckComplianceNamesTemp.push(item);
                break;
            case 'TrailerCompliance':
                trailerComplianceNamesTemp.push(item);
                break;
            case 'Expense':
                expenseNamesTemp.push(item);
                break;
            default:
                break;
            }
        });

        setDriverComplianceNames(driverComplianceNamesTemp);
        setTruckComplianceNames(truckComplianceNamesTemp);
        setTrailerComplianceNames(trailerComplianceNamesTemp);
        setExpenseNames(expenseNamesTemp);
        });
    };

    const fetchAccounts = (selectedOrgName)=>{
        let newSubhaulers = [];
        const q = query(collection(db, `Organizations/${selectedOrgName}/Accounts`));
        onSnapshot(q, (querySnapshot) => {
        setAccounts(prevAccounts => {
            let newAccounts = [...prevAccounts]; // Handle all accounts
        // Handle subhauler accounts separately

            querySnapshot.docChanges().forEach((change) => {
                const newItem = makeSelectItem(change.doc.data(), change.doc.id);
                    switch (change.type) {
                        case 'added':
                            newAccounts.push(newItem);
                            // If the account is a Subhauler, add it to subhaulers
                            if (newItem.Subhauler)   newSubhaulers.push(newItem);
                            break;
                        case 'modified':
                            // Update the accounts array
                            newAccounts = newAccounts.map(account => account.ID === change.doc.id ? newItem : account );
                            // Update the subhaulers array if it's a subhauler
                            if (newItem.Subhauler)  newSubhaulers = newSubhaulers.map(subhauler => subhauler.ID === change.doc.id ? newItem : subhauler );
                            break;
                        case 'removed':
                            // Remove from accounts
                            newAccounts = newAccounts.filter(account => account.ID !== change.doc.id);
                            // Remove from subhaulers if it's a subhauler
                            if (newItem.Subhauler)   newSubhaulers = newSubhaulers.filter(subhauler => subhauler.ID !== change.doc.id);
                            break;
                        default:
                            break;
                    }
            });

            // Update both accounts and subhaulers
            console.log('subhauelrs update d an d new subhas = ', newSubhaulers)
            setSubhaulers(newSubhaulers);
            return newAccounts;
        });
    });
    }
    const fetchCollectionData = (collectionPath, setState, transformer, filter = () => true) => {
        const q = query(collection(db, collectionPath));
        onSnapshot(q, (querySnapshot) => {
        const changes = querySnapshot.docChanges().map((change) => {
            const newItem = transformer(change.doc.data(), change.doc.id);
            return { type: change.type, item: newItem };
        });
        setState(prevState => {
            let data = [...prevState];
            changes.forEach(({ type, item }) => {
            if (filter(item)) {
                switch (type) {
                case 'added':
                    data.push(item);
                    break;
                case 'modified':
                    data = data.map(prevItem => prevItem.ID === item.ID ? item : prevItem);
                    break;
                case 'removed':
                    data = data.filter(prevItem => prevItem.ID !== item.ID);
                    break;
                }
            }
            });
            return data;
        });
        });
    };

    const logout = () => {
        console.log('Logging out...');
        setTimeout(() => window.location.reload(), 500); 
        return signOut(auth);
    };

    const getCompany = async (selectedOrgName) => {
        console.log('Getting the company for selectedOrgName = ' + selectedOrgName);
        const q = query(collection(db, 'Organizations/' + selectedOrgName + '/Preferences'));
        companiesRef.current = [];
        onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
            let tempCompany = change.doc.data();
            tempCompany.ID = change.doc.id;
            tempCompany.Name= tempCompany.CompanyName;
            console.log('findin the comapnie = ', tempCompany);
            switch (change.type) {
            case 'added':
                if (tempCompany.mainCompany) setCompany(tempCompany);
                if (tempCompany.MaterialCompany) setMaterialCompany(tempCompany);
                companiesRef.current.push(tempCompany);
                break;
            case 'modified':
                companiesRef.current = companiesRef.current.map(company => company.ID === change.doc.id ? tempCompany : company);
                if (tempCompany.mainCompany) setCompany(tempCompany);
                break;
            case 'removed':
                companiesRef.current = companiesRef.current.filter(company => company.ID !== change.doc.id);
                break;
            default:
                break;
            }
        });
        console.log('and we are setting compaineis= ', companiesRef.current)
        setCompanies([...companiesRef.current]);
        });
    };
    const getQuickbooks = async (selectedOrgName) => {
        console.log('Getting the quickbooks for selectedOrgName = ' + selectedOrgName);
        const q = query(collection(db, 'Organizations/' + selectedOrgName + '/QuickBooks'));
        quickbooksRef.current = [];
        if(selectedOrgName!=='Andrade Trucking'){
        onSnapshot(q, (querySnapshot) => {
        
        querySnapshot.docChanges().forEach((change) => {
            let tempQuickbooks = change.doc.data();
            tempQuickbooks.ID = change.doc.id;
            console.log('findin the quickbooks = ', tempQuickbooks);
            switch (change.type) {
            case 'added':
                quickbooksRef.current.push(tempQuickbooks);
                break;
            case 'modified':
                quickbooksRef.current = quickbooksRef.current.map(qb =>qb.ID === change.doc.id ? tempQuickbooks : qb);
                break;
            case 'removed':
                quickbooksRef.current = quickbooksRef.current.filter(qb => qb.ID !== change.doc.id);
                break;
            default:
                break;
            }
        });
        console.log('and we are setting quickbooks= ', quickbooksRef.current)
        setQuickbooks([...quickbooksRef.current]);
        });
    } else setQuickbooks([]);
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const resetPassword = (email) =>{
        return sendPasswordResetEmail(auth, email);
    }
    const fetchUserData = async (userId) => {
        const docRef = doc(db, "users", userId);
        const docSnap = await getDoc(docRef);
    
        if (docSnap.exists()) {
            const tempUser = docSnap.data();
			tempUser.PhoneNumber ?  window.heap.identify(tempUser.PhoneNumber) : tempUser.Email ? window.heap.identify(tempUser.Email) : window.heap.identify('unknown')
            tempUser.OptIn = true;
            tempUser.selectedOrgName = tempUser.selectedOrgName || "John's Trucking";
            localStorage.setItem('currentOrg', tempUser.selectedOrgName);
            
            setGearedUser(tempUser);  // 🔹 Ensure gearedUser is set
            await setDoc(docRef, tempUser);
            return tempUser.selectedOrgName;
        }
    
        setGearedUser(null);  // 🔹 Prevent stuck loading if user data isn't found
        return null;
    };

    const handleAuthStateChanged = async (currentUser) => {
        console.log('Auth state changed:', currentUser);
        
        if (currentUser) {
			if(currentUser.email){
				setUser(currentUser);
				const selectedOrgName = await fetchUserData(currentUser.uid);
				if (selectedOrgName) getDropDowns(selectedOrgName);
				getVersion();
		
				// ✅ Ensure gearedUser is set before allowing pages to load
				setLoading(false);
			}else {
				console.log('navigating to alianzatechnologies')
				window.location.href = 'https://geared.alianzatechnologies.com';
			}
        } else {
			console.log('renavigating to login')
            setUser(null);
            setGearedUser(null);
            setLoading(false);
            navigate('/');
        }
    };
    
    const getDropDowns = (selectedOrgName) => {
        fetchAccounts(selectedOrgName);
        getCompany(selectedOrgName);
        getQuickbooks(selectedOrgName);
        fetchCollectionData(`Organizations/${selectedOrgName}/Drivers`, setDrivers, makeSelectItem, (data) => {
            if (data.Subhauler) {
               
                setOutsideDrivers((prevOutsideDrivers) => {
                    // Check if driver already exists
                    const existingIndex = prevOutsideDrivers.findIndex(driver => driver.ID === data.ID);
                    if (existingIndex !== -1) {
                        // Update existing driver
                        const updatedDrivers = [...prevOutsideDrivers];
                        updatedDrivers[existingIndex] = makeSelectItem(data, data.ID);
                        return updatedDrivers;
                    } else {
                        // Add new driver
                        return [...prevOutsideDrivers, makeSelectItem(data, data.ID)];
                    }
                });
            }
            return !data.Subhauler; // Add to drivers array if Subhauler = false
        });
        fetchCollectionData(`Organizations/${selectedOrgName}/OutsideTrucks`,  setOutsideTrucks,makeSelectItem );
        setLoading(false);
		getQuickbooksIIF(selectedOrgName)
        setTimeout(() => {
            fetchCollectionData(`Organizations/${selectedOrgName}/Trucks`,  setTrucks,  makeSelectItem );
     
            fetchCollectionData(`Organizations/${selectedOrgName}/TruckTypes`, setTruckTypes,makeSelectItem );
            fetchCollectionData(`Organizations/${selectedOrgName}/Materials`,  setMaterials,makeSelectItem);
            fetchCollectionData(`Organizations/${selectedOrgName}/Locations`, setLocations,makeLocationSelectItem  );
        
            fetchCollectionData(`Organizations/${selectedOrgName}/Trailers`,   setTrailers, makeSelectItem );
            fetchCollectionData(`Organizations/${selectedOrgName}/Contacts`,   setContacts, makeSelectItem );
            fetchCollectionData(`Organizations/${selectedOrgName}/Employees`,   setEmployees, makeSelectItem );
            fetchCollectionData(`Organizations/${selectedOrgName}/Compliances`,   setCompliances, makeSelectItem );
			fetchCollectionData(`Organizations/${selectedOrgName}/FuelCards`,   setFuelCards, makeSelectItem );
            fetchCollectionData(`Organizations/${selectedOrgName}/Capabilities`,  setCapabilities,(data, id) => {
                data.ID=id;
                data.text = data.Name;
                data.value = data.Name;
                return data;
            });
        
            fetchDefaultNames(selectedOrgName);
        }, 500); 
       
    };

    const getVersion = async () => {
        var firstTime = true;
        console.log('Getting versions of Geared! Refresh count = ' + refreshCount);

        const docRef = doc(db, "DevVersions", "Version");
        onSnapshot(docRef, async (docSnap) => {
        const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
        if (docSnap.exists() && source === "Server") {
            if (gearedVersion !== docSnap.data().Version) {
            setRefreshCount(prev => prev + 1);
            localStorage.setItem('refreshCount', refreshCount);
            if (refreshCount < 5) {
                setTimeout(() => window.location.reload(), 3000);
            }
            } else {
            localStorage.setItem('refreshCount', 0);
            }
            if (firstTime) firstTime = false;
        }
        });
    };

    const updateGearedUser = async (tempGearedUser) => {
        if (gearedUser.ID) {
        if (gearedUser.Name !== tempGearedUser.Name || gearedUser.selectedOrgName !== tempGearedUser.selectedOrgName) {
            gearedUser.Name = tempGearedUser.Name;
            gearedUser.selectedOrgName = tempGearedUser.selectedOrgName;
            localStorage.setItem('currentOrg', tempGearedUser.selectedOrgName);
            getDropDowns(tempGearedUser.selectedOrgName);
            setGearedUser(gearedUser);
            let docRef = doc(db, "users", gearedUser.ID);
            await updateDoc(docRef, { Name: tempGearedUser.Name, selectedOrgName: tempGearedUser.selectedOrgName });
        }
        }
    };

    const loginWithConfirmationCode = (confirmationResult, code, phoneNumber) => {
        confirmationResult.confirm(code).then((result) => {
        const user = result.user;
        }).catch((error) => {
        console.error('Error signing in with confirmation code:', error);
        });
    };

    const addNewAccount =(Name, fieldName, fieldChange)=>{
        var Account = {
        ID: '',
        Qty: '', 
        PayFrequency: 'Bi-Weekly',
        Name: Name,
        Priority: '',

        Fax: '',
        Website: '',
        Address: '',
        City: '',
        State: '',
        ZipCode: '',
        Email:'',
        PhysicalAddress: '',
        PhysicalAddressName: '',
        PhysAddress: '',
        PhysCity: '',
        PhysState: '',
        PhysZipCode: '',
        Phone: '',
            PhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
        OfficePhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
        DedicatedSubhauler: false,
        TaxID: '',
        Track1099: false,
        DriverName:'',
        DriverEmail:'',

        Broker: false,
        Subhauler: false,
        Puller: false,
        Contractor: false,
        phoneOK:false,
        Status: 'Active',

        BrokerFee: '',
        TrailerFee: '',
        PaidBrokerFee: '',
        paidTruckingBrokerTotal:0,
        Notes: '',
        InvoiceNotes: '',
        TermsAndCond: '',
        ShowPhysical: false,

        Username: '',
        QBID: '',
        QBSync: '',
        QBVendorID: '',
        QBVendorSync: '',

        Contacts: [],
        TruckTypes: [],
        Trailers: [],
        Capabilities:[],
        ComplianceNames: [],
        Compliances: [],
        Trucks: [],
        Quickbooks:[],
        NoteList:[],
        Driver: {
            ID: '',
            Truck: {
                ID: '',
                Name: 'No Truck',
            },

            Trailer: {
                ID: '',
                Name: 'No Trailer',
            }
        }
        }

        

        for(var q=0; q<companies.length; q++){
            if (companies[q].realmID) {
                var newQuickBooks = {
                    ID: companies[q].ID,
                    CompanyName: companies[q].CompanyName,
                    realmID: companies[q].realmID,
                    QBCustomerID: '',
                    QBCustomerSync: '',
                    QBVendorID: '',
                    QBVendorSync: '',
                    QBUpdated: false
                };
                Account.Quickbooks.push(newQuickBooks);
            }
        }
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Accounts`), Account).then(function (docRef) {
        console.log("Account written with ID: ", docRef.id);
        Account.ID=docRef.id;
        fieldChange(fieldName,Account, true )
        });

    }
    const addNewLocation = (locType, Name,  fieldChange)=>{
        console.log('adding new location first thing id k??')
        let Location = {
            ID: '',
            Name: Name,
            Address: '',
            Address2:'',
            State:'',
            City: '',
            ZipCode: '',
            fullAddress:'',
            Plant:false
        }

        Location.text= Location.Name;
        Location.locSelect=true;
    
        console.log('add new locations = ', Location)
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Locations`),Location).then(function (docRef) {
        console.log("Location written with ID: ", docRef.id);
        Location.ID=docRef.id;
        Location.value=docRef.id;
        return fieldChange(locType,Location, true )
        });
    }
    const createLocationSummary = (selectValue, googleMapsLoc=false)=>{
        return {
            ID:selectValue.ID,
            Name:selectValue.Name,
            Address:selectValue.Address ? selectValue.Address : '',
            Address2:selectValue.Address2 ? selectValue.Address2 : '',
            City:selectValue.City ? selectValue.City : '',
            State:selectValue.State ? selectValue.State : '',
            ZipCode:selectValue.ZipCode ? selectValue.ZipCode : '',
            fullAddress:selectValue.fullAddress ? selectValue.fullAddress : '',
            locSelect:true,
            googleMapsLoc:googleMapsLoc
        }
    }
    const addNewGoogleLocation = (locType, selectValue,  fieldChange)=>{
        var Location = {...selectValue}
        Location.ZipCode='';
        Location.fullAddress = Location.Address + ', ' + Location.City + ', ' + Location.State + ', ' + Location.ZipCode;
        Location.Address2= Location.City + ', ' + Location.State + ', ' + Location.ZipCode;
        Location.text= Location.Name;
        Location.locSelect=true;
        Location.googleMapsLoc=true;
        for(var i=0; i<locations.length; i++){
        if(locations[i].place_id===Location.place_id){
            return fieldChange(locType, createLocationSummary(locations[i], true), true )
        }
        }
        console.log('add new locations = ', Location)
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Locations`),Location).then(function (docRef) {
        console.log("Location written with ID: ", docRef.id);
        Location.ID=docRef.id;
        Location.value=docRef.id;
        return fieldChange(locType,createLocationSummary(Location, true), true )
        });
    }
    const addNewMaterial = (Name,fieldChange)=>{
    var Material = {
        ID: '',
        Name: Name,
        YardsPerTon: ''
    }
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Materials`), Material).then(function (docRef) {
        console.log("Material written with ID: ", docRef.id);
        Material.ID=docRef.id;
        fieldChange('Material',Material, true )
        });
    }
    const addNewTruckType = (Name, fieldChange)=>{
        var TruckType = {
        ID: '',
        Name: Name,
        TruckCode: '',
        DefaultRate: '',
        WeekendRate: '',
        NightRate: '',
        NumOfAxles: '',
        CapacityTons: '',
        CapacityYards: '',
        Default:false
    };
    
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/TruckTypes`), TruckType).then(function (docRef) {
        console.log("trucktype written with ID: ", docRef.id);
        TruckType.ID=docRef.id;
        fieldChange('TruckType',TruckType, true)
        });
    }

   
    const addNewTrailer = (Name, fieldChange)=>{
        let Trailer = {
            ID: '',
            Name: Name,
            Make:'',
            Model:'',
            License:'',
            Year:'',
            VIN:'',
            Status:'Active',
            Compliances:[]
        }
    
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Trailers`), Trailer).then(function (docRef) {
        console.log("trailer written with ID: ", docRef.id);
        Trailer.ID=docRef.id;
        fieldChange('Trailer',Trailer, true)
        });
    }
    const addNewTruck = (Name, fieldChange)=>{
        let Truck = {
            ID: '',
            Name: Name,
            VIN:'',
            License:'',
            Make:'',
            Model:'',
            Year:'',
            Status:'Active',
            Compliances:[]
        }
    
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/Trucks`), Truck).then(function (docRef) {
        console.log("truck written with ID: ", docRef.id);
        Truck.ID=docRef.id;
        fieldChange('Truck',Truck, true)
        });
    }
    const addNewDefaultName = (Name,fieldChange, defaultNameType)=>{
        var DefaultName = {
        ID: '',
        Name: Name,
        Type: defaultNameType
    }
        console.log('adding new dfault name =', DefaultName)
        addDoc(collection(db, `Organizations/${gearedUser.selectedOrgName}/DefaultNames`), DefaultName).then(function (docRef) {
        console.log("Default Name written with ID: ", docRef.id);
        DefaultName.ID=docRef.id;
        fieldChange('Name',DefaultName )
        });
    }
    const addDocument = (object, collectionName)=>{
    
        let addDocRef =   collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/'+collectionName);  
        return addDoc(addDocRef, object).then(function (docRef) {
            console.log("collectionName document written with ID: ", docRef.id);
            object.ID = docRef.id;
            return docRef.id; // Return the ID from the promise
        });
    }

    const setDocument = (setObject, id, collectionName)=>{ 

        setObject.timestamp=Date.now();
        let setDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/'+collectionName, id);
      
        setDoc(setDocRef, setObject)  
        .then(()=>{   console.log('successfully SET AN object with id = ' + id + ' for the colleciton = '+ collectionName +' and the setObject =' , setObject)})
        .catch((error)=>{
            console.log('COULD NOT UPDATE OBJECT with id = ' + id + ' for the colleciton = '+ collectionName +' and the setObject =' , setObject);
            console.log('and the error given = ', error);
        })
    }   
    const updateDocument = (updateObject, id, collectionName)=>{ 
     
        updateObject.timestamp=Date.now();
        
        let updateDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/'+collectionName, id);
        if(collectionName==='users')updateDocRef =  doc(db, 'users/', id);
        return updateDoc(updateDocRef, updateObject)
        .then(()=>{   console.log('successfully updated object with id = ' + id + ' for the colleciton = '+ collectionName +' and the updateobject =' , updateObject)})
        .catch((error)=>{
            console.log('COULD NOT UPDATE OBJECT with id = ' + id + ' for the colleciton = '+ collectionName +' and the updateobject =' , updateObject);
            console.log('and the error given = ', error);
        })
    }   
   
    const addAuditLog = (auditType, objectType, objectID, value)=>{
        const secondsSinceEpoch = Math.round(Date.now() / 1000);
        let fullDate = new Date();

       fullDate =fullDate.toLocaleString("en-US");
       const auditLogRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/AuditLogs/'+objectType+'/'+objectID, secondsSinceEpoch.toString());
      
        var newAuditLog={
          User:gearedUser.Email,
          ID:secondsSinceEpoch,
          DateAndTime: fullDate,
          ActivityType:auditType, 
          ObjectType:objectType,
          Value: value
        }

        setDoc(auditLogRef, newAuditLog).then(()=>{   console.log('successfully updated audit log' , newAuditLog)})
        .catch((error)=>{
            console.log('failed updateing audit log for ' , newAuditLog);
            console.log('and the error given = ', error);
        })
    
    
    
      }
    const deleteDocument = async (deletedObject, collectionName) => {
        try {
        console.log('trying to dlete = ', deletedObject)
            await deleteDoc(doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/' + collectionName, deletedObject.ID))
                .then(() => {
                    console.log(`Successfully deleted document from ${collectionName}`);
                })
                .catch((error) => {
                    console.error(`Error removing document from ${collectionName}: `, error);
                });
        } catch (error) {
            console.error(`Error executing deleteDocument for ${collectionName}: `, error);
        }
    };

    function formatDate(date, divider, format) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)  month = '0' + month;
        if (day.length < 2)  day = '0' + day;

        if(format==='YYYY/MM/DD')return [year, month, day].join(divider);
        else return [month, day,year].join(divider);
    }
  
    if (!Number.prototype.formatMoney) {
        Number.prototype.formatMoney = function (c = 2, d = ".", t = ",") {
            let roundNum = this + 0.000001;
            const n = Math.abs(roundNum).toFixed(c);
            const s = roundNum < 0 ? "-" : "";
            const i = parseInt(n.split(".")[0], 10).toString();
            const j = i.length > 3 ? i.length % 3 : 0;
    
            return (
                s +
                (j ? i.substr(0, j) + t : "") +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
                (c ? d + n.split(".")[1] : "")
            );
        };
    }
    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
            return false;
        }
    
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
    
        if (keys1.length !== keys2.length) return false;
    
        for (const key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
        }
    
        return true;
    };

	const getQuickbooksIIF = async (selectedOrgName) => {
	
		getQuickbooksCustomers(selectedOrgName);
		getQuickbooksVendors(selectedOrgName);
		getQuickbooksItemList(selectedOrgName);
	}
	const getQuickbooksCustomers = async (selectedOrgName) => {
		try {
			const storageRef = ref(storage, `attachments/${selectedOrgName}/Quickbooks/CustomerList.IIF`);
			const url = await getDownloadURL(storageRef);
			await mapCustomerData(url);
		} catch (error) {
			// Check if it's a "file not found" error
			if (error.code === 'storage/object-not-found') {
				console.log(`No QuickBooks customer data found for ${selectedOrgName} - this is expected for some organizations`);
				setQuickbooksCustomers([]); // Set empty array instead of leaving it undefined
			} else {
				// Log actual errors
				console.error('Error accessing QuickBooks customer data:', error);
			}
		}
	}
	const getQuickbooksVendors = async (selectedOrgName) => {
		try {
			const storageRef = ref(storage, `attachments/${selectedOrgName}/Quickbooks/VendorList.IIF`);
			const url = await getDownloadURL(storageRef);
			await mapVendorData(url);
		} catch (error) {
			// Check if it's a "file not found" error
			if (error.code === 'storage/object-not-found') {
				console.log(`No QuickBooks Vendor data found for ${selectedOrgName} - this is expected for some organizations`);
				setQuickbooksVendors([]); // Set empty array instead of leaving it undefined
			} else {
				// Log actual errors
				console.error('Error accessing QuickBooks Vendor data:', error);
			}
		}
	}
	const getQuickbooksItemList = async (selectedOrgName) => {
		try {
			const storageRef = ref(storage, `attachments/${selectedOrgName}/Quickbooks/ItemList.IIF`);
			const url = await getDownloadURL(storageRef);
			await mapItemData(url);
		} catch (error) {
			// Check if it's a "file not found" error
			if (error.code === 'storage/object-not-found') {
				console.log(`No QuickBooks item data found for ${selectedOrgName} - this is expected for some organizations`);
				setQuickbooksItemList([]); // Set empty array instead of leaving it undefined
			} else {
				// Log actual errors
				console.error('Error accessing QuickBooks item data:', error);
			}
		}
	}

	const mapItemData = async (url) => {
		try{
			// Fetch the file content
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			
			// Get the text content
			const data = await response.text();
			console.log('IIF file loaded, length:', data.length);
			
			// Parse the IIF data
			const parsedItems = parseIIFItems(data);
			setQuickbooksItemList(parsedItems)
			
			// Sort the items
			console.log('Parsed Items:', parsedItems.length);
			if (parsedItems.length > 0) {
			console.log('First item:', parsedItems[0]);
			}
			
			const sorted = [...parsedItems].sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
			return sorted;
		} catch (error) {
			console.error('Error loading item data:', error);
				console.error('Error details:', error.message);
		}
	};
	const mapCustomerData = async (url) => {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			
			const data = await response.text();
			console.log('IIF file loaded, length:', data.length);
			
			const parsedCustomers = parseIIFCustomers(data);
			setQuickbooksCustomers(parsedCustomers);
			
			// Filter out customers that have exact matches in accounts
		
		} catch (error) {
			console.error('Error loading customer data:', error);
			console.error('Error details:', error.message);
		}
	};
	const mapVendorData = async (url) => {
		try {
			const response = await fetch(url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			
			const data = await response.text();
			console.log('IIF file loaded, length:', data.length);
			
			const parsedVendors = parseIIFVendors(data);
			setQuickbooksVendors(parsedVendors);
			
			// Filter out Vendors that have exact matches in accounts
		
		} catch (error) {
			console.error('Error loading Vendor data:', error);
			console.error('Error details:', error.message);
		}
	};
  return (
    <UserContext.Provider value={{
      signInWithEmailAndPassword,resetPassword, loginWithConfirmationCode,  signOut, signIn, logout, user,  gearedUser, updateGearedUser, autocompleteServiceRef, loading,   
      companies, company, organizationNames, driverComplianceNames, truckComplianceNames,trailerComplianceNames, expenseNames, formatMoney: Number.prototype.formatMoney, 
      accounts, subhaulers, contacts, employees, drivers, outsideDrivers, outsideTrucks, truckTypes, trucks, trailers, locations, materials, capabilities,compliances, fuelCards,
      addDocument, updateDocument, setDocument, deleteDocument,addAuditLog, formatDate,deepEqual,quickbooks, materialCompany,checkGoogleMapsAPI,setDrivers,
      addNewAccount,addNewMaterial,addNewTruckType, addNewLocation,addNewGoogleLocation,  addNewTruck, addNewTrailer, addNewDefaultName, setLoading,
	  quickbooksItemList, setQuickbooksItemList, mapItemData,
	  quickbooksCustomers, setQuickbooksCustomers, mapCustomerData,
	  quickbooksVendors, setQuickbooksVendors, mapVendorData,
       
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};