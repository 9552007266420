import React, {useState,useContext,useRef, createContext,useEffect} from 'react'
import { db } from '../../../firebase';
import { doc,  query,  collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import { usePrevailingWage } from './PrevailingWageContext';





const PrevailingWageReportContext = createContext();
export const PrevailingWageReportContextProvider = ({ children }) => {
    const { gearedUser } = UserAuth();
    const { prevailingWageReport, setPrevailingWageReport } = usePrevailingWage();

	const [freightBills, setFreightBills]= useState(null);
    const [reportWeeks, setReportWeeks] = useState([]);


    const reportWeeksRef = useRef(null);

	const prevailingWageReportRef = useRef(null);
    const prevailingWageListenerRef = useRef(null);

    const prevailingWageWeeksListenerRef = useRef(null);

	const jobFreightBillListenerRef = useRef([]);
	const freightBillsRef = useRef([]);


    console.log('prevailingWageReport on context rerender= ', prevailingWageReport)

  
	useEffect(()=>{
        prevailingWageReportRef.current=prevailingWageReport;
    },[prevailingWageReport])

    useEffect(()=>{
        reportWeeksRef.current=reportWeeks;
    },[reportWeeks])


    const fetchPrevailingWageReport = async (id) => {
      
    	return new Promise((resolve, reject) => {
          
			if (prevailingWageListenerRef.current) prevailingWageListenerRef.current();
            console.log('the id = ' + id)
       
			console.log('gearedUser.selectedOrgName = ' + gearedUser.selectedOrgName)
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`, id);
            prevailingWageListenerRef.current = onSnapshot(docRef, async (docSnap) => {
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('source =' + source)
                if (source === "Server") {
                    let tempPrevailingWageReport = docSnap.data();
                    tempPrevailingWageReport.ID = docSnap.id;
					if(!tempPrevailingWageReport.Jobs)tempPrevailingWageReport.Jobs=[tempPrevailingWageReport.ID]
                    tempPrevailingWageReport.prevailEndDateValue= tempPrevailingWageReport.prevailEndDate ? new Date(tempPrevailingWageReport.prevailEndDate) :
                    tempPrevailingWageReport.EndDate ? new Date(tempPrevailingWageReport.EndDate) : '';
                    tempPrevailingWageReport.prevailStartDateValue= tempPrevailingWageReport.prevailStartDate ? new Date(tempPrevailingWageReport.prevailStartDate) :
                    tempPrevailingWageReport.StartDate ? new Date(tempPrevailingWageReport.StartDate) : '';
                    tempPrevailingWageReport.SignatureDateValue = tempPrevailingWageReport.SignatureDate ? new Date(tempPrevailingWageReport.SignatureDate) : new Date();
					prevailingWageReportRef.current = {...tempPrevailingWageReport};
					console.log('we are setting the prevailing wagge report now = ', tempPrevailingWageReport)
                    setPrevailingWageReport({ ...tempPrevailingWageReport});
					resolve();
                }

			});
        });
    };
	const fetchPrevailingWageWeeks = (id) =>{
		return new Promise((resolve, reject) => {
			if (prevailingWageWeeksListenerRef.current) prevailingWageWeeksListenerRef.current();
			reportWeeksRef.current = [];
			const queryName = `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports/${id}/Weeks`;
			const q = query(collection(db, queryName));
			prevailingWageWeeksListenerRef.current = onSnapshot(q, (querySnapshot) => {
				querySnapshot.docChanges().forEach((change) => {
					const tempWeek = change.doc.data();
					let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
					console.log(' source = '+ source);
					console.log('found at emp week=  ', tempWeek);
				
					if (change.type === "added") {
						tempWeek.ID = change.doc.id;
						if(tempWeek.TotalFreightCount===0)tempWeek.nonPerformance=true;
						for(let i=0; i<reportWeeksRef?.current?.length; i++)console.log('reportWeeksRef.current[i].ID = '+ reportWeeksRef.current[i].ID)
						const weekIndex =  reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);

						if(weekIndex!==-1)reportWeeksRef.current[weekIndex] = tempWeek;
						else reportWeeksRef.current.push(tempWeek);
					}
					if (change.type === "modified") {
						const weekIndex =  reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);
						reportWeeksRef.current[weekIndex] = tempWeek;
					}
					if (change.type === "removed") {
						const weekIndex = reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);
						reportWeeksRef.current.splice(weekIndex,1);
					}
		
					
				});
				console.log('reportWeeksRef.current = ' , reportWeeksRef.current);
				reportWeeksRef.current=reportWeeksRef.current;
				setReportWeeks([...reportWeeksRef.current])
			
				resolve();
			});
		});
	}

 

	const queryFreightBills = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				// Clear existing listeners
				if (jobFreightBillListenerRef.current.length > 0) {
					jobFreightBillListenerRef.current.forEach(unsubscribe => unsubscribe());
					jobFreightBillListenerRef.current = [];
				}
				
				freightBillsRef.current = [];
				
				// Create array of promises for each job query
		
				const jobPromises = prevailingWageReportRef.current.Jobs.map(jobID => 
					queryFreightBillsForJob(jobID)
				);
				
				// Wait for ALL job queries to complete
				await Promise.all(jobPromises);
				console.log('All freight bills loaded, total count:', freightBillsRef.current.length);
				resolve();
			} catch (error) {
				console.error('Error in queryFreightBills:', error);
				reject(error);
			}
		});
	};

	const queryFreightBillsForJob = (jobID) => {
		return new Promise((resolve, reject) => {
			try {
				console.log('Starting query for jobID:', jobID);
				const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
				const freightJobQuery = query(collection(db, queryName), where("JobID", "==", jobID));
				
				const unsubscribe = onSnapshot(freightJobQuery, (querySnapshot) => {
					const source = querySnapshot.metadata.hasPendingWrites ? "Local" : "Server";
					if (source === "Server") {
						querySnapshot.docChanges().forEach((change) => {
							const tempFreight = change.doc.data();
							tempFreight.ID = change.doc.id;
							
							if (change.type === "added") {
								freightBillsRef.current.push(tempFreight);
							}
							if (change.type === "modified") {
								const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
								freightBillsRef.current[jobIndex] = tempFreight;
							}
							if (change.type === "removed") {
								const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
								freightBillsRef.current.splice(jobIndex, 1);
							}
						});
						
						jobFreightBillListenerRef.current.push(unsubscribe);
						console.log(`Completed query for jobID: ${jobID}, current total: ${freightBillsRef.current.length}`);
						resolve();
					}
				}, reject);
			} catch (error) {
				console.error(`Error in queryFreightBillsForJob for ${jobID}:`, error);
				reject(error);
			}
		});
	};


    return (
        <PrevailingWageReportContext.Provider value={{
           	reportWeeksRef, reportWeeks, setReportWeeks,
		   	fetchPrevailingWageWeeks, fetchPrevailingWageReport,setPrevailingWageReport, prevailingWageReportRef,prevailingWageReport,
			queryFreightBills, freightBills, setFreightBills, freightBillsRef,
		
      
        }}>
            {children}
        </PrevailingWageReportContext.Provider>
    );
}
export const usePrevailingWageReport= () => {
    return useContext(PrevailingWageReportContext);
};