

import React, {useRef} from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import FreightBillHome from './Home/FreightBillHome';
import FreightBillSearch from './Search/FreightBillSearch';
import DispatchFreights from './DispatchFreights/DispatchFreights';
import FreightBillEdit from './Edit/FreightBillEdit';

import {FreightBillHomeContextProvider} from './context/FreightBillHomeContext';
import {DispatchFreightContextProvider} from './context/DispatchFreightContext';
import {FreightBillEditContextProvider} from './context/FreightBillEditContext';

import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react'; 
import { Toast } from 'primereact/toast';





function FreightBillParent() {
    const formatDate = (date, divider, format) =>{
        var d = new Date(date),
        month = '' + (d.getMonth() + 1), 
        day = '' + d.getDate(),
        year = d.getFullYear();
    
    if (month.length < 2)  month = '0' + month;
    if (day.length < 2)  day = '0' + day;
    
    if(format==='YYYY/MM/DD')return [year, month, day].join(divider);
    else return [month, day,year].join(divider);
}

    let todaysDate = new Date();
    todaysDate =    encodeURIComponent(formatDate(todaysDate, '/', 'YYYY/MM/DD'));

  
    console.log('FREIGHT B RENDERING')

    const toast = useRef(null);

 
return ( 
    <Page>
        <React.Fragment>
        <Toast ref={toast} />
			<FreightBillHomeContextProvider>
                    <Routes>
						<Route path="dashboard" element={<Navigate to={`/freightbill/dashboard/${todaysDate}`} replace />} />
						<Route path='dashboard/:freightHomeDate' element={<ProtectedRoute><FreightBillHome/></ProtectedRoute>} />
					</Routes>
				</FreightBillHomeContextProvider>
				<DispatchFreightContextProvider>
					<Routes>
                        <Route path='freights/:id' element={<ProtectedRoute><DispatchFreights/></ProtectedRoute>} />
					</Routes>
				</DispatchFreightContextProvider>
				<FreightBillEditContextProvider>
					<Routes>
                        <Route path='freightbill/:id/:dispatchID' element={<ProtectedRoute><FreightBillEdit toast={toast}/></ProtectedRoute>} />
                    </Routes>
				</FreightBillEditContextProvider>
					<Routes>
                        <Route path='search' element={<ProtectedRoute><FreightBillSearch/></ProtectedRoute>} />
					</Routes>
					

        </React.Fragment>
    </Page>
  );
}

export default FreightBillParent;