
import React, {useEffect, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

import { Checkbox } from 'primereact/checkbox';
const DispatchDatePopUp = ({setDispatchDatePopUpVisible, dispatchDatePopUpVisible, dispatchDate, formatDate, moveOrCopyDispatch }) => {


    const [inputValues, setInputValues] = useState({});

    const closeDispatchDatePopUp = () => {
        setDispatchDatePopUpVisible(false);
    };

    useEffect(() => {
        if (dispatchDate && Object.keys(dispatchDate).length > 0) {
            console.log('dispatchDate  ' ,dispatchDate )
            setInputValues({
                JobDate:dispatchDate.JobDate,
                JobDateValue:dispatchDate.JobDateValue,
                actionType:dispatchDate.actionType
            });
           
        }
    }, [dispatchDate]);

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };


    const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
            <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => setDispatchDatePopUpVisible(false)} />
            <Button style= {{fontSize:'1.5em', width:'9em'}} label={inputValues.actionType} icon="pi pi-check"  onClick={() => moveOrCopyDispatch(inputValues) }/>
        </div>
    
    );
return(
    <Dialog header="Dispatch Date" visible={dispatchDatePopUpVisible} style={{ width: '40vw' }} breakpoints={{ '1400px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeDispatchDatePopUp}>
            <div style={{  paddingLeft:"5em", paddingRight:"5em", marginTop:'2em', marginBottom:'2em'}} className='mbsc-grid'>
            <div className="p-inputgroup mbsc-col-xl-8 mbsc-offset-xl-2"  >
                <span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}} >Job Date </span> 
                <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.JobDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'JobDate',e.value )} />
            </div>
            <div className="p-inputgroup mbsc-col-xl-8 mbsc-offset-xl-2" style={{paddingTop:"1em"}}>
                    <span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}}>Copy Drivers:</span>
                    <Checkbox style={{ width: '100%' }} onChange={e => setInputValues((prev) => ({ ...prev, copyDrivers: e.checked}))}   checked={inputValues.copyDrivers}  />
                </div>
            </div> 
    

                  
    </Dialog>
);
};

export default DispatchDatePopUp;