import React, {useState,useContext,useRef, createContext,useEffect} from 'react'
import { db } from '../../../firebase';
import { doc,  query, updateDoc, collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import { usePrevailingWage } from './PrevailingWageContext';
import { useNavigate } from 'react-router-dom';
import { useDriverCalculations } from '../hooks/useDriverCalculations';
import { useOwnerOpCalculations } from '../hooks/useOwnerOpCalculations';



const PrevailingWageReportContext = createContext();
export const PrevailingWageReportContextProvider = ({ children }) => {
    const { gearedUser, drivers, subhaulers,  formatDate,} = UserAuth();
    const {  prevailingWageReport, setPrevailingWageReport, setIsLoading} = usePrevailingWage();

  
    const [reportDrivers, setReportDrivers]= useState([]);
    const [reportWeeks, setReportWeeks] = useState([]);
    const [reportWeek, setReportWeek]=useState({});
    const navigate = useNavigate();



    const weekFreightBillsRef = useRef(null);
    const weekFreightBillListenerRef = useRef(null);
    const reportWeekRef = useRef(null);
    const reportWeeksRef = useRef(null);
	const prevailingWageReportRef = useRef(null);
    const prevailingWageListenerRef = useRef(null);
    const prevailingWageWeeksListenerRef = useRef(null);

    const { calculateDriverTotals } = useDriverCalculations();
    const { calculateOwnerOpTotals } = useOwnerOpCalculations();

    console.log('prevailingWageReport on context rerender= ', prevailingWageReport)

    useEffect(()=>{
        reportWeekRef.current=reportWeek;
    },[reportWeek])
	useEffect(()=>{
        prevailingWageReportRef.current=prevailingWageReport;
    },[prevailingWageReport])

    useEffect(()=>{
        reportWeeksRef.current=reportWeeks;
    },[reportWeeks])


    const fetchPrevailingWage = async (id) => {
        return new Promise((resolve, reject) => {
            let reportLoaded = false;
            let weeksLoaded = false;

            if (prevailingWageWeeksListenerRef.current) prevailingWageWeeksListenerRef.current();
            if (prevailingWageListenerRef.current) prevailingWageListenerRef.current();
        
            reportWeeksRef.current = [];
            console.log('reportWeeksRef.current B4= ' + reportWeeksRef.current.length)
            
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`, id);
            prevailingWageListenerRef.current = onSnapshot(docRef, async (docSnap) => {
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('source =' + source)
                if (source === "Server") {
                    let tempPrevailingWageReport = docSnap.data();
                    tempPrevailingWageReport.ID = docSnap.id;
                    tempPrevailingWageReport.prevailEndDateValue= tempPrevailingWageReport.prevailEndDate ? new Date(tempPrevailingWageReport.prevailEndDate) :
                    tempPrevailingWageReport.EndDate ? new Date(tempPrevailingWageReport.EndDate) : '';
                    tempPrevailingWageReport.prevailStartDateValue= tempPrevailingWageReport.prevailStartDate ? new Date(tempPrevailingWageReport.prevailStartDate) :
                    tempPrevailingWageReport.StartDate ? new Date(tempPrevailingWageReport.StartDate) : '';
                    tempPrevailingWageReport.SignatureDateValue = tempPrevailingWageReport.SignatureDate ? new Date(tempPrevailingWageReport.SignatureDate) : new Date();
					prevailingWageReportRef.current = {...tempPrevailingWageReport};
                    setPrevailingWageReport({ ...tempPrevailingWageReport});
                    reportLoaded = true;
                    if (reportLoaded && weeksLoaded) resolve();
                }
            });
            
            const queryName = `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports/${id}/Weeks`;
            const q = query(collection(db, queryName));
            prevailingWageWeeksListenerRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const tempWeek = change.doc.data();
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    console.log(' source = '+ source);
                    console.log('found at emp week=  ', tempWeek);
                   
                    if (change.type === "added") {
                        tempWeek.ID = change.doc.id;
                        console.log('  tempWeek.ID  = '+   tempWeek.ID )
                        if(tempWeek.TotalFreightCount===0)tempWeek.nonPerformance=true;
                        for(let i=0; i<reportWeeksRef?.current?.length; i++)console.log('reportWeeksRef.current[i].ID = '+ reportWeeksRef.current[i].ID)
                        const weekIndex =  reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);
                        console.log(' weekIndex  = '+   weekIndex )
                        if(weekIndex!==-1)reportWeeksRef.current[weekIndex] = tempWeek;
                        else reportWeeksRef.current.push(tempWeek);
                    }
                    if (change.type === "modified") {
                        const weekIndex =  reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);
                        reportWeeksRef.current[weekIndex] = tempWeek;
                    }
                    if (change.type === "removed") {
                        const weekIndex = reportWeeksRef.current.findIndex(d => d.ID === tempWeek.ID);
                        reportWeeksRef.current.splice(weekIndex,1);
                    }
         
                    
                });
                console.log('reportWeeksRef.current = ' , reportWeeksRef.current);
                reportWeeksRef.current=reportWeeksRef.current;
                setReportWeeks([...reportWeeksRef.current])
                weeksLoaded = true;
                if (reportLoaded && weeksLoaded) resolve();
            });
        });
    };
  
    const updateReportWeekDocs = (reportWeek)=>{
        let tempReportWeek = {...reportWeek};
        
        let updateDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/PrevailingWageReports/'+prevailingWageReport.ID+'/Weeks/', reportWeek.ID);
        updateDoc(updateDocRef,tempReportWeek);
    }


    
    const createReportWeek = (reportWeek, tempPrevailingWageReport)=>{
        const weekFreightBills = weekFreightBillsRef.current;
        console.log('tempPRevailignWAGepreopt.prevailingWageRate= ',  tempPrevailingWageReport)
        // Process drivers
        drivers.forEach(driver => {
			console.log('driver = ', driver)
            const calculatedDriver = calculateDriverTotals(  
				driver,  
				weekFreightBills, 
				tempPrevailingWageReport.ID,   
				tempPrevailingWageReport.PrevailingWageRate ? tempPrevailingWageReport.PrevailingWageRate : tempPrevailingWageReport.Rate ? tempPrevailingWageReport.Rate : 0 ,
				tempPrevailingWageReport.OvertimePrevailingWageRate ? tempPrevailingWageReport.OvertimePrevailingWageRate : 0
			);

            if (calculatedDriver.projectPaid !== 0) {
                const existingDriverIndex = reportWeek.Drivers.findIndex(d => d.ID === calculatedDriver.ID);
                
                if (existingDriverIndex !== -1) {
                    // Update only calculated fields for existing drivers
                    const existingDriver = reportWeek.Drivers[existingDriverIndex];
                    reportWeek.Drivers[existingDriverIndex] = {
                        ...existingDriver,
                        projectPaid: calculatedDriver.projectPaid,
                        hoursPerDay: calculatedDriver.hoursPerDay,
						overtimePerDay: calculatedDriver.overtimePerDay,
                   
                    };
                } else {
                    // Add new driver with all fields
                    reportWeek.Drivers.push(calculatedDriver);
                }
            }
        });

        for (let q = 0; q < subhaulers.length; q++) {
            const calculatedOwnerOp = calculateOwnerOpTotals(
                subhaulers[q],
                weekFreightBills,
                tempPrevailingWageReport.ID,
                tempPrevailingWageReport.OwnerOpPrevailingWageRate
            );

            if (calculatedOwnerOp.addToReport) {
                const existingOwnerOpIndex = reportWeek.OwnerOps.findIndex(o => o.ID === calculatedOwnerOp.ID);
                if (existingOwnerOpIndex !== -1) {
					console.log('calculatedOwnerOp = ', calculatedOwnerOp)
					const existingOwnerOp= reportWeek.OwnerOps[existingOwnerOpIndex];
                    reportWeek.OwnerOps[existingOwnerOpIndex] = {
                        ...existingOwnerOp,
                        projectPaid: calculatedOwnerOp.projectPaid,
                        hoursPerDay: calculatedOwnerOp.hoursPerDay
      
                    };
                  
                } else {
                    reportWeek.OwnerOps.push(calculatedOwnerOp);
                }
            }
        }
		console.log('reportWeek after createing = ', reportWeek)
		tempPrevailingWageReport.createEmployeeDocs= reportWeek.Drivers.length>0 || false;
		tempPrevailingWageReport.createOwnerOpDocs= reportWeek.OwnerOps.length>0 || false;
		console.log('tempPrevailingWageReport  after checking lengths= ', tempPrevailingWageReport)
        setPrevailingWageReport({...tempPrevailingWageReport});
        setReportWeek({...reportWeek});
        setIsLoading(false);
        return reportWeek;
    }
    const queryWeekFreightBills = (reportWeek, tempPrevailingWageReport)=>{
			setIsLoading(true);
            weekFreightBillsRef.current = [];
			let hasReceivedServerData = false;
            if ( weekFreightBillListenerRef.current)  weekFreightBillListenerRef.current();
            let startDate = reportWeek.weekStartDate;
            let endDate = reportWeek.weekEndDate;
            let encodedDate=  encodeURIComponent(formatDate(startDate,'/','YYYY/MM/DD'));
            if(prevailingWageReport?.ID) navigate('/prevailingwage/reportcreate/'+ prevailingWageReport.ID+'/'+encodedDate)
            console.log('we are now querying the freight bills for the week of ' + startDate + ' to ' + endDate+ ' and gearedUser.selcedted organe m - ' + gearedUser.selectedOrgName)
            const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            weekFreightBillListenerRef.current = onSnapshot(q, { includeMetadataChanges: true   },(querySnapshot) => {
				querySnapshot.docChanges().forEach((change) => {
					let tempFB = change.doc.data(); 
					tempFB.ID = change.doc.id;
				
					if (change.type === "added")  weekFreightBillsRef.current.push(tempFB);
					
					if (change.type === "modified") { 
						const freightIndex = weekFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
						weekFreightBillsRef.current[freightIndex] = tempFB;
					}
					if (change.type === "removed") {
						const freightIndex = weekFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
						weekFreightBillsRef.current.splice(freightIndex, 1);
					}
				});
				if(!reportWeek.Drivers && tempPrevailingWageReport.Drivers)reportWeek.Drivers = [...tempPrevailingWageReport.Drivers];
				if(!reportWeek.OwnerOps && tempPrevailingWageReport.OwnerOps)reportWeek.OwnerOps = [...tempPrevailingWageReport.OwnerOps]
				console.log('weekFreightBillsRef.current =- ', weekFreightBillsRef.current);
			
			
				if (!querySnapshot.metadata.fromCache && !hasReceivedServerData) {
                    hasReceivedServerData = true;
					createReportWeek(reportWeek, tempPrevailingWageReport);
                  
                }
        });
            
    }

    return (
        <PrevailingWageReportContext.Provider value={{
            queryWeekFreightBills,reportWeek, setReportWeek, reportWeeksRef,fetchPrevailingWage, reportWeekRef ,reportWeeksRef,
            reportDrivers, setReportDrivers,reportWeeks, setReportWeeks,updateReportWeekDocs,weekFreightBillsRef,prevailingWageReportRef,
      
        }}>
            {children}
        </PrevailingWageReportContext.Provider>
    );
}
export const usePrevailingWageReport= () => {
    return useContext(PrevailingWageReportContext);
};