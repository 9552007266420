

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';


import InvoiceCreate from './InvoiceCreate';
import InvoicePopUp from './InvoicePopUp';
import InvoiceEdit from './InvoiceEdit';
import InvoiceHome from './InvoiceHome';
import InvoiceHomeQuickbooks from './InvoiceHomeQuickbooks';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { useInvoice } from './context/InvoiceContext';
import { usePrint } from './context/PrintContext';


import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';

import { Button } from 'primereact/button';



function InvoiceParent() {

    const { invoiceRef} = useInvoice();
    const {printFBs, setPrintFBs,  printButtonLabel, attachFreightBills,closePrintPopUp,printTags, setPrintTags, showPrintTags,  printVisible, printFBImages, setPrintFBImages, showPrintFBImages } = usePrint();
    console.log('FINVOICE BILL PARENT RE RENDERING')

    const toast = useRef(null);

     const printFooterContent = (
   
     	<div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
            <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => closePrintPopUp()} />
            <Button style= {{fontSize:'1.5em', width:'9em'}} label={printButtonLabel} icon="pi pi-check" onClick={() => attachFreightBills( invoiceRef.current)}  />
        </div>
     
     );
return ( 
    <Page>
        <React.Fragment>
        <Toast ref={toast} />
        <InvoicePopUp />    
        <Routes>
            <Route path='list' element={<ProtectedRoute><InvoiceHome/></ProtectedRoute>} /> 
            <Route path='listQuickbooks' element={<ProtectedRoute><InvoiceHomeQuickbooks/></ProtectedRoute>} /> 
            <Route path='create' element={<ProtectedRoute><InvoiceCreate/></ProtectedRoute>} />  
            <Route path='invoice/:id' element={<ProtectedRoute><InvoiceEdit/></ProtectedRoute>} /> 
        </Routes>
        <Dialog header="Print Details" visible={printVisible} style={{ width: '60vw' }} footer={printFooterContent} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={closePrintPopUp}>
            <div className=' mbsc-row mbsc-grid' style={{height:"4em"}}>
                <div className="p-inputgroup mbsc-col-lg-3 " style={{fontSize:'1.3em', }}>
                    <span className="p-inputgroup-addon " style={{flexBasis:"90%"}} >Print Freight Bills:</span>
                    <Checkbox style={{ height:'3em'  }} onChange={e => setPrintFBs(e.checked)}   checked={printFBs}  />
                </div>  
                {showPrintFBImages && (<div className="p-inputgroup mbsc-col-lg-4 mbsc-offset-lg-1" style={{fontSize:'1em'}}>
                    <span className="p-inputgroup-addon" style={{flexBasis:"90%"}}>Print Attached Freight Bill Images:</span>
                    <Checkbox style={{ height:'3em'  }} onChange={e => setPrintFBImages(e.checked)}   checked={printFBImages}  />
                </div>  )}
                {showPrintTags && (<div className="p-inputgroup mbsc-col-lg-3 mbsc-offset-lg-1" style={{fontSize:'1.3em'}}>
                    <span className="p-inputgroup-addon" style={{flexBasis:"90%"}}>Print Attached Scale Tags:</span>
                    <Checkbox style={{ height:'3em'  }} onChange={e => setPrintTags(e.checked)}   checked={printTags}  />
                </div>  )}
            </div>
        </Dialog>
        </React.Fragment>
    </Page>
  );
}

export default InvoiceParent;