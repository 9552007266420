import {  useCallback} from 'react';
import { doc, writeBatch} from 'firebase/firestore';
import { db } from '../../../firebase';
import { UserAuth } from '../../../context/AuthContext';


export const useJobData = (job, jobRef, jobDispatches, setJob, setDispatch, dispatch) => {
	const { gearedUser, formatDate } = UserAuth();


	const updateJobFields = useCallback(async (fields, values, updateDispatches) => {

        setJob(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
          });
        
      
        const batch = writeBatch(db);
        const updateObject = {};
        fields.forEach((field, index) => {
          updateObject[field] = values[index];
        });

        updateObject.timestamp=Date.now();
        batch.update(jobRef,  updateObject);
        console.log('updateObject for when updating job fields= ', updateObject);
        if(updateDispatches) {
            for( let j=0; j<jobDispatches.length; j++){
                if(dispatch.ID===jobDispatches[j].ID) {
                    setDispatch(prevState => {
                        let newState = { ...prevState };
                        for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
                        return newState;
                    });
                }
                let dispatchRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Dispatches',   jobDispatches[j].ID);
                batch.update(dispatchRef, updateObject);
            }
         
        }
        await batch.commit();
       
    },  [job] );
      
    const updateJobField = useCallback(async (fieldName, value, updateDispatches) => {

        setJob((prev) => ({ ...prev,  [fieldName]:  value }));
        console.log('updating the job field = ' + fieldName + ' and value = ' , value );
        const batch = writeBatch(db);
        batch.update(jobRef, { [fieldName]: value });
        if(updateDispatches) {
            for( let j=0; j<jobDispatches.length; j++){
                let dispatchRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Dispatches',   jobDispatches[j].ID);
                if(dispatch?.ID===jobDispatches[j].ID) setDispatch((prev) => ({ ...prev, [fieldName]: value }));
                batch.update(dispatchRef, {"timestamp": Date.now(), [fieldName]: value });
            }
        
        }
        await batch.commit();
       
    },  [job, dispatch] );



  return { updateJobFields, updateJobField };
};