import React from 'react';
import NumberCell from './NumberCell';

const TableCell = ({ width, isNumber, value, borderStyle, notCurrency, dataLabel, isPercent = false }) => {
    if(!borderStyle)borderStyle='1px 1px 1px 1px ';
  //  console.log('FOR TABLE CELL WIDTH = ' + width)
  return (
    <td data-label={dataLabel} style={{ width: width, padding: '0', borderWidth: borderStyle, textAlign:'center',borderColor:'#bcbcd1', borderStyle:'solid' }}>
      {isNumber ? <NumberCell number={value} notCurrency={notCurrency}  isPercent={isPercent}/> : <span >{value}</span>}
    </td>
  );
};

export default TableCell;