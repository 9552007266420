import React, { useEffect, useRef} from 'react';

import { useGlobal } from '../../../context/GlobalContext';
import {useDispatch } from '../context/DispatchContext';
import { UserAuth } from '../../../context/AuthContext';
import DispatchEdit from './Views/DispatchEdit';
import JobEdit from './Views/JobEdit';
import DispatchStatus from './Views/DispatchStatus';

import JobPrelim from './Views/JobPrelim';
import { db } from '../../../firebase';
import { doc,    query,  collection,  onSnapshot, where, orderBy, limit } from 'firebase/firestore';
import { useNavigate,useParams} from 'react-router-dom';
import { usePrevailingWageData } from '../hooks/usePrevailingWageData';

import DispatchPrintPopUp from '../PopUps/DispatchPrintPopUp';
import { useDispatchPrintContext } from '../context/DispatchPrintContext';

function JobPage(props) {
    const navigate = useNavigate();
    const { dispatchState, setDispatchState, setDispatch, dispatch, job, setJob, setJobDispatches, queryFreightBills, homeFreightBills, 
        checkReleaseDispatch, checkCancelDispatch, unCancelDispatch, jobDispatchesRef,homeDate, setHomeDate, dispatchObjectRef,
         queryExpenses,  setExpensePopUpVisible,handleFreightClick, openDispatchDatePopUp, formatDate} = useDispatch();
    const {company, gearedUser,  updateDocument,deleteDocument,truckTypes, materialCompany, addAuditLog} =  UserAuth();
    const { id, jobID, jobDate, dispatchView } = useParams();
    const { setGlobalDispDate, globalDispDate ,setTopMenuOptions} = useGlobal();
	const { createDispatchPDF, setShowEmail, dispatchPrintPopUpVisible, setDispatchPrintPopUpVisible } = useDispatchPrintContext();

	const { handlePrevailingWageReportCreate} = usePrevailingWageData();
    const defaultTruckType = truckTypes ? truckTypes.filter(trucktype => trucktype.Default): {Name:'No Truck Type', ID:''};
    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch?.ID);
    
    const dispatchStatusFreightBills = dispatchFreightBills.sort((a, b) => a.loadOrder - b.loadOrder);
 
    const jobDispatchListenerRef = useRef(null); // Store the unsubscribe function

    
    const jobRef = useRef();
    const dispatchFreightBillsRef = useRef(null);
    const encodedDate = jobDate ? encodeURIComponent(jobDate) : encodeURIComponent(homeDate); 
    useEffect(() => {
        if(id){
            console.log('ID NOW EQUALS =' +id)
            if(!dispatch) fetchDispatch(id);else if(dispatch.ID!==id)  fetchDispatch(id);
            queryExpenses(id);
        
        }
    },[id]);

   
    useEffect(() => {
        console.log('run if the jobID has changed!! '+jobID)
		console.log('current jobid  = ' + job?.ID)
        if(jobID){
      
            if(!job || !jobDispatchesRef.current){
                fetchJob(jobID); 
                queryJobDispatches(id,jobID); 
            }else if(job.ID!==jobID){
                fetchJob(jobID);
                queryJobDispatches(id, jobID);
            }
        }else if(company) newJob();
     
    }, [jobID, company]);

    useEffect(() => {
        console.log('run if thedispatchView has changed!!'+dispatchView)
        if(dispatchView) setDispatchState(dispatchView); 
    }, [dispatchView]);

    useEffect(()=>{
        if(dispatch){
            let viewItems=[];
            console.log('runnig the use effect to set the topmenu options and when that happens  home date  = '+ encodedDate)
            if(dispatchState!=='DispatchEdit')viewItems.push( {label:'View Dispatch',function:(e) =>  navigate('/dispatch/edit/'+ id+'/'+jobID+'/'+encodedDate+'/DispatchEdit'), icon:'pi pi-map'})
            if(dispatchState!=='JobEdit') viewItems.push( {label:'View Job', function:(e) =>  navigate('/dispatch/edit/'+ id+'/'+jobID+'/'+encodedDate+'/JobEdit'), icon:"pi pi-building"})
            if(dispatchState!=='DispatchStatus')viewItems.push( {label:'View Status', function:(e) =>  navigate('/dispatch/edit/'+ id+'/'+jobID+'/'+encodedDate+'/DispatchStatus'), icon:"pi pi-map-marker"})
            if(dispatchState!=='JobPrelim')viewItems.push( {label:'View Prelim', function:(e) => navigate('/dispatch/edit/'+ id+'/'+jobID+'/'+encodedDate+'/JobPrelim'), icon:"pi pi-file"})
            console.log('running remake options ')
            let dispatchItems=[];
            if(!dispatch?.Released || dispatch?.isChanging) dispatchItems.push( {label: 'Release Dispatch',function:(e) =>  checkReleaseDispatch( dispatchObjectRef.current,dispatchFreightBillsRef.current),  icon: 'pi pi-send',})
            else  if(dispatch?.Released && !dispatch.Cancelled)  dispatchItems.push( {label: 'Change Dispatch',function:(e) =>  changeDispatch(), icon: 'pi pi-pencil',})
      
            let tempItems=[
			
                {label: 'Copy Dispatch',function:(e) => openDispatchDatePopUp('Copy' ),icon: 'pi pi-copy'},
                {label: 'Move Dispatch',function:(e) => openDispatchDatePopUp('Move'),icon: 'pi pi-directions'},
                {label: 'Delete Dispatch',function:(e) => deleteDispatch(), icon:'pi pi-times-circle'},
                {label: 'Print Dispatch',function:(e) => showPrintPopUp(false), icon:'pi pi-print'},
				{label: 'Copy Job',function:(e) => openDispatchDatePopUp('Copy Job' ),icon: 'pi pi-plus-circle'},
            ];

            dispatchItems=dispatchItems.concat(tempItems);

            if(dispatch?.Released){
                if(!dispatch.Cancelled) dispatchItems.push({ label: 'Cancel Dispatch',function:(e) =>checkCancelDispatch(), icon:'pi pi-exclamation-circle'})
                else  dispatchItems.push({ label: 'Uncancel Dispatch',function:(e) =>unCancelDispatch( ), icon:'pi pi-exclamation-circle'})
            }
       
           let dispatchColor='';
           if(dispatch.Released){
                if(dispatch.Cancelled)dispatchColor='#d93025';
                else if(dispatch.isChanging) dispatchColor='#62a8f5';
                else dispatchColor='#c5ddba';
           }
            setTopMenuOptions([{
                label: 'View',
                fontAwesomeIcon:'faMagnifyingGlass',
                items: viewItems
            },{
                label: 'Dispatch Tools',
                backgroundColor:dispatchColor,
                fontAwesomeIcon:'faGear',
                items: dispatchItems
            },{
                label: 'Show Expenses',
           
                function:(e) => setExpensePopUpVisible(true),
                backgroundColor:dispatchColor,  
                fontAwesomeIcon:'faMoneyCheckDollar'
             
            },])
        }
    },[dispatch?.Released, dispatch?.isChanging, id, dispatchState])

    useEffect(() => {
        dispatchObjectRef.current = dispatch; // Keep the ref update
    }, [dispatch]);

    useEffect(() => {
        dispatchFreightBillsRef.current = dispatchFreightBills; // Keep the ref updated
    }, [dispatchFreightBills]);

    useEffect(() => {
        console.log('jOB daTE =! '+ jobDate+ ' oh and homeDate = ' + homeDate + ' OH AND GLOBAL HOME DATE = '+ globalDispDate)
        if(jobDate){
            if(jobDate!==homeDate){
                console.log('qeurey fbs right')
                setGlobalDispDate(jobDate)
                setHomeDate(jobDate);
                queryFreightBills(jobDate, jobDate);
            }
        }
    }, [jobDate]);
	const showPrintPopUp = (email) => {
		setShowEmail(email);
		setDispatchPrintPopUpVisible(true);
	}
	const handlePrintDispatch = () => {
		createDispatchPDF(dispatchObjectRef.current, dispatchFreightBillsRef.current);
		setDispatchPrintPopUpVisible(false);
	}
    const fetchDispatch = async (id) => {
        return new Promise((resolve, reject) => {
            console.log('WE ARE RUNNIGN TEH FETCH DISPATCH')
            if (id === dispatch?.ID) return resolve(dispatch);
          
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, id);
            onSnapshot(docRef, async (docSnap) => {
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
    
                if (docSnap.exists() && source === "Server") {
                    let tempDispatch = docSnap.data();
                    tempDispatch.ID=docSnap.id;
                    setDispatch({ ...tempDispatch });
                    dispatchObjectRef.current={ ...tempDispatch };
                    return resolve(tempDispatch);
                }
            });
        });
    };
    const fetchJob = async (id) => {
      return new Promise((resolve, reject) => {
        console.log('WE ARE RUNNIGN TEH FETCH JOBH' + id)
      
        if(jobRef.current) if (job?.ID === id) return resolve(jobRef.current);
        
          const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Jobs`, id);
          onSnapshot(docRef, async (docSnap) => {
              const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
              if (docSnap.exists() && source === "Server") {
           
                  let tempJob = docSnap.data();
                  tempJob.ID=docSnap.id;
                  if(!tempJob.dispatchIds)tempJob.dispatchIds=[];
                  jobRef.current = tempJob;
                  console.log('SETTING JOB REF = TO TEMP JOB = ', tempJob)
                  setJob({ ...tempJob });
                  return resolve(tempJob);
              }
          });
      });
    };
     
    const queryJobDispatches = (dispID,jobID)=>{
   
        if (jobDispatchListenerRef.current) jobDispatchListenerRef.current();
        jobDispatchesRef.current=[];
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
        const q = query(collection(db, queryName), where("Job.ID", "==", jobID), orderBy("QueryDate", "desc"), limit(50));
        let firstCall = true;
        let jobDispatchIDs= [];
        console.log('qeury job dispatch for id = ' +jobID)
        let latestDispatchDate ='1900/01/01';
        jobDispatchListenerRef.current= onSnapshot(q, (querySnapshot) => {

            querySnapshot.docChanges().forEach((change) => {
                const tempDispatch = change.doc.data();
				console.log('tempDispatch = ', tempDispatch)
                    if (change.type === "added") {
                        tempDispatch.ID = change.doc.id;
                        jobDispatchesRef.current.push(tempDispatch);
                        if(tempDispatch.QueryDate> latestDispatchDate)latestDispatchDate=tempDispatch.QueryDate;
                        jobDispatchIDs.push(change.doc.id)
                    }
                    if (change.type === "modified") {
                        const dispatchIndex = jobDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                        if(tempDispatch.QueryDate> latestDispatchDate)latestDispatchDate=tempDispatch.QueryDate;
                        jobDispatchesRef.current[dispatchIndex] = tempDispatch;
                    }
                    if (change.type === "removed") {
                        const dispatchIndex = jobDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                        jobDispatchesRef.current.splice(dispatchIndex,1);
                        const IDIndex = jobDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                        jobDispatchIDs.splice(IDIndex,1);
                    }
                
            });
            if(!firstCall && jobRef.current){
                let updateObject ={};
				console.log('latestdispatchdate = ', latestDispatchDate)
            
				
                if(jobRef.current.dispatchIds.length !==jobDispatchIDs.length){
                    jobRef.current.dispatchIds = jobDispatchIDs;
                    updateObject.dispatchIds = jobDispatchIDs;
                }

                if(jobRef.current.dispatchIds.length !==jobDispatchIDs.length || jobRef.current.latestDispatchDate!==latestDispatchDate) {
					jobRef.current.latestDispatchDate= latestDispatchDate;
                    updateObject.latestDispatchDate= latestDispatchDate;
				
                    setJob((prev) => ({ ...prev, updateObject}));
                    updateDocument(updateObject, jobID, 'Jobs');
					if( jobRef.current.PrevailingWage)handlePrevailingWageReportCreate( jobRef.current,{latestDispatchDate:latestDispatchDate})
                }  
            }else{
                if(dispID==='none' && jobDispatchIDs.length>0){
                    const tempEncodedDate = encodeURIComponent(jobDate); 
					console.log('dispatchView on load' +dispatchView)
					navigate(`/dispatch/edit/${jobDispatchIDs[0]}/${jobID}/${tempEncodedDate}/${dispatchView}`, { replace: true });
                
                }
                firstCall=false;
            } 
          //  console.log('jobDispatchIDs= ', jobDispatchIDs)
         //   console.log('fjobDispatchesRef.current = ',jobDispatchesRef.current)
         //   console.log('CURRENT DISPATCH ID = ',dispID)
          
            setJobDispatches([... jobDispatchesRef.current])
    
        
        });
    }
   
    const deleteDispatch =() => {
        let  DeleteDispatchWarning = 'Are you sure you want to delete this Dispatch and its ALL attached FREIGHT BILLS?';
        let freightsBilled=false;
        console.log('dispatchFreightBills= ', dispatchFreightBillsRef.current )
        console.log('dispatch= ', dispatchObjectRef.current )

        for (var i = 0; i < dispatchFreightBillsRef.current.length; i++){
            if(dispatchFreightBillsRef.current [i].driverLoads>0 || !dispatchFreightBillsRef.current [i].missing) DeleteDispatchWarning ='  WARNING: This dispatch has Freight Bills with either partially or fully entered information. ARE YOU SURE YOU WANT TO DELETE?'
            if(dispatchFreightBillsRef.current [i].billed || dispatchFreightBillsRef.current [i].paid) freightsBilled=true;
        }
        try {
            if(!freightsBilled){
                if (window.confirm(DeleteDispatchWarning)) {
                    console.log('jobDispatches = ', jobDispatchesRef.current)
                    if(jobDispatchesRef.current.length<=1 && jobDispatchesRef.current[0].ID===dispatchObjectRef.current.ID){
                        deleteDocument({ID:dispatchObjectRef.current.Job.ID},'Jobs' );
                    }else deleteDocument(dispatchObjectRef.current,'Dispatches' );
                    addAuditLog('deleteDispatch', 'Dispatches',dispatchObjectRef.current.ID, dispatchObjectRef.current);
                    navigate('/dispatch/home/'+ encodedDate)
                }  
            }else window.alert('You have billed or paid freight bills on this dispatch and cannot delete.')
        } catch (error) {  console.error("Error removing document: ", error);  }
    }
   
    const changeDispatch =()=>{
       
        setDispatch((prev) => ({ ...prev,  isChanging:true }));
        updateDocument({isChanging:true}, dispatch.ID, 'Dispatches');
    }

   
    const newJob = () =>{
        let JobNumber ='';
        
        if(company.AutoJobNumber){
            company.CurrentJobNumber++;
            JobNumber= company.CurrentJobNumber.toString();
           
        }    
        const tempCompany = {
            ID: company.ID,
            CompanyID: gearedUser.selectedOrgName,
            CompanyName: company.CompanyName,
            Address: company.Address,
            address2: company.address2,
            CompanyPhone: company.CompanyPhone,
            payByJobHours : company.payByJobHours ? company.payByJobHours : false,
            Fax: company.Fax,
            CalcRunningTime: company.CalcRunningTime,
            realmID: company.realmID
        }

    
        let today = new Date();
        let prevailEndDate = new Date(today);
        prevailEndDate.setDate(today.getDate() + 14);
        prevailEndDate = formatDate(prevailEndDate, '/', 'MM/DD/YYYY');
        console.log('prevailEndDate = ', prevailEndDate)
        today.setDate(today.getDate()+1);
        today = formatDate(today,'/','MM/DD/YYYY')
        let tempJob = {
            ID: '',
            Description: '',
            Billable: true,
            Account: { ID: 'default', Name: 'No Account' },

            Contractor:  { Name: 'No Contractor', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Owner: { Name: 'No Owner', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Lender: { Name: 'No Lender', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Claimant:{ ID: 'default', Name: 'No Account' },
            Contact:  { ID: 'default', Name: 'No Contact' },
            TruckType: defaultTruckType[0],
            LoadSite: { Name: 'No Location', ID: 'default', Address: '', State: '', City: '', ZipCode:'', fullAddress:''  },
            DumpSite: { Name: 'No Location', ID: 'default', Address: '', State: '', City: '', ZipCode:'', fullAddress:'' },
            Material:{ ID: 'default', Name: 'No Material' },
            JobSite:{ ID: 'default', Name: 'No Location' },
            ProjectName:'',
            MaterialCompany:{...tempCompany},
            JobNumber: JobNumber,
            Company: {...tempCompany},
  
            ContractNumber: '',
            PONumber: '',
            QuoteDate:'',
            QtyType: 'Loads',
            JobDate: today,
            prevailStartDate:today,
            prevailEndDate:prevailEndDate,
            CertifiedPayroll: false,
            SellMaterial:false,   
            JobType:'',
            loadsPerTruck: 0,
            deliveredLoads: 0,
            deliveredQty: 0,
            MissingFreights: 0,
            unBilledFreights: 0,
            unBilledMaterials:0,
            UnreadFreights:0,
            BrokerFee:0,
            BillRate:'',
            BillType:'Hour',
            PayRate:'',
            PayType:'Hour',
            OutsidePayRate:'',
            RouteIndex: '',
            LoadsOrdered: '',
            LoadsCompleted: '',
            Status: 'In Progress',
            MaterialTaxRate: '',
            MaterialRateType: 'Ton',
            MaterialTaxable:false,
            MaterialRate:'',
            qtyOrdered: 0,
            qtyDelivered: 0,
            calcQtyDelivered: 0,
            qtyRemaining: 0,
            calcQtyRemaining: 0, 
            Unread: 0,
            bannedDates:[],
            Revenue: 0,
            dispatchCount: 1,
			PrevailingWage: false,
            PrevailingWageRate: '',
		
			OwnerOpPrevailingWageRate: '',
			OvertimePrevailingWageRate: '',
            
        }
        if(materialCompany){
            const tempMatCompany ={
          
                ID: materialCompany.ID,
                CompanyID: gearedUser.selectedOrgName,
                CompanyName: materialCompany.CompanyName,
                Address: materialCompany.Address,
                address2: materialCompany.address2,
                CompanyPhone: materialCompany.CompanyPhone,
                Fax: materialCompany.Fax,
                realmID: materialCompany.realmID
         
            }
            tempJob.MaterialCompany={...tempMatCompany}
        }
        jobDispatchesRef.current=null;
        setJobDispatches(null)
        setDispatchState('JobEdit');
        setJob({...tempJob})
    }

    return(
        <div>
            {dispatchState==='DispatchEdit' && ( <DispatchEdit/>    )}
            {dispatchState==='JobEdit' && ( <JobEdit/>    )}
            {dispatchState==='DispatchStatus' && ( <DispatchStatus dispatch={dispatch} dispatchID={id} freightBills={dispatchStatusFreightBills} onDriverClick={handleFreightClick} />   )}
            {dispatchState==='JobPrelim' && ( <JobPrelim />   )}
			<DispatchPrintPopUp setDispatchPrintPopUpVisible={setDispatchPrintPopUpVisible} dispatchPrintPopUpVisible={dispatchPrintPopUpVisible}  printDispatch={handlePrintDispatch}/>
        </div>
    )
}
export default JobPage;