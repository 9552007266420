import React, { useState, useContext, useRef, createContext } from 'react';
import { db } from '../../../firebase';
import { query, collection, onSnapshot, where, doc, getDocs } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import { useInvoice } from './InvoiceContext';
import { useLineItem} from '../hooks/useLineItem';
const InvoiceCreateContext = createContext();

export const InvoiceCreateContextProvider = ({ children }) => {

	const {  invoiceRef, invoicesRef, calcInvoiceTotal,  setInvoice, setInvoices,invoiceExpenseListenersRef,  } = useInvoice();
	
	const { gearedUser }= UserAuth();
	const { makeExpenseLineItem } = useLineItem();






	const openInvoiceExpenseListener = (tempInvoice, Dispatch) =>{

		console.log('dispatch = ', Dispatch)
		console.log('invvoicesRef.current in create context= '+   invoicesRef.current[0].LineItems.length)
	
		const expenseQueryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
        const expenseQuery = Dispatch.isFloatingExpenseDispatch ? 
		query(collection(db, expenseQueryName), where("dispatchID", "==", 'floatingDispatchExpense'), where("BillTo.ID", "==", Dispatch.Account.ID)) :
		query(collection(db, expenseQueryName), where("dispatchID", "==", Dispatch.ID));
		console.log('tempInvoice.ID  outside the snapshot ==' , tempInvoice.LineItems)
        invoiceExpenseListenersRef.current.push(onSnapshot(expenseQuery, (querySnapshot) => {
       
			const currentInvoicesArray = invoicesRef.current;
            const foundIndex =  currentInvoicesArray.findIndex(obj => obj.ID === tempInvoice.ID);
		
            if(foundIndex!==-1){
                querySnapshot.docChanges().forEach((change) => {
                    var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    var Expense = change.doc.data();
                    Expense.ID = change.doc.id;
                
                    if (Expense.BillTo.ID === currentInvoicesArray[foundIndex].Account.ID) {
                        if (change.type === "added") {
                            console.log(', Expense adding to invoice = ' , Expense);
                            if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill!== '')) {
                                currentInvoicesArray[foundIndex].Expenses.push(Expense);
                                currentInvoicesArray[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
                            }
                        }                         
                        if (change.type === "removed") {
                            for (var i = 0; i < currentInvoicesArray[foundIndex].Expenses.length; i++) {
                                if (Expense.ID == currentInvoicesArray[foundIndex].Expenses[i].ID) {
                                    currentInvoicesArray[foundIndex].Expenses.splice(i, 1);
                                    for (var j = 0; j < currentInvoicesArray[foundIndex].LineItems.length; j++) {
                                        if (currentInvoicesArray[foundIndex].LineItems[j].ID == Expense.ID) {
                                            currentInvoicesArray[foundIndex].LineItems.splice(j, 1);
                                            j--
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (change.type === "modified") {
                        var foundExpense =false;
                        console.log('found a modified epxense = ', Expense);
                        for (var i = 0; i < currentInvoicesArray[foundIndex].Expenses.length; i++) {
                            if (Expense.ID === currentInvoicesArray[foundIndex].Expenses[i].ID) {
                                foundExpense=true;
                                currentInvoicesArray[foundIndex].Expenses[i] = Expense;
                                for (var j = 0; j < currentInvoicesArray[foundIndex].LineItems.length; j++) {
                                    if (currentInvoicesArray[foundIndex].LineItems[j].ID === Expense.ID) {
                                        console.log('we found the current expense in teh line item and the expnes = ', Expense)
                                        if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill !== '') && Expense.BillTo.ID === currentInvoicesArray[foundIndex].Account.ID)   currentInvoicesArray[foundIndex].LineItems[j]=makeExpenseLineItem(Expense);
                                        else{ 
                                            currentInvoicesArray[foundIndex].Expenses.splice(i,1);
                                            currentInvoicesArray[foundIndex].LineItems.splice(j,1);
                                        }  
                                    }
                                }
                            }
                        }
                        if(!foundExpense){
                            if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill != '')) {
                                console.log('pushing to invoice.exppenses = ' + currentInvoicesArray[foundIndex].Expenses.length);
                                currentInvoicesArray[foundIndex].Expenses.push(Expense);
                                currentInvoicesArray[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
                            }   
                        }
                    }
                
                    });
            
          
                currentInvoicesArray[foundIndex] =calcInvoiceTotal(currentInvoicesArray[foundIndex]);
            
                currentInvoicesArray[foundIndex].Balance = currentInvoicesArray[foundIndex].Total;
                currentInvoicesArray[foundIndex].Balance.Type = 'Amount Due';
				invoicesRef.current = currentInvoicesArray;
                setInvoices([...currentInvoicesArray]);
				console.log('setting invoiceRef.current = ', invoiceRef.current.ID);
				console.log('currentInvoicesArray[foundIndex].ID = ', currentInvoicesArray[foundIndex].ID)
				console.log('foundIndex = ', foundIndex)
				console.log('i am setting invoice to = ', currentInvoicesArray[foundIndex])
                if(invoiceRef.current.ID===currentInvoicesArray[foundIndex].ID)setInvoice({...currentInvoicesArray[foundIndex]})
            }
        }));
	}
	return (
        <InvoiceCreateContext.Provider value={{
			openInvoiceExpenseListener,
		
        }}>
            {children}
        </InvoiceCreateContext.Provider>
    );
};

export const useInvoiceCreate = () => useContext(InvoiceCreateContext);

