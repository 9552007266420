import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const CustomDialog = ({ visible, setVisible, text, buttons, header }) => {
   // console.log('buttons = ', buttons)
  // Function to handle closing the dialog
  const closeDialog = () => {
    setVisible(false);
  };
  //console.log('custom dialog text = ', text)
  return (
    <Dialog    key={visible ? 'open' : 'closed'} header={header}  visible={visible}  onHide={closeDialog}  style={{ width: '35vw' }} breakpoints={{ '960px': '55vw', '641px': '80vw' }}  modal   className="p-fluid">
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        {text}
      </div>
      <div className="dialog-buttons" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
      {buttons &&( <div>{buttons.map((btn, idx) => (
          <Button   key={idx}   label={btn.label}   className={btn.className}    onClick={() => {  btn.onClick(); closeDialog(); }}   />
        ))}
        </div>)} 
      </div>
    </Dialog>
  );
};

export default CustomDialog;
