import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

const DriverHoursExpansion = ({ driver, weekFreightBills, reportWeek }) => {
    const navigate = useNavigate();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Create daily summary data
    const dailyData = daysOfWeek.map((day, index) => {
        const dayFreightBills = weekFreightBills.filter(fb => 
            fb.Driver === driver.ID && 
            new Date(fb.JobDate).getDay() === index
        );

        const totalPaidForDay = dayFreightBills.reduce((sum, fb) => 
            sum + (Number(fb.tPaid) || 0), 0
        );

        return {
            day,
            regularHours: driver.hoursPerDay[index] || 0,
            overtimeHours: driver.overtimePerDay[index] || 0,
            freightBills: dayFreightBills,
            projectPaid: (driver.hoursPerDay[index] * reportWeek.PrevailingWageRate) + 
                        (driver.overtimePerDay[index] * reportWeek.OvertimePrevailingWageRate),
            totalPaid: totalPaidForDay
        };
    });

    const openFreightBill = (fb) => {
		window.open(`/freightbill/freightbill/${fb.ID}/${fb.dispatchID}`, '_blank'); 
  
    };

    const freightBillsTemplate = (rowData) => {
        return (
            <div style={{ textAlign: 'center', display: 'flex', gap: '0.5em', flexWrap: 'wrap' }}>
                {rowData.freightBills.map(fb => {
                    const freightLabel = `FB-${fb.FBNO} (${fb.onJob ? 'On Job' : 'Off Job'})`;
					const buttonColor = fb.onJob ? 'success' : "warning";
                    return (
                        <Button 
                            key={fb.FBNO}
                            label={freightLabel}
                            onClick={() => openFreightBill(fb)}
                            size="small"
							severity={buttonColor}
                            style={{ textAlign: 'center', padding: '0.25em 0.5em' }}
                        />
                    );
                })}
            </div>
        );
    };

    const numberTemplate = (rowData, field) => {
        const value = rowData[field] || 0;
        return (
            <span style={{ textAlign: 'right', display: 'block' }}>
                {value.toFixed(1)}
            </span>
        );
    };

    const currencyTemplate = (rowData, field) => {
        const value = rowData[field] || 0;
        return (
            <span style={{ textAlign: 'right', display: 'block' }}>
                ${value.toFixed(2)}
            </span>
        );
    };

    const dayTemplate = (rowData) => {
        return (
            <span style={{ textAlign: 'center', display: 'block' }}>
                {rowData.day}
            </span>
        );
    };

    return (
        <div style={{ padding: '1em', backgroundColor: '#f8f9fa' }}>
            <DataTable value={dailyData} size="small">
                <Column 
                    field="day" 
                    header="Day" 
                    style={{ width: '12%' }}
                    alignHeader='center'
                    body={dayTemplate}
                />
                <Column 
                    field="regularHours" 
                    header="Regular Hours" 
                    style={{ width: '12%' }}
                       alignHeader='center'
                    body={(rowData) => numberTemplate(rowData, 'regularHours')}
                />
                <Column 
                    field="overtimeHours" 
                    header="Overtime Hours" 
                    style={{ width: '12%' }}
                      alignHeader='center'
                    body={(rowData) => numberTemplate(rowData, 'overtimeHours')}
                />
                <Column 
                    field="projectPaid"
                    header="Project Paid" 
                    style={{ width: '12%' }}
                      alignHeader='center'
                    body={(rowData) => currencyTemplate(rowData, 'projectPaid')}
                />
                <Column 
                    field="totalPaid"
                    header="Total Paid" 
                    style={{ width: '12%' }}
                      alignHeader='center'
                    body={(rowData) => currencyTemplate(rowData, 'totalPaid')}
                />
                <Column 
                    header="Freight Bills" 
                    body={freightBillsTemplate} 
                    style={{ width: '40%' }}
                      alignHeader='center'
                  	align='center'
                />
            </DataTable>
        </div>
    );
};

export default DriverHoursExpansion; 