import { usePrevailingWageReport } from '../context/PrevailingWageReportContext';
import { UserAuth } from '../../../context/AuthContext';

export const useFindWeekDates = () => {

  const {setReportWeeks,reportWeeksRef} = usePrevailingWageReport();
  const {formatDate} = UserAuth();
  const findFirstSunday = (prevailStartDate, prevailEndDate, freightBills, id)=>{
  
      var findingSundayDate=new Date(prevailStartDate);
      console.log('prevailStartDate= ',prevailStartDate )
      console.log('reportWeeks BEFORE WE BEGIN = ',reportWeeksRef.current )
	  console.log('freightBills = ', freightBills)
      while(findingSundayDate.getDay()!=0)  findingSundayDate.setDate(findingSundayDate.getDate()-1); 
      
      let weeks=[...reportWeeksRef.current];
   
      var endDate= new Date(prevailEndDate);
      while(findingSundayDate<endDate){
    

          var weekEndDate= new Date(findingSundayDate);
          weekEndDate.setDate(weekEndDate.getDate()+6);
          var formattedSundayDate=formatDate(findingSundayDate,'/','YYYY/MM/DD'); 
          var formattedSaturdayDate=formatDate(weekEndDate,'/','YYYY/MM/DD');
          var weekID = formatDate(findingSundayDate,'-','MM-DD-YYYY'); 
          const weekIndex = weeks.findIndex(w => w.weekStartDate === formattedSundayDate);
       
          if(weekIndex===-1){
              console.log('about to add a week wtih sunday  = ', formattedSundayDate);
              const uniqueDrivers = new Set();
              let jobFreightCount = 0;
              let totalFreightCount = 0;

              freightBills.forEach(bill => {
                  if(bill.QueryDate <= formattedSaturdayDate && bill.QueryDate >= formattedSundayDate) {
                      totalFreightCount++;
                      if(bill.JobID === id) {
                          jobFreightCount++;
                          if(bill.Driver) uniqueDrivers.add(bill.Driver);
                      }
                  }
              });

              var newWeek={
                  name: formatDate(findingSundayDate,'/','MM/DD/YYYY') + ' - ' + formatDate(weekEndDate,'/','MM/DD/YYYY'),
                  weekStartDate:formattedSundayDate,
                  weekEndDate: formattedSaturdayDate,
                  ID:weekID,
                  runReportButton:'Run Report',
                  JobFreightCount: jobFreightCount,
                  TotalFreightCount: totalFreightCount,
                  DriverCount: uniqueDrivers.size,
                  DocCount:0,
                  Reported:false,
                  Drivers:[],
                  OwnerOps:[],
                  Docs:[],
                  VNum:1
              }
              console.log('and allfreights length = ' , freightBills.length);
              newWeek.DocCount = 0;
              newWeek.nonPerformance = totalFreightCount === 0;
              weeks.push(newWeek);
          }else{
            
              const uniqueDrivers = new Set();
              weeks[weekIndex].JobFreightCount = 0;
              weeks[weekIndex].TotalFreightCount = 0;
              weeks[weekIndex].DocCount=weeks[weekIndex].Docs.length;
              weeks[weekIndex].DriverCount=0;
              for(var i=0; i<freightBills.length; i++){
                  if(freightBills[i].QueryDate<=formattedSaturdayDate && freightBills[i].QueryDate>=formattedSundayDate){
                      weeks[weekIndex].TotalFreightCount++;
                      if(freightBills[i].JobID===id)weeks[weekIndex].JobFreightCount++;
                      if(freightBills[i].Driver) uniqueDrivers.add(freightBills[i].Driver);
                  }
              }
              weeks[weekIndex].DriverCount = uniqueDrivers.size;
              if(weeks[weekIndex].TotalFreightCount===0)weeks[weekIndex].nonPerformance=true;
              else weeks[weekIndex].nonPerformance=false;
              console.log('we lookin at existing week = ',weeks[weekIndex])
      
          }
         
          findingSundayDate.setDate(findingSundayDate.getDate()+7);
     
      }
      weeks  = weeks.sort((a, b) => new Date(b.weekStartDate) - new Date(a.weekStartDate));
      setReportWeeks([...weeks]);
      reportWeeksRef.current=weeks;
      console.log('weeks looks like dis  = ', weeks);  
	}
return {
    findFirstSunday
};
}; 