
import React, {useState} from 'react';
import { Dialog } from 'primereact/dialog';

import { usePayStatement } from './context/PayStatementContext';
import { usePrint } from './context/PrintPayStatementContext';
import PayStatementComponent from './PayStatementComponent'; 

const PayStatementPopUp = (props) => {

  
    const { payStatementVisible, setPayStatementVisible, payStatement,   } = usePayStatement();
    const {printPayStatement} = usePrint();
    const [inputValues, setInputValues] = useState({});
    const closePayStatementPopUp = () => {
        setPayStatementVisible(false); 
    };

    const renderHeader = () => {
        return (
            <div>
            <span >Pay Statement Details </span>
            <button style={{ float:'right', margin: '0', padding: '.5em', marginRight:'6em', width:"15%" }}   onClick={(e) =>printPayStatement(inputValues)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Print</button>
            </div>
        );
    };
 
    const header =renderHeader();



   
return(
    <React.Fragment>
        {payStatement && (
        <Dialog header={header} visible={payStatementVisible} style={{ width: '100vw', maxHeight:"98%" }} breakpoints={{ '960px': '90vw', '641px': '100vw' }}  onHide={closePayStatementPopUp}>
            <PayStatementComponent inputValues={inputValues} setInputValues={setInputValues}/>
        </Dialog>)}

    </React.Fragment>
);
};

export default PayStatementPopUp;