import React, {useEffect, useState, useRef} from 'react';
import { UserAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { query, collection, onSnapshot, updateDoc } from 'firebase/firestore';
import { Panel } from 'primereact/panel';     
import {Button} from 'primereact/button';


const OrgSwap = (props) => {
    const { gearedUser,updateDocument,formatDate } = UserAuth();
    const [orgnizations, setOrganizations]= useState([]);
    const orgListenerRef = useRef(null);
    const organizationsRef= useRef(null);
    useEffect(()=>{
        queryOrganizations();
    },[]);

    const queryOrganizations = () => {
        console.log('we running Query tablets');
        if ( orgListenerRef.current)  orgListenerRef.current();
        const queryName = `OrganizationNames`;
        organizationsRef.current = [];
        const orgQuery = query(collection(db, queryName));
         orgListenerRef.current = onSnapshot(orgQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var tempOrg = change.doc.data();
                tempOrg.ID = change.doc.id;   
   
                const foundIndex = organizationsRef.current ? organizationsRef.current.findIndex(obj => obj.ID === tempOrg.ID) : -1;
                if (change.type === "added") organizationsRef.current.push({ ...tempOrg });
                if (change.type === "modified") organizationsRef.current[foundIndex] = { ...tempOrg };
                if (change.type === "removed") organizationsRef.current.splice(foundIndex, 1);
            });
            console.log('Finished querying');
        
            setOrganizations([...organizationsRef.current])
    
        });
    }

    const swapOrg = (org)=>{
        localStorage.setItem('currentOrg', org.ID);
       console.log('geared USer = ', gearedUser)
       updateDocument({selectedOrgName:org.ID}, gearedUser.ID ,"users");
       const today = new Date();
       const tomorrow = new Date();
       tomorrow.setDate(today.getDate() + 1);
       const encodedDate = encodeURIComponent(formatDate(tomorrow,'/','YYYY/MM/DD'));
        console.log('encodedDate = ', encodedDate)
        const baseUrl = window.location.origin; 
        const targetPath = '/dispatch/home/' + encodedDate; 
        setTimeout(() => window.location.assign(`${baseUrl}${targetPath}`), 1000);
     
    }

    return (
        <Panel header='Organizations'>
                    {orgnizations.map((item,index) => (
                               <div key={index} className='mbsc-row' style={{paddingTop:'1em'}}>
                   
                                    <Button onClick={(e)=>swapOrg(item)}>{item.ID}</Button>
                               </div>
                                ))}
                  
        </Panel>
    )
}

export default OrgSwap;