import { useEffect, useRef } from "react";

/**
 * Custom hook to execute a callback when dependencies change,
 * but only if the previous value of the specified dependency is not null or undefined.
 * 
 * @param {Function} callback - The function to execute.
 * @param {Array} dependencies - Dependency array for the hook.
 */
function useEffectNonNull(callback, dependencies) {
  const prevDependencies = useRef([]);

  useEffect(() => {
    const shouldRun = dependencies.some((dep, index) => 
      prevDependencies.current[index] !== null && 
      prevDependencies.current[index] !== undefined && 
      prevDependencies.current[index] !== dep
    );

    if (shouldRun) {
      callback();
    }

    prevDependencies.current = dependencies;
  }, dependencies);
}

export default useEffectNonNull;
