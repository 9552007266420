import React from 'react';
import ReactDOM from 'react-dom/client'
import './index.css';
import "./styles.css";
import App from './App';

import { createBrowserRouter,RouterProvider, createRoutesFromElements, Route} from "react-router-dom";

import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react/dist/css/mobiscroll.min.css'; // Use consistent import for styles

// Set a global theme
mobiscroll.settings = {
    theme: 'ios' // or any other theme you prefer
};



const router = createBrowserRouter(createRoutesFromElements(<Route path='*' element={  <App />} />));
ReactDOM.createRoot(document.getElementById('root')).render(

      <RouterProvider router={router} />
  
  )


