import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../../context/AuthContext';
import { useGlobal } from '../../../context/GlobalContext';
import { parseIIFCustomers } from '../../../utils/iifParser';

import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faExchangeAlt, faSync } from '@fortawesome/free-solid-svg-icons';
import { TabView, TabPanel } from 'primereact/tabview';

// Add custom styles
const styles = `
    .match-row-odd {
        background-color: #f8f9fa !important;
    }
    .match-row-even {
        background-color: #ffffff !important;
    }
`;

// Add style tag to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

const CustomerListHome = () => {
	const { deleteDocument, accounts, gearedUser, updateDocument, mapCustomerData, quickbooksCustomers } = UserAuth();
	const { showAccountPopUp } = useGlobal();
	const [sortedAccounts, setSortedAccounts] = useState([]);

	const [expandedRows, setExpandedRows] = useState({});
	const storage = getStorage();
	const [childFilters, setChildFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Status: { value: null, matchMode: FilterMatchMode.EQUALS }
	});
	const [childGlobalFilterValue, setChildGlobalFilterValue] = useState('');



	const getUnmatchedCustomers = (customers, accountsList) => {
		return customers.filter(customer => 
			!accountsList.some(account => 
				account.Name.toLowerCase().trim() === customer.Name.toLowerCase().trim()
			)
		);
	};

	const unmatchedCustomers = getUnmatchedCustomers(quickbooksCustomers, accounts);
	// Sort the unmatched customers
	const sorted = [...unmatchedCustomers].sort((a, b) => 
		(a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)
	);
	

	const findUnmatchedAccounts = (qbCustomer) => {
		// Get all accounts that don't match any QuickBooks customer name
		const unmatchedAccounts = accounts.filter(account => 
			!quickbooksCustomers.some(customer => 
				customer.Name.toLowerCase().trim() === account.Name.toLowerCase().trim()
			)
		);
		
		// Sort alphabetically by name
		return unmatchedAccounts.sort((a, b) => 
			(a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)
		);
	};

	const handleSyncName = async (qbCustomer, account) => {
		try {
			await updateDocument(
				{ Name: qbCustomer.Name },
				account.ID,
				"Accounts"
			);
			
			// Update accounts list to include the new name
			const updatedAccounts = accounts.map(acc => 
				acc.ID === account.ID ? { ...acc, Name: qbCustomer.Name } : acc
			);
			
			// Refilter the unmatched customers based on the updated accounts
			const updatedUnmatched = getUnmatchedCustomers(quickbooksCustomers, updatedAccounts);
			setSortedAccounts(updatedUnmatched.sort((a, b) => 
				(a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0)
			));
			
			// Remove the expanded row state
			setExpandedRows(prev => {
				const newState = { ...prev };
				delete newState[qbCustomer.ID];
				return newState;
			});
		} catch (error) {
			console.error("Error updating account name:", error);
		}
	};

	const renderChildHeader = (title) => (
		<div className="flex justify-content-between align-items-center">
			<h5 className="m-0">{title}</h5>
			<IconField iconPosition="left" className="homeSearch">
				<InputIcon className="pi pi-search" />
				<InputText 
					style={{ paddingLeft: '2.5rem' }} 
					value={childGlobalFilterValue} 
					onChange={(e) => {
						const value = e.target.value || '';
						setChildFilters(prev => ({ ...prev, global: { ...prev.global, value } }));
						setChildGlobalFilterValue(value);
					}} 
					placeholder="Search Accounts" 
				/>
			</IconField>
		</div>
	);

	const rowExpansionTemplate = (data) => {
		console.log('data = ', data)
		const unmatchedAccounts = findUnmatchedAccounts(data);
		if (unmatchedAccounts.length === 0) return null;

		return (
			<div className="p-3">
				<DataTable 
					value={unmatchedAccounts} 
					className="p-datatable-sm"
					rowClassName={(rowData, index) => index % 2 === 0 ? 'match-row-even' : 'match-row-odd'}
					header={renderChildHeader('Unmatched Accounts')}
					filters={childFilters}
					filterDisplay="row"
				
				>
					<Column field="Name" header="Account Name" filter filterPlaceholder="Search by name" bodyStyle={{ padding: '0.5rem' }} />
					<Column field="Status" header="Status" filter filterElement={statusRowFilterTemplate} body={statusBodyTemplate} />
					<Column header="Actions" bodyStyle={{ padding: '0.5rem' }} body={(rowData) => (
							<button className="p-button p-button-sm p-button-success" onClick={() => handleSyncName(data, rowData)}>
								<FontAwesomeIcon icon={faSync} /> Sync Name 
							</button>
						)} 
					/>
				</DataTable>
			</div>
		);
	};

	const hasUnmatchedAccounts = (data) => {
		return findUnmatchedAccounts(data).length > 0;
	};

	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		CompanyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Status: { value: 'Active', matchMode: FilterMatchMode.EQUALS },
		Subhauler: { value: null, matchMode: FilterMatchMode.EQUALS },
		DedicatedSubhauler: { value: null, matchMode: FilterMatchMode.EQUALS },
		Vendor: { value: null, matchMode: FilterMatchMode.EQUALS },
		Broker: { value: null, matchMode: FilterMatchMode.EQUALS },
		QBRefNum: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [statuses] = useState(['Active', 'Inactive']);

	const getSeverity = (status) => (status === 'Active' ? 'success' : 'danger');

	const onGlobalFilterChange = (e) => {
		const value = e.target.value || '';
		setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));
		setGlobalFilterValue(value);
	};

	const deleteBodyTemplate = (rowData) => (
		<button type="button" onClick={() => handleDelete(rowData)}>
			<FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
		</button>
	);

	const handleDelete = async (rowData) => {
		try {
			if (window.confirm("Are you sure you want delete this Account?")) {
				deleteDocument(rowData,'Accounts' )
			}  
		} catch (error) {  console.error("Error removing document: ", error);  }
	};

	const renderHeader = () => (
		<div className="flex justify-content-end mbsc-row ">
			<span>Accounts</span>
			<IconField iconPosition="left" className="homeSearch">
				<InputIcon className="pi pi-search" />
				<InputText style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
			</IconField>
		</div>
	);

	const editBodyTemplate = (rowData) => (
		<button type="button" onClick={() => handleEdit(rowData)}>
			<FontAwesomeIcon icon={faEdit} />
		</button>
	);

	const handleEdit = (rowData) => {
		showAccountPopUp(rowData);
	};

	const statusBodyTemplate = (rowData) => <Tag value={rowData.Status} severity={getSeverity(rowData.Status)} />;
	const statusItemTemplate = (option) => {
		return <Tag value={option} severity={getSeverity(option)} />;
	};

	const booleanBodyTemplate = (rowData, field, header) => (
		<span data-label={header}>
			<i
				className={classNames('pi', {
					'pi-check-circle true-icon': rowData[field],
					'pi-times-circle false-icon': !rowData[field]
				})}
			/>
		</span>
	);

	const statusRowFilterTemplate = (options) => {
		return (
			<Dropdown     
				value={options.value !== null ? options.value : undefined} 
				options={statuses}
				onChange={(e) => options.filterApplyCallback(e.value)} 
				itemTemplate={statusItemTemplate} 
				placeholder="Select One"
				className="p-column-filter" 
				showClear 
				style={{ minWidth: '12rem' }} 
			/>
		);
	};

	const booleanFilterTemplate = (options) => {
		return (
			<TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
				value={options.value !== null ? options.value : undefined}
				onChange={(e) => options.filterApplyCallback(e.value)}
			/>
		);
	};

	const clickUpload = () => {
		console.log(' document.getElementById(freightUpload) =' , document.getElementById('attachUpload'))
		document.getElementById('attachUpload').click();
	};

	const startUpload = (event) => {
		console.log('we have started an upload and event ', event)
		uploadFile(event.target.files[0])
	};

	const uploadFile = async(file) => {
		console.log('file = ', file.name)

		const storageRef = ref(storage, `attachments/${gearedUser.selectedOrgName}/Quickbooks/CustomerList.IIF`);

		uploadBytes(storageRef, file).then((snapshot) => {
			getDownloadURL(storageRef).then((url) => {
				mapCustomerData(url);
			});
		});
	};

	const findMatchingAccount = (qbCustomer) => {
		return accounts.find(account => 
			account.Name.toLowerCase().trim() === qbCustomer.Name.toLowerCase().trim()
		);
	};

	const getSyncedCustomers = () => {
		return quickbooksCustomers.filter(customer => 
			accounts.some(account => 
				account.Name.toLowerCase().trim() === customer.Name.toLowerCase().trim()
			)
		).sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
	};

	const syncedRowExpansionTemplate = (data) => {
		const matchingAccount = findMatchingAccount(data);
		if (!matchingAccount) return null;

		return (
			<div className="p-3">
				<DataTable 
					value={[matchingAccount]} 
					className="p-datatable-sm"
					rowClassName={(rowData, index) => 'match-row-even'}
					header={renderChildHeader('Synced Firestore Account')}
					filters={childFilters}
					filterDisplay="row"
				>
					<Column field="Name" header="Account Name" filter filterPlaceholder="Search by name" />
					<Column field="Status" header="Status" filter filterElement={statusRowFilterTemplate} body={statusBodyTemplate} />
					<Column field="CompanyName" header="Company Name" filter filterPlaceholder="Search by company" />
					<Column field="Phone" header="Phone" filter filterPlaceholder="Search by phone" />
					<Column field="Address" header="Address" filter filterPlaceholder="Search by address" />
					<Column field="City" header="City" filter filterPlaceholder="Search by city" />
					<Column field="State" header="State" filter filterPlaceholder="Search by state" />
				</DataTable>
			</div>
		);
	};

	const renderUnmatchedCustomersTable = () => (
		<div className="card">
			<DataTable
				value={sorted}
				paginator
				rows={25}
				dataKey="ID"
				filters={filters}
				header={header}
				filterDisplay="row"
				emptyMessage="No unmatched customers found."
				expandedRows={expandedRows}
				onRowToggle={(e) => setExpandedRows(e.data)}
				rowExpansionTemplate={rowExpansionTemplate}
			>
				<Column expander={hasUnmatchedAccounts} style={{ width: '3rem' }} />
				<Column pt={{root: { 'data-label': 'Name' }}} field="Name" header="Name" filter filterPlaceholder="Search by name" body={(rowData) => <span data-label="Name">{rowData.Name}</span>} />
				<Column pt={{root: { 'data-label': 'Company Name' }}} field="CompanyName" header="Company Name" filter filterPlaceholder="Search by Company Name" body={(rowData) => <span data-label="Company Name">{rowData.CompanyName}</span>} />
				<Column pt={{root: { 'data-label': 'Phone' }}} field="displayPhone" header="Phone" filter filterPlaceholder="Search by phone" body={(rowData) => <span data-label="Phone">{rowData.displayPhone}</span>} />
				<Column pt={{root: { 'data-label': 'Address' }}} field="Address" header="Address" filter filterPlaceholder="Search by Address" body={(rowData) => <span data-label="Address">{rowData.Address}</span>} />
				<Column pt={{root: { 'data-label': 'City' }}} field="City" header="City" filter filterPlaceholder="Search by City" body={(rowData) => <span data-label="City">{rowData.City}</span>} />
				<Column pt={{root: { 'data-label': 'State' }}} field="State" header="State" filter filterPlaceholder="Search by State" body={(rowData) => <span data-label="State">{rowData.State}</span>} />
				<Column pt={{root: { 'data-label': 'Status' }}} field="Status" header="Status" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
				<Column pt={{root: { 'data-label': 'QBRefNum' }}} field="QBRefNum" header="QB RefNum" filter filterPlaceholder="Search by QB RefNum" body={(rowData) => <span data-label="QBRefNum">{rowData.QBRefNum}</span>} />
			</DataTable>
		</div>
	);

	const renderSyncedCustomersTable = () => {
		const syncedCustomers = getSyncedCustomers();
		return (
			<div className="card">
				<DataTable
					value={syncedCustomers}
					paginator
					rows={25}
					dataKey="ID"
					filters={filters}
					header={header}
					filterDisplay="row"
					emptyMessage="No synced customers found."
					expandedRows={expandedRows}
					onRowToggle={(e) => setExpandedRows(e.data)}
					rowExpansionTemplate={syncedRowExpansionTemplate}
				>
					<Column expander style={{ width: '3rem' }} />
					<Column pt={{root: { 'data-label': 'Name' }}} field="Name" header="Name" filter filterPlaceholder="Search by name" body={(rowData) => <span data-label="Name">{rowData.Name}</span>} />
					<Column pt={{root: { 'data-label': 'Company Name' }}} field="CompanyName" header="Company Name" filter filterPlaceholder="Search by Company Name" body={(rowData) => <span data-label="Company Name">{rowData.CompanyName}</span>} />
					<Column pt={{root: { 'data-label': 'Phone' }}} field="displayPhone" header="Phone" filter filterPlaceholder="Search by phone" body={(rowData) => <span data-label="Phone">{rowData.displayPhone}</span>} />
					<Column pt={{root: { 'data-label': 'Address' }}} field="Address" header="Address" filter filterPlaceholder="Search by Address" body={(rowData) => <span data-label="Address">{rowData.Address}</span>} />
					<Column pt={{root: { 'data-label': 'City' }}} field="City" header="City" filter filterPlaceholder="Search by City" body={(rowData) => <span data-label="City">{rowData.City}</span>} />
					<Column pt={{root: { 'data-label': 'State' }}} field="State" header="State" filter filterPlaceholder="Search by State" body={(rowData) => <span data-label="State">{rowData.State}</span>} />
					<Column pt={{root: { 'data-label': 'QBRefNum' }}} field="QBRefNum" header="QB RefNum" filter filterPlaceholder="Search by QB RefNum" body={(rowData) => <span data-label="QBRefNum">{rowData.QBRefNum}</span>} />
				</DataTable>
			</div>
		);
	};

	const header = renderHeader();
	return (
		<div>
			<button className="md-btn" style={{ marginLeft:"2em", height:"3em", cursor:"pointer", marginBottom: "1em"}} onClick={() => clickUpload()}>
				Upload Customer IIF File
			</button>
			<input type='file' id={'attachUpload'} onChange={(event,inst) => startUpload(event)} style={{display:'none'}} base-sixty-four-input="true"/>
	
			<TabView>
				<TabPanel header="Unmatched Customers">
					{renderUnmatchedCustomersTable()}
				</TabPanel>
				<TabPanel header="Synced Customers">
					{renderSyncedCustomersTable()}
				</TabPanel>
			</TabView>
		</div>
	);
};

export default CustomerListHome;
