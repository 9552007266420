import {useLineItem} from './useLineItem';
import { UserAuth } from '../../../context/AuthContext';
export const useInvoiceCalc = () => {
	const {makeBrokerLineItem, makeFuelLineItem} = useLineItem();
	const { company } = UserAuth();
	

	const calcInvoiceTotal = (Invoice) => {
		if (Invoice) {
		// Initialize the Totals array
		Invoice.Totals = [];
			console.log('CALICING TOTAL BOIS!', Invoice.LineItems)
		// Helper function to sum values based on a filter
		const sumValues = (items, field, filter = () => true, log = false) => 
			items
				.filter(item => !item.onHold && filter(item)) // Exclude items with onHold = true
				.reduce((sum, item) => {
					const value = Number(item[field] || 0);
					const newTotal = sum + value;
		
					// Round the new total to two decimal places
					const roundedTotal = Math.round((newTotal + Number.EPSILON) * 100) / 100;
		
					if (log) {
						console.log('item = ', item)
						console.log(`Adding ${value} to current total of ${sum} for ${field}, new total: ${roundedTotal}`);
					}
		
					return roundedTotal;
				}, 0);
			// Calculate Totals for 'Tons'
			Invoice.Tons = {
				Type: 'Tons',
				Qty: sumValues(Invoice.LineItems, 'Qty', item => item.RateType === 'Ton'),
				Total: sumValues(Invoice.LineItems, 'Total', item => item.RateType === 'Ton', undefined, true)
			};
			Invoice.Tons.QtyString = '$' + Invoice.Tons.Qty.toFixed(2);
			Invoice.Totals.push(Invoice.Tons);
		
			// Calculate Totals for 'Loads'
			Invoice.Loads = {
				Type: 'Loads',
				Qty: sumValues(Invoice.LineItems, 'Qty', item => item.RateType === 'Load'),
				Total: sumValues(Invoice.LineItems, 'Total', item => item.RateType === 'Load')
			};
			Invoice.Loads.QtyString = '$' + Invoice.Loads.Qty.toFixed(2);
			Invoice.Totals.push(Invoice.Loads);
		
			// Calculate Totals for 'Hours'
			Invoice.Hours = {
				Type: 'Hours',
				Qty: sumValues(Invoice.LineItems, 'Qty', item => item.RateType === 'Hour'),
				Total: sumValues(Invoice.LineItems, 'Total', item => item.RateType === 'Hour')
			};
			Invoice.Hours.QtyString = '$' + Invoice.Hours.Qty.toFixed(2);
			Invoice.Totals.push(Invoice.Hours);

			Invoice.Material = {
				Type: 'Material',
				Qty: sumValues(Invoice.LineItems, 'Qty', item => item.Type === 'Material'),
				Total: sumValues(Invoice.LineItems, 'Total', item => item.Type === 'Material')
			};
			Invoice.Totals.push(Invoice.Material);

			Invoice.Tax = {
				Type: 'Tax',
				Qty: Invoice.TaxPercent || 'N/A',
				Total: sumValues(Invoice.LineItems, 'TaxTotal')
			};
			Invoice.Totals.push(Invoice.Tax);
			// First, remove any existing broker fee line items
			Invoice.LineItems = Invoice.LineItems.filter(item => item.Type !== 'Broker Fee');
		
			// Calculate Totals for 'Broker'
			Invoice.Broker = {
				Type: 'Broker',
				Qty: Invoice.BrokerPercent || 'N/A',
				Total: sumValues(Invoice.LineItems, 'BrokerTotal', undefined, true)
			};
			Invoice.Totals.push(Invoice.Broker);
		
			// Calculate Totals for 'Fuel'
			Invoice.Fuel = {
				Type: 'Fuel',
				Qty: Invoice.FuelPercent || 'N/A',
				Total: sumValues(Invoice.LineItems, 'FuelTotal', undefined, true)
			};
			Invoice.Totals.push(Invoice.Fuel);
		
			// Calculate Totals for 'Stand By'
			Invoice.StandBy = {
					Type: 'Stand By',
					loadTotal: sumValues(Invoice.LineItems, 'loadTotal'),
					dumpTotal: sumValues(Invoice.LineItems, 'dumpTotal'),
					loadQty: sumValues(Invoice.LineItems, 'loadQty'),
					dumpQty: sumValues(Invoice.LineItems, 'dumpQty'),
					Qty: sumValues(Invoice.LineItems, 'Qty', item => item.Type === 'Stand By'),
					Total: sumValues(Invoice.LineItems, 'Total', item => item.Type === 'Stand By')
			};
			Invoice.StandBy.QtyString = '$' + Invoice.StandBy.Qty.toFixed(2);
			Invoice.Totals.push(Invoice.StandBy);
	
		// Calculate Totals for 'Expenses'
			Invoice.ExpenseTotals = {
				Type: 'Expenses',
				Qty: sumValues(Invoice.LineItems, 'Qty', item => item.Type === 'Expense'),
				Total: sumValues(Invoice.LineItems, 'Total', item => item.Type === 'Expense')
			};
			Invoice.ExpenseTotals.QtyString = '$' + Invoice.ExpenseTotals.Qty.toFixed(2);
			Invoice.Totals.push(Invoice.ExpenseTotals);
			
			// Add the broker fee line item only if there's a non-zero broker total
			if (Invoice.Broker.Total !== 0 && company.downloadIIF) {
				Invoice.LineItems.push(makeBrokerLineItem(Invoice.Broker.Total, Invoice));
			}

			if (Invoice.Fuel.Total !== 0 && company.downloadIIF) {
				Invoice.LineItems.push(makeFuelLineItem(Invoice.Fuel.Total, Invoice));
			}
			// Calculate the Invoice Total
			Invoice.Total = {
				Type: 'Amount Due',
				Qty: 'N/A',
				Total: sumValues(Invoice.Totals, 'Total')
			};
		Invoice.Totals.push(Invoice.Total);
	
		console.log('Calculated Invoice Totals:', Invoice.Totals);
		}

		return Invoice;
	};
	return {
		calcInvoiceTotal
	 };
   }; 