import { useMemo } from 'react';

export const useDriverCalculations = () => {
  const getInitialDriverState = () => ({
    FedTax: 0,
    FICA: 0,
    LocalTax: 0,
    SDI: 0,
    OtherTax: 0,
    OtherDed: 0,
    TotalDed: 0,
    CheckNo: 'Deposit',
    Reportable: false,
    hoursPerDay: [0, 0, 0, 0, 0, 0, 0],
    overtimePerDay: [0, 0, 0, 0, 0, 0, 0],
    overtimeTotalHours: 0,
    projectPaid: 0,
    allProjectsTotal: 0,
    NetWages: 0
  });

  const calculateDriverHours = (freightBill, dayOfWeek) => {
    let regularHours = Number(freightBill.tHours);
    let overtimeHours = 0;

    if (regularHours > 8) {
      overtimeHours = regularHours - 8;
      regularHours = 8;
    }

    return {
      regularHours: Number(regularHours.toFixed(1)),
      overtimeHours: Number(overtimeHours.toFixed(1))
    };
  };

  const calculateDriverTotals = (driver, weekFreightBills, jobId, prevailingWageRate, overTimeRate) => {
    const result = { ...driver, ...getInitialDriverState() };
	console.log('calculateDriverTotals  for driver = '+ driver.Name)
    weekFreightBills.forEach(bill => {
		if (bill.Driver !== driver.ID || !bill.tHours) return;

		const dayOfWeek = new Date(bill.JobDate).getDay();
		const { regularHours, overtimeHours } = calculateDriverHours(bill, dayOfWeek);
		
		// Update totals for the specific job
		if (bill.JobID === jobId) {
		
			result.projectPaid += (prevailingWageRate * regularHours);
			result.projectPaid += (overTimeRate * overtimeHours);
			result.hoursPerDay[dayOfWeek] = regularHours;
			result.overtimePerDay[dayOfWeek] = overtimeHours;
		}

      // Update all projects total
      result.allProjectsTotal += Number(bill.tPaid) || 0;
    });

    result.projectPaid = Math.round((result.projectPaid + Number.EPSILON) * 100) / 100;
    result.NetWages = result.allProjectsTotal - (
    result.FedTax + result.FICA + result.SDI + 
    result.LocalTax + result.OtherTax + result.OtherDed
    );

    return result;
  };

  return {
    getInitialDriverState,
    calculateDriverTotals
  };
}; 