import React from 'react';


const NumberCell = ({ number, notCurrency, isPercent }) => {
  //  console.log(' NOTE ON LAOD = ', note);

    const formattedNumber = Number(number).formatMoney(2);
    return (
        <React.Fragment>
            {(number || number===0) && (
                <span style={{display:'block', textAlign:'right', paddingRight:'1em', fontFamily:'arial, verdana, sans-serif'}}>
                    {!notCurrency && (<span>${formattedNumber}</span>)}
                    {isPercent && (<span>{formattedNumber}%</span>)}
                    {notCurrency && !isPercent && (<span>{formattedNumber}</span>)}
                </span>
            )}
           
        </React.Fragment>
    );
};

export default NumberCell;