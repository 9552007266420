

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';


import ComplianceReport from './ComplianceReport';
import ReportBuilder from './ReportBuillders/ReportBuilder';
import ReportHome from './ReportHome';
import SingleTableBuilder from './ReportBuillders/SingleTableBuilder';

function ReportParent() {


    console.log('Report PARENT RE RENDERING')

    const toast = useRef(null);
  
 
    return ( 
        <Page>
            <React.Fragment>
            <Toast ref={toast} />
    
                    <Routes>
                        <Route path='compliance/:compType' element={<ProtectedRoute><ComplianceReport/></ProtectedRoute>} />
                        <Route path='reportHome/:listType/:reportTabType' element={<ProtectedRoute><ReportHome/></ProtectedRoute>} />
                        <Route path='reportBuilder/:listType/:reportTabType/:reportID' element={<ProtectedRoute><ReportBuilder/></ProtectedRoute>} />
                    </Routes>

            </React.Fragment>
        </Page>
    );

}

export default ReportParent; 