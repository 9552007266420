import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

import { PaperlessContextProvider } from '../context/PaperlessContext';
import { GlobalContextProvider } from '../context/GlobalContext';

import { DispatchContextProvider } from './Dispatch/context/DispatchContext';
import {FreightBillContextProvider } from './FreightBill/context/FreightBillContext';

import {InvoiceContextProvider } from './Invoice/context/InvoiceContext';
import {InvoiceCreateContextProvider } from './Invoice/context/InvoiceCreateContext';
import {PrintContextProvider } from './Invoice/context/PrintContext';  
import {ReportContextProvider } from './Report/context/ReportContext';
import {ReportBuilderContextProvider } from './Report/context/ReportBuilderContext';

import {PrintPayStatementContextProvider } from './PayStatement/context/PrintPayStatementContext';
import {PayStatementLineItemContextProvider } from './PayStatement/context/PayStatementLineItemContext';
import {PayStatementContextProvider } from './PayStatement/context/PayStatementContext';
import {PrevailingWageContextProvider } from './PrevailingWage/context/PrevailingWageContext';
import {NewPrevailingWageContextProvider } from './NewPrevailingWage/context/PrevailingWageContext';

import {JobsProfitContextProvider } from '../context/JobsProfitContext';
import { ExpenseContextProvider } from './Expenses/context/ExpenseContext';

import TopMenuComponent from './TopMenuComponent';
import PaperlessParent from './Paperless/PaperlessParent';
import DispatchParent from './Dispatch/DispatchParent';
import FreightBillParent from './FreightBill/FreightBillParent';
import PopUpParent from './PopUpComponents/PopUpParent';
import InvoiceParent from './Invoice/InvoiceParent';
import PayStatementParent  from './PayStatement/PayStatementParent';

import Settings from './MiscRoutes/Settings';
import Tablets from './MiscRoutes/Tablets';
import OrgSwap from './MiscRoutes/OrgSwap';
import JobsProfitParent from './JobsProfit/JobsProfitParent';
import PrevailingWageParent from './PrevailingWage/PrevailingWageParent';
import NewPrevailingWageParent from './NewPrevailingWage/PrevailingWageParent';
import ReportParent from './Report/ReportParent';

import AccountsHome from './HomePages/AccountsHome';
import ContactsHome from './HomePages/ContactsHome';
import EmployeesHome from './HomePages/EmployeesHome';
import LocationsHome from './HomePages/LocationsHome';
import DriversHome from './HomePages/DriversHome';
import PriorityHome from './HomePages/PriorityHome';
import TrucksHome from './HomePages/TrucksHome';
import TrailersHome from './HomePages/TrailersHome';
import TruckTypesHome from './HomePages/TruckTypesHome';
import MaterialsHome from './HomePages/MaterialsHome';
import CapabilitiesHome from './HomePages/CapabilitiesHome';
import FuelCardsHome from './HomePages/FuelCardsHome';
import ExpensesHome from './Expenses/ExpenseHome';
import ExpensesNamesHome from './HomePages/ExpenseNamesHome';
import ComplianceNamesHome from './HomePages/ComplianceNamesHome';

import CustomerListHome from './HomePages/QuickbooksHome/CustomerListHome';
import VendorListHome from './HomePages/QuickbooksHome/VendorListHome';
import ItemListHome from './HomePages/QuickbooksHome/ItemListHome';

import { StripeContextProvider } from '../context/StripeContext';
import SubscriptionPage from './Subscription/SubscriptionPage';
import BillingManagement from './Subscription/BillingManagement';
import TestPayment from './Subscription/TestPayment';

const MainContent = ({ loading }) => {
    const { user } = UserAuth();
    const location = useLocation();
    
    // Check if user is on signin page
    const isSignInPage = location.pathname === '/' || location.pathname === '/signin';
    
    // Only show TopMenuComponent if the user is logged in and not on signin page
    const showTopMenu = user && !isSignInPage;

    return (
              <div>  
            {!loading && (
                <GlobalContextProvider>
                    <StripeContextProvider>
                        {showTopMenu && <TopMenuComponent/>}
                        <PopUpParent/>
                        <Routes>
                            <Route path='/accounts' element={<AccountsHome/>} />
                            <Route path='/contacts' element={<ContactsHome/>} />
                            <Route path='/locations' element={<LocationsHome/>} />
                            <Route path='/drivers' element={<DriversHome/>} />
                            <Route path='/priority' element={<PriorityHome/>} />
                            <Route path='/trucks' element={<TrucksHome/>} />
                            <Route path='/trailers' element={<TrailersHome/>} />
                            <Route path='/trucktypes' element={<TruckTypesHome/>} />
                            <Route path='/materials' element={<MaterialsHome/>} />
                            <Route path='/capabilities' element={<CapabilitiesHome/>} />
                            <Route path='/materials' element={<MaterialsHome/>} />
							<Route path='/employees' element={<EmployeesHome/>} />
							<Route path='/fuelcards' element={<FuelCardsHome/>} />
                            <Route path='/expenses' element={ <ExpenseContextProvider>    <ExpensesHome/> </ExpenseContextProvider> } />
                            <Route path='/settings' element={<Settings/>} />
                            <Route path='/tablets' element={<Tablets/>} />
                            <Route path='/orgSwap' element={<OrgSwap/>} />
                            <Route path='/expensenames' element={<ExpensesNamesHome/>} />
                            <Route path='/compliancenames/:compType' element={<ComplianceNamesHome/>} />
                            <Route  path='/jobProfit/*'  element={<JobsProfitContextProvider><JobsProfitParent /></JobsProfitContextProvider>}  />
                            <Route  path='/prevailingwage/*'  element={<PrevailingWageContextProvider><PrevailingWageParent /></PrevailingWageContextProvider>}  />
							<Route  path='/newprevailingwage/*'  element={<NewPrevailingWageContextProvider><NewPrevailingWageParent /></NewPrevailingWageContextProvider>}  />

							<Route  path='/report/*'  element={
								<ReportContextProvider>
									<ReportBuilderContextProvider>
										<ReportParent />
									</ReportBuilderContextProvider>
								</ReportContextProvider>}
							/>
                            
							<Route  path='/dispatch/*'  element={<DispatchContextProvider><DispatchParent /></DispatchContextProvider>}  />
					
                            <Route path='/freightbill/*' element={<FreightBillContextProvider><FreightBillParent /> </FreightBillContextProvider>} />
                            <Route  path='/invoice/*'  element={ 
                                <InvoiceContextProvider> 
									<PrintContextProvider>
										<InvoiceCreateContextProvider>
                                            <InvoiceParent />
										</InvoiceCreateContextProvider>
                                    </PrintContextProvider>
                                </InvoiceContextProvider>}  
							/>

                            <Route  path='/paystatement/*'  element={ 
                                <PayStatementContextProvider> 
                                    <PrintPayStatementContextProvider>
                                        <PayStatementLineItemContextProvider>
                                            <PayStatementParent />
                                        </PayStatementLineItemContextProvider>
                                    </PrintPayStatementContextProvider>
                                </PayStatementContextProvider>}  
							/>
							<Route path ='/quickbooks/customers' element={<CustomerListHome/>}/>
							<Route path ='/quickbooks/vendors' element={<VendorListHome/>}/>
							<Route path ='/quickbooks/items' element={<ItemListHome/>}/>
						
                            <Route  path='/mobile/*'  element={ < PaperlessContextProvider ><PaperlessParent  /> </PaperlessContextProvider >}  />
                            <Route path='/subscription' element={<SubscriptionPage />} />
                            <Route path='/subscription/billing' element={<BillingManagement />} />
                            <Route path='/subscription/success' element={<div>Subscription Successful!</div>} />
                            <Route path='/subscription/cancel' element={<div>Subscription Cancelled</div>} />
                            <Route path='/subscription/test' element={<TestPayment />} />
                        </Routes>
                    </StripeContextProvider>
                </GlobalContextProvider>
            )}
           </div>    
    );
};

export default MainContent;