

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';

import { Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';

import { useDispatch } from './context/DispatchContext';
import { DispatchPrintProvider } from './context/DispatchPrintContext';
import { TruckBankContextProvider } from '../TruckBank/context/TruckBankContext';

import JobPage from './JobPage/JobPage';
import DispatchHome from './Home/DispatchHome';
import DispatchSearch from './Search/DispatchSearch';
import PrelimSearch from './Search/PrelimSearch';
import DispatchDatePopUp from './PopUps/DispatchDatePopUp';
import FreightPopUp from './PopUps/FreightPopUp';
import DispatchExpensePopUp from './PopUps/DispatchExpensePopUp';

import ProtectedRoute from '../ProtectedRoute';
import CustomDialog from '../PopUpComponents/CustomDialog';
import TruckBank from '../TruckBank/TruckBank';


function DispatchParent() {

  const { dispatchState, popUpFreight, freightPopUpVisible, setFreightPopUpVisible, expensePopUpVisible, setExpensePopUpVisible, dispatch, dispatchDate, dispatchExpenses,textFreight,updateLoadOrdersFromPopUp,
    dialogVisible, setDialogVisible,setDispatchDatePopUpVisible, dispatchDatePopUpVisible, dialogButtons, dialogText, dialogHeader, formatDate, moveOrCopyDispatch, homeFreightBills} = useDispatch();
    console.log('DISPATCH PARENT RE RENDERING= dispatchExpenses= ',dispatchExpenses)
    const dispatchColumnClass = dispatchState==='DispatchEdit' || dispatchState ==='DispatchHome' ? 'mbsc-col-lg-8 mbsc-col-12':  'mbsc-col-12';
    const toast = useRef(null);
    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch?.ID);
 
  
   
  return ( 
  <Page>
      <React.Fragment>
        <Toast ref={toast} />
		<DispatchPrintProvider>
			<div className="mbsc-grid" style={{padding:"0"}}>
				<div className="mbsc-row"  style={{margin:"0"}}>
					<div className={dispatchColumnClass}  style={{padding:"0"}} >
						<Routes>
							<Route path='edit/:id/:jobID/:jobDate/:dispatchView?' element={<ProtectedRoute><JobPage/></ProtectedRoute>} />
							<Route  path='newJob' element={<ProtectedRoute><JobPage/></ProtectedRoute>}  />
							<Route path='search/:objectType' element={<ProtectedRoute><DispatchSearch/></ProtectedRoute>} />
							<Route path='prelimSearch' element={<ProtectedRoute><PrelimSearch/></ProtectedRoute>} />
							<Route path='home/:jobDate' element={<ProtectedRoute><DispatchHome/></ProtectedRoute>} />
						</Routes>
					</div>
					<TruckBankContextProvider>
						{(dispatchState==='DispatchEdit' || dispatchState ==='DispatchHome')  && 
						(<div className="mbsc-col-lg-4 mbsc-col-12 "  style={{padding:"0", paddingLeft:".25em"}} ><TruckBank/>  </div>    )} 
					</TruckBankContextProvider>
				</div>
				<FreightPopUp freightBill={popUpFreight} visible={freightPopUpVisible} setVisible={setFreightPopUpVisible} sendText={textFreight} updateLoadOrders={updateLoadOrdersFromPopUp} /> 
				<DispatchExpensePopUp dispatch={dispatch} expenses={dispatchExpenses} visible={expensePopUpVisible} setVisible={setExpensePopUpVisible}  dispatchFreightBills={dispatchFreightBills}  /> 
				<CustomDialog   key={dialogVisible ? 'open' : 'closed'}  visible={dialogVisible} setVisible={setDialogVisible}  buttons={dialogButtons}  text={ dialogText} header={dialogHeader} /> 
				<DispatchDatePopUp setDispatchDatePopUpVisible={setDispatchDatePopUpVisible} dispatchDatePopUpVisible={dispatchDatePopUpVisible} dispatchDate={dispatchDate} formatDate={formatDate} moveOrCopyDispatch={moveOrCopyDispatch} />
			
		</div>
	  </DispatchPrintProvider>
      </React.Fragment>
    </Page>
  );
}

export default DispatchParent;