import React, { useEffect, useState, useRef, useMemo } from 'react';

import { UserAuth } from '../../context/AuthContext';

import { usePayStatement } from './context/PayStatementContext';
import { useLineItem } from './context/PayStatementLineItemContext';
import { usePrint } from './context/PrintPayStatementContext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { Button} from '@mobiscroll/react';
import { TabView, TabPanel } from 'primereact/tabview';

import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import { usePayStatementCreation } from './hooks/usePayStatementCreation';

const  PayStatementCreate =(props)=> {

    const { gearedUser, companies, formatDate,formatMoney } = UserAuth();
    const {payStatementRef, payStatementsRef,company, createDrivers,setCreateDrivers,  driversRef, outsideDriversRef, accountsRef,
        createOutsideDrivers,setCreateOutsideDrivers, createAccounts,setCreateAccounts, inputValues, setInputValues, setPayStatement,
       setPayStatements, calcDPSTotal, createCompanyRef, setPayStatementVisible} = usePayStatement();
  
    const { printAllPayStatements,checkPayStatements} = usePrint(); 
    const {makeFreightBillLineItem, makeExpenseLineItem} = useLineItem();
    const [tableDrivers, setTableDrivers] = useState([]);
    const [previewing, setPreviewing] = useState(false);
    const [activeTab, setActiveTab]= useState(0);
    const [selected, setSelected]=useState(true);

    const { updateDriverRefs, queryFreightBills, queryExpenses } = usePayStatementCreation();
    const currentDPSNumber = useRef(0);
    // Memoized sorted arrays
    const sortedDrivers = useMemo(() => 
        createDrivers
            .sort((a, b) => a.LastName.localeCompare(b.LastName))
            .map((driver, index) => ({ ...driver, index })),
        [createDrivers]
    );

    const sortedOutsideDrivers = useMemo(() => 
        createOutsideDrivers
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((driver, index) => ({ ...driver, index })),
        [createOutsideDrivers]
    );

    const sortedAccounts = useMemo(() => 
        createAccounts
            .sort((a, b) => a.Name.localeCompare(b.Name))
            .map((acc, index) => ({ ...acc, index })),
        [createAccounts]
    );

    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++){
                  
                if(companies[i].mainCompany) {

                    setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
                    createCompanyRef.current={...companies[i]}
					if(companies[i].CompanyID==="Andrade Trucking")setActiveTab(1);
                    console.log('set input Values.company!!!', companies[i])
                }
            }
        }
    }, [companies]);
    useEffect(()=>{
        setSelected(true)
        driversRef?.current?.forEach(driv => { driv.Selected = true})
        outsideDriversRef?.current?.forEach(driv => { driv.Selected = true})
        accountsRef?.current?.forEach(driv => { driv.Selected = true})
       // setActiveTab(0);
        console.log('we are runnign the loading use effect and drivers ref = ', driversRef.current)
        if(driversRef?.current) setCreateDrivers([...driversRef.current.filter(driv => driv.FreightBills?.length>0 || driv.Expenses?.length>0)]);
    },[]) 
    useEffect(() => {
        console.log('runnign teh query date use effect' + inputValues.EndDate)
        queryFreightBills();
        queryExpenses(); 
    }, [inputValues?.EndDate]);

    // Update table drivers based on active tab
    useEffect(() => {
        const tabDriverMap = {
            0: sortedDrivers,
            1: sortedOutsideDrivers,
            2: sortedAccounts
        };
        setTableDrivers(tabDriverMap[activeTab] || []);
    }, [activeTab, sortedDrivers, sortedOutsideDrivers, sortedAccounts]);

    const [filters, setFilters] = useState({
        realEarliestFBDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        unBilledFreights: { value: null, matchMode: FilterMatchMode.EQUALS },
        // Don't set anything here for Account.Name initially
    });


    const changeCreateCompany= (fieldName, value) => {
        createCompanyRef.current={...value}
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    }; 
    const getCurrentTime= () =>{
        var tempdate = new Date();
   
        var realTempDate = formatDate(tempdate, '/', 'MM/DD/YYYY');
        if (Number(tempdate.getMinutes()) < 10) realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + '0' + tempdate.getMinutes();
        else realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + tempdate.getMinutes();
        return realTempDate;
    }

    const createDPSObject = (Driver, payStatementNumber) => {
        let lastName = Driver.LastName ? Driver.LastName : Driver.Name;
        let realmID = inputValues.Company.realmID ? inputValues.Company.realmID : '';
        var expenseDriverID = Driver.ID;
        currentDPSNumber.current = payStatementNumber>currentDPSNumber.current ? payStatementNumber : currentDPSNumber.current;
        var QBVendorID = '';
        var QBVendorName = '';
        let Subhauler= false;
        if(activeTab===1 || activeTab ===2){  
            Subhauler = true;
            if(Driver.Quickbooks){  
                for(let i=0; i<Driver.Quickbooks.length; i++){
                    if(Driver.Quickbooks[i].realmID===realmID){
                        if(Driver.Quickbooks[i].QBVendorID)QBVendorID=Driver.Quickbooks[i].QBVendorID;
                        if(Driver.Quickbooks[i].QBVendorName)QBVendorName=Driver.Quickbooks[i].QBVendorName;
                    }
                }
            }
            expenseDriverID  =Driver.AccountID ?  Driver.AccountID : Driver.ID ;
        }

        return  {
            ID: '',
            Name: '',
            LastName:lastName,
            VNum: 1,
            VersionNum:"1",
            AccountID: Driver.Subhauler ? Driver.AccountID ? Driver.AccountID : Driver.ID : '',
            Company:{
                CompanyID:inputValues.Company.CompanyID,
                CompanyName:inputValues.Company.CompanyName,
                realmID:inputValues.Company.realmID,
                ID:inputValues.Company.ID,
                Address:inputValues.Company.Address,
                address2:inputValues.Company.address2,
                CompanyPhone: inputValues.Company.CompanyPhone,
                Fax: inputValues.Company.Fax,
                ShowDPSJobNumber:company.ShowDPSJobNumber ,
                ShowDPSPONumber:company.ShowDPSPONumber,
                ShowDPSHours:company.ShowDPSHours
            },
            DPSNum: payStatementNumber,
            MaxVersion:1,
            QBVendorID:QBVendorID,
            QBVendorName:QBVendorName,
            Subhauler:Subhauler,
            createdAt:getCurrentTime(),
            createdBy:gearedUser.Name,
            driverID: Driver.ID,
            driverEmail:Driver.Email ? Driver.Email : '',
            driverName:Driver.Name,
            driverAddress:Driver.Address,
            driverCity:Driver.City,
            driverState:Driver.State,
            driverZipCode:Driver.ZipCode,
            ParentID: Driver.ID,
            ParentName: Driver.Name,
            expenseDriverID:expenseDriverID,
            isExpenseDPS:false,
            showBrokerFee: false,
            showTrailerFee:false,
         
        
            QBDPSDate:formatDate(inputValues.DPSDate,'/','YYYY/MM/DD'),
            DPSDate : inputValues.DPSDate,
            StartDate : inputValues.StartDate,
            EndDate :inputValues.EndDate,
            LineItems: [],
            ExpensesQty:0,
            ExpensesTotal:0,
            PayStatementNotes: '',
            realmID:realmID,
            selectedIndex:0,
            Paid:false,
            FBCount:0,
            EarliestFB:'',
            FreightBills: [...Driver.FreightBills],
            Expenses: [...Driver.Expenses],
            Totals:[]
        };

    };
	console.log('tableDrivers = ', tableDrivers)
    const previewPayStatements = () => {
        payStatementsRef.current = [];
        let payStatementNumber = company.CurrentDPSNumber || 111111;
		console.log('payStatementNumber = ' +  payStatementNumber)
        const selectedDrivers = tableDrivers.filter(d => d.Selected);
        const createdPayStatements = selectedDrivers.map(driver => {
            const dps = createDPSObject(driver, payStatementNumber++);
            addLineItems(dps, driver);
            return { driver, dps };
        });

        // Pass activeTab to updateDriverRefs
        console.log('about to call updateDriverRefs')
        updateDriverRefs(createdPayStatements, activeTab);
        
        setPreviewing(true);
        if (createdPayStatements.length > 0) {
            setPayStatement(createdPayStatements[0].dps);
            payStatementRef.current = createdPayStatements[0].dps;
        }
    };

 
    const addLineItems = (DPS, Driver)=>{
        console.log('adding line items for driver = ', Driver)
        for(let i=0; i<Driver.FreightBills.length; i++)makeFreightBillLineItem(DPS,Driver.FreightBills[i] ); 
        for(let k=0; k<Driver.Expenses.length; k++)DPS.LineItems.push(makeExpenseLineItem(Driver.Expenses[k]));        
        let tempDPS = calcDPSTotal({...DPS});
        console.log('tempDPS tota' +  tempDPS.ParentID)
        
        payStatementsRef.current.push(tempDPS)
        Driver.DPS=tempDPS;
        Driver.Total=tempDPS.Total.Total;
        console.log('Driver = '+ Driver.Name + ' anmd their total = ', Driver.Total)
        setPayStatements([...payStatementsRef.current])
    }

 
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.realEarliestFBDate, '/', 'MM/DD/YYYY');
    };

    const handleSelectedChange = (disp, value) => {
        let tempDrivers = [...tableDrivers];
        
        // Update all relevant refs
        const updateRef = (ref, id) => {
            if (!ref?.current) return;
            for (let d = 0; d < ref.current.length; d++) {
                if (ref.current[d].ID === disp.ID) {
                    ref.current[d].Selected = value;
                }
            }
        };

        // Update all driver refs
        updateRef(driversRef);
        updateRef(outsideDriversRef);
        updateRef(accountsRef);

        // Update table drivers
        for (let i = 0; i < tempDrivers.length; i++) {
            if (tempDrivers[i].index === disp.index) {
                tempDrivers[i].Selected = value;
            }
        }
        
        setTableDrivers([...tempDrivers]);
    };

    const booleanBodyTemplate = (rowData, field) => {
        return (
            <Checkbox  onChange={(e) => handleSelectedChange(rowData, e.checked)}  checked={rowData[field]}  />
        );
    };
    const viewPreviewDPS=(rowData)=>{
        console.log('trying to veiw DPS ohhh' , rowData)
        console.log('payStatementsRef.current = ' , payStatementsRef.current)
        for(let i =0; i<payStatementsRef.current.length; i++){
            if(rowData.DPS.ParentID===payStatementsRef.current[i].ParentID){
                payStatementRef.current = {...payStatementsRef.current[i]};
                setPayStatement({... payStatementsRef.current[i]})
                setPayStatementVisible(true);
            }
        }
       
     
    }
    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) =>  options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

   
    const changePayStatementCreateTab =(e)=>{
    
       
            setActiveTab(e.index)
           
            if(e.index===0)setTableDrivers([...sortedDrivers])
            if(e.index===1)setTableDrivers([...sortedOutsideDrivers])
            if(e.index===2)setTableDrivers([...sortedAccounts])
    }
    const buttonBodyTemplate = (rowData) => {
        return <Button color="primary"  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}} onClick={(e) => viewPreviewDPS(rowData)}>Preview</Button>;
    };
    const selectAll = () =>{
        console.log('WE ARE RUNNING SELECT ALL AND TURNING IT TO ' + !selected)
        driversRef.current.forEach(driv => { driv.Selected = !selected})
        outsideDriversRef.current.forEach(driv => { driv.Selected = !selected})
        accountsRef.current.forEach(driv => { driv.Selected = !selected})
        if(activeTab===0) setCreateDrivers([...driversRef.current.filter(driv => driv.FreightBills?.length>0 || driv.Expenses?.length>0)]);
        if(activeTab===1)setCreateOutsideDrivers([...outsideDriversRef.current.filter(driv => driv.FreightBills?.length>0 || driv.Expenses?.length>0)])
        if(activeTab===2)setCreateAccounts([...accountsRef.current.filter(driv => driv.FreightBills?.length>0 || driv.Expenses?.length>0)])
        setSelected((prev) => { return !prev } );
    }
    const selectAllColumnHeader = ()=>{
        const buttonText = selected ? 'Unselect All' :"Select All"
        return(   
              <button style={{ margin: '0', padding: '.4em', width:"100%" }}     className="mbsc-ios mbsc-btn-primary mbsc-btn"   onClick={(e)=>selectAll()}  > {buttonText}</button>)
    
    }
    Number.prototype.formatMoney = function (c = 2, d = ".", t = ",") {
        let roundNum = this + 0.000001;
        const n = Math.abs(roundNum).toFixed(c);
        const s = roundNum < 0 ? "-" : "";
        const i = parseInt(n.split(".")[0], 10).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
            s +
            (j ? i.substr(0, j) + t : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
            (c ? d + n.split(".")[1] : "")
        );
    };
    const currencyBodyTemplate = (rowData, field) => {
     
        let fieldValue = Number(rowData[field]).formatMoney(2)
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${fieldValue}</span>
        );
    };
    return (
        <div className="mbsc-row">
            <div className="mbsc-col-4">  
                <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon comp-auto-input' showLabel={false} suggestions={companies} setValue={setInputValues} handleFieldChange={changeCreateCompany} disabled={previewing}/>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon comp-auto-input">Statement Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.DPSDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'DPSDate',e.value )} disabled={previewing}/>
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon comp-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} disabled={previewing}/>
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon comp-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} disabled={previewing}/>
                </div>
              
                <div className="flex justify-content-end">
                {!previewing &&  (<Button  color="primary"  onClick={(event) =>previewPayStatements()} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Preview PayStatements</Button>    )} 
                {previewing &&  (<div className="mbsc-row">
                    
                    <Button  color="primary"  className="mbsc-col-3" onClick={(event) =>setPreviewing(false)} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Back to Driver List</Button> 
                    <Button  color="primary" className="mbsc-col-3" onClick={(event) =>printAllPayStatements(true)} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Print All</Button> 

                    <Button  color="primary" className="mbsc-col-3" onClick={(event) =>checkPayStatements(currentDPSNumber.current)} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Create Pay Statements</Button> 
                </div>)}

                </div>
              
                    
            </div>
            <div className="mbsc-col-8">  
            {!previewing && (<TabView  style={{margin:"0"}} activeIndex={activeTab} onTabChange={(e) => changePayStatementCreateTab(e)}  >
                    <TabPanel header="Drivers" style={{marginTop:"0"}}  />
                    <TabPanel header="Outside Drivers" style={{marginTop:"0"}}  />
                    <TabPanel header="Non-Drivers" style={{marginTop:"0"}}  />
                </TabView>)}
                <DataTable value={previewing ? tableDrivers.filter(driver => driver.Selected) : tableDrivers} paginator rows={25} dataKey="index" filters={filters}  filterDisplay="row" emptyMessage="No drivers found.">
                    {!previewing && (<Column field="Selected" header={selectAllColumnHeader} dataType="boolean" style={{ minWidth: '6rem' }} body={(rowData) => booleanBodyTemplate(rowData, 'Selected')}  />)}
                    {previewing && (<Column field="Preview" header="Preview"  style={{ minWidth: '6rem' }} body={(rowData) => buttonBodyTemplate(rowData)}  />)}
                    <Column header="Name" field="Name"  filter filterField="Name" filterPlaceholder="Search by Name" />
                    <Column field="FBCount" header="# of Items" filter filterPlaceholder="Search "  />
                    <Column field="realEarliestFBDate" header="Earliest FB" dataType="date" sortable body={dateBodyTemplate}   filterPlaceholder="Search by Date" />
                    {previewing && (<Column field="Total" header="Total"  body={(rowData) => currencyBodyTemplate(rowData, 'Total')} filter  />)}

             
                </DataTable>
            </div>
        </div>
      
    );
};
    
    export default PayStatementCreate;