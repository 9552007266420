import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { useDispatchPrintContext } from '../context/DispatchPrintContext';
import InputTextParent from '../../InputComponents/InputTextParent';

const DispatchPrintPopUp = ({ setDispatchPrintPopUpVisible, dispatchPrintPopUpVisible, printDispatch }) => {
  const { showEmail, printEmail, setPrintEmail,  showBillRate, setShowBillRate,  showPayRate, setShowPayRate  } = useDispatchPrintContext();

  const closeDispatchPrintPopUp = () => {
    setDispatchPrintPopUpVisible(false);
  };

  const footerContent = (
    <div style={{paddingTop:'1em', textAlign:'center'}} className="flex align-items-center gap-2">
		<Button style={{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times" onClick={closeDispatchPrintPopUp} />
		<Button style={{fontSize:'1.5em', width:'9em'}} label={showEmail ? 'Email' : 'Print'} icon="pi pi-check" onClick={printDispatch} />
	</div>
  );

  return (
    <Dialog header={showEmail ? 'Email Dispatch' : 'Print Dispatch'} visible={dispatchPrintPopUpVisible} style={{ width: '40vw' }} breakpoints={{ '1400px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeDispatchPrintPopUp}>
      <div style={{ paddingLeft:"5em", paddingRight:"5em", marginTop:'2em', marginBottom:'2em'}} className='mbsc-grid'>
      
        
        {showEmail && (
			<div className="p-inputgroup mbsc-col-lg-8 mbsc-offset-lg-2" style={{height:"2.5em",padding:'0'}}>
				<span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}}>Email:</span>
				<InputTextParent value={printEmail} onChange={(e) => setPrintEmail(e.target.value)} />
			</div>
        )}
        
        <div className="p-inputgroup mbsc-col-xl-8 mbsc-offset-xl-2" style={{height:"2.5em",padding:"0",marginTop:".5em"}}>
			<span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}}>Show Bill Rate:</span>
			<Checkbox style={{ width: '100%' ,height:"2.5em"}} onChange={e => setShowBillRate(e.checked)} checked={showBillRate} />
		</div>
        
		<div className="p-inputgroup mbsc-col-xl-8 mbsc-offset-xl-2" style={{height:"2.5em",padding:"0",marginTop:".5em"}}>
			<span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}}>Show Pay Rate:</span>
			<Checkbox style={{ width: '100%' ,height:"2.5em"}} onChange={e => setShowPayRate(e.checked)} checked={showPayRate} />
			</div>
		</div>
    </Dialog>
  );
};

export default DispatchPrintPopUp;