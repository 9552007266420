import React, { useState } from 'react';
import AutoCompleteInput from './AutoCompleteInput';

const AutoCompleteInputParent = ({ fieldName, field, label, value, suggestions, handleFieldChange, setValue, disabled = false, databaseList, editClick, showLabel, labelClass, defaultNameType }) => {
    
    // Maintain local state for typing
    const [autoCompleteObject, setAutoCompleteObject] = useState({[fieldName]:value});

    // Handle typing changes


    return (
        <AutoCompleteInput
            label={label}
            fieldName={fieldName}
            field={field}
            setValue={setAutoCompleteObject}  // Handle input typing change locally
            disabled={disabled}
            value={autoCompleteObject[fieldName]}  // Controlled value for the input
            databaseList={databaseList}
            labelClass={labelClass}
            editClick={editClick}
            showLabel={showLabel}
            defaultNameType={defaultNameType}
            suggestions={suggestions}
            handleFieldChange={ handleFieldChange}  // Trigger field change when selection is finalized
        />
    );
};

export default AutoCompleteInputParent;
