import React, { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button} from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faLink } from '@fortawesome/free-solid-svg-icons';


import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 

import RowExpansionComponent from './LineItemComponents/RowExpansionComponent';

import { db } from '../../firebase';
import {  doc, getDoc, query, collection, onSnapshot, where } from 'firebase/firestore';
const InvoicesHome = (props) => {
    const { deleteDocument, gearedUser,companies,company, formatDate, accounts, formatMoney,setDocument } = UserAuth();
	const { setExpensePopUpVisible, setExpense } = useGlobal();
    const [isLoading, setIsLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [homeObject, setHomeObject]= useState({Company:{...company}})
	const [expandedRows, setExpandedRows] = useState({});
	
    const [searchDisabled, setSearchDisabled] = useState(false);
    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);
    
    const [inputValues, setInputValues]= useState({
        Account:{ID:'', Name:'No Account' }, 
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'), 
        Company:{...company}
    });


    const unsubscribeInvoicesRef = useRef([])
    const invoicesRef=useRef(null);
    const toast = useRef(null);

    console.log('home ojb company on load = ', homeObject.Company)
    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);

    useEffect(() => {
        if (inputValues?.StartDate)   queryInvoices();   
    }, []);

 

    let tempInvoices = [...invoices];
    if (inputValues.Company)   tempInvoices =  tempInvoices.filter(invoice => invoice.Company && invoice.Company.ID === inputValues.Company.ID);
    const sortedInvoices =  tempInvoices.sort((a, b) => {
        if (a.QueryDate >= b.QueryDate) return -1;
        if (a.QueryDate <= b.QueryDate) return 1;
        return 0;
    });        

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        'Account.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'LoadSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
		'DumpSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Paid: { value: null, matchMode: FilterMatchMode.EQUALS },
        // Don't set anything here for Account.Name initially
    });

   
    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
    };

    const changeAccount= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
    }; 

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const queryInvoices = () => {
      
        invoicesRef.current=[];
        if(unsubscribeInvoicesRef.current.length>0)for(var u=0;u<unsubscribeInvoicesRef.current.length; u++)unsubscribeInvoicesRef.current[u]();
        unsubscribeInvoicesRef.current=[];

        console.log('homeObject.Company = ' , inputValues);
        setIsLoading(true);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Invoices`;

        let invoiceQuery = query(collection(db, queryName), where("QueryDate", ">=", inputValues.StartDate), where("QueryDate", "<=", inputValues.EndDate));
         if (inputValues.Account.ID) invoiceQuery= query(invoiceQuery, where("Account.ID", "==", inputValues.Account.ID));
        unsubscribeInvoicesRef.current.push(onSnapshot(invoiceQuery, (querySnapshot) => {
            console.log('Invoice HOME SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {
                const tempInvoice = change.doc.data();
                tempInvoice.FreightBills = [];
                tempInvoice.ID = change.doc.id;
                tempInvoice.realInvoiceDate = new Date(tempInvoice.InvoiceDate);
                tempInvoice.realJobDate = new Date(tempInvoice.StartDate)
                if (change.type === "added") {
                    invoicesRef.current.push(tempInvoice); 
                }
                if (change.type === "modified") {
                    const invoiceIndex = invoicesRef.current.findIndex((d) => d.ID === tempInvoice.ID);
                    invoicesRef.current[invoiceIndex] = tempInvoice;
                }
                if (change.type === "removed") {
                    const invoiceIndex = invoicesRef.current.findIndex((d) => d.ID === tempInvoice.ID);
                    invoicesRef.current.splice(invoiceIndex,1);
                }
       
            });

            console.log('setting full home Invoicees and invoicesRef.current = ',invoicesRef.current);
          
            setIsLoading(false);
            setInvoices([... invoicesRef.current]) // Return the fetched invoices

        }));
      
    };
  
    const updateInvoice = (Invoice)=>{
        let tempInvoice ={...Invoice};
        delete tempInvoice.ChildInvoices;
        delete tempInvoice.Dates;
        delete tempInvoice.Dispatches;
        delete tempInvoice.Dispatch;
        delete tempInvoice.Locations;
        delete tempInvoice.LineItemWrapper;
        delete tempInvoice.LineItemTables;
        tempInvoice.Expenses = [];
        tempInvoice.FreightBills = [];
        let dumpSite = {
            ID:tempInvoice.DumpSite.ID,
            Name: tempInvoice.DumpSite.Name ? tempInvoice.DumpSite.Name :'',
            Address:tempInvoice.DumpSite.Address ? tempInvoice.DumpSite.Address  :'',
            Address2:tempInvoice.DumpSite.Address2 ? tempInvoice.DumpSite.Address2:'',
            City:tempInvoice.DumpSite.City ? tempInvoice.DumpSite.City:'',
            State:tempInvoice.DumpSite.State ? tempInvoice.DumpSite.State:'',
            ZipCode:tempInvoice.DumpSite.ZipCode ?tempInvoice.DumpSite.ZipCode   :'',
          
            fullAddress:tempInvoice.DumpSite.fullAddress ? tempInvoice.DumpSite.fullAddress :'',
        }
        let loadSite = {
            ID:tempInvoice.LoadSite.ID,
            Name: tempInvoice.LoadSite.Name ? tempInvoice.LoadSite.Name :'',
            Address:tempInvoice.LoadSite.Address ? tempInvoice.LoadSite.Address  :'',
            Address2:tempInvoice.LoadSite.Address2 ? tempInvoice.LoadSite.Address2:'',
            City:tempInvoice.LoadSite.City ? tempInvoice.LoadSite.City:'',
            State:tempInvoice.LoadSite.State ? tempInvoice.LoadSite.State:'',
            ZipCode:tempInvoice.LoadSite.ZipCode ?tempInvoice.LoadSite.ZipCode   :'',
     
            fullAddress:tempInvoice.LoadSite.fullAddress ? tempInvoice.LoadSite.fullAddress :'',
        }
        tempInvoice.DumpSite = dumpSite;
        tempInvoice.LoadSite = loadSite;
        tempInvoice.QBUpdated = true;
        console.log('tem,p Inovice = ' , tempInvoice)
        setDocument(tempInvoice, tempInvoice.ID, "Invoices")
    }
    const updateInvoices = () =>{
        let tempInvoices = [];
        for(let i=0; i<invoicesRef.current.length;i++)updateInvoice(invoicesRef.current[i])
    }

    const handleChangeInvoiceCompany = (fieldName, value) =>{
        setHomeObject((prev) => ({ ...prev, [fieldName]: value }));
    }

    const renderHeader = () => (
        <div className="mbsc-grid mbsc-row ">
            <span className="mbsc-col-xl-1">Invoices</span>
            <div className="mbsc-col-xl-3">
                <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon search-auto-input'  suggestions={companies} setValue={setInputValues} handleFieldChange={changeCompany} />
            </div> 
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                </div>
            </div>
                      
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                <AutoCompleteInput label="Customer" fieldName="Account" field="Name" hideAddCustom={true} value={inputValues.Account} labelClass='p-inputgroup-addon search-auto-input'  disabled={searchDisabled} databaseList={'Accounts'} suggestions={accounts} setValue={setInputValues} handleFieldChange={changeAccount} />
                <Button  color="primary" disabled={searchDisabled} onClick={(e) => queryInvoices()} style={{margin:"0", height:"50%", float:"right", width:"45%", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em"}}>Search Invoices</Button> 
            </div>

              {/**   <div className="p-inputgroup mbsc-col-3 mbsc-offset-4">
                    <span className="p-inputgroup-addon">Range:</span>
                    <Dropdown value={queryRange} onChange={(e) => setQueryRange( e.value)} options={queryRanges} optionLabel="text" placeholder="Select a Range" className="w-full md:w-14rem" />
            </div>
            <div className="mbsc-col-3">
                <AutoCompleteInput fieldName="Company" field="CompanyName" value={homeObject.Company} suggestions={companies}  setValue={setHomeObject}  handleFieldChange={handleChangeInvoiceCompany}   />
            </div>*/}

        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit = (rowData) => { 
       var win = window.open('/invoice/invoice/'+rowData.ID, '_blank');
       win.focus();
    };
    
    const handleOpenQuickbooks = (rowData)=>{
        var win = window.open('https://qbo.intuit.com/app/invoice?txnId=' +rowData.QBInvoiceID, '_blank');
    }  
     
    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
             <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

    const quickbooksBodyTemplate = (rowData) => {
    
        if(rowData.QBInvoiceID){
         return(
            <button type="button" onClick={() => handleOpenQuickbooks(rowData)}>
                <FontAwesomeIcon icon={faLink} />
            </button>)
        }
    };

    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this Invoice?")) {
               await deleteDocument(rowData,'Invoices' )
            } 
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

    const booleanFilterTemplate = (options) => {
        return (
          <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
            value={options.value !== null ? options.value : undefined}
            onChange={(e) => options.filterApplyCallback(e.value)}
          />
        );
    };

    const booleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'green' : 'red';
        return (
          <i style={{color:color}}
            className={classNames('pi', {
              'true-icon pi-check-circle': rowData[field],
              'false-icon pi-times-circle': !rowData[field]
            })}
          />
        );
    };

	const invoiceNumberBodyTemplate = (rowData) => {
		return (
			<button 
			style={{ 
				margin: '0', 
				height:"90%", 
				marginTop:".1em", 
				padding: '.1em', 
				marginLeft:".5em", 
				width:"90%" 
			}} 
			onClick={() => handleEdit(rowData)} 
			className="mbsc-ios mbsc-btn-primary mbsc-btn"
		>
			{rowData.InvoiceNumber}
		</button>
		)
	}
    const dateBodyTemplate = (rowData, fieldName) => {
        return formatDate( rowData[fieldName], '/', 'MM/DD/YYYY');
    };

    const currencyBodyTemplate = (rowData, fieldOne, fieldTwo) => {
        let formattedNumber = Number(Number(rowData[fieldOne][fieldTwo]).toFixed(2)).formatMoney(2)
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
	const openExpensePopUp = async (lineItem) => {
        try {
            const expenseDoc = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`, lineItem.ID);
            const expenseSnap = await getDoc(expenseDoc);
            console.log('lineitem we opening  = ', lineItem)
            if (expenseSnap.exists()) {
				console.log('expenseSnap.data() = ', expenseSnap.data())
                const expense = {...expenseSnap.data(), ID: expenseSnap.id};
                setExpense(expense);
                setExpensePopUpVisible(true);
            } else {
                console.error('No expense found with ID:', lineItem.ID);
            }
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };


	const rowExpansionTemplate = (data) => (<RowExpansionComponent invoice={data} openExpensePopUp={openExpensePopUp}/>)

	const onRowToggle = (e) => {

        setExpandedRows(e.data);

      
    };

    const header = renderHeader();

    // Add this CSS style to your component

	const columnPassThrough = (label) =>{
		return {
			root: { 
				className: 'invoice-number-column',
				'data-label': label   // Add a unique className
			},
			columnFilter:{
				style:{ width:"100%"}
			},
			filterInput: {    // Target the filter input specifically
				className: 'full-width-filter',
				style: {
					width: '100%'
				}
			},
			headerFilterClearButton: {  // Hide the menu button
				style: {
					display: 'none'
				}
			}
		};
	}
	const createInvoiceHomeColumn = ({label,  ...props}) => {
		const filterPlaceholder = "Search by " + label;

		return (
		  <Column 
				key={label}
				pt={columnPassThrough(label)} 
				header={label} 
			
				filterPlaceholder={filterPlaceholder}
				showFilterMenu={false} 
				{...props}
			/>
		);
	};

	const invoiceHomeColumns = [
		{
			label: 'Delete',
			style: { textAlign: "center" },
			body: deleteBodyTemplate
		},
	
		{
			label: 'Invoice Num',
			field: "InvoiceNumber",
			style: { maxWidth: '7rem' },
			sortable: true,
			filter: true,
			body:invoiceNumberBodyTemplate
		},
		{
			label: 'Job Num',
			field: "JobNumber",
			style: { maxWidth: '6.75rem' },
			sortable: true,
			filter: true
		},
		{
			label: 'Account',
			field: "Account.Name",
			style: { maxWidth: '11.5rem' },
			filter: true,
			body: (rowData) => rowData.Account?.Name || 'N/A'
		},
		{
			label: 'Invoice Date',
			field: "realInvoiceDate",
			sortable: true,
			style: { maxWidth: '6.75rem' },
			body: (rowData) => dateBodyTemplate(rowData, 'realInvoiceDate')
		},
		{
			label: 'Job Date',
			field: "realJobDate",
			sortable: true,
			body: (rowData) => dateBodyTemplate(rowData, 'realJobDate')
		},
		{
			label: 'Load Site',
			field: "LoadSite.Name",
			style: { maxWidth: '17rem' },
			filter: true,
			body: (rowData) => rowData.LoadSite?.Name || 'N/A'
		},
		{
			label: 'Dump Site',
			field: "DumpSite.Name",
			style: { maxWidth: '17rem' },
			filter: true,
			body: (rowData) => rowData.DumpSite?.Name || 'N/A'
		},
		{
			label: 'Paid',
			field: "Paid",
			style: { minWidth: '3rem', maxWidth: '4rem' },
			filter: true,
			filterElement: booleanFilterTemplate,
			body: (rowData) => booleanBodyTemplate(rowData, 'Paid')
		},
		{
			label: 'Total',
			field: "Total.Total",
			style: { minWidth: '6rem' },
			sortable: true,
			body: (rowData) => currencyBodyTemplate(rowData, 'Total', 'Total')
		},
		{
			label: 'Balance',
			field: "Balance.Total",
			style: { minWidth: '6rem' },
			sortable: true,
			body: (rowData) => currencyBodyTemplate(rowData, 'Balance', 'Total')
		},
		{
			label: 'QB Link',
			style: { maxWidth: '5rem', textAlign: "center" },
			field: "QBInvoiceID",
			body: quickbooksBodyTemplate
		}
	];

    return (
        <div className="card mbsc-grid">
		
			
            <Toast ref={toast} />
            <DataTable 
				value={sortedInvoices} 
				rows={25} 
				paginator 
				dataKey="ID" 
				stripedRows 
				filters={filters} 
				header={header} 
				filterDisplay="row" 
				emptyMessage="No invoices found."
				expandedRows={expandedRows}
				onRowToggle={(e) => onRowToggle(e)}
				rowExpansionTemplate={rowExpansionTemplate}
			
			>
				<Column   expander  style={{ maxWidth: '2.5rem' }}    headerStyle={{ width: '2.5rem' }}  />
				{invoiceHomeColumns.map((column, index) => (
					createInvoiceHomeColumn({...column, key: index})
				))}
				



            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
};

export default InvoicesHome;
