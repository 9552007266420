import React,{useState,useContext,createContext, useRef, useEffect} from 'react'
import { UserAuth } from './AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
    const [accountVisible, setAccountVisible] = useState(false);
    const [account, setAccount] = useState({});

    const [contactVisible, setContactVisible] = useState(false);
    const [contact, setContact] = useState({});

    const [employeeVisible, setEmployeeVisible] = useState(false);
    const [employee, setEmployee] = useState({});

    const [driverVisible, setDriverVisible] = useState(false);
    const [driver, setDriver] = useState({});

    const [materialVisible, setMaterialVisible] = useState(false);
    const [material, setMaterial] = useState({});

	const [expensePopUpVisible, setExpensePopUpVisible] = useState(false);
	const [expense, setExpense] = useState({});

	const [fuelCardVisible, setFuelCardVisible] = useState(false);
	const [fuelCard, setFuelCard] = useState({});

    const [nameVisible, setNameVisible] = useState(false);
    const [nameObject, setNameObject] = useState({});
    const [nameType, setNameType] = useState('');

    const [truckVisible, setTruckVisible] = useState(false);
    const [truck, setTruck] = useState({});

    const [trailerVisible, setTrailerVisible] = useState(false);
    const [trailer, setTrailer] = useState({});

    const [truckTypeVisible, setTruckTypeVisible] = useState(false);
    const [truckType, setTruckType] = useState({});

    const [locationVisible, setLocationVisible] = useState(false);
    const [location, setLocation] = useState({});
	
    const [tabletDriver, setTabletDriver] = useState({}); 
    const [tabletDriverVisible, setTabletDriverVisible] = useState(false);

    const [topMenuOptions, setTopMenuOptions] = useState({});

    const [imageURL,setImageURL] = useState('');
    const imageRef = useRef(null);

    const [globalDispDate, setGlobalDispDate]=useState(null);
    const [globalFreightDate, setGlobalFreightDate]=useState(null);
   
	const navigate = useNavigate();
    const locationHook = useLocation();
    const prevLocationRef = useRef(locationHook);

    const globalFreightBillRef = useRef();
    const globalFormDirty = useRef(false);

    useEffect(() => {
        let tomorrowsDate = new Date();
        tomorrowsDate.setDate(tomorrowsDate.getDate() + 1);
        tomorrowsDate =  formatDate(tomorrowsDate, '/', 'YYYY/MM/DD');
        setGlobalFreightDate(tomorrowsDate);
        setGlobalDispDate(tomorrowsDate);
        
    },[])

    useEffect(() => {
        // This function will run whenever the route changes
        const prevLocation = prevLocationRef.current;
        
        console.log('prevLocation = ' + prevLocation.pathname)
        console.log('globalFormDirty.current= ' +globalFormDirty.current)
        if(prevLocation.pathname.includes('freightbill/freightbill') && prevLocation.pathname!==locationHook.pathname)  {
            if(globalFormDirty.current ){
                if (window.confirm('Warning: You are leaving Freight Bill without saving, would you like to continue? ')) {
                    console.log('SETTING THE GLOLBAL FREIGHT BILL REF TO NULL!!')
                    globalFreightBillRef.current=null;
                    globalFormDirty.current=false;
            }else navigate(prevLocation.pathname);
        }else  globalFreightBillRef.current=null;
        
    }
        if ((prevLocation.pathname.includes('dispatch/edit') || prevLocation.pathname.includes('freightbill/freightbill') ||  prevLocation.pathname.includes('dispatch/home'))
        && (!locationHook.pathname.includes('dispatch/edit') && !locationHook.pathname.includes('freightbill/freightbill') && !locationHook.pathname.includes('dispatch/home')))setTopMenuOptions([]);
        
        prevLocationRef.current = locationHook;
      
    }, [locationHook]);

    const { accounts, contacts, drivers, materials, truckTypes, locations, trucks, trailers, expenseNames, company, addDocument, employees,
        capabilities, companies, fuelCards, driverComplianceNames, truckComplianceNames, trailerComplianceNames, logout } = UserAuth();

    const showAccountPopUp = (Account) =>{
        for(let i=0; i<accounts.length; i++)if(accounts[i].ID===Account.ID)setAccount({...accounts[i]})
        setAccountVisible(true);
    }

    const showContactPopUp = (Contact) =>{
        if(Contact.ID){
            for(let i=0; i<contacts.length; i++)if(contacts[i].ID===Contact.ID)setContact({...contacts[i]})
        }else setContact({...Contact})
        setContactVisible(true);
    }

	const showEmployeePopUp = (Employee) =>{
        if(Employee.ID){
            for(let i=0; i<employees.length; i++)if(employees[i].ID===Employee.ID)setEmployee({...employees[i]})
        }else setEmployee({...Employee})
        setEmployeeVisible(true);
    }

    const showDriverPopUp = (Driver) =>{
        if(Driver.ID){
            for(let i=0; i<drivers.length; i++)if(drivers[i].ID===Driver.ID)setDriver({...drivers[i]})
        }else setDriver({...Driver})
        setDriverVisible(true);
    }

    const showMaterialPopUp = (Material) =>{
        for(let i=0; i<materials.length; i++)if(materials[i].ID===Material.ID)setMaterial({...materials[i]})
        setMaterialVisible(true);
    }

    const showTruckPopUp = (Truck) =>{
        console.log('shwoign truck for ',Truck)
        for(let i=0; i<trucks.length; i++)if(trucks[i].ID===Truck.ID){
            console.log('found truck ', trucks[i])
            setTruck({...trucks[i]})
        }
        setTruckVisible(true);
    }

	const showFuelCardPopUp = (FuelCard) =>{
        for(let i=0; i<fuelCards.length; i++)if(fuelCards[i].ID===FuelCard.ID)setFuelCard({...fuelCards[i]})
        setFuelCardVisible(true);
    }
    const showTrailerPopUp = (Trailer) =>{
        for(let i=0; i<trailers.length; i++)if(trailers[i].ID===Trailer.ID)setTrailer({...trailers[i]})
        setTrailerVisible(true);
    }

    const showTruckTypePopUp = (TruckType) =>{
        for(let i=0; i<truckTypes.length; i++)if(truckTypes[i].ID===TruckType.ID)setTruckType({...truckTypes[i]})
        setTruckTypeVisible(true);
    }

    const showLocationPopUp = (Location) =>{
        for(let i=0; i<locations.length; i++)if(locations[i].ID===Location.ID)setLocation({...locations[i]})
        setLocationVisible(true);
    }

    const showDefaultNamePopUp = (defaultName) =>{
        for(let i=0; i<locations.length; i++)if(locations[i].ID===Location.ID)setLocation({...locations[i]})
        setLocationVisible(true);
    }

    const showTabletDriverPopUp = (Tablet) =>{
        setTabletDriver(Tablet);
        setTabletDriverVisible(true);
    }

    const showImagePopUp = (url, event) =>{
        setImageURL(url);
        imageRef.current.toggle(event);
      
    }

    const newAccount = () =>{
        var Account = {
            ID: '',
            Qty: '', 
            PayFrequency: 'Bi-Weekly',
            Name: '',
            Priority: '',
      
            Fax: '',
            Website: '',
            Address: '',
            City: '',
            State: '',
            ZipCode: '',
      
            PhysicalAddress: '',
            PhysicalAddressName: '',
            PhysAddress: '',
            PhysCity: '',
            PhysState: '',
            PhysZipCode: '',
            Phone: '',
            PhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
            OfficePhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
            DedicatedSubhauler: false,
            TaxID: '',
            Track1099: false,
            DriverName:'',
            DriverEmail:'',
      
            Broker: false,
            Subhauler: false,
            Puller: false,
            Contractor: false,
            phoneOK:false,
            Status: 'Active',
      
            BrokerFee: '',
            TrailerFee: '',
            PaidBrokerFee: '',
            paidTruckingBrokerTotal:0,
            Notes: '',
            InvoiceNotes: '',
            TermsAndCond: '',
            ShowPhysical: false,
      
            Username: '',
            QBID: '',
            QBSync: '',
            QBVendorID: '',
            QBVendorSync: '',
      
            Contacts: [],
            TruckTypes: [],
            Trailers: [],
            Capabilities:[],
            ComplianceNames: [],
            Compliances: [],
            Trucks: [],
            Quickbooks:[],
            NoteList:[],
            Driver: {
                ID: '',
                Truck: {
                    ID: '',
                    Name: 'No Truck',
                },
      
                Trailer: {
                    ID: '',
                    Name: 'No Trailer',
                }
            }
        }
        for(var q=0; q<companies; q++){
            if (companies[q].realmID) {
                var newQuickBooks = {
                    ID: companies[q].ID,
                    CompanyName: companies[q].CompanyName,
                    realmID: companies[q].realmID,
                    QBCustomerID: '',
                    QBCustomerSync: '',
                    QBVendorID: '',
                    QBVendorSync: '',
                    QBUpdated: false
                };
                Account.Quickbooks.push(newQuickBooks);
            }
        }
        
        setAccount(Account);
        setAccountVisible(true)
    }

    const newContact = () =>{
        let Contact = {
            ID: '',
            officeAccount:false,
            Account: {
                ID: '',
                Name:'',
                OrgName:''
            },
            Name: '',
            FirstName: '',
            LastName: '',
            PhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
            phoneOK:false,
            Phone: '',
            Email: '',
            Department: 'Foreman',
            Fax: '',
            createLogIn:false
        }
         setContact(Contact);
         setContactVisible(true)
    }

	const newEmployee = () =>{
        let Employee = {
            ID: '',
            Name: '',
			FirstName: '',
			LastName: '',
			PhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
			phoneOK:false,
            Department: 'Admin',
            Phone: '',
		}
		setEmployee(Employee);
		setEmployeeVisible(true)
	}
    const newDriver = () =>{
        //newdriver code here
        console.log('lezz go!')
        let Driver = {
            ID: '',
            FirstName: '',
            LastName: '',
            Nickname: '',
            Username: '',
            Address: '',
            City: '',
            State: '',
            ZipCode: '',
            Group: '',
            MobilePhone: '',
            PhoneObject:{  Phone1:'', Phone2:'',  Phone3:'', Phone4:''},
            phoneOK:false,
            HomePhone: '',
            Email: '',
            DriversLicense: '',
            SSN: '',
            Status: 'Active',
            FederalStatus:'Single',
            PayType: 'Hour',
            PayFrequency: 'Semi-Monthly',
            PayRate: '',
            TravelRate:'',
            Subhauler: false,
         
            Truck: {ID: '', Name: 'No Truck',  },
            Trailer: { ID: '',  Name: 'No Trailer',  },
            TruckTypes:[],
            LicenseExpires: '',
            BirthDate: formatDate(new Date(),'/','MM/DD/YYYY'),
            HiredDate: formatDate(new Date(),'/','MM/DD/YYYY'),
            TerminatedDate:formatDate(new Date(),'/','MM/DD/YYYY'),
            ContactID: '',
            AccountID: '',
            UserID: '',
            Note: '',
            Notes:[],
            Capabilities:[],
            Compliances: []
        };
        setDriver(Driver);
        setDriverVisible(true)

    }
    const newLocation = () =>{
       let Location = {
            ID: '',
            Name: '',
            Address: '',
            State:'',
            City: '',
            ZipCode: '',
            Plant:false
        }
        setLocation(Location);
        setLocationVisible(true)
    }

    const newMaterial = () =>{
        let Material = {
             ID: '',
             Name: '',
            YardsPerTon:''
         }
         setMaterial(Material);
         setMaterialVisible(true)
    }
    const newTruck = () =>{
        let Truck = {
            ID: '',
            Name: '',
            VIN:'',
            License:'',
            Make:'',
            Model:'',
            Year:'',
            Status:'Active',
            Compliances:[]
        }
        setTruck(Truck);
        setTruckVisible(true)
    }
    const newTrailer = () =>{
        let Trailer = {
            ID: '',
            Name: '',
            Make:'',
            Model:'',
            License:'',
            Year:'',
            VIN:'',
            Status:'Active',
            Compliances:[]
        }
        setTrailer(Trailer);
        setTrailerVisible(true)
    }
    const newTruckType= () =>{
        let TruckType = {
            ID: '',
            Name: '',
            TruckCode: '',
            DefaultRate: '',
            WeekendRate: '',
            NightRate: '',
            NumOfAxles: '',
            CapacityTons: '',
            CapacityYards: '',
            Default:false
        }
        setTruckType(TruckType);
        setTruckTypeVisible(true)
    }

	const newExpense = () =>{
		let tempJobDate = formatDate(new Date(),'/','MM/DD/YYYY');
		let tempQueryDate = formatDate(new Date(),'/','YYYY/MM/DD');
		let Expense = {
            ID: "",
            Name: expenseNames[0],
            parentExpense:'',
            description: expenseNames[0].Name,
            qty: Number(0),
            rate: Number(0),  
            total: Number(0),
            addToFreights:false,
            FBNO:'',
            floatingBillExpense:true,
            floatingPayExpense:true,
            QueryDate:tempQueryDate,
            JobDate:tempJobDate,
            Truck:'',
            Company:company,
            FreightBill:'',
            dispatchID:'floatingDispatchExpense',
            reDriver: false,
            bCustomer: false,
            reCustomer: false,
            bDriver: false,
            bill:false,
            billed:false,
            paid:false,
            pay:false,
            onHold:false,
            BillTo:{Name:'', ID:''},
            PayTo:{Name:'', ID:''},
            isEditable: true,
			taxRate:  0,
            MaterialExpense:  false
        };
		console.log('we are creating an empty line item = ', Expense);
        addDocument(Expense, 'Expenses');
		setExpensePopUpVisible(true);
		setExpense(Expense);
	}

	const newFuelCard = () =>{
        let FuelCard = {
            ID: '',
            Name: '',
            Type: 'Fuel Card',
			Driver: {
                ID: '',
                Name: ''
            },
		
			
		}
		setFuelCard(FuelCard);
		setFuelCardVisible(true)
	}
    const newDefaultName = (compType) =>{
        let Name = {
            ID: '',
            Name: '',
            Type:compType
         
        }
        setNameObject(Name);
        setNameType('DefaultNames');
        setNameVisible(true)
    }

    const newCapability = () =>{
        let Name = {
            ID: '',
            Name: ''
         
        }
        setNameObject(Name);
        setNameType('Capabilities');
        setNameVisible(true);
    }
   
    const showNamePopUp = (NameObject, nameType) => {
 
        const dataMap = {
            capabilities: {
                array: capabilities,
                collectionName: 'Capabilities'
            },
            expenseNames: {
                array: expenseNames,
                collectionName: 'DefaultNames'
            },
            driverComplianceNames: {
                array: driverComplianceNames,
                collectionName: 'DefaultNames'
            },
            truckComplianceNames: {
                array: truckComplianceNames,
                collectionName: 'DefaultNames'
            },
            trailerComplianceNames: {
                array: trailerComplianceNames,
                collectionName: 'DefaultNames'
            },
       
        };
    
        // Access the correct array and collection name using the nameType string
        const dataEntry = dataMap[nameType];
    
        if (dataEntry) {
            const nameArray = dataEntry.array;
    
            for (let i = 0; i < nameArray.length; i++) {
                if (nameArray[i].ID === NameObject.ID) {
                    setNameObject(nameArray[i]);
                    break;
                }
            }
            setNameType(dataEntry.collectionName);
            setNameVisible(true);
        } 
    };

    const formatDate = (date, divider, format) =>{
        var d = new Date(date),
            month = '' + (d.getMonth() + 1), 
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2)  month = '0' + month;
        if (day.length < 2)  day = '0' + day;
    
        if(format==='YYYY/MM/DD')return [year, month, day].join(divider);
        else return [month, day,year].join(divider);
    }

 

    return (
        <GlobalContext.Provider value={{
            account, setAccount, accountVisible, setAccountVisible, showAccountPopUp, newAccount,
            contact, setContact, contactVisible, setContactVisible, showContactPopUp, newContact,
			fuelCard, setFuelCard, fuelCardVisible, setFuelCardVisible, showFuelCardPopUp, newFuelCard,
			employee, setEmployee, employeeVisible, setEmployeeVisible, showEmployeePopUp, newEmployee,
            driver, setDriver, driverVisible, setDriverVisible, showDriverPopUp, newDriver,
            material, setMaterial, materialVisible, setMaterialVisible, showMaterialPopUp, newMaterial,
            truck, setTruck, truckVisible, setTruckVisible, showTruckPopUp, newTruck,
            trailer, setTrailer, trailerVisible, setTrailerVisible, showTrailerPopUp, newTrailer,
            truckType, setTruckType, truckTypeVisible, setTruckTypeVisible, showTruckTypePopUp, newTruckType,
            location, setLocation, locationVisible, setLocationVisible, showLocationPopUp, newLocation,
            tabletDriver, setTabletDriver, tabletDriverVisible, setTabletDriverVisible, showTabletDriverPopUp,
            nameObject, nameType, nameVisible, setNameVisible, showNamePopUp, newDefaultName, newCapability,logout,
            imageURL, imageRef, showImagePopUp, formatDate, topMenuOptions, setTopMenuOptions, globalFreightDate, setGlobalFreightDate, globalDispDate, setGlobalDispDate, prevLocationRef,
            globalFreightBillRef, globalFormDirty,expensePopUpVisible, setExpensePopUpVisible,expense, setExpense, newExpense
         
        }}>
            {children}
        </GlobalContext.Provider>
    );
};
export const useGlobal= () => useContext(GlobalContext);