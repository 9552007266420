import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button } from 'primereact/button'; // Replace with the appropriate Button component
import { Calendar } from 'primereact/calendar'; // Replace with Mobiscroll Calendar if applicable
import { Panel } from 'primereact/panel'; // Replace with Mobiscroll Calendar if applicable
import { Checkbox} from 'primereact/checkbox'; // Replace with Mobiscroll Calendar if applicable
import {   query, collection, onSnapshot, where } from 'firebase/firestore';
import { getStorage, ref,getDownloadURL,uploadString } from "firebase/storage";
import pdfMake from 'pdfmake/build/pdfmake';
import mobiscroll from '@mobiscroll/react4';

import { UserAuth } from '../../../../context/AuthContext';
import { db } from '../../../../firebase';
import {useDispatch } from '../../context/DispatchContext';
import InputTextParent from '../../../../components/InputComponents/InputTextParent'; 
import AutoCompleteInput from '../../../../components/InputComponents/AutoCompleteInput'; 
import AutoSelectInputNumber from '../../../../components/InputComponents/AutoSelectInputNumber'; 
import SignaturePopUp from '../../../../components/PopUpComponents/SignaturePopUp'; 
import PrelimList from '../../Lists/PrelimList'; 
import useFieldSpecificDebounce from '../../../../hooks/useFieldSpecificDebounce';

//import pdfFonts from 'pdfmake/build/vfs_fonts';


const JobPrelim = (props) => {
   
    const {job, formatDate, setJob, formatMoney,  setDialogText, setDialogHeader, setDialogButtons,  setDialogVisible, prelimEmailRef, handleEmailChange} = useDispatch();
    const { gearedUser,  accounts, updateDocument, addDocument, deleteDocument, company} = UserAuth();
    const [prelimHistory, setPrelimHistory]= useState([]);
    const [releaseType, setReleaseType]= useState('Conditional');
    const [signatureVisible, setSignatureVisible]=useState(false);
    const debouncedFieldUpdater = useFieldSpecificDebounce(500);
    const [inputValues, setInputValues] = useState({
        ProjectName: '',
        ThroughDate: null,
        SignatureDate: formatDate(new Date(),'/','MM/DD/YYYY'),
        SignatureDateValue:new Date(),
        ExceptionDate: null,
        AccountName: '',
        Attn: '',
        AccountFax: '',
        Claimant: {Name:company.CompanyName, ID:company.ID, address2:company.address2, Address:company.Address, City:company.City, State:company.State},
        Owner: {},
        Contractor: {},
        Lender: {},
        LoadSiteAddress: '',
        DumpSiteAddress: '',
        JobSite: { Name: '', fullAddress2: '' },
        CheckMaker: '',
        ClaimantTitle:'',
        CheckAmount: 0,
        JointCheckRequested: false,
        ReleasedAmount: 0,
        CheckPayable: '',
        ExceptionAmount: 0,
        EstimatedPrice: ''
    });
    const prelimHistoryRef = useRef(null);
    const unsubscribePrelimsRef = useRef(null);
    console.log('inputvalues on render = ', inputValues)
    const storage = getStorage(); 
 
    const sortedPrelims = prelimHistory.sort((a, b) => {
        return new Date(a.sortDate) - new Date(b.sortDate);
    }); 
   // pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log('sortedPrelims = ', sortedPrelims)
    useEffect(() => {
        console.log('WTF PRELIM SHIT')
        if (job) {
            
            let tempJob={...job};
            if(tempJob.ThroughDate) tempJob.ThroughDateValue= new Date(tempJob.ThroughDate)
            if(tempJob.SignatureDate) tempJob.SignatureDateValue= new Date(tempJob.SignatureDate)
            if(tempJob.ExceptionDate) tempJob.ExceptionDateValue= new Date(tempJob.ExceptionDate)
            if(!tempJob.ProjectName) tempJob.ProjectName= '';
            if(!tempJob.Contractor) tempJob.Contractor=  { Name: 'No Contractor', ID: 'default', Address: '', State: '', City: '', Contractor: true };
            if(!tempJob. Owner) tempJob.Owner= { Name: 'No Owner', ID: 'default', Address: '', State: '', City: '', Contractor: true };
            if(!tempJob.Lender) tempJob.Lender= { Name: 'No Lender', ID: 'default', Address: '', State: '', City: '', Contractor: true };
            tempJob.Claimant=  {Name:company.CompanyName, ID:company.ID, address2:company.address2, Address:company.Address, City:company.City, State:company.State};
            console.log('tempJob = ', tempJob)
            queryPrelims();
            setInputValues((prev) => ({ ...prev, ...tempJob}));
        } 
    }, [job]);



    const queryPrelims = () => {
        prelimHistoryRef.current = [];
        if (unsubscribePrelimsRef.current) unsubscribePrelimsRef.current();
        console.log('I AM OPENING A LISTENER TO THISE PRELIMS!')
        const queryName = `Organizations/${gearedUser.selectedOrgName}/PrelimHistory`;
        const q = query(collection(db, queryName),where("JobID", "==", job.ID));
        unsubscribePrelimsRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                let tempPrelim = change.doc.data(); 
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                tempPrelim.ID = change.doc.id;
                if (change.type === "added")  prelimHistoryRef.current.push(tempPrelim);
                
                if (change.type === "modified") { 
                    const prelimIndex = prelimHistoryRef.current.findIndex(p => p.ID === tempPrelim.ID);
                    prelimHistoryRef.current[prelimIndex] = tempPrelim;
                }
                if (change.type === "removed") {
                    console.log('i am removing the freightbill', tempPrelim)
                    const prelimIndex = prelimHistoryRef.current.findIndex(p => p.ID === tempPrelim.ID);
                    prelimHistoryRef.current.splice(prelimIndex, 1);
                }
            });
          
            console.log('SETTING HOEM FIREHGITSJLKJSKL ',prelimHistoryRef.current)
            setPrelimHistory( [...prelimHistoryRef.current]);
        });
    };

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate) 
        if(formattedDate==='12/31/1969')formattedDate= '';
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        let updateObject = {[fieldName]: formattedDate, [fieldName+'Value']: value }
        updateDocument( updateObject , job.ID, 'Jobs');
      
    };
    
    const updateJobField = useCallback(async (fieldName, value) => {

        setJob((prev) => ({ ...prev,  [fieldName]:  value }));
        console.log('updating the disaptch field = ' + fieldName + ' and value = ' , value );
       
        updateDocument({ [fieldName]:  value }, job.ID, 'Jobs');
       
    },  [job] );
  
    const handleFieldChange = (fieldName, value) => {
     
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        if(inputValues.ID!==''){
            const debouncedUpdate = debouncedFieldUpdater([fieldName], updateJobField);
            debouncedUpdate(fieldName, value); // Pass `fields` and `values` dynamically
         
        }
    };

    

    const changeJobSite = (fieldName,value)=>{
        let tempJobSite={...inputValues.JobSite};
        tempJobSite[fieldName]=value
        setInputValues((prev) => ({ ...prev, JobSite:tempJobSite }));
        const debouncedUpdate = debouncedFieldUpdater(['JobSite'], updateJobField);
        debouncedUpdate('JobSite', tempJobSite); // Pass `fields` and `values` dynamically
      
    }
    const formatAMPM = (date) =>{
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }
    const formatSignatureRow = (item) =>{
        let newText =":  ";
  
        if (!item) item = " ";
        console.log('item.length = '+ item.length);
        console.log('item = ', item);
        if(item.length>50){
            item=item.substr(0,50);
            item+='...';
        }
        for (var i = item.length; i < 300; i++) item += " ";
        newText+=item;
        console.log('newetxt= ' + newText);
        return newText;
    }

    const formatAddress=(account) =>{
        console.log('account = ', account);
        if (!account.Address) account.Address = "";
        account.fullAddress2 = "";
        account.NameDecoration='underline';
        account.AddressDecoration ='underline';
        account.fullAddressDecoration ='underline';
        if(account.Name===''){
            account.NameDecoration='';
            account.Name= ' '
        }  else for (var i = account.Name.length; i < 150; i++) account.Name += " ";

        if(account.City) if (account.City != '') account.fullAddress2 += account.City;
        if(account.State)  if (account.State != '') account.fullAddress2 += ", " + account.State;
        if(account.ZipCode)   if (account.ZipCode != '') account.fullAddress2 += ", " + account.ZipCode;
        if(account.Address===''){
            account.AddressDecoration='';
            account.Address= ' '
        }  else for (var i = account.Address.length; i <150; i++) account.Address += " ";
    
      
        if(account.fullAddress2===''){
            account.fullAddressDecoration='';
            account.fullAddress2= ' '
        }  else for (var i = account.fullAddress2.length; i < 150; i++) account.fullAddress2 += " ";
    }      

    const createReleasePDF = ( signature) =>{
        console.log('signareu baby = ', signature)
        let docDefinition = {
            content: [],
            pageMargins: [40, 85, 40, 60],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },

                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText: {
                    margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                accountHeader: {
                    margin: [30, 0, 0, 0],
                    fontSize: 13
                },
                tableFont: {
                    fontSize: 10
                },
                requestLineItem: {

                    margin: [15, 0, 0, 0],
                    border: [false, false, false, false]
                }

            }
        };
        let ConditionalWaiverHeader, DocumentNotice, ConditionalReleaseHeader, ConditionalReleaseText, ExceptionHeader, UnconditionalFinalReleased;
        
        
        if (releaseType === 'Conditional Progress') ConditionalWaiverHeader = { margin: [20, 5, 0, 20], alignment: 'center', fontSize: 15, text: 'CONDITIONAL WAIVER AND RELEASE ON PROGRESS PAYMENT (CALIFORNIA CIVIL CODE SECTION 8132, EFFECTIVE JULY 1, 2012) ' };
        if (releaseType === 'Unconditional Progress') ConditionalWaiverHeader = { margin: [20, 5, 0, 20], alignment: 'center', fontSize: 15, text: 'UNCONDITIONAL WAIVER AND RELEASE ON PROGRESS PAYMENT (CALIFORNIA CIVIL CODE SECTION 8134, EFFECTIVE JULY 1, 2012) ' };
        if (releaseType === 'Conditional Final') ConditionalWaiverHeader = { margin: [20, 5, 0, 20], alignment: 'center', fontSize: 15, text: 'CONDITIONAL WAIVER AND RELEASE ON FINAL PAYMENT (CALIFORNIA CIVIL CODE SECTION 8136, EFFECTIVE JULY 1, 2012) ' };
        if (releaseType === 'Unconditional Final') ConditionalWaiverHeader = { margin: [20, 5, 0, 20], alignment: 'center', fontSize: 15, text: 'UNCONDITIONAL WAIVER AND RELEASE ON FINAL PAYMENT (CALIFORNIA CIVIL CODE SECTION 8138, EFFECTIVE JULY 1, 2012) ' };


        if (releaseType === 'Conditional Progress') DocumentNotice = { margin: [20, 10, 0, 0], fontSize: 11, text: "NOTICE: THIS DOCUMENT WAIVES THE CLAIMANT'S LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS EFFECTIVE ON RECEIPT OF PAYMENT. A PERSON SHOULD NOT RELY ON THIS DOCUMENT UNLESS SATISFIED THAT THE CLAIMANT HAS RECEIVED PAYMENT." };
        if (releaseType === 'Unconditional Progress') DocumentNotice = { margin: [20, 10, 0, 0], fontSize: 11, text: "NOTICE TO CLAIMANT: THIS DOCUMENT WAIVES AND RELEASES LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS UNCONDITIONALLY AND STATES THAT YOU HAVE BEEN PAID FOR GIVING UP THOSE RIGHTS. THIS DOCUMENT IS ENFORCEABLE AGAINST YOU IF YOU SIGN IT, EVEN IF YOU HAVE NOT BEEN PAID. IF YOU HAVE NOT BEEN PAID, USE A CONDITIONAL WAIVER AND RELEASE FORM." };
        if (releaseType === 'Conditional Final') DocumentNotice = { margin: [20, 10, 0, 0], fontSize: 11, text: "NOTICE: THIS DOCUMENT WAIVES THE CLAIMANT'S LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS EFFECTIVE ON RECEIPT OF PAYMENT. A PERSON SHOULD NOT RELY ON THIS DOCUMENT UNLESS SATISFIED THAT THE CLAIMANT HAS RECEIVED PAYMENT." };
        if (releaseType === 'Unconditional Final') DocumentNotice = { margin: [20, 10, 0, 0], fontSize: 11, text: "NOTICE TO CLAIMANT: THIS DOCUMENT WAIVES AND RELEASES LIEN, STOP PAYMENT NOTICE, AND PAYMENT BOND RIGHTS UNCONDITIONALLY AND STATES THAT YOU HAVE BEEN PAID FOR GIVING UP THOSE RIGHTS. THIS DOCUMENT IS ENFORCEABLE AGAINST YOU IF YOU SIGN IT, EVEN IF YOU HAVE NOT BEEN PAID. IF YOU HAVE NOT BEEN PAID, USE A CONDITIONAL WAIVER AND RELEASE FORM." };


		if(!inputValues.CheckAmount)inputValues.CheckAmount=0;
		if(!inputValues.ExceptionAmount)inputValues.ExceptionAmount=0;
        let IdentifyingRow = { margin: [20, 10, 0, 0], bold: true, text: "Identifying Information" };
        var ThroughDate, Claimant, CustomerName, JobLocation, Owner, CheckMaker, CheckAmount, CheckPayable, ExceptionAmount,ExceptionDate, 
        ExceptionOne, ExceptionTwo, ExceptionThree, ExceptionFour, ExceptionFive, ExceptionSix, ExceptionSeven, ClaimantTitle, SignatureDate,

       
        ExceptionDate = formatSignatureRow(inputValues.ExceptionDate);
        ThroughDate = formatSignatureRow(inputValues.ThroughDate);
        console.log('iunbputvalues .SignatureDate = ',inputValues.SignatureDate)
        SignatureDate = formatSignatureRow(inputValues.SignatureDate);
    
        if(inputValues.Claimant) Claimant = formatSignatureRow(inputValues.Claimant.Name); else Claimant ='No Claimant';
        if(inputValues.LoadSiteAddress) JobLocation = formatSignatureRow(inputValues.LoadSiteAddress); else JobLocation = formatSignatureRow('No Location');
        CustomerName = formatSignatureRow(inputValues.AccountName);
        if(inputValues.Owner)  Owner = formatSignatureRow(inputValues.Owner.Name);else Owner = 'No Owner';
   
        CheckMaker = formatSignatureRow(inputValues.CheckMaker);
        ClaimantTitle = formatSignatureRow(inputValues.ClaimantTitle);
        CheckAmount = formatSignatureRow("$"+inputValues.CheckAmount.formatMoney(2));
        ExceptionAmount = formatSignatureRow(inputValues.ExceptionAmount.formatMoney(2));
        CheckPayable = formatSignatureRow(inputValues.CheckPayable);
        
        console.log('CheckPayable = ' + CheckPayable);
        
        let SignatureRowsOne = {
            columns: [{
                width: 120,
                alignment: 'right',
                columns: [
                    [
                        { margin: [5, 20, 0, 0], text: 'Name of Claimant' },
                        { margin: [5, 5, 0, 0], text: 'Name of Customer' },
                        { margin: [5, 5, 0, 0], text: 'Job Location' },
                        { margin: [5, 5, 0, 0], text: 'Owner' }
                       
                    ]
                ]
            }, {
                width: 400,
                alignment: 'left',
                margin: [0, 0, 100, 0],
                columns: [
                    [
                        { margin: [0, 20, 0, 0], text: Claimant, decoration: 'underline' },
                        { margin: [0, 5, 0, 0], text: CustomerName, decoration: 'underline' },
                        { margin: [0, 5, 0, 0], text: JobLocation, decoration: 'underline' },
                        { margin: [0, 5, 0, 0], text: Owner, decoration: 'underline' },
                        
                    ]
                ]
            }]
        };
        if (releaseType === 'Conditional Progress' || releaseType === 'Unconditional Progress') {
            SignatureRowsOne.columns[0].columns[0].push( { margin: [5, 5, 0, 0], text: 'Through Date' })
            SignatureRowsOne.columns[1].columns[0].push({ margin: [0, 5, 0, 0], text: ThroughDate, decoration: 'underline' })
        }
        if (releaseType === 'Conditional Progress' || releaseType === 'Conditional Final') ConditionalReleaseHeader = { margin: [20, 13, 0, 0], bold: true, text: "Conditional Waiver and Release " };
        if (releaseType === 'Unconditional Progress' || releaseType === 'Unconditional Final') ConditionalReleaseHeader = { margin: [20, 15, 0, 0], bold: true, text: "Unconditional Waiver and Release " };


        if (releaseType === 'Conditional Progress') ConditionalReleaseText = { margin: [20, 5, 0, 0], fontSize: 11, text: "This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for labor and service provided, and equipment and material delivered, to the customer on this job through the Through Date of this document. Rights based upon labor or service provided, or equipment or material delivered, pursuant to a written change order that has been fully executed by the parties prior to the date that this document is signed by the claimant, are waived and released by this document, unless listed as an Exception below. This document is effective only on the claimant's receipt of payment from the financial institution on which the following check is drawn: " };
        if (releaseType === 'Unconditional Progress') {
        
            ConditionalReleaseText = { margin: [20, 10, 0, 0], fontSize: 11, text: "This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for labor and service provided, and equipment and material delivered, to the customer on this job through the Through Date of this document. Rights based upon labor or service provided, or equipment or material delivered, purusant to a written change order that has been fully executed by the parties prior to the date that this document is signed by the claimant, are waived and released by this document, unless listed as an Exception below. The claimant has received the following progress payment: " };
            if(inputValues.ReleasedAmount>0)  ConditionalReleaseText.text= [ ConditionalReleaseText.text, {text: formatSignatureRow("$"+inputValues.ReleasedAmount.formatMoney(2)), decoration: 'underline'}] 
                else ConditionalReleaseText.text+="$_____________________________________________________________________________________";
        }
            if (releaseType === 'Conditional Final') ConditionalReleaseText = { margin: [20, 10, 0, 0], fontSize: 11, text: "This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for labor and service provided, and equipment and material delivered, to the customer on this job. Rights based upon labor or service provided, or equipment or material delivered, pursuant to a written change order that has been fully executed by the parties prior to the date that this document is signed by the claimant, are waived and released by this document, unless listed as an Exception below. This document is effective only on the claimant's receipt of payment from the financial institution on which the following check is drawn: " };
        if (releaseType === 'Unconditional Final') ConditionalReleaseText = { margin: [20, 10, 0, 0], fontSize: 11, text: "This document waives and releases lien, stop payment notice, and payment bond rights the claimant has for all labor and service provided, and equipment and material delivered, to the customer on this job. Rights based upon labor or service provided, or equipment or material delivered, pursuant to a written change order that has been fully executed by the parties prior to the date that this document is signed by the claimant, are waived and released by this document, unless listed as an Exception below. The claimant has been paid in full. " };

   
        let SignatureRowsTwo = {
            columns: [{
                width: 120,
                alignment: 'right',
                columns: [
                    [
                        { margin: [5, 20, 0, 0], text: 'Maker of Check' },
                        { margin: [5, 5, 0, 0], text: 'Amount of Check' },
                        { margin: [5, 5, 0, 0], text: 'Check Payable to' }
                    ]
                ]
            }, {
                width: 455,
                alignment: 'left',
                columns: [
                    [
                        { margin: [0, 20, 0, 0], text: CheckMaker, decoration: 'underline' },
                        { margin: [0, 5, 0, 0], text: CheckAmount, decoration: 'underline' },
                        { margin: [0, 5, 0, 0], text: CheckPayable, decoration: 'underline' }
                    ]
                ]
            }]
        };
        if (releaseType === 'Unconditional Progress' || releaseType === 'Unconditional Final') inputValues.ReleasedAmount += inputValues.CheckAmount;

        ExceptionHeader = { margin: [20, 15, 0, 0], bold: true, text: "Exceptions" };
        ExceptionOne = { margin: [20, 10, 0, 0], fontSize: 11, text: "This document does not affect any of the following: " };

        if (releaseType === 'Conditional Progress' || releaseType === 'Unconditional Progress') {
            ExceptionTwo = { margin: [20, 0, 0, 0], fontSize: 11, text: "(1) Retentions" };
            ExceptionThree = { margin: [20, 0, 0, 0], fontSize: 11, text: "(2) Extras for which the claimant has not received payment." };
        }
        else ExceptionTwo = { margin: [20, 0, 0, 0], fontSize: 11, text: [" Disputed claims for extras in the amount of: $", { text: ExceptionAmount, decoration: 'underline' }] };

        if (releaseType === 'Unconditional Progress') ExceptionFour = { margin: [20, 0, 0, 0], fontSize: 11, text: "(3) Contract rights, including (A) a right based on rescission, abandonment, or breach of contract, and (B) the right to recover compensation for work not compensated by the payment. " };

        if (releaseType === 'Conditional Progress') {
            let exceptionSixText = inputValues.ReleasedAmount>0 ? [ "Amount(s) of unpaid progress payment(s): $", {text: inputValues.ReleasedAmount, decoration: 'underline'}]  : "Amount(s) of unpaid progress payment(s): $______________________________________________"
            ExceptionFour = { margin: [20, 0, 0, 0], fontSize: 11, text: "(3) The following progress payments for which the claimant has previously given conditional waiver and release but has not received payment: " };
            ExceptionFive = { margin: [20, 0, 0, 0], fontSize: 11, text:[ "Date(s) of waiver and release", {text: ExceptionDate, decoration: 'underline'}]  };
            ExceptionSix = { margin: [20, 0, 0, 0], fontSize: 11, text:  exceptionSixText };
            ExceptionSeven = { margin: [20, 0, 0, 0], fontSize: 11, text: "(4) Contract rights, including (A) a right based rescission, abandonment, or breach of contract, and (B) the right to recover compensation for work not compensated by the payment. " };
        }

        let SignatureHeader = { margin: [20, 15, 0, 0], bold: true, text: "Signature" };
        let SignatureRowsThree = {
            columns: [{
                    width: 120,
                    alignment: 'right',
                    columns: [
                        [
                            { margin: [5, 20, 0, 0], text: "Claimant's Signature:" },
                            { margin: [5, 5, 0, 0], text: "Claimant's Title" },
                            { margin: [5, 5, 0, 0], text: "Date of Signature" }
                        ]
                    ]
                },
                [
                    { margin: [0, 20, 0, 0], width:250,image: signature, height:15,decoration: 'underline'},
                    { margin: [0, 5, 0, 0], text: ClaimantTitle, decoration: 'underline' },
                    { margin: [0, 5, 0, 0], text: SignatureDate, decoration: 'underline'}
                ]
            ]
        };

        docDefinition.content.push(ConditionalWaiverHeader);
        docDefinition.content.push(DocumentNotice);
        docDefinition.content.push(IdentifyingRow);
        docDefinition.content.push(SignatureRowsOne);

        docDefinition.content.push(ConditionalReleaseHeader);
        docDefinition.content.push(ConditionalReleaseText);
        if (releaseType === 'Conditional Progress' || releaseType === 'Conditional Final')   docDefinition.content.push(SignatureRowsTwo);

        docDefinition.content.push(ExceptionHeader);
        docDefinition.content.push(ExceptionOne);
        docDefinition.content.push(ExceptionTwo);
        if (releaseType === 'Conditional Progress' || releaseType === 'Unconditional Progress') {
            docDefinition.content.push(ExceptionThree);
            docDefinition.content.push(ExceptionFour);
        }


        if (releaseType === 'Conditional Progress') {
            docDefinition.content.push(ExceptionFive);
            docDefinition.content.push(ExceptionSix);
            docDefinition.content.push(ExceptionSeven);
        }
 
        docDefinition.content.push(SignatureHeader);
        docDefinition.content.push(SignatureRowsThree);
     
        //savePDF(false, 'Prelim');
        if (releaseType === 'Unconditional Final') UnconditionalFinalReleased = true;
        pdfMake.createPdf(docDefinition).getBase64(async function(encodedString) {
            let base64PDF = encodedString;
            const pdfUrl = await uploadPDFFile(base64PDF, prelimHistory.length);
       
            savePDF(releaseType, 'Prelim', prelimHistory.length, pdfUrl);
            console.log('pdfUrl=', pdfUrl);
        });
        //openPDF(docDefinition);

    }

    const createPrelimRequestPDF = (Request) =>{
   
        let releaseType;
        let PrelimIndex=prelimHistory.length;
        // createhistorypopup.hide();
        let docDefinition = {
            content: [],
            pageMargins: [40, 85, 40, 60],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText: {
                    margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                accountHeader: {
                    margin: [30, 0, 0, 0],
                    fontSize: 13
                },
                tableFont: {
                    fontSize: 10
                },
                requestLineItem: {
                    margin: [15, 0, 0, 0],
                    border: [false, false, false, false]
                }
            }
        };
        let CompanyHeader = inputValues.Company.Address + ', ' + inputValues.Company.address2 + '\n' + 'Office: ' + inputValues.Company.CompanyPhone + '   Fax: ' + inputValues.Company.Fax;
        var JobDate = formatDate(inputValues.JobDate,'-', 'MM/DD/YYYY');
        var todaysDate = formatDate(new Date(),'/', 'MM/DD/YYYY');
        var time = formatAMPM(new Date());

		if(!inputValues.ProjectName)inputValues.ProjectName='';
        var JobOwner = {...inputValues.Owner};
        var JobAccount = {...inputValues.Account};
        var JobContractor = {...inputValues.Contractor};
        var JobLender = {...inputValues.Lender};
        var JobClaimant = {...inputValues.Claimant};
        let accountHeader;
        if(!inputValues.JobSite)inputValues.JobSite= { Name: 'No Location', ID: 'default', Address: '', State: '', City: '', ZipCode:'', fullAddress:'' };
        console.log('inputValues.JobSite = ', inputValues.JobSite);
        var JobSite = inputValues.JobSite ? {...inputValues.JobSite} : { Name: 'No Location', ID: 'default', Address: '', State: '', City: '', ZipCode:'', fullAddress:'' };
       // console.log('JobSite.Address.lenght  bef= ' + JobSite.Address.length); 
        console.log('JobAccount = ', JobAccount);
        if (JobAccount.Name != 'No Account') formatAddress(JobAccount);
        else {
            JobAccount.Name = 'NO CUSTOMER NOTED';
            JobAccount.Address = 'No Address';
            JobAccount.fullAddress2 = 'No Address';
        }
        console.log('JobOwner = ', JobOwner);
        if (JobOwner.Name != 'No Account') formatAddress(JobOwner);
        else {
            JobOwner.Name = 'NO OWNER NOTED';
            JobOwner.Address = 'No Address';
            JobOwner.fullAddress2 = 'No Address';
        }
        console.log('JJobClaimant= ', JobClaimant);
        if (JobClaimant.Name != 'No Account') formatAddress(JobClaimant);
        else {
            JobClaimant.Name = 'NO OWNER NOTED';
            JobClaimant.Address = 'No Address';
            JobClaimant.fullAddress2 = 'No Address';
        }
        console.log('JobContractor= ', JobContractor);
        if (JobContractor.Name != 'No Account') formatAddress(JobContractor);
        else {
            JobContractor.Name = 'NO CONTRACTOR NOTED';
            JobContractor.Address = 'No Address';
            JobContractor.fullAddress2 = 'No Address';
        }
        console.log('JobLender= ', JobLender);
        if (JobLender.Name != 'No Account') formatAddress(JobLender);
        else {
            JobLender.Name = 'NO LENDER NOTED';
            JobLender.Address = 'No Address';
            JobLender.fullAddress2 = 'No Address';
        }
    
        if (JobSite.Name !== 'No Location'){
            if(JobSite.fullAddress2===''){
                JobSite.fullAddressDecoration='';
                JobSite.fullAddress2= ' '
            }  else{ 
                JobSite.fullAddressDecoration='underline';
             
                for (var i = JobSite.fullAddress2.length; i < 150; i++) JobSite.fullAddress2 += " ";
            } 
        } 
        else {
            JobSite.Name = 'NO JOB SITE NOTED';
            JobSite.Address = 'No Address';
            JobSite.fullAddress2 = 'No Address';
        }

        console.log('JobSite= ', JobSite);
        docDefinition.header = [
            { margin: [0, 30, 0, 0], text: inputValues.Company.CompanyName, fontSize: 18, bold: true, alignment: 'center' },
            { margin: [0, -18, 15, 0], text: todaysDate, alignment: 'right' },
            { margin: [0, 0, 15, 0], text: time, alignment: 'right' },
            { text: CompanyHeader, margin: [0, -12, 0, 0], bold: true, alignment: 'center', fontSize: 11 },
            { text: 'Lic#: 5311', margin: [0, 0, 0, 0], bold: true, alignment: 'center', fontSize: 11 }
        ];


        if (Request) {
             accountHeader = {
                columns: [
                    [
                        { fontSize: 13, margin: [30, 20, 0, 0], text: ['To:  ', { text: inputValues.Account.Name, bold: true }] },
                        { style: 'accountHeader', text: ['Attn:  ', { text: 'Prelim Department', bold: true }] },
                        { style: 'accountHeader', text: ['Fax:  ', { text: inputValues.Account.Fax, bold: true }] },
                    ],
                    {
                        width: 120,
                        margin: [0, 0, 0, 0],
                        table: {
                            widths: [120],
                            body: [
                                [{ style: 'requestLineItem', border: [true, true, true, false], text: 'This is our:' }],
                                [{ style: 'requestLineItem', border: [true, false, true, false], text: 'First Request' }],
                                [{ style: 'requestLineItem', border: [true, false, true, false], text: 'Second Request' }],
                                [{ style: 'requestLineItem', border: [true, false, true, true], text: 'Third Request' }]
                            ]
                        }
                    }
                ]
            };
        }
console.log('adding load site address')
    
        let loadSiteDumpSite = {
            columns: [{
                    margin: [35, 10, 0, 0],
                    width: 245,
                    text: [{ text: 'Load Site:  ' }, { text: inputValues.LoadSiteAddress, bold: true }]
                },
                {
                    margin: [20, 10, 0, 0],
                    width: 245,
                    text: [{ text: 'Dump Site:  ' }, { text: inputValues.DumpSiteAddress, bold: true }]
                },
            ]
        }
        if (Request) var SignatureRowsWidth = 105;
        else var SignatureRowsWidth = 105;

        let SignatureRows = {
            columns: [{
                width: SignatureRowsWidth,
                alignment: 'right',
                columns: [
                    [
                        { margin: [0, 10, 0, 0], text: 'Owner or Agency', bold: true },
                        { margin: [0, 10, 0, 0], text: 'Name: ' },
                        { margin: [0, 10, 0, 0], text: 'Address: ' },
                        { margin: [0, 10, 0, 0], text: 'City, State, Zip: ' },

                        { margin: [0, 20, 0, 0], text: 'General Contractor', bold: true },
                        { margin: [0, 10, 0, 0], text: 'Name: ' },
                        { margin: [0, 10, 0, 0], text: 'Address: ' },
                        { margin: [0, 10, 0, 0], text: 'City, State, Zip: ' },

                        { margin: [0, 20, 0, 0], text: 'Lender', bold: true },
                        { margin: [0, 10, 0, 0], text: 'Name: ' },
                        { margin: [0, 10, 0, 0], text: 'Address: ' },
                        { margin: [0, 10, 0, 0], text: 'City, State, Zip: ' },

                        { margin: [0, 20, 0, 0], text: 'Project Name' },
                        { margin: [0, 10, 0, 0], text: 'Legal Address' },
                        { margin: [0, 10, 0, 0], text: 'City, State, Zip' }

                    ]
                ]
            }]

        };

        if (Request) {
            SignatureRows.columns.push([
                { margin: [0, 10, 0, 0], text: '      ' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },

                { margin: [0, 20, 0, 0], text: '      ' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },

                { margin: [0, 20, 0, 0], text: '      ' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ' ____________________________________________________________________________' },

                { margin: [0, 20, 0, 0], text: ':___________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ':___________________________________________________________________________' },
                { margin: [0, 10, 0, 0], text: ':___________________________________________________________________________' },

            ]);
            releaseType = 'Preliminary Request';
          
        }
        else {

       
           console.log('JobSite.Address.lenght = ' + JobSite.Address.length); 
            var ProjectName = formatSignatureRow(inputValues.ProjectName);
           
          //  var JobSiteAddressName= formatSignatureRow(JobSite.Address);
          //  var JobSiteFullAddress = formatSignatureRow(JobSite.fullAddress2);
            SignatureRows.columns.push([
                { margin: [0, 10, 0, 0], text: '      ' },
                { margin: [10, 10, 0, 0], text: JobOwner.Name, decoration: JobOwner.NameDecoration },
                { margin: [10, 10, 0, 0], text: JobOwner.Address, decoration: JobOwner.AddressDecoration },
                { margin: [10, 10, 0, 0], text: JobOwner.fullAddress2, decoration: JobOwner.fullAddressDecoration},

                { margin: [0, 20, 0, 0], text: '      ' },
                { margin: [10, 10, 0, 0], text: JobContractor.Name, decoration: JobContractor.NameDecoration  },
                { margin: [10, 10, 0, 0], text: JobContractor.Address, decoration:JobContractor.AddressDecoration  },
                { margin: [10, 10, 0, 0], text: JobContractor.fullAddress2, decoration: JobContractor.fullAddressDecoration },

                { margin: [10, 20, 0, 0], text: '      ' },
                { margin: [10, 10, 0, 0], text: JobLender.Name, decoration: JobLender.NameDecoration },
                { margin: [10, 10, 0, 0], text: JobLender.Address, decoration:JobLender.AddressDecoration},
                { margin: [10, 10, 0, 0], text: JobLender.fullAddress2, decoration: JobLender.fullAddressDecoration  },


                { margin: [0, 20, 0, 0], text: ProjectName, decoration:'underline'},
                { margin: [0, 10, 0, 0], text: ': ' + JobSite.Address, decoration: JobSite.AddressDecoration},
                { margin: [0, 10, 0, 0], text: ': ' + JobSite.fullAddress2, decoration: JobSite.fullAddressDecoration  },

            ]);
            releaseType =  'Preliminary Info';
        }
        let requestPrelimHeader
        if (Request) requestPrelimHeader = { margin: [0,10, 0, 0], alignment: 'center', bold: true, text: 'Request For Preliminary Information', fontSize: 15 };
        else requestPrelimHeader = { margin: [0, 30, 0, 0], alignment: 'center', bold: true, text: 'Preliminary Information', fontSize: 15 };
        let inputValuesDateRow = { margin: [30, 10, 0, 0], text: ['Date of Job:  ', { bold: true, text: JobDate }], fontSize: 13 };

        let pleaseFaxASAP = { margin: [0, 5, 0, 0], alignment: 'center', bold: true, text: 'PLEASE FAX AS SOON AS POSSIBLE TO: ' + inputValues.Company.Fax, };
        let thankYou = { alignment: 'center', bold: true, margin: [0, 8, 0, 0], text: 'Thank You' };



        let entitlementClause = {
            table: {
                widths: ['*'],
                body: [
                    [{ fontSize: 9, margin: [5, 0, 5, 0], text: 'If labor, service, equipment or materials have been furnished to a jobsite by a claimant who did not give a preliminary notice pursuant to subdivision (a), that claimant shall not be precluded from giving a preliminary notice at any time thereafter. The claimant shall, however, be entitled to assert a claim against a payment bond and file a stop notice only for labor, services, equipment or material furnished within 20 days prior to the service of the preliminary notice, and at any time thereafter. (e) The failure to provide, pursuant to Chapter 974 of the Statutes of 1994, a written preliminary notice to a subcontractor with whom the claimant has contracted shall not affect the validity of any preliminary notice provided pursuant to this section. ' }],

                ]
            }
        }
        if (Request) docDefinition.content.push(accountHeader);

        docDefinition.content.push(requestPrelimHeader);
        docDefinition.content.push(inputValuesDateRow);
        docDefinition.content.push(loadSiteDumpSite);
        docDefinition.content.push(SignatureRows);
        if (Request) {
            docDefinition.content.push(pleaseFaxASAP);
            docDefinition.content.push(thankYou);
            docDefinition.content.push(entitlementClause);
        }
        console.log('docDefinition =' , docDefinition);
        
        pdfMake.createPdf(docDefinition).getBase64(async function(encodedString) {
            let base64PDF = encodedString;
            const pdfUrl = await uploadPDFFile(base64PDF, PrelimIndex);
       
            savePDF(releaseType, 'Prelim', PrelimIndex, pdfUrl);
            console.log('pdfUrl=', pdfUrl);
        });
      //  savePDF(false, 'Prelim');
        //docDefinition.footer = TermsAndCond;


    }
    const createPrelimReleasePDF = async() => {
        console.log('job = ', inputValues);
      
        console.log('prelimHistory = ', prelimHistory);
        let PrelimIndex=prelimHistory.length;
        // createhistorypopup.hide();
        var JobDate = formatDate(new Date(inputValues.JobDate),'/','MM/DD/YYYY');
        let docDefinition = {
            content: [],
            fontSize: 10,
            pageMargins: [40, 85, 40, 60],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },

                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText: {
                    margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                accountHeader: {
                    margin: [30, 0, 0, 0],
                    fontSize: 13
                },
                tableFont: {
                    fontSize: 10
                },
                requestLineItem: {

                    margin: [15, 0, 0, 0],
                    border: [false, false, false, false]
                }

            }
        };

        var JobOwner = {...inputValues.Owner};
        var JobAccount = {...inputValues.Account};
        var JobContractor = {...inputValues.Contractor};
        var JobLender = {...inputValues.Lender};
		if(!inputValues.EstimatedPrice)inputValues.EstimatedPrice='TBD';
        if (JobAccount.Name !== 'No Account') formatAddress(JobAccount);
        else {
            JobAccount.Name = 'NO CUSTOMER NOTED';
            JobAccount.Address = '';
            JobAccount.fullAddress2 = '';
        }
        console.log('JobAccount = ', JobAccount);
        if (JobOwner.Name !== 'No Account') formatAddress(JobOwner);
        else {
            JobOwner.Name = 'NO OWNER NOTED';
            JobOwner.Address = '';
            JobOwner.fullAddress2 = '';
        }
        if (JobContractor.Name != 'No Account') formatAddress(JobContractor);
        else {
            JobContractor.Name = 'NO CONTRACTOR NOTED';
            JobContractor.Address = '';
            JobContractor.fullAddress2 = '';
        }
        if (JobLender.Name != 'No Account') formatAddress(JobLender);
        else {
            JobLender.Name = 'NO LENDER NOTED';
            JobLender.Address = '';
            JobLender.fullAddress2 = '';
        }

        docDefinition.header = [
            { margin: [0, 30, 0, 0], text: "CALIFORNIA PRELIMINARY NOTICE", fontSize: 15, bold: true, alignment: 'center' },
            { fontSize: 11, text: "In accordance with section 8102, 8202 and 9303, California Civil Code. THIS IS NOT A LIEN. This is NOT a reflection on the integrity of any contractor or subcontractor. ", italics: true, margin: [75, 0, 75, 0], alignment: 'center'}
        ];
        if (inputValues.LoadSite.fullAddress[inputValues.LoadSite.fullAddress.length - 1] === ',') inputValues.LoadSite.fullAddress[inputValues.LoadSite.fullAddress.length - 1] = '';
        var jointCheckRequested ='JOINT CHECK REQUESTED';
        if(!inputValues.JointCheckRequested)jointCheckRequested='';
        var estimatedPrice= '';
        if(Number(inputValues.EstimatedPrice)>0)estimatedPrice='$' + Number(inputValues.EstimatedPrice).formatMoney(2);
        else estimatedPrice = inputValues.EstimatedPrice;

        let PrelimNoticeBody = {
            columns: [{
                width: 270,
                fontSize: 11,
                columns: [
                    [
                        { margin: [5, 0, 0, 0], text: "OWNER OR", bold: true },
                        { margin: [5, 0, 0, 0], text: "REPUTED OWNER(S)/PUBLIC ENTITY ", bold: true },
                        { margin: [5, 5, 0, 0], text: JobOwner.Name },
                        { margin: [5, 0, 0, 0], text: JobOwner.Address },
                        { margin: [5, 0, 0, 0], text: JobOwner.fullAddress2 },
                        { margin: [5, 40, 0, 0], text: "DIRECT CONTRACTOR OR", bold: true },
                        { margin: [5, 0, 0, 0], text: "REPUTED DIRECT CONTRACTOR", bold: true },
                        { margin: [5, 5, 0, 0], text: JobContractor.Name },
                        { margin: [5, 0, 0, 0], text: JobContractor.Address },
                        { margin: [5, 0, 0, 0], text: JobContractor.fullAddress2 },
                        { margin: [5, 40, 0, 0], text: "CONSTRUCTION LENDER OR REPUTED", bold: true },
                        { margin: [5, 0, 0, 0], text: " CONSTRUCTION LENDER  ", bold: true },
                        { margin: [5, 5, 0, 0], text: JobLender.Name },
                        { margin: [5, 0, 0, 0], text: JobLender.Address },
                        { margin: [5, 0, 0, 0], text: JobLender.fullAddress2 },
                        { margin: [5, 40, 0, 0], text: jointCheckRequested }
                    ]
                ]
                },
                [
                    { fontSize: 11, margin: [0, 0, 0, 0], text: '1. The following is a general description of the labor, service, equipment or materials furnished or to be furnished by the undersigned: ' },
                    { fontSize: 11, margin: [0, 10, 0, 0], text: 'DAILY TRUCKING/EQUIPMENT RENTAL ' },
                    { fontSize: 11, margin: [0, 10, 0, 0], text: '2. Estimated Price: '+ estimatedPrice },
                    { fontSize: 11, margin: [0, 10, 0, 0], text: '3. The name of person who furnished that labor, service, equipment or materials is:  ' },
                    { fontSize: 11, margin: [10, 5, 0, 0], text: inputValues.Company.CompanyName },
                    { fontSize: 11, margin: [10, 0, 0, 0], text: inputValues.Company.Address },
                    { fontSize: 11, margin: [10, 0, 0, 0], text: inputValues.Company.address2 },

                    { fontSize: 11, margin: [0, 10, 0, 0], text: ' Relationship to Parties: ' },
                    { fontSize: 11, margin: [0, 10, 0, 0], text: '4. The name of the person who contracted for purchase of that labor, service, equipment or material is:' },
                    { fontSize: 11, margin: [10, 5, 0, 0], text: JobAccount.Name },
                    { fontSize: 11, margin: [10, 0, 0, 0], text: JobAccount.Address },
                    { fontSize: 11, margin: [10, 0, 0, 0], text: JobAccount.fullAddress2 },
                    { fontSize: 11, margin: [0, 5, 0, 0], text: '5. The description of the jobsite is ' },
                    { fontSize: 11, margin: [10, 5, 0, 0], text: inputValues.JobSite.Name },
                    { fontSize: 11, margin: [10, 0, 0, 0], text: inputValues.JobSite.fullAddress },
                ]
            ]
        };

        let NoticeBodyOne = { margin: [15, 15, 0, 0], fontSize: 10, bold: true, text: "NOTICE TO PROPERTY OWNER. EVEN THOUGH YOU HAVE PAID YOUR CONTRACTOR IN FULL, If the person or firm that has given you this notice is not paid in full for labor, service, equipment, or material provided or to be provided to your construction project, a lien may be placed on your property. Foreclosure of the lien may lead to loss of all or part of your property. You may wish to protect yourself against this by (1) requiring your contractor to provide a signed release by the person or firm that has given you this notice before making payment to your contractor, or (2) any other method that is appropriate under the circumstances. " };
        let NoticeBodyTwo = { margin: [15, 0, 0, 0], fontSize: 10, bold: true, text: "      This notice is required by law to be served by the undersigned as a statement of your legal rights. This notice is not intended to reflect upon the financial condition of the contractor or the person employed by you on the construction project. " };
        let NoticeBodyThree = { margin: [15, 0, 0, 0], fontSize: 10, bold: true, text: "    If you record a notice of cessation or completion of your construction project, you must within 10 days after recording, send a copy of the notice of completion to your contractor and the person or firm that has given you this notice. The notice must be sent by registered or certified mail. Failure to send the notice will extend the deadline to record a claim of lien. You are not required to send the notice if you are a residential homeowner of a dwelling containing four or fewer units." };
        let NoticeBodyFour = { margin: [15, 5, 0, 0], fontSize: 10, text: "I declare that I am authorized to file this claim on behalf of the claimant. I have read the foregoing document and know the contents thereof; the same is true of my own knowledge. I decalre under penalty of perjury that the foregoing is true and correct. Executed at " + inputValues.Company.City + ", " + inputValues.Company.State + " " + inputValues.Company.ZipCode + " on " + JobDate + " for " + inputValues.Company.CompanyName };
        let PreparedByOne = { margin: [15, 0, 0, 0], fontSize: 10, text: "Prepared by:____________________________________________________________________________" };

        let OperationsOne = { margin: [70, 5, 0, 0], fontSize: 10, text: gearedUser.Name + ", OPERATIONS MANAGER Phone: " + inputValues.Company.CompanyPhone + '  Fax: ' + inputValues.Company.Fax };

        let ProofOfService = { margin: [0, 5, 0, 0], fontSize: 10, alignment: 'center', bold: true, text: "PROOF OF SERVICE BY MAIL AFFIDAVIT" };

        let NoticeBodyFive = { margin: [15, 0, 0, 0], fontSize: 10, text: "I declare that I served a copy of the above document, and any related documents, by certified or registered mail, postage prepaid, or other certified delivery, addressed to the above names parties, at the address listed above, on " + JobDate + "." + " I declare under penalty of perjury that the foregoing is true and correct. Executed at " + inputValues.Company.City + ", " + inputValues.Company.State + " " + inputValues.Company.ZipCode + " on " + JobDate + "." };
        let PreparedByTwo = { margin: [15, 0, 0, 0], fontSize: 10, text: "Prepared by:____________________________________________________________________________" };

        let OperationsTwo = { margin: [70, 5, 0, 0], fontSize: 10, text: gearedUser.Name + ", OPERATIONS MANAGER Phone: " + inputValues.Company.CompanyPhone + '  Fax: ' + inputValues.Company.Fax };

        docDefinition.content.push(PrelimNoticeBody);
        docDefinition.content.push(NoticeBodyOne);
        docDefinition.content.push(NoticeBodyTwo);
        docDefinition.content.push(NoticeBodyThree);
        docDefinition.content.push(NoticeBodyFour);
        docDefinition.content.push(PreparedByOne);
        docDefinition.content.push(OperationsOne);
        docDefinition.content.push(ProofOfService);
        docDefinition.content.push(NoticeBodyFive);
        docDefinition.content.push(PreparedByTwo);
        docDefinition.content.push(OperationsTwo);
        let releaseType = 'Preliminary Notice';
        pdfMake.createPdf(docDefinition).getBase64(async function(encodedString) {
            let base64PDF = encodedString;
            const pdfUrl = await uploadPDFFile(base64PDF, PrelimIndex);
       
            savePDF(releaseType, 'Prelim', PrelimIndex, pdfUrl);
            console.log('pdfUrl=', pdfUrl);
        });
        //savePDF(false, 'Prelim');
        // openPDF(docDefinition);

    }
    const handleDeletePrelim = async (prelim) => {
        try {
            if (window.confirm("Are you sure you want to delete this Prelim?")) {
                deleteDocument(prelim, 'PrelimHistory');
            }
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };
    const uploadPDFFile = async(document, PrelimIndex)=>{
        let storageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/Prelims/' + inputValues.ID + '/' + 'Prelims' + PrelimIndex + '.pdf')
        const snapshot = await uploadString(storageRef, document, 'base64');
        const url = await getDownloadURL(storageRef);
        return url;
    }

    const emailPrelim= (prelim) => {
        const formContent = (
            <div>
                 <div className="p-inputgroup" >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Email</span>
                    <InputTextParent placeholder="Email" value={ prelimEmailRef.current} onChange={(e) => handleEmailChange( e.target.value)} />
                </div>
            </div>
        );
  
        const buttons = [
            { label: 'Email', className: 'p-button-success', onClick: () => emailPDF(prelim) },
            { label: 'Cancel', className: 'p-button-secondary', onClick: () => setDialogVisible(false) },
        ];
  
        setDialogText(formContent);
        setDialogHeader('Email Prelim');
        setDialogButtons([...buttons]);
        setDialogVisible(true);
    };
    const getCurrentTime= () =>{
        var tempdate = new Date();
   
        var realTempDate = formatDate(tempdate, '/', 'MM/DD/YYYY');
        if (Number(tempdate.getMinutes()) < 10) realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + '0' + tempdate.getMinutes();
        else realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + tempdate.getMinutes();
        return realTempDate;
    }
    const emailPDF =(prelim)=>{
        console.log('no way this works')

     
        console.log('Job = ',job);
       
        var newEmailPDF={
      
            from: gearedUser.selectedOrgName + '<' +prelim.pdfType + 's@mail.geared.tech>',
            replyTo:company.SupportEmail,
            to: prelimEmailRef.current,
            createdAt:getCurrentTime(),
            createdBy:gearedUser.Name,
            URL: prelim.PDFurl,
            JobID:job.ID,
            PDFType:'Pre-Lim',
            ParentID:prelim.ID,
            Sent:false,
            subject: prelim.pdfType + ' for Job #' + job.JobNumber,
            html: '<a href="'+ prelim.PDFurl+ '">Click this link to download PDF of ' + prelim.pdfType + ' </a>'
        
        }
        console.log('newEmailpdf =' ,newEmailPDF)
       addDocument(newEmailPDF,'EmailedPDFs');
    }
    const showSignaturePopUp = (releaseType)=>{
        setReleaseType(releaseType);
        setSignatureVisible(true);
    }
    const savePDF =async(releaseType, pdfType, PrelimIndex, url )=>{
       
        console.log('inputValues.CheckAmount= ', inputValues.CheckAmount);
       
        var formatSortDate = mobiscroll.util.datetime.formatDate,
        todaysDate = formatDate(new Date(), '/', "MM/DD/YYYY");
		let queryDate = formatDate(new Date(), '/', "YYYY/MM/DD");
        let now = formatSortDate('DD MM d, hh:ii:ss A', new Date());
        var sortDate = formatSortDate('yyyy mm dd, hh:ii:ss A', new Date());
        console.log('sortDate= ', sortDate);
        let newPrelimHistory = {
            PrelimDate: todaysDate,
            JobID: inputValues.ID,
            text: releaseType + ' - $' + inputValues.CheckAmount.formatMoney(2),
            time: now,
            pdfType: pdfType,
			QueryDate: queryDate,
            releaseType:releaseType,
            sortDate: sortDate,
            PrelimIndex: PrelimIndex
        }
        var estimatedPrice= '';
        if(Number(inputValues.EstimatedPrice)>0)estimatedPrice='$' + Number(inputValues.EstimatedPrice).formatMoney(2);
        else estimatedPrice = inputValues.EstimatedPrice;
        if (releaseType === 'Preliminary Notice') newPrelimHistory.text =releaseType + ' - '+estimatedPrice;

        let body = 'Click this link to download PDF of Prelim ' + url;
        newPrelimHistory.PDFurl = url;

        newPrelimHistory.ID  = await addDocument(newPrelimHistory, 'PrelimHistory');
  
        
    }
    return (
       <React.Fragment>
            {inputValues && (<div className="mbsc-row mbsc-justify-content-center" >
                <div className="mbsc-col-md-12 mbsc-col-lg-5" style={{ padding: '0px', paddingRight: '10px' }}>
                    <Panel header='Preliminary Notice'>
                        <div className="mbsc-form-group-content">
                            <div className="mbsc-form-group-inset" style={{ margin: '1em' }}>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Project Name </span> 
                                    <InputTextParent placeholder="Project Name" value={inputValues.ProjectName} onChange={(e) => handleFieldChange('ProjectName', e.target.value)} />
                                </div>
                                
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Through Date</span> 
                                    <Calendar value={inputValues.ThroughDateValue} onChange={(e) => handleDateChange('ThroughDate', e.value)} placeholder="MM/DD/YYYY" />
                                    </div>
                                <div className="p-inputgroup" >
                                        <span className="p-inputgroup-addon dispatch-inputgroup"  >Exception Date</span>
                                    <Calendar value={inputValues.ExceptionDateValue} onChange={(e) => handleDateChange('ExceptionDate', e.value)} placeholder="MM/DD/YYYY" />
                                </div>
                                <div className="p-inputgroup" >
                                        <span className="p-inputgroup-addon dispatch-inputgroup"  >Signature Date</span>
                                    <Calendar value={inputValues.SignatureDateValue} onChange={(e) => handleDateChange('SignatureDate', e.value)} placeholder="MM/DD/YYYY" />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Customer Name</span>
                                    <InputTextParent placeholder="Customer Name" value={inputValues.AccountName} onChange={(e) => handleFieldChange('AccountName', e.target.value)} />
                                    </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Attn</span>
                                    <InputTextParent placeholder="Attn" value={inputValues.Attn} onChange={(e) => handleFieldChange('Attn', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Customer Fax</span>
                                    <InputTextParent placeholder="Customer Fax" value={inputValues.AccountFax} onChange={(e) => handleFieldChange('AccountFax', e.target.value)} />
                                </div>
                                
                        
                               {/*} <AutoCompleteInput label="Claimant" fieldName="Claimant" field="Name" value={inputValues.Claimant} suggestions={accounts} setValue={setInputValues} 
                                    handleFieldChange={handleFieldChange}  databaseList={'Accounts'} />*/}
                                
                        
                                <AutoCompleteInput label="Owner" fieldName="Owner" field="Name" value={inputValues.Owner} suggestions={accounts} setValue={setInputValues} 
                                    handleFieldChange={handleFieldChange}  databaseList={'Accounts'} />
                    
                                
                                <AutoCompleteInput label="Contractor" fieldName="Contractor" field="Name" value={inputValues.Contractor} suggestions={accounts} setValue={setInputValues} 
                                    handleFieldChange={handleFieldChange}  databaseList={'Accounts'} />
                                
                                {/* Lender */}
                                <AutoCompleteInput label="Lender" fieldName="Lender" field="Name" value={inputValues.Lender} suggestions={accounts} setValue={setInputValues} 
                                    handleFieldChange={handleFieldChange}  databaseList={'Accounts'} />

                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Load Site Address</span>
                                    <InputTextParent placeholder="Load Address Here" value={inputValues.LoadSiteAddress} onChange={(e) => handleFieldChange('LoadSiteAddress', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Dump Site Address</span>
                                    <InputTextParent placeholder="Dump Address Here" value={inputValues.DumpSiteAddress} onChange={(e) => handleFieldChange('DumpSiteAddress', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Site Name</span>
                                    <InputTextParent placeholder="Job Site Name" value={inputValues.JobSite.Name} onChange={(e) => changeJobSite('Name', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Site Address</span>
                                    <InputTextParent placeholder="Job Site Address" value={inputValues.JobSite.fullAddress2} onChange={(e) => changeJobSite('fullAddress2', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Maker of Check</span>
                                    <InputTextParent placeholder="Maker of Check" value={inputValues.CheckMaker} onChange={(e) => handleFieldChange('CheckMaker', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Claimant Title</span>
                                    <InputTextParent placeholder="Title" value={inputValues.ClaimantTitle} onChange={(e) => handleFieldChange('ClaimantTitle', e.target.value)} />
                                </div>
                                
                                {/* Check Information */}
                                <div className="mbsc-row">
                                    <div className="p-inputgroup mbsc-col-6" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Amount of Check</span> 
                                        <AutoSelectInputNumber  isCurrency={true}  value={inputValues.CheckAmount}  onChange={(e) => handleFieldChange('CheckAmount', e.value)}  />
                                    </div>
                                
                                    <div className="p-inputgroup mbsc-col-4" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup" >Joint Check</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('JointCheckRequested', e.checked)}  checked={inputValues.JointCheckRequested} />
                                    </div> 
                                
                                    
                                
                                </div>

                                  <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Total Amount Released</span>
                                    <AutoSelectInputNumber placeholder="0.00" isCurrency={true}  value={inputValues.ReleasedAmount} onChange={(e) => handleFieldChange('ReleasedAmount', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Check Payable To</span>
                                    <InputTextParent placeholder="Check Payable To" value={inputValues.CheckPayable} onChange={(e) => handleFieldChange('CheckPayable', e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Exceptions Amount</span>
                                    <AutoSelectInputNumber placeholder="0.00" value={inputValues.ExceptionAmount} onChange={(e) => handleFieldChange('ExceptionAmount', e.value)} min="0.00" />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Estimated Price</span>
                                    <InputTextParent placeholder="0.00" value={inputValues.EstimatedPrice} onChange={(e) => handleFieldChange('EstimatedPrice', e.target.value)} />
                                </div>

                                {/* Action Buttons */}
                                <div className="mbsc-row mbsc-justify-content-center">
                                    <Button label="Create Prelim Request" onClick={() => createPrelimRequestPDF(true)} />
                                    <Button label="Create Prelim Info" onClick={() => createPrelimRequestPDF(false)} />
                                    <Button label="Create Prelim Notice" onClick={() => createPrelimReleasePDF()} />
                                </div>
                                <div className="mbsc-row mbsc-justify-content-center">
                                    <Button label="Create Conditional Release" disabled={inputValues.UnconditionalFinalReleased} onClick={() => showSignaturePopUp('Conditional Progress')} />
                                    <Button label="Create Unconditional Release" disabled={inputValues.UnconditionalFinalReleased} onClick={() => showSignaturePopUp('Unconditional Progress')} />
                                </div>
                                <div className="mbsc-row mbsc-justify-content-center">
                                    <Button label="Create Conditional Final" disabled={inputValues.UnconditionalFinalReleased} onClick={() =>showSignaturePopUp('Conditional Final')} />
                                    <Button label="Create Unconditional Final" disabled={inputValues.UnconditionalFinalReleased} onClick={() => showSignaturePopUp('Unconditional Final')} />
                                </div>
                            </div>
                        </div>
                    
                    </Panel>
                </div>

                {/* Prelim History Section */}
                <div className="mbsc-col-md-12 mbsc-col-lg-7 mbsc-col-xl-6" style={{ padding: '0px', paddingLeft: '10px' }}>
                    
                    {sortedPrelims.map((item, index) => (
                      < PrelimList key={index} prelim={item} deletePrelim={handleDeletePrelim} emailPrelim={emailPrelim}/>
                    ))}
                       
                </div>
            </div>)}
            <SignaturePopUp visible={signatureVisible} setVisible={setSignatureVisible} handleSaveSignature={createReleasePDF} />
        </React.Fragment>
    );
};

export default JobPrelim;
