import React from 'react';
import { Dialog } from 'primereact/dialog';

import ExpenseList from '../../ListComponents/ExpenseList';
import { UserAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase';
import { doc,  writeBatch} from 'firebase/firestore';

const DispatchExpensePopUp = ({ expenses, dispatch, visible, setVisible, dispatchFreightBills}) => {
    const {addDocument, expenseNames, gearedUser, company } = UserAuth();
    const widths = company.SellMaterial ? ["5%",  "5%","12%", "10%", "6%", "6%", "4%",  "12%", "6%", "4%", "12%", "8%", "6%", "9%"] : ["5%",  "5%", "12%","13%", "8%", "8%", "4%",  "13%", "6%", "4%", "13%", "8%", "8%", "9%"] ;

    const expenseDriver =  { ID: '', Name: 'Driver', subhauler:false } 
    //console.log('expense pop up expenses =',  expenses)


    const closeFreightPopUp = () => {
        setVisible(false);
        console.log('ok we are closing pop up!!');
    };

  
    const handleAddExpense =async ()=>{
 
        var Expense = {
            ID: "",
            Name: expenseNames[0],
            parentExpense:'',
            description: expenseNames[0].Name,
            qty: Number(0),
            rate: Number(0),  
            total: Number(0),
            addToFreights:true,
            FBNO:'',
            floatingBillExpense:false,
            floatingPayExpense:false,
            QueryDate:dispatch.QueryDate,
            JobDate:dispatch.JobDate,
            Truck:'',
            Company:dispatch.Company,
            FreightBill:'',
            dispatchID:dispatch.ID,
            reDriver: false,
            bCustomer: false,
            reCustomer: false,
            bDriver: false,
            bill:false,
            billed:false,
            paid:false,
            pay:false,
            onHold:true,
            BillTo:{Name:dispatch.Account?.Name, ID:dispatch.Account?.ID},
            
            MaterialExpense: false
        };

        Expense.PayTo= expenseDriver;
        console.log('aabout to run add Expense for ', Expense) 
        Expense.ID= await addDocument(Expense, 'Expenses');
        createChildExpenses(Expense);
    }
    const createChildExpenses = async(expense)=>{

        const batch = writeBatch(db);
        for(let i=0;i<dispatchFreightBills.length; i++){
            var tempExp = {...expense};
            var ExpenseID= expense.ID+dispatchFreightBills[i].ID;
            tempExp.FreightBill = dispatchFreightBills[i].ID;
            tempExp.FBNO = dispatchFreightBills[i].FBNO;
            tempExp.QueryDate = dispatchFreightBills[i].QueryDate;
            tempExp.JobDate = dispatchFreightBills[i].JobDate;
            tempExp.floatingBillExpense = expense.floatingBillExpense;
            tempExp.floatingPayExpense = expense.floatingPayExpense;
            tempExp.driverID = dispatchFreightBills[i].Subhauler ? dispatchFreightBills[i].subhaulerID : dispatchFreightBills[i].Driver;
            tempExp.driverName = dispatchFreightBills[i].Subhauler ? dispatchFreightBills[i].haulerName : dispatchFreightBills[i].driverName;
            tempExp.Truck = dispatchFreightBills[i].Truck?.Name !==undefined ? dispatchFreightBills[i].Truck.Name : dispatchFreightBills[i].Truck ;
            tempExp.parentExpense= expense.ID;
            tempExp.BillType = dispatchFreightBills[i].BillType;
            tempExp.Company = dispatchFreightBills[i].Company;
            tempExp.ID=ExpenseID;
            console.log('tempExp ', tempExp);
            if(tempExp.PayTo.ID===''){
                if(!dispatchFreightBills[i].Subhauler) tempExp.PayTo={ID:dispatchFreightBills[i].Driver,Name:dispatchFreightBills[i].driverName	};
                else  tempExp.PayTo={ID:dispatchFreightBills[i].subhaulerID,Name:dispatchFreightBills[i].haulerName	};
            }
            let expenseRef =  doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Expenses',  ExpenseID);
            batch.set(expenseRef, tempExp );
        }
        await batch.commit();
    }

    return(

        <Dialog header={"Expenses"} visible={visible} style={{ width: '90vw' }}  breakpoints={{ '960px': '75vw', '641px': '100vw' }}  onHide={closeFreightPopUp}>
            <button style={{ margin: '0', padding: '.5em', width:"10%" }}  onClick={(e) =>handleAddExpense(e)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Add Expense </button>
            <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                <table style={{ marginBottom: "5px", width: "100%" }}>
                    <thead>
					<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                            <th style={{ width: widths[0] }}>Delete</th>
                         	<th style={{ width: "5%"  }}>On Hold</th>
                            {company.SellMaterial && <th style={{ width:widths[2] }}>Company</th>}
                            <th style={{ width: widths[3]  }}>Description</th>
                            <th style={{ width: widths[4] }}>QTY</th>
                            <th style={{ width: widths[5] }}>Rate</th>
                            <th style={{ width: widths[6] }}> Bill</th>
                            <th style={{ width: widths[7]  }}>Bill To</th>
                            <th style={{ width: widths[8]  }}>Invoice</th>
                            <th style={{ width: widths[9] }}>Pay </th>
                            <th style={{ width: widths[10]  }}>Pay To</th>
                            <th style={{ width: widths[11]  }}>Pay Statement</th>
                            <th style={{ width: widths[12]  }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>  
                    {expenses && dispatch && (<React.Fragment>
                    {expenses.map((item,index) => (
                        <ExpenseList key={index} expense={item} showAddToFreights={true}  driver={expenseDriver} customerID={dispatch.Account.ID}   />
                    ))}
                </React.Fragment>)}
                        
                    </tbody>
                </table>
            </div>
        </Dialog>
    )
}
export default DispatchExpensePopUp;