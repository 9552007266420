import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputNumber } from 'primereact/inputnumber';
import debounce from 'lodash/debounce';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';


const ReportTable = ({reportTable,repParent, setReportParents, parentIndex,reportParents, openFreightBill, setReportTotals,inputValues, formatDate, openPayStatement, openInvoice} ) => {
    const width= reportTable.Columns.length<=7 ? (reportTable.Columns.length*14.2).toString() +'%' : '100%';

    
    const [itemSize, setItemSize] = useState(window.innerWidth > 767 ? 46 : reportTable.Columns.length *35);
    const [balanceFilterMatchMode, setBalanceFilterMatchMode] = useState(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jobDateValue : { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LoadSiteName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        DumpSiteName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        AccountName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        DPSNum:{ value: null, matchMode: FilterMatchMode.CONTAINS },
		Balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        Total: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        paid: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    
    const dt = useRef(null); 
	const currentFilterState = useRef(null);
    useEffect(() => {
        console.log('we are setting filtered Data !!' ,repParent.reportData )
    
       resetFilters();
       
    }, [repParent.reportData,  inputValues.Company.ID, inputValues.Account.ID]);

    const resetFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            jobDateValue: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            LoadSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            DumpSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            AccountName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            driverName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            DPSNum: { value: null, matchMode: FilterMatchMode.CONTAINS },
			Balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
			Total: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            paid: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    
        if (dt.current) {
            dt.current.reset();  // Reset the table filters
        }
    };
  
    const originalData = useMemo(() => {
        return repParent.reportData.filter(item => 
            item.CompanyID === inputValues.Company.ID && 
            (inputValues.Account.ID === '' || item.AccountID === inputValues.Account.ID)
        );
    }, [repParent.reportData, inputValues.Company.ID, inputValues.Account.ID]);

    useEffect(() => {
		console.log('originalData = ', originalData)
        onTableFilter(originalData, parentIndex);
    }, [originalData, parentIndex]);
   
 
    useEffect(() => {
        const handleResize = () => {  setItemSize(window.innerWidth > 767 ? 46 : 200);  };
        window.addEventListener('resize', handleResize);
        
        let tempColumns = [...reportTable.Columns];
        for(let i=0; i<tempColumns.length; i++){
            if(tempColumns[i].field==='AccountName') tempColumns[i].filterable=true;
            if(tempColumns[i].field==='InvoiceNumber') tempColumns[i].filterable=true;
		
            if(tempColumns[i].field==='JobNumber') tempColumns[i].filterable=true;
            if(tempColumns[i].field==='LoadSiteName') tempColumns[i].filterable=true;
            if(tempColumns[i].field==='DumpSiteName') tempColumns[i].filterable=true;
            if(tempColumns[i].field==='driverName') tempColumns[i].filterable=true;
            if(tempColumns[i].field==='DPSNum') tempColumns[i].filterable=true;
        }
        return () => {  window.removeEventListener('resize', handleResize);   };
    },[]);

    useEffect(() => {
        let totals = {};

        reportTable.Columns.forEach(col => {
            if (col.showTotal) {
                totals[col.field] = sumValues(repParent.filteredData, col.field);
            }
        });
        console.log('setting reportTotals = ',originalData)
        setReportTotals(prevTotals => {
            const updatedTotals = { ...prevTotals, [repParent.header]: totals };
            return updatedTotals;
        });
    }, [repParent.filteredData]);
 

    const sumValues = (items, field) =>items.reduce((sum, item) => {
        if (item.finalRow) return sum; // Skip the final row
        const value = Number(item[field] || 0);
        const newTotal = sum + value;
        return Math.round((newTotal + Number.EPSILON) * 100) / 100;
    }, 0);
    
    const memoizedBodyTemplate = useMemo(() => {
        return (rowData, col) => {
            if(col.Type==='date') 
                return <div style={{ fontWeight: rowData.finalRow && 'bold', fontSize: rowData.finalRow && '1.1em'}} >
                    {formatDate(rowData[col.field], '/', 'MM/DD/YYYY')}
                </div>;
                
            if(col.Type==='number'){
                let value = rowData[col.field] != null && !isNaN(rowData[col.field]) ? 
                    Number(rowData[col.field]) : null;
                if (value !== null) 
                    value = col.title !== 'Hours'? 
                        value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 
                        value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                else value = ''; 
                return(
                    <div style={{textAlign: 'right', paddingRight:".5em"}}>
                        {col.title !== 'Hours' && col.title !== 'Weight' && 
                            (<span >$</span>)} 
                        <span>{value}</span>
                    </div>
                );
            }
            
            if(col.title==='FBNO' && !rowData.finalRow )
                return(
                    <button 
                        style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  
                        onClick={() => openFreightBill(rowData)}   
                        className="mbsc-ios mbsc-btn-primary mbsc-btn"
                    > 
                        {rowData[col.field]} 
                    </button>
                );
                
            if(col.title==='Invoice Number' && !rowData.finalRow )return(<button style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  onClick={(e) => openInvoice(rowData.ID)}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {rowData[col.field]} </button>)
            else if(col.title==='Pay Statement Number' && !rowData.finalRow )return(<button style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  onClick={(e) => openPayStatement(rowData.ID)}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {rowData[col.field]} </button>)
            else return(<div style={{ fontWeight: rowData.finalRow && 'bold', fontSize: rowData.finalRow && '1.1em'}} >{rowData[col.field]}</div>)
        };
    }, [formatDate, openFreightBill, openInvoice, openPayStatement]);


	const onBalanceFilterChange = (value, matchmode) => {
		let tempFilters = {...filters};
		currentFilterState.current = (tempFilters);
		setBalanceFilterMatchMode(matchmode);
		tempFilters.Balance = { operator: FilterOperator.AND, constraints: [{ value:value, matchMode: matchmode}] };
		console.log('setting filters to this = ', tempFilters);
		
		//currentFilterState.current = JSON.stringify(tempFilters);
		setFilters(tempFilters);
	};

	const onMatchModeChange = (value, matchmode) => {
		let tempFilters = {...filters};
		currentFilterState.current = (tempFilters);
		setBalanceFilterMatchMode(matchmode);
		tempFilters.Balance = { operator: FilterOperator.AND, constraints: [{ value:value, matchMode: matchmode}] };
		console.log('setting filters to this = ', tempFilters);
					
		dt.current.setFilterMeta(tempFilters);
		currentFilterState.current = tempFilters;
	
		
	
	}

	const balanceFilterTemplate = (options) => {
		console.log('balanceFilterTemplate = ', options)
		if(options?.filterModel?.matchMode)
			if(balanceFilterMatchMode!==options.filterModel.matchMode)
				onMatchModeChange(options.filterModel.constraints[0].value, options.filterModel.matchMode)
        return <InputNumber value={options.value} onChange={(e) => onBalanceFilterChange(e.value, options.filterModel.matchMode || 'lt')} mode="currency" currency="USD" locale="en-US" />;
    };

    const onTableFilter = (e, index) => {
        console.log('report parents when we are filtering = ', reportParents);
		console.log('and e = ', e)
		
	
        let tempReportParents = [...reportParents];
        tempReportParents[index].filteredData = [...e];
        setReportParents(tempReportParents);
    };
	
	/*useEffect(() => {
        // This will run whenever filters change
        if (dt.current && currentFilterState?.current) {
		
			if(JSON.stringify(currentFilterState?.current)!==JSON.stringify(dt.current.getFilterMeta())){
				console.log('setting filter meta = ', currentFilterState?.current)
				dt.current.setFilterMeta(currentFilterState?.current);
				setFilters(currentFilterState?.current);
			}
    
        }
    }, [dt?.current]);*/

    console.log('repotable = ', reportTable)
    console.log('report parents = ', reportParents)
    const createFooter = useMemo(() => {
        return (
            <ColumnGroup>
                <Row>
                    {reportTable.Columns.map((col, index) => {
                        const total = col.showTotal ? sumValues(repParent.filteredData, col.field) : null;
                        const columnDataLabel = col.showTotal ? col.title : '';
                        let formattedValue = '';
                        if (total !== null) {
                            if (col.title === 'Hours') formattedValue = total.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                            else if(col.title === 'Weight') formattedValue = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            else formattedValue = `$${total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                        }
                        console.log('itemSize = ' + itemSize)
                        if(columnDataLabel || itemSize===46) return (
                           
                           <Column
                                key={index}
                                pt={{root: { 'data-label':columnDataLabel }}}
                                footer={formattedValue}
                                footerStyle={{ textAlign: col.Type === 'number' ? 'right' : 'left', padding: '0', paddingRight:".5em", fontWeight: 'bold' }}
                            />
                          
                       
                        );
                    })}
                </Row>
            </ColumnGroup>
        );
    }, [repParent.filteredData, reportTable.Columns]);
	
    const debouncedFilter = useCallback(
        debounce((value, index) => {
            console.log('we filtering with value = ', value)
            onTableFilter(value, index);
        }, 100),
        [reportParents]
    );
	console.log('filters = ', filters)
    const handleFilterChange = (e, parentIndex) => {
        console.log('handle Filter change  = ', e);
        console.log('parent index = ', parentIndex);
        
		
        debouncedFilter(e, parentIndex);
   
        
    };
    console.log('repParent.filteredData.length = ', reportParents[0].filteredData.length)
  
    const paidFilterTemplate = (options) => {
        return (
            <TriStateCheckbox 
                style={{borderColor:"#d1d5db", background:"white"}}
                value={options.value !== null ? options.value : undefined}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    return(
        <div style={{width:width}}>
          
          
            {reportParents.length>2 ? (<DataTable 
               value={repParent.filteredData || repParent.reportData.filter(item => 
                   item.CompanyID === inputValues.Company.ID && 
                   (inputValues.Account.ID === '' || item.AccountID === inputValues.Account.ID)
               )} 
                scrollable 
                scrollHeight="400px"
                virtualScrollerOptions={{ 
                    itemSize: itemSize,
                    delay: 150,
                    lazy:true,
                    cache: true,
                  
                }}
                header={repParent.header}    
                ref={dt}
                footerColumnGroup={createFooter} 
                onValueChange={(e)=>handleFilterChange(e, parentIndex)} 
                showGridlines 
                sortField="Name" 
                sortOrder={1}
                style={{ padding: '.5em', width: '100%' }}    
                filterDisplay="row" > 
                    
                {reportTable.Columns.map((col, index) => {
                    if (col.field === 'Balance') {
                        return (
                            <Column 
                                pt={{root: { 'data-label': col.title }}} 
                                key={index} 
                                field={col.field}  
                                dataType="numeric"
                                filter={col.filterable} 
                                filterField="paid"
                                filterElement={paidFilterTemplate}
                                header={col.title} 
                                sortable   
                                body={(rowData) => memoizedBodyTemplate(rowData, col)}
                            /> 
                        );
                    }
                    
                    return (
                        <Column 
                            pt={{root: { 'data-label': col.title }}} 
                            key={index} 
                            field={col.field}  
                            dataType={col.field === 'Balance' ? 'numeric' : col.Type}
                            header={col.title} 
                            sortable   
                            body={(rowData) => memoizedBodyTemplate(rowData, col)}
                        /> 
                    );
                })}
            </DataTable>):
            (<DataTable 
                value={originalData} 
                scrollable 
                scrollHeight= "550px"
                ref={dt}
                virtualScrollerOptions={{ 
                    itemSize: itemSize,
                    delay: 150,
                    lazy:true,
                    cache: true,
                  
              
                }}
                header={repParent.header}    
                footerColumnGroup={createFooter} 
			    onValueChange={(e)=>handleFilterChange(e, parentIndex)} 
                showGridlines 
                filters={filters}
                style={{ padding: '.5em', width: '100%' }}    
                filterDisplay="row"
                > 
                    
                {reportTable.Columns.map((col, index) => {
                    if (col.field === 'Balance') {
                        return (
                            <Column 
                                pt={{root: { 'data-label': col.title }}} 
                                key={index} 
                                field={col.field}  
                                dataType="numeric"
                             
                                header={col.title} 
                                sortable   
                                body={(rowData) => memoizedBodyTemplate(rowData, col)}
                            /> 
                        );
                    }
                    
                    return (
                        <Column 
                            pt={{root: { 'data-label': col.title }}} 
                            key={index} 
                            field={col.field}  
                            dataType={col.field === 'Balance' ? 'numeric' : col.Type}
                            filter={col.filterable} 
                            header={col.title} 
                            sortable   
                            body={(rowData) => memoizedBodyTemplate(rowData, col)}
                        /> 
                    );
                })}
            </DataTable>)  }
                     
    </div>
    );
}
export default ReportTable;
