import React, { useState,useEffect} from 'react';
import LocationInput from '../../InputComponents/LocationInput';
import { Button } from 'primereact/button';
import { useGlobal } from '../../../context/GlobalContext';
const ExtraSiteInput = ({ siteData, onUpdate, onDelete,  showDelete}) => {
    
    const [localSiteData, setLocalSiteData] = useState(siteData);
    const { showLocationPopUp} = useGlobal();
    
     const handleChange = (fieldName,  value) => {
      let tempLocalSiteData ={...localSiteData}
      tempLocalSiteData[fieldName]=value;
      onUpdate(tempLocalSiteData);
  
    };
    useEffect(()=>{
      setLocalSiteData(siteData);
    },[siteData])
  console.log('these are extra sites = ', siteData.LoadSite.Name)

  console.log('these are extra sites = ', siteData)
    // render your two location inputs
    return (
      <div  className="mbsc-row" style={{paddingBottom:".5em"}}>
         <div  style={{padding:"0", paddingRight:".5em"}} className="mbsc-col-lg-1"> {showDelete &&(  <Button style= {{padding:"0", fontSize:'1.2em', height:"100%", marginBottom:".5em", width:"100%"}} label="Delete" onClick={() => onDelete()} />)}</div>
          <div  style={{padding:"0"}} className="mbsc-col-lg-11 mbsc-row">
            <div  style={{padding:"0", paddingRight:"1em"}} className="mbsc-col-lg-6">
                <LocationInput object={localSiteData} label="Load Site" fieldName="LoadSite" field="Name"  value={localSiteData.LoadSite.Name} handleFieldChange={handleChange} setValue={setLocalSiteData} showPopUp={()=> showLocationPopUp(localSiteData.LoadSite)}/>
              </div>
              <div  style={{padding:"0", paddingRight:"1em"}} className="mbsc-col-lg-6 ">
                <LocationInput object={localSiteData} label="Dump Site" fieldName="DumpSite" field="Name" value={localSiteData.LoadSite.Name}  handleFieldChange={handleChange} setValue={setLocalSiteData} showPopUp={()=> showLocationPopUp(localSiteData.DumpSite)}/>
              </div>
          </div>
      </div>
    );
};
  export default ExtraSiteInput;
  