import {  useCallback} from 'react';
import { doc, updateDoc,setDoc, runTransaction } from 'firebase/firestore';
import { db } from '../../../firebase';
import { UserAuth } from '../../../context/AuthContext';
import useFieldSpecificDebounce from '../../../hooks/useFieldSpecificDebounce';


export const usePrevailingWageData = (inputValues, setJob, jobRef, setInputValues) => {

    const { gearedUser, formatDate } = UserAuth();
    const debouncedFieldUpdater = useFieldSpecificDebounce(500);

    const createNewPrevailingWageReport = (tempJob) =>{
		
		return  {
			Account:tempJob.Account,
			Company:tempJob.Company,
			StartDate: tempJob.prevailStartDate,
			EndDate: tempJob.prevailEndDate,
			prevailStartDate:tempJob.prevailStartDate,
			prevailEndDate:tempJob.prevailEndDate,
			latestDispatchDate:tempJob.latestDispatchDate,
			JobDate:tempJob.JobDate,
			SignatureDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			SignatureDateValue: new Date(),
			WeekStartDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			WeekEndDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			OvertimePrevailingWageRate: tempJob.OvertimePrevailingWageRate ? tempJob.OvertimePrevailingWageRate : '',
			OwnerOpPrevailingWageRate: tempJob.OwnerOpPrevailingWageRate ? tempJob.OwnerOpPrevailingWageRate : '',
			LoadSite: tempJob.LoadSite,
			DumpSite: tempJob.DumpSite,
			Active: true,
			PrevailingWageRate: tempJob.PrevailingWageRate ? tempJob.PrevailingWageRate : '',
			ContractorPayroll: false,
			OwnerOpRemarks: '',
			ID: tempJob.ID,
			DriverRemarks: '',
			Driver2a: false,
			Driver2b: false,
			Driver4a: false,
			Driver4b: true,
			Driver4c: false,
			OwnerOp4a: false,
			OwnerOp4b: false,
			ContractNumber:tempJob.ContractNumber,
			JobNumber:tempJob.JobNumber,
			Contractor:tempJob.Contractor || {Name:''},
			createEmployeeDocs: true,
			createOwnerOpDocs: false,
			SubcontractorPayroll: false,
			Name: '',
			ProjectLocation: '',
			ProjectName: '',
			Title: '',          
			OwnerOpPayrollNumber: 1,
			DriverPayrollNumber: 1,
		}
    }


	
	const updateJobandPrevailingWageReport = useCallback(async (fields, values) => {

		updateJob(fields, values);
		updatePrevailingWageReport(fields, values);

	}, [inputValues]);

	const updateJob = useCallback(async (fields, values) => {

		let tempJob = {...inputValues};
		let updatedDoc = {};
		setJob(prevState => {
			let newState = { ...prevState };
			for (let i = 0; i < fields.length; i++){
				newState[fields[i]] = values[i]; 
				tempJob[fields[i]] = values[i]; 
				updatedDoc[fields[i]] = values[i]; 
			} 
			return newState;
		});
		
		
		console.log('updatedDoc b4 updating job in prevailing wage hook ', updatedDoc)
		updateDoc(  jobRef, updatedDoc);

	}, [inputValues]);


    const updatePrevailingWageReport = useCallback(async (fields, values) => {
		console.log('updating the prevailing wage report field = ' ,fields);
		console.log( 'and value s	= ', values);
	
		let tempJob = {...inputValues};
		let updatedDoc = {};
	
		for (let i = 0; i < fields.length; i++)updatedDoc[fields[i]] = values[i]; 
			
		
		const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`, inputValues.ID);
		

		if(fields[0]==='PrevailingWage'){
			fields[0]='Active';
			updatedDoc.Active = values[0];
		}
		console.log('updatedDoc after= ', updatedDoc)
		try {
			await runTransaction(db, async (transaction) => {
				const docSnapshot = await transaction.get(docRef);

				if (!docSnapshot.exists() && (fields[0]!=='Active'  || values[0])) transaction.set(docRef,createNewPrevailingWageReport(tempJob));
				else transaction.update(docRef, updatedDoc);       

			});
			console.log('Transaction committed successfully.');
		} catch (error) { console.error('Transaction failed: ', error); }
	}, [inputValues]);

	const handlePrevailingWageReportCreate = async(tempJob, updatedDoc) =>{
		const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`, tempJob.ID);
		try {
			await runTransaction(db, async (transaction) => {
				const docSnapshot = await transaction.get(docRef);

				if (!docSnapshot.exists()) transaction.set(docRef, createNewPrevailingWageReport(tempJob));
				else transaction.update(docRef, updatedDoc);       

			});
			console.log('Transaction committed successfully.');
		} catch (error) { console.error('Transaction failed: ', error); }
	
	
	}

	const handlePrevailingWageChange = (fieldName, value) =>{
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
		setJob((prev) => ({ ...prev, [fieldName]: value }));   
		let fields = [fieldName];	
		let values = [value];
        if(inputValues.ID!=='' ){
			const debouncedUpdate = debouncedFieldUpdater(fields, updateJobandPrevailingWageReport);
			debouncedUpdate(fields, values); // Pass `fields` and `values` dynamically
		}
    }
    const handlePrevailingWageRateChange = (fieldName, value) => {
     
        setJob((prev) => ({ ...prev, [fieldName]: value }));
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        if(inputValues.ID!==''){
			let fields = [fieldName];
			let values = [value];
            const debouncedUpdate = debouncedFieldUpdater(fields, updateJobandPrevailingWageReport);
            debouncedUpdate(fields, values); // Pass `fields` and `values` dynamically
        }
    }
	const handlePrevailingWageDateChange = ( fieldName, value) => {
		let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
		console.log('formattedDate = ', formattedDate) 
		let fields = [fieldName,fieldName+'Value' ];
		let values = [formattedDate, value];
		setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
		setJob((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
		if(inputValues.ID!==''){
			const debouncedUpdate = debouncedFieldUpdater(fields, updateJobandPrevailingWageReport);
			debouncedUpdate(fields, values, false); // Pass `fields` and `values` dynamically
		
		}
	}
    return { updatePrevailingWageReport, handlePrevailingWageDateChange, handlePrevailingWageChange, handlePrevailingWageRateChange,handlePrevailingWageReportCreate };
  };