import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faSort, faTrailer, faTruck } from '@fortawesome/free-solid-svg-icons';

const FreightSummaryLine = ({ dispatch, freight, index, onDelete, onClick,lastIndex }) => {
    const freightItem = freight.item;
    const itemIndex = index.index;
    let borderRadius= '';
    let backgroundColor = '';

    if(freightItem.dSubmitted) backgroundColor ='#65abf7';
    else if (freightItem.Received) backgroundColor = '#c9e7ca';
    else if (freightItem.textSent && !freightItem.Received)   backgroundColor = '#fcf1d0';
    if(lastIndex?.lastIndex===itemIndex) borderRadius='0px 0px 10px 10px';
    const removeButtonColor = !dispatch.Released ? 'red' : dispatch.isChanging ? 'red' : 'grey';

    const startDeleteFreight = () =>{
        if(!freight.truckingBilled && !freight.truckingPaid){
            if(!dispatch.Released)   onDelete(freightItem.ID);
            else if(dispatch.isChanging) if (window.confirm('Are you sure you would like to remove this driver and DELETE the Freight Bill?')) {
                onDelete(freightItem.ID);
            }
        }else  if (window.confirm('This is Freight Bill is currently billed or paid are you sure you want to delete?')) {
            onDelete(freightItem.ID);
        }
    }
	const lineID ='freightLine('+freightItem.ID+')';
    return (
        <div>
            <li className='listItem' style={{ ...itemIndex === 0 ? { borderTop: 'none' } : {}, color:'black', borderRadius:borderRadius, backgroundColor, paddingTop:'.15em', paddingBottom:'.15em' }}  onClick={() => onClick(freightItem,dispatch )} >
                <div className="mbsc-grid freightLine" style={{padding:"0px",fontSize:".8em"}}>
                    <div className="mbsc-row mbsc-justify-content-between freightLine" id={lineID}> 
                        <div className="mbsc-col-3 mbsc-row freightLine" style={{padding:'0', margin:'0'}}>
                            <div className="mbsc-col-3 freightLine" style={{paddingLeft:".2em", paddingRight:"0px"}}>
                                <FontAwesomeIcon className="fas" icon={faSort} style={{  width:"1em"}}/>
                                {freightItem.loadOrder}
                            </div>
                            <div className="mbsc-col-9 freightLine "  style={{wordWrap: "break-word", paddingLeft:"0px",paddingRight:"0px" ,fontWeight: "700",fontSize:".9em"}}>{freightItem.driverName}</div>
                        </div>
                     
                            <div className="mbsc-col-1 freightLine"  style={{paddingLeft:"0px",paddingRight:"0px" }}><span >{freightItem.TruckType.TruckCode}</span></div> 
                            <div className="mbsc-col-1 freightLine"  style={{paddingLeft:"0px",paddingRight:"0px"}}> 
                                <FontAwesomeIcon className="fas freightLine" icon={faTruck} style={{  width:"1em", paddingRight:".2em"}}/>
                                {freightItem.Truck.Name && freightItem.Truck.Name!=='No Truck'?(  <span >{freightItem.Truck.Name} </span>  ):(<span> </span>)}
                            </div>
                            <div className="mbsc-col-7 mbsc-row freightLine" style={{padding:'0', margin:'0'}}>
                                <div className="mbsc-col-3 freightLine" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                                    <FontAwesomeIcon className="fas freightLine" icon={faTrailer} style={{  width:"1em"}}/>
                                    {freightItem.Trailer.Name && freightItem.Trailer.Name!=='No Trailer'?( <span >{freightItem.Trailer.Name} </span> ):(<span></span>)}
                                </div>
                        
                                <div className="mbsc-col-6 freightLine" style={{paddingLeft:"0px",paddingRight:"0px"}} > {freightItem.Material.Name && freightItem.Material.Name!=='No Material'?( <span >{freightItem.Material.Name}</span> ):(<span></span>)}</div>
                        
                                <div className="mbsc-col-2 freightLine"  style={{paddingLeft:"0px",paddingRight:"0px"}}>{freightItem.dispatchTime}</div>
                                <div className="mbsc-col-1 freightLine" style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "right", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                    <span style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); startDeleteFreight(); }}>
                                        <FontAwesomeIcon className="fas freightLine" icon={faMinusCircle} style={{ color:  removeButtonColor, height: "1.25em", width: "1.25em" }} />
                                    </span>
                                </div>
                        </div>
                    </div>
                    
                </div>
            </li>                 
        </div>    
    )
}

export default FreightSummaryLine;