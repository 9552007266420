import React, { useState, useEffect,  useRef, useCallback } from 'react';

import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { UserAuth } from '../../context/AuthContext';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import AutoSelectInputNumber from '../InputComponents/AutoSelectInputNumber';  
import useFieldSpecificDebounce from '../../hooks/useFieldSpecificDebounce';

const ExpenseList = ({ expense, showAddToFreights, driver, customerID, updateDispatchExpenses}) => {
    console.log('expense = ', expense)
    const {  company, companies,  deleteDocument,updateDocument,expenseNames, accounts, gearedUser,formatDate	} = UserAuth();
    const [inputValues, setInputValues] = useState({tempName:{Name:''}, Attachment:{}}); 
    const debouncedFieldUpdater = useFieldSpecificDebounce(200);
    const widths = company.SellMaterial ? ["5%",  "5%","12%", "10%", "6%", "6%", "4%",  "12%", "6%", "4%", "12%", "8%", "6%", "9%"] :
	 ["5%",  "5%", "12%","13%", "8%", "8%", "4%",  "13%", "6%", "4%", "13%", "8%", "8%", "9%"] ;

    const payToSuggestions =[...accounts];
    if(driver)if(!driver.subhauler)payToSuggestions.push(driver);
    const oldPayToID=useRef(expense.PayTo.ID);
    const oldBillToID=useRef(expense.BillTo.ID);
    useEffect(() => {
        if (expense) {
            console.log('we runnign teh expense use effect', expense);
     
    
            setInputValues({
			
                Name:expense.Name,
                description:expense.description,
                Company: expense.Company ? expense.Company : company,
                onHold:expense.onHold,
                qty:expense.qty,
                rate:expense.rate,
				JobDate:expense.JobDate,
				JobDateValue:new Date(expense.JobDate),
                total:expense.total,
                Type:expense.Type || '',
                reDriver:expense.reDriver,
                bCustomer:expense.bCustomer,
                reCustomer:expense.reCustomer,
                pay:expense.pay,
                bill:expense.bill,
                bDriver:expense.bDriver,
                BillTo:expense.BillTo,
                Invoice:expense.Invoice || '',
                PayTo:expense.PayTo,
                DPS:expense.DPS || '',
                MaterialExpense:expense.MaterialExpense,
                addToFreights:expense.addToFreights
            });
         
        }
    }, [expense]);


	const handleDateChange = ( value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
		let formattedQueryDate= formatDate(value, '/', 'YYYY/MM/DD');
   
		let updateObject = {JobDate: formattedDate, ['JobDateValue']: value, QueryDate:formattedQueryDate};
        setInputValues((prev) => ({ ...prev, updateObject }));
        updateDocument(updateObject, expense.ID, "Expenses");
  
   
    };

    const updateExpenseFields= useCallback(async (fieldNames, values) => {
        let updateObject = {};

        fieldNames.forEach((field, index) => {
          updateObject[field] = values[index];
	
        });
	
		if(updateDispatchExpenses)updateDispatchExpenses(updateObject, expense.ID);
        updateDocument(updateObject, expense.ID, "Expenses");
        
    },  [expense] );

    const updateExpenseField= useCallback(async (fieldName, value) => {
       
	
        updateDocument({  [fieldName]: value }, expense.ID, "Expenses");
		if(updateDispatchExpenses)updateDispatchExpenses({  [fieldName]: value }, expense.ID);
        
    },  [expense] );

    const deleteExpense = ( expense)=>{
        if(window.confirm("Are you sure you want to delete expense?"))
            deleteDocument(expense, 'Expenses'); 

    }

    const openInvoice = (expense)=>{
        var win = window.open('/invoice/invoice/'+expense.Invoice, '_blank');
        win.focus();
  
    }
    const openPayStatement = (expense)=>{
        var win = window.open('/paystatement/paystatement/'+expense.DPS, '_blank');
        win.focus();
  
    }
	const handleChangeTotal=(value)=>{
		setInputValues((prev) => ({ ...prev, 'total': value }));
        const debouncedUpdate = debouncedFieldUpdater('total', updateExpenseField);
        debouncedUpdate('total', value); // Pass `fields` and `values` dynamically
	}


    const handleFieldChange = ( fieldName,value ) => {
        console.log('tryina set fieldname = '+ fieldName + ' equal to value = ', value)
		let tempInputValues = {...inputValues};
		tempInputValues[fieldName]=value;
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
	
	
		if(updateDispatchExpenses)updateDispatchExpenses({  [fieldName]: value }, expense.ID);
        updateDocument({  [fieldName]: value }, expense.ID, "Expenses");
		
    };
 
    const changeCompany = (fieldName, value)=>{
        let newCompany = {
            ID: value.ID,
            CompanyID: gearedUser.selectedOrgName,
            CompanyName: value.CompanyName,
            Address: value.Address,
            address2: value.address2,
            CompanyPhone: value.CompanyPhone,
            payByJobHours : company.payByJobHours ? company.payByJobHours : false,
            Fax: value.Fax,
          
            CalcRunningTime: company.CalcRunningTime,
            realmID: value.realmID

        }
        updateExpenseFields([fieldName], [newCompany]);
        setInputValues((prev) => ({ ...prev, [fieldName]: newCompany}));
    }
    const changeBillTo =(fieldName, value)=>{
    
        let floatingBillExpense= false;
        if(customerID!==value.ID)floatingBillExpense=true;
        if(value.ID!==oldBillToID.current) {
            let billTo ={ID:value.ID, Name:value.Name}
        	oldBillToID.current=value.ID;
            updateExpenseFields(['floatingBillExpense', fieldName], [floatingBillExpense, billTo]);
            setInputValues((prev) => ({ ...prev, floatingBillExpense:floatingBillExpense, [fieldName]: billTo}));
           
        }
    }
    const changePayTo =(fieldName, value)=>{
    
        let floatingPayExpense= false;
        if(driver?.ID!==value.ID)floatingPayExpense=true;
		console.log('floatingPayExpense = ', floatingPayExpense)
		console.log('value = ', value)
		console.log('oldPayToID = ', oldPayToID)
        if(value.ID!==oldPayToID.current) {
            let payTo ={ID:value.ID, Name:value.Name}
            updateExpenseFields(['floatingPayExpense', fieldName], [floatingPayExpense, payTo]);
            setInputValues((prev) => ({ ...prev, floatingPayExpense:floatingPayExpense, [fieldName]:  payTo }));
            oldPayToID.current=value.ID;
        }
    }

   
    const handleFieldsChange = (fields, values) => {
        console.log('fieldNames= ',fields)
        console.log('values= ',values)
        
        setInputValues((prevState) => {
            const newState = { ...prevState }; // <--- Unchanged
            fields.forEach((field, index) => { // <--- Changed from `for` loop to `forEach`
                newState[field] = values[index];
				expense[field]=values[index];
            });
            return newState; // <--- Unchanged
        });
		
        const debouncedUpdate = debouncedFieldUpdater(fields, updateExpenseFields);
        debouncedUpdate(fields, values); // Pass `fields` and `values` dynamically
    
 
    };
    const calcTotal = (type, qty, rate) =>{
        let amount = qty*rate;
        let fields, values;
        if(type==='qty'){
            fields=['qty','total'];
            values =[ qty, amount];
        }
        else{
            fields=['rate','total'];
            values=[rate, amount];
        } 
        if(amount!==0){
            fields.push('onHold');
            values.push(false);
        }

        handleFieldsChange(fields,values); 
        
    }

    return (
        <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
            <td data-label="Delete"  style={{ width: widths[0], padding: '0' }}>
                <button style={{ margin: '0', padding: '.5em', width:"95%" }}  disabled={inputValues.Invoice || inputValues.DPS}  onClick={(e) => deleteExpense(expense)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     >  Delete  </button>
            </td>
            {showAddToFreights ? (
                <td data-label="Add to Freights" style={{ width: widths[1], paddingRight: '.5em', paddingTop:"0 !important", paddingBottom:"0", paddingLeft: '.5em' }}>
                    <Checkbox style={{ width: '100%', paddingTop:"0 !important", }}checked={inputValues.addToFreights} onChange={(e) => handleFieldChange( 'addToFreights', e.checked)}/>
                </td>
            ) : (
                expense.dispatchID==='floatingDispatchExpense' ? (
                    <td data-label="Date" style={{ width: '9%', paddingRight: '.5em', paddingTop:"0 !important", paddingBottom:"0", paddingLeft: '.5em' }}>
                        <Calendar value={inputValues.JobDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( e.value )} />
                    </td>
                ) : (
                    <td data-label="On Hold" style={{ width: '5%', paddingRight: '.5em', paddingTop:"0 !important", paddingBottom:"0", paddingLeft: '.5em' }}>
                        <Checkbox disabled={inputValues.Invoice || inputValues.DPS}  style={{ width: '100%', paddingTop:"0 !important", }}checked={inputValues.onHold} onChange={(e) => handleFieldChange( 'onHold', e.checked)}/>
                    </td>
                )
            )}
            {company.SellMaterial && (
                <td data-label="Company" style={{ width: widths[2], padding: '0' }}>
                    <AutoCompleteInput fieldName="Company" field="CompanyName" value={inputValues.Company} suggestions={companies} setValue={setInputValues}  handleFieldChange={changeCompany} disabled={inputValues.Invoice || inputValues.PayStatement}  showLabel={false}/>  
                </td>
            )}
            <td  data-label="Name" style={{ width: widths[3], padding: '0' }}>
                <AutoCompleteInput fieldName="Name" field="Name" value={inputValues.Name} disabled={inputValues.Invoice || inputValues.DPS} suggestions={expenseNames} setValue={setInputValues} handleFieldChange={handleFieldChange} databaseList={'DefaultNames'} showLabel={false} defaultNameType={'Expense'}/>  
            </td>
            <td  data-label="Qty" style={{ width: widths[4], padding: '0' }}>
                <AutoSelectInputNumber   value={inputValues.qty} isCurrency={true}  disabled={inputValues.Invoice || inputValues.DPS}  onChange={(e) =>calcTotal('qty', e.value, inputValues.rate)} />
            </td>
            <td  data-label="Rate" style={{ width: widths[5], padding: '0' }}>
                <AutoSelectInputNumber isCurrency={true}  value={inputValues.rate}    disabled={inputValues.Invoice || inputValues.DPS}  onChange={(e) =>calcTotal('rate', inputValues.qty, e.value )} />
            </td>
       
            <td  data-label="Bill" style={{ width: widths[6], paddingRight: '.5em', paddingTop:"0 !important", paddingBottom:"0", paddingLeft: '.5em' }}>
                <Checkbox style={{ width: '100%', paddingTop:"0 !important", } } disabled={inputValues.Invoice}  checked={inputValues.bill} onChange={(e) => handleFieldChange( 'bill', e.checked)}/>
            </td>
            <td  data-label="Bill To" style={{ width: widths[7], padding: '0' }}>
                <AutoCompleteInput fieldName="BillTo" disabled={!inputValues.bill || inputValues.Invoice} field="Name" value={inputValues.BillTo} suggestions={accounts} setValue={setInputValues} handleFieldChange={changeBillTo} databaseList={'Accounts'} showLabel={false}/>  
            </td>
            <td  data-label="Invoice" style={{ width: widths[8], padding: '0' }}>
               {inputValues.Invoice && (<button style={{ margin: '0', padding: '.5em', width:"95%" }}   onClick={(e) => openInvoice(expense)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     >  Invoice </button>)}
            </td>
            <td  data-label="Pay" style={{ width: widths[9], paddingRight: '.5em', paddingTop:"0 !important", paddingBottom:"0", paddingLeft: '.5em' }}>
                <Checkbox style={{ width: '100%', paddingTop:"0 !important", }} disabled={inputValues.DPS} checked={inputValues.pay} onChange={(e) => handleFieldChange( 'pay', e.checked)}/>
            </td>
            <td  data-label="Pay to" style={{ width: widths[10], padding: '0' }}>
                <AutoCompleteInput fieldName="PayTo" field="Name" disabled={!inputValues.pay  || inputValues.DPS} value={inputValues.PayTo} suggestions={payToSuggestions} setValue={setInputValues} handleFieldChange={changePayTo} databaseList={'Accounts'} showLabel={false}/>  
            </td>
              <td  data-label="Pay Statement" style={{ width: widths[11], padding: '0' }}>
               {inputValues.DPS && (<button style={{ margin: '0', padding: '.5em', width:"95%" }}   onClick={(e) => openPayStatement(expense)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     >  Pay Statement </button>)}
               </td>
            <td  data-label="Total" style={{ width: widths[12], padding: '0' }}>
                <AutoSelectInputNumber   value={inputValues.total} isCurrency={true} disabled={inputValues.Invoice || inputValues.DPS}   onChange={(e) =>handleChangeTotal( e.value)} />
            </td>
           
         
        
        </tr>
    );
};

export default ExpenseList;