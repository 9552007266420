import React, { useState, useContext, useRef, createContext } from 'react';
import { db } from '../../../firebase';
import { query, collection, onSnapshot, where, doc, getDocs } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';


const InvoiceContext = createContext();

export const InvoiceContextProvider = ({ children }) => {
    const { gearedUser, addDocument, updateDocument, company } = UserAuth();

    const [invoice, setInvoice] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [invoiceVisible, setInvoiceVisible] = useState(false);
    const [truckingDispatches, setTruckingDispatches] = useState([]);
    const [expenseDispatches, setExpenseDispatches] = useState([]);
    const [createExpenses, setCreateExpenses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    // Refs
    const invoiceRef = useRef({});
	const invoicesRef = useRef([]);

    const expensesRef = useRef(null);
    const freightBillsRef = useRef(null);
    const dispatchesRef = useRef(null);
    const expenseDispatchesRef = useRef(null);
    const materialDispatchesRef = useRef(null);
    const unsubscribeExpensesRef = useRef(null);
    const unsubscribeDispatchesRef = useRef(null);
    const unsubscribeMaterialDispatchesRef = useRef(null);
    const unsubscribeFreightBillsRef = useRef(null);
	const invoiceExpenseListenersRef = useRef([]);
     
    const updateFreightBill =async(FreightBill)=>{
        let updateDoc ={  
            "timestamp": Date.now(),
            billed:FreightBill.billed,
            calcByLoad: FreightBill.calcByLoad,
            truckingBilled:FreightBill.truckingBilled,
            missing:FreightBill.missing,
            onHold:FreightBill.onHold,
            Adjusting:FreightBill.Adjusting,
            standByIsBilled:FreightBill.standByIsBilled,
            materialBilled:FreightBill.materialBilled,
            StandByInvoiceVNum:FreightBill.StandByInvoiceVNum,
            StandByInvoice:FreightBill.StandByInvoice,
            Invoice: FreightBill.Invoice
      
         };
         console.log('updateDoc for FB = ', updateDoc)
       await updateDocument(FreightBill, FreightBill.ID, 'FreightBills');
    }
    const checkFreightBill = async(Invoice, Dispatch,FreightBill) => {
        console.log('checkign FB = ', FreightBill)
        if(!FreightBill.StandByInvoice  && !Invoice.isMaterialInvoice){
            if (!FreightBill.standByOnHold ) {
                FreightBill.standByIsBilled = true;
                FreightBill.StandByInvoiceVNum = Invoice.VNum;
                FreightBill.StandByInvoice = Invoice.ID;
            }
            else {
                //Dispatch.unBilledFreights++;
                FreightBill.standByIsBilled = false;
                FreightBill.StandByInvoiceVNum = '';
                FreightBill.StandByInvoice = '';
            }
        }
        if(!FreightBill.MaterialInvoice){
            if (!FreightBill.materialOnHold ) {
                FreightBill.materialBilled = true;
                FreightBill.materialInvoiceVNum = Invoice.VNum;
                FreightBill.MaterialInvoice = Invoice.ID;
            }
            else {
                FreightBill.materialBilled  = false;
                FreightBill.materialInvoiceVNum = '';
                FreightBill.MaterialInvoice = '';
            }
        }
        if(!FreightBill.Invoice && !Invoice.isMaterialInvoice){
            if (!FreightBill.onHold) {
                FreightBill.truckingBilled = true;
                FreightBill.missing = false;
                FreightBill.Adjusting = false;
                if (FreightBill.standByIsBilled && FreightBill.materialBilled) FreightBill.billed = true;
                else   Dispatch.unBilledFreights++;
                FreightBill.InvoiceVNum = Invoice.VNum;
                FreightBill.Invoice = Invoice.ID;
              
            }else {
                Dispatch.unBilledFreights++;
                //if(FreightBill.hasStandBy) Dispatch.unBilledFreights++;
                FreightBill.truckingBilled = false;
                FreightBill.standByIsBilled = false;
                FreightBill.StandByInvoiceVNum = '';
                FreightBill.StandByInvoice = '';
                FreightBill.billed = false;
                FreightBill.InvoiceVNum = 0;
                FreightBill.Invoice = '';
             
            }
        }
        if (FreightBill.standByIsBilled && FreightBill.materialBilled && FreightBill.truckingBilled) FreightBill.billed = true;
        
        await   updateFreightBill( FreightBill);
    }
 
    const updateExpense =async(Expense)=>{
        let updateDoc ={  
            "timestamp": Date.now(),
            billed:Expense.billed,
            onHold:Expense.onHold,
            Invoice: Expense.Invoice
         };
         console.log('updateDoc for expense = ', updateDoc)
      await  updateDocument(Expense, Expense.ID, 'Expenses');
    }
    const checkExpense =  async(Invoice, Dispatch, Expense)=> {
  
        if (!Expense.onHold) {
            Expense.Invoice = Invoice.ID;
            Expense.billed = true;
        } else{
            Expense.billed = false;
            Dispatch.unBilledExpenses++;
        } 
       await updateExpense( Expense);
    }

    const updateChildObjects = async(Invoice, Dispatch, FreightBills, Expenses)=>{
        
        if(Invoice.isMaterialInvoice)  Dispatch.unBilledMaterials =0;
        else {
            Dispatch.unBilledFreights =0;
            Dispatch.unBilledExpenses =0;
        }
        console.log(' when updastaing child objects the freightBills = ', FreightBills)
        const freightBillPromises = FreightBills.map((freightBill) => checkFreightBill(Invoice, Dispatch, { ...freightBill }) );
    
        const expensePromises = Expenses.map((expense) =>   checkExpense(Invoice, Dispatch, { ...expense }) );

        await Promise.all([...freightBillPromises, ...expensePromises]);
        if(!Invoice.isExpenseInvoice){
            Dispatch.unBilledItems =   Dispatch.unBilledFreights +  Dispatch.unBilledExpenses +  Dispatch.unBilledMaterials ;
            console.log('Dispatch.unBilledItems = ' + Dispatch.unBilledItems)
            if(Dispatch.unBilledItems===0)Dispatch.Billable=false; else Dispatch.Billable=true;

            if(Invoice.isMaterialInvoice) await updateDocument({unBilledMaterials:Dispatch.unBilledMaterials, Billable: Dispatch.Billable}, Dispatch.ID, 'Dispatches');
            else  await updateDocument({unBilledFreights:Dispatch.unBilledFreights, unBilledExpenses: Dispatch.unBilledExpenses, Billable: Dispatch.Billable}, Dispatch.ID, 'Dispatches');
        }
    }

    const createInvoice = async(Invoice)=>{
        let tempInvoice ={...Invoice};
        let tempDispatch = {...tempInvoice.Dispatch};
        tempInvoice.Dispatch={};
        let tempFreightBills = [...tempInvoice.FreightBills];
        tempInvoice.FreightBills=[];
        let tempExpenses = [...tempInvoice.Expenses];
        tempInvoice.Expenses=[];
        console.log('now we are creating invoice and tempDispatch =- ', tempDispatch);
        console.log(' and the fireght bills = ', tempFreightBills);
        console.log('invoice we trying to add = ', tempInvoice)
		console.log(' and the expenses = ', tempExpenses);
        try {
        
            for(let l=0; l<tempInvoice.LineItems.length; l++){
                if(tempInvoice.LineItems[l].onHold){
                    tempInvoice.LineItems.splice(l,1)
                    l--;
                }
            }
            tempInvoice.previewing= false;
            console.log('lineItems b4 = ',tempInvoice.LineItems)
            tempInvoice.ID=await   addDocument(tempInvoice, 'Invoices');
            if (company.CurrentInvoiceNumber) updateDocument({CurrentInvoiceNumber:company.tempInvoiceNumber}, company.ID, 'Preferences');
            await updateChildObjects(tempInvoice, tempDispatch, tempFreightBills, tempExpenses)
        } catch (e){ console.error("Error adding document: ", e);}
   
    }
    const checkInvoiceNumber = async(Invoice)=>{

        let invNumberExists = false;
        console.log('WE ARE RUNNIGN TEH FETCH invoice at invoice number' +Invoice.InvoiceNumber)
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Invoices`;
        const q = query(collection(db, queryName), where("InvoiceNumber", "==", Invoice.InvoiceNumber));
        const querySnapshot = await getDocs(q);
        await querySnapshot.forEach((doc) => {
            invNumberExists=true;
            alert('An Invoice with the number ' + Invoice.InvoiceNumber +' already exists. Please enter unique number');
        });
        console.log('after the check boiii'+ invNumberExists);
        return invNumberExists;
    }

	const createExpenseDispatchFromExpense = (expense)=>{
        var ExpenseDispatch = {
            ID: expense.ID,
            Job:'',
            Account:expense.BillTo,
            isExpenseDispatch: true,
            unBilledItems: 0,
			Contact:{Name:'', Email:''},
			isFloatingExpenseDispatch: true,
            realJobDate: new Date(expense.JobDate),
            PONumber: '',
            ContractNumber: '',
			TruckTypeName: '',
            JobNumber: '',
            JobDate: expense.JobDate,
            Company: expense.Company,
            QueryDate: expense.QueryDate,

            LoadSite: {Name:'', ID:'', fullAddress:''},
            DumpSite: {Name:'', ID:'', fullAddress:''},
            FreightBills: [],
            Expenses: [],
        };
        ExpenseDispatch.Expenses = [];
        ExpenseDispatch.AccountName = expense.BillTo.Name;
        ExpenseDispatch.Account = expense.BillTo;
        ExpenseDispatch.unBilledItems = 1;
		console.log('we are creating an expense dispatch = ', ExpenseDispatch)
        ExpenseDispatch.Expenses.push({...expense});
        expenseDispatchesRef.current.push({...ExpenseDispatch});
    }
	const createExpenseDispatch = (dispatch, expense)=>{
        var ExpenseDispatch = {
            ID: dispatch.ID,
            Job:dispatch.Job,
            Account:dispatch.Account,
            isExpenseDispatch: true,
			Contact:dispatch.Contact ? dispatch.Contact : {Name:'', Email:''},
            unBilledItems: 0,
			isFloatingExpenseDispatch:false,
            realJobDate: new Date(dispatch.JobDate),
            PONumber: dispatch.PONumber,
            ContractNumber: dispatch.ContractNumber,
			TruckTypeName: dispatch.TruckType?.Name ? dispatch.TruckType?.Name : '',
            JobNumber: dispatch.JobNumber,
            JobDate: dispatch.JobDate,
            Company: dispatch.Company,
            QueryDate: dispatch.QueryDate,

            LoadSite: dispatch.LoadSite,
            DumpSite: dispatch.DumpSite,
            FreightBills: [],
            Expenses: [],
        };
        ExpenseDispatch.Expenses = [];
        ExpenseDispatch.AccountName = expense.BillTo.Name;
        ExpenseDispatch.Account = expense.BillTo;
        ExpenseDispatch.unBilledItems = 1;
		console.log('we are creating an expense dispatch = ', ExpenseDispatch)
        ExpenseDispatch.Expenses.push({...expense});
        expenseDispatchesRef.current.push({...ExpenseDispatch});
    }
    const createExpenseDispatches = ()=>{
		console.log('we are creating expense dispatches = ', expensesRef.current)
        for (var j = 0; j < expensesRef.current.length; j++) {
            let foundDispatch =false;
       
            for(var v=0; v< dispatchesRef.current.length; v++){
                let dispatch = {...dispatchesRef.current[v]};
                if (expensesRef.current[j].dispatchID === dispatch.ID) {
                    foundDispatch = true;
                    var foundExistingExpenseDispatch = false;
                    for (var k = 0; k < expenseDispatchesRef.current.length; k++) {
                        if (expenseDispatchesRef.current[k].Account.ID === expensesRef.current[j].BillTo.ID &&  expenseDispatchesRef.current[k].ID ===expensesRef.current[j].dispatchID ) {
                            foundExistingExpenseDispatch = true;
                            let foundExpense = false;
                            for(let e = 0; e<expenseDispatchesRef.current[k].Expenses.length; e++){
                                if(expenseDispatchesRef.current[k].Expenses[e].ID===expensesRef.current[j].ID){
                                    expenseDispatchesRef.current[k].Expenses[e]={...expensesRef.current[j]}
                                    foundExpense = true;
                                }
                            }
                            if(!foundExpense) expenseDispatchesRef.current[k].Expenses.push({...expensesRef.current[j]});
                            expenseDispatchesRef.current[k].unBilledItems=expenseDispatchesRef.current[k].Expenses.length
                        }
                    }
                    if (!foundExistingExpenseDispatch) createExpenseDispatch(dispatch,expensesRef.current[j] )
                }
            }
          
            if(!foundDispatch && expensesRef.current[j].dispatchID!=='floatingDispatchExpense') fetchDispatch(expensesRef.current[j]); 
			else if(!foundDispatch) createExpenseDispatchFromExpense(expensesRef.current[j]);
        }
       
        setExpenseDispatches([...expenseDispatchesRef.current])
       
    }
    const createMaterialDispatch = ( freight)=>{
		console.log('we are creating a material dispatch for freight with id = '+ freight.ID)
		let MaterialDispatch = {
            ID: freight.dispatchID,
            Job: {ID:freight.JobID},
            Account: freight.Account,
            isExpenseDispatch: false,
			isMaterialDispatch: true,
            unBilledItems: 0,
			TruckTypeName: freight.TruckType?.Name ? freight.TruckType?.Name : '',
            realJobDate: new Date( freight.JobDate),
            PONumber:  freight.PONumber ? freight.PONumber:'',
            ContractNumber: freight.ContractNumber ? freight.ContractNumber:'',
			MaterialCompany:freight.MaterialCompany ? freight.MaterialCompany:{ID:''},
            Company:freight.MaterialCompany ? freight.MaterialCompany:{ID:''},
            JobNumber:  freight.JobNumber,
            JobDate:  freight.JobDate,
 
            QueryDate:  freight.QueryDate,

            LoadSite:  freight.LoadSite,
            DumpSite:  freight.DumpSite,
            FreightBills: [],
            Expenses: [],
        };
		
        MaterialDispatch.unBilledItems = 1;
    
        MaterialDispatch.FreightBills.push({...freight});
        //console.log('we are updating adispathces ref= wtih this dispatch - ', FreightDispatch);
        materialDispatchesRef.current.push({...MaterialDispatch});

	}
	const createMaterialDispatches = () => {
        for (let j = 0; j < freightBillsRef.current.length; j++) {
            let foundDispatch = false;
            if (freightBillsRef.current[j].SellMaterial && !freightBillsRef.current[j].MaterialCompany?.ID !== freightBillsRef.current[j].Company?.ID) {
                
                
                // Check ALL dispatches in the array, including those we just added
                for (let v = 0; v < materialDispatchesRef.current.length; v++) {
                    let dispatch = materialDispatchesRef.current[v];
                    
                    if (freightBillsRef.current[j].dispatchID === dispatch.ID) {
                        foundDispatch = true;
                        let foundFreight = false;
                        
                        for (let e = 0; e < materialDispatchesRef.current[v].FreightBills.length; e++) {
                            if (materialDispatchesRef.current[v].FreightBills[e].ID === freightBillsRef.current[j].ID) {
                                materialDispatchesRef.current[v].FreightBills[e] = {...freightBillsRef.current[j]};
                                foundFreight = true;
                            }
                        }
                        
                        if (!foundFreight) materialDispatchesRef.current[v].FreightBills.push({...freightBillsRef.current[j]});
                        materialDispatchesRef.current[v].unBilledItems = materialDispatchesRef.current[v].FreightBills.length;
                        
                        // Break out of the inner loop once we've found and updated the dispatch
                        break;
                    }
                }
            }
            
            
            // Only create a new dispatch if we didn't find an existing one
            if (!foundDispatch && freightBillsRef.current[j].SellMaterial && 
                !freightBillsRef.current[j].MaterialCompany?.ID !== freightBillsRef.current[j].Company?.ID) {
                createMaterialDispatch(freightBillsRef.current[j]);
            }
        }
        
        console.log('materialDispatchesRef.current at the end = ', materialDispatchesRef.current);
        
        setTruckingDispatches([...dispatchesRef.current, ...materialDispatchesRef.current]);
    }
   
    const createFreightDispatch = (freight)=>{
		console.log('we are creating a freight dispatch = ', freight)
        var FreightDispatch = {
            ID: freight.dispatchID,
            Job: {ID:freight.JobID},
            Account: freight.Account,
            isExpenseDispatch: false,

            unBilledItems: 0,
			TruckTypeName: freight.TruckType?.Name ? freight.TruckType?.Name : '',
            realJobDate: new Date( freight.JobDate),
            PONumber:  freight.PONumber ? freight.PONumber:'',
            ContractNumber: freight.ContractNumber ? freight.ContractNumber:'',
            MaterialCompany:freight. MaterialCompany ? freight. MaterialCompany : freight.Company ? freight.Company:company,
            Company:freight.Company ? freight.Company:company,
            JobNumber:  freight.JobNumber,
            JobDate:  freight.JobDate,
 
            QueryDate:  freight.QueryDate,

            LoadSite:  freight.LoadSite,
            DumpSite:  freight.DumpSite,
            FreightBills: [],
            Expenses: [],
        };

      
        FreightDispatch.unBilledItems = 1;
    
        FreightDispatch.FreightBills.push({...freight});
        //console.log('we are updating adispathces ref= wtih this dispatch - ', FreightDispatch);
        dispatchesRef.current.push({...FreightDispatch});
    }

    const createFreightDispatches = ()=>{
        for (var j = 0; j < freightBillsRef.current.length; j++) {
            let foundDispatch =false;
    
            for(var v=0; v< dispatchesRef.current.length; v++){
                let dispatch = {...dispatchesRef.current[v]};
                if (freightBillsRef.current[j].dispatchID === dispatch.ID) {
                    foundDispatch = true;
                    let foundFreight= false;
                    for(let e = 0; e<dispatchesRef.current[v].FreightBills.length; e++){
                        if(dispatchesRef.current[v].FreightBills[e].ID===freightBillsRef.current[j].ID){
                            dispatchesRef.current[v].FreightBills[e]={...freightBillsRef.current[j]}
                            foundFreight = true;
                        }
                    }
                    if(!foundFreight) dispatchesRef.current[v].FreightBills.push({...freightBillsRef.current[j]});
                    dispatchesRef.current[v].unBilledItems=dispatchesRef.current[v].FreightBills.length
                }
            }
		
            if(!foundDispatch) createFreightDispatch(freightBillsRef.current[j] )
        }
        console.log('dispatchesRef.current at the end = ', dispatchesRef.current)
  
		setTruckingDispatches([...dispatchesRef.current, ...materialDispatchesRef.current]);
    }

    const fetchDispatch = async (expense) => {
        return new Promise((resolve, reject) => {
          console.log('WE ARE RUNNIGN TEH FETCH DISPATCH')
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, expense.dispatchID);
            onSnapshot(docRef, async (docSnap) => {
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
             
                if (docSnap.exists()) {
                    let tempDispatch = docSnap.data();
                    tempDispatch.ID=docSnap.id;
        
                    console.log('Dispatch inside of the invoice context is firing == ', tempDispatch)
                 
                    return createExpenseDispatch(tempDispatch, expense);
                }
            });
        });
    };

    const queryMaterialDispatches = () => {
        materialDispatchesRef.current = [];
        if (unsubscribeMaterialDispatchesRef.current) {
            unsubscribeMaterialDispatchesRef.current();
        }

        const q = query(
            collection(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`),
            where("unBilledMaterials", ">", 0)
        );

        unsubscribeMaterialDispatchesRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempDispatch = {...change.doc.data(),
                    ID: change.doc.id,
                    FreightBills: [],
                    realJobDate: new Date(change.doc.data().JobDate),
                    unBilledItems: change.doc.data().unBilledMaterials,
                    isMaterialDispatch: true
                };

                if (change.type === "added") {
                    materialDispatchesRef.current.push(tempDispatch);
                } else if (change.type === "modified") {
                    const index = materialDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    materialDispatchesRef.current[index] = tempDispatch;
                } else if (change.type === "removed") {
                    const index = materialDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    materialDispatchesRef.current.splice(index, 1);
                }
            });
        });
    };

    const queryDispatches = () => {
        dispatchesRef.current = [];
        if (unsubscribeDispatchesRef.current) {
            unsubscribeDispatchesRef.current();
        }

        const q = query(
            collection(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`),
            where("Billable", "==", true),
            where("QueryDate", ">=", "2024/10/01")
        );

        unsubscribeDispatchesRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempDispatch = {...change.doc.data(),
                    ID: change.doc.id,
                    FreightBills: [],
                    realJobDate: new Date(change.doc.data().JobDate),
                    unBilledItems: (change.doc.data().unBilledFreights || 0) +   (change.doc.data().unBilledExpenses || 0)
                };

                if (change.type === "added") {
                    dispatchesRef.current.push(tempDispatch);
                } else if (change.type === "modified") {
                    const index = dispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    dispatchesRef.current[index] = tempDispatch;
                } else if (change.type === "removed") {
                    const index = dispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    dispatchesRef.current.splice(index, 1);
                }
            });

            setTruckingDispatches([...dispatchesRef.current]);
            
            // Update material dispatches
            materialDispatchesRef.current = dispatchesRef.current
                .filter(disp => disp.unBilledMaterials > 0)
                .map(dispatch => ({
                    ...dispatch,
                    isMaterialDispatch: true,
                    unBilledItems: dispatch.unBilledMaterials
                }));

            createExpenseDispatches();
        });
    };

    const queryFreightBills = () => {
        freightBillsRef.current = [];
        dispatchesRef.current =[];
		materialDispatchesRef.current =[];
        if (unsubscribeFreightBillsRef.current) {
            unsubscribeFreightBillsRef.current();
        }

        const q = query(
            collection(db, `Organizations/${gearedUser.selectedOrgName}/FreightBills`),
            where("billed", "==", false),
            where("QueryDate", ">=", "2024/01/01")
        );

        unsubscribeFreightBillsRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempFreight = {
                    ...change.doc.data(),
                    ID: change.doc.id,
                    realJobDate: new Date(change.doc.data().JobDate)
                };

                if (change.type === "added") {
                    freightBillsRef.current.push(tempFreight);
                } else if (change.type === "modified") {
                    const index = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                    freightBillsRef.current[index] = tempFreight;
                } else if (change.type === "removed") {
                    const index = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                    freightBillsRef.current.splice(index, 1);
                }
            });

            createFreightDispatches();
			createMaterialDispatches();
        });
    };

    const queryExpenses = () => {
        expensesRef.current = [];
        expenseDispatchesRef.current=[];
        if (unsubscribeExpensesRef.current) {
            unsubscribeExpensesRef.current();
        }

        const q = query(
            collection(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`),
            where("billed", "==", false),
            where("floatingBillExpense", "==", true)
        );

        unsubscribeExpensesRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempExpense = {
                    ...change.doc.data(),
                    ID: change.doc.id,
                    realJobDate: new Date(change.doc.data().JobDate)
                };

                if (change.type === "added" && (!tempExpense.addToFreights || tempExpense.FreightBill !== '')) {
                    expensesRef.current.push(tempExpense);
                } else if (change.type === "modified") {
                    const index = expensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                    if (!tempExpense.addToFreights || tempExpense.FreightBill !== '') {
                        if (index >= 0) {
                            expensesRef.current[index] = tempExpense;
                        } else {
                            expensesRef.current.push(tempExpense);
                        }
                    } else if (index >= 0) {
                        expensesRef.current.splice(index, 1);
                    }
                } else if (change.type === "removed") {
                    const index = expensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                    if (index >= 0) {
                        expensesRef.current.splice(index, 1);
                    }
                }
            });

            createExpenseDispatches();
        });
    };

	
   

   
	console.log('isLoading  on context rerender= '+ isLoading)
    Number.prototype.formatMoney = function(c, d, t) {
        var roundNum=this;
        roundNum=roundNum+.000001;
        var n = Number(roundNum),
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d === undefined ? "." : d,
            t = t === undefined ? "," : t,
            s = n < 0 ? "-" : "",
            
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
              
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
     
    };
    return (
        <InvoiceContext.Provider value={{
            invoice,
            setInvoice,
            invoices,
            setInvoices,
            invoiceVisible,
            setInvoiceVisible,
            truckingDispatches,
            setTruckingDispatches,
            expenseDispatches,
            createExpenses,
            isLoading,
            setIsLoading,
            invoiceRef,
			invoicesRef,
            dispatchesRef,
            expenseDispatchesRef,
            materialDispatchesRef,
            queryDispatches,
            queryFreightBills,
            queryExpenses,
            queryMaterialDispatches,
            createInvoice,
            checkInvoiceNumber,
            createExpenseDispatches,
            company,
    
			invoiceExpenseListenersRef,
         
     
            
   
        }}>
            {children}
        </InvoiceContext.Provider>
    );
};

export const useInvoice = () => useContext(InvoiceContext);

