import React from 'react';
import {Textarea} from '@mobiscroll/react';

import LineItemList from './LineItemList';
import InputTextParent from '../../InputComponents/InputTextParent';
import PaymentItemList from '../../ListComponents/PaymentItemList';
import TotalItemList from '../../ListComponents/TotalItemList';

import { useLineItem } from '../hooks/useLineItem';


const RowExpansionComponent = ({ invoice, openExpensePopUp }) => {
	const { getColumnConfig } = useLineItem();
	const lineItems = invoice.LineItems || [];
	
	// Define columns for the LineItemList component
	const columns = getColumnConfig(invoice);
	console.log('columns for each linei tiem  ' , columns);
	// Define toggleOnHold function if not already defined
	const siteHeaderTemplate = () => {
		return (<>
			<div className='mbsc-row'>
				<div className="p-inputgroup mbsc-col-lg-6" >
					<span className="p-inputgroup-addon " > Load Site</span>
					<InputTextParent value={invoice.LoadSite.Name || ''} disabled={true}  />
				</div>
				
				<div className="p-inputgroup mbsc-col-lg-6" >
					<span className="p-inputgroup-addon " > Dump Site</span>
					<InputTextParent value={invoice.DumpSite.Name || ''} disabled={true}  />
				</div>
		   
			</div>
			<div className='mbsc-row'>
				<div className="p-inputgroup text-area-inputgroup mbsc-col-lg-6" style={{ marginTop:'.25em' }} >
					<span className="p-inputgroup-addon "   >Address: </span> 
					<Textarea 
						disabled={true}  
						style={{
							border:".5px solid #d1d5db", 
							borderBottomRightRadius:"6px", 
							borderTopRightRadius:"6px", 
							
						}} 
						value={invoice.LoadSite.fullAddress} 
						
					/>
				</div>
				<div className="p-inputgroup text-area-inputgroup mbsc-col-lg-6" style={{ marginTop:'.25em', marginBottom:'.25em' }} >
					<span className="p-inputgroup-addon  "   >Address: </span> 
					<Textarea 
						disabled={true}  
						style={{
							border:".5px solid #d1d5db", 
							borderBottomRightRadius:"6px", 
							borderTopRightRadius:"6px", 
							height:'100% !important'
						}} 
						
					value={invoice.DumpSite.fullAddress}  
					/>
				</div>
			</div>
		</>)
	}
	console.log('invoice balance total= ', invoice.Balance)
	const invoiceBalanceTotal = invoice.Balance.Total!==undefined ? ('$' + Number(invoice.Balance.Total).formatMoney(2)) :  invoice.Balance ? ('$' + Number(invoice.Balance).formatMoney(2)) : '$0.00';
	return (
		<div className="p-3" style={{ 
			maxHeight: '450px',
			overflowY: 'auto',
			backgroundColor: '#f8f9fa', 
			borderRadius: '8px', 
			margin: '0.5rem 1rem 1rem 1rem', 
			boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
			width: 'calc(100% - 2rem)' // Ensure the container takes full width minus margins
		}}>
			{siteHeaderTemplate(invoice)}
			<table style={{ 
					width: "100%", 
					tableLayout: "fixed", // This is important for fixed column widths
					borderCollapse: 'collapse', 
					border: '1px solid #dee2e6'
				}}>
				<thead>
					<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
						{columns.filter(col => col.visible).map(col => (
							<th key={col.id} style={{ width: col.width }}>{col.header}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{lineItems && lineItems.length > 0 ? (
						lineItems.map((lineItem, index) => {
							const prevFBNO = index > 0 ? lineItems[index - 1].FBNO : null;
							const nextFBNO = index < lineItems.length - 1 ? lineItems[index + 1].FBNO : null;
							
							const borderTop = lineItem.FBNO !== prevFBNO || lineItem.isEditable ? "1px" : "0px";
							const borderBottom = lineItem.FBNO !== nextFBNO || lineItem.isEditable ? "1px" : "0px";
							lineItem.hideFBNO = borderTop === "0px" ? true : false;
							const borderWidth = `${borderTop} 1px ${borderBottom} 1px`;
							
							return (
								<LineItemList 
									key={index} 
									invoice={invoice} 
									lineItem={lineItem}   
									borderWidth={borderWidth}   
									columns={columns}   
									openExpensePopUp={openExpensePopUp}
								/>
							);
						})
					) : (
						<tr>
							<td colSpan="9" style={{ textAlign: 'center' }}>
								No line items found
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{invoice.Totals && ( 
			<div className='mbsc-row' style={{paddingTop:'1em'}}>
				<div className='mbsc-col-lg-4 mbsc-col-6'>
					{invoice.Payments &&  (
						<table style={{ marginBottom: "5px", width: "100%", paddingBottom:"1.5em"}}>
							<thead>
								<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
									<th style={{ width: "50%" }}>Payment Date</th>
									<th style={{ width: "50%" }}>Amount</th>
								</tr>
							</thead>
							<tbody>
								{invoice.Payments.map(( paymentItem, index ) => (
									<PaymentItemList key={index} paymentItem={paymentItem}  />
								))}
								<tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
									<td data-label='Balance' style={{ fontWeight:'bold', width: '50%',  textAlign:'center', margin:'0 auto', borderWidth: '1px 1px 1px 1px ', borderColor:'#bcbcd1', borderStyle:'solid' }}>
										<span style={{ paddingRight:'.5em'}}>Balance</span>
									</td>
									<td data-label='Amount' style={{ fontWeight:'bold', width: '50%', padding: '0', borderWidth: '1px 1px 1px 1px ', borderColor:'#bcbcd1', borderStyle:'solid' }}>
										<span style={{display:'block', textAlign:'right', paddingRight:'1em'}}>{invoiceBalanceTotal}</span>
									</td>
								</tr>    
							</tbody>
						</table>
					)} 
				</div>
				<div className='mbsc-col-lg-4 mbsc-offset-lg-2 mbsc-col-6 '>
					<table style={{ marginBottom: "5px", width: "100%", paddingBottom:"1.5em"}}>
						<thead>
							<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
								<th style={{ width: "33%" }}>Type</th>
								<th style={{ width: "33%" }}>Qty</th>
								<th style={{ width: "33%" }}>Total</th>            
							</tr>
						</thead>
						<tbody>
							{invoice.Totals.map(( totalItem, index ) => (
								<TotalItemList key={index} totalItem={totalItem} isPercent={totalItem.Type === 'Broker' || totalItem.Type === 'Fuel' || totalItem.Type === 'Tax'}/>
							))}
						</tbody>
					</table>
				</div>
			</div>)}
		</div>
	);
  
}
export default RowExpansionComponent;