import React, {useState, useEffect, useRef} from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Calendar} from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../context/AuthContext';
import { Button} from '@mobiscroll/react';

import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import LoadingOverlay from '../MiscComponents/LoadingOverlay';

import { usePrevailingWage } from './context/PrevailingWageContext';
import { db } from '../../firebase';
import { query,  collection,onSnapshot, where } from 'firebase/firestore';
import {  useNavigate } from 'react-router-dom'

const PrevailingWageHome = () => {

    const {companies,accounts, addDocument,  formatDate, gearedUser} = UserAuth();
	const {   setPrevailingWageReport} = usePrevailingWage();
    const [searchJobs, setSearchJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const unsubscribeSearchDispatchesRef = useRef(null);
    const searchJobsRef = useRef(null);
	const navigate = useNavigate()
  

    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);

    const [inputValues, setInputValues]= useState({
        Account:{ID:'', Name:'No Account' }, 
        Location:{ID:'', Name:'No Location'},
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD')
    });

 
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
		realLatestDispatchDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'TruckType.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        BillType:{ value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    console.log('searchJobs = ', searchJobs)
   let sortedJobs = [...searchJobs].sort((a, b) => {
        if (a.QueryDate > b.QueryDate) return -1;
        if (a.QueryDate < b.QueryDate) return 1;
        return 0;
    });

   
    if (inputValues.Company)  sortedJobs = sortedJobs.filter(dispatch => dispatch.Company && dispatch.Company.ID === inputValues.Company.ID);
    if (inputValues.Account?.ID)  sortedJobs = sortedJobs.filter(dispatch => dispatch.Account && dispatch.Account?.ID === inputValues.Account?.ID);

	console.log('sortedJobs = ', sortedJobs);
    useEffect(() => {
        if(companies.length>0){
			console.log('setting first company')
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);

  

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const dateBodyTemplate = (rowData, fieldName) => {
        return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
    };  
	const booleanBodyTemplate = (rowData) => {

        return (
            <Checkbox  onChange={(e) => handleSelectedChange(rowData, e.checked)}  checked={rowData.Selected}  />
        );
    };
    const buttonBodyTemplate = (rowData) => {
       
     
        return <Button 
			color="primary"  
			style={{
				margin:"0", 
				marginTop:".5em", 
				marginLeft:".5em", 
				borderRadius:".5em", 
				paddingLeft:"1em !important", 
				paddingBottom: ".2em", 
				paddingTop: ".2em", 
				height:"100%"
			}} 
			onClick={(e) =>  openDispatch(rowData)}
		>
          	<span>{rowData.JobNumber  ? rowData.JobNumber : 'Open'}</span>
		</Button>;
    };

    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value })); 
    };

    const changeAccount= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    };  
    const handleSelectedChange = (job, value)=>{
        let tempJobs =[...sortedJobs]
        for(let i=0; i<tempJobs.length; i++)if(tempJobs[i].ID==job.ID) tempJobs[i].Selected=value;

       
        setSearchJobs([...tempJobs])
    }
  
	const openDispatch =(rowData) => {
        const encodedDate = encodeURIComponent(rowData.QueryDate); 
        console.log('RUNNIGN DISPATCH SEARCH OPEN DISPATCH')
		let win = window.open(`/dispatch/edit/none/${rowData.ID}/${encodedDate}/JobEdit`, '_blank'); 
		
		win.focus();
    }

  
    const queryJobs = ( ) => {
       
        searchJobsRef.current = [];

        if (unsubscribeSearchDispatchesRef.current) unsubscribeSearchDispatchesRef.current();
        setIsLoading(true);
        let dispQuery  = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/Jobs`));
		dispQuery = query(dispQuery, where("latestDispatchDate", ">=", inputValues.StartDate), where("latestDispatchDate", "<=", inputValues.EndDate)) 
        if (inputValues.Account.ID) dispQuery = query(dispQuery, where("Account.ID", "==",inputValues.Account.ID));
       
     


       	const handleJobChange = (change) => {
           	const tempJob = change.doc.data();
      
           	tempJob.ID = change.doc.id;
           	tempJob.Description = tempJob.LoadSite?.Name 
               ? (tempJob.LoadSite?.Name + ' - ' + (tempJob.DumpSite?.Name ? tempJob.DumpSite?.Name : 'No Dump Site')) 
               : ('No Load Site' + ' - ' + (tempJob.DumpSite?.Name ? tempJob.DumpSite?.Name : 'No Dump Site'));
           	tempJob.realJobDate=new Date(  tempJob.JobDate);
			tempJob.Selected = false;
    		 tempJob.realLatestDispatchDate=new Date(  tempJob.latestDispatchDate);

           	tempJob.TrucksUnassigned = tempJob.TrucksOrdered 
			? tempJob.TrucksOrdered - (tempJob.TrucksAssigned ? tempJob.TrucksAssigned : 0) 
			: 0 -(tempJob.TrucksAssigned ? tempJob.TrucksAssigned : 0);

           	if (change.type === "added") {
				console.log('found a dispatch' , tempJob)
               	const dispatchIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
			   	console.log('dispatchIndex = ', dispatchIndex)
               	if (dispatchIndex === -1) searchJobsRef.current.push(tempJob);
           	}
           	if (change.type === "modified") {
               	const dispatchIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
               	if (dispatchIndex !== -1) searchJobsRef.current[dispatchIndex] = tempJob;
           	}
           	if (change.type === "removed") {
               	const dispatchIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
               	if (dispatchIndex !== -1) searchJobsRef.current.splice(dispatchIndex, 1);
           	}	
   
       	};
   
        const processQuerySnapshot = (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => handleJobChange(change));
            console.log('setting full home searchJobsRef.currents = ', searchJobsRef.current);
            setSearchJobs([...searchJobsRef.current]);
      
            setIsLoading(false);
         
        };

       unsubscribeSearchDispatchesRef.current = onSnapshot(dispQuery, processQuerySnapshot);
		
   	}


	const createPrevailingWageReport = async() =>{
		let latestDispatchDate = formatDate(new Date('01/01/1969'), '/', 'YYYY/MM/DD');
		let newReport ={
			Account:inputValues.Account,
			Company:inputValues.Company,
			StartDate: inputValues.StartDate,
			EndDate: inputValues.EndDate,
			prevailStartDate:inputValues.StartDate,
			prevailEndDate:inputValues.EndDate,
			latestDispatchDate:latestDispatchDate,
			JobDate:'',
			Jobs:[],
			SignatureDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			SignatureDateValue: new Date(),
			WeekStartDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			WeekEndDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			OvertimePrevailingWageRate:  '',
			OwnerOpPrevailingWageRate: '',
			LoadSite: '',
			DumpSite: '',
			Active: true,
			PrevailingWageRate:  '',
			ContractorPayroll: false,
			OwnerOpRemarks: '',
			ID: '',
			DriverRemarks: '',
			Driver2a: false,
			Driver2b: false,
			Driver4a: false,
			Driver4b: true,
			Driver4c: false,
			OwnerOp4a: false,
			OwnerOp4b: false,
			ContractNumber:'',
			JobNumber:'',
			Contractor: {Name:''},
			createEmployeeDocs: true,
			createOwnerOpDocs: false,
			SubcontractorPayroll: false,
			Name: '',
			ProjectLocation: '',
			ProjectName: '',
			Title: '',          
			OwnerOpPayrollNumber: 1,
			DriverPayrollNumber: 1,
		}
		

		searchJobs.forEach(job => {if(job.Selected) newReport.Jobs.push(job.ID)})
		let tempID = await addDocument( newReport, "PrevailingWageReports");
		setPrevailingWageReport(newReport);
  
        navigate('/newprevailingwage/report/'+ tempID)
		console.log('newReport = ', newReport)
    }


	const jobColumns = [
		{
			label: 'Selected',
			field: "Selected",	
			body:(rowData) =>booleanBodyTemplate(rowData)
		},

		{
			label: 'Job #',
			field: "JobNumber",
			sortable: true,
			filter: true,
			body:(rowData) =>buttonBodyTemplate(rowData)
		},

		{
			label: 'Job Start Date',
			field: "realJobDate",
		
			filter: true,
			body:(rowData) => dateBodyTemplate(rowData,'realJobDate')
		},
		{
			label: 'Latest Dispatch Date',
			field: "realLatestDispatchDate",
		
			filter: true,
			body:(rowData) => dateBodyTemplate(rowData,'realLatestDispatchDate')
		},

		{
			label: 'Customer',
			field:'Account.Name',
		
			filter: true,
			body:(rowData) => rowData.Account?.Name || 'N/A'
		},
		{
			label: 'Description',
			field: "Description",
		
			filter: true,
			body:(rowData) => rowData.Description|| 'N/A'
	
		},
		
	];


		console.log('sortedJobs now = ', sortedJobs)
    return(
        <div className="card mbsc-grid " >
            <div className=" mbsc-row" >
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <AutoCompleteInput 
						label="Company" 
						fieldName="Company" 
						field="CompanyName" 
						value={inputValues.Company} 
						labelClass='p-inputgroup-addon search-auto-input'  
						suggestions={companies} 
						setValue={setInputValues} 
						handleFieldChange={changeCompany} 
					/>
                    <AutoCompleteInput 
						label="Customer" 
						fieldName="Account" 
						field="Name" 
						value={inputValues.Account} 
						labelClass='p-inputgroup-addon search-auto-input'  
						databaseList={'Accounts'} 
						suggestions={accounts} 
						setValue={setInputValues} 
						handleFieldChange={changeAccount} 
					/>
                </div>
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                        <Calendar 
							pt={{
								tableHeader:{'className':'dontResize'},
								tableHeaderCell:{'className':'dontResize'},
								day:{'className':'dontResize'}
								}}  
							value={inputValues.StartDateValue} 
							style={{width:"100%"}} 
							onChange={(e) => handleDateChange( 'StartDate',e.value )} 
						/>
                    </div> 
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                        <Calendar 
							pt={{
								tableHeader:{'className':'dontResize'},
								tableHeaderCell:{'className':'dontResize'},
								day:{'className':'dontResize'}
							}}  
							value={inputValues.EndDateValue} 
							style={{width:"100%"}} 
							onChange={(e) => handleDateChange( 'EndDate',e.value )}
						/>
                    </div>

                    <div className="flex justify-content-end">
                        <Button  
						color="primary"  
						disabled={(inputValues.StartDateValue > inputValues.EndDateValue) || !inputValues.Account?.ID}
						onClick={(e) => queryJobs()} 
						style={{
							margin:"0", 
							marginTop:".5em", 
							marginLeft:".5em", 
							paddingLeft:"1em !important", 
							paddingBottom: ".2em", 
							paddingTop: ".2em", 
							height:"100%"
						}}
						>
							Search Jobs
						</Button> 
						<Button  
							color="primary"  
							onClick={(e) => createPrevailingWageReport()} 
							style={{
								margin:"0", 
								marginTop:".5em", 
								marginLeft:".5em", 
								paddingLeft:"1em !important",
								paddingBottom: ".2em", 
								paddingTop: ".2em", 
								height:"100%"
							}}
						>
							Create Report
						</Button> 

                        {/* <Button  color="primary" onClick={(e) =>resetSearch() } style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Search Fields</Button> 
                       {searchedObject && (<Button  color="primary" onClick={(e) => setInputValues({...searchedObject})} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Filters</Button> )}*/}

                    </div>
                </div>
            </div>
            <div className=" mbsc-row" >
                <DataTable value={sortedJobs} paginator rows={25} dataKey="ID" filters={filters} header='Jobs' filterDisplay="row" emptyMessage="No dispatches found.">
			
				{jobColumns.map((column, index) => (
					<Column 
						key={index}
						field={column.field}
						sortable={column.sortable}
						filter={column.filter}
						body={column.body}
						pt={{root: { 'data-label': column.label }}} 
						header={column.label} 
						filterPlaceholder={`Search by ${column.label}`}
						showFilterMenu={false} 
					/>
				))}
                </DataTable>
            </div>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    )
}


export default PrevailingWageHome;
