
import React, {useEffect, useRef, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import InputTextParent from '../InputComponents/InputTextParent'; 
import { InputNumber} from 'primereact/inputnumber';
import { Button } from 'primereact/button';

import { useGlobal } from '../../context/GlobalContext'
import { UserAuth } from '../../context/AuthContext'

const LocationPopUp = (props) => {
    const { locationVisible, setLocationVisible, location} = useGlobal();
    const { updateDocument, locations, addDocument} = UserAuth();
    const [inputValues, setInputValues] = useState({});

    const isFormDirty = useRef(false);
  //console.log('location = ', location)
    const closeLocationPopUp = () => {
     
        if(isFormDirty.current){
            if (window.confirm("Changes you made are not saved. Are you sure you want to close?"))  {
                isFormDirty.current=false;
                setLocationVisible(false);
            }  
        }else  setLocationVisible(false);    

    
    };
    useEffect(()=>{
              const handleBeforeUnload = (event) => {
                  if (isFormDirty.current) {
                      event.preventDefault();
                      event.returnValue = ""; // Required for some browsers
                  }
              };
              window.addEventListener("beforeunload", handleBeforeUnload);
              return () => {
                  window.removeEventListener("beforeunload", handleBeforeUnload);
              };
      },[]);

    useEffect(() => {
        if (location && Object.keys(location).length > 0) {
           
            setInputValues({
                ID:location.ID,
                Name:location.Name,
                Address:location.Address,
                City:location.City,
                State:location.State,
                ZipCode:location.ZipCode,
            });
           
        }
    }, [location]);

     const handleFieldChange = (fieldName, value) => {
        isFormDirty.current=true;
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
      
    };

    const handleSaveLocation = ()=>{
        const locationName = inputValues.Name?.trim();
        let updatedObject ={...inputValues};
        updatedObject.fullAddress= updatedObject.Address + ', ' + updatedObject.City + ', ' +updatedObject.State + ', ' +updatedObject.ZipCode;
        if (!locationName) {
            alert('Please enter a name before saving.');
            return;
        }
         console.log('inputValues.ID == '+ inputValues.ID)
        const locationExists = locations.some( (location) => location.Name.toLowerCase() === locationName.toLowerCase() && location.ID!==inputValues.ID );

        if (locationExists) {
            alert('An location with this name already exists.');
            return;
        }
        isFormDirty.current=false;
        if(location.ID)updateDocument( updatedObject, location.ID, 'Locations');
        else addDocument( updatedObject,  'Locations');
		isFormDirty.current=false;
		setLocationVisible(false);
    }
  const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() =>closeLocationPopUp()} />
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Save" icon="pi pi-check" onClick={() => handleSaveLocation()}  />
     
        </div>
    
    );
return(
    <Dialog header="Location Details" visible={locationVisible} style={{ width: '55vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeLocationPopUp}>
         
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> Name</span>
                <InputTextParent value={inputValues.Name} onChange={(e) => handleFieldChange('Name', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> Address</span>
                <InputTextParent value={inputValues.Address} onChange={(e) => handleFieldChange('Address', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> City</span>
                <InputTextParent value={inputValues.City} onChange={(e) => handleFieldChange('City', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">State</span>
                <InputTextParent value={inputValues.State} onChange={(e) => handleFieldChange('State', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Zip Code</span>
                <InputNumber  useGrouping={false}  value={inputValues.ZipCode} onChange={(e) => handleFieldChange('ZipCode', e.value)} />
            </div>
          

                  
    </Dialog>
);
};

export default LocationPopUp;