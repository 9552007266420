import React, {useState,useContext,useRef, createContext,useEffect} from 'react'
import { db } from '../../../firebase';
import {  query,  collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';

const PrevailingWageContext = createContext();

export const PrevailingWageContextProvider = ({ children }) => {
    const { gearedUser} = UserAuth();

    const [freightBills, setFreightBills]= useState(null);
    const [prevailingWageReport, setPrevailingWageReport] = useState(null);
    const [prevailingWageReports, setPrevailingWageReports] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

    const freightBillListenerRef = useRef(null);
    const freightBillsRef = useRef(null);

    const prevailingWageReportsRef = useRef(null);
    const prevailingWageReportListenerRef = useRef(null);
	
    const queryFreightBills = (jobID)=>{
		return new Promise((resolve, reject) => {
			if (freightBillListenerRef.current) freightBillListenerRef.current();
			freightBillsRef.current=[];
			let hasReceivedServerData = false;
			const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
			const q = query(collection(db, queryName), where("JobID", "==",jobID));
			console.log('runnign query fbs for jobid= '+ jobID)
			freightBillListenerRef.current= onSnapshot(q,{ includeMetadataChanges: true   }, (querySnapshot) => {
				querySnapshot.docChanges().forEach((change) => {
					const tempFreight = change.doc.data();
					let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
					console.log(' source = '+ source);
					console.log('found at emp freight =  ', tempFreight);
				
					if (change.type === "added") {
						tempFreight.ID = change.doc.id;
						freightBillsRef.current.push(tempFreight);
					}
					if (change.type === "modified") {
						const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
						freightBillsRef.current[jobIndex] = tempFreight;
					}
					if (change.type === "removed") {
						const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
						freightBillsRef.current.splice(jobIndex,1);
					}

					
				});
				console.log(' INSIDE OF THE QUERY FREIGHT BILLS = freightBillsRef.current = ', freightBillsRef.current)
				setFreightBills([...freightBillsRef.current])
				if (!querySnapshot.metadata.fromCache && !hasReceivedServerData) {
					hasReceivedServerData = true;
					resolve();
				}
				
			});
		});
    }
    const queryPrevailingWageReports= ()=>{
        console.log('we are running the query prevailing wage reports')
        if (prevailingWageReportListenerRef.current) prevailingWageReportListenerRef.current();
        prevailingWageReportsRef.current=[];
        const queryName = `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`;
        const q = query(collection(db, queryName));

        prevailingWageReportListenerRef.current= onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempPrevailingWageReport = change.doc.data();
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log(' source = '+ source);
                console.log('found at emp report =  ', tempPrevailingWageReport);
                tempPrevailingWageReport.prevailEndDateValue= tempPrevailingWageReport.prevailEndDate ? new Date(tempPrevailingWageReport.prevailEndDate) :
				tempPrevailingWageReport.EndDate ? new Date(tempPrevailingWageReport.EndDate) : '';
                tempPrevailingWageReport.prevailStartDateValue= tempPrevailingWageReport.prevailStartDate ? new Date(tempPrevailingWageReport.prevailStartDate) :
				tempPrevailingWageReport.StartDate ? new Date(tempPrevailingWageReport.StartDate) : '';
				tempPrevailingWageReport.latestDispatchDateValue= tempPrevailingWageReport.latestDispatchDate ? new Date(tempPrevailingWageReport.latestDispatchDate) : '';
                if (change.type === "added") {
                    tempPrevailingWageReport.ID = change.doc.id;
                    prevailingWageReportsRef.current.push(tempPrevailingWageReport);
                }
                if (change.type === "modified") {
                    const reportIndex = prevailingWageReportsRef.current.findIndex(d => d.ID === tempPrevailingWageReport.ID);
                    prevailingWageReportsRef.current[reportIndex] = tempPrevailingWageReport;
                }
                if (change.type === "removed") {
                    const reportIndex = prevailingWageReportsRef.current.findIndex(d => d.ID === tempPrevailingWageReport.ID);
                    prevailingWageReportsRef.current.splice(reportIndex,1);
                }
            });
			console.log('prevailingWageReportsRef.current = ', prevailingWageReportsRef.current)
                setPrevailingWageReports([...prevailingWageReportsRef.current])
        
        });
    }



   
    return (
        <PrevailingWageContext.Provider value={{
      		freightBills, freightBillsRef, isLoading, setIsLoading,
			queryFreightBills, prevailingWageReport, setPrevailingWageReport,  queryPrevailingWageReports, prevailingWageReports,
        }}>
            {children}
        </PrevailingWageContext.Provider>
    );
}
export const usePrevailingWage= () => {
    return useContext(PrevailingWageContext);
};