import React, { useEffect, useState, useRef,useCallback } from 'react';

import { UserAuth } from '../../context/AuthContext';

import { useInvoice } from './context/InvoiceContext';
import { usePrint } from './context/PrintContext';
import { useLineItem} from './hooks/useLineItem';
import { useInvoiceCalc } from './hooks/useInvoiceCalc';

import { TabView, TabPanel } from 'primereact/tabview';

import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import  { Button} from 'primereact/button';

import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import { Badge } from 'primereact/badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTruck, faLayerGroup} from '@fortawesome/free-solid-svg-icons';
import { db } from '../../firebase';
import {query, collection,  onSnapshot, where } from 'firebase/firestore';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import { useInvoiceCreate } from './context/InvoiceCreateContext';
const  InvoiceCreate =(props)=> {
    const { queryFreightBills, queryExpenses, setInvoice, truckingDispatches, setInvoices, 
		materialDispatchesRef,  expenseDispatchesRef, dispatchesRef,invoiceExpenseListenersRef,
       setInvoiceVisible, invoiceRef, invoicesRef, isLoading } = useInvoice();
    const {makeFreightBillLineItem, makeExpenseLineItem, makeMaterialLineItem  } = useLineItem();
	const { calcInvoiceTotal } = useInvoiceCalc();
    const {setPrintTags, } = usePrint();
    const { gearedUser, company, accounts,formatDate, companies } = UserAuth();

	const { openInvoiceExpenseListener,  } = useInvoiceCreate();
    const [activeTab, setActiveTab]= useState(0);
    const [tableDispatches, setTableDispatches]= useState([]);

    const invoiceFreightListenersRef = useRef([]);

    const sorted = [...tableDispatches].sort((a, b) => {
        if (a.QueryDate < b.QueryDate) return -1;
        if (a.QueryDate > b.QueryDate) return 1;
        return 0;
    });
    var todaysDate = new Date();
    let startDate =new Date();

    startDate.setDate(todaysDate.getDate() - 30);
    const [inputValues, setInputValues]=useState({
        EndDateValue:todaysDate,
        StartDateValue:startDate
    
    });
    const sortedDispatches= sorted.map((dispatch, index) => ({ ...dispatch, index })).filter(dispatch =>{ if(activeTab===2) return dispatch.MaterialCompany?.ID === inputValues.Company?.ID
        else return dispatch.Company?.ID === inputValues.Company?.ID});

    const iconMapping = {faUser: faUser, faTruck: faTruck, faLayerGroup:faLayerGroup};
		console.log('isLoading  on create rerender= '+ isLoading)
    useEffect(() => {
        // Use Promise.all to wait for both queries to complete
        console.log('start of create invoice page and we doin teh query FBS and materials and expenses');
        queryFreightBills();
        queryExpenses();
          
    }, []);
    useEffect(() => {

      	if(activeTab===0)setTableDispatches([...dispatchesRef?.current])
        if(activeTab===1)setTableDispatches([...expenseDispatchesRef?.current])
        if(activeTab===2)setTableDispatches([...materialDispatchesRef?.current])
    }, [materialDispatchesRef.current,  expenseDispatchesRef.current, dispatchesRef.current,truckingDispatches]);

    useEffect(() => {
        console.log('itinputValues?.Company = ', inputValues?.Company)
        if(companies.length>0 && !inputValues?.Company){
            console.log('itereating through teh companies and they = ', companies)
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)   setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
           
        }
    }, [companies]);

    const [filters, setFilters] = useState({
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        'Account.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        'LoadSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS},
        'DumpSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        unBilledFreights: { value: null, matchMode: FilterMatchMode.EQUALS },
        // Don't set anything here for Account.Name initially
    });



    const handleFieldChange = (fieldName, value) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
      
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
    
    };
      
    const handleDateChange =  useCallback(( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    }, []);

    const getCurrentTime= () =>{
        var tempdate = new Date();
   
        var realTempDate = formatDate(tempdate, '/', 'MM/DD/YYYY');
        if (Number(tempdate.getMinutes()) < 10) realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + '0' + tempdate.getMinutes();
        else realTempDate = realTempDate + ' ' + tempdate.getHours() + ':' + tempdate.getMinutes();
        return realTempDate;
    }
	const getDispatchAccount = (Dispatch) =>{
	
		for (let q = 0; q < accounts.length; q++) {
            if (accounts[q].ID == Dispatch.Account.ID) {
                let tempAccount = {...accounts[q]};
                console.log('accounts[q] = ' , accounts[q])
                tempAccount.custAddress2 = tempAccount.City + ', ' + tempAccount.State + ' ' + tempAccount.ZipCode;
                var  realmID, accountTermsAndCond, qbCustomerID;
                if (!tempAccount.Quickbooks) tempAccount.Quickbooks = [];
                if (!tempAccount.InvoiceNotes) accountTermsAndCond = ''; else accountTermsAndCond = tempAccount.InvoiceNotes;
                if (!tempAccount.QBCustomerID) qbCustomerID = ''; else qbCustomerID = tempAccount.QBCustomerID;
                if (Dispatch.Company.realmID) {
                    for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===Dispatch.Company.realmID)qbCustomerID = tempAccount.Quickbooks[p].QBCustomerID;
                    if (!company.realmID) realmID = ''; else realmID = company.realmID;
                } else realmID = Dispatch.Company.realmID;

                var dispatchAccount = {
                    ID: tempAccount.ID,
                    Name: tempAccount.Name,
                    Address: tempAccount.Address,
                    City: tempAccount.City,
                    State: tempAccount.State,
                    ZipCode: tempAccount.ZipCode,
                    Quickbooks: tempAccount.Quickbooks,
                    QBCustomerID: qbCustomerID,
                    realmID: realmID,
                    InvoiceNotes: tempAccount.InvoiceNotes,
                    custAddress2: tempAccount.custAddress2
                };
            }
        }
		return dispatchAccount;

	}

	const getDispatchContact = (Dispatch) =>{
		let dispatchContact = {
			Name: Dispatch.Contact?.Name ? Dispatch.Contact?.Name :'',
			Email: Dispatch.Contact?.Email ? Dispatch.Contact?.Email :'',
		}
		return dispatchContact;
	}

	const getDispatchSite = (Dispatch, fieldName) =>{	
        let dispatchSite = {
            ID:Dispatch[fieldName].ID,
            Name: Dispatch[fieldName].Name ? Dispatch[fieldName].Name :'',
            Address:Dispatch[fieldName].Address ? Dispatch[fieldName].Address  :'',
            Address2:Dispatch[fieldName].Address2 ? Dispatch[fieldName].Address2:'',
            City:Dispatch[fieldName].City ? Dispatch[fieldName].City:'',
            State:Dispatch[fieldName].State ? Dispatch[fieldName].State:'',
            ZipCode:Dispatch[fieldName].ZipCode ?Dispatch[fieldName].ZipCode   :'',
           
            fullAddress:Dispatch[fieldName].fullAddress ?Dispatch[fieldName].fullAddress :'',
        }
		return dispatchSite;
	}

	const createCustomInvoice = () =>{
		invoicesRef.current=[];
		setInvoice({});
        setPrintTags(false);
        if(invoiceFreightListenersRef.current.length>0)for(var u=0;u<invoiceFreightListenersRef.current.length; u++)invoiceFreightListenersRef.current[u]();
        if(invoiceExpenseListenersRef.current.length>0)for(var u=0;u<invoiceExpenseListenersRef.current.length; u++)invoiceExpenseListenersRef.current[u]();
		if (company.CurrentInvoiceNumber) company.tempInvoiceNumber = company.CurrentInvoiceNumber;
		var ExpenseDispatch = {
            ID: 'custom',
            Job:'',
            Account:accounts[0],
            isExpenseDispatch: true,
            unBilledItems: 0,
			isFloatingExpenseDispatch: true,
            realJobDate: new Date(),
            PONumber: '',
            ContractNumber: '',
			TruckTypeName: '',
            JobNumber: '',
            JobDate: formatDate( new Date(), '/', 'MM/DD/YYYY'),
            Company: inputValues.Company,
            QueryDate: formatDate( new Date(), '/', 'YYYY/MM/DD'),
            LoadSite: {Name:'', ID:'', fullAddress:''},
            DumpSite: {Name:'', ID:'', fullAddress:''},
            FreightBills: [],
            Expenses: [],
        };
		console.log('ExpenseDispatch = ', ExpenseDispatch)
		console.log('invoicesRef.current b4 = ', invoicesRef.current)
		previewInvoiceByExpense({...ExpenseDispatch}, invoicesRef.current);
		invoiceRef.current=invoicesRef.current[0];
	
	}

    const previewInvoices = () =>{
   
        invoicesRef.current =[];
		if (company.CurrentInvoiceNumber) company.tempInvoiceNumber = company.CurrentInvoiceNumber;
        
        setPrintTags(false);
        if(invoiceFreightListenersRef.current.length>0)for(var u=0;u<invoiceFreightListenersRef.current.length; u++)invoiceFreightListenersRef.current[u]();
        if(invoiceExpenseListenersRef.current.length>0)for(var u=0;u<invoiceExpenseListenersRef.current.length; u++)invoiceExpenseListenersRef.current[u]();
        invoiceFreightListenersRef.current =[];
        invoiceExpenseListenersRef.current = [];
        for(let i=0; i<tableDispatches.length; i++){
            if(tableDispatches[i].Selected){
                console.log('tableDispatches[i] = ', tableDispatches[i])
                if(tableDispatches[i].isExpenseDispatch)previewInvoiceByExpense({...tableDispatches[i]});
                else  if(tableDispatches[i].isMaterialDispatch)previewInvoiceByMaterial({...tableDispatches[i]});
                else previewInvoiceByDispatch({...tableDispatches[i]});
            }
        }
        console.log('so this is when we first set the invoice ',invoicesRef.current[0])
        invoiceRef.current=invoicesRef.current[0];
		
    }

    const previewInvoiceByDispatch = (Dispatch)=>{
        var InvoiceNumber = 111111;
        if (!company.TermsAndCond)company.TermsAndCond = '';
 
        if (company.tempInvoiceNumber) {
            InvoiceNumber = company.tempInvoiceNumber;
            company.tempInvoiceNumber++;
        }


            console.log('dispatch = ', Dispatch);
            var contactEmail = '';
            var contactName = 'No Contact';
            if (Dispatch.Contact) {
                if (Dispatch.Contact.Name !== '' && Dispatch.Contact.Name) contactName = Dispatch.Contact.Name;
                if (Dispatch.Contact.Email !== '' && Dispatch.Contact.Email) contactEmail = Dispatch.Contact.Email;
            }

           
            for (var q = 0; q < accounts.length; q++) {
                if (accounts[q].ID === Dispatch.Account.ID) {
                    var tempAccount = {...accounts[q]};
                    console.log('accounts[q] = ' , accounts[q])
                    tempAccount.custAddress2 = tempAccount.City + ', ' + tempAccount.State + ' ' + tempAccount.ZipCode;
                    var  realmID, accountTermsAndCond, qbCustomerID;
                    if (!tempAccount.Quickbooks) tempAccount.Quickbooks = [];
                    if (!tempAccount.InvoiceNotes) accountTermsAndCond = ''; else accountTermsAndCond = tempAccount.InvoiceNotes;
                    if (!tempAccount.QBCustomerID) qbCustomerID = ''; else qbCustomerID = tempAccount.QBCustomerID;
                    if (!Dispatch.Company.realmID) {
                   
                        if (!company.realmID) realmID = ''; else realmID = company.realmID;
                    } else{
                        for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===Dispatch.Company.realmID)qbCustomerID = tempAccount.Quickbooks[p].QBCustomerID;
                        realmID = Dispatch.Company.realmID;
                    } 

                    var Account = {
                        ID: tempAccount.ID,
                        Name: tempAccount.Name,
                        Address: tempAccount.Address,
                        City: tempAccount.City,
                        State: tempAccount.State,
                        ZipCode: tempAccount.ZipCode,
                        Quickbooks: tempAccount.Quickbooks,
                        InvoiceNotes: tempAccount.InvoiceNotes,
                        custAddress2: tempAccount.custAddress2
                    };
                }
            }
            let dumpSite = {
                ID:Dispatch.DumpSite.ID,
                Name: Dispatch.DumpSite.Name ? Dispatch.DumpSite.Name :'',
                Address:Dispatch.DumpSite.Address ? Dispatch.DumpSite.Address  :'',
                Address2:Dispatch.DumpSite.Address2 ? Dispatch.DumpSite.Address2:'',
                City:Dispatch.DumpSite.City ? Dispatch.DumpSite.City:'',
                State:Dispatch.DumpSite.State ? Dispatch.DumpSite.State:'',
                ZipCode:Dispatch.DumpSite.ZipCode ?Dispatch.DumpSite.ZipCode   :'',
                fullAddress:Dispatch.DumpSite.fullAddress ?Dispatch.DumpSite.fullAddress :'',
            }
            let loadSite = {
                ID:Dispatch.LoadSite.ID,
                Name: Dispatch.LoadSite.Name ? Dispatch.LoadSite.Name :'',
                Address:Dispatch.LoadSite.Address ? Dispatch.LoadSite.Address  :'',
                Address2:Dispatch.LoadSite.Address2 ? Dispatch.LoadSite.Address2:'',
                City:Dispatch.LoadSite.City ? Dispatch.LoadSite.City:'',
                State:Dispatch.LoadSite.State ? Dispatch.LoadSite.State:'',
                ZipCode:Dispatch.LoadSite.ZipCode ?Dispatch.LoadSite.ZipCode   :'',
                fullAddress:Dispatch.LoadSite.fullAddress ?Dispatch.LoadSite.fullAddress :'',
            }
            if(!company.ShowInvoiceMaterial)company.ShowInvoiceMaterial=false;
            console.log('account = ', Account)
            let tempInvoice = {
                ID: invoicesRef.current.length,
                Name: '',
                BillBy: 'DispatchID',
                VNum: 1,
                ShowMaterial:company.ShowInvoiceMaterial,
                JobID: Dispatch.Job.ID,
                ParentID: Dispatch.ID,
                DispID: Dispatch.ID,
                ParentName: Dispatch.Account.Name,
                ParentInvoice: '',
                QBCustomerID:qbCustomerID,
				TruckTypeName: Dispatch.TruckTypeName,
                Contact: {
                    Name: contactName,
                    Email: contactEmail
                },
                DispatchID: Dispatch.ID,
                ContactName: contactName,
                DispatchJobDate: Dispatch.JobDate,
                JobDate: Dispatch.JobDate,
                QueryDate:  formatDate(new Date(Dispatch.JobDate), '/', 'YYYY/MM/DD'),
				PONumber: Dispatch.PONumber || '',
				ContractNumber: Dispatch.ContractNumber || '',
				JobNumber: Dispatch.JobNumber || '',
                InvoiceNumber: InvoiceNumber,
                LoadSite:loadSite,
                DumpSite:dumpSite,
                TaxPercent:0,
                Account: Account,
                AccountID: Account.ID,
                Paid: false,
                isFromInvoice: false,
                Dispatch:{},
                Dispatches: [],
           
                InvoiceDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
                StartDate: Dispatch.JobDate,
                EndDate: Dispatch.JobDate,
                LineItems: [],
                Dates: [],
                Locations: [],
                realmID: realmID,
                showWeightTags: false,
                isMaterialInvoice:false,
                isExpenseInvoice: false,
                QBInvoiceID: '',
                createdAt: getCurrentTime(),
                createdBy:gearedUser.Email,
                InvoiceNotes: tempAccount.InvoiceNotes,
                TermsAndCond: company.TermsAndCond,
                Versions: [{ VNum: 1, URL: '' }],
                PDFLogo:'',
                previewing:true,
                sortByDisp:true,
                Totals: [],
                Payments: [],
                Expenses: [],
                FreightBills:[]
            };
       
           
            if (!Dispatch.Company) tempInvoice.Company = company; else tempInvoice.Company = Dispatch.Company;
            if (company.ID == Dispatch.MaterialCompany.ID && Dispatch.SellMaterial) tempInvoice.Company = Dispatch.MaterialCompany;

            tempInvoice.Dispatch={...Dispatch};
           
            console.log(' tempInvoice.ID= '+  tempInvoice.ID);
            invoicesRef.current.push({...tempInvoice})
            const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
            const freightQuery = query(collection(db, queryName), where("dispatchID", "==", Dispatch.ID));
                        
            invoiceFreightListenersRef.current.push(onSnapshot(freightQuery, (querySnapshot) => {
                console.log('tempInvoice.ID ==' +tempInvoice.ID)
                const foundIndex =  invoicesRef.current.findIndex(obj => obj.ID === tempInvoice.ID);
                console.log('inside teh feright listener and the foundIndex = ' + foundIndex)
                if(foundIndex!==-1){
                    querySnapshot.docChanges().forEach((change) => {
                        var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server"; 
                        var tempFreight = change.doc.data();
                        tempFreight.ID = change.doc.id;
                       
                            if (change.type === "added") {
                                console.log('found a Freight Bill bitch!!! ')
                                invoicesRef.current[foundIndex].FreightBills.push(tempFreight);
                                makeFreightBillLineItem(tempFreight, invoicesRef.current[foundIndex].LineItems, invoicesRef.current[foundIndex]);
                                console.log('so right after this the invoicesRef linitesm length = ' ,[...invoicesRef.current])
                            }
                            if (change.type === "modified") {
                                for (var i = 0; i < invoicesRef.current[foundIndex].FreightBills.length; i++) {
                                    if (tempFreight.ID == invoicesRef.current[foundIndex].FreightBills[i].ID) {
                                        console.log('found this tempFreight = ', tempFreight)
                                        invoicesRef.current[foundIndex].FreightBills[i] = tempFreight;
                                        for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
                                            if (invoicesRef.current[foundIndex].LineItems[j].FreightID == tempFreight.ID && invoicesRef.current[foundIndex].LineItems[j].Type!=='Expense') {
                                                invoicesRef.current[foundIndex].LineItems.splice(j, 1);
                                                j--
                                            }
                                        }
                            
                                        makeFreightBillLineItem(tempFreight, invoicesRef.current[foundIndex].LineItems, invoicesRef.current[foundIndex]);
                                    
                                    }
                                }
                            }
                    });
                    console.log('so rightHMM OK HOLD UP THIS IS BEFORE THE CALCIN OF THE INVOICE nvoicesRef linitesm length = ' +invoicesRef.current[foundIndex].LineItems.length)
                    invoicesRef.current[foundIndex] = calcInvoiceTotal(invoicesRef.current[foundIndex]);
                    invoicesRef.current[foundIndex].Balance = invoicesRef.current[foundIndex].Total;
                    invoicesRef.current[foundIndex].Balance.Type = 'Amount Due';
                  
                    console.log('invoicesRef.current[foundIndex] ' , invoicesRef.current[foundIndex]);
                    console.log('invoiceRef.current ' , invoiceRef.current);
		
                    setInvoices([...invoicesRef.current]);
                    if(invoiceRef.current.ID===invoicesRef.current[foundIndex].ID || !invoicesRef.current[foundIndex].ID )setInvoice({...invoicesRef.current[foundIndex]})

					

                    
                }
            }));
            const expenseQueryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
            const expenseQuery = query(collection(db, expenseQueryName), where("dispatchID", "==", Dispatch.ID));
            invoiceExpenseListenersRef.current.push(onSnapshot(expenseQuery, (querySnapshot) => {
                console.log('tempInvoice.ID ==' +tempInvoice.ID)
                const foundIndex =  invoicesRef.current.findIndex(obj => obj.ID === tempInvoice.ID);
                if(foundIndex!==-1){
                    querySnapshot.docChanges().forEach((change) => {
                        var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                        var Expense = change.doc.data();
                        Expense.ID = change.doc.id;
                    
                        if (Expense.BillTo.ID === invoicesRef.current[foundIndex].Account.ID) {
                            if (change.type === "added") {
                                console.log(', Expense adding to invoice = ' , Expense);
                                if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill != '')) {
                                    invoicesRef.current[foundIndex].Expenses.push(Expense);
                                    invoicesRef.current[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
                                }
                            }                         
                            if (change.type === "removed") {
                                for (var i = 0; i < invoicesRef.current[foundIndex].Expenses.length; i++) {
                                    if (Expense.ID == invoicesRef.current[foundIndex].Expenses[i].ID) {
                                        invoicesRef.current[foundIndex].Expenses.splice(i, 1);
                                        for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
                                            if (invoicesRef.current[foundIndex].LineItems[j].ID == Expense.ID) {
                                                invoicesRef.current[foundIndex].LineItems.splice(j, 1);
                                                j--
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (change.type === "modified") {
                            var foundExpense =false;
                            console.log('found a modified epxense = ', Expense);
                            for (var i = 0; i < invoicesRef.current[foundIndex].Expenses.length; i++) {
                                if (Expense.ID === invoicesRef.current[foundIndex].Expenses[i].ID) {
                                    foundExpense=true;
                                    invoicesRef.current[foundIndex].Expenses[i] = Expense;
                                    for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
                                        if (invoicesRef.current[foundIndex].LineItems[j].ID === Expense.ID) {
                                            console.log('we found the current expense in teh line item and the expnes = ', Expense)
                                            if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill !== '') && Expense.BillTo.ID === invoicesRef.current[foundIndex].Account.ID)   invoicesRef.current[foundIndex].LineItems[j]=makeExpenseLineItem(Expense);
                                            else{ 
                                                invoicesRef.current[foundIndex].Expenses.splice(i,1);
                                                invoicesRef.current[foundIndex].LineItems.splice(j,1);
                                            }  
                                    
                                        }
                                    }
                            
                                }
                            }
                            if(!foundExpense){
                                if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill != '')) {
                                    console.log('pushing to invoice.exppenses = ' + invoicesRef.current[foundIndex].Expenses.length);
                                    invoicesRef.current[foundIndex].Expenses.push(Expense);
                                    invoicesRef.current[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
                                
                                }   
                            }
                        }
                    });
            
                
                    invoicesRef.current[foundIndex] =calcInvoiceTotal(invoicesRef.current[foundIndex]);
                    console.log('this is in the EXPENS PART = ' ,[...invoicesRef.current])
                    invoicesRef.current[foundIndex].Balance = invoicesRef.current[foundIndex].Total;
                    invoicesRef.current[foundIndex].Balance.Type = 'Amount Due';
                    setInvoices([...invoicesRef.current]);
                    if(invoiceRef.current.ID===invoicesRef.current[foundIndex].ID)setInvoice({...invoicesRef.current[foundIndex]})
                }
            }));
       
            
            console.log('setting invoiceRef.current = ',invoicesRef.current)
            setInvoiceVisible(true);
        


    }
	
    const previewInvoiceByExpense = (Dispatch)=>{
        var InvoiceNumber = 111111;
        if (!company.TermsAndCond)company.TermsAndCond = '';
 
		if (company.tempInvoiceNumber) {
            InvoiceNumber = company.tempInvoiceNumber;
            company.tempInvoiceNumber++;
        }


        console.log('dispatch = ', Dispatch);
  
        let dispatchAccount = getDispatchAccount(Dispatch);
        let dumpSite = getDispatchSite(Dispatch, 'DumpSite');
		let loadSite = getDispatchSite(Dispatch, 'LoadSite');
        let dispatchContact = getDispatchContact(Dispatch);
        if(!company.ShowInvoiceMaterial)company.ShowInvoiceMaterial=false;

		console.log('disaptchAccount = ', dispatchAccount)
        let tempInvoice = {
            ID: invoicesRef.current.length,
            Name: '',
            BillBy: 'DispatchID',
            VNum: 1,
            ShowMaterial:company.ShowInvoiceMaterial,
            JobID: Dispatch.Job.ID ? Dispatch.Job.ID : '',
            ParentID: Dispatch.ID,
            DispID: Dispatch.ID,
            QBCustomerID:dispatchAccount.QBCustomerID,
            ParentName: dispatchAccount.Name,
            ParentInvoice: '',
			TruckTypeName: Dispatch.TruckTypeName,
            Contact: dispatchContact,
            DispatchID: Dispatch.ID !== 'custom' ? Dispatch.ID : dispatchAccount.ID,
            ContactName: dispatchContact.Name,
            DispatchJobDate: Dispatch.JobDate,
            JobDate: Dispatch.JobDate,
            QueryDate:  formatDate(new Date(Dispatch.JobDate), '/', 'YYYY/MM/DD'),
			PONumber: Dispatch.PONumber || '',
			ContractNumber: Dispatch.ContractNumber || '',
			JobNumber: Dispatch.JobNumber || '',
            InvoiceNumber: InvoiceNumber,
            LoadSite: loadSite,
            DumpSite:dumpSite,
            Account: dispatchAccount,
            AccountID: dispatchAccount.ID,
            Paid: false,
            isFromInvoice: false,
            TaxPercent:0,
            Dispatch:{},
            Dispatches: [],
      
            InvoiceDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
            StartDate: Dispatch.JobDate,
            EndDate: Dispatch.JobDate,
            LineItems: [],
            Dates: [],
            Locations: [],
            realmID: dispatchAccount.realmID,
            showWeightTags: false,
            isMaterialInvoice:false,
            isExpenseInvoice: true,
			isFloatingExpenseInvoice: Dispatch.isFloatingExpenseDispatch ? true : false,
            QBInvoiceID: '',
            createdAt: getCurrentTime(),
            createdBy:gearedUser.Email,
            InvoiceNotes: dispatchAccount.InvoiceNotes,
            TermsAndCond: company.TermsAndCond,
            Versions: [{ VNum: 1, URL: '' }],
            PDFLogo:'',
            previewing:true,
            sortByDisp:true,
            Totals: [],
            Payments: [],
            Expenses: [],
            FreightBills:[]
        };
    
        
        if (!Dispatch.Company) tempInvoice.Company = company; else tempInvoice.Company = Dispatch.Company;
        

        tempInvoice.Dispatch={...Dispatch};
        invoicesRef.current.push({...tempInvoice})
		console.log('invoices ref as we pass it into the open expense listener ' +invoicesRef.current[0].LineItems.length)
        console.log('im pushign tempInvoice = ', tempInvoice)
        
		openInvoiceExpenseListener(tempInvoice, Dispatch, invoicesRef.current);
        console.log('setting invoiceRef.current = ' +invoicesRef.current[0].LineItems.length)
		setInvoiceVisible(true);
  

    }

    const previewInvoiceByMaterial = (Dispatch)=>{
        var InvoiceNumber = 111111;
        if (!company.TermsAndCond)company.TermsAndCond = '';
 
		if (company.tempInvoiceNumber) {
            InvoiceNumber = company.tempInvoiceNumber;
            company.tempInvoiceNumber++;
        }
		console.log('dispatch = ', Dispatch);
		var contactEmail = '';
		var contactName = 'No Contact';
		if (Dispatch.Contact) {
			if (Dispatch.Contact.Name !== '' && Dispatch.Contact.Name) contactName = Dispatch.Contact.Name;
			if (Dispatch.Contact.Email !== '' && Dispatch.Contact.Email) contactEmail = Dispatch.Contact.Email;
		}
		
		for (var q = 0; q < accounts.length; q++) {
			if (accounts[q].ID === Dispatch.Account.ID) {
				var tempAccount = {...accounts[q]};
				console.log('accounts[q] = ' , accounts[q])
				tempAccount.custAddress2 = tempAccount.City + ', ' + tempAccount.State + ' ' + tempAccount.ZipCode;
				var  realmID, accountTermsAndCond, qbCustomerID;
				if (!tempAccount.Quickbooks) tempAccount.Quickbooks = [];
				if (!tempAccount.InvoiceNotes) accountTermsAndCond = ''; else accountTermsAndCond = tempAccount.InvoiceNotes;
				if (!tempAccount.QBCustomerID) qbCustomerID = ''; else qbCustomerID = tempAccount.QBCustomerID;
				if (!Dispatch.MaterialCompany.realmID) {
					if (!company.realmID) realmID = ''; else realmID = company.realmID;
				} else{
					for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===Dispatch.Company.realmID)qbCustomerID = tempAccount.Quickbooks[p].QBCustomerID;
					realmID = Dispatch.MaterialCompany.realmID;
				} 

				var Account = {
					ID: tempAccount.ID,
					Name: tempAccount.Name,
					Address: tempAccount.Address,
					City: tempAccount.City,
					State: tempAccount.State,
					ZipCode: tempAccount.ZipCode,
					Quickbooks: tempAccount.Quickbooks,
					InvoiceNotes: tempAccount.InvoiceNotes,
					custAddress2: tempAccount.custAddress2
				};
			}
		}

		let dumpSite = {
			ID:Dispatch.DumpSite.ID,
			Name: Dispatch.DumpSite.Name ? Dispatch.DumpSite.Name :'',
			Address:Dispatch.DumpSite.Address ? Dispatch.DumpSite.Address  :'',
			Address2:Dispatch.DumpSite.Address2 ? Dispatch.DumpSite.Address2:'',
			City:Dispatch.DumpSite.City ? Dispatch.DumpSite.City:'',
			State:Dispatch.DumpSite.State ? Dispatch.DumpSite.State:'',
			ZipCode:Dispatch.DumpSite.ZipCode ?Dispatch.DumpSite.ZipCode   :'',
		
			fullAddress:Dispatch.DumpSite.fullAddress ?Dispatch.DumpSite.fullAddress :'',
		}
		let loadSite = {
			ID:Dispatch.LoadSite.ID,
			Name: Dispatch.LoadSite.Name ? Dispatch.LoadSite.Name :'',
			Address:Dispatch.LoadSite.Address ? Dispatch.LoadSite.Address  :'',
			Address2:Dispatch.LoadSite.Address2 ? Dispatch.LoadSite.Address2:'',
			City:Dispatch.LoadSite.City ? Dispatch.LoadSite.City:'',
			State:Dispatch.LoadSite.State ? Dispatch.LoadSite.State:'',
			ZipCode:Dispatch.LoadSite.ZipCode ? Dispatch.LoadSite.ZipCode   :'',
	
			fullAddress:Dispatch.LoadSite.fullAddress ?Dispatch.LoadSite.fullAddress :'',
		}
		if(!company.ShowInvoiceMaterial)company.ShowInvoiceMaterial=false;
		console.log('account = ', Account)
		let tempInvoice = {
			ID: invoicesRef.current.length,
			Name: '',
			BillBy: 'DispatchID',
			VNum: 1,
			Company: Dispatch.MaterialCompany ? Dispatch.MaterialCompany : company,
			ShowMaterial:company.ShowInvoiceMaterial,
			JobID: Dispatch.Job.ID,
			ParentID: Dispatch.ID,
			DispID: Dispatch.ID,
			ParentName: Dispatch.Account.Name,
			ParentInvoice: '',
			QBCustomerID:qbCustomerID,
			TruckTypeName: Dispatch.TruckTypeName,
			Contact: {
				Name: contactName,
				Email: contactEmail
			},
			DispatchID: Dispatch.ID,
			ContactName: contactName,
			DispatchJobDate: Dispatch.JobDate,
			JobDate: Dispatch.JobDate,
			QueryDate:  formatDate(new Date(Dispatch.JobDate), '/', 'YYYY/MM/DD'),
			PONumber: Dispatch.PONumber || '',
			ContractNumber: Dispatch.ContractNumber || '',
			JobNumber: Dispatch.JobNumber || '',
			InvoiceNumber: InvoiceNumber,
			LoadSite: loadSite,
			DumpSite:dumpSite,
			Account: Account,
			AccountID: Account.ID,
			Paid: false,
			TaxPercent:0,
			isFromInvoice: false,
			Dispatch:{},
			Dispatches: [],
		
			InvoiceDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			StartDate: Dispatch.JobDate,
			EndDate: Dispatch.JobDate,
			LineItems: [],
			Dates: [],
			Locations: [],
			realmID: realmID,
			showWeightTags: false,
			isMaterialInvoice:true,
			isExpenseInvoice: false,
			QBInvoiceID: '',
			createdAt: getCurrentTime(),
			createdBy:gearedUser.Email,
			InvoiceNotes: tempAccount.InvoiceNotes,
			TermsAndCond: company.TermsAndCond,
			Versions: [{ VNum: 1, URL: '' }],
			PDFLogo:'',
			previewing:true,
			sortByDisp:true,
			Totals: [],
			Payments: [],
			Expenses: [],
			FreightBills:[]
		};
	
           
		if (Dispatch.SellMaterial && Dispatch.MaterialCompany) tempInvoice.Company = Dispatch.MaterialCompany;
	


		tempInvoice.Dispatch={...Dispatch};

		console.log(' tempInvoice.ID= '+  tempInvoice.ID);
		invoicesRef.current.push({...tempInvoice})
		const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
		const freightQuery = query(collection(db, queryName), where("dispatchID", "==", Dispatch.ID));
		
		
		invoiceFreightListenersRef.current.push(onSnapshot(freightQuery, (querySnapshot) => {
			console.log('tempInvoice.ID ==' +tempInvoice.ID)
			const foundIndex =  invoicesRef.current.findIndex(obj => obj.ID === tempInvoice.ID);
			console.log('inside teh feright listener and the foundIndex = ' + foundIndex)
			if(foundIndex!==-1){
				querySnapshot.docChanges().forEach((change) => {
					var tempFreight = change.doc.data();
					tempFreight.ID = change.doc.id;
					
					if (change.type === "added") {
						console.log('found a Freight Bill bitch!!! ')
						invoicesRef.current[foundIndex].FreightBills.push(tempFreight);
						makeMaterialLineItem(tempFreight,  invoicesRef.current[foundIndex].LineItems,invoicesRef.current[foundIndex]);
						
						console.log('so right after this the invoicesRef linitesm length = ' ,[...invoicesRef.current])
					}
					if (change.type === "modified") {
						for (var i = 0; i < invoicesRef.current[foundIndex].FreightBills.length; i++) {
							if (tempFreight.ID == invoicesRef.current[foundIndex].FreightBills[i].ID) {
								console.log('found this tempFreight = ', tempFreight)
								invoicesRef.current[foundIndex].FreightBills[i] = tempFreight;
								for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
									if (invoicesRef.current[foundIndex].LineItems[j].FreightID == tempFreight.ID && invoicesRef.current[foundIndex].LineItems[j].Type!=='Expense') {
										invoicesRef.current[foundIndex].LineItems.splice(j, 1);
										j--
									}
								}
								makeMaterialLineItem(tempFreight, invoicesRef.current[foundIndex].LineItems, invoicesRef.current[foundIndex]);
							}
						}
					}
				});
				console.log('so rightHMM OK HOLD UP THIS IS BEFORE THE CALCIN OF THE INVOICE nvoicesRef linitesm length = ' +invoicesRef.current[foundIndex].LineItems.length)
				invoicesRef.current[foundIndex] = calcInvoiceTotal(invoicesRef.current[foundIndex]);
				invoicesRef.current[foundIndex].Balance = invoicesRef.current[foundIndex].Total;
				invoicesRef.current[foundIndex].Balance.Type = 'Amount Due';
	
				setInvoices([...invoicesRef.current]);
				if(invoiceRef.current.ID===invoicesRef.current[foundIndex].ID)setInvoice({...invoicesRef.current[foundIndex]})
			}
		}));
		const expenseQueryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
		const expenseQuery = query(collection(db, expenseQueryName), where("dispatchID", "==", Dispatch.ID));
		invoiceExpenseListenersRef.current.push(onSnapshot(expenseQuery, (querySnapshot) => {
			console.log('tempInvoice.ID ==' +tempInvoice.ID)
			const foundIndex =  invoicesRef.current.findIndex(obj => obj.ID === tempInvoice.ID);
			if(foundIndex!==-1){
				querySnapshot.docChanges().forEach((change) => {
					var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
					var Expense = change.doc.data();
					Expense.ID = change.doc.id;
				
					if (Expense.BillTo.ID === invoicesRef.current[foundIndex].Account.ID) {
						if (change.type === "added") {
							console.log(', Expense adding to invoice = ' , Expense);
							if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill != '') && Expense.Company.ID === invoicesRef.current[foundIndex].Company.ID) {
								invoicesRef.current[foundIndex].Expenses.push(Expense);
								invoicesRef.current[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
							}
						}                         
						if (change.type === "removed") {
							for (var i = 0; i < invoicesRef.current[foundIndex].Expenses.length; i++) {
								if (Expense.ID == invoicesRef.current[foundIndex].Expenses[i].ID) {
									invoicesRef.current[foundIndex].Expenses.splice(i, 1);
									for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
										if (invoicesRef.current[foundIndex].LineItems[j].ID == Expense.ID) {
											invoicesRef.current[foundIndex].LineItems.splice(j, 1);
											j--
										}
									}
								}
							}
						}
					}

					if (change.type === "modified") {
						var foundExpense =false;
						console.log('found a modified epxense = ', Expense);
						for (var i = 0; i < invoicesRef.current[foundIndex].Expenses.length; i++) {
							if (Expense.ID === invoicesRef.current[foundIndex].Expenses[i].ID) {
								foundExpense=true;
								invoicesRef.current[foundIndex].Expenses[i] = Expense;
								for (var j = 0; j < invoicesRef.current[foundIndex].LineItems.length; j++) {
									if (invoicesRef.current[foundIndex].LineItems[j].ID === Expense.ID) {
										console.log('we found the current expense in teh line item and the expnes = ', Expense)
										if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill !== ''  && Expense.Company.ID === invoicesRef.current[foundIndex].Company.ID) && Expense.BillTo.ID === invoicesRef.current[foundIndex].Account.ID)   invoicesRef.current[foundIndex].LineItems[j]=makeExpenseLineItem(Expense);
										else{ 
											invoicesRef.current[foundIndex].Expenses.splice(i,1);
											invoicesRef.current[foundIndex].LineItems.splice(j,1);
										}  
								
									}
								}
						
							}
						}
						if(!foundExpense){
							if (Expense.bill && !Expense.billed && (!Expense.addToFreights || Expense.FreightBill != '')) {
								console.log('pushing to invoice.exppenses = ' + invoicesRef.current[foundIndex].Expenses.length);
								invoicesRef.current[foundIndex].Expenses.push(Expense);
								invoicesRef.current[foundIndex].LineItems.push(makeExpenseLineItem(Expense ));
							
							}   
						}
					}
				});
		
			
				invoicesRef.current[foundIndex] =calcInvoiceTotal(invoicesRef.current[foundIndex]);
				console.log('this is in the EXPENS PART = ' ,[...invoicesRef.current])
				invoicesRef.current[foundIndex].Balance = invoicesRef.current[foundIndex].Total;
				invoicesRef.current[foundIndex].Balance.Type = 'Amount Due';
				setInvoices([...invoicesRef.current]);
				if(invoiceRef.current.ID===invoicesRef.current[foundIndex].ID)setInvoice({...invoicesRef.current[foundIndex]})
			}
		}));
		console.log('setting invoiceRef.current = ',invoicesRef.current)
		setInvoiceVisible(true);
        


    }

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.realJobDate, '/', 'MM/DD/YYYY');
    };

    const handleSelectedChange = (disp, value)=>{
        let tempDispatches =[...sortedDispatches]
        for(let i=0; i<tempDispatches.length; i++)if(tempDispatches[i].index===disp.index) tempDispatches[i].Selected=value;

       
        setTableDispatches([...tempDispatches])
    }
    const booleanBodyTemplate = (rowData, field) => {
        return (
            <Checkbox  onChange={(e) => handleSelectedChange(rowData, e.checked)}  checked={rowData[field]}  />
        );
    };
    const changeDispatchTab = (index) =>{
        if(index===0)setTableDispatches([...dispatchesRef.current])
        if(index===1)setTableDispatches([...expenseDispatchesRef.current])
        if(index===2)setTableDispatches([...materialDispatchesRef.current])
        setActiveTab(index);
    }
    const truckingInvoicesHeader= (options) =>{
        const countNotAbsent =0;
        return tabHeaderTemplate('Trucking', countNotAbsent, options, 'faTruck')
    }
    const expenseInvoicesHeader= (options) =>{
        const countNotAbsent =0;
        return tabHeaderTemplate('Expenses', countNotAbsent, options, 'faUser')
    }
    const materialInvoicesHeader= (options) =>{
        const countNotAbsent =0;
        return tabHeaderTemplate('Material', countNotAbsent, options, 'faLayerGroup')
    }
    const tabHeaderTemplate = ( text, value, options, iconName) => {
        return (
            <div style={{ cursor: 'pointer', height: "100%", backgroundColor: "#f7f7f7", border: "1px solid #acacac" }} onClick={options.onClick}>
                <a className="p-tabview-nav-link" style={{ paddingTop:".2em",paddingBottom:".2em", paddingLeft:"0", justifyContent:'center', paddingRight:"0", display: "flex", flexDirection: "column", alignItems: "center", fontSize: "clamp(.7rem, 0.8vw + 0.3rem, 1rem)", width: "100%", height: "100%" }}>
                    <div className='mbsc-row' style={{ margin:"0", width: "100%",display: "flex", justifyContent: "center" }}>
                        <FontAwesomeIcon className="fas" icon={iconMapping[iconName]} style={{ color: "#878787", paddingRight:"0", height: "1.5em", width: "1.5em" }} />
                        {value > 0 && (<Badge style={{ display:'flex', flexDirection:'column',  marginLeft:"-.3em", width:'1.5em',height:'1.7em',  paddingRight:"0", paddingLeft:"0", backgroundColor: "red" }}  value={value} />)}
                    </div>
                    <div className='mbsc-row' style={{margin:"0", width: "100%", display: "flex", justifyContent:'center', }}>
                        {text} 
                    </div>
                </a>
            </div>
        );
    };

      const renderHeader = () => (
        <div className="mbsc-grid mbsc-row">

            <div className="mbsc-col-xl-3 mbsc-col-6">
                <AutoCompleteInput fieldName="Company" field="CompanyName" value={inputValues.Company} suggestions={companies}  setValue={setInputValues}  handleFieldChange={handleFieldChange}   />
            </div>
            <div className="mbsc-col-xl-2 mbsc-col-6">
                <Button  color="primary"  onClick={(event) =>previewInvoices(event)} style={{margin:"0", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Preview Invoices</Button>
            </div>    
        
			<div className="mbsc-col-xl-2 mbsc-col-6">
                <Button  color="primary"  onClick={(event) =>createCustomInvoice(event)} style={{margin:"0", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Create Custom Invoice</Button>
            </div>   
        </div>
    );

    const header = renderHeader();
    return (
        <div className="card">
            <TabView  style={{margin:"0"}} activeIndex={activeTab} onTabChange={(e) => changeDispatchTab(e.index)}  > 
                <TabPanel headerTemplate={truckingInvoicesHeader} style={{marginTop:"0"}} headerStyle={{width:"33%"}}  >   </TabPanel>
                <TabPanel headerTemplate={expenseInvoicesHeader} style={{marginTop:"0"}} headerStyle={{width:"33%"}}  >   </TabPanel>
                <TabPanel headerTemplate={materialInvoicesHeader} style={{marginTop:"0"}} headerStyle={{width:"33%"}}  >   </TabPanel>
            </TabView>
                <DataTable value={sortedDispatches} paginator rows={25} dataKey="index" filters={filters} header={header} filterDisplay="row" emptyMessage="No dispatches found.">
                    <Column field="Selected" header="Selected" dataType="boolean" style={{ minWidth: '6rem' }} body={(rowData) => booleanBodyTemplate(rowData, 'Selected')}  />
                    <Column field="realJobDate" header="Job Date" dataType="date" sortable body={dateBodyTemplate}   filterPlaceholder="Search by Date" />
                    <Column header="Account" filter filterField="Account.Name" filterPlaceholder="Search by Account"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>
                
                    <Column field="JobNumber" header="Job Number" filter filterPlaceholder="Search by job number" />
                    <Column header="Load Site" filter filterField="LoadSite.Name" filterPlaceholder="Search by Load Site"  body={(rowData) => rowData.LoadSite?.Name || 'N/A'}/>
                    <Column header="Dump Site" filter filterField="DumpSite.Name" filterPlaceholder="Search by Dump Site"  body={(rowData) => rowData.DumpSite?.Name || 'N/A'}/>

                
                    <Column field="unBilledItems" header="Unbilled Items" filter filterPlaceholder="Search by unbilled"  />
                </DataTable>
				<LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
    };
    
    export default InvoiceCreate;