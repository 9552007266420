import React, { useState, useEffect } from 'react';
import InputTextParent from '../InputComponents/InputTextParent'; 
import { Checkbox } from 'primereact/checkbox';
import { Textarea} from '@mobiscroll/react';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';

import { UserAuth } from '../../context/AuthContext'; 
import { usePrevailingWage } from './context/PrevailingWageContext';
import { usePrevailingWageReport } from './context/PrevailingWageReportContext';

import DriverListItem from './DriverListItem';
import OutsideDriverListItem from './OutsideDriverListItem';
import AutoSelectInputNumber from '../InputComponents/AutoSelectInputNumber'; 
import AutoCompleteInput from 'components/InputComponents/AutoCompleteInput';
import SignaturePopUp from '../PopUpComponents/SignaturePopUp';

import {useParams } from 'react-router-dom';


import { usePrevailingWagePDFs } from './hooks/usePrevailingWagePDFs';
import { useFindWeekDates } from './hooks/useFindWeekDates';
import { useXML } from './hooks/useXML';


const PrevailingWageReport = () => {
    const {  freightBills, prevailingWageReport, setPrevailingWageReport }= usePrevailingWage();
    const { formatDate, accounts } = UserAuth();
    const { reportWeeks, reportWeek, setReportWeek,  queryWeekFreightBills,  updateReportWeekDocs,fetchPrevailingWage, weekFreightBillsRef } = usePrevailingWageReport();
	const { createXML } = useXML();
    const { createPrevailingWagePDFs, createNonPerformancePDF } = usePrevailingWagePDFs();
    const { findFirstSunday } = useFindWeekDates();

    const { id, weekDate} = useParams();
    const [signatureVisible, setSignatureVisible] = useState(false);
        
    
    
    useEffect(()=>{
        if(prevailingWageReport && freightBills?.length>0)
        findFirstSunday(prevailingWageReport?.prevailStartDateValue,prevailingWageReport?.prevailEndDateValue, freightBills, id)
    },[prevailingWageReport?.prevailStartDateValue,prevailingWageReport?.prevailEndDateValue, freightBills])

    useEffect(()=>{
        startReportPage();
    },[])

    useEffect(()=>{ 
        if(reportWeek?.weekStartDate && reportWeek?.weekEndDate && !weekFreightBillsRef.current ) queryWeekFreightBills(reportWeek, prevailingWageReport);
    },[reportWeek])

    useEffect(()=>{
    
        for(let r=0; r<reportWeeks.length; r++){
            if(reportWeeks[r].weekStartDate===weekDate){
                if(!reportWeeks[r].Drivers && prevailingWageReport.Drivers)reportWeeks[r].Drivers = [...prevailingWageReport.Drivers];
                if(!reportWeeks[r].OwnerOps && prevailingWageReport.OwnerOps)reportWeeks[r].OwnerOps = [...prevailingWageReport.OwnerOps]
				console.log('weekDate = ', weekDate)
                setReportWeek({...reportWeeks[r]});
            }
        }
     
    },[reportWeeks])

    const startReportPage = ()=>{
        if(!prevailingWageReport)    fetchPrevailingWage(id);
    }

    const handleUpdateDriver =  (index, driverArrayName, updatedDriver) => {
      
       let tempReportWeek={...reportWeek};
       tempReportWeek[driverArrayName][index]={...updatedDriver}
       setReportWeek(tempReportWeek);
    };

    const handleInputChange = (field, value) => { 
        console.log('value= ', value)
        console.log('field= ', field)
        setPrevailingWageReport({ ...prevailingWageReport, [field]: value});
        
    };

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
     
        setPrevailingWageReport((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const handleCheckboxChange = (field, value) => {
		console.log('field for checkbox= ', field)
		console.log('value for checkbox= ', value)
        setPrevailingWageReport({ ... prevailingWageReport, [field]: value });
    };

    const handleDeleteDoc = (rowData, index) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            let tempReportWeek = { ...reportWeek };
            tempReportWeek.Docs.splice(index, 1);
            setReportWeek(tempReportWeek);
            updateReportWeekDocs(tempReportWeek);
        }
    };

    const deleteBodyTemplate = (rowData, options) => {
        return (
            <button 
                type="button" 
                onClick={() => handleDeleteDoc(rowData, options.rowIndex)}
                className="p-button p-button-danger p-button-text"
                style={{ padding: '0' }}
            >
                <i className="pi pi-trash" style={{ color: 'red' }}></i>
            </button>
        );
    };

    const handleSaveSignature = (signature) => {
        createPrevailingWagePDFs(signature, reportWeek, prevailingWageReport);
    };

    return (
        <React.Fragment>
            {prevailingWageReport && reportWeek &&(<>
				<div className="mbsc-grid mbsc-row">
					<div className="mbsc-col-lg-6">
						<Panel header=' Details'>
						
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Name</span>
								<InputTextParent value={prevailingWageReport.Name} onChange={(e) => handleInputChange( 'Name',e.target.value)} />
							</div>
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Title</span>
								<InputTextParent value={prevailingWageReport.Title} onChange={(e) => handleInputChange( 'Title',e.target.value)} />
							</div>
							<div className="p-inputgroup ">
									<span className="p-inputgroup-addon dispatch-small-inputgroup">Project Name</span>
									<InputTextParent value={prevailingWageReport.ProjectName} onChange={(e) => handleInputChange( 'ProjectName',e.target.value)} />
								</div>
								<div className="p-inputgroup ">
									<span className="p-inputgroup-addon dispatch-small-inputgroup">Project and Location</span>
									<InputTextParent value={prevailingWageReport.ProjectLocation} onChange={(e) => handleInputChange( 'ProjectLocation',e.target.value)} />
								</div>
							<div className="p-inputgroup">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Signature Date:</span>
								<Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={prevailingWageReport.SignatureDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'SignatureDate',e.value )} />
							</div>
							{!reportWeek.nonPerformance &&  (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  > Rate</span> 
								<AutoSelectInputNumber   value={prevailingWageReport.PrevailingWageRate} isCurrency={true}     onChange={(e) => handleInputChange( 'PrevailingWageRate',e.value)} />
							</div>)}
							{!reportWeek.nonPerformance &&  (<div className='mbsc-row' style={{margin:'0'}} >
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
									<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Overtime Rate</span> 
									<AutoSelectInputNumber   value={prevailingWageReport.OvertimePrevailingWageRate} isCurrency={true}     onChange={(e) => handleInputChange( 'OvertimePrevailingWageRate',e.value)} />
								</div>
								<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
									<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Owner Op Rate</span> 
									<AutoSelectInputNumber   value={prevailingWageReport.OwnerOpPrevailingWageRate} isCurrency={true}   onChange={(e) => handleInputChange( 'OwnerOpPrevailingWageRate',e.value)} />
								</div>
							
							</div>)}
						
							{!reportWeek.nonPerformance && (<div className="mbsc-row">
								<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
									<span className="p-inputgroup-addon " style={{flexBasis:'80%'}}>Create EmployeeDocs</span>
									<Checkbox checked={prevailingWageReport.createEmployeeDocs} onChange={(e) => handleCheckboxChange('createEmployeeDocs',e.checked)}  />
								</div>
								<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
									<span className="p-inputgroup-addon  " style={{flexBasis:'80%'}}>Create Owner Op Docs</span>
									<Checkbox checked={prevailingWageReport.createOwnerOpDocs} onChange={(e) => handleCheckboxChange('createOwnerOpDocs',e.checked)}  />
								</div>
							</div>)}
						</Panel>
						{!reportWeek.nonPerformance && (<>
						{prevailingWageReport.createOwnerOpDocs &&( <Panel header='Owner Operator Details'>
							
								<div className="p-inputgroup ">
									<span className="p-inputgroup-addon dispatch-small-inputgroup">Payroll Number</span>
										
										<InputTextParent value={prevailingWageReport.OwnerOpPayrollNumber} onChange={(e) => handleInputChange(e.target.value, 'OwnerOpPayrollNumber')} />
									</div>
									<div className="p-inputgroup ">
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Owner Op 4(a)</span>
								
										<Checkbox checked={prevailingWageReport.OwnerOp4a} onChange={(e) => handleCheckboxChange( 'OwnerOp4a', e.checked)} />
									</div>
									<div className="p-inputgroup ">
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Owner Op 4(b)</span>
								
										<Checkbox checked={prevailingWageReport.OwnerOp4b} onChange={(e) => handleCheckboxChange( 'OwnerOp4b', e.checked)} />
									</div>
									<div className="p-inputgroup " style={{height:'4em'}}>
										<span className="p-inputgroup-addon text-area-inputgroup">Remarks</span>
								
										<Textarea rows={2} value={prevailingWageReport.OwnerOpRemarks} onChange={(e) => handleInputChange(e.target.value, 'OwnerOpRemarks')} />
									</div>
								
							</Panel>)}
							
						</>)}
					</div>
                    {reportWeek.nonPerformance ? (
                        <Panel header='Details' className='mbsc-col-lg-6'>
							  <AutoCompleteInput labelClass="p-inputgroup-addon dispatch-small-inputgroup" fieldName="Contractor" field="Name" value={prevailingWageReport.Contractor} suggestions={accounts} setValue={setPrevailingWageReport}  handleFieldChange={handleInputChange}  databaseList={'Accounts'} />
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Project and Location</span>
                                <InputTextParent value={prevailingWageReport.ProjectLocation} onChange={(e) => handleInputChange( 'ProjectLocation',e.target.value)} />
                            </div>
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Contract Number</span>
                                <InputTextParent value={prevailingWageReport.ContractNumber} onChange={(e) => handleInputChange( 'ContractNumber',e.target.value)} />
                            </div>
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Project Number</span>
                                <InputTextParent value={prevailingWageReport.ProjectNumber} onChange={(e) => handleInputChange( 'ProjectNumber',e.target.value)} />
                            </div>
							<div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Job Number</span>
                                <InputTextParent value={prevailingWageReport.JobNumber} onChange={(e) => handleInputChange( 'JobNumber',e.target.value)} />
                            </div>
                        </Panel>):(
						<div className='mbsc-col-lg-6'>
                        	{prevailingWageReport.createEmployeeDocs &&(  <Panel header='Driver Details' >
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Contract Number</span>
										<InputTextParent value={prevailingWageReport.ContractNumber} onChange={(e) => handleInputChange( 'ContractNumber',e.target.value)} />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Payroll Number</span>
										<AutoSelectInputNumber  isCurrency={false}  placeholder="Enter a number" value={prevailingWageReport.DriverPayrollNumber}    onChange={(e) => handleInputChange( 'DriverPayrollNumber',e.value)}  />

									</div>
								</div>
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Contractor Payroll</span>
										<Checkbox  checked={prevailingWageReport.ContractorPayroll}     onChange={(e) => handleCheckboxChange('ContractorPayroll',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Subcontractor Payroll</span>
										<Checkbox checked={prevailingWageReport.SubcontractorPayroll} onChange={(e) => handleCheckboxChange('SubcontractorPayroll',e.checked)}  />
									</div>
								</div>
							
							
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 2(a)</span>
										<Checkbox  checked={prevailingWageReport.Driver2a}     onChange={(e) => handleCheckboxChange( 'Driver2a',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 2(b)</span>
										<Checkbox  checked={prevailingWageReport.Driver2b}     onChange={(e) => handleCheckboxChange('Driver2b',e.checked)}  />
									</div>
								</div>
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 4(a)</span>
										<Checkbox  checked={prevailingWageReport.Driver4a}     onChange={(e) => handleCheckboxChange('Driver4a',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 4(b)</span>
										<Checkbox  checked={prevailingWageReport.Driver4b}     onChange={(e) => handleCheckboxChange('Driver4b',e.checked)}  />
									</div>
								</div>
							
								<div className="p-inputgroup " style={{height:'4em'}}>
									<span className="p-inputgroup-addon text-area-inputgroup">Remarks</span>
							
									<Textarea rows={2} value={prevailingWageReport.DriverRemarks} onChange={(e) => handleInputChange('DriverRemarks',e.target.value)} />
								</div>
                        
                        
                        	</Panel>)}
					
                        </div>
                        )}
				</div>
				{reportWeek && prevailingWageReport.createOwnerOpDocs && ( <Panel header='Owner Op Deductions' >
                    
					<table style={{ marginBottom: "5px", width: "100%" }}>
						<thead>
							<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
								<th style={{ width: "20%" }}>Name</th>
								<th style={{ width: "15%"}}>Equip/License #</th>
								<th style={{ width: "15%"}}>Check #</th>
								<th style={{ width: "15%"}}>Exception(Craft)</th>
								<th style={{ width: "15%"}}>Gross Pay</th>
								<th style={{ width: "20%"}}>Explanation</th>
							</tr>
						</thead>
						<tbody>
							{reportWeek.OwnerOps?.map((outsidedriver,index) => (
								<OutsideDriverListItem   key={index} index={index} outsidedriver={outsidedriver} onUpdateDriver={(updatedDriver) => handleUpdateDriver(index,'OwnerOps', updatedDriver)}   />
							))}
						</tbody>
					</table>
				</Panel>)}
                        {!reportWeek.nonPerformance && prevailingWageReport.createEmployeeDocs && (
                            <Panel header='Driver Deductions' className='mbsc-col-12'>
                                <table style={{ marginBottom: "5px", width: "100%" }}>
                                    <thead>
                                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                            <th style={{ width: "10%" }}>Name</th>
                                            <th style={{ width: "7%" }}>Fed Tax</th>
                                            <th style={{ width: "7%" }}>FICA</th>
                                            <th style={{ width: "7%" }}>SDI</th>
                                            <th style={{ width: "7%" }}>Local Tax</th>
                                            <th style={{ width: "7%"}}>Other Tax</th>
                                            <th style={{ width: "7%"}}>Other Ded.</th>
                                            <th style={{ width: "7%"}}>Total Ded.</th>
                                            <th style={{ width: "7%"}}>This Project</th>
                                            <th style={{ width: "7%"}}>All Projects</th>
                                            <th style={{ width: "7%"}}>Net Wages</th>
                                            <th style={{ width: "8%"}}>Check #</th>
                                            <th style={{ width: "10%"}}>Ded. Notes</th>
                                    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportWeek.Drivers?.map((driver, index) => (
                                    
                                            <DriverListItem   key={index} index={index} driver={driver} onUpdateDriver={(updatedDriver) => handleUpdateDriver(index,'Drivers', updatedDriver)}   />
                                        ))}
                                    </tbody>
                                </table>
                            </Panel>)}
                  
                        <div className="mbsc-col-12" style={{paddingBottom:'5em'}}>
                        {!reportWeek.nonPerformance ? ( <Button label='Create Reports' onClick={() => setSignatureVisible(true)} />
                        ) : (<Button label='Create Non Performance' onClick={() => createNonPerformancePDF(reportWeek, prevailingWageReport)} />   )}
                        <Button label='Create XML ' onClick={() => createXML(reportWeek, prevailingWageReport)} />  
                        <SignaturePopUp 
                            visible={signatureVisible}
                            setVisible={setSignatureVisible}
                            handleSaveSignature={handleSaveSignature}
                        />
                        
                        <DataTable value={reportWeek.Docs} responsiveLayout="scroll">
                            <Column 
                                header="Delete" 
                                body={deleteBodyTemplate} 
                                style={{ width: '4rem', textAlign: 'center' }}
                            />
                            {!reportWeek.nonPerformance  &&( <Column header="Driver Report" body={(rowData) => ( <Button label={`Driver - ${rowData.VNum}`} onClick={() => window.open(rowData.driverURL)} /> )} />)}
                            {!reportWeek.nonPerformance  &&( <Column header="Compliance Report" body={(rowData) => ( <Button label={`Comp - ${rowData.VNum}`} onClick={() => window.open(rowData.compURL)} /> )} />)}
                            {reportWeek.nonPerformance  && (<Column header="Non Performance Report" body={(rowData) => ( <Button label={`Non Performance - ${rowData.VNum}`} onClick={() => window.open(rowData.nonPerformanceURL)} /> )} />)}
                        </DataTable>
                    </div>
			</>)}
        </React.Fragment>
    );
};

export default PrevailingWageReport;
