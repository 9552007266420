import React, {useState,useContext,useRef, createContext, useEffect} from 'react'

import { db } from '../../../firebase';
import {   query, collection,  where,getDocs} from 'firebase/firestore';

import { UserAuth } from '../../../context/AuthContext';



const PayStatementContext = createContext();
export const PayStatementContextProvider = ({ children }) => {
    const {gearedUser, drivers, accounts, outsideDrivers, formatDate, addDocument, updateDocument,company }= UserAuth();
    const [payStatements, setPayStatements]= useState(null);
    const [payStatement, setPayStatement]= useState(null)
    const [createDrivers, setCreateDrivers] = useState([]);
    const [createOutsideDrivers, setCreateOutsideDrivers] = useState([]);
    const [createAccounts, setCreateAccounts] = useState([]);
    const [payStatementVisible, setPayStatementVisible]= useState(false);
    const [isLoading, setIsLoading ]=useState(false);

    var todaysDate = new Date();
    let startDate =new Date();
    let formattedTodaysDate= formatDate(todaysDate, '/', 'YYYY/MM/DD');
    startDate.setDate(todaysDate.getDate() - 14);
   

    const [inputValues, setInputValues]=useState({
        DPSDateValue: todaysDate,
        EndDateValue:todaysDate,
        StartDateValue:startDate,
        DPSDate: formatDate(todaysDate, '/', 'MM/DD/YYYY'),
        EndDate:formattedTodaysDate,

        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD')
        
    });

    const createCompanyRef =useRef(null)
    const payStatementRef = useRef(null);
    const payStatementsRef = useRef([]);

    const freightBillsRef = useRef([]);
    const expensesRef = useRef([]);
    const driversRef = useRef(null);
    const outsideDriversRef = useRef(null);
    const accountsRef = useRef(null);
  
    console.log('pay statement context reload')

    useEffect(() => {
        if (drivers.length) {
            console.log('DRIVERS LOADED!!')
            if (driversRef.current) {
                for (let k = 0; k < driversRef.current.length; k++) {
                    let tempDriverFreights = driversRef.current[k].FreightBills ? [...driversRef.current[k].FreightBills] : [];
                    let tempDriverExpenses = driversRef.current[k].Expenses ? [...driversRef.current[k].Expenses] : [];
                    let tempDPS = driversRef.current[k].DPS  ? {...driversRef.current[k].DPS} : {};
                    let tempTotal = driversRef.current[k].Total ? driversRef.current[k].Total : 0; 
                    let tempSelected = driversRef.current[k].Selected ? driversRef.current[k].Selected : false;
                    for (let q = 0; q < drivers.length; q++) {
                        if (driversRef.current[k].ID === drivers[q].ID) {
                            driversRef.current[k] = { ...drivers[q] };
                            driversRef.current[k].FreightBills = [...tempDriverFreights];
                            driversRef.current[k].Expenses = [...tempDriverExpenses];
                            driversRef.current[k].DPS = {...tempDPS};
                            driversRef.current[k].Total = tempTotal;
                            driversRef.current[k].Selected = tempSelected;
                        }
                    }
                }
                setCreateDrivers([...driversRef.current])
            } else {
                console.log(' we are currently loading a null drivers ref array');
                driversRef.current = drivers.filter(driver => driver.Status === 'Active').map((driv) => ({ ...driv,   Selected:true}))
                filterFreightBills();  // Assuming this function depends on inputValues.Company
                filterExpenses();
            }
        }
      
    }, [drivers]);

    useEffect(() => {
        if (outsideDrivers.length) {
          
            if (outsideDriversRef.current) {
           
                for (let k = 0; k < outsideDriversRef.current.length; k++) {
                    let tempDriverFreights = outsideDriversRef.current[k].FreightBills ? [...outsideDriversRef.current[k].FreightBills] : [];
                    let tempDriverExpenses = outsideDriversRef.current[k].Expenses ? [...outsideDriversRef.current[k].Expenses] : [];
                    let tempDPS =  outsideDriversRef.current[k].DPS  ? {... outsideDriversRef.current[k].DPS} : {};
                    let tempTotal =  outsideDriversRef.current[k].Total ?  outsideDriversRef.current[k].Total : 0; 
                    let tempSelected =  outsideDriversRef.current[k].Selected ?  outsideDriversRef.current[k].Selected : false;
                    for (let q = 0; q < outsideDrivers.length; q++) {
                        if (outsideDriversRef.current[k].ID === outsideDrivers[q].ID) {
                            outsideDriversRef.current[k] = { ...outsideDrivers[q] };
                            outsideDriversRef.current[k].FreightBills = [...tempDriverFreights];
                            outsideDriversRef.current[k].FreightBills = [...tempDriverExpenses];
                            outsideDriversRef.current[k].DPS = {...tempDPS};
                            outsideDriversRef.current[k].Total = tempTotal;
                            outsideDriversRef.current[k].Selected = tempSelected;
                        }
                    }
                }
                setCreateOutsideDrivers([...outsideDriversRef.current])
            } else {
                outsideDriversRef.current = outsideDrivers.filter(acc => acc.Status === 'Active').map((driv) => ({ ...driv,   Selected:true}));
                filterFreightBills();  // Assuming this function depends on inputValues.Company
                filterExpenses();
            }
        }
    }, [outsideDrivers]);

    useEffect(() => {
        if (accounts.length) {
            console.log('ACCOUNTS LOADED!!')
            if (accountsRef.current) {
           
                for (let k = 0; k < accountsRef.current.length; k++) {
                    let tempDriverExpenses = accountsRef.current[k].Expenses ? [...accountsRef.current[k].Expenses] : [];
                    for (let q = 0; q < accounts.length; q++) {
                        if (accountsRef.current[k].ID === accounts[q].ID) {
                            accountsRef.current[k] = { ...accounts[q] };
                            accountsRef.current[k].FreightBills = [...tempDriverExpenses];
                        }
                    }
                }
                setCreateAccounts([...accountsRef.current])
            } else {
                accountsRef.current = accounts.filter(acc => acc.Status === 'Active').map((driv) => ({ ...driv,   Selected:true}));
                filterExpenses();
            }
        }
    }, [accounts]);
    useEffect(() => {
        if (inputValues.Company){
            createCompanyRef.current={...inputValues.Company}
            filterFreightBills();  
            filterExpenses();
        }  
    }, [inputValues.Company]);

  

    const filterDriverFreightBills = (driver)=>{

        driver.FreightBills=[];

        for(let j=0; j<freightBillsRef.current.length; j++){
            if(freightBillsRef.current[j].Driver===driver.ID && freightBillsRef.current[j].Company.ID===createCompanyRef.current.ID ){
                let foundFreight=false;
             
                for(let q=0; q<driver.FreightBills.length; q++){
                    if(driver.FreightBills[q].ID===freightBillsRef.current[j].ID){
                        foundFreight=true;
                        driver.FreightBills[q]={...freightBillsRef.current[j]}
                    }
                }
                if(!foundFreight)driver.FreightBills.push({...freightBillsRef.current[j]})
            }
        }
        driver.FBCount = driver.FreightBills.length+(driver.Expenses?.length ? driver.Expenses.length : 0);
      
        for(let q=0; q<driver.FreightBills.length; q++){
            if( !driver.earliestFBDate)driver.earliestFBDate=driver.FreightBills[q].QueryDate;
                if( driver.earliestFBDate>driver.FreightBills[q].QueryDate) driver.earliestFBDate=driver.FreightBills[q].QueryDate;
        }
        if(driver.FreightBills.length!==0) driver.realEarliestFBDate= new Date( driver.earliestFBDate);
        
    }
    const filterOutsideFreightBills = (outsidedriver)=>{
    
        outsidedriver.FreightBills=[];
         
        for(let j=0; j<freightBillsRef.current.length; j++){
            if(freightBillsRef.current[j].Driver===outsidedriver.ID && freightBillsRef.current[j].Company.ID===createCompanyRef.current.ID ){
                let foundFreight=false;
                for(let q=0; q<outsidedriver.FreightBills.length; q++){
                    if(outsidedriver.FreightBills[q].ID===freightBillsRef.current[j].ID){
                        foundFreight=true;
                        outsidedriver.FreightBills[q]={...freightBillsRef.current[j]}
                    }
                }
                if(!foundFreight)outsidedriver.FreightBills.push({...freightBillsRef.current[j]})
            }
        }
   
        outsidedriver.FBCount = outsidedriver.FreightBills.length+ (outsidedriver.Expenses?.length ? outsidedriver.Expenses.length : 0);
        for(let q=0; q<outsidedriver.FreightBills.length; q++){
            if( !outsidedriver.earliestFBDate)outsidedriver.earliestFBDate=outsidedriver.FreightBills[q].QueryDate;
                if( outsidedriver.earliestFBDate>outsidedriver.FreightBills[q].QueryDate) outsidedriver.earliestFBDate=outsidedriver.FreightBills[q].QueryDate;
        }

        if(outsidedriver.FreightBills.length!==0) outsidedriver.realEarliestFBDate= new Date( outsidedriver.earliestFBDate);
        
    }
    const filterFreightBills = () =>{
      
        if(driversRef.current && createCompanyRef.current){
            for(let i=0; i<driversRef.current.length; i++) filterDriverFreightBills(driversRef.current[i])
            setCreateDrivers([...driversRef.current.filter(driv => driv.FreightBills?.length>0 || driv.Expenses?.length>0)])
        }
        if(outsideDriversRef.current && createCompanyRef.current ){
            for(let i=0; i<outsideDriversRef.current.length; i++)filterOutsideFreightBills(outsideDriversRef.current[i]);
            setCreateOutsideDrivers([...outsideDriversRef.current.filter(outdriv => outdriv.FreightBills?.length>0  || outdriv.Expenses?.length>0)])
        }
    }
    const filterDriverExpenses = (driver)=>{
        
            driver.Expenses=[];
            for(let j=0; j<expensesRef.current.length; j++){
                if(expensesRef.current[j].pay && expensesRef.current[j].PayTo.ID===driver.ID && expensesRef.current[j].Company.ID===createCompanyRef.current.ID ){
                    let foundFreight=false;
                    for(let q=0; q<driver.Expenses.length; q++){
                        if(driver.Expenses[q].ID===expensesRef.current[j].ID){
                            foundFreight=true;
                            driver.Expenses[q]={...expensesRef.current[j]}
                        }
                    }
                    if(!foundFreight)driver.Expenses.push({...expensesRef.current[j]})  
                }
            }
            driver.FBCount = driver.FreightBills.length+(driver.Expenses?.length ? driver.Expenses.length : 0);
           
            for(let q=0; q<driver.Expenses.length; q++){
                if( !driver.earliestFBDate)driver.earliestFBDate=driver.Expenses[q].QueryDate;
                    if( driver.earliestFBDate>driver.Expenses[q].QueryDate) driver.earliestFBDate=driver.Expenses[q].QueryDate;
            }
            driver.realEarliestFBDate= new Date( driver.earliestFBDate);
        
    }
    const filterOutsideExpenses = (outsidedriver)=>{

        outsidedriver.Expenses=[];    
        for(let j=0; j<expensesRef.current.length; j++){  
            if(expensesRef.current[j].pay && expensesRef.current[j].PayTo.ID===outsidedriver.AccountID && expensesRef.current[j].Company.ID===createCompanyRef.current.ID ){
                let foundFreight=false;
                console.log('found ane xpesne for an outside driver = ',expensesRef.current[j] )
                for(let q=0; q<outsidedriver.Expenses.length; q++){
                    if(outsidedriver.Expenses[q].ID===expensesRef.current[j].ID){
                        foundFreight=true;
                        outsidedriver.Expenses[q]={...expensesRef.current[j]}
                    }
                }
            
                if(!foundFreight)outsidedriver.Expenses.push({...expensesRef.current[j]})

            }
        }
        outsidedriver.FBCount = outsidedriver.FreightBills.length+ (outsidedriver.Expenses?.length ? outsidedriver.Expenses.length : 0);
        for(let q=0; q<outsidedriver.Expenses.length; q++){
            if( !outsidedriver.earliestFBDate)outsidedriver.earliestFBDate=outsidedriver.Expenses[q].QueryDate;
                if( outsidedriver.earliestFBDate>outsidedriver.Expenses[q].QueryDate) outsidedriver.earliestFBDate=outsidedriver.Expenses[q].QueryDate;
        }
        outsidedriver.realEarliestFBDate= new Date( outsidedriver.earliestFBDate);
    }
    
    const filterNonDriverExpenses = (account)=>{
    
        account.Expenses=[];
        account.FreightBills=[];
    
        for(let j=0; j<expensesRef.current.length; j++){
            if(expensesRef.current[j].pay && expensesRef.current[j].PayTo.ID===account.ID && expensesRef.current[j].floatingPayExpense && expensesRef.current[j].Company.ID===createCompanyRef.current.ID ){
                let foundFreight=false;
                for(let q=0; q<account.Expenses.length; q++){
                    if(account.Expenses[q].ID===expensesRef.current[j].ID){
                        foundFreight=true;
                        account.Expenses[q]={...expensesRef.current[j]}
                    }
                }
                if(!foundFreight)account.Expenses.push({...expensesRef.current[j]})
            }
        }
        account.FBCount =  account.Expenses.length;
        for(let q=0; q<account.Expenses.length; q++){
            if( !account.earliestFBDate)account.earliestFBDate=account.Expenses[q].QueryDate;
                if( account.earliestFBDate>account.Expenses[q].QueryDate) account.earliestFBDate=account.Expenses[q].QueryDate;
        }
        if(account.Expenses.length!==0) account.realEarliestFBDate= new Date(account.earliestFBDate);
        
    }

    const filterExpenses = () =>{
       
        if(driversRef.current && createCompanyRef.current){
            for(let i=0; i<driversRef.current.length; i++) filterDriverExpenses(driversRef.current[i]);
            setCreateDrivers([...driversRef.current.filter(driv => driv.FreightBills.length>0 || driv.Expenses.length>0)])
        }
        if(outsideDriversRef.current && createCompanyRef.current ){
            for(let i=0; i<outsideDriversRef.current.length; i++)filterOutsideExpenses(outsideDriversRef.current[i]);
            setCreateOutsideDrivers([...outsideDriversRef.current.filter(driv => driv.FreightBills.length>0 || driv.Expenses.length>0)])
        }
        if(accountsRef.current && createCompanyRef.current ){
            for(let i=0; i<accountsRef.current.length; i++)  filterNonDriverExpenses(accountsRef.current[i]);
            setCreateAccounts([...accountsRef.current.filter(driv => driv.Expenses.length>0)])
        }
    }
 
    const updateFreightBill =async(FreightBill)=>{
        let updateDoc ={  
            "timestamp": Date.now(),
            paid:FreightBill.paid,
       
            truckingPaid:FreightBill.truckingPaid,
            missing:FreightBill.missing,
            onHold:FreightBill.onHold,
            Adjusting:FreightBill.Adjusting,
            standByIsPaid:FreightBill.standByIsPaid ? FreightBill.standByIsPaid : false,
            hourMinIsPaid:FreightBill.hourMinIsPaid ? FreightBill.hourMinIsPaid : false,
            StandByDPSVNum:FreightBill.StandByDPSVNum ? FreightBill.StandByDPSVNum : '',
            StandByDPS:FreightBill.StandByDPS ? FreightBill.StandByDPS : '',
            HourMinDPSVNum:FreightBill.HourMinDPSVNum ? FreightBill.HourMinDPSVNum : '',
            HourMinDPS:FreightBill.HourMinDPS ? FreightBill.HourMinDPS : '',
            DPS: FreightBill.DPS ? FreightBill.DPS : ''
           
      
         };
         console.log('updateDoc for FB = ', updateDoc)
       await updateDocument(updateDoc, FreightBill.ID, 'FreightBills');
    }
    const updateExpense =async(Expense)=>{
        console.log('updating Expense like this = ', Expense)
        let updateDoc ={  
            "timestamp": Date.now(),
            paid:Expense.paid,
            onHold:Expense.onHold,
            DPS: Expense.DPS ? Expense.DPS : '',
         };
         console.log('updateDoc for expense = ', updateDoc)
         await updateDocument(updateDoc, Expense.ID, 'Expenses');
    }
    const checkExpense= async (DPS, Expense) => {
        if (!Expense.onHold) {
            Expense.DPS = DPS.ID;
            Expense.paid = true;
        } else Expense.paid = false;
   
        await updateExpense(Expense); 
    }

    const checkFreightBill = async (DPS, FreightBill) => {
        if(!FreightBill.payDifference)FreightBill.payDifference=0;

        if(!FreightBill.StandByDPS){
            if (!FreightBill.standByOnHold ) {
                FreightBill.standByIsPaid= true;
                FreightBill.StandByDPSVNum = DPS.VNum;
                FreightBill.StandByDPS =DPS.ID;
            }else {
                FreightBill.standByIsPaid = false;
                FreightBill.StandByDPSVNum = '';
                FreightBill.StandByDPS = '';
            }
        }

        if(!FreightBill.DPS){
            if(!FreightBill.onHold) {
                FreightBill.truckingPaid = true;
                FreightBill.missing=false;
                FreightBill.Adjusting = false;
                if (FreightBill.standByIsPaid && FreightBill.hourMinIsPaid) FreightBill.paid = true;
                if (FreightBill.hourMinIsPaid) {
                    FreightBill.HourMinDPSVNum = DPS.VNum;
                    FreightBill.HourMinDPS = DPS.ID;
                }
                else {
                    FreightBill.HourMinDPSVNum = '';
                    FreightBill.HourMinDPS = '';
                }
                console.log('for Freight with Freight num = ' +FreightBill.FBNO +' we are checking if trucking is paid = ' + FreightBill.truckingPaid +' if standByIsPaid = ' + FreightBill.standByIsPaid +' and if hour min is paid = ' + FreightBill.hourMinIsPaid+ 'oh and finally if the whole thing is piad = ' + FreightBill.paid );
                FreightBill.DPSVNum = DPS.VNum;
                FreightBill.DPS = DPS.ID;
                FreightBill.DPSName = DPS.Name;
             
            
            }else {
                FreightBill.truckingPaid = false;
                if (FreightBill.standByIsPaid) {
                    FreightBill.StandByDPSVNum = DPS.VNum;
                    FreightBill.StandByDPS = DPS.ID;
                } else {
                    FreightBill.StandByDPSVNum = '';
                    FreightBill.StandByDPS = '';
                }
                if (FreightBill.hourMinIsPaid) {
                    FreightBill.HourMinDPSVNum = DPS.VNum;
                    FreightBill.HourMinDPS = DPS.ID;
                }
                else {
                    FreightBill.HourMinDPSVNum = '';
                    FreightBill.HourMinDPS = '';
                }
                FreightBill.Adjusting = false;
                FreightBill.paid = false;
                FreightBill.DPSVNum = 0;
                FreightBill.onHold = FreightBill.onHold;
                FreightBill.DPS = '';
                FreightBill.DPSName = '';
            }
        }
        await updateFreightBill( FreightBill);
    }
    const updateChildObjects =  async(PayStatement,  FreightBills, Expenses)=>{

        const freightBillPromises = FreightBills.map((freightBill) => checkFreightBill(PayStatement, { ...freightBill }) );
    
        // Create promises for updating Expenses
        const expensePromises = Expenses.map((expense) =>   checkExpense(PayStatement, { ...expense }) );
        await Promise.all([...freightBillPromises, ...expensePromises]);
        console.log('Child objects updated for PayStatement:', PayStatement.ID);


    }
    const checkDPSNumber = async(DPS)=>{

        let invNumberExists = false;
        console.log('WE ARE RUNNIGN TEH FETCH dpse at dp[s] number' +DPS.DPSNum)
        const queryName = `Organizations/${gearedUser.selectedOrgName}/PayStatements`;
        const q = query(collection(db, queryName), where("DPSNum", "==",DPS.DPSNum));
        const querySnapshot = await getDocs(q);
        await querySnapshot.forEach((doc) => {
            invNumberExists=true;
            alert('An Pay Statement with the number ' + DPS.DPSNum +' already exists. Please enter unique number');
        });
        console.log('after the check boiii'+ invNumberExists);
        return invNumberExists;
    }
    const createPayStatement = async(PayStatement)=>{
        let tempFreightBills = [...PayStatement.FreightBills];
        let tempExpenses = [...PayStatement.Expenses];
        
        PayStatement.FreightBills=[];
        PayStatement.Expenses=[];
        PayStatement.LineItems =  PayStatement.LineItems.filter(lineitem => !lineitem.onHold);
        console.log('now we are creating payStatement and tempExpenses=- ', tempExpenses);
        console.log(' and the fireght bills = ', tempFreightBills);
        console.log('payStatement we trying to add = ', PayStatement)
        try {
        
            const newPayStatementID= await  addDocument(PayStatement, 'PayStatements');
            PayStatement.ID=newPayStatementID;
        
            updateChildObjects(PayStatement,  tempFreightBills, tempExpenses)
        } catch (e){ console.error("Error adding document: ", e);  throw e;}
   
    }
  
    const calcDPSTotal = (DPS) => {
        if (DPS) {
            // Initialize the Totals array
            DPS.Totals = [];

            // Helper function to sum values based on a filter
            const sumValues = (items, field, filter = () => true) => {
                const sum = items
                    .filter(item => !item.onHold && filter(item))
                    .reduce((sum, item) => {
                        const value = Number(item[field] || 0);
                        return sum + value;
                    }, 0);
                // Round to exactly 2 decimal places to avoid floating point issues
                return Math.round((sum + Number.EPSILON) * 100) / 100;
            };

            // Calculate regular totals (Tons, Loads, Hours)
            const regularTotals = [
                {
                    Type: 'Tons',
                    filter: item => item.RateType === 'Ton' || item.RateType === 'Ton/Percent'
                },
                {
                    Type: 'Loads',
                    filter: item => item.RateType === 'Load' || item.RateType === 'Load/Percent'
                },
                {
                    Type: 'Hours',
                    filter: item => item.RateType === 'Hour' || item.RateType === 'Hour/Percent'
                }
            ];

            regularTotals.forEach(totalType => {
                const total = {
                    Type: totalType.Type,
                    Qty: sumValues(DPS.LineItems, 'Qty', totalType.filter),
                    Total: sumValues(DPS.LineItems, 'Total', totalType.filter)
                };
                total.QtyString = '$' + total.Qty.toFixed(2);
                DPS.Totals.push(total);
            });

            // Calculate Broker and Trailer totals
            let BrokerTotal = {
                Type: 'Broker',
                Qty: DPS.BrokerPercent || 'N/A',
                Total: sumValues(DPS.LineItems, 'BrokerTotal')
            };
            DPS.BrokerFees = BrokerTotal;
            DPS.Totals.push(BrokerTotal);

            let TrailerTotal = {
                Type: 'Trailer Fees',
                Qty: DPS.TrailerPercent || 'N/A',
                Total: sumValues(DPS.LineItems, 'TrailerTotal')
            };
            DPS.TrailerFees = TrailerTotal;
            DPS.Totals.push(TrailerTotal);

            // Calculate Stand By total
            let StandByTotal = {
                Type: 'Stand By',
                loadTotal: sumValues(DPS.LineItems, 'loadTotal'),
                dumpTotal: sumValues(DPS.LineItems, 'dumpTotal'),
                loadQty: sumValues(DPS.LineItems, 'loadQty'),
                dumpQty: sumValues(DPS.LineItems, 'dumpQty'),
                Qty: sumValues(DPS.LineItems, 'Qty', item => item.Type === 'Stand By'),
                Total: sumValues(DPS.LineItems, 'Total', item => item.Type === 'Stand By')
            };
            StandByTotal.QtyString = '$' + StandByTotal.Qty.toFixed(2);
            DPS.Totals.push(StandByTotal);

            // Calculate dynamic expense totals
            const expenseItems = DPS.LineItems.filter(item => 
                !item.onHold && item.Type === 'Expense'
            );

            // Group expenses by description
            const expenseGroups = expenseItems.reduce((groups, item) => {
                const rateType = item.RateType|| 'Other';
                if (!groups[rateType]) {
                    groups[rateType] = {
                        Type: rateType,
                        Qty: 0,
                        Total: 0
                    };
                }
                groups[rateType].Qty += Number(item.Qty || 0);
                groups[rateType].Total += Number(item.Total || 0);
                // Round both Qty and Total to 2 decimal places
                groups[rateType].Qty = Math.round((groups[rateType].Qty + Number.EPSILON) * 100) / 100;
                groups[rateType].Total = Math.round((groups[rateType].Total + Number.EPSILON) * 100) / 100;
                return groups;
            }, {});

            // Add expense totals to DPS.Totals
            Object.values(expenseGroups).forEach(expenseTotal => {
                expenseTotal.QtyString = '$' + expenseTotal.Qty.toFixed(2);
                DPS.Totals.push(expenseTotal);
            });

            // Calculate the final DPS Total
            DPS.Total = {
                Type: 'Amount Due',
                Qty: 'N/A',
                Total: sumValues(DPS.Totals, 'Total')
            };
            DPS.Totals.push(DPS.Total);
            DPS.Balance = {...DPS.Total};
        }
        return DPS;
    };

    Number.prototype.formatMoney = function(c, d, t) {
        var roundNum=this;
        roundNum=roundNum+.000001;
        var n = Number(roundNum),
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d === undefined ? "." : d,
            t = t === undefined ? "," : t,
            s = n < 0 ? "-" : "",
            
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
              
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
     
    };

    return (
        <PayStatementContext.Provider value={{
            payStatementRef, payStatements, setPayStatements, setPayStatement, payStatement, payStatementsRef, setPayStatementVisible, payStatementVisible, filterFreightBills, filterExpenses,
            inputValues, setInputValues, driversRef,outsideDriversRef,accountsRef, createDrivers, setCreateDrivers, setCreateOutsideDrivers, createAccounts, freightBillsRef,expensesRef,
            setCreateAccounts, createCompanyRef, company, createOutsideDrivers,  calcDPSTotal, isLoading,setIsLoading,createPayStatement,checkDPSNumber
        }}>
            {children}
        </PayStatementContext.Provider>
    );
}
export const usePayStatement= () => {
    return useContext(PayStatementContext);
};
