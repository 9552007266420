import React, { useMemo } from 'react';
import { Dialog } from 'primereact/dialog';

import ExpenseList from '../ListComponents/ExpenseList';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';
const ExpensePopUp = (props) => {
	const {expense, setExpense, newExpense, expensePopUpVisible, setExpensePopUpVisible} = useGlobal();
    const { company, drivers, subhaulers } = UserAuth();
    const widths = company.SellMaterial ? ["5%",  "5%","12%", "10%", "6%", "6%", "4%",  "12%", "6%", "4%", "12%", "8%", "6%", "9%"] : ["5%",  "5%", "12%","13%", "8%", "8%", "4%",  "13%", "6%", "4%", "13%", "8%", "8%", "9%"] ;
    console.log('expense = ', expense)
	console.log('expensePopupVisible = ', expensePopUpVisible)
	// Find the associated driver or subhauler using useMemo
    const expenseDriver = useMemo(() => {
        if (!expense?.driverID) return null;

        // First check in drivers list
        const foundDriver = drivers.find(driver => driver.ID === expense.driverID);
        if (foundDriver) {
            return {
                ID: foundDriver.ID,
                Name: foundDriver.Name,
                subhauler: false
            };
        }

        // If not found in drivers, check subhaulers
        const foundSubhauler = subhaulers.find(subhauler => subhauler.Driver.ID === expense.driverID);
        if (foundSubhauler) {
            return {
                ID: foundSubhauler.ID,
                Name: foundSubhauler.Name,
                subhauler: true
            };
        }

        return null;
    }, [expense?.driverID, drivers, subhaulers]);

    const closeExpensePopUp = () => {
		setExpensePopUpVisible(false);
    };

    return (
        <Dialog 
            header="Edit Expense" 
            visible={expensePopUpVisible} 
            style={{ width: '90vw' }}  
            breakpoints={{ '960px': '75vw', '641px': '100vw' }}  
            onHide={closeExpensePopUp}
        >
            <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                <table style={{ marginBottom: "5px", width: "100%" }}>
                    <thead>
                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                            <th style={{ width: widths[0] }}>Delete</th>
                           {expense.dispatchID==='floatingDispatchExpense'	 ? <th style={{ width: "9%"  }}>Date</th> : <th style={{ width: "5%"  }}>On Hold</th>}
                            {company.SellMaterial && <th style={{ width:widths[2] }}>Company</th>}
                            <th style={{ width: widths[3]  }}>Description</th>
                            <th style={{ width: widths[4] }}>QTY</th>
                            <th style={{ width: widths[5] }}>Rate</th>
                            <th style={{ width: widths[6] }}> Bill</th>
                            <th style={{ width: widths[7]  }}>Bill To</th>
                            <th style={{ width: widths[8]  }}>Invoice</th>
                            <th style={{ width: widths[9] }}>Pay </th>
                            <th style={{ width: widths[10]  }}>Pay To</th>
                            <th style={{ width: widths[11]  }}>Pay Statement</th>
                            <th style={{ width: widths[12]  }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expense && (
                            <ExpenseList  expense={expense}    showAddToFreights={false}    driver={expenseDriver} customerID='nothing'/>
                        )}
                    </tbody>
                </table>
            </div>
        </Dialog>
    );
};

export default ExpensePopUp; 