import React, { useState, useRef } from 'react';
import { Image } from 'primereact/image';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faChevronLeft, faChevronRight  } from '@fortawesome/free-solid-svg-icons';
import { UserAuth } from '../../context/AuthContext';

import { Document, Page, pdfjs} from 'react-pdf';

const AttachmentLine = ({ attachment}) => {
    console.log(' attachmentON LAOD = ', attachment);
    const { deleteDocument} = UserAuth();
    const [PDFVisible, setPDFVisible] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const attachImageRef = useRef(null);

    const deleteAttachment =()=>{
        if (window.confirm('Are you sure you would like to delete this attachment?')) {
            deleteDocument(attachment, 'Attachments')
        }
    }

    const closePDFPopUp = ()=>{
        setPDFVisible(false)
    }
    const openPDF = () =>{
        setPDFVisible(true)
    
        setPdfUrl(attachment.url);
            pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;
    }
    const openImagePreview = ()=>{
        console.log('attachment.fileType = ' ,attachment)
        if(attachment.filetype==='application/pdf')openPDF();
    else  attachImageRef.current.show();
    }
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const goToPrevPage = () => {
        setPageNumber((prev) => Math.max(prev - 1, 1));
    };

    const goToNextPage = () => {
        setPageNumber((prev) => Math.min(prev + 1, numPages));
    };

    const footerContent = (
        <div style={{ paddingTop: '1em', textAlign: 'center' }} className="flex align-items-center gap-2">
            <div  className="mbsc-row" style={{paddingBottom:"1em"}}>
                <div className="mbsc-offset-4">
                <Button style={{padding:"1em"}}
                    icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    onClick={goToPrevPage}
                    disabled={pageNumber === 1}
                />
                <span style={{ fontSize: '1.2em', margin: '0 1em' }}>
                    Page {pageNumber} of {numPages}
                </span>
                <Button style={{padding:"1em"}}
                    icon={<FontAwesomeIcon icon={faChevronRight} />}
                    onClick={goToNextPage}
                    disabled={pageNumber === numPages}
                />
            </div>
            </div>
            <Button style={{ fontSize: '1.5em', width: '9em' }} label="Close" icon="pi pi-times" onClick={closePDFPopUp} />
        </div>
    );
    return (
        <React.Fragment>
        <tr  style={{ width: '100%', marginLeft: '1.1em' }}>
            <td data-label="Open" style={{ width: '90%', padding: '0' }}>
                <button style={{ margin: '0', padding: '.5em', width:"100%" }}   onClick={(e) => openImagePreview()}     className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {attachment.Name} </button>

            </td>
          <td>
        
          <FontAwesomeIcon onClick={(e)=>deleteAttachment()} className="fas" icon={faMinusCircle} style={{ cursor:"pointer", color: "red", height: "1.25em", width: "1.25em",paddingLeft:"1em" }} />
            
          </td>
        </tr>

            <Dialog header="Attachment" visible={PDFVisible} style={{ width: '50vw' }} breakpoints={{ '1400px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closePDFPopUp}>
                <Document onLoadSuccess={onDocumentLoadSuccess} style={{width:"100% !important"}} file={pdfUrl}>
                    <Page pageNumber={pageNumber} />
                </Document>
                <p> Page {pageNumber} of {numPages} </p>
        
        </Dialog>
        <Image  src={attachment.url} im ref={attachImageRef} alt="Image" zoomSrc={attachment.url} height="100"  style={{display:"none",margin:0, width:100}}  preview/>

        </React.Fragment>
    );
};

export default AttachmentLine;