import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Calendar} from 'primereact/calendar';


import { Button } from 'primereact/button';
import { Textarea} from '@mobiscroll/react';
import {Dropdown} from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import {useDispatch } from '../../context/DispatchContext';
import { UserAuth } from '../../../../context/AuthContext';
import { useGlobal } from '../../../../context/GlobalContext';
import DispatchListItem from '../../Lists/DispatchListItem'; 
import InputTextParent from '../../../../components/InputComponents/InputTextParent'; 
import AutoCompleteInput from '../../../../components/InputComponents/AutoCompleteInput'; 
import AutoSelectInputNumber from '../../../../components/InputComponents/AutoSelectInputNumber'; 
import useFieldSpecificDebounce from '../../../../hooks/useFieldSpecificDebounce';
import { usePrevailingWageData,useJobData } from '../../hooks';
import { db } from '../../../../firebase';
import { doc} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

function JobEdit(props) {
    
    const navigate = useNavigate();
    const {job, formatDate, setJob, setDispatch ,setDispatchState, jobDispatches, dispatch, dispatchObjectRef} = useDispatch();
    const [inputValues, setInputValues] = useState({LoadSite:{}, DumpSite:{},   JobNumber: '',  PONumber: '', ContractNumber: '', JobType: ''});
    const { gearedUser, companies, accounts, locations, materials, truckTypes, contacts, addDocument, company, deepEqual, addAuditLog} = UserAuth();
    const { showContactPopUp, showAccountPopUp, showLocationPopUp, showMaterialPopUp,showTruckTypePopUp} = useGlobal();

   
    let jobRef = job?.ID ? doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Jobs', job?.ID) : null;
    const {updatePrevailingWageReport,handlePrevailingWageDateChange, handlePrevailingWageChange,
		 handlePrevailingWageRateChange, handlePrevailingWageReportCreate} = usePrevailingWageData(inputValues, setJob,jobRef, setInputValues);
    const {updateJobFields, updateJobField} = useJobData(job, jobRef, jobDispatches, setJob, setDispatch, dispatch);


	const accountContacts = inputValues?.Account && contacts ? contacts.filter(contact => contact.Account?.ID === inputValues.Account?.ID).map((contact) => (contact)) : [];
    const jobDivClass = inputValues?.ID ? "mbsc-col-md-12 mbsc-col-lg-5 mbsc-offset-lg-1" : "mbsc-col-md-12 mbsc-col-lg-5 mbsc-offset-lg-3"

    const billTypes=[{text:'Hour', value:'Hour'},{text:'Load', value:'Load'},{text:'Ton', value:'Ton'}]
    const materialBillTypes=[{text:'Ton', value:'Ton'},{text:'Load', value:'Load'},{text:'Yard', value:'Yard'}]
    const defaultTruckType = truckTypes ? truckTypes.filter(trucktype => trucktype.Default): {Name:'No Truck Type', ID:''};
    const previousDumpSiteRef = useRef(null);
	let sortedDispatches= jobDispatches ? jobDispatches.sort((a, b) => new Date(a.QueryDate) - new Date(b.QueryDate)) : [];
    console.log('inputValues= ', inputValues)
    const debouncedFieldUpdater = useFieldSpecificDebounce(500);


    useEffect(() => {

        if (job) {
            console.log('we getting job = ', job)
		
            let tempJob={...job};
            tempJob.JobDateValue= new Date(tempJob.JobDate)
            tempJob.prevailEndDateValue= new Date(tempJob.prevailEndDate)
            tempJob.prevailStartDateValue= new Date(tempJob.prevailStartDate)
       
            setInputValues((prev) => ({ ...prev, ...tempJob}));

        } 
    }, [job]);

    useEffect(() => {
        if(inputValues){
            const fieldsToUpdate = ['Account','LoadSite','DumpSite','TruckType', 'Material'];
            const collections = { Account:accounts, LoadSite:locations, DumpSite:locations, TruckType: truckTypes, Material: materials };
            fieldsToUpdate.forEach(field => {
                const selectedItem = inputValues[field];
                if (selectedItem) {
                    const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                    if (updatedItem) if(!deepEqual(updatedItem, selectedItem)) handleFieldChange(field, { ...updatedItem }, true);

                }
            });
        }
    }, [truckTypes, accounts, locations, materials]);

    useEffect(() => {   
    
        let tempTuckType =  truckTypes ? truckTypes.filter(trucktype => trucktype.Default): {Name:'No Truck Type', ID:''};
        setInputValues((prev) => ({ ...prev, TruckType:tempTuckType[0]}));  
        setJob((prev) => ({ ...prev, TruckType:tempTuckType[0]}));  
     }, [truckTypes]);

    useEffect(() => {
        if(inputValues?.SellMaterial && inputValues?.MaterialTaxable){
            if (inputValues?.DumpSite?.fullAddress !== null && inputValues?.DumpSite?.fullAddress !== undefined && previousDumpSiteRef?.current!== undefined && previousDumpSiteRef?.current!== null){
                if(inputValues?.DumpSite?.fullAddress!==previousDumpSiteRef?.current || !inputValues?.MaterialTaxRate) getTaxRate();
            }
            if (inputValues?.DumpSite?.fullAddress !== null && inputValues?.DumpSite?.fullAddress !== undefined)   previousDumpSiteRef.current = inputValues?.DumpSite?.fullAddress; 
        }
    }, [inputValues?.DumpSite?.fullAddress, inputValues?.MaterialTaxable, inputValues?.SellMaterial]);
    useEffect(() => {
        if (job?.LoadSite.fullAddress){
            setJob((prev) => ({ ...prev, LoadSiteAddress:job.LoadSite.fullAddress }));  
        } 
    }, [job?.LoadSite]);
    useEffect(() => {
        if (job?.DumpSite.fullAddress){
            setJob((prev) => ({ ...prev, DumpSiteAddress:job.DumpSite.fullAddress }));  
        } 
    }, [job?.DumpSite]);

    const checkNewJob = () =>{

        if(!inputValues.Account?.Name || inputValues.Account.Name==='No Account'){
            alert('Please select a valid Customer');
            return 1;
        }
        let tempInputValues = {...inputValues};
        if(!tempInputValues.LoadSite?.ID)tempInputValues.LoadSite={ID:"", Name:"No Location"};
        if(!tempInputValues.TruckType?.ID)tempInputValues.TruckType=defaultTruckType[0];
        if(!tempInputValues.DumpSite?.ID)tempInputValues.DumpSite={ID:"", Name:"No Location"};
        if(!tempInputValues.Contact?.ID)tempInputValues.Contact={ID:"", Name:"No Contact"};
        if(!tempInputValues.Material?.ID)tempInputValues.Material={ID:"", Name:"No Material"};
        console.log('tempInmputValues looks like ths =' , tempInputValues);
       createNewJob(tempInputValues);
    }
    const createNewJob = async(tempJob) =>{
        
       
        console.log('query date = ', formatDate(inputValues.JobDate,'/','YYYY/MM/DD')) ;
     
        tempJob.latestDispatchDate = formatDate(inputValues.JobDate,'/','YYYY/MM/DD');
        tempJob.QueryDate = formatDate(inputValues.JobDate,'/','YYYY/MM/DD');
        let tempID = await addDocument( tempJob, "Jobs");
      
        tempJob.ID=tempID;
        let firstDispatch = {
            ID: '',
            Name: '',
            Billable: true,
            Account:{...tempJob.Account},
            Job: { JobNumber: tempJob.JobNumber, ID: tempID },
            Company:{...tempJob.Company},
            MaterialCompany:{...tempJob.MaterialCompany},
            TruckType:tempJob.TruckType,
            LoadSite: tempJob.LoadSite,
            DumpSite: tempJob.DumpSite,
            Material: tempJob.Material,
            Contractor: { Name: 'No Contractor', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Owner: { Name: 'No Owner', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Lender: { Name: 'No Lender', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Shipper: { Name: 'No Shipper', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Receiver: { Name: 'No Receiver', ID: 'default', Address: '', State: '', City: '', Contractor: true },
            Foreman: { Name: 'No Foreman', ID: 'default', Phone: '' },
            ReceiverForeman: { Name: 'No Foreman', ID: 'default', Phone: '' },
            JobNumber: tempJob.JobNumber,
            ContractNumber: '',
            BrokerFee: tempJob.BrokerFee,
            PONumber: '',
            BillRate: tempJob.BillRate ?  tempJob.BillRate : '',
            BillType: tempJob.BillType ?  tempJob.BillType : '',
            PayRate:tempJob.PayRate,
            OutsidePayRate: tempJob.OutsidePayRate,
            PayType: tempJob.PayType,
            HourMin: '',
            ShowUp: '',
            JobID: tempID,
            SetManual: false,
            StartTime: '',
            SimLoad: '',
            MinBetLoad: '',
            UseStandBy: true,
            Notes: '',
            QuoteName: '',
            RouteIndex: 0,
            JobDate: tempJob.JobDate,
            QueryDate:formatDate(tempJob.JobDate,'/','YYYY/MM/DD'),
         
            JobType:tempJob.JobType,
            CertifiedPayroll: false,
            billBrokerFuel: true,
            TrucksNeeded: 0,
            TrucksOrdered: '',
            Unread: 0,
            unBilledFreights: 0,
            unBilledExpenses:0,
            unBilledMaterials:0,
            billedFreights: 0,
            oldTrucksOrdered: '',
            TrucksAssigned: 0,
            ReceivedDispatch: 0,
            Style:{ 'background': 'rgb(247 247 247)' },
            FuelCharge: '',
            InternalNotes: '',
            Released: false,
            Cancelled: false,
            loadsPerTruck: 0,
            deliveredLoads: 0,
            deliveredQty: 0,
            Updating: false,
            DirectionsOK: false,
            showBillRate: false,
            showPayRate: false,
            PrevailingWageRate: tempJob.PrevailingWageRate ? tempJob.PrevailingWageRate : '',
            OwnerOpPrevailingWageRate: tempJob.OwnerOpPrevailingWageRate ? tempJob.OwnerOpPrevailingWageRate : '',
            OvertimePrevailingWageRate: tempJob.OvertimePrevailingWageRate ? tempJob.OvertimePrevailingWageRate : '',
            PrevailingWage: tempJob.PrevailingWage,
            indexAndDate: 0 + tempJob.JobDate,
            CurrentDispatch: true,
            SellMaterial: tempJob.SellMaterial,
            MaterialTaxable: tempJob.MaterialTaxable,
            MaterialTaxRate: tempJob.MaterialTaxRate,
            MaterialRateType: tempJob.MaterialRateType,
            standBR: tempJob.Account.standBR || '',
            standPR: tempJob.Account.standPR || '',
            standLA: tempJob.Account.standLA || '',
            standDA: tempJob.Account.standDA || '',
            dumpAddressOK:false,
            loadAddressOK:false,
            loadResults:'',
            dumpResults:'',
            Directions:'',
            FirstDispatch: true,
            LoadOrders:[],
            DriverGPSList: [],
            StandBy: { PaidStandByRate: '', BillStandByRate: '', LoadAllowed: '', DumpAllowed: '' }
        }
		console.log('firstDispatch = ', firstDispatch)
		if (tempJob.PrevailingWage)handlePrevailingWageReportCreate(tempJob);

        firstDispatch.ID = await addDocument(firstDispatch, "Dispatches");
        setJob(tempJob);
        setDispatch({...firstDispatch})
        dispatchObjectRef.current = {...firstDispatch};
        addAuditLog('CreateFirstDispatch', 'Dispatches',firstDispatch.ID, firstDispatch);
        const encodedDate = encodeURIComponent(firstDispatch.QueryDate); 
        navigate(`/dispatch/edit/${firstDispatch.ID}/${firstDispatch.JobID}/${encodedDate}/DispatchEdit`);
        setDispatchState('DispatchEdit');
        console.log(' we creatiene new dispatch = ', firstDispatch);
    }
 
	const updateJobandPrevailingWageReport = useCallback(async(fields, values, updateDispatches) =>{
		updateJobFields(fields, values, updateDispatches);
		if(inputValues.PrevailingWage)updatePrevailingWageReport(fields, values);
	},[inputValues]);

    const handleCheckBoxChange = (fieldName, value, updateDispatches) =>{
        
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        if(inputValues.ID!=='')updateJobField(fieldName, value, updateDispatches);
    }
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate) 
        let fields = [fieldName,fieldName+'Value' ];
        let values = [formattedDate, value];
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        setJob((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
     
        if(inputValues.ID!==''){
            const debouncedUpdate = debouncedFieldUpdater(fields, updateJobandPrevailingWageReport);
            debouncedUpdate(fields, values, false); // Pass `fields` and `values` dynamically
        }
    };
    
    const handleAccountChange = (fieldName, value) =>{
        console.log('runnign change account ')
        let updateObject ={[fieldName]: value };
		let brokerFee = (value.Broker && value.BrokerFee) ? value.BrokerFee : 0;
        let fields =['Account','AccountName', 'AccountFax', 'TrackPrelim', 'Contact', 'BrokerFee'];
        let values = [value, value.Name,  value.Fax ? value.Fax : '', updateObject.trackPrelim ? updateObject.trackPrelim :false, {Name:'No Contact', ID:''}, brokerFee  ]
        setInputValues(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
          });
        
          setJob(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
          });
        
    
		  if(inputValues.ID!==''){
            const debouncedUpdate = debouncedFieldUpdater(fields, updateJobandPrevailingWageReport);
             debouncedUpdate(fields, values, true); // Pass `fields` and `values` dynamically
          
        }
    }
 
    const handleLocationChange = (fieldName, value, updateDispatches) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
        setJob((prev) => ({ ...prev, [fieldName]: value }));
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        if(inputValues.ID!==''){
            const debouncedUpdate = debouncedFieldUpdater([fieldName], updateJobandPrevailingWageReport);
			debouncedUpdate([fieldName], [value], updateDispatches);
        }
    }

    const handleFieldChange = (fieldName, value, updateDispatches) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
        setJob((prev) => ({ ...prev, [fieldName]: value }));
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));

		if(inputValues.ID!==''){
	
            const debouncedUpdate = debouncedFieldUpdater([fieldName], updateJobandPrevailingWageReport);
            debouncedUpdate([fieldName], [value], updateDispatches); // Pass `fields` and `values` dynamically
          
		
        }
       
    };

    const handleFieldsChange = (fields, values,  updateDispatches) => {
        console.log('fieldNames= ',fields)
        console.log('values= ',values)
   
        setInputValues(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++){
                newState[fields[i]] = values[i]; 
                console.log('values[i] = ', values[i])
            }
          
            return newState;
          });

        setJob(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
        });
      // updateDispatchFields(fields, values, updateFreightBills)
        if(inputValues.ID!==''){
            const debouncedUpdate = debouncedFieldUpdater(fields, updateJobFields);
            debouncedUpdate(fields, values, updateDispatches); // Pass `fields` and `values` dynamically
        }
     
    };

    const handlePayTypeChange = async(payType)=>{
        handleFieldChange('PayType', payType, false)
    }
    const handleDropdownChange = (e)=>{
     
        const allElements = Array.from(document.querySelectorAll('div, input, select, textarea'));
        let nextElement =  document.getElementById(e.target.id).parentElement.nextElementSibling;

        const currentIndex = allElements.indexOf(nextElement);
        if (currentIndex > -1 && currentIndex < allElements.length - 1) {
            // Iterate through the elements starting from the next index
            for (let i = currentIndex; i < allElements.length; i++) {
                const element = allElements[i];
    
                // Check if the element is focusable
                if (element.matches('input:not([disabled]), select:not([disabled]), textarea:not([disabled])')) {
                    element.focus(); // Focus the element
                    console.log('Focused element =', element);
                    break; // Stop once a focusable element is found
                }
            }
        }
    }
    const getTaxRate = async () => {
        const object = { ...inputValues };
        console.log('Starting the get tax rate boiiis! and object = ', inputValues);
        const tempAccount = accounts.filter(acc => acc.ID === object.Account.ID);
        const tempQuickbook = tempAccount[0].Quickbooks.filter(qb=> qb.realmID === company.realmID);
        console.log('tempAccount[0]= ', tempAccount[0]);
        const QBCustomerID= tempQuickbook[0] ? tempQuickbook[0].QBCustomerID ? tempQuickbook[0].QBCustomerID: '' : '';
        const data = {
            DumpSite: object.DumpSite,
            QBCustomerID:QBCustomerID,
            JobNumber: inputValues.JobNumber,
            OrganizationName: gearedUser.selectedOrgName,
            realmID: company.realmID
        };

        try {
            const response = await axios.post('https://geared.tech/getTaxRate', data, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.info) {
                const fakeInvoice = response.data.info.Invoice;
                let tempTaxRate = 0;

                for (const taxLine of fakeInvoice.TxnTaxDetail.TaxLine) {
                    tempTaxRate += taxLine.TaxLineDetail.TaxPercent;
                    console.log('TaxLineDetail = ', taxLine.TaxLineDetail);
                }

                if (tempTaxRate > 0)   handleFieldChange( 'MaterialTaxRate',tempTaxRate, true)
                
            
            }

            console.log('Finished getting that tax rate boiis! Response = ', response);

        } catch (error) {
            console.error('Error getting Tax Rate = ', error);
        }
    };
    const sellMaterialContent = () => {
        return(
            <div  className="mbsc-form-group" style={{margin:"0"}}> 
                <AutoCompleteInput label="Mat. Company" fieldName="MaterialCompany" field="CompanyName" value={inputValues.MaterialCompany} suggestions={companies}  setValue={setInputValues}  handleFieldChange={handleFieldChange}   />
                <div className="mbsc-row">
               
                    <div className="mbsc-col-xl-6 p-inputgroup" style={{padding:"0"}}  id="materialBillTypeInputGroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate Type</span>
                        <Dropdown value={inputValues.MaterialRateType} id="jobMaterialBillTypeDropdown" onChange={(e) => {handleFieldChange('MaterialRateType', e.value, true);handleDropdownChange(e)}}  options={materialBillTypes} optionLabel="text" 
                            placeholder="Rate Type" className="w-full md:w-14rem" />
                    </div>
            
                    <div className="p-inputgroup mbsc-col-xl-6" style={{padding:"0"}}>
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  > Rate</span> 
                        <AutoSelectInputNumber   value={inputValues.MaterialRate}   onChange={(e) => handleFieldChange('MaterialRate', e.value, true)} />
                    </div>
                
            </div>
            <div className="mbsc-row">
                <div className="p-inputgroup mbsc-col-xl-6" style={{padding:'0'}}>
                    <span className="p-inputgroup-addon " style={{flexBasis:"75%"}}>Taxable</span>
                    <Checkbox style={{ width: '100%' }} onChange={(e) => handleCheckBoxChange('MaterialTaxable', e.checked, true)}  checked={inputValues.MaterialTaxable}   />
                </div>
                {inputValues.MaterialTaxable &&(<div className="p-inputgroup mbsc-col-xl-6" style={{padding:"0"}}>
                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Tax Rate</span> 
                    <AutoSelectInputNumber   value={inputValues.MaterialTaxRate}   onChange={(e) => handleFieldChange('MaterialTaxRate', e.value, true)}  />
                </div>
                )}
            </div>
        
        </div>
        )
    }
	const viewPrelims = () => {
		setDispatchState('JobPrelim');
		const encodedDate = encodeURIComponent(dispatch.QueryDate); 
		navigate(`/dispatch/edit/${dispatch.ID}/${job.ID}/${encodedDate}/JobPrelim`);
	}
    const openDispatch = (tempDispatch)=>{
        console.log('tempDispatch = ', tempDispatch)
        const encodedDate = encodeURIComponent(tempDispatch.QueryDate); 
        navigate(`/dispatch/edit/${tempDispatch.ID}/${job.ID}/${encodedDate}/DispatchEdit`);
    }
    return (
        <div>
           {inputValues && (
            <div className="mbsc-row ">
                <div className={jobDivClass}>
                    <Panel header='Job'>
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Start Date </span> 
                            <Calendar value={inputValues.JobDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'JobDate',e.value )} />
                        </div>
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-inputgroup"  >Job # </span> 
                            <InputTextParent  value={inputValues.JobNumber}  onChange={(e) => handleFieldChange('JobNumber', e.target.value, true)} />
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div className="p-inputgroup mbsc-col-lg" style={{padding:'0'}} >
                                <span className="p-inputgroup-addon dispatch-small-inputgroup" >PO # </span> 
                                <InputTextParent  value={inputValues.PONumber}  onChange={(e) => handleFieldChange('PONumber', e.target.value, true)} />
                            </div>
                            <div className="p-inputgroup mbsc-col-lg" style={{padding:'0'}} >
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Contract # </span> 
                                <InputTextParent  value={inputValues.ContractNumber}  onChange={(e) => handleFieldChange('ContractNumber', e.target.value, true)} />
                            </div>
                        </div>
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Type </span> 
                            <InputTextParent  value={inputValues.JobType}  onChange={(e) => handleFieldChange('JobType', e.target.value, false)} />
                        </div>
                        <AutoCompleteInput fieldName="Company" field="CompanyName" value={inputValues.Company} suggestions={companies}  setValue={setInputValues}  handleFieldChange={handleFieldChange}   />
                        <AutoCompleteInput label="Customer" fieldName="Account" field="Name" value={inputValues.Account} suggestions={accounts} setValue={setInputValues} handleFieldChange={handleAccountChange}  editClick={() => showAccountPopUp(inputValues.Account)} databaseList={'Accounts'}  />
                        <AutoCompleteInput fieldName="Contact" field="Name" value={inputValues.Contact} suggestions={accountContacts} setValue={setInputValues} handleFieldChange={handleFieldChange}  editClick={() => showContactPopUp(inputValues.Contact)} />
                        <AutoCompleteInput label="Load Site" fieldName="LoadSite" field="Name" value={inputValues.LoadSite} suggestions={locations} setValue={setInputValues} handleFieldChange={handleLocationChange}  editClick={() => showLocationPopUp(inputValues.LoadSite)}  databaseList={'Locations'}/>
                        <div className="p-inputgroup text-area-inputgroup" >
                            <span className="p-inputgroup-addon " style={{flexGrow:0, flexShrink:0,   flexBasis: '25%', minWidth:'150px'}} >Address</span> 
                            <Textarea disabled={true}  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px", height:'100%' }} value={inputValues.LoadSite.fullAddress}  id="jobLoadSite"  />
                        </div>
                        <AutoCompleteInput label="Dump Site" fieldName="DumpSite" field="Name" value={inputValues.DumpSite} suggestions={locations} setValue={setInputValues} handleFieldChange={handleLocationChange} editClick={() => showLocationPopUp(inputValues.DumpSite)}   databaseList={'Locations'} />

                        <div className="p-inputgroup text-area-inputgroup"  >
                            <span className="p-inputgroup-addon " style={{flexGrow:0, flexShrink:0,   flexBasis: '25%', minWidth:'150px'}}  >Address:</span> 
                            <Textarea disabled={true}   style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px", height:'100%' }}value={inputValues.DumpSite.fullAddress}  id="dumpSite"  />
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div  className="p-inputgroup mbsc-col-lg-6"  style={{padding:"0"}} id="billTypeInputGroup">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Bill Type</span>
                                <Dropdown value={inputValues.BillType} id="billTypeDropdown" onChange={(e) => {handleFieldChange('BillType', e.value, true); handleDropdownChange(e)}}  options={billTypes} optionLabel="text" 
                                    placeholder="Bill Type" className="w-full md:w-14rem" />
                            </div>
                            <div  className="p-inputgroup mbsc-col-lg-6"  style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Bill Rate</span> 
                                <AutoSelectInputNumber  isCurrency={true}  placeholder="Enter a number" value={inputValues.BillRate}   onChange={(e) => handleFieldChange('BillRate', e.value, true)}   />
                            </div>
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div  className="p-inputgroup mbsc-col-lg-6"  style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Pay Type</span>
                                <Dropdown value={inputValues.PayType} id="payTypeDropdown" onChange={(e) =>{ handlePayTypeChange( e.value); handleDropdownChange(e)}} options={billTypes} optionLabel="text" 
                                    placeholder="Pay Type" className="w-full md:w-14rem" />
                            </div>
                    
                            <div  className="p-inputgroup mbsc-col-lg-6"   style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Rate</span> 
                                <AutoSelectInputNumber isCurrency={true} value={inputValues.PayRate}   placeholder="Enter a number"  onChange={(e) => handleFieldsChange(['PayRate', 'OutsidePayRate'], [e.value, e.value], true)}  />
                            </div>
                        </div>
                        {/*<div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-inputgroup">Qty Type</span>
                            <Dropdown value={inputValues.QtyType} onChange={(e) => handleFieldChange('QtyType', e.value, false)}  options={qtyTypes} optionLabel="text" placeholder="Qty Type" className="w-full md:w-14rem" />
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Qty Ordered</span> 
                                <AutoSelectInputNumber   value={inputValues.qtyOrdered}   onChange={(e) => handleFieldChange('qtyOrdered', e.value, false)}  />
                            </div>
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Qty Delivered</span> 
                                <AutoSelectInputNumber   value={inputValues.qtyDelivered}   onChange={(e) => handleFieldChange('qtyDelivered', e.value, false)}  />
                            </div>
                        </div>*/}
                        <AutoCompleteInput label="Truck Type" fieldName="TruckType" field="Name" value={inputValues.TruckType} suggestions={truckTypes}   setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckTypePopUp(inputValues.TruckType)}  databaseList={'TruckTypes'}  />

                        {company.SellMaterial? (
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div className="mbsc-col-lg-6" style={{padding:'0'}}>
                                <AutoCompleteInput label="Material" fieldName="Material" field="Name" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)}   databaseList={'Materials'} />
                            </div>
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:'0'}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup" >Sell Material</span>
                                <Checkbox style={{ width: '100%' }} onChange={(e) => handleCheckBoxChange('SellMaterial', e.checked, true)}  checked={inputValues.SellMaterial}   />
                            </div>
                        </div>):(  <AutoCompleteInput label="Material" fieldName="Material" field="Name" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)}   databaseList={'Materials'} />)}
                        {inputValues.SellMaterial &&(<Panel style={{margin:".75em"}} header="Sell Material Details">
                                {sellMaterialContent()}
                            </Panel>)}

                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:'0'}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup" >Track Prelim Notices</span>
                                <Checkbox style={{ width: '100%' }} onChange={(e) => handleCheckBoxChange('TrackPrelim', e.checked, true)}  checked={inputValues.TrackPrelim}   />
                            </div>
                            {inputValues.TrackPrelim && (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <Button  color="primary" onClick={(e) => viewPrelims()}  style={{margin:"0",  paddingBottom: ".2em",  justifyContent: "center", paddingTop: ".2em", width:"100%", height:"100%"}}>Show Prelim</Button> 

                            </div>)}
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:'0'}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup" >Prevailing Wage</span>
                                <Checkbox style={{ width: '100%' }} onChange={(e) => handlePrevailingWageChange('PrevailingWage', e.checked, true)}  checked={inputValues.PrevailingWage}   />
                            </div>
                            {inputValues.PrevailingWage && (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Prevail. Rate</span> 
                                <AutoSelectInputNumber   value={inputValues.PrevailingWageRate} isCurrency={true}   onChange={(e) => handlePrevailingWageRateChange('PrevailingWageRate', e.value,true)}  />
                            </div>)}
                        </div>
                        <div className='mbsc-row' style={{margin:'0'}} >
                            {inputValues.PrevailingWage && (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >OT Prevail. Rate</span> 
                                <AutoSelectInputNumber   value={inputValues.OvertimePrevailingWageRate} isCurrency={true}   onChange={(e) => handlePrevailingWageRateChange('OvertimePrevailingWageRate', e.value,true)}  />
                            </div>)}
                            {inputValues.PrevailingWage && (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Owner Op Prevail. Rate</span> 
                                <AutoSelectInputNumber   value={inputValues.OwnerOpPrevailingWageRate} isCurrency={true}   onChange={(e) => handlePrevailingWageRateChange('OwnerOpPrevailingWageRate', e.value,true)}  />
                            </div>)}
                         
                        </div>
                        {inputValues.PrevailingWage &&(<div className='mbsc-row' style={{margin:'0'}} >
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:'0'}} >
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Prevail. Start Date </span> 
                                <Calendar value={inputValues.prevailStartDateValue} style={{width:"100%"}} onChange={(e) => handlePrevailingWageDateChange( 'prevailStartDate',e.value )} />
                            </div>
                            <div className="p-inputgroup mbsc-col-lg-6" style={{padding:'0'}} >
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Prevail. End Date </span> 
                                <Calendar value={inputValues.prevailEndDateValue} style={{width:"100%"}} onChange={(e) => handlePrevailingWageDateChange( 'prevailEndDate',e.value )} />
                            </div>
                        </div>)}
                       {!inputValues.ID && ( <div className="mbsc-row" style={{paddingBottom:"1em"}}>
                            <div className=" mbsc-offset-lg-2 mbsc-col-8" >
                                <Button  color="primary" onClick={(e) => checkNewJob()}  style={{margin:".5em",  paddingBottom: ".2em",  justifyContent: "center", paddingTop: ".2em", width:"100%", height:"100%"}}>Create Job/Assign Drivers</Button> 
                            </div>
                        </div>)}
                    </Panel>
                </div>
                {inputValues.ID &&(<div className="mbsc-col">
                    <Panel header='Dispatches'>
                        {sortedDispatches.map((item,index) => (  <DispatchListItem key={index} dispatch={item} onclick={openDispatch}/>  ))}
                  
                    </Panel>

                </div>)}

                
               
             
                
            </div>)} 
        </div>
        
            
    )
}
export default JobEdit;