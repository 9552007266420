import { useMemo } from 'react';

export const useOwnerOpCalculations = () => {
    const getInitialOwnerOpState = () => ({
        ID: '',
        Name: '',
        DriverName: '',
        ReportName: '',
        Driver: '',
        Address: '',
        City: '',
        State: '',
        ZipCode: '',
        hoursPerDay: [0, 0, 0, 0, 0, 0, 0],
        overtimePerDay: [0, 0, 0, 0, 0, 0, 0],
        projectPaid: 0,
        addToReport: false,
        Exception: '',
        Explanation: '',
        rateOfPay: 0
    });

    const calculateOwnerOpHours = (freightBill, dayOfWeek) => {
        let regularHours = Number(freightBill.tHours);
   

        return {
            regularHours: Number(regularHours.toFixed(1)),
        
        };
    };

    const calculateOwnerOpTotals = (ownerOp, weekFreightBills, jobId, ownerOpRate) => {
		console.log('calculateOwnerOpTotals for ownerOp = ', ownerOp.Name)
        const result = { 
      
            ID: ownerOp.ID,
            Name: ownerOp.Name,
            DriverName: ownerOp.DriverName || '',
            ReportName: ownerOp.ReportName || '',
            Driver: ownerOp.Driver || '',
            Address: ownerOp.Address || '',
            City: ownerOp.City || '',
            State: ownerOp.State || '',
            ZipCode: ownerOp.ZipCode || '',
			EquipNo: ownerOp.EquipNo || '',
			hoursPerDay: [0, 0, 0, 0, 0, 0, 0],
			overtimePerDay: [0, 0, 0, 0, 0, 0, 0],
			allProjectsTotal:ownerOp.allProjectsTotal || 0 ,
			projectPaid: 0,
			addToReport: false,
            rateOfPay: ownerOpRate || 0
        };

        let hasProjectWork = false;

        weekFreightBills.forEach(bill => {
            // Skip if not this owner operator's freight bill or no hours
            if (bill.Driver !== ownerOp.Driver?.ID || !bill.tHours) return;

            const dayOfWeek = new Date(bill.JobDate).getDay();
            const { regularHours} = calculateOwnerOpHours(bill, dayOfWeek);

            // Update totals for the specific job
            if (bill.JobID === jobId) {
                hasProjectWork = true;
                result.projectPaid += Number(bill.tPaid) || 0;
                result.hoursPerDay[dayOfWeek] = regularHours;
              
            }
			//result.allProjectsTotal += Number(bill.tPaid) || 0;
			console.log('and = '+  ownerOp.Name+ ' has a total of '+ result.allProjectsTotal)
        });

        result.addToReport = hasProjectWork;
        result.projectPaid = Math.round((result.projectPaid + Number.EPSILON) * 100) / 100;

        return result;
    };

    return {
        getInitialOwnerOpState,
        calculateOwnerOpTotals
    };
}; 