import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

const FuelCardsHome = (props) => {
    const { fuelCards, deleteDocument, formatDate } = UserAuth();
    const { showFuelCardPopUp} = useGlobal();
    const [sortedFuelCards, setSortedFuelCards] = useState([]);

    useEffect(() => {
        // Sort the fuelCards array by Name
        const sorted = [...fuelCards].sort((a, b) => {
            if (a.Name < b.Name) return -1;
            if (a.Name > b.Name) return 1;
            return 0;
        }).map(card => ({
            ...card,
            realIssuedDate: card.IssuedDate ? new Date(card.IssuedDate) : ''
        }));
        setSortedFuelCards(sorted);
    }, [fuelCards]);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DieselOrGas: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CardNo: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        'Driver.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Pin: { value: null, matchMode: FilterMatchMode.CONTAINS}

        // Don't set anything here for Account.Name initially
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
  
   
    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({
            ...prevFilters,
            global: { ...prevFilters.global, value }
        }));
        setGlobalFilterValue(value);
    };
    const dateBodyTemplate = (rowData, fieldName) => {
        return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
    };  
    const renderHeader = () => (
     
        <div className="flex justify-content-end mbsc-row ">
            <span>FuelCards</span>
            <IconField iconPosition="left" className="homeSearch">
                <InputIcon className="pi pi-search" />
                <InputText style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit = (rowData) => {
       console.log('show roate ', rowData)
        showFuelCardPopUp(rowData);
    };
 

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
             <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this Fuel Card?")) {
               await deleteDocument(rowData,'FuelCards' )
            }
            
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

  	console.log('fuelCards = ', sortedFuelCards)
    const header = renderHeader();
	const createColumn = ({label, bodyTemplate, ...props}) => {
		const filterPlaceholder = "Search by " + label;
		const filter= bodyTemplate ? false : true
		const field = props.field || label;
		bodyTemplate = props.dataType === 'date' ? (rowData) => dateBodyTemplate(rowData, props.field) : bodyTemplate;
		return (
		  <Column 
				key={label}
				pt={{root: { 'data-label': label}}} 
				header={label} 
				body={ label === 'Driver' ? (rowData) => rowData.Driver?.Name || '' : bodyTemplate} 
				filterPlaceholder={filterPlaceholder}
				field={field}
				filter={filter}
				{...props}
			/>
		);
	};
    return (
        <div className="card">
				
            <DataTable 
				value={sortedFuelCards} 
				paginator 
				rows={25} 
				dataKey="ID" 
				stripedRows 
				filters={filters} 
				header={header} 
				filterDisplay="row" 
				emptyMessage="No fuel cards found."
			>
				{createColumn({label: 'Edit', bodyTemplate: editBodyTemplate})}
				{createColumn({label: 'Delete', bodyTemplate: deleteBodyTemplate})}
				{createColumn({label: 'Card #', field: "CardNo"})}
				{createColumn({label: 'Pin' })}
				{createColumn({label: 'Issued', dataType:"date", sortable:true, field: "realIssuedDate"  })}
				{createColumn({label: 'D or G', field:"DieselOrGas" })}
				{createColumn({label: 'Driver', field:"Driver.Name" })}
			
				
            </DataTable>
        </div>
    );
};

export default FuelCardsHome;
