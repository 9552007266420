import { useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';

const useFieldSpecificDebounce = (delay = 500) => {
    const debouncedFunctions = useRef(new Map());
    const getDebouncedFunction = useCallback((fields, updateFunction) => {
        const key = `${updateFunction.name}:${Array.isArray(fields) ? fields.join(',') : fields}`;
    

        if (!debouncedFunctions.current.has(key)) {
            const debounced = debounce((...args) => {
                console.log('args = ', args)
                updateFunction(...args); // Dynamically pass arguments when called
           
            }, delay, { leading: false, trailing: true });

            debouncedFunctions.current.set(key, debounced);
        }

        return (...args) => {
            const debounced = debouncedFunctions.current.get(key);
            if (debounced) {
                debounced(...args); // Pass the latest arguments dynamically
            }
        };
    }, [delay]);

    return getDebouncedFunction;
};

export default useFieldSpecificDebounce;
