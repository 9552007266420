import React, {useRef, useEffect, useState} from 'react'

import { UserAuth } from '../../../context/AuthContext'
import DispatchCalendar from './DispatchCalendar';
import DispatchCard from '../Lists/DispatchCard';
import {useDispatch  } from '../context/DispatchContext';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Calendar } from 'primereact/calendar';
import {useParams } from 'react-router-dom';

import DispatchPrintPopUp from '../PopUps/DispatchPrintPopUp';

import { useDispatchPrintContext } from '../context/DispatchPrintContext';
export default function DispatchHome() {

    const { jobDate } = useParams();
    const {  homeDate, homeDispatches, homeFreightBills, homeFreightBillsRef,  handleUpdateFreightBills,
        setDispatch,  formatDate, setDispatchState,  dispatchObjectRef, checkReleaseDispatch,  updateUnbilledExpenses,
        dispatchFreightBillsRef, setHomeDate, handleFreightClick,navigate  } = useDispatch();
	const { printAllDispatch, setShowEmail, dispatchPrintPopUpVisible, setDispatchPrintPopUpVisible } = useDispatchPrintContext();


    const { gearedUser,company} = UserAuth();

    const [sortOption, setSortOption]=useState(localStorage.getItem('sortName'));
    const [inputValues, setInputValues]=useState({JobDate:jobDate, JobDateValue:new Date(jobDate)});
    const tempDriver = {TruckType:{Name:'No Truck Type'}}
    const sortOptions = [
		{text: "None", value: "None" },
		{text: "Customer", value: "Customer" },
		{text: "Job Type", value: "Job Type" },
		{text: "Load Site", value: "Load Site" },
		{text: "Dump Site", value: "Dump Site" },
		{text: "Material", value: "Material" } 
	];

    const filteredDispatchesRef = useRef(null);
    const mainCompanyRef = useRef(null);
  

    console.log('Re-rendering DispatchHome State',company)


    useEffect(() => {
        mainCompanyRef.current = company;
    }, [company]);

    useEffect(() => {
        setDispatchState('DispatchHome');
    }, []);

    useEffect(() => {
        console.log('WE RUNNITNE JOB DATE USEEFFECT ', jobDate)
        console.log('and home date = ', homeDate);
        if(jobDate){
            if(jobDate!==homeDate){
            console.log('and now we setting home date!@')
                setHomeDate(jobDate);
            }
        }
    }, [jobDate]);

   const filteredDispatches = homeDispatches.filter(item => item.QueryDate === homeDate).sort((a, b) => {
        const startTimeA = a.StartTime ? a.StartTime : ''; // Handle empty/null as empty string
        const startTimeB = b.StartTime ? b.StartTime : ''; // Handle empty/null as empty string
        
        // Sort null/empty times to the end (assuming you want them at the bottom)
        if (!startTimeA && startTimeB) return 1;
        if (startTimeA && !startTimeB) return -1;
        if (!startTimeA && !startTimeB) return 0;

        // If both have valid times, sort them in ascending order (assuming string comparison)
        return startTimeA.localeCompare(startTimeB);
    });
    filteredDispatchesRef.current=filteredDispatches;


    const openDispatch =(event,inst) => {
        console.log(' id = ' , event);
        setDispatch(event);
        dispatchObjectRef.current = {...event};
        const encodedDate = encodeURIComponent(event.QueryDate); 
        navigate(`/dispatch/edit/${event.ID}/${event.JobID}/${encodedDate}/DispatchEdit`);
    
    }
	const handlePrintDispatch = () => {
		printAllDispatch(filteredDispatchesRef.current,homeFreightBillsRef.current, mainCompanyRef.current,homeDate);
		setDispatchPrintPopUpVisible(false);
	}
    const releaseDispatch =(disp,freights) => {
        console.log(' id = ' , disp);
        setDispatch(disp);
        dispatchObjectRef.current = {...disp};
        dispatchFreightBillsRef.current=[...freights];

        if(disp.Released){
            const encodedDate = encodeURIComponent(disp.QueryDate); 
            navigate(`/dispatch/edit/${disp.ID}/${disp.JobID}/${encodedDate}/DispatchStatus`);
        }else checkReleaseDispatch(disp, freights);
         
    
    }
    
    const releaseAllDispatch = async() => {
        console.log('filteredDispatchesRef.current = ', filteredDispatchesRef.current)
        for (let q = 0; q < filteredDispatchesRef.current.length; q++) {
           
           if(!filteredDispatchesRef.current[q].Released){
                let freights = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === filteredDispatchesRef.current[q].ID).sort((a, b) => a.loadOrder - b.loadOrder);
                console.log('runnign teh first relase for  ',filteredDispatchesRef.current[q])
                await checkReleaseDispatch(filteredDispatchesRef.current[q], freights);
           }
        }
       
        
    
    }

    const changeSortOption = (value)=>{
        setSortOption(value);
        localStorage.setItem('sortName', value);
    }

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        let tempDate= formatDate(value, '/', 'YYYY/MM/DD');
        const encodedDate = encodeURIComponent(tempDate); 
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));  
        navigate('/dispatch/home/'+ encodedDate, { replace: true })
    };



    const showPrintPopUp = (email) => {
		setShowEmail(email);
		setDispatchPrintPopUpVisible(true);
    };

   
 
    function groupAndSortDispatches(dispatches, field) {
        // Group dispatches by the selected field
        const groupedDispatches = dispatches.reduce((acc, dispatch) => {
            // Determine the grouping key based on the field
            const key = (() => {
                switch (field) {
                    case 'Customer':
                        return dispatch.Account?.Name || 'No Customer';
                    case 'Job Type':
                        return dispatch.JobType || 'None';
                    case 'Load Site':
                        return dispatch.LoadSite?.Name || 'No Load Site';
                    case 'Dump Site':
                        return dispatch.DumpSite?.Name || 'No Dump Site';
                    case 'Material':
                        return dispatch.Material?.Name || 'No Material';
                    default:
                        return 'No Group';
                }
            })();
            
            // Create a new group if it doesn't exist
            if (!acc[key]) acc[key] = [];
            acc[key].push(dispatch);
            return acc;
        }, {});
    
        // Convert groups to an array and sort alphabetically by the grouping key
        return Object.entries(groupedDispatches)
            .sort(([aKey], [bKey]) => aKey.localeCompare(bKey))
            .map(([groupKey, groupItems]) => ({
                groupKey,
                // Sort the group items by StartTime (if present) within each group
                groupItems: groupItems.sort((a, b) => {
                    const startTimeA = a.StartTime ? a.StartTime : ''; // Handle empty/null as empty string
                    const startTimeB = b.StartTime ? b.StartTime : ''; // Handle empty/null as empty string
                    // Sort null/empty times to the end (assuming you want them at the bottom)
                    if (!startTimeA) return 1;
                    if (!startTimeB) return -1;
    
                    // Compare the times as strings (lexicographically)
                    return startTimeA.localeCompare(startTimeB);
                })
            }));
    }
  
    const groupedDispatches =   groupAndSortDispatches( filteredDispatches, sortOption);
    console.log('groupedDispatches = ',groupedDispatches)

    if (!gearedUser)  return <div>Loading...</div>;
    else if(gearedUser.selectedOrgName) return (
        <div >                          
            {homeDate &&(<DispatchCalendar printAllDispatch={showPrintPopUp}  releaseAllDispatch={ releaseAllDispatch}/>)}
            <div className="mbsc-row mbsc-grid">
                <div className="p-inputgroup mbsc-col-xl-4 mbsc-col-md-6" >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Sort By: </span> 
                    <Dropdown value={sortOption} onChange={(e) => changeSortOption(e.value)}  options={sortOptions} optionLabel="text"
                        placeholder="None" className="w-full md:w-14rem" />
                </div>
                <div className="p-inputgroup mbsc-col-xl-5 mbsc-offset-xl-2 mbsc-col-md-6"  >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Date </span> 
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}}value={inputValues.JobDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'JobDate',e.value )} />
                </div>
            </div>
            {groupedDispatches.map(({ groupKey, groupItems }, groupIndex) => (
                <div key={groupIndex}>
                    {groupKey && (
                        <Panel header={groupKey} className="mb-3"  >
                        <div className="mbsc-row" style={{ paddingLeft: ".5em", width: "100%" }}>
                            {groupItems.map((item, index) => {
                                const originalIndex = homeDispatches.findIndex(dispatch => dispatch.ID === item.ID);

                                return (
                                    
                                    <DispatchCard
                                        key={item.ID}
                                        dispatch={{ item }}
                                        originalIndex={originalIndex}
                                        homeFreightBills={homeFreightBills}
                                        onUpdateFreightBills={(updatedFreightBills) => handleUpdateFreightBills(item, updatedFreightBills)}
                                        showDrivers={true}
                                        showAssign={false}
                                        releaseDispatch= {releaseDispatch }
                                        onClick={openDispatch}
                                        onFreightClick={handleFreightClick}
                                        driver={tempDriver}
                                        updateUnbilledExpenses={updateUnbilledExpenses}
                                    />
                                );
                            })}
                            </div>
                        </Panel>
                    )}
                 </div>
            ))}
			<DispatchPrintPopUp setDispatchPrintPopUpVisible={setDispatchPrintPopUpVisible} dispatchPrintPopUpVisible={dispatchPrintPopUpVisible}  printDispatch={handlePrintDispatch}/>
        </div>
    )
     


} 


