
import React, {useEffect, useRef, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import InputTextParent from '../InputComponents/InputTextParent'; 
import { InputNumber} from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import {InputText} from 'primereact/inputtext';
import { useGlobal } from '../../context/GlobalContext'
import { UserAuth } from '../../context/AuthContext'

const EmployeePopUp = (props) => {
    const { employeeVisible, setEmployeeVisible, employee} = useGlobal();
    
    const { updateDocument, addDocument} = UserAuth();
    const [phoneObject, setPhoneObject] = useState({ Phone1: '', Phone2: '', Phone3: '' });
    const [inputValues, setInputValues] = useState({});
    const departments = [ 
		{text :'Admin',value: 'Admin'},
		{text :'Dispatch',value: 'Dispatch'},
		{text :'Foreman',value: 'Foreman'},
		{text :'Billing',value: 'Billing'} 
	];

    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const isFormDirty = useRef(false);

    const closeEmployeePopUp = () => {
      
            console.log('about to close isFormDirty = '+ isFormDirty.current)
            if(isFormDirty.current){
                if (window.confirm("Changes you made are not saved. Are you sure you want to close?"))  {
                    isFormDirty.current=false;
                    setEmployeeVisible(false);
                }  
            }else  setEmployeeVisible(false);
            
    
       
    };
    console.log('employee pop up boi=' , employee)
    useEffect(()=>{
        const handleBeforeUnload = (event) => {
            if (isFormDirty.current) {
                event.preventDefault();
                event.returnValue = ""; // Required for some browsers
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    },[]);
    useEffect(() => {
        if (employee && Object.keys(employee).length > 0) {
           
            setInputValues({
                ID:employee.ID,
                FirstName:employee.FirstName,
                LastName:employee.LastName,
                Name: employee.Name,
          
                Email:employee.Email,
                Department:employee.Department
            });
            setPhoneObject({...employee.PhoneObject})
            console.log('employee = ', employee)
        }
    }, [employee]);

     const handleFieldChange = (fieldName, value) => {
        let updateObject ={ [fieldName]: value };
        if (fieldName === 'FirstName' || fieldName === 'LastName') {
            const newFirstName = fieldName === 'FirstName' ? value : inputValues.FirstName;
            const newLastName = fieldName === 'LastName' ? value : inputValues.LastName;
            updateObject.Name = `${newFirstName} ${newLastName}`;
        }
        isFormDirty.current=true;
        setInputValues((prev) => ({ ...prev,...updateObject}));
      
    };
    const handlePhoneChange = (field, value, nextRef) => {
        const maxLength = field === 'Phone3' ? 4 : 3;
        if (value.length <= maxLength) {
            isFormDirty.current=true;
            setPhoneObject((prev) => ({ ...prev, [field]: value }));
            if (value.length === maxLength && nextRef) {
                nextRef.current.focus();
            }
        }
    };
    const handleSaveEmployee = ()=>{
        if(inputValues.Name===''){
            alert('Please enter a Name for this employee before saving');
            return;
        }
        isFormDirty.current=false;
        let updatedObject ={...inputValues};
        updatedObject.PhoneObject = {...phoneObject};
        updatedObject.displayPhone =updatedObject.PhoneObject.Phone1+'-'+updatedObject.PhoneObject.Phone2+'-'+ updatedObject.PhoneObject.Phone3;
        if(employee.ID) updateDocument( updatedObject, employee.ID, 'Employees');
        else{
        
			updatedObject ={...inputValues};
			updatedObject.PhoneObject = {...phoneObject};
			updatedObject.displayPhone =updatedObject.PhoneObject.Phone1+'-'+updatedObject.PhoneObject.Phone2+'-'+ updatedObject.PhoneObject.Phone3;

			for (let key in inputValues) {
				if (inputValues.hasOwnProperty(key)) {
					updatedObject[key] = inputValues[key];
				}
			}
			addDocument(updatedObject, 'Employees')
		
        } 
       
		isFormDirty.current=false;
		setEmployeeVisible(false);
    }
  const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                       <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() =>closeEmployeePopUp()} />
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Save" icon="pi pi-check" onClick={() => handleSaveEmployee()}  />
     
        </div>
    
    );
return(
    <Dialog 
		header="Employee Details" 
		visible={employeeVisible} 
		style={{ width: '55vw' }} 
		breakpoints={{ '960px': '75vw', '641px': '100vw' }} 
		footer={footerContent} 
		onHide={closeEmployeePopUp}
	>
         
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon"> First Name</span>
                <InputTextParent value={inputValues.FirstName} onChange={(e) => handleFieldChange('FirstName', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Last Name</span>
                <InputTextParent value={inputValues.LastName} onChange={(e) => handleFieldChange('LastName', e.target.value)} />
            </div>
            

            <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Phone</span>
                    <InputText maxLength={3} value={phoneObject.Phone1} onChange={(e) => handlePhoneChange('Phone1', e.target.value, inputRef2)}  />-
                    <InputText ref={inputRef2} maxLength={3} value={phoneObject.Phone2} onChange={(e) => handlePhoneChange('Phone2', e.target.value, inputRef3)}  />-
                    <InputText ref={inputRef3} maxLength={4} value={phoneObject.Phone3} onChange={(e) => handlePhoneChange('Phone3', e.target.value, null)} />
                </div>
                
       
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Email</span>
                <InputTextParent value={inputValues.Email} onChange={(e) => handleFieldChange('Email', e.target.value)} />
            </div>
            <div className="p-inputgroup">
                <span className="p-inputgroup-addon">Department</span>
                <Dropdown 
					value={inputValues.Department} 
					onChange={(e) => handleFieldChange('Department', e.value)} 
					options={departments} 
					optionLabel="text"
					placeholder="Select a Department" 
					className="w-full md:w-14rem" 
					/>
            </div>

                  
    </Dialog>
);
};

export default EmployeePopUp;