import React, {createContext, useEffect, useCallback, useContext, useRef, useState} from 'react'
import { UserAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase';
import { onSnapshot, query, collection, where } from 'firebase/firestore';

const ReportBuilderContext = createContext();
export const ReportBuilderContextProvider = ({ children }) => {

	const {formatDate, gearedUser} = UserAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [reportData, setReportData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [reportTable, setReportTable] = useState(null);

	const reportDataRef = useRef([]);
	const fullReportDataRef = useRef([]);
	const tableListenerRef = useRef(null);
	const reportTableRef = useRef(null);


	useEffect(()=>{
		reportTableRef.current = {...reportTable};
	},[reportTable]);

	var todaysDate = new Date();
    let startDate =new Date();
 
    let formattedTodaysDate= formatDate(todaysDate, '/', 'YYYY/MM/DD');
    startDate.setDate(todaysDate.getDate() - 30);

    const [inputValues, setInputValues]=useState({
        ReportDateValue: todaysDate,
        EndDateValue:todaysDate,
        StartDateValue:startDate,
        Account:{ ID:'', Name:'No Account' },
        ReportDate: formatDate(todaysDate, '/', 'MM/DD/YYYY'),
        EndDate:formattedTodaysDate,
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'),
 
        
    });

    const checkObject = useCallback((object)=>{
  
        let shouldAddObject = true;
        object.AccountName = object.Account ? object.Account.Name : '';
        object.TruckName = object.Subhauler ? object.Truck: object.Truck ? object.Truck.Name : '';
        object.LoadSiteName = object.LoadSite ? object.LoadSite.Name : '';
        object.DumpSiteName = object.DumpSite ? object.DumpSite.Name : '';
        object.Balance = object.Balance ?  object.Balance.Total : 0;
        object.Total =  object.Total ?  object.Total.Total : 0;
        object.CompanyID = object.Company ? object.Company.ID : '';
        object.AccountID  = object.Account ? object.Account.ID : '';
        
      
        for (const [field, value] of Object.entries(reportTableRef.current)) {
          
            if (field === 'Columns' || value === 'null' || field==='Type' || field==='tableParams' || field==='groupByDrivers' || field==='groupByDays' || field==='totals' || field==='separateScaleTags' || field==='Name' || field==='ID' || field==='timestamp') continue;
         
            if (object[field] !== value) {
                console.log('IS THE OBJECT FIALING HTOUHG= ',object[field])
                shouldAddObject = false;
                break;
            }
           
            
        }
        return shouldAddObject;
    },[reportTable]);


	const runInvoiceReport = ()=>{
  
        setIsLoading(true);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Invoices`;

        let tableQuery = query( collection(db, queryName), where("QueryDate", ">=",inputValues.StartDate),  where("QueryDate", "<=", inputValues.EndDate) );
        if (inputValues.Account.ID !== '' && inputValues.Account.ID)  tableQuery = query(tableQuery, where("Account.ID", "==", inputValues.Account.ID));

        if(tableListenerRef.current)tableListenerRef.current();
        fullReportDataRef.current= [];
        reportDataRef.current =[];
   
      
        console.log('WE RUNNING REPORT!!' +inputValues.StartDate + ' AND END DATE ' + inputValues.EndDate )
        tableListenerRef.current=onSnapshot(tableQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                var tempReportObject = change.doc.data();
                tempReportObject.ID = change.doc.id;
				console.log('found an object inside the firestore listener', tempReportObject)
                if (tempReportObject.JobDate) tempReportObject.jobDateValue = new Date(tempReportObject.JobDate);
                if (change.type === "added") {
                    fullReportDataRef.current.push({...tempReportObject});
                    if(checkObject(tempReportObject)) reportDataRef.current.push({...tempReportObject})
                }
                if (change.type === "modified") {
					
                    let index = reportDataRef.current.findIndex(data => data.ID === tempReportObject.ID);
					tempReportObject.CompanyID = tempReportObject.CompanyID  ? tempReportObject.CompanyID : tempReportObject.Company ? tempReportObject.Company.ID : '';
                    if(index!==-1)   if(checkObject(tempReportObject))reportDataRef.current[index]={...tempReportObject}
                }
                if (change.type === "removed") {
                    let index = reportDataRef.current.findIndex(data => data.ID === tempReportObject.ID);
					console.log('removing the object with ID = ' + tempReportObject.ID)
                    if(index!==-1)reportDataRef.current.splice(index,1)
                }
            })
      
			console.log('reportDataRef.current length= ' + reportDataRef.current.length)
			console.log('reportDataRef.current = ' ,reportDataRef.current)
			setReportData([...reportDataRef.current]);
			setFilteredData([...reportDataRef.current]);
         
            setIsLoading(false);
           
        })
    }
	return (
        <ReportBuilderContext.Provider value={{ 
			inputValues, setInputValues, 
			isLoading, setIsLoading,

			runInvoiceReport, 
			reportData, setReportData, 
			filteredData, setFilteredData, 
			reportTable, setReportTable
         }}>
            {children}
        </ReportBuilderContext.Provider>
    );
}
export const useReportBuilder= () => {
    return useContext(ReportBuilderContext);
};
