import React from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput';

const InputTableCell = ({ width, value, onChange, borderStyle, dataLabel, type = 'text', disabled = false, suggestions }) => {
    if(!borderStyle) borderStyle = '1px 1px 1px 1px';
    
	
    const handleFocus = (e) => {
        setTimeout(() => {
            const input = e.target;
            if (input.select) {
                if (type === 'currency') {
                    // For currency fields, we need to handle the input specially
                    const inputValue = input.value;
                    const startPos = inputValue.indexOf('$') + 1; // Position after the $ symbol
                    if (input.setSelectionRange) {
                        input.setSelectionRange(startPos, inputValue.length);
                    } else {
                        input.select();
                    }
                } else {
                    input.select();
                }
            }
        }, 0);
    };

    const renderInput = () => {
        switch(type) {
            case 'checkbox':
                return (
                    <Checkbox 
                        style={{
                            height:"1.75em", 
                            padding:"0", 
                            display: "flex", 
                            justifyContent: "center"
                        }}
                        checked={value} 
                        onChange={onChange}
                        disabled={disabled}
                    />
                );
            case 'currency':
                return (
                    <InputNumber 
                        onFocus={handleFocus}
                        value={value} 
                        onChange={onChange}
                        mode="currency"
                        currency="USD"
                        style={{ height:"2em", width: '100%' }}
                        disabled={disabled}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                    />
                );
			case 'date':
				return (
					<Calendar 
						value={value} 
						onChange={onChange}
						style={{width:"100%"}} 
					/>
				)
            case 'number':
                return (
                    <InputNumber 
                        onFocus={handleFocus} 
                        value={value} 
                        onChange={onChange}
                        style={{ height:"2em", width: '100%' }}
                        disabled={disabled}
                    />
                );
            case 'autocomplete':
                return (
                    <AutoCompleteInput 
                        fieldName={dataLabel}
                        field="Name"
                        value={value}
                        suggestions={suggestions}
                        setValue={(prev) => {}}  // Not needed for direct onChange
                        handleFieldChange={onChange}
                        showLabel={false}
                        disabled={disabled}
                    />
                );
            default:
                return (
                    <InputText 
                        onFocus={handleFocus}
                        value={value} 
                        onChange={onChange}
                        style={{ height:"2em", width: '100%' }}
                        disabled={disabled}
                    />
                );
        }
    };

    return (
        <td 
            data-label={dataLabel} 
            style={{ 
                width: width, 
                padding: '0', 
                borderWidth: borderStyle, 
                borderColor:'#bcbcd1', 
                borderStyle:'solid',
                textAlign: type === 'currency' ? 'right' : 'center'
            }}
        >
            {renderInput()}
        </td>
    );
};

export default InputTableCell; 