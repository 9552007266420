import React,{useEffect, useRef} from 'react';

import { useInvoice } from './context/InvoiceContext';
import InvoiceComponent from './InvoiceComponent';
import { db } from '../../firebase';
import { doc,  onSnapshot, query, collection, where} from 'firebase/firestore';
import { UserAuth } from '../../context/AuthContext';
import { useInvoiceCalc } from './hooks/useInvoiceCalc';
import {useParams } from 'react-router-dom';
const InvoiceEdit = (props) => {
    const { id } = useParams();  
    const { gearedUser } = UserAuth();
    const { setInvoice, invoice,invoicesRef   } = useInvoice();
    const { calcInvoiceTotal } = useInvoiceCalc();
    const freightsRef = useRef(null);
    const freightListenerRef =useRef(null);
    
    useEffect(()=>{
        freightsRef.current=[];
        fetchInvoice(id);
        queryFreightBills(id);
    },[])

    const fetchInvoice = async (id) => {
        console.log('runnign fetch invoice ya bois!!')
        return new Promise((resolve, reject) => {
            console.log(' runnign fetch Invoice = ', invoice)
            console.log('id = ', id)
			invoicesRef.current =[];
            console.log(' runnign fetch Invoice = ' + invoice.ID)
            if (id === invoice?.ID)   return resolve(invoice);
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Invoices`, id);
            onSnapshot(docRef, async (docSnap) => {
         
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('source =' +source)
                console.log('docSnap.exists() = ' ,docSnap)
                if (docSnap.exists() && source === "Server") {
             
                    let tempInvoice = docSnap.data();
                    tempInvoice.ID=docSnap.id;
                    console.log('tempinvoice = ', tempInvoice)
					let calcInvoice = calcInvoiceTotal({ ...tempInvoice });
                    calcInvoice.FreightBills=[...freightsRef.current];
                    setInvoice(calcInvoice);
					invoicesRef.current.push(calcInvoice);
                    console.log("set invoice to ",calcInvoice);
                    return resolve(tempInvoice);
                }
            });
        });
    };
    const queryFreightBills = (id) => {
       
        if (freightListenerRef.current) freightListenerRef.current();
 
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        const q = query(collection(db, queryName), where("Invoice", "==", id));

        freightListenerRef.current = onSnapshot(q, (querySnapshot) => {
            console.log('EXPENSE HOME SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {
                const tempFreight = change.doc.data();
                tempFreight.ID = change.doc.id;
                tempFreight.realJobDate = new Date(tempFreight.JobDate);

                if (change.type === "added") {
                    freightsRef.current.push(tempFreight);
                }
                if (change.type === "modified") {
                    const freightIndex = freightsRef.current.findIndex((f) => f.ID === tempFreight.ID);
                    freightsRef.current[freightIndex] = tempFreight;
                }
                if (change.type === "removed") {
                    const freightIndex = freightsRef.current.findIndex((f) => f.ID === tempFreight.ID);
                    freightsRef.current.splice(freightIndex,1);
                }
            });

            console.log('setting full home freightsRef.current = ', freightsRef.current);
            setInvoice ((prev) => ({ ...prev, FreightBills: freightsRef.current }));   
            // Assuming there's a state setter or other handler for freightsRef.current
       

    });
};


return(
    <React.Fragment>
    {invoice.Account && (<InvoiceComponent/>
    )}
    </React.Fragment>
);
};

export default InvoiceEdit;