import React, { useState} from 'react';
import { UserAuth } from '../../context/AuthContext';
import TruckDriverLine from '../ListComponents/TruckDriverLine';
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { db } from '../../firebase';
import { doc,  writeBatch} from 'firebase/firestore';

const PriorityHome = (props) => {
    const { drivers,outsideTrucks, gearedUser  } = UserAuth();
    const [activeIndex, setActiveIndex]=useState(0);
  
    const updatedDrivers = drivers ? drivers.filter(driver => driver.Status === 'Active') // Filter active drivers
    .map(driver => {
        let updatedDriver = { ...driver };
        updatedDriver.displayCapabilities=[];
        for (var i = 0; i < updatedDriver.TruckTypes?.length; i++) updatedDriver.displayCapabilities.push(updatedDriver.TruckTypes[i].TruckCode);
        for(var j=0; j<updatedDriver.Capabilities?.length; j++)updatedDriver.displayCapabilities.push(updatedDriver.Capabilities[j])

        return updatedDriver;
    }) : [];

    const updatedOutsideTrucks = outsideTrucks ? outsideTrucks.filter(outsideTruck => outsideTruck.Status === 'Active') // Filter active drivers
        .map(outsideTruck => {
        let updatedOutsideTruck = { ...outsideTruck };
        updatedOutsideTruck.displayCapabilities=[];
        for (var i = 0; i < updatedOutsideTruck.TruckTypes?.length; i++) updatedOutsideTruck.displayCapabilities.push(updatedOutsideTruck.TruckTypes[i].TruckCode);
        for(var j=0; j<updatedOutsideTruck.Capabilities?.length; j++)updatedOutsideTruck.displayCapabilities.push(updatedOutsideTruck.Capabilities[j])

        return updatedOutsideTruck;
    }) : [];

    let sortedDrivers =updatedDrivers ? updatedDrivers.sort((a, b) => a.Priority - b.Priority) : [];
    let sortedOutsideTrucks =updatedOutsideTrucks ? updatedOutsideTrucks.sort((a, b) => a.Priority - b.Priority) : [];

    console.log('sortedDrivers =' , sortedDrivers)

    const onDriverDragEnd = async(result) => {
        if (!result.destination) return;  
        const reorderedItems = Array.from([...sortedDrivers]);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        let tempPriority= 1;
        const batch = writeBatch(db);
        for(let p=0; p<reorderedItems.length; p++){
            if(reorderedItems[p].Priority!==tempPriority){
                let driverRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Drivers',   reorderedItems[p].ID);
                reorderedItems[p].Priority= tempPriority;
                batch.update(driverRef, { "timestamp": Date.now(), Priority:  tempPriority});
            } 
            tempPriority++;
        }
        await batch.commit();
        sortedDrivers=reorderedItems; 
        console.log('reordered items =', reorderedItems)
    };
    const onDedicatedDragEnd = async(result) => {
        if (!result.destination) return;  
        const reorderedItems = Array.from([...sortedOutsideTrucks]);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        let tempPriority= 1;
        const batch = writeBatch(db);
        for(let p=0; p<reorderedItems.length; p++){
            if(reorderedItems[p].Priority!==tempPriority){
                let outsideTruckRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/OutsideTrucks',   reorderedItems[p].ID);
                reorderedItems[p].Priority= tempPriority;
                batch.update(outsideTruckRef, { "timestamp": Date.now(), Priority:  tempPriority});
            } 

            tempPriority++;
        }
        await batch.commit();
        sortedOutsideTrucks=reorderedItems; 
        console.log('reordered items =', reorderedItems)
    };

    const handleDriverClick=()=>{
        console.log('clicking on driver')
    }

    return (
        <div className='mbsc-grid' style={{padding:"0"}}>
            <Panel header='Priority' className='mbsc-col-lg-6 mbsc-offset-lg-3' style={{padding:"0"}}>
                <TabView  style={{margin:"0"}} activeIndex={activeIndex}  >
                    <TabPanel header="Company Drivers" style={{marginTop:"0"}} >  
                        <DragDropContext onDragEnd={onDriverDragEnd}>
                            <Droppable droppableId="priority">
                                {(provided) => (
                                    <ul className='tableList' style={{width:"100%"}} ref={provided.innerRef} {...provided.droppableProps}>
                                        {sortedDrivers.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <TruckDriverLine key={item.ID}   driver={{item}}   index={{index}}  onClick={handleDriverClick}  >  </TruckDriverLine>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TabPanel>
                    <TabPanel header="Dedicated" style={{marginTop:"0"}} >  
                        <DragDropContext onDragEnd={onDedicatedDragEnd}>
                            <Droppable droppableId="priority">
                                {(provided) => (
                                    <ul className='tableList' style={{width:"100%"}} ref={provided.innerRef} {...provided.droppableProps}>
                                        {sortedOutsideTrucks.map((item, index) => (
                                            <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                                {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <TruckDriverLine key={item.ID}   driver={{item}}   index={{index}}  onClick={handleDriverClick}  >  </TruckDriverLine>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TabPanel>
                </TabView>
            </Panel>
        </div>
    );
};

export default PriorityHome;
