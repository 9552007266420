import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';
import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faMinusCircle, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';


const DriversHome = (props) => {
    const { drivers, deleteDocument, formatDate, } = UserAuth();
    const { showDriverPopUp } = useGlobal();
    const [sortedDrivers, setSortedDrivers] = useState([]);

    useEffect(() => {
        // Sort the drivers array by Name
        
    const updatedDrivers = drivers ? drivers.map(driver => {
        driver.realHiredDate = driver.HiredDate ? new Date(driver.HiredDate) : '';
        
        return driver;
    }) : [];
        console.log('updateDated drivesr = ', updatedDrivers)
        const sorted = [...updatedDrivers].sort((a, b) => {
            if (a.Name < b.Name) return -1;
            if (a.Name > b.Name) return 1;
            return 0;
        });
        setSortedDrivers(sorted);
    }, [drivers]);



    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Truck.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Trailer.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        displayPhone: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        Status: { value: null, matchMode: FilterMatchMode.EQUALS },
        // Don't set anything here for Account.Name initially
    });

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
         <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

const handleDelete = async (rowData) => {
    try {
        if (window.confirm("Are you sure you want delete this Driver?")) {
            deleteDocument(rowData,'Drivers' )
        }  
    } catch (error) {  console.error("Error removing document: ", error);  }
   
};
    const [globalFilterValue, setGlobalFilterValue] = useState('');
  
    const [statuses] = useState(['Active','Inactive']);

    const getSeverity = (status) => {
        switch (status) {
          case 'Active':
            return 'success';
          case 'Inactive':
            return 'danger';
        }
    };


    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({
            ...prevFilters,
            global: { ...prevFilters.global, value }
        }));
        setGlobalFilterValue(value);
    };

    const renderHeader = () => (
     
        <div className="flex justify-content-end mbsc-row ">
            <span>Drivers</span>
            <IconField iconPosition="left" className="homeSearch">
                <InputIcon className="pi pi-search" />
                <InputText style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit = (rowData) => {
        console.log('driver = ', rowData)
      showDriverPopUp(rowData);
    };
    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.Status} severity={getSeverity(rowData.Status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };


    const booleanBodyTemplate = (rowData, field) => (
        <i className={classNames('pi', {
            'true-icon pi-check-circle': rowData[field],
            'false-icon pi-times-circle': !rowData[field]
        })} />
    );

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown    
			 value={options.value !== null ? options.value : undefined} 
			 options={statuses}
			  onChange={(e) => options.filterApplyCallback(e.value)} 
			  itemTemplate={statusItemTemplate}
			   placeholder="Select One"
			    className="p-column-filter" 
				showClear 
				style={{ minWidth: '12rem' }} 
			/>
        );
    };
    const dateBodyTemplate = (rowData, fieldName) => {
    
        return formatDate( rowData[fieldName], '/', 'MM/DD/YYYY');
    };
    const booleanFilterTemplate = (options) => (
        <TriStateCheckbox style={{ borderColor: "#d1d5db", background: "white" }} value={options.value || undefined} onChange={(e) => options.filterApplyCallback(e.value)} />
    );

    const accountFilterFunction = (value, filter) => {
        return value?.Name?.toLowerCase().includes(filter.toLowerCase()) ?? false;
    };

 
const header = renderHeader();

    return (
        <div className="card">
            <DataTable 
                value={sortedDrivers} 
                paginator 
                rows={25} 
                dataKey="ID" 
                filters={filters} 
                header={header} 
                filterDisplay="row" 
                emptyMessage="No drivers found."
            
            >
          
                <Column pt={{root: { 'data-label': 'Edit' }}} header="Edit" body={editBodyTemplate} />
       
                
                <Column pt={{root: { 'data-label': 'Name' }}} field="Name" header="Name" filter filterPlaceholder="Search by name" />
                <Column pt={{root: { 'data-label': 'Truck' }}} header="Truck" filter filterField="Truck?.Name" filterPlaceholder="Search by Truck" body={(rowData) => rowData.Truck?.Name || 'N/A'}/>
                <Column pt={{root: { 'data-label': 'Trailer' }}} header="Trailer" filter filterField="Trailer?.Name" filterPlaceholder="Search by Trailer" body={(rowData) => rowData.Trailer?.Name || 'N/A'}/>
                <Column pt={{root: { 'data-label': 'Hired Date' }}} field="realHiredDate" header="Hired Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'realHiredDate')} filterPlaceholder="Search by Hired Date" />
                <Column pt={{root: { 'data-label': 'Email' }}} field="Email" header="Email" filter filterPlaceholder="Search by Email" />
                <Column pt={{root: { 'data-label': 'Phone' }}} field="displayPhone" header="Phone" filter filterPlaceholder="Search by phone" />
                <Column pt={{root: { 'data-label': 'Status' }}} field="Status" header="Status" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
            </DataTable>
        </div>
    );
};

export default DriversHome;
