import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useStripe } from '../../context/StripeContext';

const BillingManagement = () => {
    const { currentSubscription, updatePaymentMethod, cancelSubscription, billingHistory, loading, error } = useStripe();
    
    const [expandedRows, setExpandedRows] = useState(null);

    const formatDate = (isoString) => {
        return new Date(isoString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const statusTemplate = (rowData) => {
        const getStatusClass = (status) => {
            switch (status.toLowerCase()) {
                case 'paid':
                    return 'bg-green-100 text-green-700';
                case 'pending':
                    return 'bg-yellow-100 text-yellow-700';
                case 'failed':
                    return 'bg-red-100 text-red-700';
                default:
                    return 'bg-gray-100 text-gray-700';
            }
        };

        return (
            <span className={`px-3 py-1 rounded-full text-sm ${getStatusClass(rowData.status)}`}>
                {rowData.status}
            </span>
        );
    };

    return (
        <div className="billing-management p-5">
            <div className="grid">
                {/* Current Subscription Panel */}
                <div className="col-12 lg:col-6 p-3">
                    <Panel header="Current Subscription" className="h-full">
                        <div className="current-subscription p-4">
                            <h2 className="text-2xl font-bold mb-4">{currentSubscription?.planName || 'No active subscription'}</h2>
                            {currentSubscription && (
                                <>
                                    <div className="mb-4">
                                        <p className="text-gray-600 mb-2">Status: <span className="font-semibold">{currentSubscription.status}</span></p>
                                        <p className="text-gray-600 mb-2">Next billing date: <span className="font-semibold">{formatDate(currentSubscription.nextBillingDate)}</span></p>
                                        <p className="text-gray-600">Amount: <span className="font-semibold">{formatCurrency(currentSubscription.amount)}/month</span></p>
                                    </div>
                                    <div className="flex gap-2">
                                        <Button 
                                            label="Update Payment Method" 
                                            className="p-button-outlined"
                                            onClick={updatePaymentMethod}
                                            loading={loading}
                                        />
                                        <Button 
                                            label="Cancel Subscription" 
                                            className="p-button-danger p-button-outlined"
                                            onClick={cancelSubscription}
                                            loading={loading}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </Panel>
                </div>

                {/* Payment Method Panel */}
                <div className="col-12 lg:col-6 p-3">
                    <Panel header="Payment Method" className="h-full">
                        <div className="payment-method p-4">
                            <div className="flex align-items-center mb-4">
                                <i className="pi pi-credit-card mr-2" style={{ fontSize: '1.5rem' }}></i>
                                <div>
                                    <p className="font-semibold mb-1">•••• •••• •••• 4242</p>
                                    <p className="text-gray-600">Expires 12/25</p>
                                </div>
                            </div>
                            <Button 
                                label="Update Card" 
                                className="p-button-outlined"
                                onClick={updatePaymentMethod}
                                loading={loading}
                            />
                        </div>
                    </Panel>
                </div>

                {/* Billing History Panel */}
                <div className="col-12 p-3">
                    <Panel header="Billing History" className="h-full">
                        <DataTable 
                            value={billingHistory}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => setExpandedRows(e.data)}
                            rowExpansionTemplate={(data) => (
                                <div className="p-3">
                                    <h5>Invoice Details</h5>
                                    <p>Invoice Number: {data.invoiceNumber}</p>
                                    <p>Description: {data.description}</p>
                                </div>
                            )}
                            className="p-datatable-sm"
                            paginator
                            rows={5}
                            emptyMessage="No billing history available"
                        >
                            <Column expander style={{ width: '3em' }} />
                            <Column field="date" header="Date" body={(rowData) => formatDate(rowData.date)} />
                            <Column field="description" header="Description" />
                            <Column field="amount" header="Amount" body={(rowData) => formatCurrency(rowData.amount)} />
                            <Column field="status" header="Status" body={statusTemplate} />
                        </DataTable>
                    </Panel>
                </div>
            </div>

            {error && (
                <div className="p-message p-message-error mt-4">
                    {error}
                </div>
            )}
        </div>
    );
};

export default BillingManagement; 