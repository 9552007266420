import React, {useRef} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { Dialog } from 'primereact/dialog';

import { Button } from 'primereact/button';

const SignaturePopUp = ({visible, setVisible, handleSaveSignature})=> {
    const signaturePadRef = useRef(null);
    let signature;

    const updateSignature = () => {
        signature =signaturePadRef.current.toDataURL();
    }
    const saveSignature = ()=>{
        handleSaveSignature(signaturePadRef.current.toDataURL());
        setVisible(false);
    }
    const closeSignaturePopUp = ()=>{
        setVisible(false)
    };
     const footerContent = (
      
            <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                    <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => setVisible(false)} />
                    <Button style= {{fontSize:'1.5em', width:'9em'}} label="Sign" icon="pi pi-check" onClick={() => saveSignature()}  />
         
            </div>
        
        );
    return (
      <Dialog header="Signature" visible={visible} style={{ width: '35vw' }} breakpoints={{ '960px': '55vw', '641px': '80vw' }} footer={footerContent} onHide={ closeSignaturePopUp}>
            < div className="mbsc-grid" >
                <SignatureCanvas onEnd={updateSignature} ref={signaturePadRef}  canvasProps={{  height:150, className: 'signatureCanvas'}} />
            </div>
    </Dialog>
   
    );
}
export default SignaturePopUp;