import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { db } from '../../../../firebase';
import { doc,  writeBatch,  query,  updateDoc, collection,  onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../../context/AuthContext';
import { useGlobal } from '../../../../context/GlobalContext';
export const AccountPopUpContext = createContext();

export const AccountPopUpContextProvider = ({ children }) => {

    const { gearedUser} = UserAuth();
	const { account} = useGlobal();
	const [accountNotes, setAccountNotes] = useState([]);
	const accountNotesRef = useRef([]);
	const accountNotesListener = useRef(null);
	useEffect(()=>{
		if(account?.ID){
			queryNotes();
		}
	},[account?.ID]);

	const queryNotes = () =>{
        accountNotesRef.current = [];
		console.log('querying notes for account = ' + account?.Name)
    
        if ( accountNotesListener.current)  accountNotesListener.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Notes`;
            const q = query(collection(db, queryName), where("ParentID", "==",  account.ID));
			accountNotesListener.current=   onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempNote= change.doc.data();
				console.log('tempNote FOUND= ', tempNote)
                tempNote.ID = change.doc.id;
                if (change.type === "added")   accountNotesRef.current.push(tempNote);
                
                if (change.type === "modified") {
                    const noteIndex =accountNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    accountNotesRef.current[noteIndex] = tempNote;
                }
                if (change.type === "removed") {
                    const noteIndex =accountNotesRef.current.findIndex(d => d.ID === tempNote.ID);
                    accountNotesRef.current.splice(noteIndex,1)
                }
                
            })
			console.log('account notes  for account = ' + account?.Name + ' = 	',accountNotesRef.current)

			setAccountNotes( [...accountNotesRef.current]);
        })
    }

	return <AccountPopUpContext.Provider value={{
		accountNotes
	}}>
		{children}
	</AccountPopUpContext.Provider>;
};

export const useAccountPopUp = () => {
	return useContext(AccountPopUpContext);
};

