
import { useReport } from './context/ReportContext';
import { Checkbox } from 'primereact/checkbox';
import InputTextParent from '../InputComponents/InputTextParent'; 
const CustomizeTable = ({ tableType, reportType }) => {
    const {selectFields, setSelectFields, saveDefaultCompTable, saveDefaultReportTable,setShowSelectFields, reportName, setReportName} = useReport();

    const handleFieldToggle = (fieldIndex, fieldName, value) => {
        let updatedFields = [...selectFields];
        updatedFields[fieldIndex][fieldName] = value
       
        if(updatedFields[fieldIndex].Type==='boolean' && fieldName.includes('ExtraSelected')){
            if(value) updatedFields[fieldIndex].reportField=true; else updatedFields[fieldIndex].reportField=false;
            if(fieldName==='ExtraSelected1' && value) updatedFields[fieldIndex].value=true;
            if(fieldName==='ExtraSelected2' && value) updatedFields[fieldIndex].value=false;
       

        }
        else if(updatedFields[fieldIndex].reportField)  updatedFields[fieldIndex].value=value; else updatedFields[fieldIndex].value='null';
        setSelectFields([...updatedFields]);
    };
 
    const saveDefaultTable = ()=>{
        console.log('table type = ', tableType);
        if(tableType==='comp')saveDefaultCompTable(selectFields);
        if(tableType==='report')saveDefaultReportTable(selectFields, reportType);
    }

    return (
        <div className="customize-table">
            {tableType==='report' && (<div className="p-inputgroup" style={{width:"20%"}}>
                <span className="p-inputgroup-addon dispatch-inputgroup"  >Report Name </span> 
                <InputTextParent  value={reportName}  onChange={(e) => setReportName(e.target.value)} />
            </div>)}

            {selectFields.map((field, index) => (
                <div className="mbsc-row" key={index}>
                    <div className="p-inputgroup mbsc-col-3 " style={{paddingLeft:'.5em'}}>
                        <span className="p-inputgroup-addon " style={{flexBasis:'60%'}}>{field.title}: </span>
                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldToggle(index, 'selected', e.checked)} checked={field.selected}  />
                    </div> 
                    {field.hasExtra1 && field.selected && (   
                        <div className="p-inputgroup mbsc-col-2 ">
                            <span className="p-inputgroup-addon " style={{flexBasis:'60%'}}>{field.ExtraName1}: </span>
                            <Checkbox style={{ width: '100%' }} disabled={field.reportField && field.ExtraSelected2} onChange={(e) => handleFieldToggle(index, 'ExtraSelected1', e.checked)} checked={field.ExtraSelected1}  />
                    </div>)}
                    {field.hasExtra2 && ((!field.hasExtra3 && field.selected) || (field.hasExtra3 && field.ExtraSelected1)) && (   
                        <div className="p-inputgroup mbsc-col-2 ">
                            <span className="p-inputgroup-addon " style={{flexBasis:'60%'}}>{field.ExtraName2}: </span>
                            <Checkbox style={{ width: '100%' }}   disabled={field.reportField && field.ExtraSelected1} onChange={(e) => handleFieldToggle(index, 'ExtraSelected2', e.checked)} checked={field.ExtraSelected2}  />
                    </div>)}
                    {field.hasExtra3 && field.selected && field.ExtraSelected1 && (   
                        <div className="p-inputgroup mbsc-col-2 ">
                            <span className="p-inputgroup-addon " style={{flexBasis:'60%'}}>{field.ExtraName3}: </span>
                            <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldToggle(index, 'ExtraSelected3', e.checked)} checked={field.ExtraSelected3}  />
                    </div>)}
                </div>
            ))}
           
          <button style={{ margin: '0', padding: '.5em', width:"10%", marginTop:'.5em', marginRight:'1em'}}   onClick={(e) =>saveDefaultTable(selectFields)}  className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Save Table </button>
          <button style={{ margin: '0', padding: '.5em', width:"10%", marginTop:'.5em'}}   onClick={(e) => setShowSelectFields(false)}  className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Cancel</button>
        </div>
    );
    
};
export default CustomizeTable
