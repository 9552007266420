import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Panel} from 'primereact/panel';
import { Button } from 'primereact/button';
import pdfMake from 'pdfmake/build/pdfmake';
import imageCompression from 'browser-image-compression';

import { UserAuth } from '../../../context/AuthContext';
import { useDispatchFreight } from '../context/DispatchFreightContext';
import { useGlobal } from '../../../context/GlobalContext';
const SendFreightBillsPopUp = ({ visible, onHide}) => {
    const { showContactPopUp} = useGlobal();
	const { company, addDocument, gearedUser, contacts } = UserAuth();
	const { dispatchFreightBills, setDispatchFreightBills, uploadPDFFile, dispatch, emailedPDFs, dispatchDriverFreightBills } = useDispatchFreight();

	const [dispContacts, setDispContacts]= useState([]);
    const [includeLoadSiteSig, setIncludeLoadSiteSig] = useState(false);
    const [includeDumpSiteSig, setIncludeDumpSiteSig] = useState(false);
    const [includeFBPDFS, setIncludeFBPDFS] = useState(true);
    const [includeFBPics, setIncludeFBPics] = useState(false);
    const [includeScaleTagPics, setIncludeScaleTagPics] = useState(false);
    console.log('FREIGHTB ILLS ON RENDER= ',dispatchFreightBills)
    //pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(()=>{if(includeLoadSiteSig) setIncludeDumpSiteSig(false)},[includeLoadSiteSig])
    useEffect(()=>{if(includeDumpSiteSig) setIncludeLoadSiteSig(false)},[includeDumpSiteSig])
    // Sample data for demonstration purposes; replace with actual data as needed
	console.log('dispatchcontacts = ',dispContacts)
	useEffect(()=>{
		console.log('dispatch = ', dispatch)
		console.log('contacts = ', contacts)
        const tempDispContacts  = dispatch.Account && contacts ? 
			contacts.filter(
				contact => contact.Account.ID === dispatch.Account?.ID || 
				contact.Account.ID === dispatch.Receiver?.ID || 
				contact.Account.ID === dispatch.Shipper?.ID
			) : [];

        setDispContacts([...tempDispContacts]);
    },[dispatch, contacts]);

    useEffect(()=>{
        let tempFreights = dispatchFreightBills ? [...dispatchFreightBills] : [];
        for( let i=0; i<tempFreights.length; i++)tempFreights[i].Send =true;
        setDispatchFreightBills(tempFreights)
        console.log('setting freihg tiblsl on load = ', tempFreights)

    },[])
   
      const compressImage = async (imageUrl) => {
           const response = await fetch(imageUrl);
           const blob = await response.blob();
          
           const options = {
               maxSizeMB: 1.0, // Maximum file size in MB
               maxWidthOrHeight: 1024, // Resize to this width/height (keeping aspect ratio)
               useWebWorker: true, 
               initialQuality: 0.9,
           };
           const jpegBlob = new Blob([blob], { type: 'image/jpeg' });
         
           return await imageCompression( jpegBlob , options);
    };
       
    const getCompressedBase64 = async (imageUrl) => {
        const compressedBlob = await compressImage(imageUrl);
    
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(compressedBlob);
        });
    };
    const printAllFBs = async(type)=>{
        let docDefinition = {
            content: [],
            pageMargins: [40, 85, 40, 60],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };


        let CompanyHeader = dispatch.Company.Address + ', ' + dispatch.Company.address2 + '\n' + 'Office: ' + dispatch.Company.CompanyPhone + '   Fax: ' + dispatch.Company.Fax;

        let headerColumns =[];
        headerColumns.push({ width: 50, height: 50, text: '' });
        headerColumns.push([{ width: 200, text: dispatch.Company.CompanyName, bold: true, alignment: 'center', fontSize: 18, margin: [5, 35, 0, 0] }, { text: CompanyHeader, margin: [0, 0, 0, 0], bold: true, alignment: 'center', fontSize: 11 }]);
        headerColumns.push({ width: 100, text: '' });
        docDefinition.header = [{ columns: headerColumns }];
        let promises =[];
        let docDefinitionContents =[];
        let pageBreak = false;
        console.log('dispatchFreightBills = ' , dispatchFreightBills)
        console.log('dispatchDriverFreightBills = ', dispatchDriverFreightBills);
        for(let i=0; i<dispatchFreightBills.length; i++){
            docDefinitionContents.push([]);
            if(dispatchFreightBills[i].Send){
                let tempFreight = dispatchFreightBills[i];       
                if(dispatchFreightBills[i].dSubmitted && !dispatchFreightBills.approved){
                    for(let j=0; j<dispatchDriverFreightBills.length; j++){
                        const freightIndex = dispatchDriverFreightBills.findIndex(f => f.ID ===dispatchFreightBills[i].ID);
                        tempFreight=convertDriverFreightTimes(dispatchDriverFreightBills[freightIndex]);
                    }
                }
                console.log('sending tempfReight = ', tempFreight);
                promises.push(addFreightToPDF( docDefinitionContents,i, tempFreight,pageBreak));
                pageBreak = true;
            }
        }
        await Promise.all(promises);
        console.log('docDefinitionContents = ', docDefinitionContents);
        for( let d=0; d<docDefinitionContents.length; d++) docDefinition.content = docDefinition.content.concat(docDefinitionContents[d]);
        console.log('docDef at the end = ', docDefinition);

        if(type==='Preview') pdfMake.createPdf(docDefinition).download();
        else if(type==='Email' || type==='Text'){
            pdfMake.createPdf( docDefinition).getBase64( function(encodedString) {
                let base64PDF = encodedString;
             
                if(type==='Email')for(let c=0;c<dispContacts.length; c++)if(dispContacts[c].Send)emailPDF(base64PDF, dispContacts[c].Email)
            });
       }
    }
    
    const createFreightPDF =function(docDefinitionContents, index, FreightBill, pageBreak){
      
        var truckname, trailername;
   
            
    
        if(FreightBill.Trailer){if(FreightBill.Trailer.Name==='No Trailer') trailername=''; else trailername=FreightBill.Trailer.Name;}else trailername='';
        if(FreightBill.Truck) {if(FreightBill.Truck.Name==='No Truck')  truckname=''; else truckname= FreightBill.Truck.Name;} else  truckname=''; 

        var haulerText= [{ bold:true, text:'Driver Name: '  }, FreightBill.driverName];
        if(FreightBill.Subhauler)  haulerText= [{ bold:true, text:'Sub-Hauler: ' },FreightBill.haulerName]; 
        let firstRow=[{colSpan: 2, rowSpan:2, fontSize: 15, text:haulerText}, {}, {  text: [{bold: true,text: 'FB #: '}, FreightBill.FBNO]}];
        
        let secondRow=[  {}, {}, { text:[{bold:true,text: 'Job #: ' }, FreightBill.jobNO]} ]
        let thirdRow=[{ text:[{bold:true,text:'Rate Type: '}, FreightBill.PayType]}, { text:[{bold:true,text: 'Number of Loads: '}, FreightBill.loads]}, { text:[{bold:true,text:'Date: '}, FreightBill.JobDate]}];
        let shipperAndReceiverRow=[
            {fontSize: 15, text:[{bold:true,text:'Shipper: ' }, FreightBill.Shipper.Name]},
            {fontSize: 15, text:[{bold:true, text:'Receiver: ' }, FreightBill.Receiver.Name]}
        ];
    
        let fourthRow=[ {text:[{bold:true, text:'Material: '}, FreightBill.Material.Name]},  {text:[{bold:true, text:'TruckType: ' }, FreightBill.TruckType.Name]},   {text: [{bold: true,text: 'Truck: '}, truckname+'  ',{bold: true,text: 'Trailer: '}, trailername]}];
        let sixthRow=[{text:[{bold:true, text:'Origin: '}, FreightBill.LoadSite.Name]}, {text:[{bold:true, text:'Destination: ' }, FreightBill.DumpSite.Name]}];
        let seventhRow=[{text:[{bold:true, text:'Address: ' }, FreightBill.LoadSite.fullAddress]}, {text:[{bold:true, text:'Address: ' }, FreightBill.DumpSite.fullAddress]}];
        
        let weightTable=[];
        let expenseTable=[];
        let weightTableHeaders=[
            {colSpan: 3, text: 'Weights', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {},
            {colSpan: 3, text: 'Load', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {},
            {colSpan: 3, text: 'Dump', fontSize:20, style: 'tableHeader', alignment: 'center'},
            {},
            {}
        ];
        let weightTableHeaders2=[
            {text: 'Material', style: 'tableHeader', alignment: 'center'},
            {text: 'Scale Tag', style: 'tableHeader', alignment: 'center'},
            {text: 'Weight', style: 'tableHeader', alignment: 'center'},
            {text:'Arrive', style: 'tableHeader', alignment: 'center'},
            {text: 'Depart', style: 'tableHeader', alignment: 'center'},
            {text: 'Stand By', style: 'tableHeader', alignment: 'center'},
            {text: 'Arrive', style: 'tableHeader', alignment: 'center'},
            {text: 'Depart', style: 'tableHeader', alignment: 'center'},
            {text: 'Stand By', style: 'tableHeader', alignment: 'center'}
        ];
        let expenseTableHeaders=[
            {text: 'Description', style: 'tableHeader', alignment: 'center'},
            {text: 'Qty', style: 'tableHeader', alignment: 'center'},
            {text: 'Rate', style: 'tableHeader', alignment: 'center'},
            {text: 'Total', style: 'tableHeader', alignment: 'center'}
            
        ];
    
        console.log('FreightBill.approveShipperSignature = ', FreightBill.approveShipperSignature)
        var driverSignatureImage={ width:265,text: '',  height:50}; 
        var approveSignatureImage={ width:265,text: '',  height:50};
        
        if(FreightBill.driverSignature) driverSignatureImage={ width:265,image: FreightBill.driverSignature, height:50};

        if(FreightBill.approveShipperSignature && includeLoadSiteSig) approveSignatureImage={ width:265,image: FreightBill.approveShipperSignature, height:50};
        else if(FreightBill.approveReceiverSignature && includeDumpSiteSig )approveSignatureImage={ width:265,image: FreightBill.approveReceiverSignature, height:50};

        weightTable.push(weightTableHeaders);
        weightTable.push(weightTableHeaders2);
        expenseTable.push(expenseTableHeaders);
        
        let weightTableWidths=['*',55,55,45,45,40,45,45,40];
        let expenseTableWidths=['*','*','*','*'];
        for(var i=0; i<FreightBill.Weights.length; i++){
            var materialName='';
            
            if(FreightBill.Weights[i].Material){
                if(FreightBill.Weights[i].Material.Name) materialName=FreightBill.Weights[i].Material.Name;
                else materialName=FreightBill.Weights[i].Material;
            } 
    
            let weightTableRow=[
                {text: materialName, alignment:'center' },
                {text: FreightBill.Weights[i].tagNO, alignment:'center' },
                {text: FreightBill.Weights[i].weight, alignment:'right' },
                {text: FreightBill.Weights[i].loadStart, alignment:'center' },
                {text: FreightBill.Weights[i].loadEnd, alignment:'center' },
                {text: FreightBill.Weights[i].excessLoad, alignment:'right' },
                {text: FreightBill.Weights[i].dumpStart, alignment:'center' },
                {text: FreightBill.Weights[i].dumpEnd, alignment:'center' },
                {text: FreightBill.Weights[i].excessDump, alignment:'right' }
            ];
            weightTable.push(weightTableRow);
        }
        
        let totalWeightRow=[
            {colSpan: 2,bold:true, text: 'Total Weight:',  alignment: 'right'},
            {},
            {text:FreightBill.tWeight,alignment: 'right'},
            {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
            {},
            {text: FreightBill.totalExcessLoad,alignment: 'right'},
            {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
            {},
            {text: FreightBill.totalExcessDump,alignment: 'right'}
        ];
        weightTable.push(totalWeightRow);
        console.log('weightTable = ', weightTable);
        
        let expenseRowCount=0;
        for(var j=0; j<FreightBill.Expenses.length; j++){
            let expenseTableRow=[
                FreightBill.Expenses[j].Name.Name,
                FreightBill.Expenses[j].qty,
                FreightBill.Expenses[j].rate,
                FreightBill.Expenses[j].total
            ];
        if(FreightBill.Expenses[j].Name!=='Stand By'){
            expenseRowCount++;
            expenseTable.push(expenseTableRow);
        } 
        }
        console.log('expenseTable = ',expenseTable);

        let timeRows=[[{text:[{bold:true, text:'Start Time: '},FreightBill.startTime]},{text:[{bold:true, text:'End Time: ' }, FreightBill.endTime]}, {text:[{bold:true, text:'Total Hours: ' }, FreightBill.grossHours]}, {text:[{bold:true, text:'Deduction: ' }, FreightBill.lunch]}]];
        var timeRow2=[{},{}, {},{text:[{bold:true, text:'Hours: '}, FreightBill.tHours]}  ];
        if(FreightBill.PayType==='Hour' || FreightBill.PayType==='Hour/Percent') timeRow2=[{text:[{bold:true, text:'Depart Load: '},FreightBill.departRoundTrip]},{text:[{bold:true, text:'Arrive Dump: ' }, FreightBill.arriveRoundTrip]}, {text:[{bold:true, text:'Running Time: ' }, FreightBill.runningTime]},{text:[{bold:true, text:'Hours: '}, FreightBill.tHours]}  ];
        timeRows.push(timeRow2);          
    
        // docDefinition.content.push(FreightBillHeader2);


        let freightBillInfo= {
            pageBreak:'before',
            style: 'tableExample',
            table: { widths: ['*',  '*', '*'], body: [firstRow,secondRow,thirdRow,fourthRow]}
        };
        if(!pageBreak)freightBillInfo= {
            
            style: 'tableExample',
            table: { widths: ['*',  '*', '*'], body: [firstRow,secondRow,thirdRow,fourthRow]}
        };
        docDefinitionContents[index].push(freightBillInfo);
        
        let freightBillShipperAndReceiver={
            style: 'tableExample',
            table: { widths: ['*',  '*'], body: [shipperAndReceiverRow,sixthRow,seventhRow ]}
        };
        docDefinitionContents[index].push(freightBillShipperAndReceiver);
        
        let freightBillWeightTable={
            style: 'tableExample',
            table: {widths: weightTableWidths, body: weightTable } 
        }
        docDefinitionContents[index].push( freightBillWeightTable);        
        let freightBillExpenseTable={
            style: 'tableExample',
            table: {widths: expenseTableWidths, body: expenseTable } 
        }
        if(expenseRowCount>0)docDefinitionContents[index].push(freightBillExpenseTable);        
                
        let freightBillTimes={
            style: 'tableExample',
            table: {widths: ['*',  '*', '*', '*' ], body: timeRows } 
        }
        docDefinitionContents[index].push(freightBillTimes);        
                
        let freightBillSignatureLabels={
            table: {
                widths: ['*', '*'],
                body: [
                    [{ text: [{ bold: true, text: 'Driver Name: ' }, FreightBill.driverName] }, { text: [{ bold: true, text: 'Consignor Name: ' }, FreightBill.signatureName] }],
                    [{ border: [false, false,false,false],  bold: true, text: 'Driver Signature:' }, { border: [false, false,false,false], bold: true, text: 'Consignor Signature:' }]
                ]
            }
        }
        docDefinitionContents[index].push(freightBillSignatureLabels);        
                
        let freightBillSignatures={
            columns: [
                { width: 5, text: '' },
                driverSignatureImage,
                { width: 20, text: '' },
                approveSignatureImage
            ], 
        }
        docDefinitionContents[index].push(freightBillSignatures);  
        if(!FreightBill.approverComments)FreightBill.approverComments='';
        
        let freightBillComments={
            table: {
                widths: ['*', '*'],
                body: [
                    [{text:[{bold:true, text:'Driver Comments: '},   FreightBill.Comments ], alignment:'center' },
                    {text:[{bold:true, text:'Approver Comments: '},   FreightBill.approverComments ], alignment:'center' }]
                ]
            }
        }
        
        docDefinitionContents[index].push(freightBillComments);
        
        console.log('docDefinition = ' , docDefinitionContents);
    
        return(docDefinitionContents);
      
     
      
    }
    const addFreightToPDF =async(docDefinitionContents, index, FreightBill, pageBreak)=>{
        return new Promise(async (resolve, reject) => {
          
            if(includeFBPDFS)createFreightPDF(docDefinitionContents,index, FreightBill, pageBreak);
            if(includeFBPics) docDefinitionContents[index]= await getFreightBillAttachment(docDefinitionContents,index, FreightBill);
             else if(includeScaleTagPics) docDefinitionContents[index] = await getWeightAttachments(docDefinitionContents,index, FreightBill);
            return resolve(docDefinitionContents);
        });
    }
    const getBase64ImageFromURL= (url) => {
        return new Promise((resolve, reject) => {
         const img = new Image();
         img.crossOrigin = 'Anonymous';
         img.src = url;
          
          img.onload = () => {
            var canvas = document.createElement("canvas");
            
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          img.onerror = error => {
            reject(error);
          };
        
        });
    }


    const getWeightAttachments = async (docDefinitionContents, index, FreightBill) => {
        if (FreightBill.Weights.length === 0) {
            return docDefinitionContents[index];
        }
    
        const images = [];
        for (const weight of FreightBill.Weights) {
            console.log('weight.TagUrl= ' + weight.TagUrl)
            if (weight.TagUrl) {
                const TagBase64 = await getCompressedBase64(weight.TagUrl);
                images.push({ pageBreak: 'before', width: 520, image: TagBase64 });
            }
        }
    
        docDefinitionContents[index].push(...images); // Append all at once
        return  docDefinitionContents[index]
    };


    const getFreightBillAttachment = async ( docDefinitionContents,index, FreightBill) => {
        return new Promise(async (resolve, reject) => {
          console.log('Starting to get attachments for freight bill =', FreightBill);
      
            if (FreightBill.Picture) {
                const getFBPic = async (FreightBill) => {
                    console.log('Attaching the FB Picture for ID =', FreightBill.ID);
                    try {
                        const FBPic = await getBase64ImageFromURL(FreightBill.Picture); // Ensure getBase64ImageFromURL is defined
                        const FreightPicture = {  width: 590,  image: FBPic };
                        docDefinitionContents[index].push(FreightPicture);
                    } catch (error) {
                        console.error('Error fetching FB picture:', error);
                        reject(error);
                    }
            
        
                    if (includeScaleTagPics)  resolve(await getWeightAttachments(docDefinitionContents, index, FreightBill)); // Ensure getWeightAttachments is defined
                    else  resolve(docDefinitionContents[index]);
                    
                };
                await getFBPic(FreightBill);
            }else   if (includeScaleTagPics)  resolve(await getWeightAttachments(docDefinitionContents, index, FreightBill)); // Ensure getWeightAttachments is defined
            else  resolve(docDefinitionContents[index]);
                
        });
    };
      

    const getFreightBillAttachments = async (freightIndex, Invoice, docDefinition ) => {
        return new Promise(async (resolve, reject) => {
            console.log('Invoice= ', Invoice)
            const printFreightBills = Invoice.FreightBills.filter(item => item.onHold !== true).sort((a, b) => Number(a.FBNO) - Number(b.FBNO));
            const getFreightAttach = async (Freight, freightIndex) => {
                try {
                    docDefinition = await getFreightBillAttachment(Freight, docDefinition, true, true); // Ensure getFreightBillAttachment is defined
                    //console.log('DONE WITH ONE FREIGHT AND ITS INDEX =', freightIndex, 'AND invoice.printFreightBills.length =', Invoice.printFreightBills.length);
                
                    if ((printFreightBills.length - 1) === freightIndex)  resolve(docDefinition);
                    else   resolve(await getFreightBillAttachments(freightIndex + 1, Invoice, docDefinition, true, true));

                } catch (error) {
                    console.error('Error in getFreightAttach:', error);
                    reject(error);
                }
            };
      
            // Initialize or fetch Freight Bills if they are not already present

          if(printFreightBills.length)  await getFreightAttach(printFreightBills[freightIndex], freightIndex); else resolve( docDefinition);
           
        });
    };

    const checkGearedTime = (time) => {
        // Check if time matches HH:MM format and is a valid time
        return /^([01]\d|2[0-3]):[0-5]\d$/.test(time);
    };
    function formatTo24HourTime(date) {
        if(!checkGearedTime(date) && date){
       
            console.log('wait checkgeared time failed for ', date);
            const tempDate= date.toDate();
            const hours = tempDate.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
            const minutes = tempDate.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed
            console.log('hours = ' + hours)
            return `${hours}:${minutes}`;
        }else return date;
    
    }
    const convertDriverFreightTimes = (FreightBill) =>{
        console.log('we are showing driverfrieht for fReight bi ill = ', FreightBill)
        FreightBill.startTime =  formatTo24HourTime(FreightBill.startTime);
        FreightBill.startTimePaid =  formatTo24HourTime(FreightBill.startTimePaid);
        FreightBill.endTime =  formatTo24HourTime(FreightBill.endTime);
        FreightBill.endTimePaid =  formatTo24HourTime(FreightBill.endTimePaid);
    
        FreightBill.arriveRoundTrip =  formatTo24HourTime(FreightBill.arriveRoundTrip);
        FreightBill.departRoundTrip =  formatTo24HourTime(FreightBill.departRoundTrip);

        for(let i=0; i<FreightBill.Weights.length; i++){
            FreightBill.Weights[i].loadStart = formatTo24HourTime(FreightBill.Weights[i].loadStart);
            FreightBill.Weights[i].loadEnd =  formatTo24HourTime(FreightBill.Weights[i].loadEnd);
            FreightBill.Weights[i].dumpStart =  formatTo24HourTime(FreightBill.Weights[i].dumpStart);
            FreightBill.Weights[i].dumpEnd =   formatTo24HourTime(FreightBill.Weights[i].dumpEnd);
       
         
        }
       return FreightBill;
    }
    const changeContactSend = (contact, value)=>{
        const contactIndex = dispContacts.findIndex(c => c.ID === contact.ID);
        contact.Send = value;
        const tempDispContacts = [...dispContacts];
        tempDispContacts[contactIndex] =  contact;
    
        setDispContacts(tempDispContacts)
    }
    const createNewContact = (account)=>{
       
      
        let newContact = {
            ID: '',
            officeAccount:false,
            Account: {
                ID: account.ID,
                Name: account.Name,
                OrgName:account.Name
            },
            Name: '',
            FirstName: '',
            LastName: '',
            PhoneObject:{
                Phone1:'',
                Phone2:'',
                Phone3:'',
                Phone4:''
            },
            phoneOK:false,
            Phone: '',
            Email: '',
            Department: 'Foreman',
            Fax: '',
            createLogIn:false
        }
        console.log('aabout to run add Contact for ', newContact) 
        showContactPopUp(newContact);
        //addDocument(newContact, 'Contacts');

    }
    const changeFBSend = (freight, value)=>{
        const freightIndex = dispatchFreightBills.findIndex(f => f.ID === freight.ID);
        freight.Send = value;
        const tempFreightBills = [...dispatchFreightBills];
        tempFreightBills[freightIndex] =  freight;
        console.log('setting dispath freights = ',)
        setDispatchFreightBills(tempFreightBills)
    }
    const  emailPDF = async(  base64PDF, email) =>{

    
   
   
        const formattedDate =  new Date().toLocaleString('en-US', {
            timeZone: 'PST', // Set to PST
            dateStyle: 'short', // Formats as MM/DD/YY
            timeStyle: 'short'  // Formats time as hh:mm AM/PM
        });
        
        const pdfUrl = await uploadPDFFile(base64PDF);
 
      
        let  newEmailFreightBills={
            DispatchID:dispatch.ID,
            from:gearedUser.selectedOrgName + '<Dispatches@mail.geared.tech>',
            replyTo: company.SupportEmail,
            to: email,
            createdAt:formattedDate,
            createdBy:gearedUser.Name,
            URL: pdfUrl,
            PDFType:'Freight Bills',
            ParentID: dispatch.ID,
            Sent:false,
            subject: 'Freight Bills for ' +   dispatch.JobDate,
            html: '<a href="'+ pdfUrl+ '"> Click link to Download Dispatch PDF </a>'
        
        }
        console.log('lets see what is null = ', newEmailFreightBills);
        addDocument( newEmailFreightBills, 'EmailedPDFs');
        onHide();
	
    
    }
    const renderHeader = () => (
     
        <div className="flex justify-content-end mbsc-grid mbsc-row">
            <span className=" mbsc-col-xl-8 " style={{padding:'0', fontSize:'1.5em', paddingTop:'.2em'}}>Send Freight Bills</span>

            <Button className=" mbsc-col-xl-1 " style={{margin:".25em" }} onClick={(e)=>{printAllFBs('Preview')}} >Preview PDF</Button> 
            <Button className=" mbsc-col-xl-1 " style={{margin:".25em" }} onClick={(e)=>{printAllFBs('Text')}} >Send Texts</Button> 
            <Button className=" mbsc-col-xl-1 " style={{margin:".25em" }} onClick={(e)=>{printAllFBs('Email')}} >Send Emails</Button> 
    
        </div>
    );
    const header = renderHeader();
    return (
        <Dialog pt={{header: { 'style':{padding: '0'} }}} header={header} visible={visible} style={{ width: '99vw', height:'99%' }} onHide={onHide} modal>
            <div className="mbsc-grid">
                <Panel header='Include'>
                    <div className="mbsc-row">
                        <div className="mbsc-col-xl-6">
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >    Load Site Signature</span> 
                                <Checkbox inputId="includeLoadSite" checked={includeLoadSiteSig} onChange={(e) => setIncludeLoadSiteSig(e.checked)} />
                            </div>
                            <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >    Dump Site Signature</span> 
                                <Checkbox inputId="includeDumpSite" checked={includeDumpSiteSig} onChange={(e) => setIncludeDumpSiteSig(e.checked)} />
                            
                                </div>
                        </div>
                        <div className="mbsc-col-xl-6">
                        <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >             Include FB PDFs</span> 
                                <Checkbox inputId="includeFBPDFS" checked={includeFBPDFS} onChange={(e) => setIncludeFBPDFS(e.checked)} />
                    
                                </div>
                            <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >        Include FB Pictures</span> 
                                <Checkbox inputId="includeFBPics" checked={includeFBPics} onChange={(e) => setIncludeFBPics(e.checked)} />
                            
                                </div>
                            <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >     Include Scale Tag Pictures</span> 
                                <Checkbox inputId="includeScaleTagPics" checked={includeScaleTagPics} onChange={(e) => setIncludeScaleTagPics(e.checked)} />
                            
                                </div>
                        </div>
                    </div>

                </Panel >
       
                <Panel header='Contacts'>
                {dispatch.Account?.ID && ( <Button label="New Customer Contact" className="p-button-primary" style={{ marginRight: '.25em' }} onClick={(e)=>{createNewContact(dispatch.Account)}}  />)}
                   {dispatch.Shipper?.ID && ( <Button label="New Shipper Contact" className="p-button-primary" style={{ marginRight: '.25em' }} onClick={(e)=>{createNewContact(dispatch.Shipper)}} />)}
                   {dispatch.Receiver?.ID && (  <Button label="New Receiver Contact" className="p-button-primary"  onClick={(e)=>{createNewContact(dispatch.Receiver)}} />)}
                

            
                    <table style={{ marginBottom: "5px", width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Send</th>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>E-Mail</th>
                                <th>Department</th>
                            </tr>
                        </thead> 
                        <tbody>
                            {dispContacts.map((contact, index) => (
                                <tr key={index}>
                                    <td>
                                        <Checkbox checked={contact.Send} onChange={(e) => {changeContactSend(contact, e.checked)}} />
                                    </td>
                                    <td>{contact.Name}</td>
                                    <td>{contact.displayPhone}</td>
                                    <td>{contact.Email}</td>
                                    <td>{contact.Department}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
        
                </Panel >
   
                <Panel header='Emailed Freight Bills'>
            
                <table style={{ marginBottom: "5px", width: "100%" }}>
                        <thead>
                            <tr>
                                <th>Created At</th>
                                <th>Subject</th>
                                <th>To</th>
                                <th>From</th>
                                <th>Delivered</th>
                                <th>PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {emailedPDFs.map((bill, index) => (
                                <tr key={index}>
                                    <td>{bill.createdAt}</td>
                                    <td>{bill.subject}</td>
                                    <td>{bill.to}</td>
                                    <td>{bill.from}</td>
                                    <td>
                                        <span style={{ color: bill.Sent ? 'green' : 'red' }}>{bill.Sent ? 'True' : 'False'}</span>
                                    </td>
                                    <td>
                                        {bill.URL && (
                                            <a href={bill.URL} target="_blank" rel="noopener noreferrer" className="p-button p-button-primary">
                                                PDF
                                            </a>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
          
                </Panel >
        
                <Panel header='Freight Bills'>
                    <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                        <table style={{ marginBottom: "5px", width: "100%" }}>
                            <thead>
                                <tr>
                                    <th>Driver Name</th>
                                    <th>FB #</th>
                                    <th>Loads</th>
                                    <th>On Hold</th>
                                    <th>Missing</th>
                                    <th>Total</th>
                                    <th>Send</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dispatchFreightBills.map((bill, index) => (
                                    <tr key={index}>
                                        <td>{bill.driverName}</td>
                                        <td>{bill.FBNO}</td>
                                        <td>{bill.loads}</td>
                                        <td>
                                            <Checkbox checked={bill.onHold} disabled />
                                        </td>
                                        <td>
                                            <Checkbox checked={bill.missing} disabled />
                                        </td>
                                        <td>${bill.bTotal}</td>
                                        <td>
                                            <Checkbox checked={bill.Send} onChange={(e) => {changeFBSend(bill,e.checked)}} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </Panel >
            </div>
        </Dialog>
    );
};

export default SendFreightBillsPopUp;
