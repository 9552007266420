import React, { useEffect, useState } from 'react';


import { useDispatchFreight } from '../context/DispatchFreightContext';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import SendFreightBillsPopup from './SendFreightBillsPopUp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';  

import {useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function DispatchFreights(props) {
    const { id } = useParams();
 
    const [showSendFreightsPopUp, setShowSendFreightsPopUp]= useState(false);
	const [sortedFreightBills, setSortedFreightBills]= useState([]);


	const { dispatchFreightBills, dispatchExpenses,  dispatchIDRef, 
		fetchDispatchFreightBills, fetchDispatchExpenses, fetchDispatchDriverFreightBills,
		fetchDispatch, queryEmailedPDFs} =useDispatchFreight();

	const navigate = useNavigate();

    console.log('dispatchFreightBills	= ', dispatchFreightBills)

    // Sort the fbs  array by loadorder
    useEffect(()=>{
		if(dispatchFreightBills?.length){
			let tempDispatchFreightBills = dispatchFreightBills?.sort((a, b) => {
				if (a.loadOrder < b.loadOrder) return -1;
				if (a.loadOrder > b.loadOrder) return 1;
				return 0;
			});
			for( let d= 0; d<tempDispatchFreightBills.length; d++){
				tempDispatchFreightBills[d].unBilledExpenses=0;
				tempDispatchFreightBills[d].unPaidExpenses =0;
	
				for(let e=0; e<dispatchExpenses?.length; e++){
					if (dispatchExpenses[e].FreightBill === tempDispatchFreightBills[d].ID){
						if(dispatchExpenses[e].pay && !dispatchExpenses[e].paid)  tempDispatchFreightBills[d].unPaidExpenses++;
						if(dispatchExpenses[e].bill && !dispatchExpenses[e].billed) tempDispatchFreightBills[d].unBilledExpenses++;
					} 
				} 
			}
			setSortedFreightBills([...tempDispatchFreightBills])
		}
    },[dispatchFreightBills, dispatchExpenses])


    useEffect(()=>{
		if(dispatchIDRef.current !== id){
			dispatchIDRef.current = id;
			fetchDispatchFreightBills(id);
			queryEmailedPDFs(id);
			fetchDispatchExpenses(id);
			fetchDispatch(id); 
			fetchDispatchDriverFreightBills(id);
		}
        
    },[id])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FBNO: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Loads: { value: null, matchMode: FilterMatchMode.EQUALS },
        profit: { value: null, matchMode: FilterMatchMode.CONTAINS },
        billedQty: { value: null, matchMode: FilterMatchMode.CONTAINS },
        tPaid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        tBilled: { value: null, matchMode: FilterMatchMode.CONTAINS },
        dSubmitted: { value: null, matchMode: FilterMatchMode.EQUALS },
        missing: { value: null, matchMode: FilterMatchMode.EQUALS },
        onHold: { value: null, matchMode: FilterMatchMode.EQUALS },
        billed: { value: null, matchMode: FilterMatchMode.EQUALS },
        paid: { value: null, matchMode: FilterMatchMode.EQUALS },
        approved: { value: null, matchMode: FilterMatchMode.EQUALS }
  });
  

  const editBodyTemplate = (rowData) => {
    return (
        <button type="button" onClick={() => handleEdit(rowData)}> <FontAwesomeIcon icon={faEdit} /> </button>
    );
};

// Sample handleEdit function
const handleEdit = (rowData) => {
    console.log("Edit button clicked for:", rowData)
    
    navigate('/freightbill/freightbill/'+rowData.ID+'/'+rowData.dispatchID)
   // showAccountPopUp(rowData)
    // Implement your edit functionality here
};


const currencyBodyTemplate = (rowData, field) => {
    return (
       <span style={{paddingRight:".5em", float:"right"}}>${ rowData[field]}</span>
    );
};
    const booleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'green' : 'red';
        return (
          <i style={{color:color}}
            className={classNames('pi', {
              'true-icon pi-check-circle': rowData[field],
              'false-icon pi-times-circle': !rowData[field]
            })}
          />
        );
    };
    const oppositebBoleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'red' : 'green';
        return (
          <i style={{color:color}}
            className={classNames('pi', {
              'true-icon pi-check-circle': rowData[field],
              'false-icon pi-times-circle': !rowData[field]
            })}
          />
        );
    };

 const closeSendFreights = () =>{
    setShowSendFreightsPopUp(false);
 
 }

  const renderHeader = () => (
     
    <div className="flex justify-content-end mbsc-grid mbsc-row">
        <span className=" mbsc-col-xl-10 " style={{padding:'0', fontSize:'1.8em', paddingTop:'.2em'}}>Freight Bills</span>
        {dispatchFreightBills?.length && (<Button className=" mbsc-col-xl-1 " style={{margin:".25em" }}  onClick={(e)=>{setShowSendFreightsPopUp(true)}}>Send Freights</Button> )}

    </div>
);
  const booleanFilterTemplate = (options) => {
    return (
      <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
        value={options.value !== null ? options.value : undefined}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };
  const header = renderHeader();
  return(
    <div className="card">
        <DataTable pt={{header: { 'style':{padding: '0'} }}} value={sortedFreightBills} paginator rows={25} dataKey="ID" filters={filters}  header={header} filterDisplay="row"  emptyMessage="No Freight Bills found.">
            <Column  header="Edit" body={editBodyTemplate}/>
            <Column field="driverName" header="Name" filter filterPlaceholder="Search by name" />
            <Column field="FBNO" header="FB #" filter filterPlaceholder="Search" />
            <Column header="Truck" filter filterField="Truck.Name" filterPlaceholder="Search by Truck"  body={(rowData) => rowData.Truck?.Name || 'N/A'}/>
            <Column field="loads" header="Loads"  />
            <Column field="billedQty" header="Qty"   />

            <Column field="missing" header="Missing" dataType="boolean" style={{ minWidth: '4rem' }} body={(rowData) => oppositebBoleanBodyTemplate(rowData, 'missing')} filter filterElement={booleanFilterTemplate} />
            <Column field="onHold" header="On Hold" dataType="boolean" style={{ minWidth: '4rem' }}  body={(rowData) => oppositebBoleanBodyTemplate(rowData, 'onHold')} filter filterElement={booleanFilterTemplate}/>
            <Column field="dSubmitted" header="Submitted" dataType="boolean" style={{ minWidth: '4rem' }} body={(rowData) => booleanBodyTemplate(rowData, 'dSubmitted')} filter filterElement={booleanFilterTemplate} />
            <Column field="approved" header="Approved" dataType="boolean" style={{ minWidth: '4rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'approved')} filter filterElement={booleanFilterTemplate}/>
            <Column field="bTotal" header="Bill Total"  body={(rowData) => currencyBodyTemplate(rowData, 'bTotal')} style={{ minWidth: '6rem' }}/>
            <Column field="tPaid" header="Pay Total"  body={(rowData) => currencyBodyTemplate(rowData, 'tPaid')} style={{ minWidth: '6rem' }}/>
            <Column field="profit" header="Profit"  body={(rowData) => currencyBodyTemplate(rowData, 'profit')} style={{ minWidth: '6rem' }}/>
            <Column field="unBilledExpenses" header="Unbilled Expenses"   />
            <Column field="unPaidExpenses" header="Unpaid Expenses"   />
            <Column field="billed" header="Billed" dataType="boolean" style={{ minWidth: '4rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'billed')} filter filterElement={booleanFilterTemplate}/>
            <Column field="paid" header="Paid" dataType="boolean" style={{ minWidth: '4rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'paid')} filter filterElement={booleanFilterTemplate}/>
        </DataTable>
       {dispatchFreightBills?.length && ( 
			<SendFreightBillsPopup 
				visible={showSendFreightsPopUp} 
				onHide={closeSendFreights} 
		
        	/>
		)}
  </div>

  )
}


export default DispatchFreights;