import React, { useState, useCallback } from 'react';

import useFieldSpecificDebounce from '../../../hooks/useFieldSpecificDebounce';
import InputTableCell from './InputTableCell';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput';
import { UserAuth } from '../../../context/AuthContext';
import  TableCell  from '../../ListComponents/TableCell';

const EditableLineItem = ({ lineItem, columns, onUpdate }) => {
    const [inputValues, setInputValues] = useState(lineItem);
    const debouncedFieldUpdater = useFieldSpecificDebounce(500);
    const { expenseNames, deleteDocument, updateDocument, formatDate } = UserAuth();
    console.log('inputValues for the editable line item = ', inputValues)

	const handleDateChange = (fieldName, value) => {
		let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate) 
        let fields = [fieldName,fieldName+'Value' ];
        let values = [formattedDate, value];
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        updateExpenseFields(fields, values);
	}
    const handleChange = (field, value) => {
        const updatedValues = { ...inputValues,  [field]: value  };
        setInputValues(updatedValues);
        if(field==='onHold')  updateDocument({onHold:value}, lineItem.ID, "Expenses");
        if(field==='Total'){
			const debouncedUpdate = debouncedFieldUpdater(['total'], updateExpenseFields);
			debouncedUpdate(['total'], [value]); // Pass `fields` and `values` dynamically
		}  
   
    };


    const updateExpenseFields= useCallback(async (fieldNames, values) => {
    
        let updateObject = {};
        fieldNames.forEach((field, index) => {
          	updateObject[field] = values[index];
        });
  
        updateDocument(updateObject, lineItem.ID, "Expenses");
        
    },  [lineItem] );
    const calcTotal = (fieldName, value) =>{
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName] = value;
        console.log('tempInputValues= ', tempInputValues);
        tempInputValues.Total = tempInputValues.Qty*tempInputValues.Rate;
		
        const fields=['qty', 'rate', 'FuelTotal', 'BrokerTotal', 'total'];
        const values = [tempInputValues.Qty, tempInputValues.Rate, tempInputValues.FuelTotal, tempInputValues.BrokerTotal, tempInputValues.Total];
        setInputValues(tempInputValues);
        const debouncedUpdate = debouncedFieldUpdater(fields, updateExpenseFields);
        debouncedUpdate(fields, values); // Pass `fields` and `values` dynamically
    
        
    }
    const handleDelete = (lineitem) => {
        if (window.confirm('Are you sure you would like to delete this Expense Line Item?')) {
            deleteDocument(lineitem, 'Expenses');
        }
      
    }
    const handleRateTypeChange = (fieldName, value) => {
        console.log('fieldName= ', fieldName)
        console.log('value= ', value)
        updateDocument({Name:value}, lineItem.ID, 'Expenses');
        setInputValues( {...inputValues, RateType: value.Name});
    }
    const renderEditableCell = (col) => {
        const borderStyle = "1px 1px 1px 1px";

        // Return empty cell for non-visible columns
        if (!col.visible) {
            return <td style={{ display: 'none' }}></td>;
        }

        switch(col.id) {
            case 'fbno':
                return (
                    <td 
                        data-label='FB NO' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <button 
                            style={{ 
                                margin: '0', 
                                height:"80%", 
                                marginTop:".1em", 
                                padding: '.1em', 
                                marginLeft:".5em", 
                                width:"90%",
                                backgroundColor: "#dc3545", // Red color for delete button
                                color: "white"
                            }} 
                            onClick={() => handleDelete(inputValues)} 
                            className="mbsc-ios mbsc-btn-primary mbsc-btn"
                        >
                            Delete
                        </button>
                    </td>
                );
            case 'truck':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.truck}
                        onChange={(e) => handleChange('truck', e.target.value)}
                        borderStyle={borderStyle}
                        dataLabel='Truck'
                    />
                );
            case 'driver':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.Driver}
                        onChange={(e) => handleChange('Driver', e.target.value)}
                        borderStyle={borderStyle}
                        dataLabel='Driver'
                    />
                );
			case 'date':
				return (
					<InputTableCell 
						width={col.width}
						value={inputValues.JobDateValue}
						onChange={(e) => handleDateChange ('JobDate', e.value)}
						borderStyle={borderStyle}
						dataLabel='Date'
						type="date"
					/>
				)
            case 'missing':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.missing}
                        onChange={(e) => handleChange('missing', e.checked)}
                        borderStyle={borderStyle}
                        dataLabel='Missing'
                        type="checkbox"
                        disabled={true}
                    />
                );
            case 'onHold':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.onHold}
                        onChange={(e) => handleChange('onHold', e.checked)}
                        borderStyle={borderStyle}
                        dataLabel='On Hold'
                        type="checkbox"
                  
                    />
                );
            case 'description':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.Description}
                        onChange={(e) => handleChange('Description', e.target.value)}
                        borderStyle={borderStyle}
                        dataLabel='Description'
                    />
                );
            case 'rateType':
                return (
                    <td 
                        data-label='Rate Type' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <AutoCompleteInput 
                            fieldName="RateType"
                            field="Name"
                            value={{ Name: inputValues.RateType}}
                            suggestions={expenseNames}
                            setValue={setInputValues}
                            handleFieldChange={(fieldName, value) => handleRateTypeChange('RateType', value)}
                            showLabel={false}
                            databaseList={'LineItemNames'}
                            defaultNameType={'Expense'}
                        />
                    </td>
                );
            case 'qty':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.Qty}
                        onChange={(e) => calcTotal('Qty',e.value)} 
                        borderStyle={borderStyle}
                        dataLabel='Qty'
                        type="number"
                    />
                );
            case 'rate':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.Rate}
                        onChange={(e) => calcTotal('Rate', e.value )}
                        borderStyle={borderStyle}
                        dataLabel='Rate'
                        type="currency"
                        key={`rate-${inputValues.ID}`}
                    />
                );
				case 'tax':
					return (
						<TableCell
							width={col.width} 
							isNumber={true} 
							value={inputValues.TaxTotal} 
							borderStyle={borderStyle} 
							dataLabel='Tax'
							disabled={true}
						/>
					);
            case 'fuelCharge':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.FuelTotal}
                        onChange={(e) =>  calcTotal('FuelTotal', e.value )}
                        borderStyle={borderStyle}
                        dataLabel='Fuel Charge'
                        type="currency"
                        key={`fuel-${inputValues.ID}`}
                    />
                );
            case 'brokerFee':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={Math.abs(inputValues.BrokerTotal) * -1}
                        onChange={(e) => calcTotal('BrokerTotal', Math.abs(e.value) * -1 )}
                        borderStyle={borderStyle}
                        dataLabel='Broker Fee'
                        type="currency"
                        key={`broker-${inputValues.ID}`}
                    />
                );
            case 'total':
                return (
                    <InputTableCell 
                        width={col.width}
                        value={inputValues.Total}
                        borderStyle={borderStyle}
                        dataLabel='Total'
                        type="currency"
						onChange={(e) => handleChange('Total', e.value)}
                       
                    />
                );
            default:
                // Return empty cell instead of null
                return (
                    <td 
                        data-label={col.header} 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    ></td>
                );
        }
    };

    return (
        <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em'}}>
            {columns.map((col, index) => (
                <React.Fragment key={`${col.id}-${inputValues.ID}`}>
                    {renderEditableCell(col)}
                </React.Fragment>
            ))}
        </tr>
    );
};

export default EditableLineItem; 