import { getStorage, ref, getDownloadURL, uploadString } from "firebase/storage";
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { UserAuth } from '../../../context/AuthContext';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../../../fonts/vfs_fonts"

export const usePrevailingWagePDFs = () => {
    const storage = getStorage();
    const { gearedUser, company, formatDate, setDocument} = UserAuth();
    
    pdfMake.vfs = pdfFonts;
   
    pdfMake.addFonts({
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Medium.ttf',
            italics: 'Roboto-Italic.ttf',
            bolditalics: 'Roboto-MediumItalic.ttf'
        },
        ZapfDingbats: {
            normal: 'ZapfDingbats.ttf'
        }
     
    });
    const oldEmployeeDriverStuff=()=>{
        console.log('oldEmployeeDriverStuff');
      /*  
        driverComplianceDoc.content.push({ text: '(4) That fringe benefits as listed in the contract:', style: 'pageNumberText' });
        
        driverComplianceDoc.content.push({ text: ['(a) ', Driver4a, '  Have been or will be paid to the approved plan(s), fund(s), or program(s) for the benefit of listed employee(s), except as noted below.'], style: 'pageLetterText' });
        driverComplianceDoc.content.push({ text: ['(b) ', Driver4b, '  Have been paid directly to the listed employee(s), except as noted below.'], style: 'pageLetterText' });
        driverComplianceDoc.content.push({ text: ['(c) ', Driver4c, '  See exceptions noted below'], style: 'pageLetterText' });*/
    }
    const createEmployeeDriverPDF = async (reportWeek, prevailingWageReport, driverReportDoc) => {
        var ContractorBox, SubcontractorBox;
        console.log('ceportWeek,F' , reportWeek,);
        let checkedBox = {  text: '✔',  font: 'ZapfDingbats' };  // Filled checkbox
        let uncheckedBox = {  text: '❑', font: 'ZapfDingbats'   }; // Empty checkbox
        if(prevailingWageReport.ContractorPayroll)ContractorBox=checkedBox; else ContractorBox=uncheckedBox; 
        if(prevailingWageReport.SubcontractorPayroll)SubcontractorBox=checkedBox; else SubcontractorBox=uncheckedBox;
        
        
        
        let prevailingWageReportHeader={columns:[[{margin:[0,5,0,0], text:'STATE OF CALIFORNIA - DEPARTMENT OF TRANSPORTATION'},{text:['CONTRACTOR PAYROLL  ',ContractorBox , '   SUBCONTRACTOR PAYROLL  ',SubcontractorBox]          },{text:'DC-CEM 2502(OLD HC-347 REV 6/96)'} ],
        [{margin:[0,5,28,8], table:{widths:['*'], alignment:'right',  body:[[{alignment:'center',text:'PERSONAL INFORMATION'}],[ {fontSize:5, text:'Pursuant to the Federal Private Act(P.L. 93-579) and the Information Practices Act of 1977 (CM Code Sections 1796, et. Seq.) notice is hereby given for the respect of personal information by this form. The requested personal information is voluntary. The principal purpose of the voluntary information is so the department can fulfill teh need of the form. The failure to provide all or any part of the requested information may delay processing of this form. No disclosure of personal information can be made unless permissible under Article 8, Section 1798.24 of the IPA of 1977. Each individual has the right upon request and proper identification to inspect all personal information in any record maintained on the Individual by an identifying particular. Direct any inquires on information maintenance to your IPA Officer. '}]]}}]]};
        driverReportDoc.content.push(prevailingWageReportHeader);


        
       
        let tableHeaders=[];
        let driverTable=[];
        let daysTable={  fontSize:5,  margin:[-5,-3,-5,-3], table: {headerRows:1,  alignment: 'center',  widths:['*','*','*','*','*','*','*'], body:[]}}
        let daysRow=[{colSpan:7,alignment:'center', text:'DAY AND DATE'},{},{},{},{},{},{}];
        daysTable.table.body.push(daysRow);
        daysRow=[];
        var findingSaturdayDate=new Date(reportWeek.weekStartDate);
        for (var i=0; i<7; i++){
            const formattedDate = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit'  }).format(findingSaturdayDate);
            daysRow.push(formattedDate)
            findingSaturdayDate.setDate(findingSaturdayDate.getDate()+1);    
        } 
        daysTable.table.body.push(daysRow);
                
        daysRow=['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr','Sa'];
        daysTable.table.body.push(daysRow);
        daysRow=[{colSpan:7,alignment:'center', text:'HOURS WORKED EACH DAY'},{},{},{},{},{},{}];
        daysTable.table.body.push(daysRow);
    
        var tempEndDate=formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY');
        console.log('tempEndDate= ' + tempEndDate);
        let firstRow=[{colSpan:6,text:'CONTRACTOR/SUBCONTRACTOR NAME: ', border:[true,true,true,false]},{},{},{},{},{},{text:'BUSINESS ADDRESS:', border:[true,true,true,false],colSpan:11},{},{},{},{},{},{},{},{},{},{}];
        let secondRow=[{colSpan:6,text:company.CompanyName,style:'headerField', border:[true,false,true,true]},{},{},{},{},{},{text: company.Address + ', ' +company.address2, style:'headerField', border:[true,false,true,true], colSpan:11},{},{},{},{},{},{},{},{},{},{}];
        let thirdRow=[{ text:'PAYROLL NO.'},{ text:'FOR WEEK ENDING', colSpan:2},{},{text:'PROJECT AND LOCATION', colSpan:4},{},{},{},{text:'CONTRACT NUMBER: '+prevailingWageReport.ContractNumber, colSpan:9},{},{},{},{},{},{},{},{},{}];
        let fourthRow=[{ text:prevailingWageReport.DriverPayrollNumber.toString(), alignment:'center', fontSize:12, bold:true},{ text:tempEndDate, bold:true, fontSize:12, colSpan:2},{},{text:prevailingWageReport.ProjectLocation, colSpan:4},{},{},{},{text:'GROSS AMOUNT EARNED', colSpan:2},{},{text:[{text:'DEDUCTIONS'}, {text:'ON GROSS AMOUNT EARNED ALL PROJECTS'}, {text:'BASED'}], colSpan:6},{},{},{},{},{},{text:'NET WAGES PAID FOR WEEK',rowSpan:2},{text:'CHECK #', rowSpan:2}];

        driverTable.push( firstRow);
        driverTable.push( secondRow);
        driverTable.push( thirdRow);
        driverTable.push( fourthRow);
        tableHeaders.push('EMPLOYEE NAME, ADDRESS AND SOCIAL SECURITY NUMBER');
        tableHeaders.push('# EX');
        tableHeaders.push('WORK CLASSIFICATION');
        tableHeaders.push('ST or OT');
        tableHeaders.push( daysTable);
        tableHeaders.push('TOTAL HOURS');
        tableHeaders.push('RATE OF PAY');
        tableHeaders.push('THIS PROJECT');
        tableHeaders.push('ALL PROJECTS');
        tableHeaders.push('FED TAX');
        tableHeaders.push('FICA (SOC SEC)');
        tableHeaders.push('SDI');
        tableHeaders.push('LOCAL TAX');
        tableHeaders.push('OTHER TAX');
        tableHeaders.push('OTHER DED');
        tableHeaders.push({});
        tableHeaders.push({});
        let tableWidths=[80,10,50,10,160,25,20,33,33,20,20,20,20,20,25,35,30];
        
        
        driverTable.push( tableHeaders);
           
          
        for(var j=0; j<reportWeek.Drivers.length;j++){
        
            let driverRow=[];
            let driverCell={fontSize:7, text: reportWeek.Drivers[j].Name+'\n' + reportWeek.Drivers[j].Address, border: [true, true,true ,false]};
            driverRow.push(driverCell);
            var marriageDependentsText=reportWeek.Drivers[j].Dependents.toString() +'/';
            if(reportWeek.Drivers[j].FederalStatus=='Married')marriageDependentsText+='M'; else  if(reportWeek.Drivers[j].FederalStatus=='Single') marriageDependentsText+='S';
            else  if(reportWeek.Drivers[j].FederalStatus=='HH') marriageDependentsText+='HH'; else  if(reportWeek.Drivers[j].FederalStatus=='Excempt') marriageDependentsText+='E';
            driverRow.push({text:marriageDependentsText, rowSpan:2, margin:[-2,10,-2,0]});
            driverRow.push({ style:'tableFont', text:'Driver Dump Truck', rowSpan:2});
            driverRow.push({style:'tableFont', text:'S'});
            reportWeek.Drivers[j].totalHours=0;
            daysTable={fontSize:10, margin:[-5,-3,-5,-3], table: {heights: [20], alignment: 'center',  margin:[0,0,0,0], widths:['*','*','*','*','*','*','*'], body:[]}}
            let hourRow=[];
            for(var k=0; k< reportWeek.Drivers[j].hoursPerDay.length; k++){
                if(reportWeek.Drivers[j].hoursPerDay[k]!=0)  hourRow.push({text:reportWeek.Drivers[j].hoursPerDay[k], border:[true, true, true,false]}); else hourRow.push({text:'', border:[true, true, true,false]});
                    reportWeek.Drivers[j].totalHours+=reportWeek.Drivers[j].hoursPerDay[k];
            }
            
            daysTable.table.body.push(hourRow);
         
            reportWeek.Drivers[j].totalHours=   Number(reportWeek.Drivers[j].totalHours.toFixed(1));
            driverRow.push(daysTable);
            console.log('reportWeek.Drivers[j].allProjectsTotal .allProjectsTotal == ' + reportWeek.Drivers[j].allProjectsTotal)
            driverRow.push({fontSize:8, text:  reportWeek.Drivers[j].totalHours});
            if(!reportWeek.Drivers[j].projectPaid)reportWeek.Drivers[j].projectPaid=0;
            if(!reportWeek.Drivers[j].allProjectsTotal)reportWeek.Drivers[j].allProjectsTotal=0;
            if(!reportWeek.Drivers[j].FedTax)reportWeek.Drivers[j].FedTax=0;
            if(!reportWeek.Drivers[j].FICA)reportWeek.Drivers[j].FICA=0;
            if(!reportWeek.Drivers[j].SDI)reportWeek.Drivers[j].SDI=0;
            if(!reportWeek.Drivers[j].LocalTax)reportWeek.Drivers[j].LocalTax=0;
            if(!reportWeek.Drivers[j].OtherTax)reportWeek.Drivers[j].OtherTax=0;
            if(!reportWeek.Drivers[j].NetWages)reportWeek.Drivers[j].NetWages=0;
            if(!reportWeek.Drivers[j].CheckNo)reportWeek.Drivers[j].CheckNo=0;
            
            driverRow.push({style:'tableFont', text:  Number(prevailingWageReport.PrevailingWageRate || 0).toFixed(2)});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].projectPaid).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].allProjectsTotal).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].FedTax).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].FICA).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].SDI).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].LocalTax).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number( reportWeek.Drivers[j].OtherTax).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].OtherDed).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  Number(reportWeek.Drivers[j].NetWages).formatMoney(2), rowSpan:2});
            driverRow.push({style:'tableFont', text:  reportWeek.Drivers[j].CheckNo, rowSpan:2});
            
            console.log('scope.driverow .lenght = ' + driverRow.length);
            driverTable.push(driverRow);
            driverRow=[];
            driverRow.push({fontSize:7,text:  reportWeek.Drivers[j].City + ' ' +reportWeek.Drivers[j].State+ ' ' + reportWeek.Drivers[j].ZipCode+'\n' +  reportWeek.Drivers[j].SSN , border: [true, false,true ,true]});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({style:'tableFont',text:'O'});
            daysTable={fontSize:10, margin:[-5,-3,-5,-3], table: { heights: [20], alignment: 'center',  margin:[0,0,0,0], widths:['*','*','*','*','*','*','*'], body:[]}}
             hourRow=[];
             console.log(' reportWeek.Drivers[j].overtimePerDay arraty = ' ,  reportWeek.Drivers[j].overtimePerDay);
             reportWeek.Drivers[j].overtimeTotalHours=0;
            for(var k=0; k< reportWeek.Drivers[j].overtimePerDay.length; k++){
                if(reportWeek.Drivers[j].overtimePerDay[k]!=0)  hourRow.push({text:reportWeek.Drivers[j].overtimePerDay[k], border:[true, true, true,false]}); else hourRow.push({text:'', border:[true, true, true,false]});
                    reportWeek.Drivers[j].overtimeTotalHours+=Number(reportWeek.Drivers[j].overtimePerDay[k]);
            }
            console.log(' revailingWageReport.Drivers[j].overtimeTotalHours b4 format = ' +  reportWeek.Drivers[j].overtimeTotalHours);
            reportWeek.Drivers[j].overtimeTotalHours=Number(reportWeek.Drivers[j].overtimeTotalHours.toFixed(1));
            daysTable.table.body.push(hourRow);
            driverRow.push(daysTable);
            driverRow.push({fontSize:8, text: reportWeek.Drivers[j].overtimeTotalHours});
			driverRow.push({style:'tableFont', text:  Number(prevailingWageReport.OvertimePrevailingWageRate || 0).toFixed(2)});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverRow.push({});
            driverTable.push(driverRow);
                
            
        }
       
        driverReportDoc.content.push({ fontSize:6, table: { alignment: 'center', dontBreakRows: true, widths: tableWidths,body: driverTable}});
           
    }
    const createOwnerOpDriverPDF = async ( reportWeek, prevailingWageReport, driverReportDoc) => {
        let pageBreak=prevailingWageReport.createEmployeeDocs ? 'before' : '';
        let prevailingWageReportHeader={pageBreak:pageBreak, columns:[{margin:[0,5,0,0], text:[{text:'STATE OF CALIFORNIA * DEPARTMENT OF MOTOR TRANSPORTATION'},{text:'\n OWNER OPERATOR LISTING', bold:true},{text:'\n CEM-2505 (REV 05/2001)', fontSize:9, bold:true}], width:380},{text:'', width:125},
        {margin:[0,5,58,10], width:320, table:{widths:['*'], alignment:'right', headerRows:1, body:[[
            {alignment:'center', bold:true, fontSize:9, text:'ADA Notice', border:[true, true, true, false]}],
            [{fontSize:8, border:[true, false, true, true], text:'For individuals with sensory disabilities, this document is available in alternate formats. For information call (916) 654-6410 or TDD (916) 654-3880 or write REcords and Forms Management, 1120 N Street, MS-89, Sacramento, CA 95814.'}]]
            }}]};
        driverReportDoc.content.push(prevailingWageReportHeader);
        let tableHeaders=[];
        let driverTable=[];
        let daysTable={  colSpan:5, fontSize:5,  margin:[-5,-3,-5,-3], table: {headerRows:1,  alignment: 'center',  widths:['*','*','*','*','*','*','*','*'], body:[]}}
        let daysRow=[{colSpan:8, fontSize:9, alignment:'center', text:'DAY AND DATE'},{},{},{},{},{},{},{}];
        daysTable.table.body.push(daysRow);

        daysRow=[
            {text:'ST', fontSize: 8, alignment:'center',border:[true,true,true, false]},  
            {text:'S', alignment:'center', fontSize: 8,border:[true,true,true, false]}, 
            {text:'M', fontSize: 8, alignment:'center',border:[true,true,true, false]}, 
            {text:'T', fontSize: 8, alignment:'center',border:[true,true,true, false]}, 
            {text:'W', fontSize: 8, border:[true,true,true, false]}, 
            {text:'TH', fontSize: 8, alignment:'center', border:[true,true,true, false]},
            {text:'F', alignment:'center',fontSize: 8,border:[true,true,true, false]},
            {text:'S', alignment:'center', fontSize: 8,border:[true,true,true, false]}
        ];
        daysTable.table.body.push(daysRow);
        daysRow=[
            {text:'or', fontSize:7, alignment:'center', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}, 
            {text:'', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}, {text:'', border:[true,false,true, false]}
        ];
        
        daysTable.table.body.push(daysRow);
        daysRow=[];
        daysRow.push(  {text:'OT', alignment:'center',fontSize: 8, border:[true,false,true, false]},)
        var findingSaturdayDate=new Date(reportWeek.weekStartDate);
        for (var i=0; i<7; i++){
            const formattedDate = new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(findingSaturdayDate);

            daysRow.push({text:formattedDate,fontSize: 8,alignment:'center',  border:[true,false,true,false]})
            findingSaturdayDate.setDate(findingSaturdayDate.getDate()+1);    
        } 
        daysTable.table.body.push(daysRow);
        
        var tempStartDate=formatDate(reportWeek.weekStartDate,'/','MM/DD/YYYY');
        var tempEndDate=formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY');
    
        let firstRow=[{colSpan:8,text:'NAME OF CONTRACTOR EMPLOYING OWNER OPERATORS ', border:[true,true,true,false], fontSize:9},{},{},{},{},{},{},{},{fontSize:9, text:'ADDRESS', border:[true,true,true,false],colSpan:9},{},{},{},{},{},{},{},{}];
        let secondRow=[{colSpan:8,text:company.CompanyName,style:'headerField', border:[true,false,true,true]},{},{},{},{},{},{},{},{text: company.Address + ', ' +company.address2, style:'headerField', border:[true,false,true,true], colSpan:9, fontSize:9},{},{},{},{},{},{},{},{}];
        let thirdRow=[{ text:'PAYROLL NO.', border:[true,true,true,false], fontSize:9, colSpan:2},{},{ text:'FOR WEEK ENDING', colSpan:4, border:[true,true,true,false], fontSize:9},{},{},{},{text:'PROJECT AND LOCATION', colSpan:7, border:[true,true,true,false], fontSize:9},{},{},{},{},{},{},{text:'CONTRACT NUMBER: ', colSpan:4, border:[true,true,true,false], fontSize:9},{},{},{}];
        let fourthRow=[{ text:prevailingWageReport.OwnerOpPayrollNumber, alignment:'center', fontSize:12, bold:true, border:[true,false,true,true], colSpan:2},{},{text:tempStartDate + ' - ' + tempEndDate, bold:true, fontSize:10, colSpan:4, border:[true,false,true,true]},{},{},{},{alignment:'center', fontSize:11, bold:true, text:prevailingWageReport.ProjectLocation, colSpan:7, border:[true,false,true,true]},{},{},{},{},{},{},{alignment:'center', fontSize:11, bold:true, text:prevailingWageReport.ContractNumber, colSpan:4, border:[true,false,true,true]},{},{},{}];

        driverTable.push( firstRow);
        driverTable.push( secondRow);
        driverTable.push( thirdRow);
        driverTable.push( fourthRow);
        tableHeaders.push({fontSize:8,text:"NAME, ADDRESS, SOCIAL SECURITY NO. AND CONTRACTOR'S LICENSE NO. OF OWNER OPERATOR (IF ANY)", colSpan:2});
        tableHeaders.push({});
        tableHeaders.push({fontSize:8, alignment:'center',  text:'WORK CLASSIFICATION', colSpan:2});
        tableHeaders.push({});
        tableHeaders.push({fontSize:8,  alignment:'center', text:'DESCRIPTION OF EQUIPMENT', colSpan:2});
        tableHeaders.push({});
        tableHeaders.push({fontSize:8,  alignment:'center',text:'TRUCK CAL T NO AND, OR EQUIP. LICENSE NO.', colSpan:2});
        tableHeaders.push({});
        
        tableHeaders.push( daysTable);
        tableHeaders.push({});
        tableHeaders.push({});
        tableHeaders.push({});
        tableHeaders.push({});
        tableHeaders.push({fontSize:8,  alignment:'center', text:'TOTAL WEEKLY HOURS'});

        tableHeaders.push({fontSize:8,  alignment:'center', text:'HOURLY RATE OF PAY'});
        
        tableHeaders.push({fontSize:8,  alignment:'center', text:'GROSS PAYMENT EARNED'});
        
        tableHeaders.push({fontSize:8,  alignment:'center', text:'CHECK NO.'});


        let tableWidths=[90,5,45,25,25,25,25,35,5,150,10,20,20,30,30,40,35];
        
        
        driverTable.push( tableHeaders);
        
        
        for(var j=0; j<reportWeek.OwnerOps.length;j++){
            var SSN, EquipNo, ReportName;
                if(!reportWeek.OwnerOps[j].SSN)SSN=''; else SSN= reportWeek.OwnerOps[j].SSN;
                if(!reportWeek.OwnerOps[j].EquipNo)EquipNo=''; else EquipNo= reportWeek.OwnerOps[j].EquipNo;
                if(!reportWeek.OwnerOps[j].ReportName)if(!reportWeek.OwnerOps[j].DriverName)ReportName=''; else ReportName= reportWeek.OwnerOps[j].DriverName;else ReportName= reportWeek.OwnerOps[j].ReportName;
                let driverRow=[];
                
                let driverCell={fontSize:8, text: ReportName + '\n' + reportWeek.OwnerOps[j].Address, border: [true, true,true ,false], colSpan:2};
                driverRow.push(driverCell);
                driverRow.push({});
                driverRow.push({ style:'tableFont', text:'Owner Operator', rowSpan:2, colSpan:2});
                driverRow.push({});
                driverRow.push({ style:'tableFont', text:'2 AXLES', rowSpan:2, colSpan:2});
                driverRow.push({});
                driverRow.push({ style:'tableFont', text:EquipNo, rowSpan:2, colSpan:2});
                driverRow.push({});
            
                reportWeek.OwnerOps[j].totalHours=0;
                daysTable={colSpan:5,fontSize:10, margin:[-5,-3,-5,-3], table: {heights: [20], alignment: 'center',  margin:[0,0,0,0], widths:['*','*','*','*','*','*','*','*'], body:[]}}
                let hourRow=[];
                hourRow.push({text:'S',  alignment: 'center', border:[true, true, true,false]});
                for(var k=0; k< reportWeek.OwnerOps[j].hoursPerDay.length; k++){
                    console.log('reportWeek.OwnerOps[j].hoursPerDay[k] = ' + reportWeek.OwnerOps[j].hoursPerDay[k]);
                    if(reportWeek.OwnerOps[j].hoursPerDay[k]!=0)  hourRow.push({ fontSize:8, alignment:'right', text:reportWeek.OwnerOps[j].hoursPerDay[k], border:[true, true, true,false]}); else hourRow.push({text:'', border:[true, true, true,false]});
                    reportWeek.OwnerOps[j].totalHours+=Number(reportWeek.OwnerOps[j].hoursPerDay[k]);
                }
                reportWeek.OwnerOps[j].totalHours =Number(reportWeek.OwnerOps[j].totalHours.toFixed(1));
            
                daysTable.table.body.push(hourRow);
            
                driverRow.push(daysTable);
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                console.log('reportWeek.OwnerOps[j].allProjectsTotal .allProjectsTotal == ' + reportWeek.OwnerOps[j].allProjectsTotal)
                if(! reportWeek.OwnerOps[j].allProjectsTotal) reportWeek.OwnerOps[j].allProjectsTotal=0;
                driverRow.push({fontSize:8,  text:  reportWeek.OwnerOps[j].totalHours});
                console.log('reportWeek.OwnerOps[j].rateOfPay == ' +reportWeek.OwnerOps[j].rateOfPay)
				
                driverRow.push({style:'tableFont', text:  Number(reportWeek.OwnerOps[j].rateOfPay).formatMoney(2),  rowSpan:2});
                driverRow.push({style:'tableFont', text:  Number(reportWeek.OwnerOps[j].allProjectsTotal).formatMoney(2), rowSpan:2});
                driverRow.push({style:'tableFont', text: reportWeek.OwnerOps[j].CheckNo, rowSpan:2});
                
                console.log('scope.driverow .lenght = ' + driverRow.length);
                driverTable.push(driverRow);
            
                driverRow=[];
                driverRow.push({fontSize:8, colSpan:2, text:  reportWeek.OwnerOps[j].City + ' ' +reportWeek.OwnerOps[j].State+ ' ' + reportWeek.OwnerOps[j].ZipCode+'\n' +  SSN, border: [true, false,true ,true]});
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                daysTable={colSpan:5,fontSize:10, margin:[-5,-3,-5,-3], table: { heights: [20],alignment: 'center',  margin:[0,0,0,0], widths:['*','*','*','*','*','*','*','*'], body:[]}}
                hourRow=[];
                hourRow.push({text:'O', alignment: 'center', border:[true, true, true,false]});
                for(var k=0; k< reportWeek.OwnerOps[j].hoursPerDay.length; k++){
                //   if(reportWeek.OwnerOps[j].hoursPerDay[k]!=0)  hourRow.push({text:reportWeek.OwnerOps[j].hoursPerDay[k], border:[true, true, true,false]}); else 
                    hourRow.push({text:'', border:[true, true, true,false]});
                }
                daysTable.table.body.push(hourRow);
                
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
            
                driverRow.push(daysTable);
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                driverRow.push({fontSize:8, text: '0'});
                
                
                driverRow.push({});
                driverRow.push({});
                driverRow.push({});
                
                driverTable.push(driverRow);
                    
                
        }
    
        driverReportDoc.content.push({ fontSize:6, table: { alignment: 'center',widths: tableWidths,body: driverTable}});
        driverReportDoc.content.push({margin:[25,25,25, 400], text:'NOTE: CERTIFICATION WILL BE ACCEPTED ONLY FROM THE CONTRACTOR EMPLOYING THE OWNER OPERATOR. IT WILL NOT BE ACCEPTED FROM THE OWNER OPERATOR HIMSELF.'});
        
            
            
           
    }

    const createEmployeeCompliancePDF = async (signature, reportWeek, prevailingWageReport, driverComplianceDoc) => {
        let checkedBox = {  text: '✔',  font: 'ZapfDingbats' };  // Filled checkbox
        let uncheckedBox = {  text: '❑', font: 'ZapfDingbats'   }; // Empty checkbox
        let secondPageHeader={ fontSize:9, text:'STATE OF CALIFORNIA  DEPARTMENT OF TRANSPORTATION' };
        driverComplianceDoc.content.push(secondPageHeader);
        driverComplianceDoc.content.push({ margin: [0, 5, 0, 0], text: 'STATEMENT OF COMPLIANCE', fontSize: 13, bold: true });
        driverComplianceDoc.content.push({ margin: [0, 5, 0, 0], text: 'CP-CEM-2503  (OLD HC-348 REV 8/96)', fontSize: 7 });
       
   
        let firstTableRow = [
            { text: 'CONTRACTOR OR SUBCONTRACTOR', fontSize: 6, border: [false, true, true, false] },
            { text: 'CONTRACT NUMBER', fontSize: 6, border: [true, true, false, false] }
        ];
        let secondTableRow = [
            { text: company.CompanyName, style: 'headerField', border: [false, false, true, true] },
            { text: prevailingWageReport.ContractNumber, style: 'headerField', border: [true, false, false, true] }
        ];
        
        let thirdTableRow = [
            { text: 'FIRST DAY AND DATE OF THE PERIOD', fontSize: 6, border: [false, true, true, false] },
            { text: 'LAST DAY AND DATE OF PAY PERIOD', fontSize: 6, border: [true, true, false, false] }
        ];
        let fourthTableRow = [
            { text: 'Sunday - ' + formatDate(reportWeek.weekStartDate,'/','MM/DD/YYYY'), style: 'headerField', border: [false, false, true, true] },
            { text: 'Saturday - ' + formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY'), style: 'headerField', border: [true, false, false, true] }
        ];


        
        let secondPageHeaderTable = { table: { margin: [0, 0, 0, 0], alignment: 'center', widths: ['*', '*'], body: [] } };
 
        secondPageHeaderTable.table.body.push(firstTableRow);
        secondPageHeaderTable.table.body.push(secondTableRow);
        secondPageHeaderTable.table.body.push(thirdTableRow);
        secondPageHeaderTable.table.body.push(fourthTableRow);
        driverComplianceDoc.content.push(secondPageHeaderTable);
        
        var Driver2a, Driver2b, Driver4a, Driver4b, Driver4c;

        if (prevailingWageReport.Driver2a) Driver2a = checkedBox;else Driver2a = uncheckedBox;
        if (prevailingWageReport.Driver2b) Driver2b = checkedBox;else Driver2b = uncheckedBox;
        if (prevailingWageReport.Driver4a) Driver4a = checkedBox;else Driver4a = uncheckedBox;
        if (prevailingWageReport.Driver4b) Driver4b = checkedBox;else Driver4b = uncheckedBox;
        if (prevailingWageReport.Driver4c) Driver4c = checkedBox;else Driver4c = uncheckedBox;
       
        driverComplianceDoc.content.push({margin:[30,15,0,0], text:['Date:  ',{text:formatDateTwo(prevailingWageReport.SignatureDateValue), bold:true, fontSize:10, decoration:'underline'}]});
        
        driverComplianceDoc.content.push({margin:[30,5,0,0], text:['I, ', {fontSize:10 , bold:true, text:prevailingWageReport.Name, decoration:'underline'},'  ', { fontSize:10 , bold:true, text:prevailingWageReport.Title, decoration:'underline'}, {fontSize:9,  text:'  do hereby state: '}]});
        driverComplianceDoc.content.push({margin:[30,5,0,0], text:[{ fontSize:9, text:'(1)  That I pay or supervise the payment of persons employed by '}, 
            {text:company.CompanyName, fontSize:10 , bold:true,  decoration:'underline'},
            {text:' on the ', fontSize:10 },
            {text:prevailingWageReport.ProjectName ? prevailingWageReport.ProjectName : '', decoration:'underline',fontSize:10 },
            {fontSize:9, text:', that during the payroll period commencing on  '}, 
            {fontSize:10 , bold:true,  text:  formatDate(reportWeek.weekStartDate,'/','MM/DD/YYYY'), decoration:'underline'}, 
            {fontSize:9, text:'  and ending on  '}, 
            {fontSize:10 , bold:true,  text:  formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY'), decoration:'underline'},
      
            {fontSize:9, text:', all persons employed on said project have been paid the full weekly wages earned, that no rebates have been or will be made either directly or indirectly to or on behalf of said '},
            {text:company.CompanyName, fontSize:10 , bold:true,  decoration:'underline'},
            {fontSize:9, text:'  from the full weekly wages earned by any person and that no deductions have been made either directly or indirectly from the full wages earned by any person, other than permissible deductions as defined in Regulations, Part 3 (29 C.F.R. Subtitle A), issued by issued by the Secretary of Labor under the Copeland Act, as amended (48 Stat. 948, 63 Stat. 108, 72Stat. 967; 76 Stat. 357; 40 U.S.C. § 3145), and described below:'}]});
       
        
        //driverComplianceDoc.content.push({ text: 'I do hereby certify under the penalty of purjury:', style: 'pageNumberText', margin: [22, 15, 0, 0] });


       // driverComplianceDoc.content.push({  text: '(1) That I pay or supervise payment to employees of the above-referenced contractor on the above-referenced contract. All persons employed on said project for the above-referenced time period have been paid their full weekly wages earned, that no rebates have been or will be made either directly or indirectly to or on behalf of said contractor from the full weekly wages earned by any person and that no deductions have been made either directly or indirectly from the full wages earned by any person other than permissible deductions.', style: 'pageNumberText' });
        driverComplianceDoc.content.push({ text: '(2) That any payrolls otherwise under this contract required to be submitted for the above period are correct and complete; that the wage rates for laborers or mechanics contained there are not less than the applicable wage rates:', style: 'pageNumberText' });
        driverComplianceDoc.content.push({ text: ['(a) ', Driver2a, '  Specified in the applicable wage determination incorporated into the contract;'], style: 'pageLetterText' });
        driverComplianceDoc.content.push({ text: ['(b) ', Driver2b, '  Determined by the Director of Industrial Relations for the county or counties in which the work is performed;'], style: 'pageLetterText' });
        driverComplianceDoc.content.push({ text: 'that the classification set forth therein for each laborer or mechanic conform with the work he/she performed.', style: 'pageLetterText' });
        
        driverComplianceDoc.content.push({ text: '(3) That any apprentices employed in the above period are duly registered in a bona fide apprenticeship program registered with a State apprenticeship agency by the Bureau of Apprenticeship and Training., United States Department of LAbor  or if no such recognized agency exists in a State, are registered with the Bereau of Apprenticeship and Training, United States Department of Labor.', style: 'pageNumberText' });
        
        
        driverComplianceDoc.content.push({ columns:[{margin:[10,15,0,0],text:'(4)',fontSize:10, width:30}, { margin:[0,15,0,0], fontSize:10, text:[' That ']}]}); 
        driverComplianceDoc.content.push({
            columns:[{margin:[0,5,0,0],text:'', width:30},{ margin:[0,5,0,0], fontSize:10, text:'a)   WHERE FRINGE BENEFITS ARE PAID TO APPROVED PLANS, FUNDS, OR PROGRAMS. \n'}]});
   
        driverComplianceDoc.content.push({
            columns:[{margin:[0,5,0,0],text:'', width:30},{margin:[0,5,0,0],text:Driver4a, fontSize:12, width:12},{ fontSize:10, text:'  In addition to the basic hourly wage rates paid to each laborer or mechanic listed in the above referenced payroll or listings payments of fringe benefits of such employees, except as noted on Section 4(c) below. '}]});
        
        // OwnerOp4bText={margin:[50,0,0,0],text:[]};
        driverComplianceDoc.content.push({
            columns:[{margin:[0,5,0,0],text:'', width:30},{ margin:[0,15,0,0], fontSize:10, text:'b)   WHERE FRINGE BENEFITS ARE PAID IN CASH \n'}]});
       
        driverComplianceDoc.content.push({
            columns:[{margin:[0,5,0,0],text:'', width:30},{margin:[0,5,5,0],text:Driver4b,fontSize:12, width:12},{ fontSize:10, text:'  Each laborer or mechanic listed in the above refernce payroll or listings has been paid as indicated on the payroll or listings an amount not less than the sum of the applicable basic hourly wage rate plus the amount of the required fringe benefits as listed in the contract, except as noted in Section 4(c) below.'}]});
        
        
        
        let exceptionsAndSignatures = { margin: [0, 20, 0, 0], table: { alignment: 'center', widths: ['*', '*'], body: [] } };
        let exceptionsFirstRow = [
            { text: 'EXCEPTION (CRAFT)', alignment: 'center', fontSize: 9 },
            { text: 'EXPLANATION', alignment: 'center', fontSize: 9 }
        ];
        exceptionsAndSignatures.table.body.push(exceptionsFirstRow);
        
        
      
        let exceptionsSecondRow = [{ height: 40, text: [{ text: 'Remarks:   ', fontSize: 7 }, { text: prevailingWageReport.DriverRemarks, fontSize: 8 }], colSpan: 2, rowSpan: 10 }, {}];
        
        let signaturesFirstRow = [
            { text: 'NAME (PLEASE PRINT)', fontSize: 8, border: [true, true, true, false] },
            { text: 'TITLE', border: [true, true, true, false], fontSize: 8 }
        ];
        let signaturesSecondRow = [
            { text: prevailingWageReport.Name, style: 'headerField', border: [true, false, true, true] },
            { text: prevailingWageReport.Title, style: 'headerField', border: [true, false, true, true] }
        ];
        let signaturesThirdRow = [
            { text: 'SIGNATURE', fontSize: 8, border: [true, true, true, false] },
            { text: 'DATE', border: [true, true, true, false], fontSize: 8 }
        ];
        let signaturesFourthRow = [
            {  image: signature, height: 30, width:200, margin:[50,-10,0,0], border: [true, false, true, true] },
            { text: formatDate(prevailingWageReport.SignatureDateValue,'/','MM/DD/YYYY'), style: 'headerField', border: [true, false, true, true] }
        ];
           
        exceptionsAndSignatures.table.body.push(exceptionsSecondRow);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push(signaturesFirstRow);
        exceptionsAndSignatures.table.body.push(signaturesSecondRow);
        exceptionsAndSignatures.table.body.push(signaturesThirdRow);
        exceptionsAndSignatures.table.body.push(signaturesFourthRow);
        driverComplianceDoc.content.push(exceptionsAndSignatures);
        
        driverComplianceDoc.content.push({ text: 'On federally-funded projects, permissible deductions are defined in Regulations, Part 3 (29 CFR, Subtitle A), issued by the Secretary of Labor under the Copeland Act, as amended (48 Stat. 948 Stat. 108,72 Stat. 967;76 Stat 357:40 U.S.C. 276c).', style: 'pageNumberText' });
        driverComplianceDoc.content.push({ text: 'Also, the willful falsification of any of the above statements may subject the contractor or subcontractor to civil or criminal prosecution (See Section 1001 of Title 18 and Section 231 of Title 31 of the United States Code).', style: 'pageNumberText' });
        
        
        const driversWithExceptions = reportWeek.Drivers.filter(driver => 
            driver.Exception && driver.Exception.trim() !== ''
        );
        console.log('driversWithExceptions', driversWithExceptions)
        if (driversWithExceptions.length > 0)  await createEmployeeDeductionNotesPDF(driversWithExceptions,  driverComplianceDoc);
    }
    const createEmployeeDeductionNotesPDF = async (driversWithExceptions, driverComplianceDoc) => {
        // Filter drivers to only those with exceptions
     

        // Only create the table if there are drivers with exceptions
      
            // Add page break if needed
            let pageBreak = driverComplianceDoc.content.length > 0 ? 'before' : '';
            
            // Add the header
            driverComplianceDoc.content.push({
                pageBreak: pageBreak,
                text: 'Other Deductions Notes',
                fontSize: 12,
                margin: [0, 10, 0, 10]
            });

            // Create the table
            let deductionsTable = {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: [
                        // Header row
                        [
                            { text: 'Employee Name', style: 'tableHeader' },
                            { text: 'Craft', style: 'tableHeader' },
                            { text: 'Classification', style: 'tableHeader' },
                            { text: 'Other Deduction Notes', style: 'tableHeader' }
                        ]
                    ]
                }
            };

            // Add only drivers with exceptions
            driversWithExceptions.forEach(driver => {
                deductionsTable.table.body.push([
                    { text: `${driver.LastName}, ${driver.FirstName}` },
                    { text: 'DRIVER (ON/OFF-HAULING TO/FROM CONSTRUCTION SITE)' },
                    { text: 'Driver: Dump Truck' },
                    { text: driver.Exception }
                ]);
            });

            // Add the table to the document
            driverComplianceDoc.content.push(deductionsTable);
       
    };
    const createOwnerOpCompliancePDF = async (signature, reportWeek, prevailingWageReport, driverComplianceDoc) => {
        let checkedBox = {  text: '✔',  font: 'ZapfDingbats' };  // Filled checkbox
        let uncheckedBox = {  text: '❑', font: 'ZapfDingbats'   }; // Empty checkbox
        let pageBreak=prevailingWageReport.createEmployeeDocs ? 'before' : '';
        let prevailingWageReportHeader={pageBreak:pageBreak, columns:[{margin:[0,5,0,0], text:[{text:'STATE OF CALIFORNIA * DEPARTMENT OF MOTOR TRANSPORTATION'},{text:'\n OWNER-OPERATOR LISTING STATE OF COMPLIANCE'},{text:'\n CEM-2505 (REV 05/2001)', fontSize:9, bold:true}], width:380},{text:'', width:125},
            {margin:[0,5,10,8], width:320, text:''}]};
        driverComplianceDoc.content.push(prevailingWageReportHeader);


		   
        driverComplianceDoc.content.push({margin:[30,15,0,0], text:['Date:  ',{text:formatDateTwo(prevailingWageReport.SignatureDateValue), bold:true, fontSize:10, decoration:'underline'}]});
        
        driverComplianceDoc.content.push({margin:[30,5,0,0], text:['I, ', {fontSize:10 , bold:true, text:prevailingWageReport.Name, decoration:'underline'},'  ', { fontSize:10 , bold:true, text:prevailingWageReport.Title, decoration:'underline'}, {fontSize:9,  text:'  do hereby state: '}]});

        driverComplianceDoc.content.push({margin:[30,5,0,0], text:[{ fontSize:9, text:'(1)  That I pay or supervise the payment of persons reported on this form as Owner-Operators by '},
		 {text:company.CompanyName, fontSize:10 , bold:true,  decoration:'underline'},
		 {text:' on the ', fontSize:10 },
		 {text:prevailingWageReport.ProjectName ? prevailingWageReport.ProjectName : '', decoration:'underline',fontSize:10 },
		 {fontSize:9, text:', that during the payroll period commencing on  '}, 
		 {fontSize:10 , bold:true,  text:  formatDate(reportWeek.weekStartDate,'/','MM/DD/YYYY'), decoration:'underline'}, 
		 {fontSize:9, text:'  and ending on  '}, 
		 {fontSize:10 , bold:true,  text:  formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY'), decoration:'underline'},

		{fontSize:9, text:', all persons working on said project have been paid the full weekly sums earned, that no rebates have been or will be made either directly or indirectly to or on behalf of said '},
		 {text:company.CompanyName, fontSize:10 , bold:true,  decoration:'underline'},
		{fontSize:9, text:' from the full weekly sums earned by any person and that no deductions have been made either directly or indirectly from the full sums earned by any person, other than permissable deductions, as described here:'+
        ' __________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________'}]});
       
        driverComplianceDoc.content.push({
            columns:[{margin:[30,15,0,0],text:'(2)', width:60}, { margin:[0,15,0,0], text:' That any payrolls or listings otherwise under this contract required to be submitted for the above period are correct and complete; that the wage rates for laborers or mehanics contained therein are not less than the applicable wage rates contained in any wage determination incorporated into this contract; that the classification set forth therein for each laborer or mechanic conforms with the work he performed.'}]}); 
        driverComplianceDoc.content.push({
            columns:[{margin:[30,15,0,0],text:'(3)', width:60}, { margin:[0,15,0,0],  text:'  That any apprentices employed in the above period are duly registered in a bona fide apprenticeship program registered with a State apprenticeship agency by the Bureau of Apprenticeship and Training., United States Department of LAbor  or if no such recognized agency exists in a State, are registered with the Bereau of Apprenticeship and Training, United States Department of Labor.'}]});
        
        driverComplianceDoc.content.push({ columns:[{margin:[30,15,0,0],text:'(4)', width:60}, { margin:[0,15,0,0], text:[' That ']}]}); 
        //  OwnerOp4aText={margin:[50,0,0,0],text:[]};
        driverComplianceDoc.content.push({
            columns:[{margin:[30,0,0,0],text:'', width:60},{text:'a)   WHERE FRINGE BENEFITS ARE PAID TO APPROVED PLANS, FUNDS, OR PROGRAMS. \n'}]});
        var OwnerOp4aCheckbox, OwnerOp4bCheckbox;
        if(prevailingWageReport.OwnerOp4a) OwnerOp4aCheckbox=checkedBox; else OwnerOp4aCheckbox=uncheckedBox;
          
           
        driverComplianceDoc.content.push({
            columns:[{margin:[30,5,0,0],text:'', width:60},{margin:[0,5,0,0],text:OwnerOp4aCheckbox, fontSize:15, width:20},{ text:'  In addition to the basic hourly wage rates paid to each laborer or mechanic listed in the above referenced payroll or listings payments of fringe benefits of such employees, except as noted on Section 4(c) below. '}]});
        
        // OwnerOp4bText={margin:[50,0,0,0],text:[]};
        driverComplianceDoc.content.push({
            columns:[{margin:[30,5,0,0],text:'', width:60},{ margin:[0,15,0,0], text:'b)   WHERE FRINGE BENEFITS ARE PAID IN CASH \n'}]});
        if(prevailingWageReport.OwnerOp4b)OwnerOp4bCheckbox=checkedBox; else OwnerOp4bCheckbox=uncheckedBox;
        driverComplianceDoc.content.push({
            columns:[{margin:[30,5,0,0],text:'', width:60},{margin:[0,5,0,0],text:OwnerOp4bCheckbox,fontSize:15, width:20},{text:'  Each laborer or mechanic listed in the above refernce payroll or listings has been paid as indicated on the payroll or listings an amount not less than the sum of the applicable basic hourly wage rate plus the amount of the required fringe benefits as listed in the contract, except as noted in Section 4(c) below.'}]});
        
        //driverComplianceDoc.content.push( OwnerOp4aText); 
        // driverComplianceDoc.content.push(  OwnerOp4bText);
            driverComplianceDoc.content.push({margin:[50,15,0,0],text:'c)   EXCEPTIONS'});
        let exceptionsAndSignatures = { margin: [0, 20, 0, 0], table: { alignment: 'center', widths: ['*', '*'], body: [] } };
        let exceptionsFirstRow = [
            { text: 'EXCEPTION (CRAFT)', alignment: 'center', fontSize: 9 },
            { text: 'EXPLANATION', alignment: 'center', fontSize: 9 }
        ];
        exceptionsAndSignatures.table.body.push(exceptionsFirstRow);
        
        
        for (var j = 0; j < reportWeek.OwnerOps.length; j++) {
            if (reportWeek.OwnerOps[j].Exception) {
                console.log('for ' + reportWeek.OwnerOps[j].Name + 'LENGHT = ' + reportWeek.OwnerOps[j].Exception.length);
                console.log('teh exception = '+reportWeek.OwnerOps[j].Exception);
                var driverExceptionRow = [{ text: reportWeek.OwnerOps[j].ReportName }, { text: reportWeek.OwnerOps[j].Exception }];
                exceptionsAndSignatures.table.body.push(driverExceptionRow);
            }
        }
        let exceptionsSecondRow = [{ height: 40, text: [{ text: 'Remarks:   ', fontSize: 7 }, { text: prevailingWageReport.OwnerOpRemarks, fontSize: 8 }], colSpan: 2, rowSpan: 10 }, {}];
        
        let signaturesFirstRow = [
            { text: 'NAME AND TITLE', fontSize: 8, border: [true, true, true, false] },
            { text: 'SIGNATURE', fontSize: 8, border: [true, true, true, false] }
        ];
        let signaturesSecondRow = [
            { text: prevailingWageReport.Name +'        ' +  prevailingWageReport.Title, margin:[5,0,0,0], style: 'headerField', border: [true, false, true, true] },
            {  image: signature, height: 30,width:200, margin:[50,-10,0,0], border: [true, false, true, true] }
        ];
        
          
           
        exceptionsAndSignatures.table.body.push(exceptionsSecondRow);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push([{}, {}]);
        exceptionsAndSignatures.table.body.push(signaturesFirstRow);
        exceptionsAndSignatures.table.body.push(signaturesSecondRow);
        
        driverComplianceDoc.content.push(exceptionsAndSignatures);
    }
    const createPrevailingWagePDFs = async (signature, reportWeek, prevailingWageReport) => {
        console.log('signature = ', signature)
        console.log('prevailingWageReport= ', prevailingWageReport)
        let driverReportDoc = {
               content: [],
               pageMargins: [15, 10, 35, 0],
               pageOrientation: 'landscape',
               fontSize:9,
               
               styles: {
                 
                   header: {
                       fontSize: 18,
                       bold: true,
                       margin: [0, 0, 0, 0]
                   },
              
                   tableExample: {
                       margin: [0,5, 0, 5]
                   },
                   tableHeader: {
                       bold: true,
                       fontSize: 13,
                       color: 'black'
                   },
                   smallText:{
                         margin: [0, 10, 0, 0],
                       fontSize: 9,
                   },
                   tableFont:{
                       fontSize: 8, 
                       alignment:'center',
                       margin:[-2,10,-2,0]
                   },
                    signatureTableCell:{
                        fontSize: 7,
                        border: [false, false,false ,false]
                   },
                   headerField:{
                       fontSize:13,
                       margin:[60,5,0,0],
                       bold:true
                   },
                   pageLetterText:{
                       fontSize:10,
                       margin:[35,5,15,0]
                      
                   },
                    pageNumberText:{
                       fontSize:10,
                       margin:[20,5,15,0]
                      
                   },
                 
               }
        };
          
        let driverComplianceDoc = {
            content: [],
            pageMargins: [20,35, 20, 0],
            pageOrientation: 'portrait',
            fontSize:9,
            fonts:pdfMake.fonts,
            styles: {
                wingding :{ font: 'ZapfDingbats' },
              
                icon: { font: 'icons' },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
            
                tableExample: {
                    margin: [0,5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText:{
                        margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                tableFont:{
                    fontSize: 9, 
                    alignment:'center',
                    margin:[-2,10,-2,0]
                },
                signatureTableCell:{
                    fontSize: 7,
                    border: [false, false,false ,false]
                },
                headerField:{
                    fontSize:10,
                    margin:[60,0,0,0],
                    bold:true
                },
                pageLetterText:{
                    fontSize:9,
                    margin:[35,5,0,0]
                    
                },
                pageNumberText:{
                    fontSize:9,
                    margin:[10,15,15,0]
                    
                },
                
            }
        };  
        let tempReportWeek = {...reportWeek};
        if(prevailingWageReport.createEmployeeDocs) {
            tempReportWeek.VNum = prevailingWageReport.DriverPayrollNumber ? prevailingWageReport.DriverPayrollNumber :1
           

            await createEmployeeDriverPDF(reportWeek, prevailingWageReport, driverReportDoc);
            if(prevailingWageReport.createOwnerOpDocs) {
                tempReportWeek.VNum = prevailingWageReport.DriverPayrollNumber ? prevailingWageReport.DriverPayrollNumber :prevailingWageReport.OwnerOpPayrollNumber ? prevailingWageReport.OwnerOpPayrollNumber : 1;
                await createOwnerOpDriverPDF(reportWeek, prevailingWageReport, driverReportDoc);
            }
            await createEmployeeCompliancePDF(signature, reportWeek, prevailingWageReport, driverComplianceDoc);
            if(prevailingWageReport.createOwnerOpDocs) {
                await createOwnerOpCompliancePDF(signature, reportWeek, prevailingWageReport, driverComplianceDoc);
            }
        }
        else if(prevailingWageReport.createOwnerOpDocs) {
            tempReportWeek.VNum = prevailingWageReport.OwnerOpPayrollNumber ? prevailingWageReport.OwnerOpPayrollNumber : 1;

            await createOwnerOpDriverPDF(reportWeek, prevailingWageReport, driverReportDoc);
            await createOwnerOpCompliancePDF(signature, reportWeek, prevailingWageReport, driverComplianceDoc);
        }
     
       

        let newDoc={VNum: tempReportWeek.VNum, driverURL:'', compURL:'' };
        let promises =[];

        let tempPrevailingWageReport = {...prevailingWageReport};
       
        console.log('tempPrevailingWageReport right before i set it = ', tempPrevailingWageReport)
        setDocument(tempPrevailingWageReport,prevailingWageReport.ID,'PrevailingWageReports')

        console.log('driverReportDoc = ', driverReportDoc)
        console.log('driverComplianceDoc = ', driverComplianceDoc)

        promises.push(new Promise(async (resolve, reject) => {
            pdfMake.createPdf(driverReportDoc).getBase64(async function(encodedString) {
         
                let base64PDF = encodedString;
                let driverDocStorageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/PrevailingWage/' + prevailingWageReport.ID +'/DriverDoc'+  reportWeek.Docs.length + '.pdf');
                const driverDocSnapshot = await uploadString(driverDocStorageRef, base64PDF, 'base64');
                const url = await getDownloadURL(driverDocStorageRef);
                newDoc.driverURL = url;
                resolve();
            });
          
        }));
        promises.push(new Promise(async (resolve, reject) => {
            
            pdfMake.createPdf(driverComplianceDoc).getBase64(async function(encodedString) {
         
                let base64PDF = encodedString;
                let complianceDocStorageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/PrevailingWage/' + prevailingWageReport.ID +'/ComplianceDoc'+  reportWeek.Docs.length + '.pdf');
                const compDocSnapshot = await uploadString(complianceDocStorageRef, base64PDF, 'base64');
                const url = await getDownloadURL(complianceDocStorageRef);
                newDoc.compURL=url;
                resolve();
            });
        }));
      
     await Promise.all(promises);
 
     tempReportWeek.Docs.push(newDoc);
     let setDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/PrevailingWageReports/'+prevailingWageReport.ID+'/Weeks/', reportWeek.ID);
     setDoc(setDocRef,tempReportWeek);
  
    }
    const createNonPerformancePDF = async(reportWeek, prevailingWageReport)=>{
    
		 		
    
        let nonPerfReportDoc = {
            content: [],
            pageMargins: [15, 10, 35, 0],
            pageOrientation: 'portrait',
            fontSize:5,
            
            styles: {
                icon: { font: 'icons' },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
            
                tableExample: {
                    margin: [0,5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText:{
                        margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                tableFont:{
                    fontSize: 8, 
                    alignment:'center',
                    margin:[-2,10,-2,0]
                },
                    signatureTableCell:{
                        fontSize: 7,
                        border: [false, false,false ,false]
                },
                headerField:{
                    fontSize:13,
                    margin:[60,5,0,0],
                    bold:true
                },
                pageLetterText:{
                    fontSize:10,
                    margin:[35,5,15,0]
                    
                },
                    pageNumberText:{
                    fontSize:10,
                    margin:[20,5,15,0]
                    
                },
                
            }
        };
        console.log('reportWeek = ', reportWeek)
        let convertedStartDate = formatDate(reportWeek.weekStartDate,'/','MM/DD/YYYY');
        let convertedEndDate = formatDate(reportWeek.weekEndDate,'/','MM/DD/YYYY');
            let reportHeader0={margin:[15,40,15,0], fontSize:10, alignment:'right', text:formatDate(prevailingWageReport.SignatureDateValue,'/','MM/DD/YYYY') };
            let reportHeader1={margin:[25,5,0,0], fontSize:10,  text:prevailingWageReport.Company.CompanyName};
            let reportHeader2={margin:[25,0,0,0], fontSize:10,  text:prevailingWageReport.Company.Address};
            let reportHeader3={margin:[25,0,0,0], fontSize:10,  text:prevailingWageReport.Company.address2};
            if(!prevailingWageReport.Contractor)prevailingWageReport.Contractor={Name:''};
            let reportHeader4={margin:[25,25,15,0],fontSize:10, alignment:'right', text:'CERTIFIED PAYROLL NON-PERFORMANCE', bold:true };
            let reportHeader5={margin:[25,25,0,0], fontSize:10, text:prevailingWageReport.Account.Name};
            let reportHeader6={margin:[25,0,0,0], fontSize:10,  text:prevailingWageReport.Account.Address};
            let reportHeader7={margin:[25,0,0,0], fontSize:10,  text:prevailingWageReport.Account.City+', '+prevailingWageReport.Account.State+', '+prevailingWageReport.Account.ZipCode};
            let reportHeader8={margin:[25,20,0,0],fontSize:10,  text:'Number: ', bold:true};	
            let reportHeader9={margin:[25,20,0,0],fontSize:10,  text:[{text: 'Bid Package Number: ' }, {text:'N/A', bold:true }]};	
            let reportHeader10={margin:[25,0,0,0], fontSize:10, text:[{text: 'Name of Contractor: ' }, {text:prevailingWageReport.Contractor.Name ,bold:true}] };	
            let reportHeader11={margin:[25,20,0,0],fontSize:10, text:'I do hereby state that no persons were employed on the contstruction site of: '};
            let reportHeader12={margin:[25,5,0,0], fontSize:10, text:[{text: 'Project Location: ' }, {text:prevailingWageReport.ProjectLocation, bold:true}]};	
            let reportHeader13={margin:[25,5,0,0], fontSize:10, text:[{text: 'Contract Number: ' }, {text:prevailingWageReport.ContractNumber, bold:true}]};	
            let reportHeader14={margin:[25,5,0,0], fontSize:10, text:[{text: prevailingWageReport.Account.Name +' Project #: ' }, {text:prevailingWageReport.JobNumber,bold:true}]};	
            let reportHeader15={margin:[25,5,0,0], fontSize:10, text:[{text:'During the payroll period commencing on '},{text: convertedStartDate, decoration:'underline', bold:true} ,{text:'  thru '}, {text:convertedEndDate , decoration:'underline', bold:true}]};	
        
            let reportHeader16={margin:[25,30,0,0], fontSize:10, text: 'X                                                                                              '+prevailingWageReport.Name+' '+prevailingWageReport.Title+'                 ' , decoration:'underline' };	
            let reportHeader17={margin:[25,2,0,0], text: 'SIGNATURE                                                                                                                          NAME AND TITLE' ,fontSize:7 };	
        
        
            let reportHeader18={margin:[25,20,0,0],fontSize:12,   text:'FINAL PAYROLL:  YES  NO'};	
            let reportHeader19={margin:[25,20,25,0],fontSize:10,   text:'This statement is not required to be submitted until after submission of hte initial payroll report. It must then be submitted by the contractor for each week in place of a certfied payroll where no work is performed until the final certified payroll is submitted.'};	
    
            nonPerfReportDoc.content.push(reportHeader0);
            nonPerfReportDoc.content.push(reportHeader1);
            nonPerfReportDoc.content.push(reportHeader2);
            nonPerfReportDoc.content.push(reportHeader3);
            nonPerfReportDoc.content.push(reportHeader4);
            nonPerfReportDoc.content.push(reportHeader5);
            nonPerfReportDoc.content.push(reportHeader6);
            nonPerfReportDoc.content.push(reportHeader7);
            nonPerfReportDoc.content.push(reportHeader8);
            nonPerfReportDoc.content.push(reportHeader9);
            nonPerfReportDoc.content.push(reportHeader10);
            nonPerfReportDoc.content.push(reportHeader11);
            nonPerfReportDoc.content.push(reportHeader12);
            nonPerfReportDoc.content.push(reportHeader13);
            nonPerfReportDoc.content.push(reportHeader14);
            nonPerfReportDoc.content.push(reportHeader15);
            nonPerfReportDoc.content.push(reportHeader16);
            nonPerfReportDoc.content.push(reportHeader17);
            nonPerfReportDoc.content.push(reportHeader18);
            nonPerfReportDoc.content.push(reportHeader19);
                 
         
            setDocument(prevailingWageReport,prevailingWageReport.ID,'PrevailingWageReports')    

            pdfMake.createPdf(nonPerfReportDoc).getBase64(async function(encodedString) {
         
                let base64PDF = encodedString;
                let nonPerfDocStorageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/PrevailingWage/' + prevailingWageReport.ID +'/NonPerformance'+  reportWeek.Docs.length + '.pdf');
                const nonPerfDocSnapshot = await uploadString(nonPerfDocStorageRef , base64PDF, 'base64');
                const url = await getDownloadURL(nonPerfDocStorageRef );
                let tempReportWeek = {...reportWeek};
                tempReportWeek.VNum++;
                let newDoc={VNum:  tempReportWeek.VNum, nonPerformanceURL:url, driverURL:'', compURL:'' };
              
                tempReportWeek.Docs.push(newDoc);
                let setDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/PrevailingWageReports/'+prevailingWageReport.ID+'/Weeks/', reportWeek.ID);
             
                setDoc(setDocRef,tempReportWeek);
              
            });
        
                            
          
    }
    function formatDateTwo(date) {
        var monthNames = [
        "Jan.", "Feb.", "Mar.",
        "Apr.", "May", "Jun.", "Jul.",
        "Aug.", "Sep.", "Oct.",
        "Nov.", "Dec."
        ];
        
        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();
    
        return monthNames[monthIndex] +  ' '  + day + ',' +  ' ' + year;
    }
    return {
        createPrevailingWagePDFs,
        createNonPerformancePDF
    };
}; 