
import React, {useState, useEffect, useCallback} from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../context/AuthContext';
import { Button} from  'primereact/button';

import { useJobsProfit } from '../../context/JobsProfitContext';
import {  useNavigate } from 'react-router-dom'
import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import debounce from 'lodash/debounce';
const JobsProfitHome = () => {

    const {formatDate,formatMoney } = UserAuth();
    const {queryWeekFreightBills, queryWeekDispatches, dispatches, isLoading, profitHomeFreights, setJobDispatches, setIsLoading} = useJobsProfit();
    const [inputValues, setInputValues]= useState({StartDateValue:new Date(), EndDateValue: new Date().setDate(new Date().getDate() -14)});

 
    const navigate = useNavigate();

    const addNewJob = (jobList, dispatch)=>{
        let newJob={
            ID:dispatch.JobID,
            Account:dispatch.Account,
            LoadSite:dispatch.LoadSite,
            DumpSite:dispatch.DumpSite,
            JobNumber:dispatch.JobNumber,
            jobDateValue: new Date(dispatch.JobDate),
            QueryDate: dispatch.QueryDate,
            netPercent:dispatch.netPercent,
            netPercentNumber:dispatch.netPercentNumber,
            totalBilledNumber:dispatch.totalBilledNumber,
            totalPaidNumber:dispatch.totalPaidNumber,
            profitNumber:dispatch.profitNumber,
            totalPaid:dispatch.totalPaid,
            totalBilled:dispatch.totalBilled,
            profit:dispatch.profit,
            dispatches:[],
            dispatchCount:1
        }
        console.log('adding a new job based onte h disapthc = ', dispatch)
        newJob.dispatches.push(dispatch);
        jobList.push(newJob);
    }

    
    
    const calculateDispatchProfit = (jobList, dispatch)=>{
        dispatch.totalBilledNumber = 0;
        dispatch.totalPaidNumber = 0;
        dispatch.jobDateValue=new Date(dispatch.JobDate);
        const dispatchFreightBills = profitHomeFreights.filter(freightBill => freightBill.dispatchID === dispatch.ID);
        for(var k=0; k<dispatchFreightBills.length; k++){
            dispatch.totalBilledNumber+=Number(dispatchFreightBills[k].bTotal);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidQty)*Number(dispatchFreightBills[k].PayRate);

            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].standPaid);
            if(dispatchFreightBills[k].paidExpenses) dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidExpenses);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidBrokerFee);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].tFee)
        
      
        }
        dispatch.profitNumber=dispatch.totalBilledNumber-dispatch.totalPaidNumber;
        if(dispatch.profitNumber!==0) dispatch.netPercentNumber =  Number(dispatch.profitNumber/dispatch.totalBilledNumber)*100;
        
        let foundJob = false;
        for(let j=0; j<jobList.length; j++){
            if(dispatch.JobID===jobList[j].ID){
                foundJob=true;
                if(jobList[j].QueryDate>dispatch.QueryDate){
                    jobList[j].QueryDate=dispatch.QueryDate;
                    jobList[j].jobDateValue=new Date(dispatch.JobDate);
                }
                jobList[j].totalBilledNumber+=Math.round((dispatch.totalBilledNumber + Number.EPSILON) * 100) / 100;
                jobList[j].totalPaidNumber+= Math.round((dispatch.totalPaidNumber+ Number.EPSILON) * 100) / 100;
                jobList[j].dispatchCount++;
                jobList[j].dispatches.push(dispatch);
            }
        }
        if(!foundJob)addNewJob(jobList, dispatch);

    }
   
    let jobList = [];
    for(let d=0; d< dispatches?.length; d++)calculateDispatchProfit(jobList,   dispatches[d]);
    let sortedJobs = [...jobList].sort((a, b) => {
        if (a.QueryDate < b.QueryDate) return -1;
        if (a.QueryDate > b.QueryDate) return 1;
        return 0;
    });

    sortedJobs.forEach(job => {
        job.profitNumber = job.totalBilledNumber - job.totalPaidNumber;
        if(job.profitNumber!==0){
            if(job.totalBilledNumber!==0)job.netPercentNumber = (Number(job.profitNumber) / Number(job.totalBilledNumber)) * 100 ;
            else job.netPercentNumber = -100;
        }else job.netPercentNumber=0;

        
    });
    const [filteredJobs, setFilteredJobs]=useState(sortedJobs);
    useEffect(()=>{
        setFilteredJobs([...sortedJobs])
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            'LoadSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'DumpSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
            'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
          
            BillType:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        })
    },[dispatches])

    useEffect(()=>{
        let endDate = new Date();
        const startDate= new Date();
        startDate.setDate(endDate.getDate() -14);
        setInputValues({StartDateValue:startDate,  StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'),  EndDateValue:endDate, EndDate:formatDate(endDate, '/', 'YYYY/MM/DD')});
        startJobsProfitPage(startDate, endDate);
        
       
    },[])


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'LoadSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'DumpSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
      
        BillType:{ value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    
    const startJobsProfitPage = (startDate, endDate)=>{
        queryWeekFreightBills(startDate, endDate)
        queryWeekDispatches(startDate, endDate);

    }


    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName] = formattedDate;
        startJobsProfitPage( tempInputValues.StartDate, tempInputValues.EndDate)
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.jobDateValue, '/', 'MM/DD/YYYY');
    };   

    const buttonBodyTemplate = (rowData, buttonText) => {
        let tempFunction;
        if(!buttonText){
            tempFunction = () => openDispatch(rowData);
            buttonText = rowData.JobNumber  ? rowData.JobNumber : 'Open';
        }
        return <Button color="primary"  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}} onClick={(e) => tempFunction()}>
          <span>{buttonText}</span></Button>;
    };
    const currencyBodyTemplate = (rowData, field) => {
       const formattedNumber =  Number(rowData[field]).formatMoney(2);
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
    const percentBodyTemplate = (rowData, field) => {
        const formattedNumber = rowData[field] ? Number(rowData[field]).formatMoney(0) : 0;
         return (
            <span style={{paddingRight:".5em", float:"right"}}>{formattedNumber}%</span>
         );
     };
    const openDispatch =(rowData) => {
            console.log('RUNNIGN DISPATCH SEARCH OPEN job = ', rowData)
            setJobDispatches([...rowData.dispatches])
            const encodedStartDate = encodeURIComponent(formatDate(inputValues.StartDateValue,'/','YYYY/MM/DD'));
            const encodedEndDate = encodeURIComponent(formatDate(inputValues.EndDateValue,'/','YYYY/MM/DD'));

            navigate('/jobProfit/jobView/'+rowData.ID+'/'+encodedStartDate+'/'+encodedEndDate)
    }
 
    const totalColumn = (fieldName, currency)=>{
        let grandTotal = 0;
        filteredJobs.forEach(job => {
            grandTotal+= job[fieldName] ? job[fieldName] : 0;
        });
    
       return currency ? '$'+ Number(grandTotal).formatMoney(2) : Number(grandTotal);
    }

      const debouncedFilter = useCallback(
            debounce((value) => {
            setFilteredJobs([...value])
            }, 100),
            []
        );
    
        const handleFilterChange = (e) => {  debouncedFilter(e);   };
    return(
        <div className="card mbsc-grid ">
            <div className='mbsc-row'> 
            <div className="mbsc-col-xl-3 mbsc-offset-xl-2" style={{paddingRight:".5em"}}>  
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'} ,tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                </div> 
            
            </div>
            <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
            
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'}, tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}}value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                </div>
            </div>
            <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                  <Button  color="primary"  onClick={(e) => startJobsProfitPage(inputValues.StartDateValue, inputValues.EndDate)} style={{margin:"0",  marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Search Dates</Button> 
                </div>
            </div>                     
            <DataTable 
                value={sortedJobs} 
                paginator 
                rows={25} 
                dataKey="index" 
                filters={filters} 
                onValueChange={(e)=>handleFilterChange(e)} 
                header='Jobs' 
                filterDisplay="row" 
                emptyMessage="No Jobs found."
            >
                <Column  header="Job #"  body={(rowData) =>buttonBodyTemplate(rowData)}  />
                <Column field="jobDateValue" header="Job Date" dataType="date" sortable body={dateBodyTemplate}   filterPlaceholder="Search by Date" />
                <Column header="Customer" filter filterField="Account.Name" filterPlaceholder="Search by Customer"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>
            
                <Column header="Load Site" filter filterField="LoadSite.Name" filterPlaceholder="Search by Load Site"  body={(rowData) => rowData.LoadSite?.Name || 'N/A'}/>
                <Column header="Dump Site" filter filterField="DumpSite.Name" filterPlaceholder="Search by Dump Site"  body={(rowData) => rowData.DumpSite?.Name || 'N/A'}/>
                <Column header="Dispatches" sortable field="dispatchCount" footer={totalColumn('dispatchCount')}/>
                <Column header="Billed" sortable field="totalBilledNumber" footer={totalColumn('totalBilledNumber', true)} body={(rowData) => currencyBodyTemplate(rowData, 'totalBilledNumber')}  />
                <Column header="Paid" sortable field="totalPaidNumber" footer={totalColumn('totalPaidNumber', true)} body={(rowData) => currencyBodyTemplate(rowData, 'totalPaidNumber')}  />
                <Column header="Net" sortable field="profitNumber"  footer={totalColumn('profitNumber', true)} body={(rowData) => currencyBodyTemplate(rowData, 'profitNumber')}  />
                <Column  style={{ minWidth: '6rem' }} header="Net %" sortable field="netPercentNumber" body={(rowData) => percentBodyTemplate(rowData, 'netPercentNumber')}  />
            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
    </div>
    )
}


export default JobsProfitHome;
