import React, { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';

import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button} from 'primereact/button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';

import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import { usePayStatement } from './context/PayStatementContext';
import { db } from '../../firebase';
import { query, collection, onSnapshot, where } from 'firebase/firestore';


const PayStatementsHome = (props) => {
    const { payStatementsRef, setPayStatement, setPayStatementVisible} = usePayStatement();
    const { deleteDocument, gearedUser,companies,company, formatDate, accounts, drivers } = UserAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [payStatements, setPayStatements] = useState([]);

    console.log('user auth company on load = ', companies)
    const [homeObject, setHomeObject]= useState({Company:{...company}})
    const [queryRange, setQueryRange]= useState('SixMonths');
    const queryRanges = [ {text :'Six Months',value: 'SixMonths'},{text :'Twelve Months',value: 'TwelveMonths'} ];
    const unsubscribePayStatementsRef = useRef(null)
    
    const driversAndAccounts = drivers.concat(accounts);
    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);
    
    const [inputValues, setInputValues]= useState({
        Driver:{ID:'', Name:'No Driver' }, 
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'), 
        Company:{...company}
    });

    useEffect(() => {
        if (company && Object.keys(company).length > 0) {
            setHomeObject({Company:{...company}})
           
        }
    }, [company]);
    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);

    useEffect(() => {
        if (inputValues?.StartDate) {
            queryPayStatements();
            
        }
    }, []);
    useEffect(() => {
        // This will be called when the component mounts or dependencies change
        return () => {
            if (unsubscribePayStatementsRef.current) {
                unsubscribePayStatementsRef.current();
                console.log('Unsubscribed from PayStatements listener on cleanup');
            }
        };
    }, []); 
 
    let tempPayStatements = [...payStatements];
    if (inputValues.Company)   tempPayStatements =  tempPayStatements.filter(dps => dps.Company && dps.Company.ID === inputValues.Company.ID);
    const sortedPayStatements =  tempPayStatements.sort((a, b) => {
        if (a.QBDPSDate >= b.QBDPSDate) return -1;
        if (a.QBDPSDate <= b.QBDPSDate) return 1;
        return 0;
    });        


                                                                                                                                                                
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DPSNum: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        ParentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Paid: { value: null, matchMode: FilterMatchMode.EQUALS },
        Subhauler:    { value: null, matchMode: FilterMatchMode.EQUALS }
        // Don't set anything here for Account.Name initially
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
  
   
    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({
            ...prevFilters,
            global: { ...prevFilters.global, value }
        }));
        setGlobalFilterValue(value);
    };


    const queryPayStatements= () => {
          
        payStatementsRef.current=[];
        if (unsubscribePayStatementsRef.current && typeof unsubscribePayStatementsRef.current === 'function') unsubscribePayStatementsRef.current();

        console.log('homeObject.Company = ' , inputValues);
        setIsLoading(true);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/PayStatements`;
        let payStatementQuery = query(collection(db, queryName), where("QBDPSDate", ">=", inputValues.StartDate), where("QBDPSDate", "<=", inputValues.EndDate));

        let unsubscribeAccountID, unsubscribeDriverID;

   
        const handlePayStatementChange = (change) => {
        
            const tempPayStatement = change.doc.data();
            tempPayStatement.ID = change.doc.id;
            tempPayStatement.FreightBills = [];
            tempPayStatement.realDPSDate = new Date(tempPayStatement.DPSDate);
            tempPayStatement.startDateValue = new Date(tempPayStatement.StartDate);
            tempPayStatement.endDateValue = new Date(tempPayStatement.EndDate);
            if (change.type === "added") {
                payStatementsRef.current.push(tempPayStatement); 
            }
            if (change.type === "modified") {
                const payStatementIndex = payStatementsRef.current.findIndex((d) => d.ID === tempPayStatement.ID);
                payStatementsRef.current[payStatementIndex] = tempPayStatement;
            }
            if (change.type === "removed") {
                const payStatementIndex = payStatementsRef.current.findIndex((d) => d.ID === tempPayStatement.ID);
                payStatementsRef.current.splice(payStatementIndex,1);
            }
    
            // Only update state after the initial load
          
        };
        const processQuerySnapshot = (querySnapshot) => {
            console.log('about to run query')
            querySnapshot.docChanges().forEach((change) => handlePayStatementChange(change));
     
            setIsLoading(false);
            setPayStatements([... payStatementsRef.current]) // Return the fetched payStatements
         
        };
        if (inputValues.Driver.ID) {
            const driverQuery = query(payStatementQuery, where("ParentID", "==", inputValues.Driver.ID));
            unsubscribeDriverID = onSnapshot(driverQuery, processQuerySnapshot);
        
            if(inputValues.Driver?.Driver?.ID) {
                const subhaulerQuery = query(payStatementQuery, where("ParentID", "==", inputValues.Driver.Driver.ID));
                unsubscribeAccountID = onSnapshot(subhaulerQuery, processQuerySnapshot);
            }
        
            unsubscribePayStatementsRef.current = () => {
                unsubscribeDriverID();
                unsubscribeAccountID();
            };
        } else {
            console.log('this ist eh snapshot that should run');
           unsubscribePayStatementsRef.current = onSnapshot(payStatementQuery, processQuerySnapshot);
        }
       
         
      

  
        
    };
    const getPayStatements = () => {
      
        payStatementsRef.current=[];
        if (unsubscribePayStatementsRef.current && typeof unsubscribePayStatementsRef.current === 'function') unsubscribePayStatementsRef.current();

        let tempDate =new Date();
        let monthSubtract;
        if(queryRange==='TwelveMonths')monthSubtract=12;
        if(queryRange==='SixMonths')monthSubtract=6;
        tempDate.setMonth(tempDate.getMonth()-monthSubtract);
        tempDate=formatDate(tempDate, '/','YYYY/MM/DD');
        console.log('queryRange= ' , queryRange);

        const queryName = `Organizations/${gearedUser.selectedOrgName}/PayStatements`;

        const q = query( collection(db, queryName), where("QBDPSDate", ">=", tempDate), where("Company.ID","==", homeObject.Company.ID) );
        console.log('tempDAte = ', tempDate)
        unsubscribePayStatementsRef.current = onSnapshot(q, (querySnapshot) => {
            console.log('PayStatement HOME SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {
                const tempPayStatement = change.doc.data();
                tempPayStatement.FreightBills = [];
                tempPayStatement.ID = change.doc.id;
                tempPayStatement.realDPSDate = new Date(tempPayStatement.DPSDate);
                tempPayStatement.startDateValue = new Date(tempPayStatement.StartDate);
                tempPayStatement.endDateValue = new Date(tempPayStatement.EndDate);
                if (change.type === "added") {
                    payStatementsRef.current.push(tempPayStatement); 
                }
                if (change.type === "modified") {
                    const payStatementIndex = payStatementsRef.current.findIndex((d) => d.ID === tempPayStatement.ID);
                    payStatementsRef.current[payStatementIndex] = tempPayStatement;
                }
                if (change.type === "removed") {
                    const payStatementIndex = payStatementsRef.current.findIndex((d) => d.ID === tempPayStatement.ID);
                    payStatementsRef.current.splice(payStatementIndex,1);
                }
            });

            console.log('setting full home PayStatementes = ',  payStatementsRef.current);
            setPayStatements([... payStatementsRef.current]) // Return the fetched payStatements
        }); // Handle errors by rejecting the promise
        console.log('subscriber ref after its first set!',  unsubscribePayStatementsRef.current);
    };

    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
      
    };
    const changeDriver= (fieldName, value) => {
        console.log('driver looks like this = ', value);
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    }; 
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
     
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const renderHeader = () => (
        <div className="mbsc-row mbsc-grid">
            <span className="mbsc-col-xl-1" >PayStatements</span>
           
            <div className="mbsc-col-xl-3">
                <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon search-auto-input'  suggestions={companies} setValue={setInputValues} handleFieldChange={changeCompany} />
            </div> 
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                </div>
            </div>
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                <AutoCompleteInput label="Driver" fieldName="Driver" field="Name" hideAddCustom={true} value={inputValues.Driver} labelClass='p-inputgroup-addon search-auto-input' suggestions={driversAndAccounts} setValue={setInputValues} handleFieldChange={changeDriver} />
                <Button  color="primary" onClick={(e) => queryPayStatements()} style={{margin:"0", height:"50%", float:"right", width:"45%", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em"}}>Search Pay Statements</Button> 

            </div>
            
            {/* <div className="p-inputgroup mbsc-col-xl-3 mbsc-offset-xl-4">
                <span className="p-inputgroup-addon">Range:</span>
                <Dropdown value={queryRange} onChange={(e) => setQueryRange( e.value)} options={queryRanges} optionLabel="text" placeholder="Select a Range" className="w-full md:w-14rem" />
            </div>
            <div className="mbsc-col-xl-3">
                <AutoCompleteInput fieldName="Company" field="CompanyName" value={homeObject.Company} suggestions={companies}  setValue={setHomeObject}  handleFieldChange={handleChangePayStatementCompany}   />
            </div>*/}

        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );
    const handleOpenQuickbooks = (rowData)=>{
        var win = window.open('https://qbo.intuit.com/app/bill?txnId=' +rowData.QBPayStatementID, '_blank');
    }  
     
    const handleEdit = (rowData) => {
      // console.log('show roate ', rowData)

      
        console.log('trying to veiw DPS ohhh' , rowData)
        
  
                
                setPayStatement(rowData)
                setPayStatementVisible(true);
            

    };
 

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
             <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this PayStatement?")) {
               await deleteDocument(rowData,'PayStatements' )
            }
            
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

    const booleanFilterTemplate = (options) => {
        return (
          <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
            value={options.value !== null ? options.value : undefined}
            onChange={(e) => options.filterApplyCallback(e.value)}
          />
        );
      };
      const booleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'green' : 'red';
        return (
          <i style={{color:color}}
            className={classNames('pi', {
              'true-icon pi-check-circle': rowData[field],
              'false-icon pi-times-circle': !rowData[field]
            })}
          />
        );
      };
      const quickbooksBodyTemplate = (rowData) => {
    
        if(rowData.QBPayStatementID){
         return(
            <button type="button" onClick={() => handleOpenQuickbooks(rowData)}>
                <FontAwesomeIcon icon={faLink} />
            </button>)
        }
    };
    const dateBodyTemplate = (rowData, fieldName) => {
       // console.log('making date boyd temp = ', rowData.realDPSDate)
        return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
    };
    const currencyBodyTemplate = (rowData, fieldOne, fieldTwo) => {
  
      //  console.log('and rowData[field] = ' ,rowData)
       
        let formattedNumber = Number(Number(rowData[fieldOne][fieldTwo]).toFixed(2)).formatMoney(2)
        return (
            <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={sortedPayStatements} paginator rows={25} dataKey="ID" stripedRows filters={filters} header={header} filterDisplay="row" emptyMessage="No payStatements found.">
                <Column pt={{root: { 'data-label': 'Edit' }}} header="Edit" body={editBodyTemplate} />
                <Column pt={{root: { 'data-label': 'Delete' }}} style={{textAlign:"center"}} header="Delete" body={deleteBodyTemplate} />
          
                <Column pt={{root: { 'data-label': 'Statement #' }}} field="DPSNum" sortable header="Statement #" filter filterPlaceholder="Search by Statement #" />
                <Column pt={{root: { 'data-label': 'Driver' }}} field="ParentName" header="Driver"  filter  filterPlaceholder="Search by Driver"  sortable />
                <Column pt={{root: { 'data-label': 'Subhauler' }}} field="Subhauler" header="Subhauler" dataType="boolean" style={{ minWidth: '6rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'Subhauler')} filter filterElement={booleanFilterTemplate}/>
                <Column pt={{root: { 'data-label': 'Statement Date' }}} field="realDPSDate" header="Statement Date" dataType="date" body={(rowData) => dateBodyTemplate(rowData, 'realDPSDate')}   filterPlaceholder="Search by Date" />
                <Column pt={{root: { 'data-label': 'Start Date' }}} field="startDateValue" header="Start Date" dataType="date" body={(rowData) => dateBodyTemplate(rowData, 'startDateValue')}    filterPlaceholder="Search by Date" />

                <Column pt={{root: { 'data-label': 'End Date' }}} field="endDateValue" header="End Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'endDateValue')}  filterPlaceholder="Search by Date" />

                <Column pt={{root: { 'data-label': 'Paid' }}} field="Paid" header="Paid" dataType="boolean" style={{ minWidth: '6rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'Paid')} filter filterElement={booleanFilterTemplate}/>
                <Column pt={{root: { 'data-label': 'Total' }}} header="Total" field="Total.Total" body={(rowData) => currencyBodyTemplate(rowData, 'Total','Total')} sortable style={{ minWidth: '6rem' }}/>
                <Column pt={{root: { 'data-label': 'QB Statement' }}} header="QB Statement" body={quickbooksBodyTemplate} />
            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
};

export default PayStatementsHome;
