
import React, {useState,useContext,useRef, createContext,useCallback} from 'react'

import { db } from '../../../firebase';
import {  query,  collection,onSnapshot, where } from 'firebase/firestore';


import { UserAuth } from '../../../context/AuthContext';


const FreightBillHomeContext = createContext();
export const FreightBillHomeContextProvider = ({ children }) => {
    
    const { gearedUser } = UserAuth();
    const [homeFreightBills, setHomeFreightBills] = useState([]);

    const [homeExpenses, setHomeExpenses] = useState([]);
    const [homeDate, setHomeDate]= useState(null); 
    const [homeDispatches, setHomeDispatches] = useState([]);


    const [calendarStartDate, setCalendarStartDate]= useState(null);
    const [calendarEndDate, setCalendarEndDate]= useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const unsubscribeFreightBillsRef = useRef(null); // Store the unsubscribe functionf
    const unsubscribeDispatchesRef = useRef(null); // Store the unsubscribe function
    const unsubscribeExpensesRef =useRef(null);
    const unsubscribeDispatchExpensesRef =useRef(null);

  
    const homeExpensesRef= useRef(null);
    const homeFreightsRef= useRef(null);




    const queryExpenses = useCallback((startDate, endDate) => {
        homeExpensesRef.current = [];
        if(startDate!==calendarStartDate && endDate!==calendarEndDate){
            if (unsubscribeExpensesRef.current) unsubscribeExpensesRef.current();
            if (unsubscribeDispatchExpensesRef.current)  unsubscribeDispatchExpensesRef.current();
            console.log('querying expenses for start date = '+ startDate +  ' and end date = ' + endDate)
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            unsubscribeExpensesRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const tempExpense = change.doc.data(); 
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    tempExpense.ID = change.doc.id;
                    console.log('expense snapshot fired and change.type = ', change.type)
                    console.log('and temp expnese looks like this  = ', tempExpense)
                    if (change.type === "added") {
                        homeExpensesRef.current.push(tempExpense);
                    }
                    if (change.type === "modified") { 
                        const expenseIndex = homeExpensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                        console.log('found an exppense b4? ', homeExpensesRef.current[expenseIndex])
                        homeExpensesRef.current[expenseIndex] = tempExpense;
                        console.log('found an exppense in the arrya after ',    homeExpensesRef.current[expenseIndex] )
                    }
                    if (change.type === "removed") {
                        console.log('i am removing the expensebill', tempExpense)
                        const expenseIndex = homeExpensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                        homeExpensesRef.current.splice(expenseIndex, 1);
                    }
                });
                console.log('finished with expense query', homeExpensesRef.current)
                setCalendarStartDate(startDate);
                setCalendarEndDate(endDate)
                setHomeExpenses((prevExpenses) => [...homeExpensesRef.current]); 
               
            });
        }
    }, [calendarStartDate, calendarEndDate]);

    const queryFreightBills = useCallback((startDate, endDate) => {
        homeFreightsRef.current = [];
        if(startDate!==calendarStartDate && endDate!==calendarEndDate){
            if (unsubscribeFreightBillsRef.current) unsubscribeFreightBillsRef.current();
            setIsLoading(true);
			console.log('querying freight bills for start date = '+ startDate +  ' and end date = ' + endDate)
            const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            unsubscribeFreightBillsRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const tempFB = change.doc.data(); 
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                
                    tempFB.ID = change.doc.id;
                    if (change.type === "added") {
                    
                        homeFreightsRef.current.push(tempFB);
                    }
                    if (change.type === "modified") { 
                        const freightIndex = homeFreightsRef.current.findIndex(f => f.ID === tempFB.ID);
                        homeFreightsRef.current[freightIndex] = tempFB;
                    }
                    if (change.type === "removed") {
                        console.log('i am removing the freightbill', tempFB)
                        const freightIndex = homeFreightsRef.current.findIndex(f => f.ID === tempFB.ID);
                        homeFreightsRef.current.splice(freightIndex, 1);
                    }
                });
            
           
                setCalendarStartDate(startDate);
                setCalendarEndDate(endDate)
                setHomeFreightBills([...homeFreightsRef.current]);
                setIsLoading(false);
                console.log('finished with freight query and we setting loading is false ' , homeFreightsRef.current)
               // if(dispatchIDRef.current)updateUnbilledFreights();
            });
        
        }
    }, [calendarStartDate, calendarEndDate]);
    
    const queryDispatches = useCallback((startDate, endDate) => {
        let dispatches = [];
        if (unsubscribeDispatchesRef.current) unsubscribeDispatchesRef.current();
        
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
        const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
        onSnapshot(q, (querySnapshot) => {
            console.log('DISPATCH HOME SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {
              const tempDispatch = change.doc.data();
       
                if (change.type === "added") {
                    tempDispatch.ID = change.doc.id;
                    dispatches.push(tempDispatch);
                }
                if (change.type === "modified") {
                    const dispatchIndex = dispatches.findIndex(d => d.ID === tempDispatch.ID);
                    dispatches[dispatchIndex] = tempDispatch;
                }
          });
    
          console.log('inished with dispatch query = ', dispatches);
          setHomeDispatches(dispatches);
      
      });
    }, [calendarStartDate, calendarEndDate]);

      
    return (
        <FreightBillHomeContext.Provider value={{
			homeFreightBills, setHomeFreightBills, homeDispatches, setHomeDispatches, homeExpenses,  setHomeExpenses,
			 queryFreightBills, queryDispatches,   queryExpenses,
            homeDate, setHomeDate, 
         	homeFreightsRef,   isLoading
        }}>
            {children}
        </FreightBillHomeContext.Provider>
    );
}
export const useFreightBillHome = () => {
    return useContext(FreightBillHomeContext);
};

