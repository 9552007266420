import React, { useRef, createContext, useState, useContext } from 'react';
import { getStorage, ref,getDownloadURL,uploadString } from "firebase/storage";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "../../../fonts/vfs_fonts"
import { UserAuth } from '../../../context/AuthContext'
const DispatchPrintContext = createContext();

export const DispatchPrintProvider = ({ children }) => {
	const { gearedUser, addDocument,formatDate } = UserAuth();
  const [showPayRate, setShowPayRate] = useState(false);
  const [showBillRate, setShowBillRate] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [printEmail, setPrintEmail] = useState('');
  const [dispatchPrintPopUpVisible, setDispatchPrintPopUpVisible] = useState(false);
  // Add a
	const storage = getStorage();
    const docDefinitionRef = useRef(null);

    if (pdfMake)  pdfMake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts;

	  
    const createDispatchPDF = (dispatch,freightBills) =>{
        let Dispatch = dispatch;
        let columns = [];
        if (showPayRate) columns = ['Order', 'Start Time', 'Driver Name', 'Phone #', 'Pay Rate', 'Pay Type', 'Truck', 'Note'];
        else columns = ['Order', 'Start Time', 'Driver Name', 'Phone #', 'Truck', 'Note'];
        let CompanyHeader = Dispatch.Company.Address + ', ' + Dispatch.Company.address2 + '\n' + 'Office: ' + Dispatch.Company.CompanyPhone + '   Fax: ' + Dispatch.Company.Fax;
        let driverTable = [];
        let driverWidths = [];
        let driverHeader = []
        if (showPayRate) {
            driverHeader = [{ colSpan: 7, fontSize: 20, text: 'Drivers' }, {}, {}, {}, {}, {}, {}, {}];
            driverWidths = [30, 50,70, 75, 50, 50, 55, 60];
        }
        else {
            driverHeader = [{ colSpan: 5, fontSize: 20, text: 'Drivers' }, {}, {}, {}, {}, {}];
            driverWidths = [40, '*', '*', '*', '*', '*'];
        }
        driverTable.push(driverHeader);
        driverTable.push(columns);
        var tempBillRate =  Number(Dispatch.BillRate).formatMoney(2);

        let firstRow = [{ colSpan: 3, fontSize: 15, text: [{ bold: true, text: 'Bill To: ' }, Dispatch.Account.Name] }, {}, {}, { text: [{ bold: true, text: 'Job #: ' }, Dispatch.JobNumber] }, { text: [{ bold: true, text: 'PO #: ' },Dispatch.PONumber] }];
        let secondRow =[];
        if (showBillRate) secondRow = [{ colSpan: 2, fontSize: 12, text: [{ bold: true, text: 'Truck Type: ' }, Dispatch.TruckType.Name] }, {}, { text: [{ bold: true, text: 'Material: ' }, Dispatch.Material.Name] }, { text: [{ bold: true, text: 'Bill Type: ' }, Dispatch.BillType] }, { text: [{ bold: true, text: 'Bill Rate: $' }, tempBillRate] }];
        else secondRow = [{ colSpan: 3, fontSize: 12, text: [{ bold: true, text: 'Truck Type: ' }, Dispatch.TruckType.Name] }, {}, {}, { text: [{ bold: true, text: 'Material: ' }, Dispatch.Material.Name] }, { text: [{ bold: true, text: 'Bill Type: ' }, Dispatch.BillType] }];
        let thirdRow = [{ colSpan: 3, fontSize: 12, text: [{ bold: true, text: 'Trucks Ordered: ' }, Dispatch.TrucksOrdered] }, {}, {}, { colSpan: 2, text: [{ bold: true, text: 'Trucks Assigned: ' }, Dispatch.TrucksAssigned] }, {}];
        let loadAndDumpName = [{ fontSize: 15, text: [{ bold: true, text: 'Load Site: ' }, Dispatch.LoadSite.Name] }, { fontSize: 15, text: [{ bold: true, text: 'Dump Site: ' }, Dispatch.DumpSite.Name] }];
        let loadAndDumpAddress = [{ rowSpan: 2, text: [{ bold: true, text: 'Address: ' }, Dispatch.LoadSite.fullAddress] }, { rowSpan: 2, text: [{ bold: true, text: 'Address: ' }, Dispatch.DumpSite.fullAddress] }];
        let driverTableRow=[];
        const sortedFreightBills = [...freightBills].sort((a, b) => a.loadOrder - b.loadOrder);

        for (var i = 0; i < sortedFreightBills.length; i++) {
            let truckName = sortedFreightBills[i].Truck?.Name ? sortedFreightBills[i].Truck?.Name : sortedFreightBills[i].Truck;
            var tempayrate =  Number(sortedFreightBills[i].PayRate).formatMoney(2);
            if (showPayRate) driverTableRow = [sortedFreightBills[i].loadOrder, sortedFreightBills[i].dispatchTime, sortedFreightBills[i].driverName, sortedFreightBills[i].displayPhone, tempayrate, sortedFreightBills[i].PayType, truckName, sortedFreightBills[i].driverNote];
            else driverTableRow = [sortedFreightBills[i].loadOrder, sortedFreightBills[i].dispatchTime, sortedFreightBills[i].driverName, sortedFreightBills[i].displayPhone,  truckName, sortedFreightBills[i].driverNote];
            driverTable.push(driverTableRow);
        }

        let docDefinition = {
            content: [
                { text: 'Job Date: ' + Dispatch.JobDate, style: 'header', alignment: 'center', fontSize: 15, margin: [300, 0, 0, 0] },
                { text: Dispatch.Company.CompanyName, style: 'header', alignment: 'center', fontSize: 25, margin: [0, 0, 0, 0] },
                { text: CompanyHeader, style: 'header', alignment: 'center', fontSize: 15 },

                {
                    style: 'tableExample',
                    table: { widths: ['*', '*', '*', '*', '*'], body: [firstRow, secondRow, thirdRow] }
                },
                {
                    style: 'tableExample',
                    table: { widths: ['*', '*'], body: [loadAndDumpName, loadAndDumpAddress, [{}, {}]] }
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*'],
                        body: [
                            [{ fontSize: 15, text: { bold: true, text: 'Notes to Drivers ' } }],
                            [{ text: Dispatch.Notes }]
                        ]
                    }
                },
                {
                    style: 'tableExample',
                    table: {
                        alignment: 'center',
                        widths:driverWidths,
                        body: driverTable
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
            }
        };
        console.log('docDefiniton = ', docDefinition);
        
        pdfMake.createPdf(docDefinition).getBase64(async function(encodedString) {
              
            pdfMake.createPdf(docDefinition).download('Dispatch.pdf')
        });
    
  

    }
	const uploadPDFFile = async(document, formattedDate)=>{
        let storageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/DispatchDays/' + 'DispatchFor' + formattedDate+ '.pdf')
        await uploadString(storageRef, document, 'base64');
        const url = await getDownloadURL(storageRef);
        return url;
    }

	const printAllDispatch = async(dispatches, freightBills, company, homeDate )=>{
        docDefinitionRef.current = {
            content: [],
            pageMargins: [40, 60, 40, 60],
                pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                    return currentNode.headlineLevel === 1 &&  currentNode.pageNumbers.length > 1;
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 0]
                },
                    customerTable: {
                    margin: [0,5, 0, 0]
                },
                lineItemTable: {
                    fontSize:10,
                    alignment:'center',
                    margin: [0, 5, 0, 20]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }
    
            }
        };
        console.log('filteredDsipathces = ', dispatches)
        for (let q = 0; q < dispatches.length; q++) {
            await addDispatchToPDF(dispatches[q], q, freightBills); // Wait for each call to complete
        }
        console.log(' - docDefinitionRef.current ' , docDefinitionRef.current);
       if(showEmail){

            pdfMake.createPdf(docDefinitionRef.current).getBase64(async function(encodedString) {
                let base64PDF = encodedString;
                const dispatchDate = formatDate(homeDate,'/','MM/DD/YYYY');
                const  pdfDate=  formatDate(homeDate,'-','YYYY/MM/DD');
                const formattedDate =  new Date().toLocaleString('en-US', {
                    timeZone: 'PST', // Set to PST
                    dateStyle: 'short', // Formats as MM/DD/YY
                    timeStyle: 'short'  // Formats time as hh:mm AM/PM
                });
                const pdfUrl = await uploadPDFFile(base64PDF,pdfDate);
         
                console.log('company = ',company);
                let newEmailDispatch={
                    DispatchDate: dispatchDate,
                    from:gearedUser.selectedOrgName + '<Dispatches@mail.geared.tech>',
                    replyTo: company.SupportEmail,
                    to: printEmail,
                    createdAt:formattedDate,
                    createdBy:gearedUser.Name,
                    URL: pdfUrl,
                    PDFType:'DispatchByDay',
                    ParentID: dispatchDate,
                    Sent:false,
                    subject: 'Dispatch for ' +   dispatchDate,
                    html: '<a href="'+ pdfUrl+ '"> Click link to Download Dispatch PDF </a>'
                
                }
                addDocument(newEmailDispatch, 'EmailedPDFs');
                console.log('pdfUrl=',newEmailDispatch);
            });
        }else pdfMake.createPdf(docDefinitionRef.current).download('Dispatch.pdf');
    }
    const addDispatchToPDF = (Dispatch, q, freightBills) =>{
        return new Promise((resolve) => {
            let columns = [];
             if (showPayRate) columns = ['Order', 'Start Time', 'Driver Name', 'Phone #', 'Pay Rate', 'Pay Type', 'Truck', 'Note'];
            else columns = ['Order', 'Start Time', 'Driver Name', 'Phone #', 'Truck', 'Note'];
            let CompanyHeader = Dispatch.Company.Address + ', ' + Dispatch.Company.address2 + '\n' + 'Office: ' + Dispatch.Company.CompanyPhone + '   Fax: ' + Dispatch.Company.Fax;
            let driverTable = [];
            let driverWidths = [];
            let driverHeader = []
        	if (showPayRate) {
                driverHeader = [{ colSpan: 7, fontSize: 20, text: 'Drivers' }, {}, {}, {}, {}, {}, {}, {}];
                driverWidths = [30, 50,70, 75, 50, 50, 55, 60];
            }
            else {
                driverHeader = [{ colSpan: 5, fontSize: 20, text: 'Drivers' }, {}, {}, {}, {}, {}];
                driverWidths = [40, '*', '*', '*', '*', '*'];
            }
            driverTable.push(driverHeader);
            driverTable.push(columns);
            var tempBillRate =  Number(Dispatch.BillRate).formatMoney(2);

            let firstRow = [{ colSpan: 3, fontSize: 15, text: [{ bold: true, text: 'Bill To: ' }, Dispatch.Account.Name] }, {}, {}, { text: [{ bold: true, text: 'Job #: ' }, Dispatch.JobNumber] }, { text: [{ bold: true, text: 'PO #: ' },Dispatch.PONumber] }];
            let secondRow =[];
            if (showBillRate) secondRow = [{ colSpan: 2, fontSize: 12, text: [{ bold: true, text: 'Truck Type: ' }, Dispatch.TruckType.Name] }, {}, { text: [{ bold: true, text: 'Material: ' }, Dispatch.Material.Name] }, { text: [{ bold: true, text: 'Bill Type: ' }, Dispatch.BillType] }, { text: [{ bold: true, text: 'Bill Rate: $' }, tempBillRate] }];
            else secondRow = [{ colSpan: 3, fontSize: 12, text: [{ bold: true, text: 'Truck Type: ' }, Dispatch.TruckType.Name] }, {}, {}, { text: [{ bold: true, text: 'Material: ' }, Dispatch.Material.Name] }, { text: [{ bold: true, text: 'Bill Type: ' }, Dispatch.BillType] }];
            let thirdRow = [{ colSpan: 3, fontSize: 12, text: [{ bold: true, text: 'Trucks Ordered: ' }, Dispatch.TrucksOrdered] }, {}, {}, { colSpan: 2, text: [{ bold: true, text: 'Trucks Assigned: ' }, Dispatch.TrucksAssigned] }, {}];
            let loadAndDumpName = [{ fontSize: 15, text: [{ bold: true, text: 'Load Site: ' }, Dispatch.LoadSite.Name] }, { fontSize: 15, text: [{ bold: true, text: 'Dump Site: ' }, Dispatch.DumpSite.Name] }];
            let loadAndDumpAddress = [{ rowSpan: 2, text: [{ bold: true, text: 'Address: ' }, Dispatch.LoadSite.fullAddress] }, { rowSpan: 2, text: [{ bold: true, text: 'Address: ' }, Dispatch.DumpSite.fullAddress] }];
            let driverTableRow=[];
           
            const sortedFreightBills = freightBills.filter(freightBill => freightBill.dispatchID === Dispatch.ID).sort((a, b) => a.loadOrder - b.loadOrder);
            for (var i = 0; i < sortedFreightBills.length; i++) {
                let truckName = sortedFreightBills[i].Truck?.Name ? sortedFreightBills[i].Truck?.Name : sortedFreightBills[i].Truck?.Name==='' ? '' : sortedFreightBills[i].Truck;
                var tempayrate =  Number(sortedFreightBills[i].PayRate).formatMoney(2);
                if (showPayRate) driverTableRow = [sortedFreightBills[i].loadOrder, sortedFreightBills[i].dispatchTime, sortedFreightBills[i].driverName, sortedFreightBills[i].displayPhone, tempayrate, sortedFreightBills[i].PayType, truckName, sortedFreightBills[i].driverNote];
                else  driverTableRow = [sortedFreightBills[i].loadOrder, sortedFreightBills[i].dispatchTime, sortedFreightBills[i].driverName, sortedFreightBills[i].displayPhone,  truckName, sortedFreightBills[i].driverNote];
                driverTable.push(driverTableRow);
            }
          
            const jobDateRow = q!==0 ?  { text: 'Job Date: ' + Dispatch.JobDate, style: 'header', alignment: 'center', fontSize: 15, margin: [300, 0, 0, 0], pageBreak: 'before' }: { text: 'Job Date: ' + Dispatch.JobDate, style: 'header', alignment: 'center', fontSize: 15, margin: [300, 0, 0, 0] };
       
            docDefinitionRef.current.content.push(
                jobDateRow,
                { text: Dispatch.Company.CompanyName, style: 'header', alignment: 'center', fontSize: 25, margin: [0, 0, 0, 0] },
                { text: CompanyHeader, style: 'header', alignment: 'center', fontSize: 15 },
                { style: 'tableExample', table: { widths: ['*', '*', '*', '*', '*'], body: [firstRow, secondRow, thirdRow] } },
                { style: 'tableExample', table: { widths: ['*', '*'], body: [loadAndDumpName, loadAndDumpAddress, [{}, {}]] } },
                {
                    style: 'tableExample',
                    table: {
                        widths: ['*'],
                        body: [
                            [{ fontSize: 15, text: { bold: true, text: 'Notes to Drivers ' } }],
                            [{ text: Dispatch.Notes }]
                        ]
                    }
                },
                { style: 'tableExample', table: { alignment: 'center', widths: driverWidths, body: driverTable } }
            );

        
            resolve(); // Resolve the promise to indicate completion
        });
        
      

    }

  return (
    <DispatchPrintContext.Provider value={{
      showPayRate, setShowPayRate,
      showBillRate, setShowBillRate,
      showEmail, setShowEmail,
      printEmail, setPrintEmail,
      printAllDispatch, createDispatchPDF,
      dispatchPrintPopUpVisible, setDispatchPrintPopUpVisible
    }}>
      {children}
    </DispatchPrintContext.Provider>
  );
};

export const useDispatchPrintContext = () => useContext(DispatchPrintContext); 