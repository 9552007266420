
import React, {useState,useContext,useRef, createContext,useCallback} from 'react'

import { db } from '../../../firebase';
import { doc,  query,  collection,onSnapshot, where, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL,uploadString } from "firebase/storage";

import { UserAuth } from '../../../context/AuthContext';
import pdfMake from 'pdfmake/build/pdfmake';

const DispatchFreightContext = createContext();
export const DispatchFreightContextProvider = ({ children }) => {
    
    const { gearedUser,updateDocument, contacts} = UserAuth();

	const [dispatchFreightBills, setDispatchFreightBills] = useState([]);
	const [dispatchExpenses, setDispatchExpenses] = useState([]);
	const [dispatchDriverFreightBills, setDispatchDriverFreightBills] = useState([]);
    const [emailedPDFs, setEmailedPDFs] = useState([]);
    
    const [dispatch, setDispatch] = useState({});

  
    const storage = getStorage();

    const unbilledExpenseRef = useRef(0);
    const unbilledFreightsRef = useRef(0);
    const unbilledMaterialsRef = useRef(0);


    const unsubscribeDispatchExpensesRef =useRef(null);
    const unsubscribeDispatchFreightsRef =useRef(null);
    const unsubscribeDispatchDriverFreightsRef =useRef(null);
    const unsubscribeEmailedPDFsRef =useRef(null);

    const dispatchIDRef= useRef(null);

    const dispatchExpensesRef= useRef(null);
    const dispatchFreightsRef= useRef(null);
    const dispatchDriverFreightsRef = useRef(null);
    const emailedPDFsRef= useRef(null);

 
    console.log('dispatchIDReload = '+ dispatchIDRef.current)

      
   // pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const fetchDispatch = async (id) => {
	return new Promise((resolve, reject) => {
		console.log('WE ARE RUNNIGN TEH FETCH DISPATCH')
		if(dispatch){
			if (id === dispatch.ID) return resolve(dispatch);
		}
			const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, id);
			onSnapshot(docRef, async (docSnap) => {
				const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
				console.log('source =' +source)
				if (docSnap.exists()) {
			
					let tempDispatch = docSnap.data();
					tempDispatch.ID=docSnap.id;
					unbilledFreightsRef.current = tempDispatch.unBilledFreights ? tempDispatch.unBilledFreights : 0;
					unbilledMaterialsRef.current = tempDispatch.unBilledMaterials ? tempDispatch.unBilledMaterials : 0;
					unbilledExpenseRef.current = tempDispatch.unBilledExpenses ? tempDispatch.unBilledExpenses : 0;
					setDispatch({ ...tempDispatch });
					console.log('tempDispatch == ', tempDispatch)
				
					return resolve(tempDispatch);
				}
			});
		});
	};

    const fetchDispatchExpenses = async(dispatchID)=>{
        return new Promise((resolve, reject) => {
            const q = query(collection(db, "Organizations/"+gearedUser.selectedOrgName+"/Expenses"),where("dispatchID", "==", dispatchID));
            if (unsubscribeDispatchExpensesRef.current) unsubscribeDispatchExpensesRef.current();
            dispatchExpensesRef.current=[];
            dispatchIDRef.current=dispatchID;
			console.log('fetching expenses for dispatch id = ', dispatchID)
            unsubscribeDispatchExpensesRef.current=   onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {  
                    let tempExpense = change.doc.data();
                    tempExpense.ID=change.doc.id;
                    console.log('found an expnes = ',tempExpense)
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                
                    if (change.type === "added") {
                        dispatchExpensesRef.current.push(tempExpense);
                    }
                    if (change.type === "modified") { 
                        const expenseIndex = dispatchExpensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                        dispatchExpensesRef.current[expenseIndex] = tempExpense;
                    }
                    if (change.type === "removed") {
                        console.log('i am removing the freightbill', tempExpense)
                        const expenseIndex = dispatchExpensesRef.current.findIndex(e => e.ID === tempExpense.ID);
                        dispatchExpensesRef.current.splice(expenseIndex, 1);
                    }
                
                }); 
                console.log('setting dispatch expenses =' , dispatchExpensesRef.current);
				setDispatchExpenses([...dispatchExpensesRef.current]);
                resolve(updateUnbilledExpenses());
              
            
            });
        });
    }

    const fetchDispatchFreightBills = async( dispatchID)=>{
        return new Promise((resolve, reject) => {
			console.log('fetching freight bills for dispatch id = ', dispatchID)
            const q = query(collection(db, "Organizations/"+gearedUser.selectedOrgName+"/FreightBills"),where("dispatchID", "==", dispatchID));
            if (unsubscribeDispatchFreightsRef.current) unsubscribeDispatchFreightsRef.current();
            dispatchFreightsRef.current=[];
            dispatchIDRef.current=dispatchID;
            unsubscribeDispatchFreightsRef.current=   onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {  
                    let tempFreight = change.doc.data();
                    tempFreight.ID=change.doc.id;
                    console.log('found an freight = ',tempFreight)
                    if (change.type === "added") {
                        dispatchFreightsRef.current.push(tempFreight);
                    }
                    
                    if (change.type === "modified") { 
                        const freightIndex = dispatchFreightsRef.current.findIndex(f => f.ID === tempFreight.ID);
                        dispatchFreightsRef.current[freightIndex] = tempFreight;
                    }
                    if (change.type === "removed") {
                        console.log('i am removing the freightbill', tempFreight)
                        const freightIndex = dispatchFreightsRef.current.findIndex(f => f.ID === tempFreight.ID);
                        dispatchFreightsRef.current.splice(freightIndex, 1);
                    } 
                }); 
                console.log('setting dispatch freight bills =' , dispatchFreightsRef.current);
				resolve(setDispatchFreightBills([...dispatchFreightsRef.current])); // Ensure a new array reference is created
           // updateUnbilledFreights();
       
        	});
        });
    }

    const fetchDispatchDriverFreightBills = async( dispatchID)=>{
        return new Promise((resolve, reject) => {
            console.log('earedUser.selectedOrgName = ' + gearedUser.selectedOrgName)
            const q = query(collection(db, "Organizations/"+gearedUser.selectedOrgName+"/DriverFreightBills"),where("dispatchID", "==", dispatchID));
            if (unsubscribeDispatchDriverFreightsRef.current) unsubscribeDispatchDriverFreightsRef.current();
            dispatchDriverFreightsRef.current=[];
            console.log('fetchign freights for dispathcid = ', dispatchID)
            dispatchIDRef.current=dispatchID;
            unsubscribeDispatchDriverFreightsRef.current=   onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {  
                    let tempFreight = change.doc.data();
                    tempFreight.ID=change.doc.id;
                    tempFreight.isDriverFreight = true;
                    console.log('found an freight = ',tempFreight)
					if (change.type === "added") {
						dispatchDriverFreightsRef.current.push(tempFreight);
					}
					
					if (change.type === "modified") { 
						const freightIndex = dispatchDriverFreightsRef.current.findIndex(f => f.ID === tempFreight.ID);
						dispatchDriverFreightsRef.current[freightIndex] = tempFreight;
					}
					if (change.type === "removed") {
						console.log('i am removing the driver freightbill', tempFreight)
						const freightIndex = dispatchDriverFreightsRef.current.findIndex(f => f.ID === tempFreight.ID);
						dispatchDriverFreightsRef.current.splice(freightIndex, 1);
					}
                }); 
                resolve(setDispatchDriverFreightBills([...dispatchDriverFreightsRef.current]));
            })
        });
    }
  
    const updateUnbilledExpenses = ()=>{
        let unbilledCount=0;
        let billable = false;
        console.log('unbilledExpenseRef.current = ', dispatchExpensesRef.current)
        let dispatchExpenses = dispatchExpensesRef.current ? dispatchExpensesRef.current.filter(e => e.dispatchID === dispatchIDRef.current) : [];
        for(let e=0; e<dispatchExpenses.length; e++){
            if(dispatchExpenses[e].bill && !dispatchExpenses[e].billed && !dispatchExpenses[e].floatingBillExpense && (dispatchExpenses[e].FreightBill!=='' || !dispatchExpenses[e].addToFreights)){
                console.log('updating the unbilled coutn for this  ',dispatchExpenses);
                unbilledCount++;
            }
        }
        console.log('unbilledcount expenses = '+ unbilledCount);
        console.log('dispatch.unBilledExpenses = '+ unbilledExpenseRef.current);
        if(unbilledExpenseRef.current!==unbilledCount){
            unbilledExpenseRef.current=unbilledCount;
            if((unbilledExpenseRef.current+ unbilledFreightsRef.current+ unbilledMaterialsRef.current)>0)billable=true;
        
            updateDocument({unBilledExpenses:unbilledCount, Billable:billable}, dispatchIDRef.current,'Dispatches')
        }
    }

 
    const queryEmailedPDFs= (dispatchID) => {
        emailedPDFsRef.current = [];
       
        if (unsubscribeEmailedPDFsRef.current) unsubscribeEmailedPDFsRef.current();
        
        console.log('I AM OPENING A LISTENER TO THISE FREGITH BILLS!!')
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        const q = query(collection(db, queryName), where("DispatchID", "==", dispatchID));
        unsubscribeEmailedPDFsRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempEmailedPDF = change.doc.data(); 
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
            
                tempEmailedPDF.ID = change.doc.id;
                if (change.type === "added") {
                
                    dispatchFreightsRef.current.push(tempEmailedPDF);
                }
                if (change.type === "modified") { 
                    const freightIndex = dispatchFreightsRef.current.findIndex(f => f.ID === tempEmailedPDF.ID);
                    dispatchFreightsRef.current[freightIndex] = tempEmailedPDF;
                }
                if (change.type === "removed") {
                    console.log('i am removing the freightbill', tempEmailedPDF)
                    const freightIndex = dispatchFreightsRef.current.findIndex(f => f.ID === tempEmailedPDF.ID);
                    dispatchFreightsRef.current.splice(freightIndex, 1);
                }
            });
        
        
          
            setEmailedPDFs([... emailedPDFsRef.current]);
            console.log('finished with freight query disp id' + dispatchID)
         
        });
    
    };


    const uploadPDFFile = async(document)=>{
        let storageRef = ref(storage, 'attachments/' + gearedUser.selectedOrgName + '/' + dispatch.ID + '/' + 'FreightBills.pdf')
        const snapshot = await uploadString(storageRef, document, 'base64');
        const url = await getDownloadURL(storageRef);
        return url;
    }

 
      
    return (
        <DispatchFreightContext.Provider value={{
          	fetchDispatchFreightBills,  dispatchFreightBills, setDispatchFreightBills,
			fetchDispatchExpenses, dispatchExpenses, setDispatchExpenses, dispatchExpensesRef,
			fetchDispatch, dispatch, dispatchIDRef,
			fetchDispatchDriverFreightBills, dispatchDriverFreightBills, 
         	queryEmailedPDFs, emailedPDFs, uploadPDFFile, 
			unbilledFreightsRef, unbilledMaterialsRef, unbilledExpenseRef

        }}>
            {children}
        </DispatchFreightContext.Provider>
    );
}
export const useDispatchFreight= () => {
    return useContext(DispatchFreightContext);
};

