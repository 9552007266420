import { createContext, useContext, useState, useRef } from 'react';
import { db } from '../../../firebase';
import { collection, query, onSnapshot, where, orderBy } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';

const ExpenseContext = createContext();

export const useExpense = () => {
    return useContext(ExpenseContext);
};

export const ExpenseContextProvider = ({ children }) => {
    const [expenses, setExpenses] = useState([]);
    const [expense, setExpense] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const expensesRef = useRef([]);
    const { gearedUser, updateDocument, deleteDocument } = UserAuth();

    const queryExpenses = async (startDate, endDate, account) => {
        setIsLoading(true);
        try {
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
            let expenseQuery;

            if (startDate && endDate) {
                if (account?.ID) {
                    // Create two separate queries - one for BillTo and one for PayTo
                    const billToQuery = query(
                        collection(db, queryName),
                        where('QueryDate', '>=', startDate),
                        where('QueryDate', '<=', endDate),
                        where('BillTo.ID', '==', account.ID),
                        orderBy('QueryDate', 'desc')
                    );

                    const payToQuery = query(
                        collection(db, queryName),
                        where('QueryDate', '>=', startDate),
                        where('QueryDate', '<=', endDate),
                        where('PayTo.ID', '==', account.ID),
                        orderBy('QueryDate', 'desc')
                    );

                    // Return a combined snapshot listener
                    return onSnapshot(billToQuery, (billToSnapshot) => {
                        onSnapshot(payToQuery, (payToSnapshot) => {
                            const expenses = [];
                            const processedIds = new Set(); // To prevent duplicates

                            // Process BillTo results
                            billToSnapshot.forEach((doc) => {
                                const expense = doc.data();
                                expense.ID = doc.id;
                                expense.realJobDate = new Date(expense.JobDate);
                                if(!expense.addToFreights || expense.FreightBill) expenses.push(expense);
                                processedIds.add(doc.id);
                            });

                            // Process PayTo results
                            payToSnapshot.forEach((doc) => {
                                // Only add if not already added from BillTo query
                                if (!processedIds.has(doc.id)) {
                                    const expense = doc.data();
                                    expense.ID = doc.id;
                                    expense.realJobDate = new Date(expense.JobDate);
									if(!expense.addToFreights || expense.FreightBill)expenses.push(expense);
                                }
                            });

                            expensesRef.current = expenses;
                            setExpenses(expenses);
                            setIsLoading(false);
                        });
                    });
                } else {
                    // If no account filter, just query by date range
                    expenseQuery = query(
                        collection(db, queryName),
                        where('QueryDate', '>=', startDate),
                        where('QueryDate', '<=', endDate),
                        orderBy('QueryDate', 'desc')
                    );

                    return onSnapshot(expenseQuery, (querySnapshot) => {
                        const expenses = [];
                        querySnapshot.forEach((doc) => {
                            const expense = doc.data();
                            expense.ID = doc.id;
                            expense.realJobDate = new Date(expense.JobDate);
                          	if(!expense.addToFreights || expense.FreightBill)  expenses.push(expense);
                        });
                        expensesRef.current = expenses;
                        setExpenses(expenses);
                        setIsLoading(false);
                    });
                }
            } else {
                // Default query without date range
                expenseQuery = query(
                    collection(db, queryName),
                    orderBy('QueryDate', 'desc')
                );

                return onSnapshot(expenseQuery, (querySnapshot) => {
                    const expenses = [];
                    querySnapshot.forEach((doc) => {
                        const expense = doc.data();
                        expense.ID = doc.id;
                        expense.realJobDate = new Date(expense.JobDate);
						if(!expense.addToFreights || expense.FreightBill) expenses.push(expense);
                    });
                    expensesRef.current = expenses;
                    setExpenses(expenses);
                    setIsLoading(false);
                });
            }
        } catch (error) {
            console.error("Error querying expenses: ", error);
            setIsLoading(false);
        }
    };


    const value = {
        expenses, setExpenses, expense, setExpense,
        isLoading, setIsLoading,
        expensesRef,  queryExpenses, 
    };

    return (
        <ExpenseContext.Provider value={value}>
            {children}
        </ExpenseContext.Provider>
    );
}; 