/**
 * Parses IIF customer data into a format compatible with the accounts table
 * @param {string} iifData - Raw IIF file content
 * @returns {Array} Array of parsed account objects
 */
export const parseIIFCustomers = (iifData) => {
  if (!iifData) return [];
  
  const lines = iifData.split('\n');
  const customers = [];
  let isCustomerSection = false;
  let customerFieldMap = null;
  
  // Helper function to clean field values
  const cleanField = (value) => {
    if (!value) return '';
    // Remove leading and trailing quotes, and any extra quotes within the value
    return value.replace(/^["']|["']$/g, '').replace(/["']/g, '');
  };
  
  for (const line of lines) {
    const fields = line.trim().split('\t');
    
    // Skip empty lines
    if (fields.length === 0 || fields[0] === '') continue;
    
    // Check for customer header line
    if (fields[0] === '!CUST') {
      isCustomerSection = true;
      customerFieldMap = fields.reduce((map, field, index) => {
        map[field] = index;
        return map;
      }, {});
      continue;
    }
    
    // Process customer data lines
    if (isCustomerSection && fields[0] === 'CUST') {
      const name = cleanField(fields[1] || '');
      const customer = {
        ID: cleanField(fields[2] || ''), // RefNumber
        Name: name,
        CompanyName: cleanField(fields[31] || ''), // COMPANYNAME field
        displayPhone: cleanField(fields[14] || ''), // PHONE1 field
        Address: cleanField(fields[10] || ''), // BADDR1
        City: cleanField(fields[11] || ''), // BADDR2 - might need to parse city from this
        State: cleanField(fields[12] || ''), // BADDR3 - might need to parse state from this
        Status: name.includes('(deleted)') || cleanField(fields[fields.length - 1] || '') === 'Y' ? 'Inactive' : 'Active',
        Subhauler: false,
        DedicatedSubhauler: false,
        Vendor: false,
        Broker: false,
        QBRefNum: cleanField(fields[2] || '') // Store the QuickBooks reference number
      };
      
      // Clean up city and state if they're in combined format
      if (customer.City && customer.City.includes(',')) {
        const parts = customer.City.split(',');
        customer.City = parts[0].trim();
        if (parts[1]) {
          const stateParts = parts[1].trim().split(' ');
          customer.State = stateParts[0];
        }
      }
      
      // Only add if we have at least a name
      if (customer.Name) {
        customers.push(customer);
      }
    }
  }
  
  console.log('Parsed customers:', customers.length);
  return customers;
};

export const parseIIFVendors = (iifData) => {
	if (!iifData) return [];
	
	const lines = iifData.split('\n');
	const customers = [];
	let isVendorSection = false;
	let customerFieldMap = null;
	
	// Helper function to clean field values
	const cleanField = (value) => {
	  if (!value) return '';
	  // Remove leading and trailing quotes, and any extra quotes within the value
	  return value.replace(/^["']|["']$/g, '').replace(/["']/g, '');
	};
	
	for (const line of lines) {
	  const fields = line.trim().split('\t');
	  
	  // Skip empty lines
	  if (fields.length === 0 || fields[0] === '') continue;
	  
	  // Check for customer header line
	  if (fields[0] === '!VEND') {
		isVendorSection = true;
		customerFieldMap = fields.reduce((map, field, index) => {
		  map[field] = index;
		  return map;
		}, {});
		continue;
	  }
	  
	  // Process customer data lines
	  if (isVendorSection && fields[0] === 'VEND') {
		const name = cleanField(fields[1] || '');
		const customer = {
		  ID: cleanField(fields[2] || ''), // RefNumber
		  Name: name,
		  CompanyName: cleanField(fields[23] || ''), // COMPANYNAME field
		  displayPhone: cleanField(fields[13] || ''), // PHONE1 field
		  Address: cleanField(fields[6] || ''), // BADDR1
		  Address2: cleanField(fields[7] || ''), // BADDR2 - might need to parse city from this
		  Address3: cleanField(fields[8] || ''), // BADDR3 - might need to parse state from this
		  Status: name.includes('deleted') || cleanField(fields[fields.length - 1] || '') === 'Y' ? 'Inactive' : 'Active',
		  Subhauler: false,
		  DedicatedSubhauler: false,
		  Vendor: true,
		  Broker: false,
		  QBRefNum: cleanField(fields[2] || '') // Store the QuickBooks reference number
		};
		
		// Clean up city and state if they're in combined format
		if (customer.City && customer.City.includes(',')) {
		  const parts = customer.City.split(',');
		  customer.City = parts[0].trim();
		  if (parts[1]) {
			const stateParts = parts[1].trim().split(' ');
			customer.State = stateParts[0];
		  }
		}
		
		// Only add if we have at least a name
		if (customer.Name) {
		  customers.push(customer);
		}
	  }
	}
	
	console.log('Parsed customers:', customers.length);
	return customers;
  };
/**
 * Parses IIF item data into a format compatible with the items table
 * @param {string} iifData - Raw IIF file content
 * @returns {Array} Array of parsed item objects
 */
export const parseIIFItems = (iifData) => {
  if (!iifData) return [];
  
  const lines = iifData.split('\n');
  const items = [];
  let isItemSection = false;
  let itemFieldMap = null;
  
  // Helper function to clean field values (reusing the same function from parseIIFCustomers)
  const cleanField = (value) => {
    if (!value) return '';
    // Remove leading and trailing quotes, and any extra quotes within the value
    return value.replace(/^["']|["']$/g, '').replace(/["']/g, '');
  };
  
  console.log('Total lines in file:', lines.length);
  
  for (const line of lines) {
    const fields = line.trim().split('\t');
    
    // Skip empty lines
    if (fields.length === 0 || fields[0] === '') continue;
    
    // Check for item header line
    if (fields[0] === '!INVITEM') {
      console.log('Found INVITEM header');
      isItemSection = true;
      itemFieldMap = fields.reduce((map, field, index) => {
        map[field] = index;
        return map;
      }, {});
      continue;
    }
    
    // Process item data lines
    if (fields[0] === 'INVITEM') {
      const name = cleanField(fields[1] || '');
      const item = {
        ID: cleanField(fields[2] || ''), // REFNUM
        Name: name,
        Type: cleanField(fields[4] || ''), // INVITEMTYPE
        Description: cleanField(fields[5] || ''), // DESC
        PurchaseDesc: cleanField(fields[6] || ''), // PURCHASEDESC
        Account: cleanField(fields[7] || ''), // ACCNT
        AssetAccount: cleanField(fields[8] || ''), // ASSETACCNT
        COGSAccount: cleanField(fields[9] || ''), // COGSACCNT
        Quantity: fields[10] ? parseFloat(cleanField(fields[10])) : 0, // QNTY
        Value: fields[11] ? parseFloat(cleanField(fields[11])) : 0, // VALUE
        Price: fields[12] ? parseFloat(cleanField(fields[12])) : 0, // PRICE
        Cost: fields[13] ? parseFloat(cleanField(fields[13])) : 0, // COST
        Taxable: cleanField(fields[14]) === 'Y', // TAXABLE
        SalesTaxCode: cleanField(fields[15] || ''), // SALESTAXCODE
        Status: name.includes('(deleted)') ? 'Inactive' : 'Active',
        QBRefNum: cleanField(fields[2] || '') // Store the QuickBooks reference number
      };
      
      // Only add if we have at least a name
      if (item.Name) {
        items.push(item);
      }
    }
  }
  
  console.log('Found items:', items.length);
  if (items.length > 0) {
    console.log('Sample item:', items[0]);
  }
  return items;
}; 