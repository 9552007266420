import React, { useState, createContext, useContext } from 'react';
import {usePayStatement} from './PayStatementContext';
import pdfMake from 'pdfmake/build/pdfmake';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../context/AuthContext';
const PrintPayStatementContext = createContext();

export const PrintPayStatementContextProvider = ({ children }) => {
    const { createPayStatement,   checkDPSNumber,  payStatementsRef, setIsLoading } = usePayStatement();
    const { formatDate, updateDocument, company} = UserAuth();
    const navigate = useNavigate();

 


    const checkPayStatements = async(currentDPSNumber)=>{
        console.log('trying to creat pay statement = ', payStatementsRef.current)
      
        let DPSNumExists = false; 
        for( let p=0; p<payStatementsRef.current.length; p++){
            let tempDPSNumExists = await checkDPSNumber(payStatementsRef.current[p]) ;
            DPSNumExists = DPSNumExists ? DPSNumExists :  tempDPSNumExists &&  tempDPSNumExists;
        }
        if(!DPSNumExists){
            currentDPSNumber++;
            console.log('we are updating the current DPS number = ', currentDPSNumber)
            if (company.CurrentDPSNumber && currentDPSNumber>0) updateDocument({CurrentDPSNumber:currentDPSNumber}, company.ID, 'Preferences');
            const payStatementPromises = payStatementsRef.current.map(async (PayStatement) => {
                try {
                    // Process each PayStatement and handle confirmation prompts
                    await checkPayStatement(PayStatement);
                } catch (error) {
                    console.error('Error processing PayStatement:', PayStatement, error);
                }
            });
            payStatementPromises.push(printAllPayStatements(false));
            // Wait for all pay statements to be processed
            await Promise.all(payStatementPromises);
         
            navigate(`/paystatement/list`);
            console.log('We are all done');
        }
      
    }
    const checkPayStatement =async(PayStatement)=>{
      
            console.log('trying to creat pay statement = ', PayStatement)
            let PayStatementWarning=false;

            for (let lineItem of PayStatement.LineItems) {
                if ((lineItem.Amount === 0 && !lineItem.onHold) || (lineItem.missing && !lineItem.onHold))  {
                    console.log('for this paystamtent = ', PayStatement);
                    console.log('we are warnign onthsi line item = ', lineItem);
                    PayStatementWarning = true;
                }  
                
            }
            if (PayStatementWarning) {
                const confirm = window.confirm(  'Warning: You are about to create a Pay Statement with a line item that is missing or has a total 0. Are you sure you want to continue?'   );
        
                if (!confirm) {
                    console.log('User canceled PayStatement creation:', PayStatement);
                    return; // Skip this PayStatement
                }
            }
            await createPayStatement(PayStatement);
           
       
    }


 
    const printAllPayStatements = async(preview)=>{
        return new Promise(async (resolve) => {
            setIsLoading(true);
            let docDefinition = preview ? await createPayStatementPDF() : await createPayStatementPDF({ID:2});
            console.log('payStatementsRef.current =  ', payStatementsRef.current); 
    
            for (let q = 0; q < payStatementsRef.current.length; q++)  addPayStatementToPDF(payStatementsRef.current[q], q, docDefinition);

            pdfMake.createPdf(docDefinition).download();
            setIsLoading(false);
            resolve(); // Resolve the promise after download completes
        });
    }
    const printPayStatement=async(PayStatement)=>{
        setIsLoading(true);
        let docDefinition=await createPayStatementPDF(PayStatement);
        addPayStatementToPDF(PayStatement, 0, docDefinition)
        setIsLoading(false);
        pdfMake.createPdf(docDefinition).download();
    }
    const createPayStatementPDF = async(PayStatement)=>{

        const draftImage={ margin:[50,170,0,0], opacity: 0.2, width:500, height:200, image: await getBase64ImageFromURL('https://firebasestorage.googleapis.com/v0/b/alianza-47fa6.appspot.com/o/draft.png?alt=media&token=34a82063-4b19-4529-a5a0-79877eced495')};

        let  docDefinition = {
            content: [],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                 lineItem:{
                    fontSize:9,
                     alignment:'right'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    alignment:'center',
                    bold: true,
                    fontSize: 11,
                    color: 'black'
                }
            }
        };
        if(!PayStatement?.ID)docDefinition.background=draftImage;
        console.log('returnign this doc ', docDefinition)
        return docDefinition;

        //if(printAllPayStatements)addPayStatementToPDF(payStatementsRef.current[0], docDefinition)
    
    }
    const addPayStatementToPDF = function(DPS, index, docDefinition) {

        let CompanyHeader = DPS.Company.Address + ', ' + DPS.Company.address2 + '\n' + 'Office: ' +DPS.Company.CompanyPhone + '   Fax: ' + DPS.Company.Fax;
        console.log('teh docDefinition we are reading int add pay stajte = o', docDefinition)
        let lineItemTable = [];
        let lineItemTableHeaders = [
            { text: 'Job Date', style: 'tableHeader'},
            { text: 'FB #', style: 'tableHeader'}
        ];
        if(DPS.Company.ShowDPSJobNumber)    lineItemTableHeaders.push( { text: 'Job #', style: 'tableHeader'});
        if(DPS.Company.ShowDPSPONumber) lineItemTableHeaders.push( { text: 'PO #', style: 'tableHeader'});

        lineItemTableHeaders.push({ text: 'Description', style: 'tableHeader'});
        if(DPS.Company.ShowDPSHours && !DPS.Subhauler)lineItemTableHeaders.push({ text: 'Hours', style: 'tableHeader'})
        lineItemTableHeaders.push({ text: 'Qty', style: 'tableHeader'})
        lineItemTableHeaders.push({ text: 'Rate', style: 'tableHeader'})
        lineItemTableHeaders.push({ text: 'Amount', style: 'tableHeader'})
        
        let lineItemTableWidths = [48,38];
        if(DPS.Company.ShowDPSPONumber) lineItemTableWidths.push(35);
        if(DPS.Company.ShowDPSJobNumber)lineItemTableWidths.push(35);
        if(DPS.Company.ShowDPSHours && !DPS.Subhauler) lineItemTableWidths.push(...[100, 30, 30, 35, 45]);
        else lineItemTableWidths.push(...[100, 30, 35, 45]);
        if (DPS.showDriverPercent) {
            lineItemTableWidths.push(...[20, 50]);
            lineItemTableHeaders.push({fontSize:11,  text: '%', style: 'tableHeader'});
            lineItemTableHeaders.push({fontSize:11,  text: 'Total', style: 'tableHeader'});
        }
        if(DPS.showTrailerFee){
            lineItemTableWidths.push(38);
            lineItemTableHeaders.push({ text: 'Trailer Fee', style: 'tableHeader'});
        } 
        if(DPS.showBrokerFee){
            lineItemTableHeaders.push(  { text: 'Broker Fee', style: 'tableHeader'});
            lineItemTableWidths.push(38);
        }
        if(DPS.showFuelCharge){
            lineItemTableHeaders.push(  { text: 'Fuel', style: 'tableHeader'});
            lineItemTableWidths.push(40);
        }

        lineItemTable.push(lineItemTableHeaders);
        let DPSNumRow = [{ text: [{ fontSize:11, bold: true, text: 'Statement Number: ' },{ fontSize:10, text: DPS.DPSNum}] }];
        let StartDateRow = [{ text: [{ fontSize:11, bold: true, text: 'Start Date: ' },{ fontSize:10, text: formatDate(DPS.StartDate,'/','MM/DD/YYYY')}] }];
        let EndDateRow = [{ text: [{fontSize:11,  bold: true, text: 'End Date: ' },{ fontSize:10, text:  formatDate(DPS.EndDate,'/','MM/DD/YYYY')}] }];
       
        for (var i = 0; i < DPS.LineItems.length; i++) {
            if (!DPS.LineItems[i].onHold) {
               
               let lineItemTableRow = [
                    { alignment: 'center', text:DPS.LineItems[i].JobDate, style: 'lineItem' },
                    { alignment: 'center', text: DPS.LineItems[i].FBNO , style: 'lineItem' },
                ];
                if(DPS.Company.ShowDPSJobNumber)lineItemTableRow.push({ alignment: 'center', text: DPS.LineItems[i].JobNumber, style: 'lineItem' });
                if(DPS.Company.ShowDPSPONumber)lineItemTableRow.push({ alignment: 'center', text: DPS.LineItems[i].PONumber, style: 'lineItem' });
                
            
                lineItemTableRow.push({ alignment: 'center', text: DPS.LineItems[i].Description, style: 'lineItem' });
                if(DPS.Company.ShowDPSHours && !DPS.Subhauler)lineItemTableRow.push({ alignment: 'center', text: DPS.LineItems[i].totalYardHours, style: 'lineItem' });
                
                lineItemTableRow.push({ alignment: 'center', text: DPS.LineItems[i].Qty, style: 'lineItem' })
                lineItemTableRow.push({  text:DPS.LineItems[i].RateString, style: 'lineItem' })
                lineItemTableRow.push({ text:DPS.LineItems[i].amountString, style: 'lineItem' })
                 
             
                console.log('we are creating pdf and   DPS.LineItems[i].FBNO ' + DPS.LineItems[i].FBNO)
                console.log('we are creating pdf and DPS.LineItems[i].DriverPercent ' +DPS.LineItems[i].DriverPercent)
                console.log('we are creating pdf and DPS.LineItems[i].TotalString' +DPS.LineItems[i].TotalString)

                if (DPS.showDriverPercent) {
                    lineItemTableRow.push({ alignment:'center', text:DPS.LineItems[i].DriverPercent, style: 'lineItem' });
                    lineItemTableRow.push({ text:DPS.LineItems[i].TotalString, style: 'lineItem' } );
                }
                if(DPS.showTrailerFee)lineItemTableRow.push({ text:DPS.LineItems[i].TrailerTotalString, style: 'lineItem' });
                if(DPS.showBrokerFee)lineItemTableRow.push({ text:DPS.LineItems[i].BrokerTotalString, style: 'lineItem' });
                if(DPS.showFuelCharge)lineItemTableRow.push({ text:DPS.LineItems[i].FuelTotalString, style: 'lineItem' });
              //  
                lineItemTable.push(lineItemTableRow);
            }
        }
    
        let totalsTable = [];
        let totalsHeader = [{ bold: true, text: 'Type' }, { bold: true, text: 'Qty' }, { bold: true, text: 'Total' }];
        totalsTable.push(totalsHeader);
        let DPSTotalRow =[];
        for (var k = 0; k < DPS.Totals.length; k++) {
            if (DPS.Totals[k].Type ==='Amount Due' || DPS.Totals[k].Type ==='DPS Total') {
                DPSTotalRow = [{ colSpan: 2, bold: true, text: 'Amount Due ' }, {}, { alignment: 'right',text: '$' + Number(DPS.Totals[k].Total).formatMoney(2) }];
            }
            else {
                if (DPS.Totals[k].Total != 0) {
                      var qtyString;
                        if(DPS.Totals[k].Type==='Tons') qtyString=Number( DPS.Totals[k].Qty).formatMoney(2);
                        else if(DPS.Totals[k].Type==='Hours') qtyString=Number(DPS.Totals[k].Qty).formatMoney(1);
                        else qtyString=Number(DPS.Totals[k].Qty).toString();
                    let totalsTableRow = [{fontSize:10, text: DPS.Totals[k].Type }, {alignment:'right', fontSize:10,text:qtyString }, {alignment:'right', fontSize:10, text: '$' + Number(DPS.Totals[k].Total).formatMoney(2) }];
                   totalsTable.push(totalsTableRow);
                }
            }
            console.log('k = '+ k);
            console.log('DPS.Totals.length = '+ DPS.Totals.length);
            console.log('so were pushing DPSTotalRow =', DPSTotalRow)
            if (k === (DPS.Totals.length - 1)) totalsTable.push(DPSTotalRow);
        }
    
        let DPSPDFHeader = {
            columns: [
                { width: 140, text: '' },
                { width: 220, style: 'header', alignment: 'center', text: DPS.Company.CompanyName, fontSize: 14 },
                { width: 140, text: 'Prepared On: ' + DPS.DPSDate, alignment: 'right', fontSize: 10 },
            ]
        };
        if(index!==0)DPSPDFHeader.pageBreak='before';
       docDefinition.content.push(DPSPDFHeader);

     

       let DPSPDFHeader2 = {
           columns: [
               { width: 120, text: '' },
               { width: 260, style: 'header', alignment: 'center', text: CompanyHeader, fontSize: 12 },
               { width: 140, text: '' },
           ]
       };
       docDefinition.content.push(DPSPDFHeader2);
       let DPSPDFDriver = {
            columns: [
                { width: 50, text: '' },
                { width: 200, margin: [0, 57, 0, 27], text: [{ fontSize: 12, bold: true, text: DPS.driverName }, { fontSize: 12, bold: true, text: '\n ' + DPS.driverAddress + '\n ' + DPS.driverCity + ', ' + DPS.driverState + ', ' + DPS.driverZipCode }] },
                { width: 120, text: '' },
                { width: 145, table: { widths: ['*'], body: [DPSNumRow, StartDateRow, EndDateRow] } }
            ]
        };
        docDefinition.content.push(DPSPDFDriver);
    
        let DPSPDFLineItemTable = {
            style: 'tableExample',
            table: { dontBreakRows: true, widths: lineItemTableWidths, body: lineItemTable }
        };
        docDefinition.content.push(DPSPDFLineItemTable);
    
        let DPSPDFTotals = {
            columns: [
                { width: 295, text: '' },
                { width: 220, table: { widths: ['*', '*', '*'], body: totalsTable } }
            ]
        };
        if (DPS.DPSNotes) DPSPDFTotals.columns[0].text = [{ bold: true, text: 'Notes: ' }, DPS.DPSNotes];
        
        docDefinition.content.push(DPSPDFTotals);
        console.log('creatying doc = ', docDefinition)
     
    
    }
    const getBase64ImageFromURL= (url) => {
        return new Promise((resolve, reject) => {
         const img = new Image();
         img.crossOrigin = 'Anonymous';
         img.src = url;
          
          img.onload = () => {
            var canvas = document.createElement("canvas");
            
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          };
          img.onerror = error => {
            reject(error);
          };
        
        });
    }


    return (
        <PrintPayStatementContext.Provider value={{
           
            printAllPayStatements,
            checkPayStatements,
            printPayStatement,
           
        }}>
            {children}
        </PrintPayStatementContext.Provider>
    );
};

export const usePrint = () => useContext(PrintPayStatementContext); 