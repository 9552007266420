import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber'; 

const OvertimeDialog = ({ visible, setVisible, freightbill, buttons, header }) => {
   // console.log('buttons = ', buttons)
  // Function to handle closing the dialog
  const closeDialog = () => {
    setVisible(false);
  };
 // console.log('text = ', text)
  return (
    <Dialog header={header}  visible={visible}  onHide={closeDialog}  style={{ width: '55vw' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}  modal   className="p-fluid">
        <div className='mbsc-grid' style={{ textAlign: 'center', marginBottom: '20px' }}>
            <div className='mbsc-row' >
                <div className='mbsc-col' >
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Job OT Rate</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.JobOvertimeRate} isCurrency={true}   />
                    </div>
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Travel OT Rate</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.TravelOvertimeRate} isCurrency={true}   />
                    </div>
                </div>
                <div className='mbsc-col' >
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  > Job OT Qty</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.JobOvertimeQty} isCurrency={true}   />
                    </div>
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  > Travel OT Qty</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.TravelOvertimeQty} isCurrency={true}   />
                    </div>
                </div>
                <div className='mbsc-col' >
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >  Job OT Total</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.JobOvertimeTotal} isCurrency={true}   />
                    </div>
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  > Travel OT Total</span> 
                        <AutoSelectInputNumber  disabled={!freightbill.billingOverride} value={freightbill.TravelOvertimeTotal} isCurrency={true}   />
                    </div>
                </div>
            </div>
      </div>
      <div className="dialog-buttons" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
      {buttons &&( <div>{buttons.map((btn, idx) => (
          <Button   key={idx}   label={btn.label}   className={btn.className}    onClick={() => {  btn.onClick(); closeDialog(); }}   />
        ))}
        </div>)} 
      </div>
    </Dialog>
  );
};

export default OvertimeDialog;
