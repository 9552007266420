import React, { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import {useGlobal} from '../../context/GlobalContext';
import { useExpense } from './context/ExpenseContext';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faLink, faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';


const ExpenseHome = () => {
    const { formatDate, accounts, drivers, deleteDocument , gearedUser} = UserAuth();
	const {expense, setExpense, newExpense, setExpensePopUpVisible} = useGlobal();
    const { expenses, isLoading, queryExpenses } = useExpense();

    const driversAndAccounts = drivers.concat(accounts);
    const toast = useRef(null);

 
  
    // Get today's date and one month ago
    var todaysDate = new Date();
    let startDate = new Date();
    startDate.setDate(todaysDate.getDate() - 30);
    
    const [inputValues, setInputValues] = useState({
        Account: { ID: '', Name: 'No Account' },
        EndDateValue: todaysDate,
        StartDateValue: startDate,
        EndDate: formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate: formatDate(startDate, '/', 'YYYY/MM/DD')
    });

    const [filters, setFilters] = useState({
        'Name.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'BillTo.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'PayTo.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'driverName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        pay: { value: null, matchMode: FilterMatchMode.EQUALS },
        bill: { value: null, matchMode: FilterMatchMode.EQUALS },
        onHold: { value: null, matchMode: FilterMatchMode.EQUALS },
        billed: { value: null, matchMode: FilterMatchMode.EQUALS },
        paid: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    useEffect(() => {
        if (inputValues?.StartDate) {
            queryExpenses(inputValues.StartDate, inputValues.EndDate, inputValues.Account);
        }
    }, []);

    const handleDateChange = (fieldName, value) => {
        const formattedDate = formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues(prev => ({
            ...prev,
            [fieldName]: formattedDate,
            [`${fieldName}Value`]: value
        }));
    };

    const changeAccount = (fieldName, value) => {
        setInputValues(prev => ({ ...prev, [fieldName]: value }));
    };

    const booleanFilterTemplate = (options) => {
        return (
            <TriStateCheckbox
                style={{borderColor:"#d1d5db", background:"white"}}
                value={options.value !== null ? options.value : undefined}
                onChange={(e) => options.filterApplyCallback(e.value)}
            />
        );
    };

    const booleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'green' : 'red';
        return (
            <i style={{color:color}}
                className={classNames('pi', {
                    'true-icon pi-check-circle': rowData[field],
                    'false-icon pi-times-circle': !rowData[field]
                })}
            />
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.realJobDate, '/', 'MM/DD/YYYY');
    };

    const handleEdit = (rowData) => {
        setExpense(rowData);
        setExpensePopUpVisible(true);
    };

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this Expense?")) {
                await deleteDocument(rowData, 'Expenses');
            }
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
            <FontAwesomeIcon 
                className="fas" 
                icon={faMinusCircle} 
                style={{
                    color: "red", 
                    height: "1.25em", 
                    textAlign: "center", 
                    width: "1.25em !important"
                }}
            /> 
        </button>
    );

    const currencyBodyTemplate = (rowData, field) => {
        return (
            <span style={{paddingRight:".5em", float:"right"}}>
                ${Number(Number(rowData[field]).toFixed(2)).formatMoney(2)}
            </span>
        );
    };

    const openInvoice = (expense) => {
        var win = window.open('/invoice/invoice/'+expense.Invoice, '_blank');
        win.focus();
    };

    const openPayStatement = (expense) => {
        var win = window.open('/paystatement/paystatement/'+expense.DPS, '_blank');
        win.focus();
    };

    const invoiceBodyTemplate = (rowData) => {
        if (rowData.Invoice) {
            return (
                <button 
                    style={{ 
                        margin: '0', 
                        padding: '.5em', 
                        width: "95%" 
                    }}   
                    onClick={() => openInvoice(rowData)}    
                    className="mbsc-ios mbsc-btn-primary mbsc-btn"
                >
                    Invoice
                </button>
            );
        }
        return null;
    };

    const payStatementBodyTemplate = (rowData) => {
        if (rowData.DPS) {
            return (
                <button 
                    style={{ 
                        margin: '0', 
                        padding: '.5em', 
                        width: "100%" ,
                        fontSize:".8em"

                    }}   
                    onClick={() => openPayStatement(rowData)}    
                    className="mbsc-ios mbsc-btn-primary mbsc-btn"
                >
                    Pay Statement
                </button>
            );
        }
        return null;
    };

    const openFreightBill = (expense) => {
        if (expense.FreightBill) {
            var win = window.open('/freightbill/freightbill/'+expense.FreightBill+'/'+expense.dispatchID, '_blank');
            win.focus();
        }
    };

    const freightBillBodyTemplate = (rowData) => {
        if (rowData.FreightBill) {
            return (
                <button type="button" onClick={() => openFreightBill(rowData)}>
                    <FontAwesomeIcon icon={faLink} />
                </button>
            );
        }
        return null;
    };

    const updateDriverFromFreightBill = async (rowData) => {
        try {
            const freightBillRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/FreightBills/${rowData.FreightBill}`);
            const freightBillDoc = await getDoc(freightBillRef);
            
            if (freightBillDoc.exists() && freightBillDoc.data().Driver) {
                const expenseRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses/${rowData.ID}`);
                await updateDoc(expenseRef, {
                    driverID: freightBillDoc.data().Driver
                });
                
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Driver updated successfully',
                    life: 3000
                });
                
                // Refresh the expenses list
                queryExpenses(inputValues.StartDate, inputValues.EndDate, inputValues.Account);
            } else {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'No driver found in freight bill',
                    life: 3000
                });
            }
        } catch (error) {
            console.error("Error updating driver:", error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to update driver',
                life: 3000
            });
        }
    };

    const updateDriverBodyTemplate = (rowData) => {
        if (!rowData.driverID && rowData.FreightBill) {
            return (
                <button type="button" onClick={() => updateDriverFromFreightBill(rowData)}>
                    <FontAwesomeIcon 
                        icon={faUserPlus} 
                        style={{
                            color: "#2196F3",
                            height: "1.25em",
                            textAlign: "center",
                            width: "1.25em !important"
                        }}
                    />
                </button>
            );
        }
        return null;
    };

    const renderHeader = () => (
        <div className="mbsc-grid mbsc-row">
            <span className="mbsc-col-xl-1">Expenses</span>
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar 
                        value={inputValues.StartDateValue} 
                        onChange={(e) => handleDateChange('StartDate', e.value)} 
                    />
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">End Date:</span>
                    <Calendar 
                        value={inputValues.EndDateValue} 
                        onChange={(e) => handleDateChange('EndDate', e.value)} 
                    />
                </div>
            </div>
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>
                <AutoCompleteInput 
                    label="Account/Driver" 
                    fieldName="Account" 
                    field="Name" 
                    hideAddCustom={true} 
                    value={inputValues.Account} 
                    labelClass='p-inputgroup-addon search-auto-input' 
                    suggestions={driversAndAccounts} 
                    setValue={setInputValues} 
                    handleFieldChange={changeAccount} 
                />
                <Button 
                    color="primary" 
                    onClick={() => queryExpenses(inputValues.StartDate, inputValues.EndDate, inputValues.Account)} 
                    style={{
                        margin:"0", 
                        height:"50%", 
                        float:"right", 
                        width:"45%", 
                        marginTop:".5em", 
                        marginLeft:".5em", 
                        paddingLeft:"1em !important", 
                        paddingBottom: ".2em", 
                        paddingTop: ".2em"
                    }}
                >
                    Search Expenses
                </Button>
            </div>
        </div>
    );

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable 
                value={expenses} 
                paginator 
                rows={25} 
                dataKey="ID" 
                filters={filters} 
                header={renderHeader()} 
                filterDisplay="row" 
                emptyMessage="No expenses found."
            >
                <Column 
                    pt={{root: { 'data-label': 'Edit' }}}
                    header="Edit" 
                    body={editBodyTemplate} 
                />
               
                <Column 
                    pt={{root: { 'data-label': 'Delete' }}}
                    style={{textAlign:"center"}}
                    header="Delete" 
                    body={deleteBodyTemplate} 
                />
                <Column 
                    field="Name.Name" 
                    header="Name" 
                    filter 
                    filterPlaceholder="Search by name"
                />
                <Column 
                    field="onHold" 
                    header="On Hold" 
                    dataType="boolean" 
                    body={(rowData) => booleanBodyTemplate(rowData, 'onHold')} 
                    filter 
                    filterElement={booleanFilterTemplate}
                />
                       <Column 
                    field="realJobDate" 
                    header="Job Date" 
                    sortable 
                    body={dateBodyTemplate}
                    filter 
                    filterPlaceholder="Search by date"
                />
                  <Column 
                    field="driverName" 
                    header="Driver Name" 
                    filter 
                    filterPlaceholder="Search by driver name"
                />
                <Column 
                    field="pay" 
                    header="Pay" 
                    dataType="boolean" 
                    body={(rowData) => booleanBodyTemplate(rowData, 'pay')} 
                    filter 
                    filterElement={booleanFilterTemplate}
                />
                <Column 
                    field="bill" 
                    header="Bill" 
                    dataType="boolean" 
                    body={(rowData) => booleanBodyTemplate(rowData, 'bill')} 
                    filter 
                    filterElement={booleanFilterTemplate}
                />
                <Column 
                    field="BillTo.Name" 
                    header="Bill To" 
                    filter 
                    filterPlaceholder="Search by bill to"
                />
                <Column 
                    field="PayTo.Name" 
                    header="Pay To" 
                    filter 
                    filterPlaceholder="Search by pay to"
                />
                <Column 
                    field="billed" 
                    header="Invoice" 
                    dataType="boolean"
                    body={invoiceBodyTemplate}
                    filter
                    filterElement={booleanFilterTemplate}
                />
                <Column 
                    field="paid" 
                    header="Pay Statement" 
                    dataType="boolean"
                    body={payStatementBodyTemplate}
                    filter
                    filterElement={booleanFilterTemplate}
                />
                <Column 
                    pt={{root: { 'data-label': 'Freight Bill' }}}
                    header="Freight Bill" 
                    body={freightBillBodyTemplate}
                    style={{textAlign:"center"}}
                />
                <Column 
                    field="qty" 
                    header="QTY" 
                    sortable
                    style={{textAlign:"right"}}
                    body={(rowData) => currencyBodyTemplate(rowData, 'qty')}
                />
                <Column 
                    field="rate" 
                    header="Rate" 
                    sortable
                    style={{textAlign:"right"}}
                    body={(rowData) => currencyBodyTemplate(rowData, 'rate')}
                />
                <Column 
                    field="total" 
                    header="Total" 
                    sortable
                    style={{textAlign:"right"}}
                    body={(rowData) => currencyBodyTemplate(rowData, 'total')}
                />
            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
            
          
        </div>
    );
};

export default ExpenseHome; 