


import React from 'react';


const DispatchListItem = ({ dispatch, onclick}) => {


return(
<div className="mbsc-grid" onClick={(e)=>onclick(dispatch)} style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px" }} >      
    <div className="mbsc-row" style={{padding:".5em"}}>
        <div className="mbsc-col">{dispatch.JobDate}   </div>
        <div className="mbsc-col">Trucks Ordered: {dispatch.TrucksOrdered} </div>
    </div>
    <div className="mbsc-row" style={{padding:".5em"}}>
        <div className="mbsc-col">{dispatch.TruckType.Name}</div>

        <div className="mbsc-col"> Trucks Assigned: {dispatch.TrucksAssigned}</div>
    </div>
</div>
)

};

export default DispatchListItem;