import React from 'react';
import { Card } from 'primereact/card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faTrashCan } from '@fortawesome/free-solid-svg-icons';  
import { Tooltip } from 'primereact/tooltip';
const PrelimList = ({ prelim, deletePrelim,emailPrelim }) => {
    const downloadPrelim = ()=>{
     
       var win = window.open(prelim.PDFurl, '_blank');
    }

    return (
        <div style={{ paddingTop: '0',paddingLeft: '.5em', paddingRight:'0', marginBottom: '.5em ', width:'100%' }} >
            <Card  style={{  paddingLeft: '.5em',paddingRight:'.5em', color:'black' }}>
                <div className="mbsc-row " style={{  margin:'0', }}>
                    <div className='mbsc-col-8' style={{ fontWeight: 'bold', fontSize:'1.1em', padding:'0' }}>{prelim.text}</div>
                    <div className='mbsc-col-4' > 
                        <span id="download-icon"> <FontAwesomeIcon  onClick={(e) => downloadPrelim()}  className="fas" icon={faDownload} style={{  width:"1em", paddingRight:".5em", cursor: "pointer"}}/></span>
                       {emailPrelim && (<span id="mail-icon"> <FontAwesomeIcon className="fas" onClick={(e) => emailPrelim(prelim)} icon={faEnvelope} style={{  width:"1em", paddingRight:".5em", cursor: "pointer"}}/></span>)}
                        <span id="trash-icon"> <FontAwesomeIcon onClick={(e) => deletePrelim(prelim)}   className="fas" icon={faTrashCan} style={{  width:"1em", paddingRight:".5em", cursor: "pointer"}}/></span>

                        <Tooltip target="#download-icon" content="Download" />
                
                        <Tooltip target="#mail-icon" content="E-Mail" />
                        <Tooltip target="#trash-icon" content="Delete" />
                    </div> 
                </div>
              
            
                <div className="mbsc-row" style={{paddingTop:'1em'}}>{prelim.time}</div>
                
          
        </Card>
      </div>
        );
    };
    
    export default PrelimList;