

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';
import {PrevailingWageReportContextProvider } from './context/PrevailingWageReportContext';
import PrevailingWageHome from './PrevailingWageHome';
import PrevailingWageReportList from './PrevailingWageReportList';
import PrevailingWageReport from './PrevailingWageReport';
import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';
import { usePrevailingWage } from './context/PrevailingWageContext';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';


function PrevailingWageParent() {
    const { isLoading } = usePrevailingWage();


    console.log('FREIGHT BILL PARENT RE RENDERING')

    const toast = useRef(null);

 
return ( 
    <Page>
   
        <React.Fragment>
        <Toast ref={toast} />
            <PrevailingWageReportContextProvider>
                <Routes>
                    <Route path='home' element={<ProtectedRoute><PrevailingWageHome/></ProtectedRoute>} />
                        <Route path='reportlist/:id' element={<ProtectedRoute><PrevailingWageReportList/></ProtectedRoute>} />
                        <Route path='reportcreate/:id/:weekDate' element={<ProtectedRoute><PrevailingWageReport/></ProtectedRoute>} />
                </Routes>
				<LoadingOverlay isLoading={isLoading} message="Please wait..." />
                </PrevailingWageReportContextProvider>
        </React.Fragment>
    </Page>
  );
}

export default PrevailingWageParent;