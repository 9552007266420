import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';

import { UserAuth } from '../../../context/AuthContext';
import { useReport } from '../context/ReportContext';
import { useReportBuilder } from '../context/ReportBuilderContext';

import { db } from '../../../firebase';
import { onSnapshot, query, collection, where } from 'firebase/firestore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button} from 'primereact/button';
import { Calendar} from 'primereact/calendar';

import { FilterMatchMode} from 'primereact/api';

import CustomizeTable from '../CustomizeTable'; 

import InvoiceTable from '../ReportTables/InvoiceTable';
import ReportTable from '../ReportTables/ReportTable';
import AutoCompleteInputParent from '../../InputComponents/AutoCompleteInputParent'; 
import InputTextParent from '../../InputComponents/InputTextParent'; 
import pdfMake from 'pdfmake/build/pdfmake';

import LoadingOverlay from '../../MiscComponents/LoadingOverlay';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';

const ReportBuilder = () => {
    const navigate = useNavigate();
    const { reportTabType, listType, reportID } = useParams();
    const [ saveReportPopUpVisible, setSaveReportPopUpVisible] = useState(false);
    const { gearedUser,formatDate, drivers, companies, accounts, subhaulers, addDocument} = UserAuth();

    const {setSelectFields, showSelectFields, setShowSelectFields, reportTable, setReportTable, reportList, getDefaultReports,
        getReports, freightBillFields,invoiceFields, driverFields, formatMoney,roundedMultiplication, setReportName} = useReport();

    const [reportTotals, setReportTotals] = useState({});
  
    const [reportParents, setReportParents]=useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reportType, setReportType]= useState(reportTabType);

    const inputValuesRef = useRef(null);
    const reportCompanyRef = useRef(null);
    const tableListenerRef = useRef(null);
    const fullReportDataRef = useRef(null);
    const reportDataRef = useRef(null);
    const reportTableRef = useRef(null);

    const reportTypeRef = useRef(reportTabType);
    var todaysDate = new Date();
    let startDate =new Date();
 
    let formattedTodaysDate= formatDate(todaysDate, '/', 'YYYY/MM/DD');
    startDate.setDate(todaysDate.getDate() - 30);
    const [inputValues, setInputValues]=useState({
        ReportDateValue: todaysDate,
        EndDateValue:todaysDate,
        StartDateValue:startDate,
        Account:{ ID:'', Name:'No Account' },
        ReportDate: formatDate(todaysDate, '/', 'MM/DD/YYYY'),
        EndDate:formattedTodaysDate,
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'),
        reportName:reportTable?.Name ? reportTable.Name : ''
        
    });
    console.log('REPORT TABLE reportParents ' ,reportTable)
    const sortedReportParents =  [...reportParents].sort((a, b) => {
        if (a.truckName < b.truckName) return -1;
        if (a.truckName > b.truckName) return 1;
        return 0;
    });
    useEffect(()=>{
        console.log('reportID USE EFFECT RUNNING')
       if(reportList?.length<=0) listType==='create' ?  getDefaultReports() : getReports() 
       
    },[reportID]);

    useEffect(()=>{
        if(reportList?.length>0 ) {
            console.log('reportList USE EFFECT RUNNING')
            for(let i=0; i<reportList.length; i++)
                if(reportList[i].ID===reportID) createReportTable(reportList[i]);
        }
     },[reportList]);

     useEffect(()=>{
        console.log('inputValues USE EFFECT RUNNING')
        inputValuesRef.current = {...inputValues}
     },[inputValues]);

    useEffect(()=>{
        console.log('reportTable USE EFFECT RUNNING')
        reportTableRef.current=reportTable;
        setInputValues((prev) => ({ ...prev, reportName: reportTable?.Name ? reportTable.Name : ''}))
    },[reportTable]);

    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++){
                if(companies[i].mainCompany){
                      setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
                    reportCompanyRef.current={...companies[i]}
                    console.log('set input Values.company!!!', companies[i])
                }
                }
            }
           
    }, [companies]);



    const calculateGrandTotals = useCallback(() => {
        if (!reportTable || !reportTable.Columns) return [];
        return reportTable.Columns.filter(col => col.showTotal) .map(col => (
            {
                Type: col.title,
                Total: Object.values(reportTotals).reduce((acc, parentTotals) => acc + (parentTotals[col.field] || 0), 0 ),
            }));
    }, [reportTable, reportTotals]);

    const grandTotals = useMemo(() => calculateGrandTotals(), [reportTable, reportTotals])
    console.log('grandTotals =', grandTotals)

    const createReportTable =  (report) => {
        console.log('open this table', report)
        let tempTable ={
            ID:report.ID,
            Name:report.Name,
            Columns:report.Columns,
            groupByDrivers:report.groupByDrivers ? report.groupByDrivers: false,
            separateScaleTags:report.separateScaleTags ? report.separateScaleTags: false,
            Type:report.Type,
        }
       
        tempTable.groupByDays = report.groupByDays!==undefined ? report.groupByDays: 'null';
        tempTable.billed = report.billed!==undefined ? report.billed : 'null';
        tempTable.paid= report.paid!==undefined ? report.paid : 'null';
        tempTable.Paid= report.Paid!==undefined ? report.Paid : 'null';
        tempTable.missing = report.missing!==undefined ? report.missing : 'null';
        tempTable.Subhauler= report.Subhauler!==undefined ? report.Subhauler : 'null';
        let tempFilters ={};
        let tempTotals =[];
        for(let i=0; i<tempTable.Columns.length; i++){
            if(tempTable.Columns[i].showTotal)tempTotals.push({
                Type:tempTable.Columns[i].title,
                Total:0
            })
           tempFilters[tempTable.Columns[i].field]=  { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
        tempTable.totals=[...tempTotals];
      
        setReportTable({...tempTable});
        if(listType==='list'){
                
            let tempInputValues = {
                ReportDateValue: new Date(report.ReportDate),
                EndDateValue:new Date(report.EndDate),
                StartDateValue:new Date(report.StartDate),
                Account:report.Account,
                ReportDate: report.ReportDate,
                EndDate:report.EndDate,
                StartDate:report.StartDate,
                reportName:reportTable?.reportName ? reportTable.reportName : reportTable?.Name ? reportTable.Name: ''
                
            }
            setInputValues(tempInputValues);
            inputValuesRef.current = tempInputValues;
            runReport();
        }
    
    };

    const handleDateChange =  useCallback(( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    }, []);

    const checkObject = useCallback((object)=>{
  
        let shouldAddObject = true;
        object.AccountName = object.Account ? object.Account.Name : '';
        object.TruckName = object.Subhauler ? object.Truck: object.Truck ? object.Truck.Name : '';
        object.LoadSiteName = object.LoadSite ? object.LoadSite.Name : '';
        object.DumpSiteName = object.DumpSite ? object.DumpSite.Name : '';
        object.Balance = object.Balance ?  object.Balance.Total : 0;
        object.Total =  object.Total ?  object.Total.Total : 0;
        object.CompanyID = object.Company ? object.Company.ID : '';
        object.AccountID  = object.Account ? object.Account.ID : '';
        
      
        for (const [field, value] of Object.entries(reportTableRef.current)) {
          
            if (field === 'Columns' || value === 'null' || field==='Type' || field==='tableParams' || field==='groupByDrivers' || field==='groupByDays' || field==='totals' || field==='separateScaleTags' || field==='Name' || field==='ID' || field==='timestamp') continue;
         
            if (object[field] !== value) {
                console.log('IS THE OBJECT FIALING HTOUHG= ',object[field])
                shouldAddObject = false;
                break;
            }
           
            
        }
        return shouldAddObject;
    },[reportTable]);

    const changeReportAccount =  (fieldName, value)=>{
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
    }

    const changeReportCompany =  (fieldName, value) => {
        reportCompanyRef.current={...value};
        console.log('value = ', value)
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
        if(reportType==='Drivers')debouncedSetReportParents([{ header: reportType, Name: reportType, filteredData: [], reportData:  createDriverReport()}]);
    
    }

    const returnHome = () =>{
        navigate('/report/reportHome/' + listType+'/'+reportType, {replace:true})
    }
    const filterByDriver = (reportDays) =>{
        console.log('runnign filter by driver' ,reportDays);
        let tempDrivers =[...drivers];
        for (let s = 0; s < subhaulers.length; s++) {
            if (subhaulers[s].Driver) {
                tempDrivers.push({ ...subhaulers[s].Driver });
            }
        }
    
        for(let d=0;d<tempDrivers.length;d++){
            tempDrivers[d].reportData=[];
            tempDrivers[d].filteredData=[];
            tempDrivers[d].header = 'Freight Bills for '+ tempDrivers[d].Name;

            for(let k=0; k< reportDataRef.current.length; k++){
                if(tempDrivers[d].ID===reportDataRef.current[k].Driver){
                    let tempFreight = {...reportDataRef.current[k]}
                    if(reportTableRef.current.separateScaleTags && reportTableRef.current.separateScaleTags!=='null'){
                        if(tempFreight.BillType==='Ton'){
                            for(var z=0; z<tempFreight.Weights.length; z++){
                        
                                let tempWeight={...tempFreight.Weights[z]};
                                tempWeight.JobDate=tempFreight.JobDate;
                                tempWeight.jobDateValue= tempFreight.jobDateValue;
                                tempWeight.FBNO=tempFreight.FBNO;
                                tempWeight.truckName=tempFreight.Truck.Name;
                                tempWeight.ID=tempFreight.ID;
                                tempWeight.dispatchID=tempFreight.dispatchID;
                                tempWeight.CompanyID=tempFreight.Company.ID;
                                tempWeight.AccountID=tempFreight.Account.ID;
                                tempWeight.BillRate=tempFreight.BillRate;
                                tempWeight.Total=roundedMultiplication(tempWeight.weight,tempFreight.BillRate);
                                tempWeight.Driver=tempFreight.Driver;
                                tempDrivers[d].filteredData.push({...tempWeight})
                                tempDrivers[d].reportData.push({...tempWeight})
                        
                            }
                         
                            if(tempFreight.standBilled>0){
                                let tempWeight={
                                    FBNO:tempFreight.FBNO,
                                    ID:tempFreight.ID,
                                    JobDate:tempFreight.JobDate,
                                    truckName:tempFreight.Truck.Name,
                                    BillRate:tempFreight.standBR,
                                    Driver:tempFreight.Driver,
                                    jobDateValue:tempFreight.jobDateValue,
                                    weight:tempFreight.standExMin,
                                    dispatchID:tempFreight.dispatchID,
                                    tagNO:'Stand By',
                                    Total:tempFreight.standBilled
                                }
                                tempWeight.CompanyID=tempFreight.Company.ID;
                                tempWeight.AccountID=tempFreight.Account.ID;
                                tempWeight.truckName=tempFreight.Truck.Name;
                                tempDrivers[d].filteredData.push({...tempWeight})
                                tempDrivers[d].reportData.push({...tempWeight})
                            }
                            if(tempFreight.fuelBilled>0){
                                let tempWeight={
                                    FBNO:tempFreight.FBNO,
                                    ID:tempFreight.ID,
                                    JobDate:tempFreight.JobDate,
                                    jobDateValue:tempFreight.jobDateValue,
                                    truckName:tempFreight.Truck.Name,
                                    BillRate:tempFreight.BillRate,
                                    Driver:tempFreight.Driver,
                                    weight:tempFreight.FuelCharge,
                                    dispatchID:tempFreight.dispatchID,
                                    tagNO:'Fuel Charge',
                                    Total:tempFreight.fuelBilled
                                }
                                tempWeight.CompanyID=tempFreight.Company.ID;
                                tempWeight.AccountID=tempFreight.Account.ID;
                                tempWeight.truckName=tempFreight.Truck.Name;
                                tempDrivers[d].truckName = tempWeight.truckName;
                                tempDrivers[d].filteredData.push({...tempWeight})
                                tempDrivers[d].reportData.push({...tempWeight})
                            }
                        }
                    }else  if (reportTableRef.current.groupByDays && reportTableRef.current.groupByDays !== 'null') {
                        for (let z = 0; z < reportDays.length; z++) {
                            if (tempFreight.JobDate === reportDays[z]) {
                                let existingReport = tempDrivers[d].reportData.find(report => report.JobDate === reportDays[z]);
                             
                                // If a report for this JobDate already exists, update it
                                if (existingReport) {
                                    // Compare startTimePaid and endTimePaid
                                    if (tempFreight.startTimePaid < existingReport.startTimePaid) {
                                        existingReport.startTimePaid = tempFreight.startTimePaid;
                                    }
                                    if (tempFreight.endTimePaid > existingReport.endTimePaid) {
                                        existingReport.endTimePaid = tempFreight.endTimePaid;
                                    }
                                    
                                    // Recalculate totalYardHours based on new start and end times
                                    existingReport.totalYardHours = calculateYardHours(existingReport.startTimePaid, existingReport.endTimePaid);
                                } else {
                                  
                                    // Create a new report data object if none exists for this JobDate
                                    let tempReportDataObject = {
                                        JobDate: reportDays[z],
                                        jobDateValue: new Date(reportDays[z]),
                                        startTimePaid: tempFreight.startTimePaid,
                                        endTimePaid: tempFreight.endTimePaid,
                                        AccountID:tempFreight.Account.ID,
                                        CompanyID:tempFreight.Company.ID,
                                        totalYardHours: calculateYardHours(tempFreight.startTimePaid, tempFreight.endTimePaid)
                                    };
                                    if(tempReportDataObject.totalYardHours>0)tempDrivers[d].filteredData.push(tempReportDataObject);
                                    if(tempReportDataObject.totalYardHours>0)tempDrivers[d].reportData.push(tempReportDataObject);
                                    
                                }
                            }
                        }
                    }else{
                        tempDrivers[d].filteredData.push({...reportDataRef.current[k]})
                        tempDrivers[d].reportData.push({...reportDataRef.current[k]})
                    }
                } 
            }
           
        }
        
        return  tempDrivers.filter(driv => driv.reportData.length>0);
    }
    const createDriverReport = ()=>{
        let tempDrivers =[];
        console.log('data when we run reportt', reportDataRef.current)
        if(reportTableRef.current.Subhauler || reportTableRef.current.Subhauler==='null') for (let s = 0; s < subhaulers.length; s++) {
            if (subhaulers[s].Driver) {
                drivers.push({ ...subhaulers[s].Driver });
            }
        }
        for(let d=0;d<drivers.length;d++){
            let tempDriver ={ID: drivers[d].ID, addToReport:false, Name: drivers[d].Name, CompanyID: reportCompanyRef.current.ID, AccountID: inputValues.Account.ID, Gross:0, Earned:0, Net:0, BrokerFee:0, TrailerFee:0, tHours:0};
            for(let k=0; k< reportDataRef.current.length; k++){
                if(drivers[d].ID===reportDataRef.current[k].Driver && reportCompanyRef.current.ID ===reportDataRef.current[k].CompanyID){
                    tempDriver.addToReport=true;
                    let tempFreight = {...reportDataRef.current[k]}
              
                    tempDriver.Gross += Number(tempFreight.bTotal);
                    tempDriver.Earned += Number(tempFreight.tPaid);
                    tempDriver.Net += Number(tempFreight.bTotal-tempFreight.tPaid);
                    tempDriver.BrokerFee += Number(tempFreight.bFee);
                    tempDriver.TrailerFee += Number(tempFreight.tFee);
                    tempDriver.tHours+= Number(tempFreight.tHours);
                }
            }
            if(tempDriver.addToReport)tempDrivers.push({...tempDriver});
        }
        console.log('tempdrivers = ', tempDrivers)
        return  tempDrivers;
    
    }
    const calculateYardHours = (startTime, endTime) => {
        const start = new Date(`1970-01-01T${startTime}:00`);
        const end = new Date(`1970-01-01T${endTime}:00`);
    
        // Get the difference in hours
        const diffInMilliseconds = end - start;
        console.log('diffInMilliseconds = '+ diffInMilliseconds);
        const diffInHours = diffInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to hours
        console.log('calculating time between startime = '+ startTime);
        console.log('AND end time  = '+endTime);
        console.log('diffInHours  = '+ diffInHours);
        // Return the difference with one decimal point
        return diffInHours.toFixed(1);
    };
    const getGrossForPayStatement = (payStatement) =>{
        let newPayStatement = {
            ID:payStatement.ID,
            Company:{
                CompanyName:payStatement.CompanyName,
                CompanyID:payStatement.CompanyID,
                ID:payStatement.Company.ID
            },
            Subhauler:payStatement.Subhauler,
            driverName:payStatement.driverName,
            QBDPSDate: payStatement.QBDPSDate,
            Gross: payStatement.Total.Total,
            Total:{
                Total:payStatement.Total.Total
            },
            DPSNum:payStatement.DPSNum,

        }

        for(let i=0; i<payStatement.Totals.length; i++){
            if(payStatement.Totals[i].Type==='Broker Fees' || payStatement.Totals[i].Type==='Trailer Fees' ){
                newPayStatement.Gross-=payStatement.Totals[i].Total;
            }
        }
      return newPayStatement;
    }
    const runReport = ()=>{
        const dataType = reportType!=='Drivers' ? reportType : 'FreightBills';
        console.log('data type = ', dataType)
        setIsLoading(true);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/${dataType}`;
        console.log('ijnputValues =' ,inputValuesRef.current.Account)
        const dateValue = reportType==='PayStatements' ? "QBDPSDate" : "QueryDate"
        let tableQuery = query( collection(db, queryName), where(dateValue, ">=", inputValuesRef.current.StartDate),  where(dateValue, "<=", inputValuesRef.current.EndDate) );
        if (inputValuesRef.current.Account.ID !== '' && inputValuesRef.current.Account.ID)  tableQuery = query(tableQuery, where("Account.ID", "==", inputValuesRef.current.Account.ID));

        if(tableListenerRef.current)tableListenerRef.current();
        fullReportDataRef.current= [];
        reportDataRef.current =[];
        const generateReportDays = (startDate, endDate) => {
            let reportDays = [];
            const delta = endDate - startDate;
            for (let i = 0; i <= delta / (1000 * 60 * 60 * 24); i++) {
                const day = new Date(startDate.getTime() + i * (1000 * 60 * 60 * 24));
                const formattedDay = formatDate(day, '/', 'MM/DD/YYYY');
                reportDays.push(formattedDay);
            }
            return reportDays;
        };
    
        const reportDays = generateReportDays(inputValuesRef.current.StartDateValue, inputValuesRef.current.EndDateValue);
      
        console.log('WE RUNNING REPORT!!' +inputValuesRef.current.StartDate + ' AND END DATE ' + inputValuesRef.current.EndDate +' oh and reportType = ' +reportType )
        tableListenerRef.current=onSnapshot(tableQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                var tempReportObject = change.doc.data();
                tempReportObject.ID = change.doc.id;
                if (tempReportObject.JobDate) tempReportObject.jobDateValue = new Date(tempReportObject.JobDate);
                if (dataType==='PayStatements') tempReportObject = getGrossForPayStatement(tempReportObject);
                if (change.type === "added") {
                    fullReportDataRef.current.push({...tempReportObject});
                    if(checkObject(tempReportObject)) reportDataRef.current.push({...tempReportObject})
                }
                if (change.type === "modified") {
                    let index = reportDataRef.current.findIndex(data => data.ID === tempReportObject.ID);
                    if(index!==-1) if(checkObject(tempReportObject))reportDataRef.current[index]={...tempReportObject}
                }
                if (change.type === "removed") {
                    let index = reportDataRef.current.findIndex(data => data.ID === tempReportObject.ID);
                    if(index!==-1)reportDataRef.current.splice(index,1)
                }
            })
      
            console.log('rreportTypeRef.current ='+ reportTypeRef.current);
            if( reportTypeRef?.current==='Drivers' ) debouncedSetReportParents([{ header: reportTabType, Name: reportTabType, filteredData: [], reportData:  createDriverReport()}]);
            else if (reportTableRef?.current?.groupByDrivers && reportTableRef?.current?.groupByDrivers !== 'null')  debouncedSetReportParents([...filterByDriver(reportDays)]);
            else   debouncedSetReportParents([{ header: reportTabType, Name:reportTabType, filteredData: [...reportDataRef.current], reportData: [...reportDataRef.current] }]);
            setIsLoading(false);
           
        })
    }
    const debouncedRunReport = useCallback(debounce(runReport, 300), [inputValues, gearedUser]);
    const debouncedSetReportParents = useCallback(debounce((parents) =>     setReportParents([...parents]) , 300), []);

    const editReport = () =>{
        let fields =[];
        console.log('reportTable ', reportTable)
        setReportName(reportTable.Name);
        if(reportType==='FreightBills')  fields=[...freightBillFields];
            if(reportType==='Invoices')  fields=[...invoiceFields];
            if(reportType==='Drivers') fields=[...driverFields];
            for(let f= 0;f<fields.length; f++){
                if(reportTable[fields[f].fieldName]===true){
                    fields[f].selected=true;
                    if(fields[f].reportField)fields[f].value=true;
                    if(fields[f].hasExtra1) fields[f].ExtraSelected1=true;  
                }
                if(reportTable[fields[f].fieldName]===false && fields[f].hasExtra2){
                    fields[f].selected=true;
                    fields[f].ExtraSelected2=true;  
                    if(fields[f].reportField)fields[f].value=false
                }
                for(let c= 0;c<reportTable.Columns.length; c++){
                    if(fields[f].title===reportTable.Columns[c].title)fields[f].selected=true;
                }
            }
            console.log('firelds = ', fields)
        setSelectFields([...fields])
        setShowSelectFields(true);
    }
 
    const printReport = ()=> {

       let reportDoc = {
            content: [],
            pageMargins: [20, 35, 20, 0],
            pageOrientation: 'landscape',
            fontSize: 9,
        
            styles: {
                icon: { font: 'icons' },
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
        
                tableExample: {
                    margin: [0, 5, 0, 5]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                },
                smallText: {
                    margin: [0, 10, 0, 0],
                    fontSize: 9,
                },
                tableFont: {
                    fontSize: 9,
                    alignment: 'center',
                    margin: [-2, 10, -2, 0]
                },
                signatureTableCell: {
                    fontSize: 7,
                    border: [false, false, false, false]
                },
                headerField: {
                    fontSize: 10,
                    margin: [60, 0, 0, 0],
                    bold: true
                },
                pageLetterText: {
                    fontSize: 8,
                    margin: [35, 5, 0, 0]
        
                },
                pageNumberText: {
                    fontSize: 8,
                    margin: [10, 15, 15, 0]
        
                },
        
            }
        };
        let showTotalCols = false;
        let pageHeader={text:reportType, fontSize:13, bold:true, alignment:'center'};
        reportDoc.content.push(pageHeader);
        let datesRow={margin:[0, 10, 0, 0], text:formatDate(inputValues.StartDate,'/','MM/DD/YYYY') + ' - ' +formatDate(inputValues.EndDate,'/','MM/DD/YYYY'),fontSize:10, alignment:'center'};
        reportDoc.content.push(datesRow)
        console.log('real reportTable = ', reportTable)
        for( let i=0; i<reportParents.length; i++){
            let repParent ={...reportParents[i]}
            let dataTable = { width:700, margin: [0, 20, 0, 0],  fontSize: 7, table:{ dontBreakRows: true,keepWithHeaderRows:15, width:700, headerRows: 2, widths: [], alignment: 'center', body: [] } }
            let headerRow = [];
            let driverHeader=[{text:repParent.Name, fontSize:11, bold:true, colSpan:0}];
            let headerCount=0;
            console.log('real reportTablesecond tiem = ', reportTable.Columns)
            for ( let q = 0; q < reportTable.Columns.length; q++) {
                var headerObj = { fontSize: 10, bold: true, text: reportTable.Columns[q].title };
                headerRow.push(headerObj);
                driverHeader[0].colSpan++;
                if(headerCount!=0)driverHeader.push({});
                headerCount++;
                dataTable.table.widths.push('*')
            }
            console.log('headerRow = ', headerRow)
     
            console.log('driverHeader= ',driverHeader)
            dataTable.table.body.push(driverHeader);
            dataTable.table.body.push(headerRow);
            let actualTableTotals={};
            for ( let j = 0; j < repParent.filteredData.length; j++) {
                let reportRow = [];
                for ( let q = 0; q < reportTable.Columns.length; q++) {
                    let reportRowObject = {};
                    
                    console.log('reportTable.Columns[q].= ',reportTable.Columns[q])
                    if (reportTable.Columns[q].Type === 'number') {
                        if(!actualTableTotals[reportTable.Columns[q].field])actualTableTotals[reportTable.Columns[q].field]=0;
                        actualTableTotals[reportTable.Columns[q].field]+=Number(repParent.filteredData[j][reportTable.Columns[q].field]);
                        if (reportTable.Columns[q].title!=='Hours') reportRowObject.text = '$' +formatMoney(repParent.filteredData[j][reportTable.Columns[q].field], 2);
                        else reportRowObject.text = formatMoney(repParent.filteredData[j][reportTable.Columns[q].field], 1);
                        reportRowObject.alignment = 'right';
                    }else  if (reportTable.Columns[q].field === 'jobDateValue') reportRowObject.text = repParent.filteredData[j].JobDate;
                    else reportRowObject.text = repParent.filteredData[j][reportTable.Columns[q].field];
                    reportRow.push(reportRowObject);

                }
                dataTable.table.body.push(reportRow);
            }

            let totalRow = [];
           
            console.log(' printCols = ' , reportTable.Columns);
            console.log('  actualTableTotals = ' , actualTableTotals);
            for ( let q = 0; q < reportTable.Columns.length; q++) {
                if (reportTable.Columns[q].showTotal) {
                    showTotalCols = true;
                    if (!actualTableTotals[reportTable.Columns[q].field]) totalRow.push({ bold: true, alignment: 'right', text: '$0.00' });
                    else if (reportTable.Columns[q].title!=='Hours')totalRow.push({ bold: true, alignment: 'right', text: '$' +  formatMoney( actualTableTotals[reportTable.Columns[q].field],2) })
                    else totalRow.push({ bold: true, alignment: 'right', text:   formatMoney( actualTableTotals[reportTable.Columns[q].field],1) })

                } else totalRow.push('');
            }
            if (showTotalCols) dataTable.table.body.push(totalRow);
            console.log('rdataTable = ', dataTable)
            reportDoc.content.push(dataTable);
        }
        var totalTable = { margin: [300, 20, 0, 0], dontBreakRows: true, fontSize: 7, table: {keepWithHeaderRows:99, headerRows: 1, widths: ['*','*'], alignment: 'center', body: [] } }
        var totalRow=[{text:'Type', bold:true, alignment:'center'}, {text:'Total', bold:true, alignment:'center'}];
        totalTable.table.body.push(totalRow);
        for (let t = 0; t < grandTotals.length;t++) {
            let totalRow=[];
            showTotalCols = true;
            totalRow.push({text:grandTotals[t].Type, bold:true, alignment:'center'})
            if(grandTotals[t].Type!=='Hours') totalRow.push({ bold: true, alignment: 'right', text: '$' +formatMoney(grandTotals[t].Total, 2)})
            else  totalRow.push({ bold: true, alignment: 'right', text: formatMoney(grandTotals[t].Total, 1)})
             totalTable.table.body.push(totalRow);
        }
        if (showTotalCols)  reportDoc.content.push(totalTable);

        console.log('reportDoc = ',reportDoc);
        pdfMake.createPdf(reportDoc).download('Report.pdf')
    }
    const openFreightBill = (freight)=>{
        console.log('freight = ', freight);
        var win = window.open('/freightbill/freightbill/'+freight.ID+'/'+freight.dispatchID, '_blank');
        win.focus();
     
    }
    const openInvoice= (invoiceID)=>{
        var win = window.open('/invoice/invoice/'+invoiceID, '_blank');
        win.focus();
    }

    const openPayStatement= (DPSID)=>{
        var win = window.open('/paystatement/paystatement/'+DPSID, '_blank');
        win.focus();
     
    }
    const totalBodyTemplate=(rowData)=>{
       // console.log('Number(rowData.Total) = '  +Number(rowData.Total));
     //   console.log("Number(rowData.Total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })" + Number(rowData.Total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
        if(rowData.Type==='Hours')return(`${Number(rowData.Total).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}`)
        else if(rowData.Type==='Weight') return(`${Number(rowData.Total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`)
        else  return(`$${Number(rowData.Total).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`)
    }
    const saveReport = ()=>{
      
        let tempReportTable = {...reportTable}
        tempReportTable.ReportDateValue= inputValues.ReportDateValue;
        tempReportTable.EndDateValue=inputValues.EndDateValue;
        tempReportTable.StartDateValue=inputValues.StartDateValue;
        tempReportTable.Account=inputValues.Account;
        tempReportTable.Name = inputValues.reportName;
        tempReportTable.ReportDate=inputValues.ReportDate;
        tempReportTable.EndDate=inputValues.EndDate;
        tempReportTable.StartDate=inputValues.StartDate;
        tempReportTable.reportName=inputValues.reportName;
        addDocument(tempReportTable, 'Reports');
        setSaveReportPopUpVisible(false);
        navigate('/report/reportHome/list/'+reportType)
        console.log('tempREportTable = ', tempReportTable)
    }
    
    const DateInputGroup = ({ value, label, handleChange }) => (
        <div className="p-inputgroup " style={{ padding: ".2em" }}>
            <span className="p-inputgroup-addon" style={{flexBasis:'40%', height:'40px'}}>{label}</span>
            <Calendar value={value} style={{ width: "100%" , height:'40px'}} onChange={handleChange} />
        </div>
    )
 
    const AutoCompleteMemo = React.memo(({ label, field, value, suggestions, handleFieldChange }) => {
        console.log('label = ' + label)
        return (
           <div   style={{ padding:"0"}}>
                <AutoCompleteInputParent
                    label={label}
                    fieldName={label}
                    field={field}
                    value={value}
                    setValue={setInputValues}
                    labelClass='p-inputgroup-addon comp-auto-input'
                    showLabel={false}
                    suggestions={suggestions}
                    handleFieldChange={handleFieldChange}
                />
            </div>)
         
    });
    const closeSaveReportPopUp = () => {
        setSaveReportPopUpVisible(false);
        
    }
    const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() =>   setSaveReportPopUpVisible(false)} />
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Save" icon="pi pi-check"  onClick={() => saveReport(false)} />
     
        </div>
    
    );

    return (
        <div >
            
            {!showSelectFields ? (<div className="mbsc-grid mbsc-justify-content-center" style={{height:'100%', paddingLeft:".25rem", paddingRight:".25rem"}}>
     
                <div className='mbsc-row' style={{padding:'0', paddingTop:".25em",  height:'100%', width:'100%'}}>
                    <div className="mbsc-col-xl-2 mbsc-col-lg-3 mbsc-col-md-6" style={{padding:'0'}}>
                        <DateInputGroup value={inputValues.StartDateValue}  label="Start Date:"  handleChange={(e) => handleDateChange('StartDate', e.value)}    />
                        <DateInputGroup value={inputValues.EndDateValue}  label="End Date:" handleChange={(e) => handleDateChange('EndDate', e.value)}    />
                    </div>
                    <div className="mbsc-col-xl-2 mbsc-col-lg-3 mbsc-col-md-6" style={{padding:'0', paddingTop:".2em"}}>
                        <AutoCompleteMemo   label="Company"  field="CompanyName" value={inputValues.Company} setValue suggestions={companies} handleFieldChange={changeReportCompany} />
                        {reportType!=='Drivers' && reportType!=='PayStatements' &&(<AutoCompleteMemo label="Account" field="Name" value={inputValues.Account} suggestions={accounts}  handleFieldChange={changeReportAccount} />)} 
                    </div>
                               
                        <button style={{ margin: '0', padding: '.5em', marginLeft:'1em',  marginTop:'.5em', maxWidth:'140px' }}  onClick={debouncedRunReport} className="mbsc-ios mbsc-btn-primary mbsc-btn mbsc-col-xl-1 mbsc-col-lg-2 mbsc-col-4" > Run Report  </button>
                        {listType!=='list' && (<button style={{ margin: '0', padding: '.5em',marginLeft:'1em',   marginTop:'.5em', maxWidth:'140px' }}  onClick={(e) =>editReport()} className="mbsc-ios mbsc-btn-primary mbsc-btn mbsc-col-xl-1 mbsc-col-lg-2 mbsc-col-4"> Edit Report  </button>)}
                        {listType!=='list' && (<button style={{ margin: '0', padding: '.5em', marginLeft:'1em', marginTop:'.5em', maxWidth:'140px' }}  onClick={(e) =>setSaveReportPopUpVisible(true)}  className="mbsc-ios mbsc-btn-primary mbsc-btn mbsc-col-xl-1 mbsc-col-lg-2 mbsc-col-4" >Save Report  </button>)}
                        <button style={{ margin: '0', padding: '.5em', marginLeft:'1em',  marginTop:'.5em', maxWidth:'140px' }}  onClick={(e) =>returnHome()} className="mbsc-ios mbsc-btn-primary mbsc-btn mbsc-col-xl-1 mbsc-col-lg-2 mbsc-col-4"> Report Home  </button>
                        <button style={{ margin: '0', padding: '.5em', marginLeft:'1em', marginTop:'.5em', maxWidth:'140px' }}  onClick={(e) =>printReport()}  className="mbsc-ios mbsc-btn-primary mbsc-btn mbsc-col-xl-1 mbsc-col-lg-2 mbsc-col-4" >Print Report  </button>
                 
                </div>
                {sortedReportParents.map(( repParent, parentIndex ) => (
					<div key={parentIndex}>
                    	{repParent.reportData.filter(item => (inputValues.Account.ID === '' || item.AccountID === inputValues.Account.ID)).length>0 && ( 
						<>
						{reportType==='Invoices' ? (
							<InvoiceTable 
								reportTable={reportTable} 
								repParent={repParent} 
								setReportParents={setReportParents} 
								parentIndex={parentIndex}  
								reportParents={reportParents} 
								openPayStatement={openPayStatement}
								openInvoice={ openInvoice} 
								openFreightBill={ openFreightBill} 
								setReportTotals={setReportTotals} 
								inputValues={inputValues} 
								formatDate={formatDate}
							/>
						):(
							<ReportTable 
								reportTable={reportTable} 
								repParent={repParent} 
								setReportParents={setReportParents} 
								parentIndex={parentIndex}  
								reportParents={reportParents} 
								openPayStatement={openPayStatement}
								openInvoice={ openInvoice} 
								openFreightBill={ openFreightBill} 
								setReportTotals={setReportTotals} 
								inputValues={inputValues} 
								formatDate={formatDate}
							/>
						)}
                
					</>)}
				</div>))}
                <div className='mbsc-row'>
                    {reportTable?.totals?.length>0 &&( 
                        <DataTable className='mbsc-offset-xl-8 mbsc-col-xl-4 mbsc-offset-md-6 mbsc-col-md-6' value={grandTotals}  pt={{header: {style: {padding: '.5em'}}}} header='Totals' style={{ padding: '.25em', width: '100%' }}    >                     
                            <Column pt={{root: { 'data-label': 'Type' }}} field='Type' header='Type' /> 
                            <Column pt={{root: { 'data-label': 'Total' }}}field='Total' header='Total' body={totalBodyTemplate} style={{textAlign:'right'}} /> 
                    </DataTable>)}
                </div> 
          
            </div>):(<CustomizeTable tableType={'report'} reportTable={reportTable} reportType={reportType} ></CustomizeTable>)} 
            
            <Dialog header="Save Report" visible={saveReportPopUpVisible} style={{ width: '40vw' }} breakpoints={{ '1400px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeSaveReportPopUp}>
                <div style={{  paddingLeft:"5em", paddingRight:"5em", marginTop:'2em', marginBottom:'2em'}} className='mbsc-grid'>
                    <div className="p-inputgroup mbsc-col-xl-8 mbsc-offset-xl-2"  >
                        <span className="p-inputgroup-addon dispatch-inputgroup" style={{flexBasis:'40%'}} > Name  </span> 
                        <InputTextParent value={inputValues.reportName} style={{width:"100%"}} onChange={(e) => setInputValues((prev) => ({ ...prev, reportName: e.target.value }))} />
                    </div>
                </div>    
            </Dialog>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
}
export default ReportBuilder;

