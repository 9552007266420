import React, { useState, useCallback, useEffect, useRef } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from '@mobiscroll/react';
import { UserAuth } from '../../context/AuthContext'


const AutoCompleteInput = ({ fieldName, field, label, value, suggestions, handleFieldChange,  setValue, disabled = false,  showLabel = true, databaseList, editClick , labelClass, defaultNameType, hideAddCustom = false}) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
   
    const { addNewAccount, addNewMaterial, addNewTruckType, addNewTrailer, addNewTruck, autocompleteServiceRef, checkGoogleMapsAPI, addNewLocation, addNewGoogleLocation, addNewDefaultName } = UserAuth();
    const autoCompleteRef = useRef(null);
    const realLabel = label ? label: fieldName; 
   // console.log('fieldName = ', fieldName)
    const realSuggestions = fieldName!=='Company' &&  
	databaseList!=='LineItemNames' && 
	fieldName!=='LoadSite' && 
	fieldName!=='DumpSite' &&
	fieldName!=='PayTo' &&
	fieldName!=='BillTo' ?
	 [{Name:'No '+fieldName, ID:''},...suggestions] 
	 : 
	 fieldName==='LoadSite' || fieldName==='DumpSite' && databaseList!=='LineItemNames' ? 
	  [{Name:'No Location', ID:''},...suggestions] : [...suggestions];
 
 

    useEffect(() => {
        checkGoogleMapsAPI();
        if (autoCompleteRef.current) {
            const inputElement = autoCompleteRef.current.getInput();
            if (inputElement) {
                inputElement.autocomplete = "new-password"; // Change the name attribute
            }
        }
    }, []);

    const doesItemMatchQuery = (item, query) => {
        return Object.values(item).some(value => 
          String(value).toLowerCase().includes(query.toLowerCase())
        );
    };

    const filterList = (event, list) => {   
        return list.filter(item => doesItemMatchQuery(item, event.query));
    };

    const handleClear = () => {
        // Show all suggestions when the input is cleared
        setFilteredSuggestions([...realSuggestions]);
        setHighlightedIndex(realSuggestions.length > 0 ? 0 : -1);
        if (autoCompleteRef.current) {
            autoCompleteRef.current.show(); // Manually trigger the suggestion panel
        }
    };
  
    const handleFilter = useCallback((event) => {
        let filtered = [];
        let customName = 'Add Custom ' + fieldName;
        console.log('runnign the filter')
        if (event.query.length === 0)  filtered = realSuggestions; else filtered = filterList(event, realSuggestions); 
        if (databaseList === 'Locations'){
            if(autocompleteServiceRef.current && event.query.length > 0) {
                autocompleteServiceRef.current.getPlacePredictions(
                    { input: event.query },
                    (predictions, status) => {
               
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            const suggestionList = predictions.map((prediction) => ({
                                Name: prediction.description,
                                place_id: prediction.place_id,
                                Address: (prediction.terms[0] ? prediction.terms[0].value : '') + ' ' + (prediction.terms[1] ? prediction.terms[1].value : ''),
                                City: prediction.terms[2] ? prediction.terms[2].value : '',
                                State: prediction.terms[3] ? prediction.terms[3].value : '',
                                googleMapsLoc: true
                            }));
                            filtered.push(...suggestionList);

                        }
                        
                        filtered.push({ ID: customName, value: customName, Name:'Add Custom Location'});
                        setFilteredSuggestions(filtered);
     
                        setHighlightedIndex(filtered.length > 0 ? 0 : -1);
                    }
                );
            }
          
          
        } else{
            if(field!=='CompanyName' && !hideAddCustom) filtered.push({ ID: customName, value: customName, Name: customName });
            setFilteredSuggestions(filtered);
            setHighlightedIndex(filtered.length > 0 ? 0 : -1);
        } 
           
        
        console.log('filtered after he run ',filtered)
       
    }, [filterList, realSuggestions]);

    const handleFocus = (event) => {
        event.target.select(); 
      
    };

    const deepEqual = (obj1, obj2) => {
        if (obj1 === obj2) return true;
        if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) return false;
        
        let keys1 = Object.keys(obj1);
        let keys2 = Object.keys(obj2);
        
        if (keys1.length !== keys2.length) return false;
        
        for (let key of keys1) {
            if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
        }
        
        return true;
    };
    const addCustomObject = ()=>{
        if(databaseList==='Accounts') addNewAccount(value, fieldName, handleFieldChange);
        if(databaseList==='Materials') addNewMaterial(value, handleFieldChange);
        if(databaseList==='TruckTypes') addNewTruckType(value, handleFieldChange);
        if(databaseList==='Trucks') addNewTruck(value, handleFieldChange);
        if(databaseList==='Trailers') addNewTrailer(value, handleFieldChange);
        if(databaseList==='DefaultNames') addNewDefaultName(value,handleFieldChange,defaultNameType);
        if(databaseList==='LineItemNames') addNewDefaultName(value.Name,handleFieldChange,defaultNameType);
    }
    const createCompanySummary = (selectValue)=>{
        return {
          
            ID: selectValue.ID,
            CompanyID: selectValue.CompanyID,
            CompanyName: selectValue.CompanyName,
            CalcRoundTrip: selectValue.CalcRoundTrip ? selectValue.CalcRoundTrip : "Last Load",
            CalcRunningTime: selectValue.CalcRunningTime ? selectValue.CalcRunningTime : "Last Load",
            Name:selectValue.Name,
            payByJobHours :  selectValue.payByJobHours ?  selectValue.payByJobHours : false,
            Address: selectValue.Address,
            address2: selectValue.address2,
            CompanyPhone: selectValue.CompanyPhone,
            Fax: selectValue.Fax,
            realmID: selectValue.realmID
     
        }
    }
    const createAccountSummary = (selectValue)=>{
        return {
            ID:selectValue.ID,
            Name:selectValue.Name, 
            Address:selectValue.Address ? selectValue.Address : '',
            City:selectValue.City ? selectValue.City : '',
            State:selectValue.State ? selectValue.State : '',
            ZipCode:selectValue.ZipCode ? selectValue.ZipCode : '',
            Broker:selectValue.Broker ? selectValue.Broker : false,
            BrokerFee:selectValue.BrokerFee ? selectValue.BrokerFee : 0,
           // Quickbooks:selectValue.Quickbooks ? selectValue.Quickbooks : [],
            DriverEmail:selectValue.DriverEmail ? selectValue.DriverEmail : ''
        }
    }
    const createLocationSummary = (selectValue)=>{
        return {
            ID:selectValue.ID,
            Name:selectValue.Name,
            Address:selectValue.Address ? selectValue.Address : '',
            Address2:selectValue.Address2 ? selectValue.Address2 : '',
            City:selectValue.City ? selectValue.City : '',
            State:selectValue.State ? selectValue.State : '',
            ZipCode:selectValue.ZipCode ? selectValue.ZipCode : '',
            fullAddress:selectValue.fullAddress ? selectValue.fullAddress : '',
            googleMapsLoc:selectValue.googleMapsLoc ? selectValue.googleMapsLoc : false
        }
    }
    const selectItem = ( selectValue)=>{
        console.log('doing select value =', selectValue)
        console.log('databaseList ='+ databaseList)
        if(databaseList==='Locations' ){
           if(selectValue.googleMapsLoc) addNewGoogleLocation(fieldName , selectValue, handleFieldChange);
           else  if(selectValue.ID==='Add Custom ' + fieldName)addNewLocation(fieldName,value,  handleFieldChange);
           else  handleFieldChange(fieldName, createLocationSummary(selectValue), true)
        }else if(selectValue.ID==='Add Custom ' + fieldName)addCustomObject(selectValue);
        else if(databaseList==='Accounts' && fieldName!=='PayTo')handleFieldChange(fieldName, createAccountSummary(selectValue), true)
        else if(field==='CompanyName' )handleFieldChange(fieldName, createCompanySummary(selectValue) , true)
        else  handleFieldChange(fieldName, selectValue, true)
    }
    const handleKeyDown = (event) => {
      
        if (event.key === 'ArrowDown') {
            setHighlightedIndex(prevIndex => (prevIndex + 1) % filteredSuggestions.length);
            event.preventDefault();
        } else if (event.key === 'ArrowUp') {
            setHighlightedIndex(prevIndex => (prevIndex - 1 +filteredSuggestions.length) % filteredSuggestions.length);
            event.preventDefault();
        } else if (event.key === 'Enter' && highlightedIndex !== -1) {
            console.log('about to set value = ', event)
           selectItem( filteredSuggestions[highlightedIndex])
            if(autoCompleteRef.current)autoCompleteRef.current.hide();
            focusNextElement(event.target);
            event.preventDefault();
        }else if (event.key === 'Enter' && highlightedIndex === -1) {
            focusNextElement(event.target);
            event.preventDefault();
        }
    };

    const focusNextElement = (currentElement) => {
        const focusableElements = 'input:not([disabled]), select:not([disabled]), textarea:not([disabled])';
        const elements = Array.from(document.querySelectorAll(focusableElements));
        console.log(' focusableElements  = ',  elements )
        const currentIndex = elements.indexOf(currentElement);
        if (currentIndex > -1 && currentIndex < elements.length - 1) {
            elements[currentIndex + 1].focus();
        }
    };

    const passThroughOptions = {

        item: {
            className: 'custom-item-class',
            'aria-selected': false,
            role: 'option'
        }
    };


    const itemTemplate = (suggestion, index) => {
        const isHighlighted = index === highlightedIndex;
        let fontWeight = 'normal';
        if(suggestion.ID === 'Add Custom ' + fieldName || suggestion.locSelect) fontWeight='bold'
       
        return (
            <div className={`p-autocomplete-item ${isHighlighted ? 'p-highlight' : ''}`} style={{ padding: '10px', cursor: 'pointer'}} role="option"  aria-selected={isHighlighted} >
                <div style={{ fontWeight:fontWeight }}>  {suggestion[field]}</div>
                {suggestion.locSelect && (<div>{suggestion.fullAddress} </div>)}
            </div>

        );
    };




    return (
       <div className="p-inputgroup ">
        {!labelClass && showLabel && ( <span className="p-inputgroup-addon" >{realLabel}</span> )} 
       {labelClass && ( <span className={labelClass} >{realLabel}</span> )}
        <AutoComplete
            ref={autoCompleteRef}
            field={field}
            value={value}
            suggestions={filteredSuggestions}
            completeMethod={handleFilter}
            onClear={(e) => handleClear(e)}
            onChange={(e) => { setValue((prev) => ({ ...prev, [fieldName]: e.value })); }}
            onSelect={(e) => selectItem(e.value)}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            minLength={0}  // Allow empty input to show suggestions
            itemTemplate={itemTemplate}
            autoHighlight={true}
            disabled={disabled}
            pt={passThroughOptions}
            delay={10}
        />
             {editClick && value?.ID && value?.ID!=='default' && (<Button startIcon="tag" type="button" style={{ color: "blue", margin: "0" }} onClick={editClick} ></Button>)}
        </div>
    );
};

export default AutoCompleteInput;