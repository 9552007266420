
import React, {useState, useEffect, useRef} from 'react'

import { useDispatch } from '../context/DispatchContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../../context/AuthContext';
import { Button} from '@mobiscroll/react';

import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import LoadingOverlay from '../../MiscComponents/LoadingOverlay';
import { db } from '../../../firebase';
import { query,  collection,onSnapshot, where } from 'firebase/firestore';


import {useParams } from 'react-router-dom';
const DispatchSearch = () => {
    const { dispatchObjectRef, setDispatch, setDispatchState,openDispatchDatePopUp, jobDispatchesRef,queryFreightBills,setHomeDate} = useDispatch();
    const {companies,accounts, company, locations, formatDate, gearedUser} = UserAuth();
    const [searchDispatches, setSearchDispatches] = useState([]);
    const [searchDisabled, setSearchDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const unsubscribeSearchDispatchesRef = useRef(null);
    const searchDispatchesRef = useRef(null);
    const jobDispatchListenerRef = useRef(null); // Store the unsubscribe function
    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);

    const [inputValues, setInputValues]= useState({
        Account:{ID:'', Name:'No Account' }, 
        Location:{ID:'', Name:'No Location'},
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD')
    });

    const { objectType } = useParams();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
		realLatestDispatchDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        'TruckType.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
        BillType:{ value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    console.log('searchDispatches = ', searchDispatches)
   let sortedDispatches = [...searchDispatches].sort((a, b) => {
        if (a.QueryDate > b.QueryDate) return -1;
        if (a.QueryDate < b.QueryDate) return 1;
        return 0;
    });

   
    if (inputValues.Company)  sortedDispatches = sortedDispatches.filter(dispatch => dispatch.Company && dispatch.Company.ID === inputValues.Company.ID);
    if (inputValues.Account?.ID)  sortedDispatches = sortedDispatches.filter(dispatch => dispatch.Account && dispatch.Account?.ID === inputValues.Account?.ID);
    if (inputValues.Location?.ID)  sortedDispatches = sortedDispatches.filter(dispatch => (dispatch.LoadSite || dispatch.DumpSite) && (dispatch.LoadSite?.ID === inputValues.Location?.ID || dispatch.DumpSite?.ID === inputValues.Location?.ID))  ;
    if (objectType==='Prelims')sortedDispatches = sortedDispatches.filter(dispatch =>dispatch.TrackPrelim ===true);
  
	console.log('sortedDispatches = ', sortedDispatches);
    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);
    useEffect(()=>{setDispatchState('DispatchSearch')},[])

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const dateBodyTemplate = (rowData, fieldName) => {
        return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
    };  

    const buttonBodyTemplate = (rowData, buttonText) => {
        let tempFunction;
        if(!buttonText){
            tempFunction = () => openDispatch(rowData);
            buttonText = rowData.JobNumber  ? rowData.JobNumber : 'Open';
        }
        else {
            tempFunction = () => {
                setDispatch(rowData)
                dispatchObjectRef.current=rowData;
                queryJobDispatches(rowData.Job.ID)
                setHomeDate(rowData.QueryDate);
                queryFreightBills(rowData.QueryDate, rowData.QueryDate)
                openDispatchDatePopUp('Copy'); 
            }
        }
        return <Button color="primary"  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}} onClick={(e) => tempFunction()}>
          <span>{buttonText}</span></Button>;
    };

    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
      
    };
    const changeAccount= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    };  
    const changeLocation= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
    
    };  
    const queryObjects = ()=>{
        querySearchDispatches(inputValues, objectType)
    }

    const openDispatch =(rowData) => {
        const encodedDate = encodeURIComponent(rowData.QueryDate); 
        console.log('RUNNIGN DISPATCH SEARCH OPEN DISPATCH')
		let win;
        if(objectType==='Dispatches'){
            setDispatch({...rowData});
            dispatchObjectRef.current = {...rowData};
			win = window.open(`/dispatch/edit/${rowData.ID}/${rowData.JobID}/${encodedDate}/DispatchEdit`, '_blank');
        }else {
			setDispatchState('JobEdit');
			win = window.open(`/dispatch/edit/none/${rowData.ID}/${encodedDate}/JobEdit`, '_blank'); 
		}	
		win.focus();
    }

    const queryJobDispatches = (jobID)=>{
   
        if (jobDispatchListenerRef.current) jobDispatchListenerRef.current();
        jobDispatchesRef.current=[];
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
        const q = query(collection(db, queryName), where("Job.ID", "==", jobID));

        jobDispatchListenerRef.current= onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempDispatch = change.doc.data();
                if (change.type === "added") {
                    tempDispatch.ID = change.doc.id;
                    jobDispatchesRef.current.push(tempDispatch); 
                }
                if (change.type === "modified") {
                    const dispatchIndex = jobDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    jobDispatchesRef.current[dispatchIndex] = tempDispatch;
                }
                if (change.type === "removed") {
                    const dispatchIndex = jobDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                    jobDispatchesRef.current.splice(dispatchIndex,1);
                }
            });
        });
    }
   
    const querySearchDispatches = (object, objectType) => {
        if(objectType==='Prelims')objectType='Jobs'
        searchDispatchesRef.current = [];

        if (unsubscribeSearchDispatchesRef.current) unsubscribeSearchDispatchesRef.current();
        setIsLoading(true);
        let dispQuery  = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/${objectType}`));
		dispQuery =  objectType==='Dispatches' ? query(dispQuery, where("QueryDate", ">=", object.StartDate), where("QueryDate", "<=", object.EndDate)) :   query(dispQuery, where("latestDispatchDate", ">=", object.StartDate), where("latestDispatchDate", "<=", object.EndDate)) 
        if (object.Account.ID) dispQuery = query(dispQuery, where("Account.ID", "==", object.Account.ID));
        console.log('object = ', objectType);
        let unsubscribeDumpSite, unsubscribeLoadSite;


       	const handleDispatchChange = (change) => {
           	const tempDispatch = change.doc.data();
      
           	tempDispatch.ID = change.doc.id;
           	tempDispatch.Description = tempDispatch.LoadSite?.Name 
               ? (tempDispatch.LoadSite?.Name + ' - ' + (tempDispatch.DumpSite?.Name ? tempDispatch.DumpSite?.Name : 'No Dump Site')) 
               : ('No Load Site' + ' - ' + (tempDispatch.DumpSite?.Name ? tempDispatch.DumpSite?.Name : 'No Dump Site'));
           	tempDispatch.realJobDate=new Date(  tempDispatch.JobDate);

    		if(objectType==='Jobs') tempDispatch.realLatestDispatchDate=new Date(  tempDispatch.latestDispatchDate);

           	tempDispatch.TrucksUnassigned = tempDispatch.TrucksOrdered 
			? tempDispatch.TrucksOrdered - (tempDispatch.TrucksAssigned ? tempDispatch.TrucksAssigned : 0) 
			: 0 -(tempDispatch.TrucksAssigned ? tempDispatch.TrucksAssigned : 0);

           	if (change.type === "added") {
				console.log('found a dispatch' , tempDispatch)
               	const dispatchIndex = searchDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
			   	console.log('dispatchIndex = ', dispatchIndex)
               	if (dispatchIndex === -1) searchDispatchesRef.current.push(tempDispatch);
           	}
           	if (change.type === "modified") {
               	const dispatchIndex = searchDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
               	if (dispatchIndex !== -1) searchDispatchesRef.current[dispatchIndex] = tempDispatch;
           	}
           	if (change.type === "removed") {
               	const dispatchIndex = searchDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
               	if (dispatchIndex !== -1) searchDispatchesRef.current.splice(dispatchIndex, 1);
           	}	
   
       	};
   
        const processQuerySnapshot = (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => handleDispatchChange(change));
            console.log('setting full home searchDispatchesRef.currents = ', searchDispatchesRef.current);
            setSearchDispatches([...searchDispatchesRef.current]);
      
            setIsLoading(false);
         
        };

       	if (object.Location.ID) {
           const dumpSiteQuery = query(dispQuery, where("DumpSite.ID", "==", object.Location.ID));
           unsubscribeDumpSite = onSnapshot(dumpSiteQuery, processQuerySnapshot);
   
           const loadSiteQuery = query(dispQuery, where("LoadSite.ID", "==", object.Location.ID));
           unsubscribeLoadSite = onSnapshot(loadSiteQuery, processQuerySnapshot);
   
           unsubscribeSearchDispatchesRef.current = () => {
               unsubscribeDumpSite();
               unsubscribeLoadSite();
           };

		} else unsubscribeSearchDispatchesRef.current = onSnapshot(dispQuery, processQuerySnapshot);
		
   	}
	

	const jobDateHeader = objectType==='Jobs' ? 'Start Date' : 'Job Date';
    return(
        <div className="card mbsc-grid " >
            <div className=" mbsc-row" >
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon search-auto-input'  suggestions={companies} setValue={setInputValues} handleFieldChange={changeCompany} />
                    <AutoCompleteInput label="Customer" fieldName="Account" field="Name" value={inputValues.Account} labelClass='p-inputgroup-addon search-auto-input'  disabled={searchDisabled} databaseList={'Accounts'} suggestions={accounts} setValue={setInputValues} handleFieldChange={changeAccount} />
                    <AutoCompleteInput label="Load/Dump Site" fieldName="Location" field="Name" value={inputValues.Location} labelClass='p-inputgroup-addon search-auto-input' disabled={searchDisabled} databaseList={'Locations'} suggestions={locations} setValue={setInputValues} handleFieldChange={changeLocation} />
                </div>
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                        <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                    </div> 
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                        <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                    </div>
                    <div className="flex justify-content-end">
                        <Button  color="primary" disabled={searchDisabled} onClick={(e) => queryObjects()} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Search {objectType}</Button> 
                        
                        {/* <Button  color="primary" onClick={(e) =>resetSearch() } style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Search Fields</Button> 
                       {searchedObject && (<Button  color="primary" onClick={(e) => setInputValues({...searchedObject})} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Filters</Button> )}*/}

                    </div>
                </div>
            </div>
            <div className=" mbsc-row" >
                <DataTable value={sortedDispatches} paginator rows={25} dataKey="ID" filters={filters} header={objectType} filterDisplay="row" emptyMessage="No dispatches found.">
                    <Column pt={{root: { 'data-label': 'Job #' }}}  header="Job #"  body={(rowData) =>buttonBodyTemplate(rowData)}  />
                    <Column pt={{root: { 'data-label': 'Job Date' }}}   field="realJobDate" header={jobDateHeader} dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData,'realJobDate')}   filterPlaceholder="Search by Date" />
					{objectType==='Jobs' && (<Column pt={{root: { 'data-label': 'Latest Dispatch' }}}   field="realLatestDispatchDate" header="Latest Dispatch" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData,'realLatestDispatchDate')}   filterPlaceholder="Search by Date" />)}

					{/*{objectType==='Dispatches' && ( <Column  header="New Job" body={(rowData) =>buttonBodyTemplate(rowData, 'New Job')}   />)}*/}
                    {objectType==='Dispatches' && ( <Column pt={{root: { 'data-label': 'Add' }}}   header="Add Dispatch"  body={(rowData) =>buttonBodyTemplate(rowData, 'Add Dispatch')}   />)}
                    <Column header="Customer" pt={{root: { 'data-label': 'Customer' }}}  filter filterField="Account.Name" filterPlaceholder="Search by Customer"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>
                    {objectType==='Dispatches' && ( <Column pt={{root: { 'data-label': 'Truck Type' }}}  header="Truck Type" filter filterField="TruckType.Name" filterPlaceholder="Search by Truck Type"  body={(rowData) => rowData.TruckType?.Name || 'N/A'}/>)}
                    <Column pt={{root: { 'data-label': 'Description' }}}  header="Description" filter filterField="Description" filterPlaceholder="Search by Load Site"  body={(rowData) => rowData.Description || 'N/A'}/>
                    {objectType==='Dispatches' && (  <Column pt={{root: { 'data-label': 'Bill Type' }}}   header="Bill Type" filter  field="BillType" filterField="BillType" filterPlaceholder="Search by Bill Type"  />)}
                    {objectType==='Dispatches' && (  <Column pt={{root: { 'data-label': 'Ordered' }}}  header="Ordered" filter  field="TrucksOrdered" sortable />)}
                    {objectType==='Dispatches' && (  <Column pt={{root: { 'data-label': 'Assigned' }}} header="Assigned" filter  field="TrucksAssigned" sortable  />)}
                </DataTable>
            </div>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    )
}


export default DispatchSearch;
