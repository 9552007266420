import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faRepeat, faCar, faLocationDot, faTrailer} from '@fortawesome/free-solid-svg-icons';  

import FreightStatusSpan from './FreightStatusSpan';


const FreightStatusCard = ({ freight, openMobileFreight, getDistance }) => {
    
    const backgroundColor= freight.Received && !freight.dSubmitted  ? '#c5ddba': freight.dSubmitted ? '#62a8f5' : freight.textSent ? '#fcf1d0' : 'white';
     
    if(freight.Status==='Clocked In' || freight.Status==='Arrived Dump' || freight.Status==='Departed Dump')freight.distanceType = 'Load';
    else if(freight.Status==='Arrived Load' ||  freight.Status==='Departed Load' ||  freight.Status==='Signed Out Load' )freight.distanceType = 'Dump';
    else if(freight.Status==='Submitted' ||  freight.Status==='Signed Out Dump' )freight.distanceType = 'Yard';
    else freight.distanceType = 'Yard';

    return (
        <div className="mbsc-col-12 mbsc-col-md-4 " style={{ paddingTop: '0',paddingLeft: '.5em', paddingRight:'0', marginBottom: '.5em ' }} >
            <Card  style={{ backgroundColor:backgroundColor, paddingLeft: '.5em',paddingRight:'.5em', color:'black' }}>
                <div className="mbsc-row " style={{  margin:'0', }}>
                    <div className='mbsc-col-7' style={{ fontWeight: 'bold', fontSize:'1.1em', padding:'0' }}>{freight.driverName}</div>
                    <div className='mbsc-row mbsc-col-5' style={{ margin:"0", padding:'0', textAlign: 'right' }}>
                        <span className='mbsc-col' style={{padding:"0", paddingTop:".3em"}}>{freight.StatusTime}</span>
                        <Button className='mbsc-col-md-6 mbsc-col'  label={freight.Status}  style={{height:'.6em', width:'75%', paddingBottom:'1em',paddingTop:'1em',  paddingLeft:'.1em', paddingRight:'.1em', fontSize:'.8em', marginLeft:'.5em'}}      onClick={() => openMobileFreight(freight)}   /> 
                    </div>
                </div>
                <div className="mbsc-row mbsc-justify-content-between" style={{paddingTop:'1em'}}>
                <div>
                    <FontAwesomeIcon className="fas" icon={faTruck} style={{  width:"1em", paddingRight:".2em"}}/> {freight.Truck?.Name}{' '}
                    <FontAwesomeIcon className="fas" icon={faTrailer} style={{  width:"1em", paddingLeft:'.5em', paddingRight:".2em"}}/> 
                    {freight.Trailer?.Name && freight.Trailer?.Name !== 'No Trailer' && ( <span>  {freight.Trailer?.Name}  </span> )}
                </div>
                <div>Load: <FreightStatusSpan freight={freight} fieldName={'loads'} driverFieldName ={'driverLoads'} /></div>
                </div>
            
                <div className="mbsc-row" style={{paddingTop:'1em'}}>{freight.Material?.Name}</div>
                <div className="mbsc-row mbsc-justify-content-start" style={{paddingTop:'1em'}}>
                <div className="mytooltip" tooltip="Avg Load Time">
                    <i className="mbsc-ic mbsc-ic-arrow-up2"></i> <FreightStatusSpan freight={freight} fieldName={'AverageLoadTime'} /> 
                </div>
                <div className="mytooltip mbsc-offset-1" tooltip="Avg Dump Time">
                    <i className="mbsc-ic mbsc-ic-arrow-down2"></i> <FreightStatusSpan freight={freight} fieldName={'AverageDumpTime'} /> 
                </div>
                <div className="mytooltip mbsc-offset-1" tooltip="Avg Round Trip (Driving)">
                <FontAwesomeIcon className="fas" icon={faCar} style={{  width:"1em", paddingRight:".2em"}}/> <FreightStatusSpan freight={freight} fieldName={'AverageRoundTrip'} /> 
                </div>
                <div className="mytooltip mbsc-offset-1" tooltip="Avg Round Trip">
                <FontAwesomeIcon className="fas" icon={faRepeat} style={{  width:"1em", paddingRight:".2em"}}/> <FreightStatusSpan freight={freight} fieldName={'FullAverageRoundTrip'} driverFieldName ={'FullAverageRoundTrip'} /> 
                </div>
                </div>
                <div className="mbsc-row mbsc-justify-content-between" style={{paddingTop:'1em'}}>
                <div className="mbsc-col" style={{padding:'0'}}><FontAwesomeIcon className="fas" icon={faLocationDot} style={{  width:"1em", paddingRight:".2em"}}/>
                    {freight.GPSStatus === 'Enabled' ? (<span>{freight.locationTime}</span>)   : ('GPS Disabled')}
                </div>
                <div className="mbsc-col" style={{padding:'0'}}>
                    Miles to {freight.distanceType}:{' '}
                    {freight.Distance ? (
                    <span>{freight.Distance}</span>
                    ) : (
                    <Button label="Get" onClick={() => getDistance(freight)} style={{height:'.6em', width:'4em', padding:'.6em', fontSize:'.8em'}} />
                    )}
                </div>
                </div>
        </Card>
      </div>
        );
    };
    
    export default FreightStatusCard;