
import React, {createContext,useContext, useRef, useState} from 'react'
import { UserAuth } from '../../../context/AuthContext';
import { db } from '../../../firebase';
import { onSnapshot, query, collection } from 'firebase/firestore';
const ReportContext = createContext();
export const ReportContextProvider = ({ children }) => {

    const { updateDocument, addDocument, gearedUser } = UserAuth();
    const [tableType, setTableType] = useState(null)
    const [reportList, setReportList] = useState([]);
    const [reportName, setReportName] = useState('')
    const [showSelectFields, setShowSelectFields] = useState(false);
    const [selectFields, setSelectFields] = useState([]);
    const [reportTable, setReportTable] = useState(null);
    const reportsRef = useRef(null);
    const reportListener = useRef(null);
    console.log('runnign the report context')
    
    const getReports = async () => {
        reportsRef.current=[];
        if(reportListener.current)reportListener.current();
        const q = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/Reports`));
        console.log(';gearedUSer. ' , gearedUser.selectedOrgName);
        reportListener.current = onSnapshot(q, (querySnapshot) => {
            console.log('report list SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {     
                let tempReport = change.doc.data();
                tempReport.ID = change.doc.id;  
                if (change.type === "added") reportsRef.current.push({...tempReport})
                
                if (change.type === "modified") {
                    let index =   reportsRef.current.findIndex(data => data.ID === tempReport.ID);
                     if(index!==-1)  reportsRef.current[index]={...tempReport}
                }
                if (change.type === "removed") {
                    let index =   reportsRef.current.findIndex(data => data.ID === tempReport.ID);
                    if(index!==-1)  reportsRef.current.splice(index,1);
                }
            })
            console.log('repsorts ref .curernt = ' , reportsRef.current)
            setReportList([...reportsRef.current])
        })
    };

    const getDefaultReports = async () => {
        reportsRef.current=[];
        if(reportListener.current)reportListener.current();
        const q = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/DefaultReports`));
        console.log(';gearedUSer. ' , gearedUser.selectedOrgName);
        reportListener.current = onSnapshot(q, (querySnapshot) => {
            console.log('report list SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {     
                let tempReport = change.doc.data();
                tempReport.ID = change.doc.id;  
                if (change.type === "added") reportsRef.current.push({...tempReport})
                
                if (change.type === "modified") {
                    let index =   reportsRef.current.findIndex(data => data.ID === tempReport.ID);
                     if(index!==-1)  reportsRef.current[index]={...tempReport}
                }
                if (change.type === "removed") {
                    let index =   reportsRef.current.findIndex(data => data.ID === tempReport.ID);
                    if(index!==-1)  reportsRef.current.splice(index,1);
                }
            })
            console.log('repsorts ref .curernt = ' , reportsRef.current)
            setReportList([...reportsRef.current])
        })
    };
    const freightBillFields = [
        {
            title:'Group By Driver',
            fieldName:'groupByDrivers',
            Type:'text',
            value:'null',
            showColumn:false,
            reportField:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Separate Scale Tags',
            fieldName:'separateScaleTags',
            Type:'text',
            value:'null',
            reportField:true,
            showColumn:false,
            printColumn:true,
            selected:false
        },
    
        {
            title:'FBNO',
            fieldName:'FBNO',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Driver Name',
            fieldName:'driverName',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Job Date',
            fieldName:'JobDate',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
         },
      
        {
            title:'Truck',
            fieldName:'TruckName',
 
            Type:'text',
            showColumn:false,
            printColumn:true,
            selected:false
        },
        {
            title:'Customer Name',
            fieldName:'AccountName',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Start Time',
            fieldName:'startTime',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'End Time',
            fieldName:'endTime',
            Type:'text',
            showColumn:true,
            printColumn:false,
            selected:false
        },
        {
            title:'Start Time Paid',
            fieldName:'startTimePaid',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'End Time Paid',
            fieldName:'endTimePaid',
            Type:'text',
            showColumn:true,
            printColumn:true,
            selected:false
        },

        {
            title:'Hours',
            fieldName:'totalYardHours',
            hasExtra1: true,
            ExtraName1:'Total',
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Bill Rate',
            fieldName:'BillRate',
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Hourly Rate',
            fieldName:'hourlyRate',
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Gross Billed',
             fieldName:'bTotal',
             hasExtra1: true,
        
            ExtraName1:'Total',
       
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Pay Total',
            fieldName:'tPaid',
            hasExtra1: true,
       
            ExtraName1:'Total',
      
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Net Billed',
            fieldName:'profit',
            hasExtra1: true,
   
            ExtraName1:'Total',
     
            Type:'number',
            showColumn:true,
            printColumn:true,
            selected:false
        },
        {
            title:'Subhauler',
            fieldName:'Subhauler',
            hasExtra1: true,
            hasExtra2:true,
            reportField:true,
            ExtraName1:'True',
            ExtraName2:'False',
            value:'null',
            Type:'boolean',
            showColumn:false,
            printColumn:true,
            selected:false
        },
        {
            title:'Is Missing',
            fieldName:'missing',
            hasExtra1: true,
            hasExtra2:true,
            reportField:true,
            ExtraName1:'True',
            value:'null',
            ExtraName2:'False',
            Type:'boolean',
            showColumn:false,
            printColumn:true,
            selected:false
            
        },
        {
            title:'Is Billed',
            fieldName:'billed',
            hasExtra1: true,
            hasExtra2:true,
            reportField:true,
            ExtraName1:'True',
            ExtraName2:'False',
            value:'null',
            Type:'boolean',
            showColumn:false,
            printColumn:true,
            selected:false
        },
        {
            title:'Is Paid',
            fieldName:'paid',
            hasExtra1: true,
            hasExtra2:true,
            value:'null',
            reportField:true,
            ExtraName1:'True',
            ExtraName2:'False',
            Type:'boolean',
            showColumn:false,
            printColumn:true,
            selected:false
        }
     
    ];
    const payStatementFields =[
      
        {
           title: 'Driver',
            fieldName: 'driverName',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            filterable:true,
            showColumn:true,
            selected: true
        },
        {
            title: 'Pay Statement Number',
            fieldName: 'DPSNum',
            hasExtra1: false,
            hasExtra2: false,
            filterable:true,
            Type: 'text',
            showColumn:true,
            selected: true
        },
   
    
        {
           title:'Is Paid',
            fieldName:'Paid',
            hasExtra1: true,
            hasExtra2:true,
            ExtraName1:'True',
            ExtraName2:'False',
            reportField:true,
            Type:'boolean',
            value:'null',
            showColumn:false,
            selected:false
        },
        {
            title: 'Subhauler',
             fieldName: 'Subhauler',
             hasExtra1: true,
             hasExtra2: true,
             ExtraName1: 'True',
             ExtraName2: 'False',
             showColumn:false,
             reportField:true,
             Type:'boolean',
             value:'null',
             selected: false
         },

        {
           title: 'Balance',
            fieldName:'Balance',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        
        {
            title: 'Net',
             fieldName: 'Total',
             hasExtra1: true,
             ExtraName1: 'Total',
             Type: 'number',
             showColumn:true,
             selected: false
         },
        {
            title: 'Gross',
             fieldName: 'Gross',
             hasExtra1: true,
             ExtraName1: 'Total',
             Type: 'number',
             showColumn:true,
             selected: false
         },
   
    ];
    const invoiceFields =[
        {
           title: 'Account',
            fieldName: 'AccountName',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Invoice Number',
            fieldName: 'InvoiceNumber',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Job Number',
            fieldName: 'JobNumber',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Load Site',
            fieldName: 'LoadSiteName',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Dump Site',
            fieldName: 'DumpSiteName',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Dispatch Date',
            fieldName: 'jobDateValue',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'date',
            showColumn:true,
            selected: true
        },
        {
           title:'Is Paid',
            fieldName:'Paid',
            hasExtra1: true,
            hasExtra2:true,
            ExtraName1:'True',
            ExtraName2:'False',
            reportField:true,
            Type:'boolean',
            value:'null',
            showColumn:false,
            selected:false
        },

        {
           title: 'Balance',
            fieldName:'Balance',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title: 'Total',
            fieldName: 'Total',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        }
    ];
    const driverFields =[
        {
           title: 'Name',
            fieldName: 'Name',
            hasExtra1: false,
            hasExtra2: false,
            Type: 'text',
            showColumn:true,
            selected: true
        },
        {
           title: 'Broker Fee',
            fieldName: 'BrokerFee',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
             showColumn:true,
            selected: false
        },
        {
           title: 'Trailer Fee',
            fieldName: 'TrailerFee',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title: 'Surcharge',
            fieldName: 'Surcharge',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title: 'Hours',
            fieldName: 'tHours',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title: 'Gross',
            fieldName: 'Gross',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title:'Pay Total',
            fieldName:'Earned',
            hasExtra1: true,
            ExtraName1:'Total',
            Type:'number',
            showColumn:true,
            selected:false
        },
        {
           title: 'Net',
            fieldName: 'Net',
            hasExtra1: true,
            ExtraName1: 'Total',
            Type: 'number',
            showColumn:true,
            selected: false
        },
        {
           title: 'Is Paid',
            fieldName: 'Paid',
            hasExtra1: true,
            hasExtra2: true,
            ExtraName1: 'True',
            ExtraName2: 'False',
            reportField:true,
            Type:'boolean',
            value:'null',
            showColumn:false,
            selected: false
        },
        {
           title: 'Subhauler',
            fieldName: 'Subhauler',
            hasExtra1: true,
            hasExtra2: true,
            ExtraName1: 'True',
            ExtraName2: 'False',
            showColumn:false,
            reportField:true,
            Type:'boolean',
            value:'null',
            selected: false
        }
    ];
 

    const saveDefaultReportTable =(fields, reportType)=>{
        let cols =[];
        console.log('fields = ', fields)
        let defaultTable={Name:reportName, ID: reportTable.ID, Columns:[], Type: reportType, tableParams:{Type:reportType}};
        let tempTotals=[];
        for(var j=0; j<fields.length; j++){
            if(fields[j].selected){
                if(fields[j].reportField)  defaultTable[fields[j].fieldName]=fields[j].value;
                else{
                    let showTotal = fields[j].ExtraSelected1 && fields[j].ExtraName1==='Total' ? true: false;
                   if(showTotal) tempTotals.push({ Type:fields[j].title,  Total:0    })
                    let newColumn={
                        field:fields[j].fieldName,
                        title: fields[j].title,
                        Type:fields[j].Type,
                        filter: {   },  
                        showTotal:showTotal,    
                        show:true
                    }
                  
                    newColumn.filter[newColumn.field]='text';
    
                    cols.push(newColumn);
        
            
                }
            }else   if(fields[j].reportField)defaultTable[fields[j].fieldName]='null';
        }
        defaultTable.Columns=[...cols];
        defaultTable.totals=[...tempTotals];
        console.log('Ima save this table = ', defaultTable)
        setShowSelectFields(false);
        
       if(defaultTable.ID)updateDocument(  defaultTable, defaultTable.ID, "DefaultReports");
       else defaultTable.ID= addDocument( defaultTable, "DefaultReports")
       setReportTable(defaultTable);
    }
    const saveDefaultCompTable =(fields)=>{
        let cols =[];

        for(var j=0; j<fields.length; j++){
            if(fields[j].selected){
                let newColumn={
                    ID:fields[j].ID,
                    field:fields[j].fieldName,
                    title: fields[j].title,
                    Type:fields[j].Type,
                    filter: {   }, 
                    background:fields[j].backgroundName,
                    sortName:fields[j].sortName,
                    status: fields[j].statusName,
                    show:true
                }
                newColumn.sortable=newColumn.sortName;
                newColumn.filter[newColumn.field]='text';
   
                cols.push(newColumn);
                if(fields[j].ExtraSelected1){
                    let infoColumn={
                        ID:fields[j].ID,
                        field:fields[j].ID+'Info',
                        title: fields[j].Name + ' Info',
                        Type:fields[j].Type,
                        filter: {  }, 
                        background:fields[j].backgroundName,
                        status: fields[j].statusName,
                        show:true
                    }
                    infoColumn.sortable=fields[j].fieldName;
                    infoColumn.filter[fields[j].fieldName]='text';
                    cols.push(infoColumn);
                    
                }
          
               
            }
        }
        let defaultTable={Columns:[...cols], Type: tableType, tableParams:{Type:tableType}}
        console.log('Ima save this table = ', defaultTable)
        setShowSelectFields(false);
       updateDocument(  defaultTable,   tableType, "DefaultComplianceTable");
    }
    const roundedMultiplication=(value1, value2)=> {
        let value = (value1 * 100) * (value2 * 100) / (100 * 100)
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
    const formatMoney =(value, decimals)=>{
   
        if(value)return Number(value).toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })  
        else return Number(0).toLocaleString('en-US', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })  ;
    }
    return (
        <ReportContext.Provider value={{ setSelectFields, selectFields, showSelectFields,setShowSelectFields,tableType, setTableType, saveDefaultCompTable, saveDefaultReportTable, 
        freightBillFields,  invoiceFields, payStatementFields, driverFields, reportTable, setReportTable, formatMoney,roundedMultiplication, reportName, setReportName,
         getDefaultReports,reportList, getReports
         }}>
            {children}
        </ReportContext.Provider>
    );
}
export const useReport= () => {
    return useContext(ReportContext);
};
