import React, { useEffect } from 'react';
import { Panel } from 'primereact/panel';

import { useFreightBill } from '../context/FreightBillContext';
import { useFreightBillHome } from '../context/FreightBillHomeContext';
import { UserAuth } from '../../../context/AuthContext';

import DispatchList from './DispatchList';
import FreightBillCalendar from './FreightBillCalendar';
import LoadingOverlay from '../../MiscComponents/LoadingOverlay';

import {useParams } from 'react-router-dom';

const FreightBillHome=(props)=> {
    const {freightBillIDRef, dispatchIDRef, fetchDispatchDriverFreightBills } = useFreightBill();
	const {
		homeDate, setHomeDate,
		homeFreightBills, homeDispatches, homeExpenses, 
		isLoading
	} = useFreightBillHome();

    const { gearedUser } = UserAuth();
    const { freightHomeDate } = useParams();

    dispatchIDRef.current='';
    freightBillIDRef.current=''; 
	
    console.log('home date on fb dashbd = '+ homeDate)
    useEffect(() => {
        console.log('WE RUNNITNE freightHomeDate USEEFFECT ', freightHomeDate)
        console.log('and home date = ', homeDate);
        if(freightHomeDate){
            if(freightHomeDate!==homeDate){
            	console.log('and now we setting home date!@')
                setHomeDate(freightHomeDate);
            }
        }
    }, [freightHomeDate]);
    if (!gearedUser) {
        return <div>Loading...</div>;
    } else if (gearedUser.selectedOrgName) {
        const sortedDispatches = [...homeDispatches.filter(item => item.QueryDate === homeDate)].sort((a, b) => {
            const timeA = a.StartTime ? new Date(`1970-01-01T${a.StartTime}:00`) : null;
            const timeB = b.StartTime ? new Date(`1970-01-01T${b.StartTime}:00`) : null;
            if (timeA && timeB)  return timeA - timeB;
            else if (timeA)   return -1;
            else if (timeB) return 1;
            else  return 0;
            
        });

        return (
            <div className="mbsc-grid" style={{ padding: "0" }}>
                <div className="mbsc-row" style={{ margin: "0" }}>
                    <div className="mbsc-col-lg-7 mbsc-col-12" style={{ padding: "0", paddingRight: ".5em" }}>
                       {homeDate &&(<FreightBillCalendar />)} 
                    </div>
                    <div className="mbsc-col-lg-5 mbsc-col-12" style={{ padding: "0", paddingLeft: ".25em" }}>
                        <Panel header="Dispatches">
                            {sortedDispatches.map((item, index) => {
                                const originalIndex = homeDispatches.findIndex(dispatch => dispatch.ID === item.ID);
                                return (
                                    <DispatchList 
										key={item.ID} 
										dispatch={{ item }} 
										homeFreightBills={homeFreightBills} 
										homeExpenses={homeExpenses}  
										originalIndex={originalIndex} 
										fetchDriverFreights ={fetchDispatchDriverFreightBills}
									/>
                                );
                            })}
                        </Panel>
                    </div>
                </div>
                <LoadingOverlay isLoading={isLoading} message="Please wait..." />
            </div>
        );
    }
}
export default FreightBillHome;
