import React from 'react';
import AutoCompleteInput from './AutoCompleteInput'; 
import  {  Textarea} from '@mobiscroll/react';

import { UserAuth } from '../../context/AuthContext';
const LocationInput = ({object, label, field, fieldName, setValue,handleFieldChange, showPopUp, disabled}) => {
    const { locations} = UserAuth();

    return(
        <React.Fragment>
             <AutoCompleteInput label={label} fieldName={fieldName} field={ field} value={object[fieldName]} suggestions={locations} setValue={setValue} handleFieldChange={handleFieldChange}  editClick={showPopUp}  databaseList={'Locations'}  disabled={disabled}/>
                <div className="p-inputgroup  text-area-inputgroup" style={{height:'4em !important'}} >
                    <span className="p-inputgroup-addon "   >Address</span> 
                    <Textarea disabled={true}  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px", height:'100% !important' }} value={object[fieldName].fullAddress}   />
                </div>
        </React.Fragment>
    )
}
export default LocationInput;