import React,{useEffect,useState} from 'react';
import { Checkbox } from 'primereact/checkbox';
import EditableLineItem from './EditableLineItem';

import  TableCell  from '../../ListComponents/TableCell';

const LineItemLine = ({ invoice, lineItem, toggleOnHold,borderWidth, columns, openExpensePopUp }) => {
    const [inputValues, setInputValues]= useState(null);

    useEffect(() => {
        if (lineItem) {
            if(lineItem.isEditable) lineItem.hideFBNO = true;
            setInputValues({...lineItem});
           
        
        }
    }, [lineItem]);

    const handleFieldChange = ( fieldName,value ) => {
        console.log('tryina set fieldname = '+ fieldName + ' equal to value = ', value)
         setInputValues((prev) => ({ ...prev, [fieldName]: value }));
         let tempLineItem={...inputValues};
         tempLineItem[fieldName]=value;
        toggleOnHold(tempLineItem, value);
     };
    const openFreightBill = (lineitem)=>{
        var win = window.open('/freightbill/freightbill/'+lineitem.FreightID+'/'+lineItem.dispatchID, '_blank');
        win.focus();
    
    }
  
    let borderStyle= borderWidth ? borderWidth: "1px 1px 1px 1px"

   
    const renderCell = (columnId, values, col) => {
        switch(columnId) {
            case 'fbno':
                return (
                    <td 
                        data-label='FB NO' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        {values.isEditable && ( <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 

                                    width:"90%" 
                                }} 
                                onClick={(e) => openExpensePopUp(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                               Open Expense
                            </button>)}
                        {!values.hideFBNO && (values.Type === 'Freight Bill' || values.firstWeight || values.Type === 'Expense' || values.Type === 'Material') && (
                            <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 
                                    width:"90%" 
                                }} 
                                onClick={(e) => openFreightBill(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                                {values.FBNO}
                            </button>
                        )}
                    </td>
                );
            case 'truck':
                return !values.hideFBNO ? 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value={values.truck} 
                        borderStyle={borderStyle} 
                        dataLabel='Truck'
                    /> : 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value='' 
                        borderStyle={borderStyle} 
                        dataLabel='Truck'
                    />;
			case 'date':
				return <TableCell 
				width={col.width} 
				isNumber={false} 
				value={values.JobDate} 
				borderStyle={borderStyle} 
				dataLabel='Date'
			/> 
			case 'driver':
                return !values.hideFBNO ? 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value={values.Driver} 
                        borderStyle={borderStyle} 
                        dataLabel='Driver'
                    /> : 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value='' 
                        borderStyle={borderStyle} 
                        dataLabel='Driver'
                    />;
            case 'missing':
                return invoice.previewing && (
                    <td 
                        data-label='Missing' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <Checkbox style={{height:"1.75em ", padding:"0 ", display: "flex", justifyContent: "center" }} checked={values.missing} disabled={true} onChange={(e) => handleFieldChange( 'missing', e.checked)}/>
                    </td>
                );
            case 'onHold':
                return invoice.previewing && (
                    <td 
                        data-label='On Hold' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <Checkbox style={{ height:"1.75em", padding:"0 ", display: "flex", justifyContent: "center" }} disabled={lineItem.Type==='Scale Tag' && lineItem.hideFBNO} checked={values.onHold} onChange={(e) => handleFieldChange( 'onHold', e.checked)}/>
                    </td>
                );
            case 'description':
                return invoice.calcByLoad && (
                    <TableCell 
                        width={col.width} 
                        value={values.Description} 
                        borderStyle={borderStyle} 
                        dataLabel='Description'
                    />
                );
            case 'rateType':
                return <TableCell 
                    width={col.width} 
                    value={values.RateType} 
                    borderStyle={borderStyle} 
                    dataLabel='Rate Type'
                />;
            case 'qty':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    notCurrency={true} 
                    value={values.Qty} 
                    borderStyle={borderStyle} 
                    dataLabel='Qty'
                />;
            case 'rate':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    value={values.Rate} 
                    borderStyle={borderStyle} 
                    dataLabel='Rate' 
                />;
            case 'tax':
                return invoice.hasTax && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.TaxTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Tax'
                    />
                );
            case 'fuelCharge':
                return invoice.hasFuelCharge && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.FuelTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Fuel Charge'
                    />
                );
            case 'brokerFee':
                return invoice.hasBrokerFee && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.BrokerTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Broker Fee'
                    />
                );
            case 'total':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    value={values.Total} 
                    borderStyle={borderStyle} 
                    dataLabel='Total'
                />;
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {inputValues && !inputValues.FreightHold && (
                <>
                    {lineItem.isEditable && invoice.previewing ? (
                        <EditableLineItem   
							lineItem={lineItem} 
							columns={columns}
                            onUpdate={(updatedLineItem) => {
                                setInputValues(updatedLineItem);
                                toggleOnHold(updatedLineItem);
                            }}
                        />
                    ) : (
                        <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em'}}>
                            {columns.map(col => col.visible && (
                                <React.Fragment key={col.id}>
                                    {renderCell(col.id, inputValues, col)}
                                </React.Fragment>
                            ))}
                        </tr>
                    )}
                </>
            )}
        </React.Fragment>
    )
}

export default LineItemLine;