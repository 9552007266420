import React, { useState, useEffect } from 'react';
import {UserAuth}  from '../../../context/AuthContext';


import { getStorage, ref,getDownloadURL,uploadBytes } from "firebase/storage";

import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';


const ItemListHome = () => {
  const { gearedUser, mapItemData, quickbooksItemList } = UserAuth();

  const storage = getStorage();

  const sorted = [...quickbooksItemList].sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));


	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Description:{ value: null, matchMode: FilterMatchMode.CONTAINS },
		Account: { value: null, matchMode: FilterMatchMode.CONTAINS },
		Status: { value: 'Active', matchMode: FilterMatchMode.EQUALS },
		Taxable: { value: null, matchMode: FilterMatchMode.EQUALS },
		SalesTaxCode: { value: null, matchMode: FilterMatchMode.CONTAINS }
	});

	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const [statuses] = useState(['Active', 'Inactive']);

	const getSeverity = (status) => (status === 'Active' ? 'success' : 'danger');

	const onGlobalFilterChange = (e) => {
		const value = e.target.value || '';
		setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));
		setGlobalFilterValue(value);
	};

	const formatCurrency = (value) => {
		return value ? `$${value.toFixed(2)}` : '$0.00';
	};

	const taxableBodyTemplate = (rowData) => (
		<i className={classNames('pi', {
		'pi-check-circle true-icon': rowData.Taxable,
		'pi-times-circle false-icon': !rowData.Taxable
		})} />
	);

	const taxableFilterTemplate = (options) => (
		<TriStateCheckbox
		value={options.value}
		onChange={(e) => options.filterCallback(e.value)}
		/>
	);

	const statusBodyTemplate = (rowData) => <Tag value={rowData.Status} severity={getSeverity(rowData.Status)} />;
	
	const statusItemTemplate = (option) => <Tag value={option} severity={getSeverity(option)} />;
	const statusRowFilterTemplate = (options) => {
		return (
			<Dropdown    
			value={options.value !== null ? options.value : undefined} 
			options={statuses}
			onChange={(e) => options.filterApplyCallback(e.value)} 
			itemTemplate={statusItemTemplate}
			placeholder="Select One"
				className="p-column-filter" 
				showClear 
				style={{ minWidth: '12rem' }} 
			/>
		);
	};
	const header = (
		<div className="flex justify-content-end mbsc-row">
		<span>Items</span>
		<IconField iconPosition="left" className="homeSearch">
			<InputIcon className="pi pi-search" />
			<InputText
			style={{ paddingLeft: '2.5rem' }}
			value={globalFilterValue}
			onChange={onGlobalFilterChange}
			placeholder="Keyword Search"
			/>
		</IconField>
		</div>
	);

const clickUpload = () =>{
	console.log(' document.getElementById(freightUpload) =' , document.getElementById('attachUpload'))
	document.getElementById('attachUpload').click();
}
const startUpload=(event)=>{
	console.log('we have started an uplaod and event ', event)
	uploadFile(event.target.files[0])
}
const uploadFile = async(file)=>{
	console.log('file = ', file.name)

	const storageRef = ref(storage, `attachments/${gearedUser.selectedOrgName}/Quickbooks/ItemList.IIF`);

	uploadBytes(storageRef, file).then((snapshot) => {
		getDownloadURL(storageRef).then((url) => {
			mapItemData(url);
			
	  	});
	});

}


  return (
	<div>
		<button    className="md-btn "  style={{ marginLeft:"2em", height:"3em", cursor:"pointer"}} onClick={() =>  clickUpload()}  >Upload Item List IIF File</button>
		<input type='file' id={'attachUpload'}    onChange={(event,inst) => startUpload(event)} style={{display:'none'}} base-sixty-four-input="true"/>
	
      <DataTable
        value={sorted}
        paginator
        rows={25}
        dataKey="ID"
        filters={filters}
        header={header}
        filterDisplay="row"
        emptyMessage="No items found."
      >
        <Column
          field="Name"
          header="Name"
          filter
		  sortable
          filterPlaceholder="Search by name"
          body={(rowData) => <span>{rowData.Name}</span>}
        />
        <Column
          field="Type"
          header="Type"
          filter
          filterPlaceholder="Search by type"
          body={(rowData) => <span>{rowData.Type}</span>}
        />
        <Column
          field="Description"
          header="Description"
          filter
          filterPlaceholder="Search by description"
          body={(rowData) => <span>{rowData.Description}</span>}
        />
        <Column
          field="Account"
          header="Account"
          filter
          filterPlaceholder="Search by account"
          body={(rowData) => <span>{rowData.Account}</span>}
        />
        <Column
          field="Value"
          header="Value"
          body={(rowData) => <span>{formatCurrency(rowData.Value)}</span>}
        />
        <Column
          field="Price"
          header="Price"
          body={(rowData) => <span>{formatCurrency(rowData.Price)}</span>}
        />
        <Column
          field="Cost"
          header="Cost"
          body={(rowData) => <span>{formatCurrency(rowData.Cost)}</span>}
        />
        <Column
          field="Taxable"
          header="Taxable"
          filter
          filterElement={taxableFilterTemplate}
          body={taxableBodyTemplate}
        />
        <Column
          field="SalesTaxCode"
          header="Tax Code"
          filter
          filterPlaceholder="Search by tax code"
          body={(rowData) => <span>{rowData.SalesTaxCode}</span>}
        />
    <Column 
	pt={{root: { 'data-label': 'Status' }}} 
	field="Status" 
	header="Status" 
	showFilterMenu={false} 
	filterMenuStyle={{ width: '14rem' }}
	 body={statusBodyTemplate} 
	 filter
	  filterElement={statusRowFilterTemplate} 
	  />

        <Column
          field="QBRefNum"
          header="QB RefNum"
          filter
          filterPlaceholder="Search by QB RefNum"
          body={(rowData) => <span>{rowData.QBRefNum}</span>}
        />
      </DataTable>
    </div>
  );
};

export default ItemListHome;
