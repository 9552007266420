import React, { useCallback, useEffect, useState, useRef } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { Dialog } from 'primereact/dialog';
import { Button } from '@mobiscroll/react';
import {InputText} from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';

import { db } from '../../../firebase';
import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber'; 
import InputTextParent from '../../InputComponents/InputTextParent'; 


import { UserAuth } from '../../../context/AuthContext';
import { useGlobal } from '../../../context/GlobalContext'


import { Dropdown } from 'primereact/dropdown';
import NoteList from '../../ListComponents/NoteList';
import DispatchCard from '../Lists/DispatchCard';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import useFieldSpecificDebounce from '../../../hooks/useFieldSpecificDebounce';
import { useDispatch } from '../context/DispatchContext';



const DispatchDriverPopUp = ({ driver, visible, setVisible, onDriverUpdate,  homeDate, onAssignDriver, assignedTrucks, hideDispatches }) => {
    const { capabilities, truckTypes, trucks, trailers, gearedUser, subhaulers, addDocument, updateDocument, deleteDocument} = UserAuth();
    const {  newTruckType, showTruckPopUp, showTrailerPopUp, newCapability} = useGlobal();
    const { homeDispatches, homeFreightBills, formatDate, dispatch, deleteFreightBillsByTruck } = useDispatch();
	
    const [selectedCapabilities, setSelectedCapabilities] = useState(null);
    const [selectedTruckTypes, setSelectedTruckTypes] = useState(null);
    const [selectedTruckTypeID, setSelectedTruckTypeID] = useState(driver.TruckTypes?.[0]?.ID || '');
    const [selectedTruckType, setSelectedTruckType] = useState(driver.TruckTypes?.[0] || {});

    const [assign, setAssign] = useState(1);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [phoneObject, setPhoneObject] = useState({ Phone1: '', Phone2: '', Phone3: '' });
    const [isEditable, setIsEditable] = useState(false);
    const [inputValues, setInputValues] = useState(null); // State to handle input values

    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch?.ID);
    let freightBills = dispatchFreightBills.sort((a, b) => a.loadOrder - b.loadOrder);
    let driverFreights = dispatchFreightBills.filter(freightBill => freightBill.Driver === driver.Driver?.ID && freightBill.TruckType.ID===selectedTruckTypeID);
    // Refs for input fields to manage focus transitions
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputValuesRef = useRef(inputValues);

    useEffect(() => {
        inputValuesRef.current = inputValues;
    }, [inputValues]);

    console.log('driver on dispathc driverp op up load', driver);
    console.log('dispatch ON THE LOAD??  = ', dispatch)
    const debouncedFieldUpdater = useFieldSpecificDebounce(500);
  //  console.log('driver = ', driver)
    
    const statuses = ['Active', 'Inactive', 'On Leave'];
 
    const closeDriverPopUp = () => {
        setVisible(false);
        console.log('ok we are closing pop up!!');
    };

   
   

    useEffect(() => {
        if (driver) {
            setSelectedCapabilities(driver.Capabilities);
            console.log('driver when reseting the input values=', driver)
            setInputValues({ ...driver,  Truck: driver.Type==='Dedicated' ? driver.Truck.Name :driver.Truck, 
            
            });
          
            setSelectedStatus(driver.Status);
    
            let ids = driver.TruckTypes.map(truckType => truckType.ID);
            setSelectedTruckTypes(ids);
            if(hideDispatches){
                setAssign(1)
                if(driver.Trucks?.[0]) {
                    setSelectedTruckTypeID(driver.Trucks[0].TruckType?.ID || '')
                    setSelectedTruckType(driver.Trucks[0].TruckType || '')
                }else{
                    setSelectedTruckTypeID(driver.TruckTypes?.[0]?.ID || '');
                    setSelectedTruckType(driver.TruckTypes?.[0] || {});
                }
            }
            if (driver?.PhoneObject) setPhoneObject({ ...driver.PhoneObject });
        }
    }, [driver]);

    const homeDateObj = new Date(homeDate.replace(/-/g, '/'));
    const notesWithIndex = driver?.Notes ? driver.Notes.map((note, originalIndex) => ({ note, originalIndex })) : [];

    const filteredNotes = notesWithIndex.filter(({ note }) => {
        if (note.EndDate) {
            const endDateParts = note.EndDate.split('/');
            const endDateObj = new Date(endDateParts[2], endDateParts[0] - 1, endDateParts[1]);
            return endDateObj >= homeDateObj;
        } else return 0;
    });

    useEffect(() => {
        if(inputValues){
            const fieldsToUpdate = driver.Type==='Dedicated' ? [ 'Trailer'] : ['Truck', 'Trailer'];
            const collections = driver.Type==='Dedicated' ?  {Trailer: trailers }:{  Truck: trucks, Trailer: trailers };
            console.log('I am runnign the update auutocomplete inputs on the dispatch driver assing/edit pop up')
            fieldsToUpdate.forEach(field => {
                const selectedItem = inputValues[field];
                if (selectedItem) {
                    const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                    if (updatedItem) if(updatedItem.Name!==selectedItem.Name) handleFieldChange(field, { ...updatedItem }, false);
    
                }
            });
        }
        }, [ trucks, trailers]);

    const updateDriverField = useCallback(async (fieldName, value) => {
        let updatedDriver = { ...inputValuesRef.current, [fieldName]: value };
        const collectionName = driver.Type === 'Driver' ? 'Drivers' : driver.Type === 'Dedicated' ? 'OutsideTrucks' :driver.Type === 'Subhauler' && 'Accounts';
        console.log('update driver in the popu p ==- ', inputValuesRef.current)
        let updateObject=  { [fieldName]: value };
        if (fieldName === 'FirstName' || fieldName === 'LastName') {
            const newFirstName = fieldName === 'FirstName' ? value : inputValuesRef.current.FirstName;
            const newLastName = fieldName === 'LastName' ? value : inputValuesRef.current.LastName;
            inputValuesRef.current.Name = `${newFirstName} ${newLastName}`;
            updatedDriver.Name = `${newFirstName} ${newLastName}`;
            updateObject = { Name:updatedDriver.Name, [fieldName]: value };
        }
        await   updateDocument(updateObject,inputValuesRef.current.ID,collectionName )
        onDriverUpdate(inputValuesRef.current);
    },  [ onDriverUpdate] );

  

    const handleFieldChange = (fieldName, value) => {
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        inputValuesRef.current[fieldName]=value; 
        console.log('inputValues when field is changed = ', inputValues)
        const debouncedUpdate = debouncedFieldUpdater([fieldName], updateDriverField);
        debouncedUpdate(fieldName, value); // Pass `fields` and `values` dynamically
  
    };
	const handleUpdateNote = useCallback((index, note) => {
		let tempNote = {...note};
		tempNote.QueryDate = formatDate(tempNote.EndDate, '/', 'YYYY/MM/DD');
		updateDocument(tempNote, note.ID, 'Notes')
	}, [driver, onDriverUpdate]);

   
    const handleDeleteNote = useCallback((index, note) => {
		if(window.confirm('Are you sure you want to delete this note?')){
			console.log('deleting note = ', note)
			deleteDocument(note, 'Notes');
	   }
       
    }, [driver, onDriverUpdate]);

    const handleCapabilitiesChange = (selected) => {
        console.log('selected = ', selected);
        setSelectedCapabilities(selected);
        const collectionName = driver.Type === 'Driver' ? 'Drivers' : driver.Type === 'Dedicated' ? 'OutsideTrucks' :driver.Type === 'Subhauler' && 'Accounts';
     
        let displayCapabilities = [];
        for (var i = 0; i < driver.TruckTypes.length; i++) displayCapabilities.push(driver.TruckTypes[i].TruckCode);
        for (var j = 0; j < selected.length; j++) displayCapabilities.push(selected[j]);
        let updateObject = {Capabilities: selected, displayCapabilities: displayCapabilities  };
        console.log('inputvalues = ', inputValues)
        updateDocument(updateObject,inputValuesRef.current.ID,collectionName )
        onDriverUpdate({ ...inputValuesRef.current, Capabilities: selected, displayCapabilities: displayCapabilities });
    };

    const handleTruckTypesChange = (selected) => {
        console.log('selected = ', selected);
        setSelectedTruckTypes(selected);
        const collectionName = driver.Type === 'Driver' ? 'Drivers' : driver.Type === 'Dedicated' ? 'OutsideTrucks' :driver.Type === 'Subhauler' && 'Accounts';
        let displayCapabilities = [];
        let tempTruckTypes = [];
        driver.TruckTypes = [];
        for (var q = 0; q < truckTypes.length; q++) {
            for (var j = 0; j < selected.length; j++) {
                if (selected[j] === truckTypes[q].ID) {
                    tempTruckTypes.push(truckTypes[q]);
                    displayCapabilities.push(truckTypes[q].TruckCode);
                }
            }
        }

        for (var i = 0; i < driver.Capabilities.length; i++) displayCapabilities.push(driver.Capabilities[i]);
        console.log('displayCapabilities = ', displayCapabilities);
        let updateObject = {TruckTypes: tempTruckTypes, displayCapabilities: displayCapabilities  };
        updateDocument(updateObject,inputValuesRef.current.ID,collectionName )

        onDriverUpdate({ ...inputValuesRef.current, TruckTypes: tempTruckTypes, displayCapabilities: displayCapabilities });
    };
    const handleTruckTypeChange = (value)=>{
        console.log('value of truck tyhep = ', value)
        setSelectedTruckTypeID(value);
        for (let i = 0; i < truckTypes.length; i++)
            if (truckTypes[i].ID === value) setSelectedTruckType(truckTypes[i])
                
    }
    const handlePhoneChange = (field, value, nextRef) => {
        const maxLength = field === 'Phone3' ? 4 : 3;
        if (value.length <= maxLength) {
            setPhoneObject((prev) => ({ ...prev, [field]: value }));
            if (value.length === maxLength && nextRef) {
                nextRef.current.focus();
            }
        }
    };

    const addSchedule = function () {
    
        let Note = {};
   
        Note.Note = '';
        Note.noteType = 'Schedule';
        Note.Red = true;
        Note.Yellow = false;
	
        Note.QueryDate = formatDate(homeDate, '/', 'YYYY/MM/DD');
        Note.EndDate = formatDate(homeDate, '/', 'MM/DD/YYYY');
        Note.Date = formatDate(homeDate, '/', 'MM/DD/YYYY');
        Note.StartDate = formatDate(homeDate, '/', 'MM/DD/YYYY');
		Note.ParentID = driver.ID;
        Note.createdBy = gearedUser.Email;

        if (driver.Type === 'Dedicated'){
            Note.Truck ={ID:driver.ID,Name:driver.Name, DriverName: driver.DriverName};
           /* for(var q=0; q<subhaulers.length; q++){
                if(driver.AccountID===subhaulers[q].ID){
                    let tempSubhaulers =[...subhaulers];
                    tempSubhaulers[q].NoteList.push(Note)
                    console.log('and the new notelist look like dis = ', tempSubhaulers[q].NoteList)
                    console.log('saving to acocunt = ', driver.AccountID);
                    let accountRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Accounts', driver.AccountID);
                    updateDoc(accountRef, { NoteList: tempSubhaulers[q].NoteList}).then(() => {}).catch((error) => {console.log('error updating acc =', error);  });
                }    
            }*/
        } else if(driver.Type === 'Subhauler'){
            Note.Truck = {ID:'',Name:'', DriverName:''};
           /* for(var q=0; q<subhaulers.length; q++){
                if(driver.ID===subhaulers[q].ID){
                    let tempSubhaulers =[...subhaulers];
                    tempSubhaulers[q].NoteList.push(Note)
                    let accountRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Accounts', driver.ID);
                    updateDoc(accountRef, { NoteList: tempSubhaulers[q].NoteList}).then(() => {}).catch((error) => {console.log('error updating acc =', error);  });
                }    
            }*/

        }
		addDocument(Note, 'Notes');
    };

    const handlePhoneSave = () => {
        const updatedDriver = { ...driver, PhoneObject: phoneObject, Phone: phoneObject.Phone1 + phoneObject.Phone2 + phoneObject.Phone3 };
        onDriverUpdate(updatedDriver);
        setIsEditable(false);
    };

    const handleDispatchClick = async (selectedDispatch, freightBills, expenses) => {
        if(driver.Type!=='Subhauler'){
            const isDriverAssigned = freightBills.some(freightBill => freightBill.Driver === driver.ID);
            if (isDriverAssigned) {
                window.alert('Driver is already assigned to this Dispatch');
                // Optionally, you could handle the case where the driver is already assigned
                return;
            }
            if(selectedDispatch.Released && !selectedDispatch.isChanging ){
                window.alert('Dispatch is already Released. Please open Dispatch to assign driver.');
                // Optionally, you could handle the case where the driver is already assigned
                return;
            }
            console.log('driver = ', driver)
            if(driver.Absent){
                window.alert('Driver is unavailable today because: '+ driver.Note);
                // Optionally, you could handle the case where the driver is already assigned
                return;
            }
            if(driver.absentWarning ) window.alert('Driver has a note today because: '+ driver.Note);
            onAssignDriver(driver, selectedDispatch, freightBills,{}, expenses);
            setVisible(false);
        }
    };
    const handleAssignTrucks = (freightBills, selectedDispatch, truckAmount, tempSelectedTruckType, expenses)=>{
  
        let tempCapabilities = [];
        let TruckID=0;
        let trucks =[];

        if(driver.Absent){
            window.alert('Driver is unavailable today because: '+ driver.Note);
            // Optionally, you could handle the case where the driver is already assigned
            return;
        } 
        if(selectedDispatch.Released && !selectedDispatch.isChanging ){
            window.alert('Dispatch is already Released. Please open Dispatch to assign driver.');
            // Optionally, you could handle the case where the driver is already assigned
            return;
        }
        if(driver.absentWarning ) window.alert('Driver has a note today because: '+ driver.Note);
        if(tempSelectedTruckType.TruckCode)tempCapabilities.push(tempSelectedTruckType.TruckCode);
        tempCapabilities = tempCapabilities.concat(driver.Capabilities);
   
        console.log('driver.ORgName ', driver);
        console.log('tempCapabilitie = ', tempCapabilities);
    
        if(driver.Trucks) trucks = [...driver.Trucks];
        for(let q=0; q<assignedTrucks.length; q++){
            if(assignedTrucks[q].SubandCapabilities===driver.Driver.ID+tempCapabilities) trucks = [...assignedTrucks[q].Trucks];
        }
        for(var a =0; a<trucks.length; a++) if(TruckID<=trucks[a].Priority)TruckID=trucks[a].Priority;
        for(var i=0; i<truckAmount; i++){
            let newTruck ={
                ID: TruckID.toString()+driver.ID+tempCapabilities,
                TruckType: tempSelectedTruckType,
                Dedicated:false,
                fullID: TruckID.toString()+driver.ID+tempCapabilities,
                SubandCapabilities:driver.Driver.ID+tempCapabilities,
                DriverID: driver.Driver.ID,
                Assigned:false,
                OnDispatch:false,
                DispatchAssigned:0,
                OrgName:driver.OrgName,
                DriverName:driver.DriverName,
                Phone: driver.Phone,
                tempCapabilities: tempCapabilities,
                displayCapabilities: tempCapabilities,
                Capabilities:tempCapabilities,
                Priority: TruckID + 1
            };
            console.log('we are pushign thsi truck = ', newTruck)
            TruckID++;
            onAssignDriver(driver, selectedDispatch, freightBills,newTruck, expenses);
       }
       setVisible(false);
    }
        
    const assignBulkTrucks = () =>{
        console.log('selectedTruckType = ', selectedTruckType)
        console.log('dispatch from the disaiptth ccontext ??  = ', dispatch)
        handleAssignTrucks(freightBills, dispatch, assign, selectedTruckType, null)
    } 
    const unassignBulkTrucks = () =>{
       if(assign<=driverFreights.length){
            console.log('selectedTruckType = ', selectedTruckType)
            deleteFreightBillsByTruck(dispatch, driver.Driver.ID, assign, selectedTruckTypeID);
            setVisible(false);
        }else alert('You do not have that many trucks with that truck type on this Dispatch');
   
    }
 
    const toggleEditMode = () => {
        if (isEditable) {
            handlePhoneSave();
        } else {
            setIsEditable(true);
        }
    };

    const panelHeaderTemplate = () => {
        return <div className="py-2 px-3"> </div>;
    };

    if (!driver || !driver.Type) {
        return null;
    }

    return (
        <Dialog header={driver.Type !== "Subhauler" ? "Driver Details" : "Profile"} visible={visible} style={{ width: '40vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }} onHide={closeDriverPopUp}>
      
            {inputValues && (<div className="mbsc-form-group" style={{margin:".5em"}}>
                {driver.Type === "Subhauler" || driver.Type === 'Dedicated' ? (
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">Name:</span>
                        <InputTextParent value={inputValues.DriverName} onChange={(e) => handleFieldChange('DriverName', e.target.value)} />
                    </div>
                ) : (
                    <div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">First Name:</span>
                            <InputTextParent value={inputValues.FirstName} onChange={(e) => handleFieldChange('FirstName', e.target.value)} />
                        </div>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon" >Last Name:</span>
                            <InputTextParent value={inputValues.LastName} onChange={(e) => handleFieldChange('LastName', e.target.value)} />
                        </div>
                    </div>
                )}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Phone:</span>
                    <InputText maxLength={3} value={phoneObject.Phone1} onChange={(e) => handlePhoneChange('Phone1', e.target.value, inputRef2)} disabled={!isEditable} />-
                    <InputText ref={inputRef2} maxLength={3} value={phoneObject.Phone2} onChange={(e) => handlePhoneChange('Phone2', e.target.value, inputRef3)} disabled={!isEditable} />-
                    <InputText ref={inputRef3} maxLength={4} value={phoneObject.Phone3} onChange={(e) => handlePhoneChange('Phone3', e.target.value, null)} disabled={!isEditable} />
                    <Button starticon="pencil" style={{ margin: "0" }} onClick={toggleEditMode}></Button>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Capabilities:</span>
                    <MultiSelect panelHeaderTemplate={panelHeaderTemplate} value={selectedCapabilities} onChange={(e) => handleCapabilitiesChange(e.value)} options={capabilities} optionLabel="Name"
                        placeholder="Capabilities" maxSelectedLabels={3} className="w-full md:w-20rem" />
                        <button  className="mbsc-reset mbsc-font mbsc-button mbsc-windows mbsc-ltr mbsc-button-standard"  onClick={(e) =>newCapability()}  starticon="tag" style={{ color: "blue", margin: "0" }}>
                                    <span className='mbsc-button-icon mbsc-ltr mbsc-button-icon-start mbsc-icon mbsc-windows mbsc-font-icon mbsc-icon-plus'></span>
                            </button>
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Truck Type:</span>
                    <MultiSelect panelHeaderTemplate={panelHeaderTemplate} value={selectedTruckTypes} onChange={(e) => handleTruckTypesChange(e.value)} options={truckTypes} optionLabel="Name"
                        placeholder="Truck Types" maxSelectedLabels={3} className="w-full md:w-20rem" />
                   <button  className="mbsc-reset mbsc-font mbsc-button mbsc-windows mbsc-ltr mbsc-button-standard"  onClick={(e) =>newTruckType()}  starticon="tag" style={{ color: "blue", margin: "0" }}>
                                    <span className='mbsc-button-icon mbsc-ltr mbsc-button-icon-start mbsc-icon mbsc-windows mbsc-font-icon mbsc-icon-plus'></span>
                                </button>
                </div>
                {driver.Type === "Subhauler" || driver.Type === 'Dedicated' ? (
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon" >Truck:</span>
                      <InputTextParent value={inputValues.Truck} onChange={(e) => handleFieldChange('Truck', e.target.value)} />
                    </div>):
                    ( <AutoCompleteInput fieldName="Truck" field="Name" value={inputValues.Truck} suggestions={trucks} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckPopUp(inputValues.Truck)} databaseList={'Trucks'}/>)}
                {driver.Type !== "Subhauler" && (
                    <AutoCompleteInput fieldName="Trailer" field="Name" value={inputValues.Trailer} suggestions={trailers} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTrailerPopUp(inputValues.Trailer)} databaseList={'Trailers'}/>
                )}
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">Status:</span>
                    <Dropdown value={selectedStatus} onChange={(e) => setSelectedStatus(e.value)} options={statuses}
                        placeholder="Select a Status" className="w-full md:w-14rem" />
                </div>
                {hideDispatches && (<>
                    <div className="mbsc-row">    
                        <div className="p-inputgroup mbsc-col-lg-10" style={{padding:"0", paddingRight:".8em", height:"2.5em"}}>
                            <span className="p-inputgroup-addon " style={{flexBasis:"32%"}} > Assign: </span>
                            <AutoSelectInputNumber  className='mbsc-col-lg-5' style={{padding:"0"}} minFractionDigits={0} maxFractionDigits={0}  value={assign}  onChange={(e) => setAssign(e.value)}  />
                            <Button className=" mbsc-col-lg-3 "  color="primary" style={{margin:".25em" }}  onClick={(e)=>{assignBulkTrucks()}}>Assign</Button> 
                            <Button className= "mbsc-col-lg-3" color="primary" style={{margin:".25em" }} onClick={(e)=>{unassignBulkTrucks()}}>Unassign</Button> 
                        </div>
                    </div>
                    <div className="mbsc-row">  
                        <div className="p-inputgroup mbsc-col-lg-10" style={{padding:"0", paddingRight:".8em", height:"2.5em"}}>
                            <span className="p-inputgroup-addon" style={{flexBasis:"32%"}} >Truck Type:</span>
                            <Dropdown  value={selectedTruckTypeID} onChange={(e) => handleTruckTypeChange(e.value)} options={truckTypes} optionLabel="Name"
                                placeholder="Truck Type" className="w-full md:w-20rem" />
                        </div>
                    </div>
                </>)}
            </div>)}

            {inputValues && ( <div className="mbsc-form-group-content" style={{margin:".25em"}}>
                <div className="mbsc-form-group-inset" style={{ fontSize: ".75em", margin:".25em" }}>
                 
                            <div className="mbsc-row" style={{ width: "100%" }}>
                                <div className="mbsc-col-md-2 mbsc-col-4"><button id="addNoteButton" className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button" onClick={addSchedule} >Add Schedule</button></div>
                            </div>
                            <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                                <table style={{ marginBottom: "5px", width: "100%" }}>
                                    <thead>
                                        <tr style={{ width: "100%", marginLeft: "1em" }}>
                                            <th style={{ width: "10%" }}>Delete</th>
                                            <th style={{ width: "15%" }}>Start Date</th>
                                            <th style={{ width: "15%" }}>End Date</th>
                                            <th style={{ width: "20%" }}>Note</th>
                                            <th style={{ width: "5%", background: "red" }}></th>
                                            <th style={{ width: "5%", background: "#ef6c00" }}></th>
                                            <th style={{ width: "30%" }}>Created By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredNotes.map(({ note, originalIndex }) => (
                                            <NoteList key={originalIndex} note={note}  formatDate={formatDate} onDeleteNote={(deletedNote) => handleDeleteNote(originalIndex,deletedNote)}   onUpdateNote={(updatedNote) => handleUpdateNote(originalIndex, updatedNote)} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                       
                </div>
            </div>)}

       

           {!hideDispatches && ( <div className="mbsc-row" style={{ paddingLeft: ".5em", width: "100%" }}>
                {homeDispatches.filter(item => item.QueryDate === homeDate).map((item, index) => {
                    const originalIndex = homeDispatches.findIndex(dispatch => dispatch.ID === item.ID);

                    return (
                        <DispatchCard
                            key={item.ID}
                            dispatch={{ item }}
                            originalIndex={originalIndex} // Pass the original index
                            homeFreightBills={homeFreightBills} // Pass the filtered FreightBills
                            onClick={handleDispatchClick}
                            showDrivers={false}
                            showAssign={driver.Type==='Subhauler'}
                            assignTrucks={handleAssignTrucks}
                            driver={driver}
                            deleteFreightBillsByTruck={deleteFreightBillsByTruck}
                      
                        />
                    );
                })}
            </div>)}
        </Dialog>
    );
};

export default DispatchDriverPopUp;