import React, { useEffect, useState, useRef } from 'react';
import { Page } from '@mobiscroll/react';
import { Panel } from 'primereact/panel';     
  
import { useGlobal } from '../../context/GlobalContext'
import { UserAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { query, collection, onSnapshot } from 'firebase/firestore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import MapComponent from '../MiscComponents/MapComponent'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEdit } from '@fortawesome/free-solid-svg-icons';

const Tablets = (props) => {
    const { gearedUser, drivers } = UserAuth();
    const { showTabletDriverPopUp} = useGlobal();
    const [tabletList, setTabletList] = useState(null);
    const tabletsRef = useRef(null);
    const deviceListenerRef = useRef(null);

  
    useEffect(() => {
   
        syncDevicesWithDrivers();
     }, [drivers]);
    useEffect(() => {
       queryTablets();
    }, []);

    const formatTabletDate = (dateString) => {
        // Convert the string into a Date object
        dateString+='.000Z';
        const dateObject = new Date(dateString);
        console.log('dateObject = ', dateObject)
        // Format the date to the desired output in PST (Pacific Standard Time)
        const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: 'PST', // Set to PST
            dateStyle: 'short', // Formats as MM/DD/YY
            timeStyle: 'short'  // Formats time as hh:mm AM/PM
        });
        console.log('formattedDate =' , formattedDate)
        return { dateObject, formattedDate };
    };
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName: { value: null, matchMode: FilterMatchMode.CONTAINS },
       model: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        IMEI: { value: null, matchMode: FilterMatchMode.CONTAINS },
       serail_no: { value: null, matchMode: FilterMatchMode.CONTAINS}
   
        // Don't set anything here for Account.Name initially
    });

    const syncDevicesWithDrivers = () =>{
        if( tabletsRef.current &&  tabletsRef.current.length>0){
        if(drivers && drivers.length>0){
            for(let i=0; i<drivers.length; i++){
             
                    for(let q=0; q<tabletsRef.current.length; q++){
                        if(drivers[i].deviceID===tabletsRef.current[q].ID){
                            tabletsRef.current[q].Driver=drivers[i];
                            tabletsRef.current[q].driverName=drivers[i].Name;
                            tabletsRef.current[q].driverID=drivers[i].ID;
                            if(drivers[i].Initials)tabletsRef.current[q].Initials=drivers[i].Initials;else tabletsRef.current[q].Initials=tabletsRef.current[q].name;
                                
                        }
                    }
                }
                console.log('SETTING TALBETS LIST = ', tabletsRef.current)
                setTabletList([...tabletsRef.current])
            }

        }
    }
    const queryTablets = () => {
        console.log('we running Query tablets');
        if (deviceListenerRef.current) deviceListenerRef.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/ScalefusionDevices`;
        tabletsRef.current = [];
        const deviceQuery = query(collection(db, queryName));
        deviceListenerRef.current = onSnapshot(deviceQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var tempDevice = change.doc.data();
                tempDevice.ID = change.doc.id;
                const formattedDate = formatTabletDate(tempDevice.location.created_at);
                tempDevice.driverName = tempDevice.driverName ? tempDevice.driverName : '';
                tempDevice.lastUpdated = formattedDate.dateObject;  // Keep the Date object for sorting
                tempDevice.lastUpdatedFormatted = formattedDate.formattedDate;  // Store the formatted date for display
                const foundIndex = tabletsRef.current ? tabletsRef.current.findIndex(obj => obj.ID === tempDevice.ID) : -1;
                if (change.type === "added") tabletsRef.current.push({ ...tempDevice });
                if (change.type === "modified") tabletsRef.current[foundIndex] = { ...tempDevice };
                if (change.type === "removed") tabletsRef.current.splice(foundIndex, 1);
            });
            console.log('Finished querying');
            syncDevicesWithDrivers();
          
    
        });
    }
   
    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );
 
    const handleEdit = (rowData) => {
       console.log('show roate ', rowData)
       showTabletDriverPopUp(rowData);
     //  showNamePopUp(rowData, 'expenseNames');
    
    };

    const dateBodyTemplate = (rowData) => {
        return rowData.lastUpdatedFormatted;  // Display formatted date in the table
    }

    return (
        <Page>
            <div className="mbsc-grid mbsc-justify-content-center">
                <Panel header="map">
                        <MapComponent tabletList={tabletList}></MapComponent>      
                    </Panel>

                <DataTable  filters={filters}  showGridlines  sortField="lastUpdated" sortOrder={1} value={tabletList}  filterDisplay="row" style={{ paddingTop: '3em', paddingBottom:'2em' }}  tableStyle={{ width: '100%' }}>
                    <Column pt={{root: { 'data-label': 'Edit' }}} style={{ width: '1em', textAlign:"center"}} header="Edit" body={editBodyTemplate} />
                    <Column pt={{root: { 'data-label': 'Device Name' }}} style={{ width: '3em' }}  sortable filter field="name" header="Device Name"></Column>
                    <Column pt={{root: { 'data-label': 'Driver' }}} style={{ width: '3em' }}  sortable filter field="driverName" header="Driver Name"></Column>
               
                    <Column  pt={{root: { 'data-label': 'Last Updated' }}} style={{ width: '2em' }} field="lastUpdated"  dataType="date"  sortable  body={dateBodyTemplate}header="Last Updated"></Column>
                    <Column pt={{root: { 'data-label': 'Battery' }}} style={{ width: '2em' }} sortable field="battery_status" header="Battery Status"></Column>
                    <Column pt={{root: { 'data-label': 'Model' }}} style={{ width: '3em' }} sortable filter field="model" header="Model"></Column>
                    <Column pt={{root: { 'data-label': 'IMEI' }}} style={{ width: '2em' }} sortable filter field="imei_no" header="IMEI"></Column>
                    <Column pt={{root: { 'data-label': 'Serial #' }}} style={{ width: '2em' }} sortable filter field="serial_no" header="Serial #"></Column>
                </DataTable>
            </div>
        </Page>
    );
}

export default Tablets;
