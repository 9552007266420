import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useReport } from './context/ReportContext';
import { db } from '../../firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode} from 'primereact/api';
import CustomizeTable from './CustomizeTable'; 
import { Card} from 'primereact/card';
import pdfMake from 'pdfmake/build/pdfmake';
//import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useParams } from 'react-router-dom';
const ComplianceReport = () => {

    const { gearedUser, compliances, trucks, trailers, drivers, subhaulers, driverComplianceNames, truckComplianceNames,trailerComplianceNames, company, formatDate } = UserAuth();
    const {setSelectFields, showSelectFields, setShowSelectFields, setTableType} = useReport();
    const { compType } = useParams();
    
    const [complianceTableList, setComplianceTableList] = useState([]);
    const [fullComplianceTableList, setFullComplianceTableList] = useState([]);

    const [complianceTable, setComplianceTable] = useState(null);
    const [filters, setFilters] = useState({});
 

    const [showUpcoming, setShowUpcoming] = useState(false);
    const [showExpired, setShowExpired] = useState(false);
    const [showMissing, setShowMissing] = useState(false);



    console.log('runnign compliance report page', complianceTable)
    console.log('runnign compliance report page', complianceTableList)
    useEffect(() => {
        console.log('running the use effect to setcompliancetable list ');
        if (showUpcoming) {
            setComplianceTableList(fullComplianceTableList.filter(comp => comp.hasUpcoming));
        } else if (showExpired) {
            setComplianceTableList(fullComplianceTableList.filter(comp => comp.hasExpired));
        } else if (showMissing) {
            setComplianceTableList(fullComplianceTableList.filter(comp => comp.hasMissing));
        } else {
            setComplianceTableList([...fullComplianceTableList]);
        }
    }, [showUpcoming, showExpired, showMissing, fullComplianceTableList]);
    useEffect(()=>{
        if(compType!=='OutsideDriver') setTableType(compType); else setTableType('Driver'); 
       
    },[compType])
    useEffect(() => {
        if (complianceTable) {
            if(compType==='Trailer' && trailers)  filterTrailers();
            if(compType==='Truck' && trucks)  filterTrucks();
            if(compType==='Driver' && drivers)  filterDrivers();
            if(compType==='OutsideDriver' && subhaulers)  filterOutsideDrivers();
        }
    }, [complianceTable, trucks,trailers, drivers, subhaulers]);

    useEffect(() => {
        console.log('RUNNIGN COMPLIANCE GEARED USER USE EFECT')
        if (gearedUser) {
            getDefaultTable();
        }
    }, [gearedUser, compType]);
    

    const setDriverFields = () => {
        let fields = [];
      
            fields.push({
                title:'Name',
                fieldName:'Name',
                ID:'Name',
                Name:'Name',
                Type:'text',
                selected:true,
                backgroundName:'namebackground',
                statusName:'Name',
                sortName:'Name',
                hasExtra1:false,
                ExtraSelected1: false,
                class:'driverNameClass'
            });
            fields.push({
                title: 'Phone Number',
                fieldName: 'displayPhone',
                Name:'Phone Number',
                ID:'Phone',
                Type: 'text',
                backgroundName: 'namebackground',
                statusName: 'Phone',
                sortName:'Phone',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: false
            });
            fields.push({
                title: 'Hired Date',
                ID:'HiredDate',
                Name:'Hired Date',
                fieldName: 'HiredDate',
                Type: 'date',
                backgroundName: 'namebackground',
                statusName: 'HiredDate',
                sortName: 'HiredDate',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: false
            });
             fields.push({
                title: 'Birth Date',
                ID:'BirthDate',
                Name:'BirthDate',
                fieldName: 'BirthDate',
                Type: 'date',
                backgroundName: 'namebackground',
                statusName: 'BirthDate',
                sortName: 'BirthDate',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: false
            });
            fields.push({
                title: 'Address',
                ID:'fullAddress',
                Name:'Address',
                fieldName: 'fullAddress',
                Type: 'text',
                backgroundName: 'namebackground',
                statusName: 'Address',
                sortName: 'Address',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: false
            });
             fields.push({
                title: 'License #',
                ID: 'License #',
                Name:'License #',
                fieldName: 'DriversLicense',
                Type: 'text',
                backgroundName: 'namebackground',
                statusName: 'DriversLicense',
                sortName: 'DriversLicense',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: false
            });
            for(var i=0; i<driverComplianceNames.length; i++){
                let sortName = driverComplianceNames[i].Name.replace(/'/g, "");
                sortName = sortName.replace(/ /g, "");
                sortName = sortName.replace(/-/g, "");
                fields.push({
                     title:driverComplianceNames[i].Name,
                     fieldName:driverComplianceNames[i].ID+'ExpDate',
                     Type:'date',
                     Name:driverComplianceNames[i].Name,
                     ID:driverComplianceNames[i].ID,
                     backgroundName:driverComplianceNames[i].ID+'background',
                     statusName:driverComplianceNames[i].ID+'status',
                     sortName:sortName,
                     hasExtra1:true,
                     infoField:true,
                     ExtraName1:'Info',
                     ExtraSelected1: false,
                     selected: true
                 });
            }
            setSelectFields([...fields]);
        
    }
    const setOutsideDriverFields = () =>{
        let fields = [];
        fields.push({
            title: 'Name',
            ID:'Name',
            Name:'Name',
            fieldName: 'DriverName',
            Type: 'text',
            backgroundName: 'namebackground',
            statusName: 'DriverName',
            sortName:'DriverName',
            hasExtra1:false,
            ExtraSelected1: false,
            selected: true
        });
        if(company.CompanyName==="John's Trucking Inc"){
            fields.push({
                title: 'SSN',
                ID:'SSN',
                fieldName: 'SSN',
                Name:'SSN',
                Type: 'text',
                backgroundName: 'namebackground',
                statusName: 'SSN',
                sortName:'SSN',
                hasExtra1:false,
                ExtraSelected1: false,
                selected: true
            });
        }
        for(var i=0; i<driverComplianceNames.length; i++){
            let sortName = driverComplianceNames[i].Name.replace(/'/g, "");
            sortName = sortName.replace(/ /g, "");
            sortName = sortName.replace(/-/g, "");
            fields.push({
                    title:driverComplianceNames[i].Name,
                    ID:driverComplianceNames[i].ID,
                    fieldName:driverComplianceNames[i].ID+'ExpDate',
                    Name:driverComplianceNames[i].Name,
                    Type:'date',
                    backgroundName:driverComplianceNames[i].ID+'background',
                    statusName:driverComplianceNames[i].ID+'status',
                    sortName:sortName,
                    hasExtra1:true,
                    infoField:true,
                    ExtraName1:'Info',
                    ExtraSelected1: false,
                    selected: true
                });
                console.log('we just pushed for driverComplianceNames[i].ID = ',   fields);
            }
            setSelectFields([...fields]);
        
    }
    const setTruckFields = () =>{
        let fields= [];
        fields.push({
            title:'Name',
            ID:'Name',
            Name:'Name',
            fieldName:'Name',
            Type:'text',
            selected:true,
            backgroundName:'namebackground',
            statusName:'Name',
            hasExtra1:false,
            sortName:'Name',
            ExtraSelected1: false,
            class:'driverNameClass'
        });
    
        for(var i=0; i<truckComplianceNames.length; i++){
        fields.push({
                title:truckComplianceNames[i].Name,
                Name:truckComplianceNames[i].Name,
                fieldName:truckComplianceNames[i].ID+'ExpDate',
                Type:'date',
                ID:truckComplianceNames[i].ID,
                backgroundName:truckComplianceNames[i].ID+'background',
                statusName:truckComplianceNames[i].ID+'status',
                hasExtra1:false,
                sortName:truckComplianceNames[i].ID,
                ExtraSelected1: false,
                selected:false
            });
        }
        setSelectFields([...fields]);
      
    }
    const setTrailerFields = () =>{
        let fields= [];
        fields.push({
            title:'Name',
            ID:'Name',
            Name:'Name',
            fieldName:'Name',
            Type:'text',
            selected:true,
            backgroundName:'namebackground',
            statusName:'Name',
            hasExtra1:false,
            sortName:'Name',
            ExtraSelected1: false,
            class:'driverNameClass'
        });
    
        for(var i=0; i<trailerComplianceNames.length; i++){
        fields.push({
                title:trailerComplianceNames[i].Name,
                Name:trailerComplianceNames[i].Name,
                fieldName:trailerComplianceNames[i].ID+'ExpDate',
                Type:'date',
                ID:trailerComplianceNames[i].ID,
                backgroundName:trailerComplianceNames[i].ID+'background',
                statusName:trailerComplianceNames[i].ID+'status',
                hasExtra1:false,
                sortName:trailerComplianceNames[i].ID,
                ExtraSelected1: false,
                selected:false
            });
        }
        setSelectFields([...fields]);
      
    }

    const getDefaultTable = async () => {
        let newTableType = compType==='OutsideDriver' ? 'Driver' : compType;
        console.log('ok newTable tye = ' + newTableType);

        const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/DefaultComplianceTable`, newTableType);
        onSnapshot(docRef, async (docSnap) => {
  
            if (docSnap.exists()) {
                let tempTable = docSnap.data();
                console.log('foudn table it looks like thsi =', tempTable)
                let tempFilters ={};
                for(let i=0; i<tempTable.Columns.length; i++){
                    tempFilters[tempTable.Columns[i].field]=  { value: null, matchMode: FilterMatchMode.CONTAINS }
                }
                setFilters(tempFilters)
                setComplianceTable(tempTable);
            }
        })
    };
    
    const filterDrivers = () =>{
    
        let complianceList = [];
        const driverCompliances  = compliances ? compliances.filter(compliance => compliance.Type==='Driver') : [];
    
        console.log('RUNNIGN SELECT DRIVERS and length ==   ' + drivers.length);
        for (var k = 0; k < drivers.length; k++) {
                let driver = {...drivers[k]}
                if(driver.Status==='Active'){
                    if(driver.Address)driver.fullAddress=driver.Address;else driver.fullAddress='';
                    if(driver.City)driver.fullAddress+=', ' + driver.City;
                    if(driver.State)driver.fullAddress+=', ' + driver.State;
                    if(driver.ZipCode)driver.fullAddress+=' ' + driver.ZipCode;
                    driver.compExpired = 0;
                    driver.compMissing = 0;
                    driver.compUpcoming = 0;
                    driver.isShowing = true;
                    if(driver.HiredDate)driver.HiredDate=new Date(driver.HiredDate)
                    if(driver.BirthDate)driver.BirthDate=new Date(driver.BirthDate)
                    for (var j = 0; j < complianceTable.Columns.length; j++) {
                        if (complianceTable.Columns[j].field != 'Name' && complianceTable.Columns[j].field !== 'SSN' && complianceTable.Columns[j].field !== 'DriversLicense'  && complianceTable.Columns[j].field !== 'fullAddress' && complianceTable.Columns[j].field !== 'Phone' && complianceTable.Columns[j].field !== 'HiredDate' && complianceTable.Columns[j].field !== 'BirthDate' && complianceTable.Columns[j].field !=='DriverLicense') {
                          // console.log('for a copmliance table column field name = ' + complianceTable.Columns[j].field + 'WE ARE UPPING THE COMPMISSING FOR DRIVER = '+ driver.Name)
                            driver[complianceTable.Columns[j].status] = 'Missing';
                            driver.compMissing++;
                           // console.log('and ocmp missing ofr htis nwo = ' + driver.compMissing)
                        }
                    }
                    driver.Compliances=[];
                    for(var d=0; d<driverCompliances.length; d++)if(driverCompliances[d].ParentID===driver.ID) driver.Compliances.push(driverCompliances[d])
                    
                    for (var q = 0; q < driver.Compliances.length; q++) {
                        var tempComp = {...driver.Compliances[q]}
                        tempComp.DriverName = driver.Name;
                 
                        for (var i = 0; i < driverComplianceNames.length; i++) {
                            if (tempComp.tempName?.ID === driverComplianceNames[i].ID) {
                                var tempDate = new Date();
                                driver[driverComplianceNames[i].ID + 'ExpDate'] = new Date(tempComp.ExpDate);
                                if (new Date(tempComp.ExpDate) < tempDate) {
                                    driver[driverComplianceNames[i].ID + 'background'] = { 'backgroundColor': 'rgb(255, 143, 115)' };
                                    driver[driverComplianceNames[i].ID + 'status'] = 'Expired';
                           
                                }
                                else {
                                    tempDate.setDate(tempDate.getDate() + 30);
                                    if (new Date(tempComp.ExpDate) < tempDate) {
                                        driver[driverComplianceNames[i].ID + 'status'] = 'Upcoming';
                                        driver[driverComplianceNames[i].ID + 'background'] = { 'backgroundColor': 'rgb(255, 227, 128)' };
                                    }
                                    else {
                                        driver[driverComplianceNames[i].ID + 'status'] = 'OK';
                                        driver[driverComplianceNames[i].ID + 'background'] = { 'backgroundColor': 'rgb(0, 199, 230)' };
                                    }
                                }
                            }
                        }
          

                        for (var j = 0; j < complianceTable.Columns.length; j++) {
                        
                            if (tempComp.tempName?.ID === complianceTable.Columns[j].ID) {

                                driver.compMissing--;
                                driver[complianceTable.Columns[j].field] = new Date(tempComp.ExpDate);
                                if (driver[complianceTable.Columns[j].status] === 'Expired')driver.compExpired++;
                                else if (driver[complianceTable.Columns[j].status] === 'Upcoming') driver.compUpcoming++;
                                
                            }
                        }
                    }
                
                
                    if (driver.compMissing > 0) driver.hasMissing = true;
                    if (driver.compUpcoming > 0) driver.hasUpcoming = true;
                    if (driver.compExpired > 0) driver.hasExpired = true;
               
                    complianceList.push(driver);
        
            }
        }
        complianceList.timestamp='';
        setFullComplianceTableList([...complianceList]);
        setComplianceTableList([...complianceList]);
   
    }
    const filterTrucks = () =>{
        let complianceList = [];
        const truckCompliances  = compliances ? compliances.filter(compliance => compliance.Type==='Truck') : [];
        for (var k = 0; k < trucks.length; k++) {
            if (trucks[k].Name !== 'No Truck') {
                var truck = {...trucks[k]};
                truck.compExpired = 0;
                truck.compMissing = 0;
                truck.compUpcoming = 0;
                truck.isShowing = true;

                for (var j = 0; j < complianceTable.Columns.length; j++) {
                    if (complianceTable.Columns[j].field !== 'Name' && complianceTable.Columns[j].field !== 'SSN' && complianceTable.Columns[j].field !== 'DriversLicense' && complianceTable.Columns[j].field !== 'Address' && complianceTable.Columns[j].field !== 'Phone' && complianceTable.Columns[j].field !== 'HiredDate' && complianceTable.Columns[j].field !== 'BirthDate' && complianceTable.Columns[j].field !== 'DriverLicense') {
                        truck[complianceTable.Columns[j].status] = 'Missing';
                        truck.compMissing++;                    
                    }
                }
                truck.Compliances=[];
                for(var q=0; q<truckCompliances.length; q++)if(truckCompliances[q].ParentID===truck.ID) truck.Compliances.push(truckCompliances[q])
                
        
             
                for (var q = 0; q < truck.Compliances.length; q++) {
                    var tempComp = {...truck.Compliances[q]}
                    tempComp.TrucksName = truck.Name;

                    for (var i = 0; i < truckComplianceNames.length; i++) {
                        if (tempComp.tempName?.ID === truckComplianceNames[i].ID) {
                            
                            var tempDate = new Date();
                            truck[truckComplianceNames[i].ID + 'ExpDate'] = new Date(tempComp.ExpDate);

                            if (new Date(tempComp.ExpDate) < tempDate) {
                                truck[truckComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(255, 143, 115)' };
                                truck[truckComplianceNames[i].ID + 'status'] = 'Expired';
                                truck.compExpired++;
                            }
                            else {
                                tempDate.setDate(tempDate.getDate() + 30);
                                if (new Date(tempComp.ExpDate) < tempDate) {
                                    truck[truckComplianceNames[i].ID + 'status'] = 'Upcoming';
                                    truck[truckComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(255, 227, 128)' };
                                    truck.compUpcoming++
                                } 
                                else {
                                    truck[truckComplianceNames[i].ID + 'status'] = 'OK';
                                    truck[truckComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(0, 199, 230)' };
                                }
                            }
                        }
                    }
                    for (var j = 0; j < complianceTable.Columns.length; j++) {
                        if (tempComp.Name === complianceTable.Columns[j].Name) {

                            /*   if (tempComp.Attachment.Name && tempComp.Attachment.url) {
                                Images.push({ url: tempComp.Attachment.url, fileType: tempComp.Attachment.fileType, compType: tempComp.Name, truckName: truck.Name });
                                var img = $("#imageTable")[0].children[0].children[.Images.length - 1].children[0].children[1];
                                if (.Images[.Images.length - 1].fileType === 'application/pdf') img.data = .Images[.Images.length - 1].url + "?#zoom=85&scrollbar=0&toolbar=0&navpanes=0";
                                else img.src = .Images[.Images.length - 1].url;
                            }*/
                            truck.compMissing--;
                        
                            truck[complianceTable.Columns[j].field] = tempComp.ExpDate;
                            if (truck[complianceTable.Columns[j].status] === 'Expired')truck.compExpired++;
                            else if (truck[complianceTable.Columns[j].status] === 'Upcoming') truck.compUpcoming++;
                            
                        }
                    }

                }
                if (truck.compMissing > 0) truck.hasMissing = true;
                if (truck.compUpcoming > 0) truck.hasUpcoming = true;
                if (truck.compExpired > 0) truck.hasExpired = true;
                complianceList.push(truck);
                
            }

        }
        setFullComplianceTableList([...complianceList]);
        setComplianceTableList([...complianceList]);
        
    }
    const filterTrailers = () =>{
        let complianceList = [];
        const trailerCompliances  = compliances ? compliances.filter(compliance => compliance.Type==='Trailer') : [];
        for (var k = 0; k < trailers.length; k++) {
            if (trailers[k].Name !== 'No Trailer') {
                var trailer = {...trailers[k]};
                trailer.compExpired = 0;
                trailer.compMissing = 0;
                trailer.compUpcoming = 0;
                trailer.isShowing = true;

                for (var j = 0; j < complianceTable.Columns.length; j++) {
                    if (complianceTable.Columns[j].field !== 'Name' && complianceTable.Columns[j].field !== 'SSN' && complianceTable.Columns[j].field !== 'DriversLicense' && complianceTable.Columns[j].field !== 'Address' && complianceTable.Columns[j].field !== 'Phone' && complianceTable.Columns[j].field !== 'HiredDate' && complianceTable.Columns[j].field !== 'BirthDate' && complianceTable.Columns[j].field !== 'DriverLicense') {
                        trailer[complianceTable.Columns[j].status] = 'Missing';
                        trailer.compMissing++;                    
                    }
                }
                trailer.Compliances=[];
             
                for(var q=0; q<trailerCompliances.length; q++)  if(trailerCompliances[q].ParentID===trailer.ID) trailer.Compliances.push(trailerCompliances[q])
                    
             
                for (var q = 0; q < trailer.Compliances.length; q++) {
                    var tempComp = {...trailer.Compliances[q]}
                    tempComp.TrailersName = trailer.Name;

                    for (var i = 0; i < trailerComplianceNames.length; i++) {
                        if (tempComp.tempName?.ID === trailerComplianceNames[i].ID) {
                            
                            var tempDate = new Date();
                            trailer[trailerComplianceNames[i].ID + 'ExpDate'] = new Date(tempComp.ExpDate);

                            if (new Date(tempComp.ExpDate) < tempDate) {
                                trailer[trailerComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(255, 143, 115)' };
                                trailer[trailerComplianceNames[i].ID + 'status'] = 'Expired';
                                trailer.compExpired++;
                            }
                            else {
                                tempDate.setDate(tempDate.getDate() + 30);
                                if (new Date(tempComp.ExpDate) < tempDate) {
                                    trailer[trailerComplianceNames[i].ID + 'status'] = 'Upcoming';
                                    trailer[trailerComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(255, 227, 128)' };
                                    trailer.compUpcoming++
                                } 
                                else {
                                    trailer[trailerComplianceNames[i].ID + 'status'] = 'OK';
                                    trailer[trailerComplianceNames[i].ID + 'background'] = { backgroundColor: 'rgb(0, 199, 230)' };
                                }
                            }
                        }
                    }
                    for (var j = 0; j < complianceTable.Columns.length; j++) {
                        if (tempComp.tempName?.ID  === complianceTable.Columns[j].ID) {

                  
                            trailer.compMissing--;
                        
                            trailer[complianceTable.Columns[j].field] = tempComp.ExpDate;
                            if (trailer[complianceTable.Columns[j].status] === 'Expired')trailer.compExpired++;
                            else if (trailer[complianceTable.Columns[j].status] === 'Upcoming') trailer.compUpcoming++;
                            
                        }
                    }

                }
                if (trailer.compMissing > 0) trailer.hasMissing = true;
                if (trailer.compUpcoming > 0) trailer.hasUpcoming = true;
                if (trailer.compExpired > 0) trailer.hasExpired = true;
                complianceList.push(trailer);
                
            }

        }
        setFullComplianceTableList([...complianceList]);
        setComplianceTableList([...complianceList]);
        
    }
    const filterOutsideDrivers = () =>{
  
        let complianceList=[];
    
        const driverCompliances  = compliances ? compliances.filter(compliance => compliance.Type==='Driver') : [];
        for (var k = 0; k <subhaulers.length; k++) {
            var driver ={...subhaulers[k]} 
            if(driver.Status==='Active'){
                if(driver.Address)driver.fullAddress=driver.Address;else driver.fullAddress='';
                if(driver.City)driver.fullAddress+=', ' + driver.City;
                if(driver.State)driver.fullAddress+=', ' + driver.State;
                if(driver.ZipCode)driver.fullAddress+=' ' + driver.ZipCode;
                driver.compExpired = 0;
                driver.compMissing = 0;
                driver.compUpcoming = 0;
            
                let foundCompCount = 1;
                var extraDrivers =[];
                driver.Compliances=[];
                for(var d=0; d<driverCompliances.length; d++)if(driverCompliances[d].ParentID===driver.Driver.ID) driver.Compliances.push(driverCompliances[d])
                if(driver.Compliances.length>0){
                    for (var j = 0; j < complianceTable.Columns.length; j++) {
                        console.log('complianceTable.Columns[j] = ' , complianceTable.Columns[j])
                        if (complianceTable.Columns[j].field!== 'Name' && !complianceTable.Columns[j].field.includes('Info') && complianceTable.Columns[j].field !== 'DriverName' && complianceTable.Columns[j].field !== 'SSN' && complianceTable.Columns[j].field !== 'DriversLicense'  && complianceTable.Columns[j].field !== 'Address' && complianceTable.Columns[j].field !== 'Phone' && complianceTable.Columns[j].field !== 'HiredDate' && complianceTable.Columns[j].field !== 'BirthDate' && complianceTable.Columns[j].field !== 'DriverLicense') {
                            driver[complianceTable.Columns[j].status] = 'Missing';
                            driver[complianceTable.Columns[j].ID + 'ExpDate']='';
                            driver[complianceTable.Columns[j].Name]='';
                            driver[complianceTable.Columns[j].field + 'ExpDate']='';
                            driver.compMissing++;
        
                        }
                    }  

                    driver.isShowing = true;
                    var rawDriver = {...driver};
                    let foundComplianceNames=[];   
                    console.log('and  complianceTable.Columns = '  , complianceTable.Columns)
                    console.log('adriver.Compliances.length  = '  , driver.Compliances)
                    for (var q = 0; q < driver.Compliances.length; q++) {
                        let tempComp = {...driver.Compliances[q]}
                        tempComp.DriverName = driver.Name;  
                        tempComp.Name = tempComp.Name ? tempComp.Name : tempComp.tempName.Name;   
                        let foundComplianceName = false;
                    //    console.log('tempComp. = ',tempComp);
                        for(var n=0; n<foundComplianceNames.length; n++){
                            if (tempComp.Name === foundComplianceNames[n]){
                                foundCompCount++;
                                foundComplianceName = true;
                                console.log('found dpulicate compliance for name = ' + tempComp.Name  + ' and driver = ' + driver.DriverName);
                            } 
                        }
                        if(!foundComplianceName){
                            foundComplianceNames.push(tempComp.Name);
                            console.log('wee addin to found comp names and lentght = ' + foundComplianceNames.length + ' and wee added tempname= ' + tempComp.Name);

                        }
                        
                        for (var j = 0; j < complianceTable.Columns.length; j++) {
                                //    console.log(' complianceTable.Columns[j]' , complianceTable.Columns[j]);
                             console.log('complianceTable.Columns[j].field = ' +  complianceTable.Columns[j].Name+ ' and tempComp.Name = ' +tempComp.Name);
                             complianceTable.Columns[j].Name = complianceTable.Columns[j].Name ? complianceTable.Columns[j].Name : complianceTable.Columns[j].title;
                            if (tempComp.Name===complianceTable.Columns[j].Name && complianceTable.Columns[j].field!== 'Name' && !complianceTable.Columns[j].field.includes('Info') && complianceTable.Columns[j].field!== 'SSN' && complianceTable.Columns[j].field!== 'DriverName' ) {
                                console.log(' found a matching column and field complianceTable.Columns[j].field = ' +  complianceTable.Columns[j].Name+ ' and tempComp.Name = ' +tempComp.Name);
                                var tempDate = new Date();
                            
                                var driverTwo = {...rawDriver};
                                driverTwo.DriverName += ' - '+  foundCompCount.toString();
                                let sortName = complianceTable.Columns[j].Name.replace(/'/g, "");
                                sortName = sortName.replace(/ /g, "");
                                sortName = sortName.replace(/-/g, "");
                                console.log('for comp  = ' + complianceTable.Columns[j].Name)
                                console.log('sort name  = ' + sortName)
                                if(foundComplianceName){
                                    driverTwo.compExpired = 0;
                                    driverTwo.compMissing = 0;
                                    driverTwo.compUpcoming = 0;
                           
                                    driverTwo[complianceTable.Columns[j].field + 'ExpDate']= new Date(tempComp.ExpDate);
                                    driverTwo[complianceTable.Columns[j].ID + 'ExpDate'] = new Date(tempComp.ExpDate);
                                    driverTwo[complianceTable.Columns[j].ID + 'Info'] = tempComp.Info;
                                    driverTwo.sortName=sortName;
                                    driverTwo[sortName]=  formatDate(new Date(tempComp.ExpDate), '/', 'YYYY/MM/DD');
                                    if ( formatDate(new Date(tempComp.ExpDate), '/', 'YYYY/MM/DD') < formatDate(new Date(tempDate), '/', 'YYYY/MM/DD')) {
                                        driverTwo[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(255, 143, 115)' };
                                        driverTwo[complianceTable.Columns[j].ID + 'status'] = 'Expired';
                                        driverTwo[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(255, 143, 115)' };
                                        driverTwo[complianceTable.Columns[j].ID + 'infostatus'] = 'Expired';
                                        driverTwo.compExpired++;
                                    }
                                    else {
                                        tempDate.setDate(tempDate.getDate() + 30);
                                        if (new Date(tempComp.ExpDate) < tempDate) {
                                            driverTwo[complianceTable.Columns[j].ID + 'infostatus'] = 'Upcoming';
                                            driverTwo[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(255, 227, 128)' };
                                            driverTwo[complianceTable.Columns[j].ID + 'status'] = 'Upcoming';
                                            driverTwo[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(255, 227, 128)' };
                                            driverTwo.compUpcoming++;
                                        
                                        }
                                        else {
                                        
                                            driverTwo[complianceTable.Columns[j].ID + 'infostatus'] = 'OK';
                                            driverTwo[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(0, 199, 230)' };
                                            driverTwo[complianceTable.Columns[j].ID + 'status'] = 'OK';
                                            driverTwo[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(0, 199, 230)' };
                                        }
                                    }
                                    if (driverTwo.compMissing > 0) driverTwo.hasMissing = true;
                                    if (driverTwo.compUpcoming > 0) driverTwo.hasUpcoming = true;
                                    if (driverTwo.compExpired > 0) driverTwo.hasExpired = true;
                                    if (driver.Status=='Active') extraDrivers.push(driverTwo);
                                }else{
                                   
                                    driver[complianceTable.Columns[j].field + 'ExpDate']= new Date(tempComp.ExpDate);
                                    driver[complianceTable.Columns[j].ID + 'ExpDate'] = new Date(tempComp.ExpDate);
                                    driver[sortName]= formatDate(new Date(tempComp.ExpDate), '/', 'YYYY/MM/DD'); 
                                    driver[complianceTable.Columns[j].ID + 'Info'] = tempComp.Info;
                                    driver.sortName=sortName;
                                    if (formatDate(new Date(tempComp.ExpDate), '/', 'YYYY/MM/DD') < formatDate(new Date(tempDate), '/', 'YYYY/MM/DD')) {
                                        driver[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(255, 143, 115)' };
                                        driver[complianceTable.Columns[j].ID + 'status'] = 'Expired';
                                        driver[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(255, 143, 115)' };
                                        driver[complianceTable.Columns[j].ID + 'infostatus'] = 'Expired';
                                        driver.compMissing--;
                                        driver.compExpired++;
                                    }
                                    else {
                                        tempDate.setDate(tempDate.getDate() + 30);
                                        if (new Date(tempComp.ExpDate) < tempDate) {
                                            driver.compUpcoming++;
                                            driver.compMissing--;

                                            driver[complianceTable.Columns[j].ID + 'infostatus'] = 'Upcoming';
                                            driver[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(255, 227, 128)' };
                                            driver[complianceTable.Columns[j].ID + 'status'] = 'Upcoming';
                                            driver[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(255, 227, 128)' };
                                        }
                                        else {
                                            driver.compMissing--;

                                            driver[complianceTable.Columns[j].ID + 'infostatus'] = 'OK';
                                            driver[complianceTable.Columns[j].ID + 'infobackground'] = { 'backgroundColor': 'rgb(0, 199, 230)' };
                                            driver[complianceTable.Columns[j].ID + 'status'] = 'OK';
                                            driver[complianceTable.Columns[j].ID + 'background'] = { 'backgroundColor': 'rgb(0, 199, 230)' };
                                        }
                                    }
                                }
                            }
                                
                        }
            
            
                    }
                }else{ 
                    driver.isShowing = false;
                    for (var j = 0; j < complianceTable.Columns.length; j++) {
                        if (complianceTable.Columns[j].field!= 'Name' && !complianceTable.Columns[j].field.includes('Info') && complianceTable.Columns[j].field != 'DriverName' && complianceTable.Columns[j].field != 'SSN' && complianceTable.Columns[j].field != 'DriversLicense'  && complianceTable.Columns[j].field != 'Address' && complianceTable.Columns[j].field != 'Phone' && complianceTable.Columns[j].field != 'HiredDate' && complianceTable.Columns[j].field != 'BirthDate' && complianceTable.Columns[j].field != 'DriverLicense') {
                            driver[complianceTable.Columns[j].status] = 'Missing';
                            driver[complianceTable.Columns[j].ID + 'ExpDate']='';
                            driver.compMissing++;

                        }
                    }  
                }
                if (driver.compMissing > 0) driver.hasMissing = true;
                if (driver.compUpcoming > 0) driver.hasUpcoming = true;
                if (driver.compExpired > 0) driver.hasExpired = true;
                if (driver.Status=='Active' &&  driver.Compliances.length>0) complianceList.push(driver);
                console.log('extraDrivers.lengtht = ' ,extraDrivers.length);
                if(extraDrivers.length>0)for(var e=0; e<extraDrivers.length; e++)complianceList.push(extraDrivers[e]);
            
        
            }
        }
        setFullComplianceTableList([...complianceList]);
        setComplianceTableList([...complianceList]);
     
    }
    const toggleExpired = () => {
        setShowExpired(!showExpired);
        setShowUpcoming(false);
        setShowMissing(false);
    };

    const toggleUpcoming = () => {
        setShowUpcoming(!showUpcoming);
        setShowExpired(false);
        setShowMissing(false);
    };

    const toggleMissing = () => {
        setShowMissing(!showMissing);
        setShowExpired(false);
        setShowUpcoming(false);
    };
    const customizeTable = () =>{
        if(compType==='Trailer')setTrailerFields();
        if(compType==='Truck')  setTruckFields();
        if(compType==='Driver') setDriverFields ();
        if(compType==='OutsideDriver') setOutsideDriverFields();
        setShowSelectFields(true);
    }
    const printReport = ()=> {

        let reportDoc = {
             content: [],
             pageMargins: [20, 35, 20, 0],
             pageOrientation: 'landscape',
             fontSize: 9,
         
             styles: {
                 icon: { font: 'icons' },
                 header: {
                     fontSize: 18,
                     bold: true,
                     margin: [0, 0, 0, 0]
                 },
         
                 tableExample: {
                     margin: [0, 5, 0, 5]
                 },
                 tableHeader: {
                     bold: true,
                     fontSize: 13,
                     color: 'black'
                 },
                 smallText: {
                     margin: [0, 10, 0, 0],
                     fontSize: 9,
                 },
                 tableFont: {
                     fontSize: 9,
                     alignment: 'center',
                     margin: [-2, 10, -2, 0]
                 },
                 signatureTableCell: {
                     fontSize: 7,
                     border: [false, false, false, false]
                 },
                 headerField: {
                     fontSize: 10,
                     margin: [60, 0, 0, 0],
                     bold: true
                 },
                 pageLetterText: {
                     fontSize: 8,
                     margin: [35, 5, 0, 0]
         
                 },
                 pageNumberText: {
                     fontSize: 8,
                     margin: [10, 15, 15, 0]
         
                 },
         
             }
         };
     
         let pageHeader={text:compType, fontSize:13, bold:true, alignment:'center'};
         reportDoc.content.push(pageHeader);
     
         console.log('real complianceTable = ', complianceTable)
      
        
             let dataTable = { width:700, margin: [0, 20, 0, 0],  fontSize: 7, table:{ dontBreakRows: true,keepWithHeaderRows:15, width:700, headerRows: 2, widths: [], alignment: 'center', body: [] } }
             let headerRow = [];
             let driverHeader=[{text:compType, fontSize:11, bold:true, colSpan:0}];
             let headerCount=0;
             console.log('real complianceTablesecond tiem = ', complianceTable.Columns)
             for ( let q = 0; q < complianceTable.Columns.length; q++) {
                 var headerObj = { fontSize: 10, bold: true, text: complianceTable.Columns[q].title };
                 headerRow.push(headerObj);
                 driverHeader[0].colSpan++;
                 if(headerCount!=0)driverHeader.push({});
                 headerCount++;
                 dataTable.table.widths.push('*')
             }
             console.log('headerRow = ', headerRow)
      
             console.log('driverHeader= ',driverHeader)
             dataTable.table.body.push(driverHeader);
             dataTable.table.body.push(headerRow);
             let actualTableTotals={};
             for ( let j = 0; j < complianceTableList.length; j++) {
                 let reportRow = [];
                 console.log(' complianceTableList j = ' ,  complianceTableList[j])
                 for ( let q = 0; q < complianceTable.Columns.length; q++) {
                     let reportRowObject = {};
                     if (complianceTable.Columns[q].Type === 'date') reportRowObject.text =  complianceTableList[j][complianceTable.Columns[q].field] ? formatDate(complianceTableList[j][complianceTable.Columns[q].field], '/', 'MM/DD/YYYY') : '';
                     else reportRowObject.text = complianceTableList[j][complianceTable.Columns[q].field];
                     reportRow.push(reportRowObject);
 
                 }
                 dataTable.table.body.push(reportRow);
             }
 
         
            
             console.log(' printCols = ' , complianceTable.Columns);
         
           
             console.log('rdataTable = ', dataTable)
             reportDoc.content.push(dataTable);
         

  
 
         console.log('reportDoc = ',reportDoc);
         pdfMake.createPdf(reportDoc).download('Report.pdf')
     }
    const dateBodyTemplate = (rowData, fieldName) => {

       if(rowData[fieldName]) return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
        else return '';
    };   

    const cardHeader = (title) => {
        return (
            <div className="p-3 border-b border-gray-200 " style={{textAlign:"center", backgroundColor:"lightGrey"}} >
                <h3 className="text-xl font-semibold text-gray-700 m-0" style={{ marginTop:"0", paddingTop:"1em", backgroundColor:"lightGrey"}}>
                    {title}
                </h3>
            </div>
        );
    };
    return (
         <div id="compliance-report" className="p-8">
            {!showSelectFields ? (<div className="mbsc-grid mbsc-justify-content-center">
                <div className="mbsc-row">
                    <Card 
                        header={cardHeader('Upcoming')}
                        className={showUpcoming ? 'border-primary mbsc-col-lg-3 ' : 'mbsc-col-lg-3 '}
                        style={{ 
                            padding:"0",
                            cursor: 'pointer',
                            transition: 'box-shadow 0.2s',
                            border: showUpcoming ? '2px solid var(--primary-color)' : '1px solid #dee2e6'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'}
                        onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
                        onClick={toggleUpcoming}
                    >
                        <div style={{ textAlign: 'center', padding: '1rem' }}>
                            <span style={{ fontSize: '1.875rem', fontWeight: 'bold', color: '#2563eb' }}>
                                {fullComplianceTableList.filter(comp => comp.hasUpcoming).length || 0}
                            </span>
                        </div>
                    </Card>

                    <Card 
                        header={cardHeader('Expired')}
                        className={showExpired ? 'border-danger mbsc-col-lg-3 mbsc-offset-lg-1' : 'mbsc-col-lg-3 mbsc-offset-lg-1'}
                        style={{ 
                            padding:"0",
                            cursor: 'pointer',
                            transition: 'box-shadow 0.2s',
                            border: showExpired ? '2px solid var(--red-500)' : '1px solid #dee2e6'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'}
                        onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
                        onClick={toggleExpired}
                    >
                        <div style={{ textAlign: 'center', padding: '1rem' }}>
                            <span style={{ fontSize: '1.875rem', fontWeight: 'bold', color: '#dc2626' }}>
                                {fullComplianceTableList.filter(comp => comp.hasExpired).length || 0}
                            </span>
                        </div>
                    </Card>

                    <Card 
                        header={cardHeader('Missing')}
                        className={showMissing ? 'border-warning mbsc-col-lg-3 mbsc-offset-lg-1' : 'mbsc-col-lg-3 mbsc-offset-lg-1'}
                        style={{ 
                            padding:"0",
                            cursor: 'pointer',
                            transition: 'box-shadow 0.2s',
                            border: showMissing ? '2px solid var(--yellow-500)' : '1px solid #dee2e6'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)'}
                        onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
                        onClick={toggleMissing}
                    >
                        <div style={{ textAlign: 'center', padding: '1rem' }}>
                            <span style={{ fontSize: '1.875rem', fontWeight: 'bold', color: '#eab308' }}>
                                {fullComplianceTableList.filter(comp => comp.hasMissing).length || 0}
                            </span>
                        </div>
                    </Card>
                </div>
       
                <div className="mbsc-row">
                    <button style={{ margin: '0', padding: '.5em', width:"12em", marginLeft:'1em'}}  onClick={(e) =>customizeTable()}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Customize Table  </button>
                    <button style={{ margin: '0', padding: '.5em', width:"12em", marginLeft:'2em'}}  onClick={(e) =>printReport()}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Print Table  </button>
                </div>
                {complianceTable && (
                    <DataTable value={complianceTableList.filter(comp => comp.isShowing) } showGridlines resizableColumns sortField="Name" sortOrder={1} 
                    style={{ paddingTop: '0em', width: '100%' }} filters={filters} filterDisplay="row" >
                        {complianceTable.Columns.map((col, index) => (
                         
                            <Column key={index} header={col.title}   pt={{root: { 'data-label': col.title }}}  field={col.field} filterField={col.field} sortable  dataType={col.Type} filter={col.Type !== 'date'} body={(rowData) => {
                                return (<div style={rowData[col.background] ? rowData[col.background]: { backgroundColor: 'white' }}>
                                    {col.Type !== 'date' ? (<span> {rowData[col.field]}</span>):(<span> {dateBodyTemplate(rowData,col.field)}</span>)} </div> );
                            }}/> 
                       ))}
                    </DataTable>
                )}
            </div>):(<CustomizeTable tableType='comp'></CustomizeTable>)} 
        </div>
    );
};

export default ComplianceReport;
