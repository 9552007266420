import React, { useEffect, useState, useRef, useCallback } from 'react';


import { db } from '../../../firebase';
import {  query,collection, onSnapshot, where } from 'firebase/firestore';
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";

import {useNavigate } from 'react-router-dom'
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button} from '@mobiscroll/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';  
import { classNames } from 'primereact/utils';

import { UserAuth } from '../../../context/AuthContext';
import { useFreightBill } from '../context/FreightBillContext';

import InputTextParent from '../../InputComponents/InputTextParent'; 
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import LoadingOverlay from '../../MiscComponents/LoadingOverlay';

import debounce from 'lodash/debounce';

import { Toast } from 'primereact/toast';

const  FreightBillSearch =(props)=> {
    const navigate = useNavigate();
    const {gearedUser, companies, formatDate } = UserAuth();
    const { setFreightBill,freightBillIDRef } = useFreightBill();
    const [searchFreightBills, setSearchFreightBills]=useState(null);
     const [isLoading, setIsLoading] = useState(false);
    const searchFreightBillsRef = useRef(null);
    const freightListenerRef = useRef(null);
    var todaysDate = new Date();
    let startDate =new Date();
    const storage = getStorage();
    const toast = useRef(null);
    let formattedTodaysDate= formatDate(todaysDate, '/', 'YYYY/MM/DD');
    startDate.setDate(todaysDate.getDate() - 30);
    const [inputValues, setInputValues]=useState({
        DPSDateValue: todaysDate,
        EndDateValue:todaysDate,
        StartDateValue:startDate,
        EndDate:formattedTodaysDate,
        FBNO:'',
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD')
        
    });
    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
          
        }
    }, [companies]);
  
    useEffect(() => {
        queryFreightBills();
    }, []);

    let sortedFreightBills = searchFreightBills ? [...searchFreightBills].sort((a, b) => {
        if (a.QueryDate > b.QueryDate) return -1;
        if (a.QueryDate < b.QueryDate) return 1;
        return 0;
    }) : [];


    const deleteStorageBucket = async (freightBill) => {
        console.log("Deleting storage for:", freightBill.ID);
        const storage = getStorage();
        const weightsRef = ref(storage, `attachments/${gearedUser.selectedOrgName}/FreightBills/${freightBill.ID}/Weights`);
    
        try {
            // Use the stored storageFiles instead of querying again
            const deletionPromises = freightBill.storageFiles.map((itemRef) => deleteObject(itemRef));
    
            await Promise.all(deletionPromises);
    
            console.log(`Deleted all contents in the Weights folder for FreightBill ID: ${freightBill.ID}`);
    
            // **Update the state to remove the button from the row**
            setSearchFreightBills((prevBills) =>
                prevBills.map((fb) =>
                    fb.ID === freightBill.ID ? { ...fb, hasStorage: false, storageFiles: [] } : fb
                )
            );
        } catch (error) {
            console.error("Error deleting Weights folder contents:", error);
        }
    };
    const deleteAllStorage = async () => {
        console.log("Starting bulk storage deletion...");
    
        // Get all FreightBills that have storage
        const billsWithStorage = searchFreightBills.filter(fb => fb.hasStorage);
    
        if (billsWithStorage.length === 0) {
            console.log("No FreightBills with storage found.");
            return;
        }
    
        try {
            // Run deleteStorageBucket for each FreightBill
            await Promise.all(billsWithStorage.map(fb => deleteStorageBucket(fb)));
    
            console.log("✅ All storage deleted successfully!");
        } catch (error) {
            console.error("❌ Error deleting all storage:", error);
        }
    };
    if (inputValues.Company)  sortedFreightBills= sortedFreightBills.filter(fb => fb.Company && fb.Company.ID === inputValues.Company.ID);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FBNO: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CustomerName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LoadSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DumpSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        Subhauler: { value: null, matchMode: FilterMatchMode.EQUALS },
        missing: { value: null, matchMode: FilterMatchMode.EQUALS },
        onHold: { value: null, matchMode: FilterMatchMode.EQUALS },
        Dedicated: { value: null, matchMode: FilterMatchMode.EQUALS }
      
  });
  const deleteStorageBodyTemplate = (rowData) => {
    if (!rowData.hasStorage) return null; // Hide button if no storage

    return (
        <button 
            type="button" 
            onClick={() => deleteStorageBucket(rowData)}
            style={{ color: 'red', border: 'none', background: 'none', cursor: 'pointer' }}
        >
            Delete Storage
        </button>
    );
};
    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
      
    }; 
        
    const queryFreightBillNumber = useCallback(async (FBNO) => {
        if(freightListenerRef.current)freightListenerRef.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        searchFreightBillsRef.current=[];
        const freightQuery = query(collection(db, queryName),  where("FBNO","==", FBNO ));
        freightListenerRef.current=onSnapshot(freightQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var tempFreight = change.doc.data();
                tempFreight.ID = change.doc.id;
                console.log('tempFreight = ', tempFreight)
                tempFreight.CustomerName = tempFreight.Account ? tempFreight.Account?.Name : '';
                tempFreight.LoadSiteName = tempFreight.LoadSite ? tempFreight.LoadSite?.Name : '';
                tempFreight.DumpSiteName = tempFreight.DumpSite ? tempFreight.DumpSite?.Name : '';
                tempFreight.realJobDate=new Date(tempFreight.JobDate);
                const foundIndex =  searchFreightBillsRef.current ? searchFreightBillsRef.current.findIndex(obj => obj.ID === tempFreight.ID) : -1;
                if (change.type === "added")  searchFreightBillsRef.current.push({...tempFreight})
                if (change.type === "modified") searchFreightBillsRef.current[foundIndex]={...tempFreight};
                if (change.type === "removed")   searchFreightBillsRef.current.splice(foundIndex,1);
            });
            console.log('SO WEVE FINISEHD OUR QUERY IDK WHATS HAPPENIG ')
            setSearchFreightBills(searchFreightBillsRef.current);
    
        });

       
    },  [inputValues] );
    const debouncedUpdateFBSearch = useCallback(debounce(queryFreightBillNumber, 1000), [queryFreightBillNumber]);


    const updateFBNOSearch = (value)=>{
   
            setInputValues((prev) => ({ ...prev,  FBNO: value }));
            if(value)  debouncedUpdateFBSearch(value);
        
    }
    const queryFreightBills = async () => {
        console.log('Running query for FreightBills...' +inputValues.StartDate + ' ' + inputValues.EndDate);
        setIsLoading(true);
    
        if (freightListenerRef.current) freightListenerRef.current();
    
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        searchFreightBillsRef.current = [];
    
        const freightQuery = query(
            collection(db, queryName),
            where("QueryDate", ">=", inputValues.StartDate),
            where("QueryDate", "<=", inputValues.EndDate)
        );
    
        freightListenerRef.current = onSnapshot(freightQuery, async (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempFreight= change.doc.data();
                tempFreight.ID = change.doc.id;
                tempFreight.CustomerName = tempFreight.Account?.Name;
                tempFreight.LoadSiteName = tempFreight.LoadSite?.Name;
                tempFreight.DumpSiteName = tempFreight.DumpSite?.Name;
                tempFreight.realJobDate = new Date(tempFreight.JobDate);
                if (change.type === "added") {
                    tempFreight.ID = change.doc.id;
                    searchFreightBillsRef.current.push(tempFreight);
                    
                }
                if (change.type === "modified") {
                    const freightIndex =    searchFreightBillsRef.current.findIndex(f=> f.ID === tempFreight.ID);
                    searchFreightBillsRef.current[freightIndex] = tempFreight;
                }
                if (change.type === "removed") {
                    const freightIndex =    searchFreightBillsRef.current.findIndex(f=> f.ID === tempFreight.ID);
                    searchFreightBillsRef.current.splice(freightIndex,1);
              
            
                }
                
            });
        
    
                // **NEW: Check Weights folder storage for this FreightBill**
               /* const weightsRef = ref(storage, `attachments/${gearedUser.selectedOrgName}/FreightBills/${tempFreight.ID}/Weights`);
                try {
                    const listResult = await listAll(weightsRef);
                    tempFreight.hasStorage = listResult.items.length > 0;
                    tempFreight.storageFiles = listResult.items; // Store files so we don't need to query again
                } catch (error) {
                    console.error("Error checking Weights storage for:", tempFreight.ID, error);
                    tempFreight.hasStorage = false;
                    tempFreight.storageFiles = [];
                }
    */
    
            // **Wait for all storage checks to complete**
         
            setIsLoading(false);
            setSearchFreightBills([... searchFreightBillsRef.current]);
        });
    };
 
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.realJobDate, '/', 'MM/DD/YYYY');
    };


    const booleanBodyTemplate = (rowData, field) => {
        console.log(`Checking ${field} for:`, rowData);
        let value = rowData[field];
        console.log(`Value is: ${value}, type: ${typeof value}`);
        
        let color = value ? 'green' : 'red';
        return (
            <i style={{color:color}}
                className={classNames('pi', {
                    'true-icon pi-check-circle': value,
                    'false-icon pi-times-circle': !value
                })}
            />
        );
    };
    

      const booleanFilterTemplate = (options) => {
        return (
          <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
            value={options.value !== null ? options.value : undefined}
            onChange={(e) => options.filterApplyCallback(e.value)}
          />
        );
      };
    

    const editBodyTemplate = (rowData) => {
        return (
            <button type="button" onClick={() => handleEdit(rowData)}>
                <FontAwesomeIcon icon={faEdit} />
            </button>
        );
    };
    const handleEdit = (rowData) => {
      
        var win = window.open('/freightbill/freightbill/'+rowData.ID+'/'+rowData.dispatchID, '_blank');
        win.focus();
  
    
     };
    //const handleEdit = (rowData) => {
      //  console.log("Edit button clicked for:", rowData);
      //  setFreightBill(rowData);
      
      //  freightBillIDRef.current=rowData.ID;
      //  navigate('/freightbill/freightbill/'+rowData.ID+'/'+rowData.dispatchID)
       // showAccountPopUp(rowData)
        // Implement your edit functionality here
   // };
    
    const renderHeader = () => (
        <div className="mbsc-grid mbsc-row">
            <span className="mbsc-col-xl-1">Freight Bills</span>
            <div className="mbsc-col-xl-3">
                <AutoCompleteInput 
                    label="Company" 
                    fieldName="Company" 
                    field="CompanyName" 
                    value={inputValues.Company} 
                    labelClass='p-inputgroup-addon search-auto-input' 
                    suggestions={companies} 
                    setValue={setInputValues} 
                    handleFieldChange={changeCompany} 
                />
            </div>
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar 
                        value={inputValues.StartDateValue} 
                        onChange={(e) => handleDateChange('StartDate', e.value)} 
                    />
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">End Date:</span>
                    <Calendar 
                        value={inputValues.EndDateValue} 
                        onChange={(e) => handleDateChange('EndDate', e.value)} 
                    />
                </div>
            </div>
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Freight Bill #:</span>
                    <InputTextParent 
                        value={inputValues.FBNO} 
                        style={{width:"100%"}} 
                        onChange={(e) => updateFBNOSearch(e.target.value)} 
                    />
                </div>
                <Button 
                    color="primary" 
                    onClick={() => queryFreightBills()} 
                    style={{
                        margin:"0", 
                        height:"50%", 
                        float:"right", 
                        width:"45%", 
                        marginTop:".5em", 
                        marginLeft:".5em", 
                        paddingLeft:"1em !important", 
                        paddingBottom: ".2em", 
                        paddingTop: ".2em"
                    }}
                >
                    Search Freight Bills
                </Button>
            </div>
        </div>
    );

    console.log('First FreightBill:', sortedFreightBills[0]); // Check data structure
    console.log('Current Filters:', filters); // Check filter state

    return (
        <div className="card">
            <Toast ref={toast} />
            <DataTable 
                value={sortedFreightBills} 
                paginator 
                rows={25} 
                dataKey="ID" 
                filters={filters} 
                header={renderHeader()} 
                filterDisplay="row" 
                emptyMessage="No Freight Bills found."
            >
                <Column 
                    pt={{root: { 'data-label': 'Edit' }}}
                    header="Edit" 
                    body={editBodyTemplate}
                />
                <Column 
                    style={{maxWidth:'14em'}} 
                    field="driverName" 
                    header="Name" 
                    filter 
                    filterPlaceholder="Search by name" 
                />
                <Column 
                    style={{maxWidth:'8em'}} 
                    field="FBNO" 
                    filterMenuClassName="filterHider" 
                    header="FB #" 
                    filter 
                    filterPlaceholder="Search" 
                />
                <Column 
                    field="CustomerName" 
                    header="Customer" 
                    filter 
                    filterPlaceholder="Search Customer" 
                />
                <Column 
                    field="LoadSiteName" 
                    header="Load Site" 
                    filter 
                    filterPlaceholder="Search Load Site" 
                />
                <Column 
                    field="DumpSiteName" 
                    header="Dump Site" 
                    filter 
                    filterPlaceholder="Search Dump Site" 
                />
                <Column 
                    style={{minWidth:'7em'}} 
                    field="realJobDate" 
                    header="Job Date" 
                    dataType="date" 
                    sortable 
                    body={dateBodyTemplate} 
                    filterPlaceholder="Search by Date" 
                />
                   <Column 
                    field="Subhauler" 
                    header="Subhauler" 
                    dataType="boolean" 
                    style={{ minWidth: '4rem' }} 
                    body={(rowData) => booleanBodyTemplate(rowData, 'Subhauler')} 
                    filter 
                    filterElement={booleanFilterTemplate}
                />
                <Column 
                    field="missing" 
                    header="Missing" 
                    dataType="boolean" 
                    style={{ minWidth: '4rem' }} 
                    body={(rowData) => booleanBodyTemplate(rowData, 'missing')} 
                    filter 
                    filterElement={booleanFilterTemplate} 
                />
                <Column 
                    field="onHold" 
                    header="On Hold" 
                    dataType="boolean" 
                    style={{ minWidth: '4rem' }} 
                    body={(rowData) => booleanBodyTemplate(rowData, 'onHold')} 
                    filter 
                    filterElement={booleanFilterTemplate}
                />
            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
};
    
    export default FreightBillSearch;