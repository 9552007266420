import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import InputTextParent from '../InputComponents/InputTextParent'; 
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faLink } from '@fortawesome/free-solid-svg-icons';
import {  useNavigate } from 'react-router-dom'
import { usePrevailingWage } from './context/PrevailingWageContext';
const PrevailingWageHome = (props) => {

    const { formatDate } = UserAuth();
    const {   setPrevailingWageReport, prevailingWageReports, queryPrevailingWageReports} = usePrevailingWage();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const navigate = useNavigate()
    const sortedReports = prevailingWageReports?.sort((a, b) => new Date(b.QueryDate) - new Date(a.QueryDate));
    console.log('sortedReports = ', sortedReports)
    useEffect(()=>{
    
       if(prevailingWageReports===null) queryPrevailingWageReports();
    },[])
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
		'ProjectName':{ value: null, matchMode: FilterMatchMode.CONTAINS },
		'ProjectID':{ value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({ ...prevFilters, global: { ...prevFilters.global, value }   }));
        setGlobalFilterValue(value);
    };


    const renderHeader = () => (
        <div className="flex justify-content-end mbsc-row ">
            <span>Prevailing Wage Jobs</span>
            <IconField iconPosition="left" className="homeSearch">
                <InputIcon className="pi pi-search" />
                <InputTextParent style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
    );
    const dateBodyTemplate = (rowData, dateName) => {
        console.log('date = ', dateName)
        console.log('rowData = ',rowData)
        if(rowData[dateName]==='')return '';
        return formatDate(rowData[dateName], '/', 'MM/DD/YYYY');
    };   
    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );
	const openLinkBodyTemplate = (rowData)=>{
		return <button type="button" onClick={() => handleOpenJob(rowData)}>
            <FontAwesomeIcon icon={faLink} />
        </button>
	} 
    const handleEdit = (rowData)=>{
        setPrevailingWageReport(rowData);
  
        navigate('/newprevailingwage/report/'+ rowData.ID)
        console.log('edit this ' ,rowData);
    }
	const handleOpenJob = (rowData)=>{ 
		let queryDate = formatDate(rowData.JobDate, '/', 'YYYY/MM/DD');
		const encodedDate = encodeURIComponent(queryDate); 
		const win = window.open(`/dispatch/edit/none/${rowData.ID}/${encodedDate}/JobEdit`, '_blank'); 
		win.focus();
}
    const header = renderHeader();
    return (
        <div >
  
         
        <DataTable value={sortedReports} paginator rows={25} dataKey="ID" filters={filters}  header={header} filterDisplay="row" id="prevalingWageHomelTable" emptyMessage="No Prevailing Wage Jobs found.">
            <Column  header="Open Report" body={editBodyTemplate}/>
		
            <Column header="Customer" filter filterField="Account.Name" filterPlaceholder="Search by Customer"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>
            <Column field="prevailStartDateValue" header="Start Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'prevailStartDateValue')}  />
            <Column field="prevailEndDateValue" header="End Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'prevailEndDateValue')}  />
      
            <Column filterField="ProjectName" header="ProjectName" filter   filterPlaceholder="Search by ProjectName"  body={(rowData) => rowData.ProjectName || 'N/A'}/>
            <Column filterField="ProjectID" header="Project ID" filter   filterPlaceholder="Search by  Project ID"  body={(rowData) => rowData.ProjectID || 'N/A'}/>
    
       
        
        </DataTable>
            
        </div>
   
    );
};

export default PrevailingWageHome;
