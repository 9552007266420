import { useCallback, useRef,useEffect } from 'react';

import { db } from '../../../firebase';
import {   query, collection, onSnapshot, where} from 'firebase/firestore';
import { usePayStatement } from '../context/PayStatementContext';
import { useLineItem } from '../context/PayStatementLineItemContext';
import { UserAuth } from '../../../context/AuthContext';
export const usePayStatementCreation = () => {
    const { gearedUser } = UserAuth();
    const { 
        driversRef, 
        outsideDriversRef, 
        accountsRef,
        setCreateDrivers,
        setCreateOutsideDrivers,
        setCreateAccounts,
        payStatementsRef,
        setPayStatements,
        setPayStatement,
        payStatementRef,
        filterFreightBills,
        filterExpenses,
        calcDPSTotal,
        freightBillsRef,
        expensesRef,
        
        inputValues
    } = usePayStatement();
    const { makeFreightBillLineItem, makeExpenseLineItem } = useLineItem();


    const updateDriverRefs = useCallback((createdPayStatements, activeTab) => {

        
        // Create new arrays instead of modifying existing ones
        const updatedDrivers = [...driversRef.current];
        const updatedOutsideDrivers = [...outsideDriversRef.current];
        const updatedAccounts = [...accountsRef.current];
        
        createdPayStatements.forEach(({ driver }) => {
            const updateArray = (array, id) => {
                const index = array.findIndex(d => d.ID === id);
                if (index !== -1) {
                    array[index] = { ...driver };
                }
            };

            updateArray(updatedDrivers, driver.ID);
            updateArray(updatedOutsideDrivers, driver.ID);
            updateArray(updatedAccounts, driver.ID);
        });

        // Update refs with new arrays
        driversRef.current = updatedDrivers;
        outsideDriversRef.current = updatedOutsideDrivers;
        accountsRef.current = updatedAccounts;
  
        const updateMap = {
            0: () => setCreateDrivers([...updatedDrivers.filter(driv => (driv.FreightBills?.length>0 || driv.Expenses?.length>0))]),
            1: () => setCreateOutsideDrivers([...updatedOutsideDrivers.filter(driv =>  (driv.FreightBills?.length>0 || driv.Expenses?.length>0) )]),
            2: () => setCreateAccounts([...updatedAccounts.filter(driv => (driv.FreightBills?.length>0 || driv.Expenses?.length>0) )])
        };

        const updateFn = updateMap[activeTab];
        if (updateFn) {
            updateFn();
        }
    }, []);

    const freightListenerRef = useRef(null);
    const expenseListenerRef = useRef(null);
    useEffect(() => {
        console.log('expenseListenerRef  changed:', expenseListenerRef );
        return () => {
            console.log('Component unmounting, expenseListenerRef :', expenseListenerRef );
        };
    }, [expenseListenerRef ?.current]);
    const queryFreightBills = () => {
        console.log('we runnig Query Fbs ')
        freightBillsRef.current=[]; 
        console.log('we are running the queryFreightBills and does freightListenerRef.current = ', freightListenerRef.current)
        if(freightListenerRef.current){
            console.log('WE ARE TRYI8NG TO UNSUBSCRIBE', freightListenerRef.current)
            freightListenerRef.current(); 
        }
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        const freightQuery = query(collection(db, queryName), where("paid", "==",false), where("QueryDate",">=","2024/08/01" ), where("QueryDate","<=",inputValues.EndDate ));
        freightListenerRef.current=onSnapshot(freightQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                var tempFreight = change.doc.data();
                tempFreight.ID = change.doc.id;
  
                if (change.type === "added") {
                
                    if (tempFreight.missing) tempFreight.onHold = true;
                    if(tempFreight.QueryDate>"2024/08/01") freightBillsRef.current.push({...tempFreight})
                }
                if (change.type === "modified") {
                    const foundIndex =  payStatementsRef.current.findIndex(obj => obj.driverID=== tempFreight.Driver);
                    const foundDriverIndex =  driversRef.current ? driversRef.current.findIndex(obj => obj.ID === tempFreight.Driver) : -1;
                    const foundOutsideDriverIndex =  outsideDriversRef.current ? outsideDriversRef.current.findIndex(obj => obj.ID === tempFreight.Driver) : -1;
                    console.log('found Index = ' + foundIndex)
                    let tempDPS;
                    if(foundIndex!==-1){
                        for (var i = 0; i < payStatementsRef.current[foundIndex].FreightBills.length; i++) {
                            if (tempFreight.ID === payStatementsRef.current[foundIndex].FreightBills[i].ID) {
                                payStatementsRef.current[foundIndex].FreightBills[i] = tempFreight;
                                for (var j = 0; j < payStatementsRef.current[foundIndex].LineItems.length; j++) {
                                    if (payStatementsRef.current[foundIndex].LineItems[j].FreightID === tempFreight.ID && payStatementsRef.current[foundIndex].LineItems[j].Type!=='Expense') {
                                        payStatementsRef.current[foundIndex].LineItems.splice(j, 1);
                                        j--
                                    }
                                }
                    
                                makeFreightBillLineItem(payStatementsRef.current[foundIndex], tempFreight );
                                tempDPS = calcDPSTotal(payStatementsRef.current[foundIndex]);
                                payStatementsRef.current[foundIndex]  =  tempDPS;
                                setPayStatements([...payStatementsRef.current])
                                setPayStatement({...payStatementsRef.current[foundIndex]})
                                payStatementRef.current={...payStatementsRef.current[foundIndex]}
                                
                            }
                        }
                        if(tempDPS){
                            if(foundDriverIndex!==-1){
                                console.log('tempDPS = ', tempDPS)
                                console.log('oundDriverIndex= '+ foundDriverIndex)
                                driversRef.current[foundDriverIndex].DPS = {...tempDPS}
                                driversRef.current[foundDriverIndex].Total=tempDPS.Total.Total;
                            }
                            if(foundOutsideDriverIndex!==-1){
                                console.log('hmmm waht happened here =')
                                outsideDriversRef.current[foundOutsideDriverIndex].DPS = {...tempDPS}
                                outsideDriversRef.current[foundOutsideDriverIndex].Total=tempDPS.Total.Total;
                            }
                        }
                    }
                }
            });
            console.log('SO WEVE FINISEHD OUR QUERY IDK WHATS HAPPENIG ')
            filterFreightBills();
    
        });
    }
    const queryExpenses = () => {
        expensesRef.current=[];
        console.log('we are running the queryExpenses and does xpenseListenerRef.current = ', expenseListenerRef.current)
        if(expenseListenerRef.current){
            console.log('WE ARE TRYI8NG TO UNSUBSCRIBE', expenseListenerRef.current)
            expenseListenerRef.current();
        }
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
        const freightQuery = query(collection(db, queryName),  where("paid", "==",false), where("QueryDate","<=",inputValues.EndDate ));
        expenseListenerRef.current= onSnapshot(freightQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                var tempExpense = change.doc.data();
                tempExpense.ID = change.doc.id; 
               // console.log('tempExpense in the queryExpenses = ', tempExpense)
            
                if (change.type === "added" || change.type === "modified") {
                    if(tempExpense.missing) tempExpense.onHold = true;
                    if(tempExpense.QueryDate>"2024/08/01") expensesRef.current.push({...tempExpense})
                    for(let q=0;q<payStatementsRef?.current?.length; q++){
                        let foundExpense = false;
                        for (var i = 0; i < payStatementsRef?.current[q]?.Expenses?.length; i++) {
                            if (tempExpense.ID === payStatementsRef?.current[q]?.Expenses[i]?.ID) {
                                if( tempExpense.pay && tempExpense.PayTo.ID===payStatementsRef?.current[q]?.expenseDriverID){
                                    foundExpense = true;
                                    for (var j = 0; j < payStatementsRef.current[q].LineItems.length; j++) {
                                        if (payStatementsRef.current[q].LineItems[j].ID === tempExpense.ID) {
                                         if(source==='Server' || !tempExpense.isEditable)   payStatementsRef.current[q].LineItems[j]=makeExpenseLineItem(tempExpense);
                                        }
                                    }
                                    console.log('payStatementsRef.current[q].LineItems = ', payStatementsRef.current[q].LineItems)
                                    payStatementsRef.current[q]  = calcDPSTotal(payStatementsRef.current[q]);
            
                                    if(payStatementRef?.current?.ID===payStatementsRef?.current[q]?.ID){
                                        setPayStatement({...payStatementsRef.current[q]})
                                        payStatementRef.current={...payStatementsRef.current[q]}
                                    } 
                                    setPayStatements([...payStatementsRef.current]) 
                                }  else{
                                    payStatementsRef.current[q].Expenses.splice(i,1);
                                    for (var j = 0; j < payStatementsRef.current[q].LineItems.length; j++) {
                                        if (payStatementsRef.current[q].LineItems[j].ID === tempExpense.ID) {
                                            payStatementsRef.current[q].LineItems.splice(j, 1);
                                            j--;
                                        }
                                    }
                                }  
                            }     
                     
                        }
                 
                        if(foundExpense===false && tempExpense.pay && tempExpense.PayTo.ID===payStatementsRef?.current[q]?.expenseDriverID){
               
                            payStatementsRef.current[q].Expenses.push({...tempExpense})
                            payStatementsRef.current[q].LineItems.push(makeExpenseLineItem(tempExpense));
                            payStatementsRef.current[q]  = calcDPSTotal(payStatementsRef.current[q]);

                            if(payStatementRef?.current?.ID===payStatementsRef?.current[q]?.ID){
                                setPayStatement({...payStatementsRef.current[q]})
                                payStatementRef.current={...payStatementsRef.current[q]}
                            } 
                            setPayStatements([...payStatementsRef.current])       
                        }
                    
                    }
                       // console.log('paystatmesnte look like this  AFTER=', payStatementsRef.current)
                }
                if (change.type === "removed") {
                    for(let q=0;q<payStatementsRef.current.length; q++){
                        for (var i = 0; i < payStatementsRef.current[q].Expenses.length; i++) {
                            if (tempExpense.ID === payStatementsRef.current[q].Expenses[i].ID) {

                                payStatementsRef.current[q].Expenses.splice(i,1);
                                for (var j = 0; j < payStatementsRef.current[q].LineItems.length; j++) {
                                    if (payStatementsRef.current[q].LineItems[j].ID === tempExpense.ID) {
                                        payStatementsRef.current[q].LineItems.splice(j, 1);
                                        j--;
                                    }
                                }
                                payStatementsRef.current[q]  = calcDPSTotal(payStatementsRef.current[q]);
                                if(payStatementRef.current.ID===payStatementsRef.current[q].ID){
                                    setPayStatement({...payStatementsRef.current[q]})
                                    payStatementRef.current={...payStatementsRef.current[q]}
                                } 
                                setPayStatements([...payStatementsRef.current]) 
                            }   
                        }
                    }
                }
            });
       
            filterExpenses();
    
        });
    }
    return {
        queryFreightBills,
        queryExpenses,
        updateDriverRefs
    };
}; 