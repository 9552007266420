import { UserAuth } from '../../../context/AuthContext';

export const useLineItem = () => {

    const { expenseNames,addDocument, accounts, company} = UserAuth();

	function roundedMultiplication(value1, value2) {
        let value = (value1 * 100) * (value2 * 100) / (100 * 100)
        console.log(' value before the .round and nubmer epsilon ' + value)
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }
	const findAccount = (FreightBill)=>{
		for(let i=0; i<accounts.length; i++){
			if(accounts[i].ID===FreightBill.subhaulerID)return accounts[i].Name;
		}
		return '';	
	}

    const makeFreightBillLineItem =  (FreightBill, LineItems, Invoice)=> {
        let newLineItem = {};
      
        console.log('m  Invoice.LineItems.length at teh start  = '+  Invoice.LineItems.length)
        console.log('makign freight bill ine item for fb = ', FreightBill)
        if (FreightBill.ParentFreight === '') FreightBill.ParentFreight = FreightBill.ID;
        newLineItem.loadSite = FreightBill.loadSite;
        newLineItem.dumpSite = FreightBill.dumpSite;
        newLineItem.loadAddress = FreightBill.loadAddress;
        newLineItem.dumpAddress = FreightBill.dumpAddress;
        newLineItem.Description = FreightBill.loadSite + ' - ' + FreightBill.dumpSite;
        newLineItem.Type = 'Freight Bill';
        newLineItem.hideFBNO = false;
        if(!FreightBill.billedQty)newLineItem.Qty=0; else newLineItem.Qty = FreightBill.billedQty;
        newLineItem.ID=FreightBill.ID;
        newLineItem.QtyString = Number(newLineItem.Qty).toString();
		if(FreightBill.Subhauler) newLineItem.subhaulerName = findAccount(FreightBill); else newLineItem.subhaulerName = '';

		
        newLineItem.VNum = FreightBill.VNum ? FreightBill.VNum : 1;
        newLineItem.TotalWeight = FreightBill.tWeight;
        newLineItem.Rate = FreightBill.BillRate || 0;
        newLineItem.TotalString = '$' + Number(FreightBill.tBilled).toString();
        newLineItem.tWeight = FreightBill.tWeight;
        newLineItem.tHours = FreightBill.tHours;
        newLineItem.loads = FreightBill.loads;
        newLineItem.FreightID = FreightBill.ID;
    
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.missing = FreightBill.missing;
  
        newLineItem.JobDate = FreightBill.JobDate;
        newLineItem.Weights = [];
        newLineItem.FBNumber = Number(FreightBill.FBNO);
        newLineItem.tableLength = 0;


        newLineItem.Amount = Number(Number(newLineItem.Rate) * Number(newLineItem.Qty).toString());
        //newLineItem.PONumber = FreightBill.PONumber;
        newLineItem.FBNO = FreightBill.FBNO;
        if (FreightBill.QueryDate < Invoice.QueryStartDate) {
            Invoice.QueryStartDate = FreightBill.QueryDate;
            Invoice.StartDate = FreightBill.JobDate;
        }
        if (FreightBill.QueryDate > Invoice.QueryEndDate) {
            Invoice.QueryEndDate = FreightBill.QueryDate;
            Invoice.EndDate = FreightBill.JobDate;
        }
        console.log('about to set line item.truc = ' , FreightBill.Truck)
        newLineItem.truck = (FreightBill.Truck && FreightBill.Truck?.Name!==undefined && FreightBill.Truck?.Name!==null && FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ) ?  newLineItem.truck = FreightBill.Truck?.Name : FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ? newLineItem.truck = FreightBill.Truck :  newLineItem.truck ='';
        
        console.log('newLineItem.truck = ', newLineItem.truck);
        newLineItem.Driver = FreightBill.driverName;
     
        newLineItem.RateString = '$' + Number(newLineItem.Rate).toString();
        newLineItem.FreightTotal = FreightBill.bTotal;
        newLineItem.RateType = FreightBill.BillType;
        newLineItem.BillType = FreightBill.BillType;
        //newLineItem.InvoiceVNums = FreightBill.InvoiceVNums;
        newLineItem.FuelPercent = FreightBill.FuelCharge + '%';
        newLineItem.BrokerPercent = FreightBill.billedBrokerPercent + '%';
        newLineItem.background = 'background-color : transparent!important';
        newLineItem.printBackground = 'background-color : transparent!important';
        newLineItem.isFreightBill = true;


        if (FreightBill.FreightTaxable) {
            if (FreightBill.FreightTaxTotal) newLineItem.TaxTotal = FreightBill.FreightTaxTotal; else newLineItem.TaxTotal = 0;
            if (FreightBill.FreightTaxRate) Invoice.TaxRate = FreightBill.FreightTaxRate
            newLineItem.Taxable = true;
        } else {
            newLineItem.TaxTotal = 0;
            newLineItem.Taxable = false;
        }
        if (FreightBill.MaterialTaxable) {
            if (FreightBill.MaterialTaxRate) Invoice.TaxRate = FreightBill.MaterialTaxRate
        }
        if (FreightBill.billedBrokerPercent > 0) Invoice.BrokerPercent = FreightBill.billedBrokerPercent;
        console.log('FreightBill.tBilled= ' + FreightBill.tBilled)
        if (FreightBill.tBilled && FreightBill.tBilled !== '') {
           	if (FreightBill.billedBrokerPercent) newLineItem.BrokerTotal = Math.round(-1 * (FreightBill.tBilled * (FreightBill.billedBrokerPercent / 100)) * 100) / 100;
            else newLineItem.BrokerTotal = 0;
            console.log('newLineItem.BrokerTotal= ' + newLineItem.BrokerTotal)
        } else {
            newLineItem.BrokerTotal = 0;
            newLineItem.Amount = 0;
            newLineItem.Total = 0;
        }

        newLineItem.Total = Number(FreightBill.tBilled);
        newLineItem.Amount = Number(FreightBill.tBilled);

        newLineItem.BrokerTotalString = '$' + Number(newLineItem.BrokerTotal).toString();
        console.log('FreightBill.fuelBilled b4 = '+  FreightBill.fuelBilled)
        if (FreightBill.fuelBilled!== '' && FreightBill.fuelBilled ) {
            newLineItem.FuelTotal = FreightBill.fuelBilled;
            Invoice.FuelPercent = FreightBill.FuelCharge ? FreightBill.FuelCharge : 0;
            Invoice.showFuelCharge = true;
        } else newLineItem.FuelTotal = 0;
        console.log('wtf new line itme fuleTotal = '+ newLineItem.FuelTotal)

        newLineItem.backTotal = newLineItem.Total + newLineItem.FuelTotal + newLineItem.BrokerTotal;
        if (newLineItem.BrokerTotal < 0) Invoice.showBrokerFee = true;

        newLineItem.dispatchID = FreightBill.dispatchID;

        if ((FreightBill.UseStandBy && FreightBill.standBilled > 0) && !FreightBill.standByIsBilled) {
            FreightBill.hasStandBy = true;
            newLineItem.hasStandBy = true;
            newLineItem.tableLength++;
        }
        else {
            FreightBill.hasStandBy = false;
            newLineItem.hasStandBy = false;
        }
        if(!FreightBill.calcByLoad)FreightBill.calcByLoad=false;
        if(FreightBill.BillType==='Ton') Invoice.showCalcByLoad = true;
		
        if (FreightBill.Company.ID === Invoice.Company.ID && !FreightBill.truckingBilled) {
            for (var j = 0; j < FreightBill.Weights.length; j++) {
                var amount = '$' + Number(Number(FreightBill.Weights[j].weight) * Number(FreightBill.BillRate).toString());
                if (!FreightBill.Weights[j].tagNO) FreightBill.Weights[j].tagNO = '';
                newLineItem.Weights.push({
                    tagNumber: FreightBill.Weights[j].tagNO,
                    Weight: Number(FreightBill.Weights[j].weight),
                    Amount: amount
                });
               // FreightBill.truckingBilled = true;
                if (FreightBill.calcByLoad && FreightBill.BillType==='Ton' ) makeScaleTagLineItem(Invoice, FreightBill, FreightBill.Weights[j], LineItems);

            }
            console.log('for fb  =',FreightBill)
            console.log('about to pushe line item =', newLineItem)
            if (!FreightBill.calcByLoad || FreightBill.BillType!=='Ton') Invoice.LineItems.push({...newLineItem});
            console.log('Invoice.LineItems.length FTER ='+Invoice.LineItems.length)
        }

        //addSTand By
        if (FreightBill.SellMaterial && !FreightBill.materialBilled) {
         	console.log('Invoice.Company.ID = '+ Invoice.Company.ID)
         	console.log('MaterialCompany = '+ FreightBill.MaterialCompany)
            if (!FreightBill.MaterialCompany) makeMaterialLineItem(FreightBill, LineItems, Invoice);
            else if (FreightBill.MaterialCompany.ID === Invoice.Company.ID) makeMaterialLineItem(FreightBill, LineItems, Invoice);
            else FreightBill.materialOnHold=true;
        }else{
			FreightBill.materialBilled = true;
			FreightBill.materialOnHold = false;
		} 
        
        if (FreightBill.hasStandBy && !FreightBill.standByIsBilled) makeStandByLineItem(FreightBill, LineItems); else FreightBill.standByIsBilled = true;



    }
    const makeExpenseLineItem =  (Expense)=> {

        console.log('Making expense line item ', Expense);
        let newLineItem = {};
        newLineItem.isEditable = Expense.isEditable || (Expense.floatingBillExpense && Expense.FreightBill === '') ? true : false;
        newLineItem.isFreightBill = false;
        newLineItem.dispatchID = Expense.dispatchID;
        newLineItem.Qty = Expense.qty;
        newLineItem.ExpenseID = Expense.ID;

		newLineItem.JobDate = Expense.JobDate;
		newLineItem.JobDateValue = new Date(Expense.JobDate);
        newLineItem.QtyString = Number(newLineItem.Qty).toString();
        newLineItem.tableLength = 0;
		newLineItem.MaterialExpense = Expense.MaterialExpense ? true : false;
		newLineItem.taxRate = Expense.taxRate ? Expense.taxRate : 0;

        newLineItem.Total = 0 + Expense.total;
        newLineItem.Rate = 0 + Expense.rate;
        newLineItem.Type = 'Expense';
        if(!Expense.Name)newLineItem.Name={Name:'No Description'};
        newLineItem.RateType = Expense.Name.Name;
        newLineItem.ID = Expense.ID;
        newLineItem.hideFBNO = false;
        newLineItem.RateString = newLineItem.Rate.toString();
        newLineItem.TotalString = '$' + Number(newLineItem.Total).toString();
    
        newLineItem.backTotal = newLineItem.Total;
     
        newLineItem.FBNO = Number(Expense.FBNO);
       if (Expense.taxRate) {
            var expenseBeforeTax = Number(Number(newLineItem.Rate) * Number(newLineItem.Qty));
            newLineItem.TaxTotal = roundedMultiplication(expenseBeforeTax ,(Expense.taxRate / 100));
            newLineItem.Taxable = true;
           
        } else {
            newLineItem.TaxTotal = 0;
            newLineItem.Taxable = false;
           
        }
     
        newLineItem.FreightID = Expense.FreightBill;
        newLineItem.ParentFreight = Expense.FreightBill;
        newLineItem.Weights = [];
        newLineItem.truck = Expense.Truck!=='No Truck' ? Expense.Truck : '';
      
        newLineItem.FBNO = Expense.FBNO;
        newLineItem.FBNumber = Number(Expense.FBNO);
        newLineItem.VNum = 1;
        newLineItem.Description = Expense.Name?.Name;
        newLineItem.onHold = Expense.onHold;
 
        newLineItem.Driver = Expense.driverName ? Expense.driverName : '';
        newLineItem.BrokerPercent = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = Expense.FuelTotal ? Expense.FuelTotal : 0;
        newLineItem.tWeight = '';
        newLineItem.Amount = Number((Number(newLineItem.Rate) * Number(newLineItem.Qty)));
        newLineItem.BrokerTotal = Expense.BrokerTotal ? Expense.BrokerTotal : 0;

        console.log('newLineItem expense line item= ' , newLineItem);
        return newLineItem;

 

    }

    const makeScaleTagLineItem =  (Invoice, FreightBill, Weight, LineItems) =>{
        var newLineItem = {};
        console.log('making scale tag line item for driver = ' + FreightBill.driverName + ' and scale tag = ' + Weight.tagNO);
        if (FreightBill.ParentFreight === '') FreightBill.ParentFreight = FreightBill.ID;
       // Invoice.calcByLoad = true;
        newLineItem.FBNO = FreightBill.FBNO.toString();
        newLineItem.FBNumber = Number(FreightBill.FBNO);
        if (FreightBill.Weights.indexOf(Weight) === 0) {
            newLineItem.hideFBNO = false;
            newLineItem.Driver = FreightBill.driverName;
            newLineItem.firstWeight = true;

        } else {
            newLineItem.hideFBNO = true;
            newLineItem.Driver = '';
            newLineItem.firstWeight = false;
        }
        newLineItem.truck = (FreightBill.Truck && FreightBill.Truck?.Name!==undefined && FreightBill.Truck?.Name!==null && FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ) ?  newLineItem.truck = FreightBill.Truck?.Name : FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ? newLineItem.truck = FreightBill.Truck :  newLineItem.truck ='';

        if (FreightBill.Weights.indexOf(Weight) === (FreightBill.Weights.length - 1)) {
            newLineItem.lastWeight = true;
            newLineItem.hasStandBy = FreightBill.hasStandBy;
        } else newLineItem.lastWeight = false;


 
        if(newLineItem.firstWeight && newLineItem.lastWeight)   newLineItem.Style = { 'border-top': '1px solid #ddd', 'border-bottom': '1px solid #ddd'};
        if(newLineItem.firstWeight && !newLineItem.lastWeight) newLineItem.Style = { 'border-top': '1px solid #ddd', 'border-bottom': '0px'};
        if(!newLineItem.firstWeight && newLineItem.lastWeight) newLineItem.Style = { 'border-top': '0px', 'border-bottom': '1px solid #ddd'};
        if(!newLineItem.firstWeight && !newLineItem.lastWeight) newLineItem.Style = { 'border-top': '0px', 'border-bottom': '0px'};
  
        newLineItem.loadSite = FreightBill.loadSite;
        newLineItem.dumpSite = FreightBill.dumpSite;
        newLineItem.loadAddress = FreightBill.loadAddress;
        newLineItem.dumpAddress = FreightBill.dumpAddress;
        newLineItem.Description = Weight.tagNO;
        newLineItem.Type = 'Scale Tag';
        if(!Weight.weight) newLineItem.Qty=0; else newLineItem.Qty = Weight.weight;
        newLineItem.QtyString = Number(Weight.weight).formatMoney(2);
        newLineItem.VNum = FreightBill.VNum ? FreightBill.VNum : 1;
        newLineItem.TotalWeight = FreightBill.tWeight;
        newLineItem.Rate = FreightBill.BillRate;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightHold = false;
        newLineItem.tWeight = FreightBill.tWeight;
        newLineItem.tHours = FreightBill.tHours;
        newLineItem.loads = FreightBill.loads;
        newLineItem.FreightID = FreightBill.ID;
    
        newLineItem.ParentFreight = FreightBill.ParentFreight;
       
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.JobDate = FreightBill.JobDate;
        newLineItem.Weights = [];



      

        newLineItem.tableLength = 0;


        newLineItem.Amount = Number(Number(newLineItem.Rate) * Number(newLineItem.Qty));
        newLineItem.Total = Math.round(Number(Number(newLineItem.Rate) * Number(newLineItem.Qty).toString()) * 100) / 100;
       
        if (FreightBill.fuelBilled!== '' && FreightBill.fuelBilled ) {
            newLineItem.FuelTotal = Math.round((newLineItem.Amount * (FreightBill.FuelCharge / 100)) * 100) / 100;
            console.log(' newLineItem.FuelTotal '+  newLineItem.FuelTotal )
            Invoice.showFuelCharge = true;
        } else newLineItem.FuelTotal = 0;
        if (FreightBill.billedBrokerPercent) { if (FreightBill.billedBrokerPercent > 0) newLineItem.BrokerTotal = Math.round(-1 * (newLineItem.Total * (FreightBill.billedBrokerPercent / 100)) * 100) / 100; else newLineItem.BrokerTotal = 0; } else newLineItem.BrokerTotal = 0;

        newLineItem.backTotal = newLineItem.Total + newLineItem.FuelTotal + newLineItem.BrokerTotal;
        newLineItem.TotalString = '$' + Number(newLineItem.Amount).formatMoney(2);
        //newLineItem.PONumber = FreightBill.PONumber;

        if (FreightBill.FreightTaxable) {

            if (FreightBill.FreightTaxRate) {
                Invoice.TaxRate = FreightBill.FreightTaxRate
                newLineItem.TaxTotal = newLineItem.Total * FreightBill.FreightTaxRate;
            } else newLineItem.TaxTotal = 0;
            newLineItem.Taxable = true;
        } else {
            newLineItem.TaxTotal = 0;
            newLineItem.Taxable = false;
        }


        newLineItem.Rate = FreightBill.BillRate;
        newLineItem.RateString = '$' + Number(newLineItem.Rate).formatMoney(2);
        newLineItem.FreightTotal = FreightBill.bTotal;
        newLineItem.RateType = FreightBill.BillType;

       // newLineItem.InvoiceVNums = FreightBill.InvoiceVNums;
        newLineItem.FuelPercent = FreightBill.FuelCharge + '%';
        newLineItem.BrokerPercent = FreightBill.billedBrokerPercent + '%';
        newLineItem.background = 'background-color : transparent!important';
        newLineItem.printBackground = 'background-color : transparent!important';
        newLineItem.isFreightBill = true;
        newLineItem.loadSite = FreightBill.loadSite;
        newLineItem.dumpSite = FreightBill.dumpSite;
      
        console.log("LineItems.length before = " + LineItems.length);
        LineItems.push(newLineItem);
        console.log("LineItems.length before = " + LineItems.length);
    }

    const makeStandByLineItem = (FreightBill, LineItems) => {
        var newLineItem = {};
        newLineItem.Type = 'Stand By';
        newLineItem.Description = 'Stand By';
        newLineItem.RateType = 'Stand By';
        if (FreightBill.totalStandBilled) newLineItem.amountString = '$' + Number(FreightBill.totalStandBilled).formatMoney(2);
        else newLineItem.amountString = '$' + Number(FreightBill.totalStandBilled).formatMoney(2);
        newLineItem.truckingBilled = FreightBill.truckingBilled;
        if(FreightBill.truckingBilled) newLineItem.hideFBNO=false; else newLineItem.hideFBNO=true;
       
        newLineItem.loadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.dumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
      //  newLineItem.InvoiceVNums = FreightBill.InvoiceVNums;
        newLineItem.Qty = FreightBill.standExMin;
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.Rate = FreightBill.standBR;
        newLineItem.Total = FreightBill.standBilled;
        if (!FreightBill.calcByLoad) newLineItem.lastWeight = true;
        newLineItem.RateString = '$' + Number(FreightBill.standBR).formatMoney(2);
        newLineItem.TotalString = '$' + Number(FreightBill.standBilled).formatMoney(2);
        newLineItem.FreightID = FreightBill.ID; 
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.Amount = Number((Number(newLineItem.Rate) * Number(newLineItem.Qty)));
        newLineItem.truck = (FreightBill.Truck && FreightBill.Truck?.Name!==undefined && FreightBill.Truck?.Name!==null && FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ) ?  newLineItem.truck = FreightBill.Truck?.Name : FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ? newLineItem.truck = FreightBill.Truck :  newLineItem.truck ='';

        newLineItem.VNum = FreightBill.VNum ? FreightBill.VNum : 1;
        newLineItem.JobDate = '';
        newLineItem.Weights = [];
        newLineItem.TaxTotal = 0;
        newLineItem.onHold = FreightBill.onHold;
        if (FreightBill.onHold )FreightBill.standByOnHold=true; else FreightBill.standByOnHold=false;
        newLineItem.FreightHold = FreightBill.onHold;
        console.log(' making stand byline item and on hold === '+newLineItem.onHold)
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber;
        else newLineItem.PONumber = '';
        newLineItem.FBNO = FreightBill.FBNO;
        newLineItem.FBNumber = Number(FreightBill.FBNO);
        newLineItem.Driver = FreightBill.driverName;
        newLineItem.FuelPercent = '';
        newLineItem.tableLength = 1;
        if (FreightBill.totalExcessLoad) newLineItem.loadQty = FreightBill.totalExcessLoad; else newLineItem.loadQty = 0
        if (FreightBill.totalExcessDump) newLineItem.dumpQty = FreightBill.totalExcessDump; else newLineItem.dumpQty = 0;
        newLineItem.loadTotal = Math.round(Number(newLineItem.loadQty * FreightBill.standBR * 100)) / 100;
        newLineItem.dumpTotal = Math.round(Number(newLineItem.dumpQty * FreightBill.standBR * 100)) / 100;

        if (FreightBill.billedBrokerPercent) {
            newLineItem.BrokerTotal = Math.round(-1 * (FreightBill.standBilled * (FreightBill.billedBrokerPercent / 100)) * 100) / 100;
            newLineItem.loadBrokerTotal = Math.round(-1 * (FreightBill.loadTotal * (FreightBill.billedBrokerPercent / 100)) * 100) / 100;
            newLineItem.dumpBrokerTotal = Math.round(-1 * (FreightBill.dumpTotal * (FreightBill.billedBrokerPercent / 100)) * 100) / 100;
        } else {
            newLineItem.BrokerTotal = 0;
            newLineItem.loadBrokerTotal = 0;
            newLineItem.dumpBrokerTotal = 0;
        }
     /*   if (FreightBill.FuelCharge) {
            newLineItem.FuelTotal = Math.round((FreightBill.standBilled * (FreightBill.FuelCharge / 100)) * 100) / 100;
            newLineItem.loadFuelTotal = Math.round((FreightBill.dumpTotal * (FreightBill.FuelCharge / 100)) * 100) / 100;
            newLineItem.dumpFuelTotal = Math.round((FreightBill.dumpTotal * (FreightBill.FuelCharge / 100)) * 100) / 100;
        } */
            newLineItem.FuelTotal = 0;
            newLineItem.loadFuelTotal = 0;
            newLineItem.dumpFuelTotal = 0;
      

        newLineItem.backTotal = newLineItem.Total + newLineItem.FuelTotal + newLineItem.BrokerTotal;
        newLineItem.loadBackTotal = newLineItem.loadTotal + newLineItem.loadFuelTotal + newLineItem.loadBrokerTotal;
        newLineItem.dumpBackTotal = newLineItem.dumpTotal + newLineItem.dumpFuelTotal + newLineItem.dumpBrokerTotal;
        newLineItem.tWeight = '';
        newLineItem.hasStandBy = false;
        console.log('FreightBill.FuelCharge = ' + FreightBill.FuelCharge);
        console.log('makign stand by line item and hideFBNO= '+ newLineItem.hideFBNO)
 
        LineItems.push({...newLineItem});
        console.log('LineItems inside stand by push =' + LineItems[LineItems.length-1].onHold)
    }
    const makeMaterialLineItem = (FreightBill, LineItems, Invoice) =>{

        var newLineItem = {};
        newLineItem.Type = 'Material';
        console.log('making material LINE ITEM!!!');
        if (FreightBill.Material){
            if (FreightBill.Material.Name === 'No Material') newLineItem.Description = 'Material';
            else newLineItem.Description = FreightBill.Material.Name;
        }
        newLineItem.JobDate = FreightBill.JobDate;
        newLineItem.RateType = newLineItem.Description + '/' + FreightBill.MaterialRateType;
        newLineItem.amountString =  FreightBill.MaterialTotal ? ('$' + Number(FreightBill.MaterialTotal).formatMoney(2)):('$' + Number(FreightBill.MaterialTotal).formatMoney(2));

        newLineItem.truckingBilled = FreightBill.truckingBilled;
        newLineItem.loadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.dumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
      //  newLineItem.InvoiceVNums = FreightBill.InvoiceVNums;
        newLineItem.Qty = FreightBill.MaterialQty;
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.Rate = FreightBill.MaterialRate;
        newLineItem.tableLength = 0;
        newLineItem.RateString = '$' + Number(FreightBill.MaterialRate).formatMoney(2);

         newLineItem.FreightID = FreightBill.ID; 
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.Total = roundedMultiplication(newLineItem.Rate , newLineItem.Qty);
        newLineItem.Amount = roundedMultiplication(newLineItem.Rate , newLineItem.Qty);
        newLineItem.TotalString = '$' + Number(FreightBill.MaterialTotal).formatMoney(2);
        newLineItem.truck = (FreightBill.Truck && FreightBill.Truck?.Name!==undefined && FreightBill.Truck?.Name!==null && FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ) ?  newLineItem.truck = FreightBill.Truck?.Name : FreightBill.Truck?.Name !== 'No Truck' &&  FreightBill.Truck?.Name !== 'Truck #' ? newLineItem.truck = FreightBill.Truck :  newLineItem.truck ='';
       
      //  newLineItem.VNum = FreightBill.VNum;
        newLineItem.JobDate = '';
        newLineItem.Weights = [];
        newLineItem.onHold = FreightBill.onHold;
        if (FreightBill.onHold )FreightBill.materialOnHold=true; else FreightBill.materialOnHold=false;
        newLineItem.FreightHold = false
        newLineItem.PONumber = FreightBill.PONumber ? FreightBill.PONumber : ''
        
        newLineItem.FBNO = FreightBill.FBNO.toString();
        newLineItem.FBNumber = Number(FreightBill.FBNO);
        newLineItem.Driver = FreightBill.driverName;
        newLineItem.FuelPercent = '';
        if (FreightBill.MaterialTaxable) {
            var materialBeforeTax = Number(Number(newLineItem.Rate) * Number(newLineItem.Qty));
            newLineItem.TaxTotal = roundedMultiplication(materialBeforeTax ,(FreightBill.MaterialTaxRate / 100));
            newLineItem.Taxable = true;
            Invoice.TaxPercent = FreightBill.MaterialTaxRate;
        } else {
            newLineItem.TaxTotal = 0;
            newLineItem.Taxable = false;
           
        }

        newLineItem.BrokerTotal = 0;
        newLineItem.loadBrokerTotal = 0;
        newLineItem.dumpBrokerTotal = 0;
        newLineItem.FuelTotal = 0;
        newLineItem.loadFuelTotal = 0;
        newLineItem.dumpFuelTotal = 0;

        newLineItem.backTotal = newLineItem.Total;
        newLineItem.tWeight = '';
        newLineItem.hasStandBy = false;
        console.log('sllmaterial line item  = ', newLineItem);
        if (FreightBill.MaterialTaxable) Invoice.showTaxRate = true;
        LineItems.push(newLineItem);

       
    }
	const makeFuelLineItem = ( fuelTotal, Invoice) => {
	
		console.log('creating fuelTotal lineitem = ', fuelTotal)
		let newLineItem = {};
        newLineItem.isEditable = false;
        newLineItem.isFreightBill = false;
        newLineItem.dispatchID = Invoice.DispID;
        newLineItem.Qty = '';
        newLineItem.ExpenseID = '';

		newLineItem.JobDate = Invoice.JobDate;

        newLineItem.QtyString = Number(newLineItem.Qty).toString();
        newLineItem.tableLength = 0;
	
		newLineItem.taxRate =0;

        newLineItem.Total = fuelTotal;
        newLineItem.Rate = '';
        newLineItem.Type = 'Fuel Charge';
       	newLineItem.Name={Name:'Fuel Charge'};
        newLineItem.RateType = 'Fuel Charge';
        newLineItem.ID = 'Fuel Charge';
        newLineItem.hideFBNO = true;
        newLineItem.RateString = newLineItem.Rate.toString();
        newLineItem.TotalString = '$' + Number(newLineItem.Total).toString();
        newLineItem.backTotal = newLineItem.Total;

        newLineItem.FBNO = 'Fuel Charge'
    
        newLineItem.TaxTotal = 0;
        newLineItem.Taxable = false;
           
     
     
        newLineItem.FreightID = 'Fuel Charge'
        newLineItem.ParentFreight = 'Fuel Charge'
        newLineItem.Weights = [];
        newLineItem.truck =  '';
    
        newLineItem.FBNO = 'Fuel Charge';
        newLineItem.FBNumber = 'Fuel Charge'
        newLineItem.VNum = 1;
        newLineItem.Description = 'Fuel Charge';
        newLineItem.onHold = false;
 
        newLineItem.Driver ='';
        newLineItem.BrokerPercent = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = 0;
        newLineItem.tWeight = '';
        newLineItem.Amount = fuelTotal;
        newLineItem.BrokerTotal =  0;

        console.log('newLineItem expense line item= ' , newLineItem);
        return newLineItem;


	}
	const makeBrokerLineItem = ( brokerTotal, Invoice) => {
		console.log('creating brokerTotal lineitem = ', brokerTotal)
		let newLineItem = {};
        newLineItem.isEditable = false;
        newLineItem.isFreightBill = false;
        newLineItem.dispatchID = Invoice.DispID;
        newLineItem.Qty = '';
        newLineItem.ExpenseID = '';

		newLineItem.JobDate = Invoice.JobDate;

        newLineItem.QtyString = Number(newLineItem.Qty).toString();
        newLineItem.tableLength = 0;
	
		newLineItem.taxRate =0;

        newLineItem.Total = brokerTotal;
        newLineItem.Rate = '';
        newLineItem.Type = 'Broker Fee';
       	newLineItem.Name={Name:'Broker Fee'};
        newLineItem.RateType = 'Broker Fee';
        newLineItem.ID = 'Broker Fee';
        newLineItem.hideFBNO = true;
        newLineItem.RateString = newLineItem.Rate.toString();
        newLineItem.TotalString = '$' + Number(newLineItem.Total).toString();
        newLineItem.backTotal = newLineItem.Total;

        newLineItem.FBNO = 'Broker Fee'
    
        newLineItem.TaxTotal = 0;
        newLineItem.Taxable = false;
           
     
     
        newLineItem.FreightID = 'Broker Fee'
        newLineItem.ParentFreight = 'Broker Fee'
        newLineItem.Weights = [];
        newLineItem.truck =  '';
    
        newLineItem.FBNO = 'Broker Fee';
        newLineItem.FBNumber = 'Broker Fee'
        newLineItem.VNum = 1;
        newLineItem.Description = 'Broker Fee';
        newLineItem.onHold = false;
 
        newLineItem.Driver ='';
        newLineItem.BrokerPercent = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = 0;
        newLineItem.tWeight = '';
        newLineItem.Amount = brokerTotal;
        newLineItem.BrokerTotal =  0;

        console.log('newLineItem expense line item= ' , newLineItem);
        return newLineItem;

	}
    const createEmptyLineItem = (invoice) => {

        let Expense = {
            ID: "",
            Name: expenseNames[0],
            parentExpense:'',
            description: expenseNames[0].Name,
            qty: Number(0),
            rate: Number(0),  
            total: Number(0),
            addToFreights:false,
            FBNO:'',
            floatingBillExpense:false,
            floatingPayExpense:false,
            QueryDate:invoice.Dispatch.QueryDate,
            JobDate:invoice.Dispatch.JobDate,
            Truck:'',
            Company:invoice.Company,
            FreightBill:'',
            dispatchID: invoice.isFloatingExpenseInvoice ? 'floatingDispatchExpense' :invoice.Dispatch.ID,
            reDriver: false,
            bCustomer: false,
            reCustomer: false,
            bDriver: false,
            bill:true,
            billed:false,
            paid:false,
            pay:false,
            onHold:false,
            BillTo:{Name:invoice.Account?.Name, ID:invoice.Account?.ID},
            PayTo:{Name:invoice.Account?.Name, ID:invoice.Account?.ID},
            isEditable: true,
			taxRate: invoice.TaxPercent ? invoice.TaxPercent : 0,
            MaterialExpense: invoice.isMaterialInvoice ? true : false
        };
		console.log('we are creating an empty line item = ', Expense);
        addDocument(Expense, 'Expenses');
    };
 
	
	const getColumnConfig = (invoice) => {
		// Base widths calculation
		let rateTypeWidth = !company.downloadIIF ? 7 : 5;
		let qtyWidth = 5;
		let totalWidth = !company.downloadIIF ? 10 : 8;

		// Dynamic width adjustments
		rateTypeWidth = !invoice.previewing ? rateTypeWidth + 1 : rateTypeWidth;
		rateTypeWidth = !invoice.calcByLoad ? rateTypeWidth + 2 : rateTypeWidth;
		
		rateTypeWidth = invoice.hasTax ? rateTypeWidth - 2 : rateTypeWidth;
		
			rateTypeWidth = invoice.hasFuelCharge ? rateTypeWidth + 3 : rateTypeWidth;
			rateTypeWidth = invoice.hasBrokerFee ? rateTypeWidth + 3 : rateTypeWidth;
			qtyWidth = invoice.hasFuelCharge ? qtyWidth + 3 : qtyWidth;
			qtyWidth = invoice.hasBrokerFee ? qtyWidth + 3 : qtyWidth;
		
		qtyWidth = !invoice.calcByLoad && !invoice.previewing ? qtyWidth + 2 : qtyWidth;
		qtyWidth = invoice.hasTax ? qtyWidth - 1 : qtyWidth;

		totalWidth = !invoice.calcByLoad ? totalWidth + 4 : totalWidth;
		console.log('invoice we getting columns for = ',invoice)
		// Base widths array
		const baseWidths = invoice.previewing  && !company.downloadIIF ?  ['8%', '7%', '15%', '4%', '8%'] : invoice.previewing  ? ['6%', '6%', '10%', '4%', '8%']:  ['10%', '8%', '20%'];

		return [
			{
				id: 'fbno',
				header: 'FB #',
				width: baseWidths[0],
				visible: true
			},
			{
				id: 'truck',
				header: 'Truck',
				width: baseWidths[1],
				visible: !invoice.isFloatingExpenseInvoice
			},
			{
				id: 'driver',
				header: 'Driver',
				width: baseWidths[2],
				visible: !invoice.isFloatingExpenseInvoice
			},
			{
				id: 'date',
				header: 'Date',
				width: baseWidths[2],
				visible: invoice.isFloatingExpenseInvoice
			},
			{
				id: 'missing',
				header: 'Missing',
				width: baseWidths[3],
				visible: invoice.previewing
			},
			{
				id: 'onHold',
				header: 'On Hold',
				width: baseWidths[4],
				visible: invoice.previewing
			},
			{
				id: 'description',
				header: 'Description',
				width: '8%' ,
				visible: invoice.calcByLoad
			},
			{ 
				id: 'qbItem',
				header: 'QB Item',
					width: '9%' ,
					visible: company.downloadIIF
			},
			{
				id: 'rateType',
				header: 'Rate Type',
				width: `${rateTypeWidth}%`,
				visible: true
			},
			{
				id: 'qty',
				header: 'Qty',
				width: `${qtyWidth}%`,
				visible: true
			},
			{
				id: 'rate',
				header: 'Rate',
				width: `${rateTypeWidth}%`,
				visible: true
			},
			{
				id: 'tax',
				header: 'Tax',
				width: '8%',
				visible: invoice.hasTax
			},
			{
				id: 'fuelCharge',
				header: 'Fuel Charge',
				width: '9%',
				visible: invoice.hasFuelCharge 
			},
			{
				id: 'brokerFee',
				header: 'Broker Fee',
				width: '9%',
				visible: invoice.hasBrokerFee 
			},
			{
				id: 'total',
				header: 'Total',
				width: `${totalWidth}%`,
				visible: true
			}
		];
	
	
	};

  return {
	makeFreightBillLineItem,
	makeExpenseLineItem,
	makeScaleTagLineItem,
	makeStandByLineItem,
	makeMaterialLineItem,
	makeFuelLineItem,
	makeBrokerLineItem,
	createEmptyLineItem, 
	getColumnConfig
  };
}; 