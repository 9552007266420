import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';

import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

const AccountsHome = () => {
  const { accounts, deleteDocument, updateDocument } = UserAuth();
  const { showAccountPopUp } = useGlobal();
  const [sortedAccounts, setSortedAccounts] = useState([]);

  useEffect(() => {
    const sorted = [...accounts].sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
    setSortedAccounts(sorted);
  }, [accounts]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
	Address: { value: null, matchMode: FilterMatchMode.CONTAINS },
	City: { value: null, matchMode: FilterMatchMode.CONTAINS },
	State: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Status: { value: null, matchMode: FilterMatchMode.EQUALS },
    Subhauler: { value: null, matchMode: FilterMatchMode.EQUALS },

    DedicatedSubhauler: { value: null, matchMode: FilterMatchMode.EQUALS },
    Vendor: { value: null, matchMode: FilterMatchMode.EQUALS },
    Broker: { value: null, matchMode: FilterMatchMode.EQUALS },
    Customer: { value: null, matchMode: FilterMatchMode.EQUALS }
  });

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [statuses] = useState(['Active', 'Inactive']);

  const getSeverity = (status) => (status === 'Active' ? 'success' : 'danger');

  const onGlobalFilterChange = (e) => {
    const value = e.target.value || '';
    setFilters((prev) => ({ ...prev, global: { ...prev.global, value } }));
    setGlobalFilterValue(value);
  };

  const deleteBodyTemplate = (rowData) => (
    <button type="button" onClick={() => handleDelete(rowData)}>
         <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
    </button>
);

const handleDelete = async (rowData) => {
    try {
        if (window.confirm("Are you sure you want delete this Account?")) {
            deleteDocument(rowData,'Accounts' )
        }  
    } catch (error) {  console.error("Error removing document: ", error);  }
   
};
  const renderHeader = () => (
   
    <div className="flex justify-content-end mbsc-row ">
        <span>Accounts</span>
        <IconField iconPosition="left" className="homeSearch">
            <InputIcon className="pi pi-search" />
            <InputText style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </IconField>
    </div>
  );

  const editBodyTemplate = (rowData) => (
    <button type="button" onClick={() => handleEdit(rowData)}>
      <FontAwesomeIcon icon={faEdit} />
    </button>
  );

  const handleEdit = (rowData) => {
    showAccountPopUp(rowData);
  };

  const statusBodyTemplate = (rowData) => <Tag value={rowData.Status} severity={getSeverity(rowData.Status)} />;
  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
};


  const booleanBodyTemplate = (rowData, field, header) => {
	let color = rowData[field] ? 'green' : 'red';
	return (
	 
    <span data-label={header}>
  	 <i style={{color:color}}
        className={classNames('pi', {
          'pi-check-circle true-icon': rowData[field],
          'pi-times-circle false-icon': !rowData[field]
        })}
      />
    </span>
	)
}
  const statusRowFilterTemplate = (options) => {
    return (
        <Dropdown     value={options.value !== null ? options.value : undefined} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
};

const booleanFilterTemplate = (options) => {
  return (
    <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
      value={options.value !== null ? options.value : undefined}
      onChange={(e) => options.filterApplyCallback(e.value)}
    />
  );
};

const handleCustomerChange = async (rowData, checked) => {
  try {
	console.log('rowData = ', rowData)
	console.log('checked = ', checked)
    const updatedData = { ...rowData, Customer: checked };
    await updateDocument(updatedData, rowData.ID, 'Accounts');
  } catch (error) {
    console.error("Error updating customer status: ", error);
  }
};

const customerBodyTemplate = (rowData) => (
  <div className="flex align-items-center justify-content-center">
    <input 
      type="checkbox" 
      checked={rowData.Customer || false} 
      onChange={(e) => handleCustomerChange(rowData, e.target.checked)}
    />
  </div>
);

const header = renderHeader();
  return (
    <div className="card">
      <DataTable
        value={sortedAccounts}
        paginator
        rows={25}
        dataKey="ID"
        filters={filters}
        header={header}
        filterDisplay="row"
        emptyMessage="No customers found."

      >
      
        <Column pt={{root: { 'data-label': 'Edit' }}} header="Edit" body={(rowData) => <span data-label="Edit">{editBodyTemplate(rowData)}</span>} />
 
        <Column pt={{root: { 'data-label': 'Name' }}} field="Name" header="Name" filter filterPlaceholder="Search by name" body={(rowData) => <span data-label="Name">{rowData.Name}</span>} />
        <Column pt={{root: { 'data-label': 'Phone' }}} field="displayPhone" header="Phone" filter filterPlaceholder="Search by phone" body={(rowData) => <span data-label="Phone">{rowData.displayPhone}</span>} />
        <Column pt={{root: { 'data-label': 'Address' }}} field="Address" header="Address" filter filterPlaceholder="Search by Address" body={(rowData) => <span data-label="Address">{rowData.Address}</span>} />
        <Column pt={{root: { 'data-label': 'City' }}} field="City" header="City" filter filterPlaceholder="Search by City" body={(rowData) => <span data-label="City">{rowData.City}</span>} />
        <Column pt={{root: { 'data-label': 'State' }}}  field="State" header="State" filter filterPlaceholder="Search by State" body={(rowData) => <span data-label="State">{rowData.State}</span>} />
        <Column pt={{root: { 'data-label': 'Status' }}} field="Status" header="Status" filter filterElement={statusRowFilterTemplate} body={(rowData) => <span data-label="Status">{statusBodyTemplate(rowData)}</span>} />
        <Column pt={{root: { 'data-label': 'Customer' }}} field="Customer" header="Customer" dataType="boolean" filter filterElement={booleanFilterTemplate} body={(rowData) => booleanBodyTemplate(rowData, 'Customer', 'Customer')}  />
        <Column pt={{root: { 'data-label': 'Subhauler' }}}  field="Subhauler" header="Subhauler" dataType="boolean" filter filterElement={booleanFilterTemplate} body={(rowData) => booleanBodyTemplate(rowData, 'Subhauler', 'Subhauler')} />
        <Column pt={{root: { 'data-label': 'Dedicated' }}} field="DedicatedSubhauler" header="Dedicated" dataType="boolean" filter filterElement={booleanFilterTemplate} body={(rowData) => booleanBodyTemplate(rowData, 'DedicatedSubhauler', 'Dedicated')} />
        <Column pt={{root: { 'data-label': 'Vendor' }}} field="Vendor" header="Vendor" dataType="boolean" filter filterElement={booleanFilterTemplate} body={(rowData) => booleanBodyTemplate(rowData, 'Vendor', 'Vendor')} />
        <Column pt={{root: { 'data-label': 'Broker' }}} field="Broker" header="Broker" dataType="boolean" filter filterElement={booleanFilterTemplate}    body={(rowData) => (  booleanBodyTemplate(rowData, 'Subhauler') )}/>
      </DataTable>
    </div>
  );
};

export default AccountsHome;
