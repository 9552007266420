import React, { useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';

import GoogleMapReact from 'google-map-react';
import InputTextParent from '../InputComponents/InputTextParent'; 
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const MapComponent = forwardRef(({ dispatch, tabletList, company, handleFieldsChange}, ref) => {
    const mapRef = useRef(null);
    const mapsRef = useRef(null);
    const directionsServiceRef = useRef(null);
    const directionsRendererRef = useRef(null);
    const markersRef = useRef([]);  // To store markers
    const [distance, setDistance] = useState(null); // State for distance
    const [duration, setDuration] = useState(null); // State for duration
    const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 }); // Default to center of the US
    const mapContainerRef = useRef(null); 
    
	const loadSiteLatLngRef = useRef(null);
	const dumpSiteLatLngRef = useRef(null)

    useEffect(() => {
        if(dispatch && mapsRef.current){
          	loadSiteLatLngRef.current='';
			console.log('updating the load site ')
            updateDirections();
        }
    }, [dispatch?.LoadSite?.fullAddress]);


    useEffect(() => {
        if(dispatch && mapsRef.current){
			dumpSiteLatLngRef.current='';
			console.log('updating the dump site ')
            updateDirections();
        }
    }, [dispatch?.DumpSite?.fullAddress]);
    useEffect(()=>{
        if(tabletList && mapsRef.current )updateMarkers();
    },[tabletList]);


    
    const updateDirections = useCallback(async () => {
        console.log("Updating directions...");
    
        if (directionsRendererRef.current) directionsRendererRef.current.setMap(null);
        
        let newLoadAddressOK, newDumpAddressOK;
        directionsServiceRef.current = new mapsRef.current.DirectionsService();
        directionsRendererRef.current = new mapsRef.current.DirectionsRenderer();
        directionsRendererRef.current.setMap(mapRef.current);
    	console.log('loadsitelatlgn = ', loadSiteLatLngRef.current)
            // No cache, proceed with API call
      
         	
        try {

			const loadSiteLatLng =  loadSiteLatLngRef.current ? loadSiteLatLngRef.current : await getLatLng(dispatch.LoadSite.fullAddress);
            const dumpSiteLatLng = dumpSiteLatLngRef.current ? dumpSiteLatLngRef.current : await getLatLng(dispatch.DumpSite.fullAddress);
		  	console.log('dumpSiteLatLng = ', dumpSiteLatLng)
			  if(loadSiteLatLng) setCenter(loadSiteLatLng); else if(dumpSiteLatLng)setCenter(dumpSiteLatLng)
            const directionsRequest = {
                origin: loadSiteLatLng,
                destination: dumpSiteLatLng,
                travelMode: "DRIVING",
            };
       
            let fields = ["loadResults", "loadAddressOK", "dumpResults", "dumpAddressOK"];

            directionsServiceRef.current.route(directionsRequest, (result, status) => {
                if (status === mapsRef.current.DirectionsStatus.OK) {
                    directionsRendererRef.current.setDirections(result);
                    newLoadAddressOK = true;
                    newDumpAddressOK = true;
    
                    // Extract distance and duration
                    const legs = result.routes[0].legs[0];
                    const calculatedDistance = Math.round(legs.distance.value * 0.000621371192 * 10) / 10 + " miles";
                    const calculatedDuration = Math.round(legs.duration.value / 60) + " minutes";
    
                    setDistance(calculatedDistance);
                    setDuration(calculatedDuration);
    
                    console.log(`Distance: ${calculatedDistance}, Duration: ${calculatedDuration}`);
                    console.log('RESULT OF THE DIRCTIONS REQUEST = ', result)
                              
                 
                } else {
                    newLoadAddressOK = false;
                    newDumpAddressOK = false;
                    console.error(`Failed to fetch directions: ${status}`);
                }
				console.log('newLoadAddressOK = '+  newLoadAddressOK + ' newDumpAddressOK = '+ newDumpAddressOK)
				console.log(' dispatch.dumpAddressOK= '+  dispatch.dumpAddressOK+ ' dispatch.loadAddressOK = '+ dispatch.loadAddressOK)
                let values = [loadSiteLatLng, newLoadAddressOK,dumpSiteLatLng, newDumpAddressOK];
                if (handleFieldsChange) {
                    if (loadSiteLatLng?.lat !== dispatch.loadResults?.lat || loadSiteLatLng?.lng !== dispatch.loadResults?.lng ||
                        dumpSiteLatLng?.lat !== dispatch.dumpResults?.lat || dumpSiteLatLng?.lng !== dispatch.dumpResults?.lng   
					   || newDumpAddressOK !== dispatch.dumpAddressOK) handleFieldsChange(fields, values, true);
                }
            });
        } catch (error) {
            console.error("Error getting latitude and longitude:", error);
        }
    }, [dispatch?.DumpSite?.fullAddress, dispatch?.LoadSite?.fullAddress]);
    

    const handleApiLoaded = (map, maps) => {
        mapRef.current = map;
        mapsRef.current = maps;

        if(dispatch){
			loadSiteLatLngRef.current = dispatch.loadResults;
			dumpSiteLatLngRef.current = dispatch.dumpResults;
			updateDirections();
		}
        if(tabletList)updateMarkers();
    }
  
    const getLatLng = useCallback((address) => {
        return new Promise((resolve, reject) => {
            console.log('lets count the geocoder')
            const geocoder = new mapsRef.current.Geocoder();
		

            geocoder.geocode({ address }, (results, status) => {
                if (status === mapsRef.current.GeocoderStatus.OK) {
                    const location = results[0].geometry.location;
                    resolve({
                        lat: location.lat(),
                        lng: location.lng()
                    });
                } else {
                    resolve('')
                }
            });
	
        });
    }, []);

    const updateMarkers = () => {
        if (!tabletList) return;
        // Clear previous markers
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
        console.log('mapsREf.current = ', mapsRef.current);
        // Loop through devices and create markers
        tabletList.forEach(device => {
            console.log('(tryign to set device = ', device)
        
            if (device.location && device.location.lat && device.location.lng) {
                let initials= device.Initials ? device.Initials:device.name;
                const marker = new mapsRef.current.Marker({
                    position: { lat: device.location.lat, lng: device.location.lng },
                    label:{text:initials, color:'white'},
                    driverID: device.driverID,
                    title:device.driverName,
                    map: mapRef.current,
                    optimized: false
                });
                markersRef.current.push(marker);  // Store marker reference
            }
        });
        console.log('(markersRef.current= ',markersRef.current)
        // Adjust map bounds to fit markers
        const bounds = new mapsRef.current.LatLngBounds();
        console.log('bounds = ', bounds)
        markersRef.current.forEach(marker => bounds.extend(marker.getPosition()));
        if (markersRef.current.length > 0) {
            mapRef.current.fitBounds(bounds);
        }
    };

    useImperativeHandle(ref, () => ({
        setDistanceForFreight : (freight)=> {
            return new Promise((resolve, reject) => {
            console.log('runnign directions for freight!!' , freight)
            directionsServiceRef.current = new mapsRef.current.DirectionsService();
            var METERS_TO_MILES = 0.000621371192;

            freight.LatLng = { lat: freight.lat, lng: freight.lng };
            var directionsRequest = {
                origin: freight.LatLng,
                destination: dispatch.LoadSite.fullAddress,
                travelMode: 'DRIVING'
        
            };
            freight.showingDistance = true;
            if(freight.distanceType==='Load'){
                directionsServiceRef.current.route(directionsRequest, function (response, status) {
                    console.log(' status for load= ' + status + 'for dispatchfreight.drivername = ', freight);
                    if (status === mapsRef.current.DirectionsStatus.OK) {
                        freight.Distance = (Math.round(response.routes[0].legs[0].distance.value * METERS_TO_MILES * 10) / 10) + " miles";
                        freight.Duration = (Math.round(response.routes[0].legs[0].duration.value / 60)) + " minutes";
                        resolve(freight.Distance); 
                    }
                    else if (status == "NOT_FOUND") reject('COULD NOT CAULATE LOAD DISTANCE')
               
                });
            }
            if(freight.distanceType==='Dump'){
                directionsRequest.destination = dispatch.DumpSite.fullAddress;
                directionsServiceRef.current.route(directionsRequest, function (dumpresponse, dumpstatus) {
                    console.log(' status for dump= ' + dumpstatus);
                    if (dumpstatus == mapsRef.current.DirectionsStatus.OK) {
                        freight.Distance = (Math.round(dumpresponse.routes[0].legs[0].distance.value * METERS_TO_MILES * 10) / 10) + " miles";
                        freight.Duration = (Math.round(dumpresponse.routes[0].legs[0].duration.value / 60)) + " minutes";
                        resolve(freight.Distance); 
                    } else if (dumpstatus == "NOT_FOUND") reject('COULD NOT CAULATE DUMP  DISTANCE')
              
                });
            }
            if(freight.distanceType==='Yard'){
                directionsRequest.destination = company.YardAddress + ', ' + company.YardCity + ', ' + company.YardState + ', ' + company.YardZipCode;
                directionsServiceRef.current.route(directionsRequest, function (yardresponse, yardstatus) {
                    console.log(' status for yard= ' + yardstatus);
                    if (yardstatus == mapsRef.current.DirectionsStatus.OK) {
                        freight.Distance = (Math.round(yardresponse.routes[0].legs[0].distance.value * METERS_TO_MILES * 10) / 10) + " miles";
                        freight.Duration = (Math.round(yardresponse.routes[0].legs[0].duration.value / 60)) + " minutes";  
                        resolve(freight.Distance); 
                    } else if (yardstatus == "NOT_FOUND")reject('COULD NOT CAULATE DUMP  DISTANCE')
                  
                });
            }
          
        })
    }
    }));
    return (
        <div className="mbsc-grid" style={{padding:"0"}}>
            <div className="mbsc-row" ref={mapContainerRef}  style={{ height: '500px', width: '100%' }}>

                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    bootstrapURLKeys={{ key: "AIzaSyBLRfT0lk65I2sQ7nJaHVWddKclD6ohiHI", libraries: ["places"] }}
                    center={center}
                    defaultZoom={10}
                >
                    <AnyReactComponent lat={center.lat} lng={center.lng}  />
                </GoogleMapReact>
            
            </div>
         <div className='mbsc-row'>
         {duration ? (<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Duration</span> 
                <InputTextParent disabled={true}  value={duration}   />
            </div>):( <p>'Calculating...'</p>)}
            {distance ? (<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Distance</span> 
                <InputTextParent disabled={true}  value={distance}   />
            </div>):( <p>'Calculating...'</p>)}
          
         </div>
     </div>
    );
}); 

export default MapComponent;
