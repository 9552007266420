

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';

import JobsProfitHome from './JobsProfitHome';
import DispatchList from './DispatchList';
import DispatchProfit from './DispatchProfit';
import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';





function JobsProfitParent() {



    console.log('FREIGHT BILL PARENT RE RENDERING')

    const toast = useRef(null);

 
return ( 
    <Page>
        <React.Fragment>
        <Toast ref={toast} />

                    <Routes>
                        <Route path='home' element={<ProtectedRoute><JobsProfitHome/></ProtectedRoute>} />
                        <Route path='jobView/:jobID/:startDate/:endDate' element={<ProtectedRoute><DispatchList/></ProtectedRoute>} />
                        <Route path='dispatchView/:dispID' element={<ProtectedRoute><DispatchProfit/></ProtectedRoute>} />
                    </Routes>

        </React.Fragment>
    </Page>
  );
}

export default JobsProfitParent;