import React, { useState, useCallback, useEffect, useRef } from 'react';


import useFieldSpecificDebounce from '../../../hooks/useFieldSpecificDebounce';
import DPSInputTableCell from './DPSInputTableCell';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput';
import { UserAuth } from '../../../context/AuthContext';

const DPSEditableLineItem = ({ lineItem, columns, onUpdate }) => {
    const [inputValues, setInputValues] = useState(lineItem);
    const lineItemRef = useRef(lineItem);  // Add a ref to track the original lineItem
    const debouncedFieldUpdater = useFieldSpecificDebounce(1000);
    const { expenseNames, deleteDocument, updateDocument,formatDate } = UserAuth();
    console.log('inputValues for the editable line item = ', inputValues)

    // Sync inputValues with lineItem when it changes
    useEffect(() => {
        lineItemRef.current = lineItem;
        setInputValues(lineItem);
    }, [lineItem]);

    const handleChange = (field, value) => {
        const updatedValues = { ...inputValues, [field]: value };
        setInputValues(updatedValues);
        lineItemRef.current[field]=value;

        onUpdate(lineItemRef.current);
        if (field === 'onHold') {   
            updateDocument(  { onHold: value },    lineItemRef.current.ID,    "Expenses"  );
        }else {
			const debouncedUpdate = debouncedFieldUpdater([field], updateExpenseFields);
			debouncedUpdate([field], [value]);
		}
    }

    const handleDateChange = ( fieldName, value) => {
        let queryDate = formatDate(value, '/', 'YYYY/MM/DD');
        let formattedDate = formatDate(value, '/', 'MM/DD/YYYY');
        let updateObject = {QueryDate:queryDate, [fieldName]: formattedDate, [fieldName+'Value']: value };
        setInputValues((prev) => ({ ...prev, ...updateObject}));  

        updateDocument(updateObject,  lineItemRef.current.ID, "Expenses");

    };
    const updateExpenseFields= useCallback(async (fieldNames, values) => {
    
        let updateObject = {};
     
        fieldNames.forEach((field, index) => {
          updateObject[field] = values[index];
          lineItemRef.current[field]=values[index];
        });
      
        updateDocument(updateObject, lineItemRef.current.ID, "Expenses");
        
    },  [lineItem] );
    const calcTotal = (fieldName, value) =>{
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName] = value;
        console.log('tempInputValues= ', tempInputValues);
        tempInputValues.Total = tempInputValues.Qty*tempInputValues.Rate;
        tempInputValues.Amount = tempInputValues.Total;
        tempInputValues.TotalString = '$' + Number(tempInputValues.Total).formatMoney(2);
        tempInputValues.RateString = '$' + Number(tempInputValues.Rate).formatMoney(2);
        tempInputValues.amountString = '$' + Number(tempInputValues.Amount).formatMoney(2);
        tempInputValues.TrailerTotalString = '$' + Number(tempInputValues.TrailerTotal).formatMoney(2);
        tempInputValues.BrokerTotalString = '$' + Number(tempInputValues.BrokerTotal).formatMoney(2);
        const fields=['qty', 'rate', 'TrailerTotal', 'BrokerTotal', 'amount','total'];
        const values = [tempInputValues.Qty, tempInputValues.Rate, tempInputValues.TrailerTotal, tempInputValues.BrokerTotal, tempInputValues.Amount, tempInputValues.Total];
        setInputValues(tempInputValues);
        onUpdate(tempInputValues);
        const debouncedUpdate = debouncedFieldUpdater(fields, updateExpenseFields);
        debouncedUpdate(fields, values); // Pass `fields` and `values` dynamically
        
        
    }
    const handleDelete = (lineitem) => {
        if (window.confirm('Are you sure you would like to delete this Expense Line Item?')) {
            deleteDocument(lineitem, 'Expenses');
        }
      
    }
    const handleRateTypeChange = (fieldName, value) => {
        console.log('fieldName= ', fieldName)
        console.log('value= ', value)
        updateDocument({Name:value}, lineItem.ID, 'Expenses');
        setInputValues( {...inputValues, RateType: value.Name});
    }
    const renderEditableCell = (col) => {
        const borderStyle = "1px 1px 1px 1px";

        // Return empty cell for non-visible columns
        if (!col.visible) {
            return <td style={{ display: 'none' }}></td>;
        }

        switch(col.id) {
            case 'fbno':
                return (
                    <td 
                        data-label='FB NO' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <button 
                            style={{ 
                                margin: '0', 
                                height:"80%", 
                                marginTop:".1em", 
                                padding: '.1em', 
                                marginLeft:".5em", 
                                width:"90%",
                                backgroundColor: "#dc3545", // Red color for delete button
                                color: "white"
                            }} 
                            onClick={() => handleDelete(inputValues)} 
                            className="mbsc-ios mbsc-btn-primary mbsc-btn"
                        >
                            Delete
                        </button>
                    </td>
                );
            case 'jobdate':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.JobDateValue}
                        onChange={(e) => handleDateChange('JobDate', e.value)}
                        borderStyle={borderStyle}
                        dataLabel='Job Date'
                        type="calendar"
                    />
                );
           case 'jobnumber':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.JobNumber} 
                        onChange={(e) => handleChange('JobNumber', e.target.value)}
                        borderStyle={borderStyle}
                        dataLabel='Job Number'
                    />
                );
            case 'ponumber':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.PONumber} 
                        borderStyle={borderStyle}
                        dataLabel='PO Number'
                    />
                );
            case 'driver':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.Driver}
                        onChange={(e) => handleChange('Driver', e.target.value)}
                        borderStyle={borderStyle}
                        dataLabel='Driver'
                    />
                );
          
            case 'onHold':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.onHold}
                        onChange={(e) => handleChange('onHold', e.checked)}
                        borderStyle={borderStyle}
                        dataLabel='On Hold'
                        type="checkbox"
                  
                    />
                );
            case 'description':
                return (
                    <DPSInputTableCell 
                    width={col.width}
                    value={inputValues.Description}
                    onChange={(e) => handleChange('Description', e.target.value)}
                    borderStyle={borderStyle}
                    dataLabel='Description'
                 
                />
             
                  
                );
            case 'rateType':
                return (

                    <td 
                    data-label='Rate Type' 
                    style={{ 
                        width: col.width, 
                        padding: '0', 
                        borderWidth: borderStyle, 
                        borderColor:'#bcbcd1', 
                        borderStyle:'solid' 
                    }}
                >
                    <AutoCompleteInput 
                        fieldName="RateType"
                        field="Name"
                        value={{ Name: inputValues.RateType}}
                        suggestions={expenseNames}
                        setValue={setInputValues}
                        handleFieldChange={(fieldName, value) => handleRateTypeChange('RateType', value)}
                        showLabel={false}
                        databaseList={'LineItemNames'}
                        defaultNameType={'Expense'}
                    />
                </td>
              
                );
            case 'qty':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.Qty}
                        onChange={(e) => calcTotal('Qty',e.value)} 
                        borderStyle={borderStyle}
                        dataLabel='Qty'
                        type="number"
                    />
                );
            case 'rate':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.Rate}
                        onChange={(e) => calcTotal('Rate', e.value )}
                        borderStyle={borderStyle}
                        dataLabel='Rate'
                        type="currency"
                        key={`rate-${inputValues.ID}`}
                    />
                );
                case 'trailerFee':
                    return (
                        <DPSInputTableCell 
                            width={col.width}
                            value={Math.abs(inputValues.TrailerTotal) * -1}
                            onChange={(e) => calcTotal('TrailerTotal', Math.abs(e.value) * -1 )}
                            borderStyle={borderStyle}
                            dataLabel='Trailer Fee'
                            type="currency"
                            key={`trailer-${inputValues.ID}`}
                        />
                    );
            case 'brokerFee':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={Math.abs(inputValues.BrokerTotal) * -1}
                        onChange={(e) => calcTotal('BrokerTotal', Math.abs(e.value) * -1 )}
                        borderStyle={borderStyle}
                        dataLabel='Broker Fee'
                        type="currency"
                        key={`broker-${inputValues.ID}`}
                    />
                );
                 case 'amount':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.Amount}
                        borderStyle={borderStyle}
                        dataLabel='Amount'
                        type="currency"
                       
                    />
                );
            case 'total':
                return (
                    <DPSInputTableCell 
                        width={col.width}
                        value={inputValues.Total}
                        borderStyle={borderStyle}
                        dataLabel='Total'
                        type="currency"
                       
                    />
                );
            default:
                // Return empty cell instead of null
                return (
                    <td 
                        data-label={col.header} 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    ></td>
                );
        }
    };

    return (
        <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em'}}>
            {columns.map((col, index) => (
                <React.Fragment key={`${col.id}-${inputValues.ID}`}>
                    {renderEditableCell(col)}
                </React.Fragment>
            ))}
        </tr>
    );
};

export default DPSEditableLineItem; 