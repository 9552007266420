import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputNumber } from 'primereact/inputnumber';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const TestPaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [amount, setAmount] = useState(1000); // $10.00
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        if (!stripe || !elements) {
            return;
        }

        try {
            // Create payment method
            const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            if (stripeError) {
                throw new Error(stripeError.message);
            }

            // Make API call to your backend
            const response = await fetch('/api/create-payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id,
                    amount: amount // in cents
                })
            });

            if (response.ok) {
                setSuccess(true);
            } else {
                throw new Error('Payment processing failed');
            }

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-4">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Test Amount (in cents)
                </label>
                <InputNumber 
                    value={amount} 
                    onValueChange={(e) => setAmount(e.value)}
                    mode="currency" 
                    currency="USD" 
                    locale="en-US"
                    className="w-full"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Card Details
                </label>
                <div className="p-3 border rounded" style={{ background: '#f8f9fa' }}>
                    <CardElement 
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>
            </div>

            <Button 
                type="submit" 
                disabled={!stripe || loading} 
                loading={loading}
                label="Test Payment"
                className="w-full"
            />

            {error && (
                <div className="p-message p-message-error mt-4">
                    {error}
                </div>
            )}

            {success && (
                <div className="p-message p-message-success mt-4">
                    Test payment successful!
                </div>
            )}

            <div className="mt-4 p-3 bg-gray-100 rounded">
                <h3 className="font-bold mb-2">Test Card Numbers:</h3>
                <ul className="list-none p-0 m-0">
                    <li className="mb-1">Success: 4242 4242 4242 4242</li>
                    <li className="mb-1">Decline: 4000 0000 0000 0002</li>
                    <li className="mb-1">Insufficient Funds: 4000 0000 0000 9995</li>
                    <li>Requires Authentication: 4000 0000 0000 3220</li>
                </ul>
            </div>
        </form>
    );
};

const TestPayment = () => {
    return (
        <div className="p-5">
            <Panel header="Test Stripe Payment">
                <Elements stripe={stripePromise}>
                    <TestPaymentForm />
                </Elements>
            </Panel>
        </div>
    );
};

export default TestPayment; 