import React,{useState,useContext,createContext, useRef,  useCallback, useEffect} from 'react'

import { db } from '../../../firebase';
import { doc,  writeBatch,  query,  updateDoc, collection,  onSnapshot, where } from 'firebase/firestore';

import { UserAuth } from '../../../context/AuthContext';
import { isEqual } from 'lodash'; 
import { Checkbox } from 'primereact/checkbox';
import { useNavigate, useLocation } from 'react-router-dom';


import { usePrevailingWageData} from '../hooks';

const DispatchContext = createContext();

export const DispatchContextProvider = ({ children }) => {

    const { gearedUser, updateDocument, company, addAuditLog } = UserAuth();
	const { handlePrevailingWageReportCreate} = usePrevailingWageData();
    const [homeDate, setHomeDate]= useState(null); 
    const [homeDispatches, setHomeDispatches] = useState([]);
  
    const [homeFreightBills, setHomeFreightBills] = useState([]);
    const [dispatch, setDispatch] = useState(null);
    const [jobDispatches, setJobDispatches] = useState([]);
    const [job, setJob] = useState(null);
    const [dispatchState, setDispatchState] = useState(null);
    const [assigning, setAssigning]=useState(false);
    const [dispatchDate, setDispatchDate] =useState(null);
    const [dispatchDatePopUpVisible, setDispatchDatePopUpVisible] =useState(false);
    const [popUpFreight, setPopUpFreight] = useState(null);
    const [freightPopUpVisible, setFreightPopUpVisible] = useState(null);
    const [dispatchExpenses, setDispatchExpenses] = useState([]);
  
    const [prelimEmail, setPrelimEmail]=useState('');
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogButtons, setDialogButtons] = useState(null);
    const [dialogText, setDialogText] = useState(null);
    const [dialogHeader, setDialogHeader] = useState(null);
    const [searchedObject, setSearchedObject] = useState(null);
    const [expensePopUpVisible, setExpensePopUpVisible] = useState(null);
 
    const [selectedFreightBills, setSelectedFreightBills] = useState({});
    const [selectedDrivers, setSelectedDrivers] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();
    const prelimEmailRef = useRef(null);
    const dispatchObjectRef = useRef(null);
    const expensesRef = useRef(null);
    const jobDispatchesRef = useRef(null)
   
    const expenseListenerRef = useRef(null);

    const homeFreightBillsRef = useRef(null); 
    const unsubscribeFreightBillsRef = useRef(null); // Store the unsubscribe function

    const selectedFreightBillsRef = useRef(null); 
    const selectedDriversRef = useRef(null);
    const unassignedDriversRef = useRef([]);
    const popUpFreightRef = useRef(null);


    const homeDispatchesRef = useRef(null);
	const unsubscribeDispatchesRef = useRef(null); // Store the unsubscribe function
	
    const dispatchFreightBillsRef = useRef(null); 
    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch?.ID);




    useEffect(()=>{
        dispatchFreightBillsRef.current= homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatchObjectRef?.current?.ID).sort((a, b) => a.loadOrder - b.loadOrder);
    },[homeFreightBills,dispatchObjectRef])

    const createChildExpenses = async(expense)=>{
    
        const batch = writeBatch(db);
        for(let i=0;i<dispatchFreightBills.length; i++){
            var ExpenseID= expense.ID+dispatchFreightBills[i].ID;
            let expenseRef =  doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Expenses',  ExpenseID);
            let tempExp = createChildExpense(dispatchFreightBills[i], expense);
            console.log('we setting the tempExp = ', tempExp)
            batch.set(expenseRef, tempExp );
        }
        await batch.commit();
    }
    
    const createChildExpense = (freightBill, expense)=>{
        let tempExp = {...expense};

        tempExp.FreightBill = freightBill.ID;
        tempExp.FBNO = freightBill.FBNO;
        tempExp.QueryDate = freightBill.QueryDate;
        tempExp.JobDate = freightBill.JobDate;
        tempExp.floatingBillExpense = expense.floatingBillExpense ? expense.floatingBillExpense : false;
        tempExp.floatingPayExpense = expense.floatingPayExpense ? expense.floatingPayExpense : false;
        tempExp.driverID = freightBill.Subhauler ? freightBill.subhaulerID : freightBill.Driver;
        tempExp.driverName =  freightBill.driverName;
        tempExp.Truck = freightBill.Truck?.Name!==null && freightBill.Truck?.Name!==undefined ? freightBill.Truck.Name :'';
        tempExp.parentExpense= expense.ID;
        tempExp.BillType = freightBill.BillType;
        tempExp.Company = freightBill.Company;
        tempExp.ID=expense.ID+freightBill.ID;
        tempExp.onHold= expense.total===0 ? true : false;
        if(tempExp.PayTo.ID===''){
            if(!freightBill.Subhauler) tempExp.PayTo={ID:freightBill.Driver,Name:freightBill.driverName	};
            else  tempExp.PayTo={ID:freightBill.subhaulerID,Name:freightBill.haulerName	};
        }
        console.log('returnign tempy exppp = ', tempExp)
        return tempExp;
    }

    const updateChildExpenses = async(childExpenses, Expense)=>{
        console.log('childExpenses = ' ,childExpenses);
        const batch = writeBatch(db);
    
        for(let e=0; e<childExpenses.length; e++){
            let PayTo;
          
            if(Expense.PayTo.ID==='') PayTo={ID:childExpenses[e].driverID,Name:childExpenses[e].driverName	};  else PayTo={...Expense.PayTo}
            let expenseRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Expenses',  childExpenses[e].ID);
            batch.update(expenseRef, 
				{ 
					Name:Expense.Name, 
					BillTo:Expense.BillTo, 
					floatingPayExpense: Expense.floatingPayExpense, 
					floatingBillExpense: Expense.floatingBillExpense, 
					qty:Expense.qty, 
					rate:Expense.rate, 
					bill:Expense.bill, 
					pay:Expense.pay, 
					total:Expense.total, 
					PayTo:PayTo,
					onHold:Expense.onHold
				}
			);
        }
      
        await batch.commit();
    }

    const deleteChildExpenses = async(childExpenses)=>{
      const batch = writeBatch(db);
      for(let e=0; e<childExpenses.length; e++){
        let expenseRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Expenses',  childExpenses[e].ID);
        batch.delete(expenseRef);
      }
      await batch.commit();
    }

    const updateUnbilledExpenses = (tempDispatch, tempExpenses)=>{
        let unbilledCount=0;
        console.log('tempExpenses = ', tempExpenses)
        if(tempDispatch){
            for(let e=0; e<tempExpenses.length; e++){
                if(tempExpenses[e].bill && !tempExpenses[e].billed && !tempExpenses[e].floatingBillExpense && (tempExpenses[e].FreightBill!=='' || !tempExpenses[e].addToFreights))unbilledCount++;
            }
            if(tempDispatch?.unBilledExpenses!==unbilledCount){
                tempDispatch.unBilledExpenses=unbilledCount;
                setDispatch((prev) => ({ ...prev, unBilledExpenses:unbilledCount}));
                updateDocument({unBilledExpenses:unbilledCount}, tempDispatch.ID,'Dispatches')
            }
        }
    }
    const queryExpenses = useCallback((dispID) => {
        expensesRef.current = [];
        if (expenseListenerRef.current)expenseListenerRef.current();
        console.log('I AM OPENING A LISTENER TO THISE EXPENSES!!')
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
        const q = query(collection(db, queryName), where("dispatchID", "==", dispID));
        expenseListenerRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                let tempExpense = change.doc.data(); 
             
                tempExpense.ID = change.doc.id;
                if (change.type === "added") expensesRef.current.push(tempExpense);
                
                if (change.type === "modified") { 
                    const expenseIndex = expensesRef.current.findIndex(f => f.ID === tempExpense.ID);
                    let childExpenses=expensesRef.current.filter(expense => expense.parentExpense===tempExpense.ID && !expense.Invoice);
                    console.log('got a MODIFIED EPXENSE = ', tempExpense)
                    if(tempExpense.addToFreights){
                        if(!expensesRef.current[expenseIndex].addToFreights && childExpenses.length>0)createChildExpenses(tempExpense)
                        else if(childExpenses.length>0) updateChildExpenses(childExpenses, tempExpense);
                    }else  deleteChildExpenses(childExpenses);
                    expensesRef.current[expenseIndex] = tempExpense;
                }
                if (change.type === "removed") {
                    console.log('i am removing the expense', tempExpense)
                    const expenseIndex = expensesRef.current.findIndex(f => f.ID === tempExpense.ID);
                    let childExpenses=expensesRef.current.filter(expense => expense.parentExpense===tempExpense.ID && !expense.Invoice);
                    deleteChildExpenses(childExpenses);
                    expensesRef.current.splice(expenseIndex, 1);
                }
            });
      
          console.log('SETTING expensesRef ',expensesRef.current)
        
          setDispatchExpenses([...expensesRef.current.filter(expense => expense.FreightBill==='')])
          updateUnbilledExpenses(dispatchObjectRef?.current, expensesRef.current);
      });
    }, [gearedUser]);

    const queryFreightBills = useCallback((startDate, endDate) => {
        homeFreightBillsRef.current = [];
        if (unsubscribeFreightBillsRef.current) unsubscribeFreightBillsRef.current();
        console.log('I AM OPENING A LISTENER TO THISE FREGITH BILLS!!')
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
        unsubscribeFreightBillsRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                let tempFB = change.doc.data(); 
                tempFB.ID = change.doc.id;
                if (change.type === "added")  homeFreightBillsRef.current.push(tempFB);
                
                if (change.type === "modified") { 
                 
                    const freightIndex = homeFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
                    console.log('just got a modified freight for driver = '+ tempFB.driverName +  'and its loadORder  = '+ tempFB.loadOrder)
                    homeFreightBillsRef.current[freightIndex] = tempFB;
                }
                if (change.type === "removed") {
                    console.log('i am removing the freightbill', tempFB)
                    const freightIndex = homeFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
                    homeFreightBillsRef.current.splice(freightIndex, 1);
                }
            });
            console.log('setting the popUpFreight?.ID ' +  popUpFreightRef?.current?.ID)
            if(popUpFreightRef?.current?.ID){
                const popupFreightIndex = homeFreightBillsRef.current.findIndex(f => f.ID ===popUpFreightRef?.current?.ID);
                if(popupFreightIndex!==-1)  setPopUpFreight(homeFreightBillsRef.current[popupFreightIndex] )
            }
            setHomeFreightBills( [...homeFreightBillsRef.current]);
        });
    }, [gearedUser]);


    const queryDispatches = useCallback((startDate, endDate) => {
        homeDispatchesRef.current = [];
        if (unsubscribeDispatchesRef.current)  unsubscribeDispatchesRef.current();
         
        console.log('starting to run the query dispatches', startDate, endDate);
        console.log('gearedUser.selectedOrgName', gearedUser.selectedOrgName);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
        const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
        unsubscribeDispatchesRef.current=   onSnapshot(q, (querySnapshot) => {
            console.log('DISPATCH HOME SNAPSHOT FIRING');
            let hasChanged = false;
            querySnapshot.docChanges().forEach((change) => {
                const tempDispatch = change.doc.data();
                tempDispatch.ID = change.doc.id;
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('found a dispatch to remove and teh soruce = ' + source)
                if(source==="Server"){
                    hasChanged = true;
                    console.log('found a dispatch to do soemthign with  = ' + tempDispatch.QueryDate)
                    if (change.type === "added") {
                    
                        homeDispatchesRef.current.push(tempDispatch);
                    }
                    if (change.type === "modified") {
                        const dispatchIndex =homeDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                        homeDispatchesRef.current[dispatchIndex] = tempDispatch;
                    }
                    if (change.type === "removed") {
                        const dispatchIndex =homeDispatchesRef.current.findIndex(d => d.ID === tempDispatch.ID);
                       
                      
                       if(dispatchIndex!==-1) homeDispatchesRef.current.splice(dispatchIndex,1)
                    }
                }
            });
    
          console.log('setting full home DhomeDispatchesRef.currents = ', homeDispatchesRef.current);
          if (hasChanged)  setHomeDispatches([...homeDispatchesRef.current]);
      
      });
    }, [gearedUser]);

    const handleUpdateFreightBills = async (Dispatch, updatedFreightBills) => {
        try {
            const batch = writeBatch(db);
            console.log('updatedFreightBills when runnign handle update dfreightbills -= ', updatedFreightBills)
    
            let newDispatch = { ...Dispatch };
            let newFreightBills = [...updatedFreightBills];
            let loadOrder = 1;
            let realLoadOrder = 0;
            let count = 0;
    
            newDispatch.LoadOrders=[];
            if(!newDispatch.SimLoad )newDispatch.SimLoad=1;
            if(!newDispatch.MinBetLoad) newDispatch.MinBetLoad =''; 
            console.log('sim load = ' +newDispatch.SimLoad )
            console.log('snewDispatch.MinBetLoad = ' +newDispatch.MinBetLoad )
            console.log('newFreightBills = ',newFreightBills);
            for (var i = 0; i < updatedFreightBills.length; i++) {
                console.log('realLoadOrder = '+ realLoadOrder);
                let minDifference = realLoadOrder * Number(newDispatch.MinBetLoad);
                var oldDispatchTime= updatedFreightBills[i].dispatchTime;
                if(!updatedFreightBills[i].SetManual)updatedFreightBills[i].dispatchTime = formatDriverDispatchTime(minDifference, updatedFreightBills[i].dispatchTime, newDispatch);
                console.log('for driver = '+ updatedFreightBills[i].driverName + ' there dispathc time = ' + updatedFreightBills[i].dispatchTime)
                let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   updatedFreightBills[i].ID);
                let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   updatedFreightBills[i].ID);
                if(oldDispatchTime!==updatedFreightBills[i].dispatchTime || updatedFreightBills[i].loadOrder !== loadOrder) updatedFreightBills[i].loadOrder = loadOrder;
                  
                const homeIndex =  homeFreightBillsRef.current.findIndex(f => f.ID === updatedFreightBills[i].ID);
                if(homeIndex!==-1){
                    homeFreightBillsRef.current[homeIndex].loadOrder=loadOrder;
                    homeFreightBillsRef.current[homeIndex].dispatchTime=updatedFreightBills[i].dispatchTime;
                }
                batch.update(freightRef, { "timestamp": Date.now(), loadOrder:  updatedFreightBills[i].loadOrder,  dispatchTime:  updatedFreightBills[i].dispatchTime  });
                batch.update(driverFreightRef, { "timestamp": Date.now(), loadOrder:  updatedFreightBills[i].loadOrder,  dispatchTime:  updatedFreightBills[i].dispatchTime, fromOfficeUser:true });
                if(!updatedFreightBills[i].SetManual)count++;
                var tempLoad = {
                    loadOrder: updatedFreightBills[i].loadOrder,
                    Truck: updatedFreightBills[i].Truck.Name,
                    dispatchTime: updatedFreightBills[i].dispatchTime,
                    Name: updatedFreightBills[i].driverName
                } 
                newDispatch.LoadOrders.push(tempLoad);
         
                if (count === newDispatch.SimLoad) {
                    count = 0;
                    realLoadOrder++;
                }
                loadOrder++ 
            }

            
            setHomeFreightBills([...homeFreightBillsRef.current]);
            console.log('ne newDispatch.LoadOrders= ' , newDispatch.LoadOrders);
            let dispRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/Dispatches',  Dispatch.ID);
            batch.update(dispRef , { "timestamp": Date.now(), LoadOrders:    newDispatch.LoadOrders });
            await batch.commit();
            //setIsLoading(false);
        } catch (error) {
            console.error("Error updating freight bills: ", error);
        }
    };

    const changeLoadOrder =  (newFreight, Dispatch, freightBills) => {
        console.log('RUNNING CHANGE LOAD ORDERRRR!!', newFreight);
        let simLoad =  Dispatch.SimLoad ?  Dispatch.SimLoad : 1;
        const updates = [];
        let count = 0;
        let realLoadOrder = 1;
    
        for (let i = 0; i < freightBills.length; i++) {
            console.log('l;oad order at teh top = ' +realLoadOrder + ' for driver = ' + freightBills[i].driverName )
            if ( newFreight.loadOrder <= freightBills[i].loadOrder) {
                if( freightBills[i].loadOrder===newFreight.loadOrder){
                    count++;
                    if (count === simLoad) {
                        count = 0;
                        realLoadOrder++;
                    } 
                }
                freightBills[i].loadOrder++;
                console.log('realLoad order  = ' + realLoadOrder +' for fb  =' , freightBills[i])
                const minDifference = Number((realLoadOrder-1) * Number(Dispatch.MinBetLoad));
                if (!freightBills[i].SetManual) {
                    freightBills[i].dispatchTime = formatDriverDispatchTime(minDifference, freightBills[i].dispatchTime, Dispatch);
                }
            
                
                updates.push({
                    index: homeFreightBills.findIndex(f => f.ID === freightBills[i].ID),
                    updatedFreightBill: { ...freightBills[i] }
                });
                
                console.log('runnign teh update load order on this bitch',  freightBills[i]);
                const freightRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/FreightBills', freightBills[i].ID);
                const driverFreightRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/DriverFreightBills', freightBills[i].ID);
    
                updateDoc(freightRef, { "timestamp": Date.now(), loadOrder: freightBills[i].loadOrder, dispatchTime: freightBills[i].dispatchTime });         
                updateDoc(driverFreightRef, { "timestamp": Date.now(), loadOrder: freightBills[i].loadOrder, dispatchTime: freightBills[i].dispatchTime }).then().catch(function(error) { 
                    console.error("Error adding document trying again: ", error); 
                    setTimeout(()=>
                        updateDoc(driverFreightRef, { "timestamp": Date.now(), loadOrder: freightBills[i].loadOrder, dispatchTime: freightBills[i].dispatchTime }).then().catch(function(error) {      console.error("DID NOT UPDATE DRIVER FREIGHT: ", error); }),
                    100);
                    
                });
               
             
            }
          
            if (!freightBills[i].SetManual)count++;
            console.log('for driver = ' + freightBills[i].driverName + ' the count is now  = ' + count)
            if (count === simLoad) {
                count = 0;
                realLoadOrder++;
            } 
    
         
        }
    
      // Batch update state
        setHomeFreightBills(prevHomeFreightBills => {
            const newHomeFreightBills = [...prevHomeFreightBills];
            updates.forEach(({ index, updatedFreightBill }) => {
                newHomeFreightBills[index] = updatedFreightBill;
            });
            
            return newHomeFreightBills;
        });
   
       
    };

    const updateLoadOrders = (tempDispatch, freightBills) =>{
 
        tempDispatch.LoadOrders=[];
        for(let i=0; i<freightBills.length; i++){
            var tempLoad = {
                loadOrder: freightBills[i].loadOrder,
                Truck: freightBills[i].Truck.Name,
                dispatchTime: freightBills[i].dispatchTime,
                Name: freightBills[i].driverName
            } 
            tempDispatch.LoadOrders.push(tempLoad);
        }
    }

    const updateLoadOrdersFromPopUp = ()=>{
        let tempDispatch = dispatchObjectRef.current;
        let freightBills = dispatchFreightBillsRef.current;
        console.log(' dispatchFreightBillsRef == ', dispatchFreightBillsRef.current);
        console.log(' dispatchObjectRef.current== ', dispatchObjectRef.current);
        tempDispatch.LoadOrders=[];
        for(let i=0; i<freightBills.length; i++){
            var tempLoad = {
                loadOrder: freightBills[i].loadOrder,
                Truck: freightBills[i].Truck.Name,
                dispatchTime: freightBills[i].dispatchTime,
                Name: freightBills[i].driverName
            } 
            tempDispatch.LoadOrders.push(tempLoad);
        }
        updateDocument({LoadOrders:tempDispatch.LoadOrders}, tempDispatch.ID, 'Dispatches');
    }

    const formatDriverDispatchTime = (minuteDifference, oldStartTime,  Dispatch) => {
        let startHour;
        console.log('mindifference = '+ minuteDifference);
        console.log('oldstartime = '+ oldStartTime);
        if (Dispatch.StartTime) {
            let startMinute = Number(Dispatch.StartTime.substr(3)) + minuteDifference;
            console.log('start minute now = ' + startMinute)
            if (startMinute < 60) startHour = Number(Dispatch.StartTime.substr(0, 2));
            else {
                var hourDifference = Math.floor(startMinute / 60);
                startHour = Number(Dispatch.StartTime.substr(0, 2)) + hourDifference;
                startMinute = startMinute - (hourDifference * 60);
            }
            if (startMinute < 10) startMinute = '0' + startMinute;
            if (Number(startHour) > 23) {
                var hourDifference = Math.floor(startHour / 24);
                startHour = startHour - Number(hourDifference * 24);
            }
            if (Number(startHour) < 10) startHour = '0' + startHour;
            let driverStartTime = startHour + ':' + startMinute
         
            return driverStartTime;
        }else return oldStartTime;
    }

    const getDriverLoadOrder = function(Dispatch,FreightBill, freightBills) {
        if (Dispatch.SimLoad === '' || Dispatch.SimLoad < 1) Dispatch.SimLoad = 1;
        let driverLoadOrder = freightBills.length+1;

        console.log('getting top driver load order and maxloadorder= ' + driverLoadOrder);
        console.log('FreightBill.Priority= ' + FreightBill.Priority);
        for (var j = 0; j < freightBills.length; j++) {
            if(freightBills[j].loadOrder <driverLoadOrder){
                console.log('checking priority of freightBill = '+ freightBills[j].Priority + ' against the new FB prio = ' + FreightBill.Priority);
                if (freightBills[j].Subhauler || freightBills[j].Priority>FreightBill.Priority){
                
                driverLoadOrder = freightBills[j].loadOrder
                console.log('priority was greater than the new priority so now we setting driverLoadOrder = ' + driverLoadOrder)
                } 
            }
            }
        console.log('returning this as driver load order = '+driverLoadOrder);
        return driverLoadOrder;
    }

    const getDedicatedLoadOrder = function(Dispatch,FreightBill, freightBills) {
        if (Dispatch.SimLoad === '' || Dispatch.SimLoad < 1) Dispatch.SimLoad = 1;
    
        var newFreightLoadOrder = 1;
        console.log('reightBills.length = '+ freightBills.length)
        for (var j = 0; j < freightBills.length; j++) {
            if (freightBills[j].Subhauler){
                if(!freightBills[j].Dedicated){
                newFreightLoadOrder = freightBills[j].loadOrder;
                break;
            }else{
                if(Number(freightBills[j].Priority) >  Number(FreightBill.Priority)){
                newFreightLoadOrder = freightBills[j].loadOrder;
                break;
                }  else newFreightLoadOrder++;
            }
        } else newFreightLoadOrder++;
        
        }
        console.log('returning this as top driver load order = '+ newFreightLoadOrder);
        return newFreightLoadOrder;
    }

    const checkReleaseDispatch = (Dispatch, dispatchFreightBills) => {

        console.log('dipsathc object = ' ,Dispatch)
        return new Promise((resolve) => {
            if (Dispatch.isChanging) {
                const sortedFreightBills = [...dispatchFreightBills].sort((a, b) => a.loadOrder - b.loadOrder);
                dispatchFreightBillsRef.current = sortedFreightBills;
                console.log('dipsathc is changigng  ID !!' ,dispatchFreightBillsRef.current)
                setSelectedFreightBills(dispatchFreightBillsRef.current.reduce((acc, freightBill) => {  acc[freightBill.ID] = true;   return acc;  }, {}));
                selectedFreightBillsRef.current = dispatchFreightBillsRef.current.reduce((acc, freightBill) => {  acc[freightBill.ID] = true;   return acc;  }, {})

                const formContent = (
                    <div className='mbsc-grid'>
                        {dispatchFreightBillsRef.current.map((freightBill) => (
                            <div key={freightBill.ID}>
                                <div className="p-inputgroup mbsc-col-lg-8 mbsc-offset-lg-2"  style={{padding:'0'}}>
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup" > {freightBill.driverName}{' '}</span>
                                    <Checkbox style={{ width: '100%' }} onChange={() =>  handleCheckboxChange(freightBill.ID)} checked={selectedFreightBillsRef.current[freightBill.ID]}   />
                                </div> 
                              
                            </div>
                        ))}
                    </div>
                );
                const handleCheckboxChange = (freightBillID) => {
                    setSelectedFreightBills((prevSelected) => {
                        // Update the checkbox state
                        const newSelected = { ...prevSelected, [freightBillID]: !prevSelected[freightBillID] };
                        console.log('New selectedFreightBills:', newSelected);
                        selectedFreightBillsRef.current=newSelected;
                        const formContent = (
                            <div className='mbsc-grid'>
                                {dispatchFreightBillsRef.current.map((freightBill) => (
                                    <div key={freightBill.ID}>
                                        <div className="p-inputgroup mbsc-col-lg-8 mbsc-offset-lg-2"  style={{padding:'0'}}>
                                            <span className="p-inputgroup-addon dispatch-small-inputgroup" > {freightBill.driverName}{' '}</span>
                                            <Checkbox style={{ width: '100%' }} onChange={() =>  handleCheckboxChange(freightBill.ID)} checked={selectedFreightBillsRef.current[freightBill.ID]}   />
                                        </div> 
                                      
                                    </div>
                                ))}
                            </div>
                        );
                        setDialogText(formContent);
                        return newSelected;
                    });
                
                };
        
                // Dialog buttons
                const buttons = [
                    {
                        label: 'OK',
                        className: 'p-button-success',
                        onClick: () => {
                            // Update sendText property for each freight bill based on checkbox state
                            console.log('selectedFreightBillss =' , selectedFreightBillsRef.current)
                            const updatedFreightBills = dispatchFreightBillsRef.current.map((freightBill) => ({ ...freightBill,   sendText: selectedFreightBillsRef.current[freightBill.ID]}));
                            // Call releaseDispatch with the updated freight bills
                            console.log('updatedFreightBills =' , updatedFreightBills)
                            releaseDispatch(dispatch, updatedFreightBills, true);
                            resolve(); 
                            setDialogVisible(false); // Close dialog
                        },
                    },
                    { label: 'Cancel', className: 'p-button-secondary', onClick: () => {setDialogVisible(false);   resolve(); } },
                ];
        
                setDialogText(formContent); // Set the form content
                setDialogHeader('Please check which drivers to notify');
                setDialogButtons([...buttons]);
                setDialogVisible(true);
            } else {
                // Simple yes/no dialog for when dispatch.isChanging is false
                setDialogText('Do you want to text the drivers for the ' +Dispatch.Account.Name +' job that starts at: ' + Dispatch.StartTime +' saying that their dispatch is now ready?');
                console.log('dispatchFreightBills b4 releases ' ,dispatchFreightBills)
                const updatedFreightBills =dispatchFreightBills.map(obj => ({...obj,  sendText: true   }));
                const buttons = [
                    { label: 'Yes', className: 'p-button-success', onClick: () =>{ releaseDispatch(Dispatch, updatedFreightBills, true);  resolve(); }},
                    { label: 'No, but still release', className: 'p-button-secondary', onClick: () =>{ releaseDispatch(Dispatch, dispatchFreightBills, false); resolve(); } },
                    { label: 'Cancel', className: 'p-button-warning', onClick: () =>{ setDialogVisible(false);  resolve(); } },
                ];
        
                setDialogHeader('Release Dispatch');
                setDialogButtons([...buttons]);
                setDialogVisible(true);
            }
        });
    }; 

    const checkCancelDispatch = ()=>{
        setDialogText('Are you sure you want to cancel Dispatch?');
        console.log('dispatchFreightBills b4 releases ' ,dispatchFreightBillsRef.current)
        const updatedFreightBills =dispatchFreightBillsRef.current.map(obj => ({...obj,  sendText: true   }));
        const buttons = [
            { label: 'Yes and remove Dispatch from Billing', className: 'p-button-success', onClick: () => cancelDispatch(dispatchObjectRef.current,updatedFreightBills, false) },
            { label: 'Yes, but keep Dispatch for Billing', className: 'p-button-secondary', onClick: () => cancelDispatch(dispatchObjectRef.current,updatedFreightBills, true) },
            { label: 'Cancel', className: 'p-button-warning', onClick: () => setDialogVisible(false) },
        ];

        setDialogHeader('Cancel Dispatch');
        setDialogButtons([...buttons]);
        setDialogVisible(true);
    }
    const cancelDispatch =  async (Dispatch, FreightBills, Billable)=>{
        try {
            const batch = writeBatch(db);
            console.log('Disaptch -= ', Dispatch)
    
            const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', Dispatch.ID);

            console.log(' FreightBills -= ', FreightBills)
            releaseAndTextFreightBills(Dispatch, FreightBills, batch, 'Cancelled')
            
            setDispatch((prev) => ({ ...prev, isChanging:false, Billable: Billable, Cancelled:true }));

            batch.update(dispatchRef, {"timestamp": Date.now(), isChanging:false, Billable: Billable, Cancelled:true  });
            
            await batch.commit();
        } catch (error) {
            console.error("Error cancellingDisatpch and updated FBs : ", error);
        }
    };

    const releaseDispatch =  async (Dispatch, FreightBills,sendTexts)=>{
        try {
            const batch = writeBatch(db);
            const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', Dispatch.ID);

            console.log(' FreightBills -= ', FreightBills)
            if(sendTexts) releaseAndTextFreightBills(Dispatch, FreightBills, batch, 'Released')
            else await releaseFreightBills(FreightBills,batch)
        
            setDispatch((prev) => ({ ...prev, isChanging:false, Released:true }));
            Dispatch.isChanging=false;
            Dispatch.Released = true;

            const dispatchIndex =homeDispatchesRef?.current ? homeDispatchesRef.current.findIndex(d => d.ID === Dispatch.ID) : -1;
　　 　 　
            if(dispatchIndex!==-1){
                homeDispatchesRef.current[dispatchIndex] = {...Dispatch};
                setHomeDispatches([...homeDispatchesRef.current]);
            }
            batch.update( dispatchRef, { "timestamp": Date.now(), isChanging:false, Released:true  });
        
            await batch.commit();
			console.log('batch committed!')
        } catch (error) {
            console.error("Error releaseing Disatpch and updated FBs : ", error);
        }
    };

    const releaseFreightBills = async( FreightBills, batch)=>{
		return new Promise((resolve) => {
			let promises=[];
			for (var f = 0; f< FreightBills.length; f++) promises.push(releaseFreightBill(FreightBills[f], batch));
			resolve(Promise.all(promises))
		});
    }

    const releaseFreightBill = async(FreightBill,  batch)=>{
		return new Promise((resolve) => {
        	console.log('Releasing FB withtout Texting = ', FreightBill)
        	let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   FreightBill.ID);
        	let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   FreightBill.ID);
        
			resolve(batch.update(freightRef, { "timestamp": Date.now(), missing: true, textSent:FreightBill.textSent,  Released:true, sendText:false  }),
        	batch.update(driverFreightRef, { "timestamp": Date.now(), missing: true, Released:true, fromOfficeUser:true }))
		})
    }

    const releaseAndTextFreightBills = (Dispatch, FreightBills, batch, textType) => {
        var dispatchDayText = new Date(Dispatch.JobDate).toLocaleDateString('en-US', { weekday: 'long' });
        let encodedOrgName = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27')
        let sendTextFreights=[];
        for (var f = 0; f< FreightBills.length; f++) {
            let foundPhoneNumber=false;
            if( FreightBills[f].sendText){
            for(var j=0; j < sendTextFreights.length; j++){
                if(sendTextFreights[j].phoneNumber===FreightBills[f].phoneNumber && sendTextFreights[j].Driver===FreightBills[f].Driver){
                foundPhoneNumber=true;
                sendTextFreights[j].TrucksAssigned++;  
                if(FreightBills[f].dispatchTime<sendTextFreights[j].dispatchTime){
                    console.log('now going to text freight bill with start time of ' + FreightBills[f].dispatchTime + ' instead o f freight bill with start time = ' +sendTextFreights[j].dispatchTime );
                    releaseFreightBill(sendTextFreights[j], batch);
                    FreightBills[f].TrucksAssigned=	sendTextFreights[j].TrucksAssigned;
                    sendTextFreights[j]= {...FreightBills[f]};
                }else{
                    FreightBills[f].textSent = true;
                    releaseFreightBill(FreightBills[f], batch);
                } 
                }
            }  
            
            console.log('found phone number = ', foundPhoneNumber)
            if(!foundPhoneNumber){
                FreightBills[f].TrucksAssigned=1;
                sendTextFreights.push({...FreightBills[f]});	
            } 
            }else{
            FreightBills[f].textSent = true;
            releaseFreightBill(FreightBills[f], batch);
            }          
        }
        console.log(' sendTextFreights = ',  sendTextFreights);
        for(var i = 0; i<sendTextFreights.length; i++) releaseAndTextFreightBill(sendTextFreights[i], dispatchDayText, encodedOrgName, batch, textType)
    }

    const releaseAndTextFreightBill = (FreightBill, dispatchDayText, encodedOrgName, batch, textType)=>{
        var mobileDispLink, DispatchText;
        console.log('RELEASEING AND TEXTING FRIEGHT IBLLS')
        let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   FreightBill.ID);
        let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   FreightBill.ID);
        if(textType==='Cancelled'){
            DispatchText =  'Your Dispatch for ' + dispatchDayText + ' ' + FreightBill.JobDate + ' and starting at ' + FreightBill.dispatchTime + ' has been Cancelled.'
            mobileDispLink= 'Please click this link to verify you have received this text geared.alianzatechnologies.com/dispatch/' + encodedOrgName + '/' + FreightBill.ID;
        }else{
            mobileDispLink ='Please go to geared.alianzatechnologies.com/dispatch/' + encodedOrgName + '/' + FreightBill.ID + ' to see the details.';
            if(FreightBill.TrucksAssigned>1){
                mobileDispLink ='Please go to geared.alianzatechnologies.com/trucksdispatch/' + encodedOrgName + '/' + FreightBill.ID + ' to see the details.';
                DispatchText = 'You have a Dispatch for ' + FreightBill.TrucksAssigned +' trucks on '+  dispatchDayText + ' '  + FreightBill.JobDate + '. ';
            }else DispatchText ='You have a Dispatch for ' +  dispatchDayText + ' ' + FreightBill.JobDate +'. ';
        }
        let textData = {
            OrganizationName: gearedUser.selectedOrgName,
            FreightID: FreightBill.ID,
            body: DispatchText + mobileDispLink,
            from: company.twilioNumber,
            to: FreightBill.phoneNumber
        };
        console.log('text Data = ', textData)
        batch.update(freightRef, { "timestamp": Date.now(), missing: true, ReceivedTime:'', Received:false, [textType]:true, fromReact:true, sendText:true, textData:textData });
        batch.update(driverFreightRef, { "timestamp": Date.now(), missing: true, ReceivedTime:'', Received:false, [textType]:true,fromOfficeUser:true });
    }

    const textFreight = (FreightBill, dispatchDayText, encodedOrgName,textType)=>{
        var mobileDispLink, DispatchText;
    
        if(textType==='Cancelled'){
            DispatchText =  'Your Dispatch for ' + dispatchDayText + ' ' + FreightBill.JobDate + ' and starting at ' + FreightBill.dispatchTime + ' has been Cancelled.'
            mobileDispLink= 'Please click this link to verify you have received this text geared.alianzatechnologies.com/dispatch/' + encodedOrgName + '/' + FreightBill.ID;
        }else{
            mobileDispLink ='Please go to geared.alianzatechnologies.com/dispatch/' + encodedOrgName + '/' + FreightBill.ID + ' to see the details.';
            if(FreightBill.TrucksAssigned>1){
                mobileDispLink ='Please go to geared.alianzatechnologies.com/trucksdispatch/' + encodedOrgName + '/' + FreightBill.ID + ' to see the details.';
                DispatchText = 'You have a Dispatch for ' + FreightBill.TrucksAssigned +' trucks on '+  dispatchDayText + ' '  + FreightBill.JobDate + '. ';
            }else DispatchText ='You have a Dispatch for ' +  dispatchDayText + ' ' + FreightBill.JobDate +'. ';
        }
        let textData = {
            OrganizationName: gearedUser.selectedOrgName,
            FreightID: FreightBill.ID,
            body: DispatchText + mobileDispLink,
            from: company.twilioNumber,
            to: FreightBill.phoneNumber
        };
        let updateDoc ={
            "timestamp": Date.now(), 
            missing: true, 
            ReceivedTime:'', 
            Received:false, 
          
          
        }
        updateDocument(updateDoc, FreightBill.ID, 'DriverFreightBills');
        updateDoc.fromReact=true;
        updateDoc.sendText=true;
        updateDoc.textData=textData;

        updateDocument(updateDoc, FreightBill.ID, 'FreightBills');
    }
    
    const unCancelDispatch =  async ()=>{
        try {
            const batch = writeBatch(db);
            console.log('Disaptch -= ', dispatchObjectRef.current)
    
            const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', dispatchObjectRef.current.ID);
            for (var i = 0; i < dispatchFreightBillsRef.current.length; i++) {
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   dispatchFreightBillsRef.current[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',  dispatchFreightBillsRef.current[i].ID);

            batch.update(freightRef, { "timestamp": Date.now(), missing: true,  Cancelled:false  });
            batch.update(driverFreightRef, { "timestamp": Date.now(), missing: true, Cancelled:false,fromOfficeUser:true  });
            }
    
            batch.update( dispatchRef, { "timestamp": Date.now(), isChanging:false, Cancelled:false  });
            setDispatch((prev) => ({ ...prev,isChanging:false, Cancelled:false }));
            await batch.commit();
        } catch (error) {
            console.error("Error cancelnilin Disatpch and updated FBs : ", error);
        }
    };
  
    const unReleaseDispatch =  async (Dispatch, FreightBills)=>{
        try {
            const batch = writeBatch(db);
            console.log('Disaptch -= ', Dispatch)
    
            const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', Dispatch.ID);

            for (var i = 0; i < FreightBills.length; i++) {
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   FreightBills[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   FreightBills[i].ID);

            batch.update(freightRef, { "timestamp": Date.now(), missing: true, ReceivedTime:'', Received:false, Released:false  });
            batch.update(driverFreightRef, { "timestamp": Date.now(), missing: true, ReceivedTime:'', Received:false, Released:false, fromOfficeUser:true });
            }
    
            batch.update( dispatchRef, { "timestamp": Date.now(), isChanging:false, Released:false });
            setDispatch((prev) => ({ ...prev,isChanging:false, Released:false }));
            await batch.commit();
        } catch (error) {
            console.error("Error releaseing Disatpch and updated FBs : ", error);
        }
    };
 
  
    const addFreightBill =  async(freightBill, Dispatch, freightBills, tempExpenses, driver) => {
        //setAssigning(true);
        const batch = writeBatch(db);
        const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', Dispatch.ID);

        let simLoad = Dispatch.SimLoad ? Dispatch.SimLoad:1;
   
        let billable = false;
        if (freightBill.Cancelled)  freightBill.Style = { 'background': 'rgb(234, 67, 53)' }; else  freightBill.Style = { 'background': '#fff' };
         
    
        Dispatch.TrucksAssigned = freightBills.length;
        tempExpenses = tempExpenses ? tempExpenses : expensesRef.current;

        if (!freightBill.Subhauler) freightBill.loadOrder = getDriverLoadOrder(Dispatch, freightBill, freightBills);
        else if (freightBill.Dedicated) freightBill.loadOrder = getDedicatedLoadOrder(Dispatch, freightBill, freightBills);
        else  freightBill.loadOrder = Dispatch.TrucksAssigned + 1;

        let unBilledCount =0;
        let unbilledMaterialCount =0
        for(let q=0; q<freightBills.length; q++) {
            if(!freightBills[q].billed)unBilledCount++;
            if(!freightBills[q].materialBilled && freightBills[q].MaterialCompany.ID!==Dispatch.Company.ID)unbilledMaterialCount++;
        } 
        if(!freightBill.materialBilled && freightBill.MaterialCompany.ID!==Dispatch.Company.ID)unbilledMaterialCount++;
        unBilledCount++;
        Dispatch.TrucksAssigned++;
        if((unbilledMaterialCount+unBilledCount)>0) billable=true;


        let loadDivision = freightBill.loadOrder / simLoad;
        let roundedDown = Math.floor(loadDivision);
        let minDifference = 0;
        minDifference = (loadDivision % 1 === 0) ? (roundedDown - 1) * Number(Dispatch.MinBetLoad) : roundedDown * Number(Dispatch.MinBetLoad); 
        freightBill.dispatchTime = formatDriverDispatchTime(minDifference, ' ', Dispatch);
        changeLoadOrder(freightBill, Dispatch, freightBills); 
        freightBills.push({...freightBill})
        updateLoadOrders(Dispatch,freightBills);

        console.log('DispatchLoadORdesr after we add an fb = ', Dispatch.LoadOrders)

        // Create a new document reference for each Expense (with a generated ID)
        const newFreightRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/FreightBills'));
        freightBill.ID= newFreightRef.id; // Get the generated Expense ID
   
        // Add the Expense to the batch
        batch.set(newFreightRef,  freightBill);
 
        let tempDispExpenses = tempExpenses.filter(expense => expense.FreightBill==='' && expense.addToFreights);
       
        for(var d=0; d< tempDispExpenses.length; d++){
            let tempExp= createChildExpense(freightBill,  tempDispExpenses[d]);
            let expenseRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`, tempExp.ID);
            tempExpenses.push(tempExp);
            batch.set(expenseRef, tempExp );
        }

        let unbilledExpensesCount =0;
   
        for(let e=0; e<tempExpenses.length; e++){
            if(tempExpenses[e].bill && !tempExpenses[e].billed && !tempExpenses[e].floatingBillExpense && (tempExpenses[e].FreightBill!=='' || !tempExpenses[e].addToFreights))unbilledExpensesCount++;
        }
        Dispatch.unBilledExpenses=unbilledExpensesCount;
        console.log('unbilledExpensesCount;= ' + unbilledExpensesCount)
        batch.update(dispatchRef, {
            "timestamp": Date.now(), 
            TrucksAssigned: Dispatch.TrucksAssigned, 
            unBilledFreights:unBilledCount, 
            Billable:billable, 
            LoadOrders:Dispatch.LoadOrders,
            unBilledExpenses:unbilledExpensesCount,
            unBilledMaterials:unbilledMaterialCount
        });
        driver.assigning=false;

        setDispatch((prev) => ({ ...prev, unBilledExpenses:unbilledExpensesCount,TrucksAssigned: Dispatch.TrucksAssigned, Billable:billable,   unBilledFreights:unBilledCount, unBilledMaterials:unbilledMaterialCount}));
        dispatchObjectRef.current = {
            ...dispatchObjectRef.current,
            TrucksAssigned: Dispatch.TrucksAssigned, 
            unBilledFreights:unBilledCount, 
            Billable:billable, 
            LoadOrders:Dispatch.LoadOrders,
            unBilledExpenses:unbilledExpensesCount,
            unBilledMaterials:unbilledMaterialCount
        };

        let driverFreight ={...freightBill};
        driverFreight.fromOfficeUser = true;
        console.log('freightbill.ID = ' + freightBill.ID)
        let driverFBRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/DriverFreightBills`,freightBill.ID);
        addAuditLog('AddFreightBill', 'FreightBills', freightBill.ID, freightBill);
        batch.set(driverFBRef, freightBill);
        batch.commit();
     
  
      // Update state with new freight bill
   //   setHomeFreightBills(prevHomeFreightBills => [...prevHomeFreightBills, freightBill]);
  	};


    const deleteFreightBills = async ()=>{
        const batch = writeBatch(db);
        const dispatchRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches', dispatch.ID);
        const tempFreightBills = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === dispatch.ID).map(freightBill => ({ ...freightBill })); 
        for (let i = 0; i < tempFreightBills.length; i++) {
            
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   tempFreightBills[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   tempFreightBills[i].ID);

            batch.delete(freightRef);
            batch.delete(driverFreightRef);
        }
        batch.update(dispatchRef, {"timestamp": Date.now(), TrucksAssigned:0, unBilledFreights:0, Billable:false, unBilledMaterials:0 });
        await batch.commit();
    }

    const deleteFreightBillsByTruck = async (tempDispatch, driverID, amountToDelete, selectedTruckTypeID) => {
        try {
            const batch = writeBatch(db);
        
            // Reference to the dispatch document you're updating
            const dispatchRef = doc( db,  "Organizations", gearedUser.selectedOrgName,"Dispatches",  tempDispatch.ID  );
        

            const freightBillsToDelete = homeFreightBillsRef.current.filter((freightBill) =>freightBill.dispatchID === tempDispatch.ID && freightBill.Driver === driverID && freightBill.TruckType.ID === selectedTruckTypeID)
                .slice(0, amountToDelete)
                .map((freightBill) => ({ ...freightBill }));
            console.log(';freightbills to delet = ', freightBillsToDelete)
            // Loop through the filtered and sliced freight bills and delete them
            for (let i = 0; i < freightBillsToDelete.length; i++) {
                const freightRef = doc( db, "Organizations", gearedUser.selectedOrgName,"FreightBills",  freightBillsToDelete[i].ID );
                const driverFreightRef = doc(  db, "Organizations",  gearedUser.selectedOrgName, "DriverFreightBills",  freightBillsToDelete[i].ID  );
        
                // Mark them for deletion in the batch
                batch.delete(freightRef);
                batch.delete(driverFreightRef);
            }
            const leftoverFreightBills = homeFreightBillsRef.current.filter((fb) =>fb.dispatchID === tempDispatch.ID &&  !freightBillsToDelete.some((deletedFB) => deletedFB.ID === fb.ID));
        
            // 4) Count unBilledFreights and unBilledMaterials from the leftoverFreightBills
            let unBilledCount = 0;
            let unBilledMaterialCount = 0;
            let billable = false;
            for (let q = 0; q < leftoverFreightBills.length; q++) {
                const bill = leftoverFreightBills[q];
                if (!bill.billed) unBilledCount++;
                if (!bill.materialBilled && bill.MaterialCompany?.ID !== tempDispatch.Company?.ID)  unBilledMaterialCount++;

            }
            billable =  (unBilledCount+unBilledMaterialCount) > 0 ? true : false;
            // Optionally, update the dispatch document (fields are placeholders)
            batch.update(dispatchRef, { timestamp: Date.now(),  TrucksAssigned: leftoverFreightBills.length,   unBilledFreights: unBilledCount, Billable:  billable,  unBilledMaterials: unBilledMaterialCount });
            console.log(' leftoverFreightBills = ' ,  leftoverFreightBills)
            setDispatch((prev) => ({ ...prev,  TrucksAssigned: leftoverFreightBills.length ,unBilledFreights: unBilledCount, Billable:  billable,  unBilledMaterials: unBilledMaterialCount }));

            // Commit all batched operations
            await batch.commit();
            handleUpdateFreightBills(tempDispatch,leftoverFreightBills );
            console.log(`Successfully deleted up to ${freightBillsToDelete.length} freight bills for driver: ${driverID}`);
        } catch (error) {
            console.error("Error deleting freight bills:", error);
        }
    };


    const  handleEmailChange = (value) => {
        console.log('new value = ', value)
        prelimEmailRef.current=value;
        setPrelimEmail(value );
        
    };


    const openDispatchDatePopUp = (actionType) =>{

        let tempDate =   new Date(dispatchObjectRef.current.JobDate);
        tempDate.setDate(tempDate.getDate()+1);
        let tempFormattedDate =formatDate(tempDate,'/','MM/DD/YYYY')
        console.log('tempFormattedDate = ' + tempFormattedDate)
        setDispatchDate({  JobDate:tempFormattedDate,   JobDateValue: tempDate, actionType:actionType,copyDrivers:false })
        setDispatchDatePopUpVisible(true);
    
    }

 

  
    const moveFreightBillsForNewJob = (dispatchDateObject,  jobID, batch)=>{
    
        const tempFreightBills = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === dispatch.ID).map(freightBill => ({ ...freightBill })); 
        for (let i = 0; i < tempFreightBills.length; i++) {
            
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   tempFreightBills[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   tempFreightBills[i].ID);

            batch.update(freightRef, { "timestamp": Date.now(), JobID: jobID, JobDate: dispatchDateObject.JobDate , QueryDate:  formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD') });
            batch.update(driverFreightRef, { "timestamp": Date.now(), JobID: jobID, JobDate: dispatchDateObject.JobDate , QueryDate: formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD'), fromOfficeUser:true });
        }
     
    }

    const moveOrCopyDispatch =(dispatchDateObject)=>{
        console.log('dispatchDateObject = ',dispatchDateObject )
		if(dispatchDateObject.actionType==='Copy Job'){
			copyJob(dispatchDateObject);
			setDispatchDatePopUpVisible(false);
		}else{
			let dateWarning = false;
			let latestDispatchDate = formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD');
			let tempJobID = ''
			for( let i =0; i<jobDispatchesRef.current.length; i++){
				tempJobID=jobDispatchesRef.current[i].Job.ID;
				if(jobDispatchesRef.current[i].QueryDate>latestDispatchDate) latestDispatchDate=jobDispatchesRef.current[i].QueryDate;
				if(jobDispatchesRef.current[i].JobDate === dispatchDateObject.JobDate) dateWarning =true;
			} 
				
		
			if( dateWarning){
				if (window.confirm('Warning: Dispatch already exists for this day. Do you want to continue? ')) {	
					if(dispatchDateObject.actionType==='Copy')copyDispatch(dispatchDateObject)
					if(dispatchDateObject.actionType==='Move')moveDispatch(dispatchDateObject)
					setDispatchDatePopUpVisible(false);
					updateDocument({latestDispatchDate:latestDispatchDate},  tempJobID,'Jobs')
					if(job.PrevailingWage)handlePrevailingWageReportCreate(job,{latestDispatchDate:latestDispatchDate})
				}
			}else{
				if(dispatchDateObject.actionType==='Copy')copyDispatch(dispatchDateObject)
				if(dispatchDateObject.actionType==='Move')moveDispatch(dispatchDateObject)
				setDispatchDatePopUpVisible(false);
				updateDocument({latestDispatchDate:latestDispatchDate},  tempJobID,'Jobs')
				if(job.PrevailingWage)handlePrevailingWageReportCreate(job,{latestDispatchDate:latestDispatchDate})
			}
		}

    }

	const copyJob = async(dispatchDateObject)=>{
        let tempJob =resetJob({...job}, dispatchDateObject.JobDateValue);
		setIsLoading(true);
		
        const newJobRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/Jobs'));
        const newJobID = newJobRef.id; 

     
       	const batch = writeBatch(db);
	
		// Create a new document reference for Dispatch (with a generated ID)
		const newDispatchRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches'));
		const newDispatchID = newDispatchRef.id; // Get the generated Dispatch ID
	
		let newDispatch = resetDispatch({ ...dispatch });
		newDispatch.JobDate = dispatchDateObject.JobDate;
        newDispatch.JobDateValue = dispatchDateObject.JobDateValue;
        newDispatch.QueryDate = formatDate(dispatchDateObject.JobDateValue, '/', 'YYYY/MM/DD');
        newDispatch.QueryDateValue = dispatchDateObject.JobDateValue;
        newDispatch.JobID= newJobID;
		newDispatch.Job.ID=newJobID;
		newDispatch.Billable = true;
		newDispatch.ID = newDispatchID;  // Set the Dispatch ID in the object
		const encodedDate = encodeURIComponent(newDispatch.QueryDate); 
		console.log(' newJobID  = ' +  newJobID)
		console.log('newDispatch = ' +  newDispatch.JobID)

		let newExpenses = [];
		for (let j = 0; j < dispatchExpenses.length; j++) {
			let newExpense = { ...dispatchExpenses[j] };
			newExpense.ID = '';  // Reset expense ID
			newExpense.dispatchID = newDispatchID; // Link to new Dispatch
			newExpense.QueryDate = newDispatch.QueryDate;
			newExpense.JobDate= newDispatch.JobDate;

			// Create a new document reference for each Expense (with a generated ID)
			const newExpenseRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/Expenses'));
			const newExpenseID = newExpenseRef.id; // Get the generated Expense ID
			newExpense.ID = newExpenseID;  // Set the Expense ID in the object

			// Add the Expense to the batch
			batch.set(newExpenseRef, newExpense);
			
			newExpenses.push(newExpense); // Add to the newExpenses array
		}
		console.log('newExpenses = ', newExpenses)

		console.log('    homeFreightBillsRef.current = '  ,    homeFreightBillsRef.current)
		if (dispatchDateObject.copyDrivers) {
			
			let addDocRef = collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/FreightBills');
			const tempFreightBills = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === dispatch.ID).map(freightBill => ({ ...freightBill }));
			newDispatch.TrucksAssigned=tempFreightBills.length;
			newDispatch.unBilledFreights=tempFreightBills.length;
			if(newDispatch.SellMaterial && newDispatch.Company.ID!==newDispatch.MaterialCompany?.ID)newDispatch.unBilledMaterials=tempFreightBills.length;
			const secondsSinceEpoch = Math.round(Date.now() / 1000);
			let fullDate = new Date();
	
			fullDate =fullDate.toLocaleString("en-US");
		
			for (let i = 0; i < tempFreightBills.length; i++) {
				let newFreightBill = resetFreightBill(tempFreightBills[i], dispatchDateObject.JobDateValue, newDispatch.ID);  
				newFreightBill.JobID=newJobID;
				
				const newFreightBillDocRef = doc(addDocRef);
				const newFreightBillID = newFreightBillDocRef.id; // Get the generated ID before adding to batch
				newFreightBill.ID = newFreightBillID;
				batch.set(newFreightBillDocRef, newFreightBill);
				const auditLogRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/AuditLogs/FreightBills/'+newFreightBillID, secondsSinceEpoch.toString());
					
				const newAuditLog={
					User:gearedUser.Email,
					ID:secondsSinceEpoch,
					DateAndTime: fullDate,
					ActivityType:'copyDriverNewJobFreightBill', 
					ObjectType:'FreightBills',
					Value: newFreightBill
				}
				batch.set(auditLogRef,newAuditLog);
				for (let j = 0; j < newExpenses.length; j++) {
					let expense = newExpenses[j];
					console.log('expense = ', expense)
					if (newExpenses[j].addToFreights) {
						let childExpense = createChildExpense(newFreightBill, expense);
						console.log('childExpense = ', childExpense)
						const childExpenseID = expense.ID + newFreightBill.ID;
						const childExpenseRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Expenses', childExpenseID);  // Set the document ID explicitly
						childExpense.ID = childExpenseID;  // Set the expense ID
						batch.set(childExpenseRef, childExpense);   // Add the child expense to the batch
					}
				}
			}
			if (company.UseAutoFBNum)  updateDocument(company, company.ID, 'Preferences');
			
		} else{
			newDispatch.unBilledFreights=0;
			newDispatch.unBilledMaterials=0;
		}
		console.log('newDispatch. = '+ newDispatch.unBilledFreights)
		batch.set(newDispatchRef, newDispatch);
		batch.set(newJobRef, tempJob);
		setDispatch({...newDispatch});
		
		await batch.commit();
		setIsLoading(false);
		navigate(`/dispatch/edit/${newDispatch.ID}/${newDispatch.JobID}/${encodedDate}/DispatchEdit`);
		console.log('newDispatch = ' , newDispatch)
	
	
	}

    const copyDispatch =async (dispatchDateObject)=>{
        setIsLoading(true);
        const batch = writeBatch(db);
    
        // Create a new document reference for Dispatch (with a generated ID)
        const newDispatchRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/Dispatches'));
        const newDispatchID = newDispatchRef.id; // Get the generated Dispatch ID
    
        let newDispatch = resetDispatch({ ...dispatch });
        newDispatch.JobDate = dispatchDateObject.JobDate;
        newDispatch.JobDateValue = dispatchDateObject.JobDateValue;
        newDispatch.QueryDate = formatDate(dispatchDateObject.JobDateValue, '/', 'YYYY/MM/DD');
        newDispatch.QueryDateValue = dispatchDateObject.JobDateValue;
        newDispatch.Billable = true;
        newDispatch.ID = newDispatchID;  // Set the Dispatch ID in the object
        const encodedDate = encodeURIComponent(newDispatch.QueryDate); 
    

        let newExpenses = [];
        for (let j = 0; j < dispatchExpenses.length; j++) {
            let newExpense = { ...dispatchExpenses[j] };
            newExpense.ID = '';  // Reset expense ID
            newExpense.dispatchID = newDispatchID; // Link to new Dispatch
            newExpense.QueryDate = newDispatch.QueryDate;
            newExpense.JobDate= newDispatch.JobDate;

            // Create a new document reference for each Expense (with a generated ID)
            const newExpenseRef = doc(collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/Expenses'));
            const newExpenseID = newExpenseRef.id; // Get the generated Expense ID
            newExpense.ID = newExpenseID;  // Set the Expense ID in the object

            // Add the Expense to the batch
            batch.set(newExpenseRef, newExpense);
            
            newExpenses.push(newExpense); // Add to the newExpenses array
        }
        console.log('newExpenses = ', newExpenses)

        console.log('    homeFreightBillsRef.current = '  ,    homeFreightBillsRef.current)
        if (dispatchDateObject.copyDrivers) {
            
            let addDocRef = collection(db, 'Organizations/' + gearedUser.selectedOrgName + '/FreightBills');

            const tempFreightBills = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === dispatch.ID).map(freightBill => ({ ...freightBill }));
            newDispatch.TrucksAssigned=tempFreightBills.length;
            newDispatch.unBilledFreights=tempFreightBills.length;
            if(newDispatch.SellMaterial && newDispatch.Company.ID!==newDispatch.MaterialCompany?.ID)newDispatch.unBilledMaterials=tempFreightBills.length;
            const secondsSinceEpoch = Math.round(Date.now() / 1000);
            let fullDate = new Date();
    
           fullDate =fullDate.toLocaleString("en-US"); 
        
            for (let i = 0; i < tempFreightBills.length; i++) {
                let newFreightBill = resetFreightBill(tempFreightBills[i], dispatchDateObject.JobDateValue, newDispatch.ID);  
                const newFreightBillDocRef = doc(addDocRef);
                const newFreightBillID = newFreightBillDocRef.id; // Get the generated ID before adding to batch
				console.log('newFreightBillID = '+ newFreightBillID)
				let newDriverFreightBillRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/DriverFreightBills', newFreightBillID);
                newFreightBill.ID = newFreightBillID;
			
                batch.set(newFreightBillDocRef, newFreightBill);
				batch.set(newDriverFreightBillRef, newFreightBill);
                const auditLogRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/AuditLogs/FreightBills/'+newFreightBillID, secondsSinceEpoch.toString());
                  
                const newAuditLog={
                    User:gearedUser.Email,
                    ID:secondsSinceEpoch,
                    DateAndTime: fullDate,
                    ActivityType:'copyDriverNewDispatchFreightBill', 
                    ObjectType:'FreightBills',
                    Value: newFreightBill
                }
                batch.set(auditLogRef,newAuditLog);
                for (let j = 0; j < newExpenses.length; j++) {
                    let expense = newExpenses[j];
                    console.log('expense = ', expense)
                    if (newExpenses[j].addToFreights) {
                        let childExpense = createChildExpense(newFreightBill, expense);
                        console.log('childExpense = ', childExpense)
                        const childExpenseID = expense.ID + newFreightBill.ID;
                        const childExpenseRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/Expenses', childExpenseID);  // Set the document ID explicitly
                        childExpense.ID = childExpenseID;  // Set the expense ID
                        batch.set(childExpenseRef, childExpense);   // Add the child expense to the batch
                    }
                }
            }
            if (company.UseAutoFBNum)  updateDocument(company, company.ID, 'Preferences');
            
            
        } else{
            newDispatch.unBilledFreights=0;
            newDispatch.unBilledMaterials=0;
        }
        console.log('newDispatch. = '+ newDispatch.unBilledFreights)
        batch.set(newDispatchRef, newDispatch);
        setDispatch({...newDispatch});
        await batch.commit();
        setIsLoading(false);
        navigate(`/dispatch/edit/${newDispatch.ID}/${newDispatch.JobID}/${encodedDate}/DispatchEdit`);
        console.log('newDispatch = ' , newDispatch)


    }

    const moveDispatch =(dispatchDateObject)=>{

        let newDispatch ={...dispatch};
       
        newDispatch.JobDate=dispatchDateObject.JobDate;
        newDispatch.JobDateValue=dispatchDateObject.JobDateValue;
        newDispatch.QueryDate=formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD')
        newDispatch.QueryDateValue=dispatchDateObject.JobDateValue;
        setDispatch({...newDispatch});
        let  updatedDispatch={ JobDate: newDispatch.JobDate,  QueryDate:  newDispatch.QueryDate };
        const encodedDate = encodeURIComponent(newDispatch.QueryDate); 
        navigate(`/dispatch/edit/${newDispatch.ID}/${newDispatch.JobID}/${encodedDate}/DispatchEdit`);
        updateDocument(updatedDispatch, newDispatch.ID, 'Dispatches');
        if(dispatchDateObject.copyDrivers)  moveFreightBills(dispatchDateObject);
        else deleteFreightBills();
    }
    const moveFreightBills = async (dispatchDateObject)=>{
        const batch = writeBatch(db);
        const tempFreightBills = homeFreightBillsRef.current.filter(freightBill => freightBill.dispatchID === dispatch.ID).map(freightBill => ({ ...freightBill })); 
        for (let i = 0; i < tempFreightBills.length; i++) {
            
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   tempFreightBills[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',   tempFreightBills[i].ID);

            batch.update(freightRef, { "timestamp": Date.now(), JobDate: dispatchDateObject.JobDate , QueryDate:  formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD') });
            batch.update(driverFreightRef, { "timestamp": Date.now(), JobDate: dispatchDateObject.JobDate , QueryDate: formatDate(dispatchDateObject.JobDateValue,'/','YYYY/MM/DD'), fromOfficeUser:true });
        }
        await batch.commit();
    }
    const resetJob = (tempJob, dispatchDate)=>{
        tempJob.ID='';
        tempJob.UnreadFreights = 0;
		tempJob.dispatchIDs=[];
		tempJob.JobDateValue=dispatchDate;
		tempJob.latestDispatchDate=formatDate(dispatchDate,'/','YYYY/MM/DD');
		tempJob.QueryDate=formatDate(dispatchDate,'/','YYYY/MM/DD');
		tempJob.JobDate=formatDate(dispatchDate,'/','MM/DD/YYYY');
		tempJob.dispatchCount = 1;
		tempJob.unBilledFreights=0;
		tempJob.unBilledMaterials=0;
		tempJob.TrucksAssigned=0;


        return tempJob;
    }
    const resetDispatch = (tempDispatch)=>{
        tempDispatch.ID='';
        tempDispatch.UnreadFreights = 0;
   
        tempDispatch.Invoice = '';
        tempDispatch.billedFreights = 0;
        tempDispatch.Billable = true;
        tempDispatch.Released = false;
        tempDispatch.isChanging = false;
        tempDispatch.JobDate = dispatchDate;
        tempDispatch.Cancelled = false;
        tempDispatch.TrucksAssigned = 0;
        tempDispatch.StartTime = '';
        tempDispatch.TrucksOrdered = '';
        tempDispatch.FirstDispatch = false;
        return tempDispatch;
    }

    const resetFreightBill = (FreightBill, dispatchDate, dispID)=> {
        let FBNO = '';
        if (company.UseAutoFBNum) {
            company.CurrentFBNumber++;
            FBNO = company.CurrentFBNumber.toString();
        }
        
        console.log('resetting freight and new FBNO = ' + FBNO);
        FreightBill.dispatchID = dispID;
        FreightBill.ID = '';
        FreightBill.FBNO = FBNO;
    
        FreightBill.JobDate = formatDate(dispatchDate,'/','MM/DD/YYYY');
        FreightBill.QueryDate = formatDate(dispatchDate,'/','YYYY/MM/DD');
        FreightBill.billed = false;
        FreightBill.paid = false;
		FreightBill.textSent = false;
        FreightBill.truckingPaid = false;
        FreightBill.truckingBilled = false;
        FreightBill.standByIsPaid = false;
        FreightBill.standByIsBilled = false;
        FreightBill.hourMinIsPaid = false;
    
        FreightBill.Cancelled = false;
        FreightBill.materialBilled = false;
		FreightBill.Versions=[{VNum:1,URL:''}];
        FreightBill.DPS = '';
        FreightBill.Invoice = '';
        FreightBill.FBTurnInDate = '';
      
        FreightBill.Status='Loading';
        FreightBill.fbTurnIn = '';
        FreightBill.runningTime = '';
        FreightBill.arriveRoundTrip = '';
        FreightBill.departRoundTrip = '';
        FreightBill.AverageRoundTrip = '';
        FreightBill.AverageLoadTime = '';
        FreightBill.AverageDumpTime ='';
        FreightBill.startTime = '';
        FreightBill.dispatchTime = '';
        FreightBill.endTime = '';
        FreightBill.startTimePaid = '';
        FreightBill.paidTravelTime = '';
        FreightBill.endTimePaid = '';
        FreightBill.realEndTime = '';
        FreightBill.tripTotalMinutes = 0;
        FreightBill.paidHoursWorked = 0;
        FreightBill.driverNote = '';
        FreightBill.mobileDepartRoundTrip='';
        FreightBill.mobileArriveRoundTrip='';
        FreightBill.mobileStartTime = '';
        FreightBill.mobileStartTimePaid = '';
        FreightBill.mobileendTimePaid = '';
        FreightBill.mobileendTime = '';
        FreightBill.FBNoteToDriverQuill={};
        FreightBill.PDFurl = '';
        FreightBill.InvoiceVNums = [];
        FreightBill.payDifference = 0;
        FreightBill.VNum = 1;
        FreightBill.lunch = '';
        FreightBill.hourMinTotalPaid=0;
		FreightBill.driverSignature='';
        FreightBill.approveSignature='';
		FreightBill.ShipperApproved='';
	    FreightBill.ReceiverApproved='';
		FreightBill.ShipperPDFurl='';
		FreightBill.ReceiverApproved='';
        FreightBill.Adjusting = false;
        FreightBill.ParentFreight = '';
        FreightBill.lunchPaid = '';
        FreightBill.loads = '';
        FreightBill.odStart = '';
        FreightBill.odEnd = '';
        FreightBill.billedQty = '';
        FreightBill.paidQty = '';
        FreightBill.travelTime = '';
        FreightBill.paidTravelTime = '';
        FreightBill.tFee = '';
        FreightBill.tBilled = '';
        FreightBill.matTotal = '';
        FreightBill.emailCustomer = false;
        FreightBill.billedFees = '';
        FreightBill.bTotal = '';
        FreightBill.totalDriverPay = '';
        FreightBill.totalTruckPay = '';
        FreightBill.profit = '';
        FreightBill.tHours = '';
        FreightBill.grossHours = '';
        FreightBill.totalYardHours = '';
        FreightBill.tHoursPaid = '';
        FreightBill.grossHoursPaid = '';
        FreightBill.tWeight = '';
        FreightBill.truckPaid = '';
        FreightBill.bFee = '';
        FreightBill.pFees = '';
        FreightBill.tPaid = '';
    
        FreightBill.fuelBilled = '';
        FreightBill.standExMin = '';
        FreightBill.standBilled = '';
        FreightBill.totalExcessDump = '';
        FreightBill.totalExcessLoad = '';
        FreightBill.standPaid = '';
        FreightBill.Received = false;
        FreightBill.Released = false;
        FreightBill.onHold = true;
        FreightBill.realOnHold = false;
        FreightBill.missing = true;
        FreightBill.dSubmitted = false;
        FreightBill.approved = false;
        FreightBill.billed = false;
        FreightBill.paid = false;
        FreightBill.SetManual = false;       
        FreightBill.standByIsPaid = false;
        FreightBill.hourMinIsPaid = false;
        FreightBill.standByIsBilled = false;
   
        FreightBill.lastModified = '';
        FreightBill.signature = '';
        FreightBill.ReceivedTime = '';
        FreightBill.paidBrokerFee = '';
        FreightBill.signatureName = '';
        FreightBill.billingOverride = '';
        FreightBill.Comments = '';
        FreightBill.Weights = [];

        return FreightBill;
    }

    Number.prototype.formatMoney = function(c, d, t) {
        var roundNum=this;
        roundNum=roundNum+.000001;
        var n = Number(roundNum),
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d === undefined ? "." : d,
            t = t === undefined ? "," : t,
            s = n < 0 ? "-" : "",
            
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;
            
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    
    };

    const addObjectIfNotExists = (array, object) => {
        const exists = array.some(item => isEqual(item, object));
        if (!exists) {
          array.push(object);
        }
        return array;
    };

    const handleFreightClick = (freight, tempDispatch) => {

        setPopUpFreight({...freight});      
        dispatchObjectRef.current=tempDispatch;
        popUpFreightRef.current=freight;
        setFreightPopUpVisible(true,)
        
        
    };
    const sendUnassignedText = (driver, batch  )=>{
        const refID = driver.ID+formatDate(homeDate,'-','YYYY/MM/DD');
        const unassignedRef = doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/UnassignedTexts', refID); 
 
        var dispatchDayNum= new Date(homeDate).getDay();
        var dispatchDayText;
        if(dispatchDayNum===0)dispatchDayText='Sunday';
        if(dispatchDayNum===1)dispatchDayText='Monday';
        if(dispatchDayNum===2)dispatchDayText='Tuesday';
        if(dispatchDayNum===3)dispatchDayText='Wednesday';
        if(dispatchDayNum===4)dispatchDayText='Thursday';
        if(dispatchDayNum===5)dispatchDayText='Friday';
        if(dispatchDayNum===6)dispatchDayText='Saturday';
        let encodedOrgName = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27');
      //  toastr.info('Sending Text', '');
        var UnassignedText = {
           ID:'',
           driverID: driver.ID,
           Name: driver.Name,
           Date: formatDate(homeDate,'/','MM/DD/YYYY'),
           read: false,
           fromReact:true,
           dispatchDayText: dispatchDayText
        }
        UnassignedText.textData={      
        
            OrganizationName: gearedUser.selectedOrgName,
    
            body: gearedUser.selectedOrgName + ' has no work scheduled for ' + dispatchDayText+ ' ' + formatDate(homeDate,'/','MM/DD/YYYY') + '. Please click the link to confirm you have read this message:   geared.alianzatechnologies.com/nodispatch/' + encodedOrgName + '/',
            from:company.twilioNumber,
            to: '+1' + driver.Phone
        };
        batch.set(unassignedRef, UnassignedText);
        console.log('this would be the text message = ', UnassignedText)
    }
    const sendUnassignedTexts = async(driversToText)=>{
        const batch = writeBatch(db);
     
        for(let d=0; d<driversToText.length; d++){
            if(driversToText[d].sendText)sendUnassignedText(driversToText[d], batch);
        }
        await batch.commit();
    }
    const textUnassignedDrivers = (unassignedDrivers)=>{
        console.log('unassignedDrivers = ', unassignedDrivers)

        unassignedDriversRef.current = [...unassignedDrivers];
        setSelectedDrivers( unassignedDriversRef.current.reduce((acc,  unassignedDriver) => {  acc[unassignedDriver.ID] = true;   return acc;  }, {}));
        selectedDriversRef.current = unassignedDriversRef.current.reduce((acc,  unassignedDriver) => {  acc[unassignedDriver.ID] = true;   return acc;  }, {})
          // Create the checkbox form for dispatchFreightBills
          const formContent = (
            <div className='mbsc-grid'>
                {unassignedDriversRef.current.map(( unassignedDriver) => (<div key={unassignedDriver.ID}>
                    <div className="p-inputgroup mbsc-col-lg-8 mbsc-offset-lg-2"  style={{padding:'0'}}>
                        <span className="p-inputgroup-addon dispatch-small-inputgroup" >   {unassignedDriver.Name ? unassignedDriver.Name : unassignedDriver.DriverName}{' '}</span>
                        <Checkbox style={{ width: '100%' }} onChange={() =>  handleCheckboxChange(unassignedDriver.ID)} checked={selectedDriversRef.current[unassignedDriver.ID]}   />
                    </div> 
                        
                </div>))}
            </div>
          );
          const handleCheckboxChange = (unassignedDriverID) => {
            setSelectedDrivers((prevSelected) => {
              console.log('Previous selectedFreightBills:', prevSelected);
              console.log('Toggling unassignedDriverID:', unassignedDriverID);
          
              // Update the checkbox state
              const newSelected = { ...prevSelected, [unassignedDriverID]: !prevSelected[unassignedDriverID] };
              console.log('New selectedFreightBills:', newSelected);
              selectedDriversRef.current={...newSelected  };
　　 　 　 　
                const formContent = (
                    <div className='mbsc-grid'>
                        {unassignedDriversRef.current.map(( unassignedDriver) => (<div key={unassignedDriver.ID}>
                            <div className="p-inputgroup mbsc-col-lg-8 mbsc-offset-lg-2"  style={{padding:'0'}}>
                                <span className="p-inputgroup-addon dispatch-small-inputgroup" >   {unassignedDriver.Name ? unassignedDriver.Name : unassignedDriver.DriverName}{' '}</span>
                                <Checkbox style={{ width: '100%' }} onChange={() =>  handleCheckboxChange(unassignedDriver.ID)} checked={selectedDriversRef.current[unassignedDriver.ID]}   />
                            </div> 
                                  
                        </div>))}
                    </div>
                );
                setDialogText(formContent); 
                return newSelected;
            });
          };
  
          // Dialog buttons
          const buttons = [
              {
                  label: 'OK',
                  className: 'p-button-success',
                  onClick: () => {
                      // Update sendText property for each freight bill based on checkbox state
                      console.log('selectedFreightBillss =' , selectedDriversRef.current)
                      const updatedDrivers = unassignedDriversRef.current.map((unassignedDriver) => ({ ...unassignedDriver,   sendText:selectedDriversRef.current[unassignedDriver.ID], }));
                      // Call releaseDispatch with the updated freight bills
                      console.log(' updatedDrivers =' , updatedDrivers)
                      sendUnassignedTexts(updatedDrivers)
                      setDialogVisible(false); // Close dialog
                  },
              },
              { label: 'Cancel', className: 'p-button-secondary', onClick: () => setDialogVisible(false) },
          ];
  
          setDialogText(formContent); // Set the form content
          setDialogHeader('Please check which drivers to notify');
          setDialogButtons([...buttons]);
          setDialogVisible(true);
    }

    return (
      <DispatchContext.Provider value={{
          setDispatch, setHomeDate, handleUpdateFreightBills, addFreightBill, setHomeFreightBills,
          dispatch, homeFreightBills, homeDate,  homeDispatches, dispatchState, setDispatchState, assigning, setAssigning, releaseDispatch, unReleaseDispatch,
           queryFreightBills, formatDate, setHomeDispatches, queryDispatches, addObjectIfNotExists, job, setJob, jobDispatches, setJobDispatches,updateLoadOrdersFromPopUp,
           dispatchDatePopUpVisible, setDispatchDatePopUpVisible,dispatchDate, setDispatchDate,dispatchObjectRef, homeFreightBillsRef, deleteFreightBills,deleteFreightBillsByTruck,
          freightPopUpVisible, setFreightPopUpVisible, popUpFreight, setPopUpFreight, handleFreightClick,checkReleaseDispatch,createChildExpense,
           checkCancelDispatch,unCancelDispatch,queryExpenses, dispatchExpenses, expensePopUpVisible, setExpensePopUpVisible, expensesRef, updateUnbilledExpenses,
           setDialogVisible,setDialogText,  setDialogButtons, setDialogHeader, dialogVisible, dialogText, dialogButtons, dialogHeader,   prelimEmailRef, handleEmailChange,
           openDispatchDatePopUp,moveOrCopyDispatch,  jobDispatchesRef,selectedDriversRef, setSelectedDrivers,textUnassignedDrivers,location, navigate, dispatchFreightBillsRef,
           textFreight,searchedObject, setSearchedObject, isLoading, setIsLoading
      }}>
          {children}
      </DispatchContext.Provider>
  );
};
export const useDispatch= () => {
    return useContext(DispatchContext);
};
function formatDate(date, divider, format) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)  month = '0' + month;
    if (day.length < 2)  day = '0' + day;

    if(format==='YYYY/MM/DD')return [year, month, day].join(divider);
    else return [month, day,year].join(divider);
}