import React,{useEffect,useState} from 'react';
import { Checkbox } from 'primereact/checkbox';

import {UserAuth}  from '../../../context/AuthContext';
import  TableCell  from '../../ListComponents/TableCell';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput';
import EditableLineItem from './EditableLineItem';
const LineItemLine = ({ invoice, lineItem, toggleOnHold,borderWidth, columns, openExpensePopUp }) => {
    const [inputValues, setInputValues]= useState(null);
	const { quickbooksItemList } = UserAuth();
    const [selectedQBItem, setSelectedQBItem] = useState({ ID: '', Name: 'Select Item' });
	const sortedQuickbooksItemList = quickbooksItemList
    .filter(item => item.Status === 'Active')
    .map(item => ({ ...item })) // Create a new object copy
    .sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));

// Now modify the copies
sortedQuickbooksItemList.forEach(item => {
	item.itemName = item.Name;
    item.Name = item.Name + " - " + item.Description;
});
	console.log('inside the qb item list and selectedqbitem = ', selectedQBItem)
    useEffect(() => {
        if (lineItem) {
            if(lineItem.isEditable) lineItem.hideFBNO = true;
            setInputValues({...lineItem});
           
           if(lineItem.Type==='Scale Tag') console.log('setting the input Values of fb #  = ' +lineItem.FBNO +' to this onhold value= '+ lineItem.onHold)
           
           // Initialize QB item if lineItem has one
		
           if (lineItem.QBItem) {
               setSelectedQBItem(lineItem.QBItem);
           }else {
				const qbItem = findQBItem(lineItem);
				lineItem.QBItem=qbItem;
				setSelectedQBItem(qbItem);
		   } 
        }
    }, [lineItem]);

	const findQBItem = (lineItem) => {
		console.log('lineItem we are looking for qb item for looks like this = ', lineItem);
		console.log('invoice in the line item= ', invoice)
		// Check if we have the required data
		if (!lineItem || !invoice || !invoice.TruckTypeName || !sortedQuickbooksItemList) {
			console.log('Missing required data for QBItem search');
			return null;
		}
		
		// Get the search criteria
		let truckTypeName = invoice.TruckTypeName;
		const driverName = lineItem.subhaulerName;
		const rateType = lineItem.RateType;
		if(truckTypeName.includes('Super')) truckTypeName = 'Super'
		// Determine rate type string to search for
		const rateTypeString = rateType === "Ton" ? "Tonnage" : 
							   rateType === "Hour" ? "Hourly" : null;
		
		if (!rateTypeString) {
			console.log('Unknown rate type:', rateType);
			return null;
		}
		  // Helper function to normalize strings for comparison
		  const normalizeString = (str) => {
			if (!str) return '';
			// Remove punctuation, extra spaces, and convert to lowercase
			return str.toLowerCase()
					  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")  // Remove punctuation
					  .replace(/\s+/g, " ")                         // Replace multiple spaces with single space
					  .trim();                                      // Remove leading/trailing spaces
		  };
		  
		  // Normalize our search terms
		  const normalizedTruckType = normalizeString(truckTypeName);
		  const normalizedDriver = normalizeString(driverName);
		  const normalizedRateType = normalizeString(rateTypeString);
		  
		  // Search for matching item
		  const matchingItem = sortedQuickbooksItemList.find(item => {
			const itemName = item.Name || '';
			const normalizedItemName = normalizeString(itemName);
			
			// Check if normalized item name contains all three required elements
			const hasTruckType = normalizedItemName.includes(normalizedTruckType);
			const hasRateType = normalizedItemName.includes(normalizedRateType);
			
			// For driver name, we'll do a more flexible check
			// This handles cases like "S Bhangu, LLC" vs "S. Bhangu LLC"
			const hasDriver = (() => {
			  // If exact match after normalization, return true
			  if (normalizedItemName.includes(normalizedDriver)) return true;
			  
			  // If driver name has multiple parts (like "S Bhangu"), check if all parts are present
			  const driverParts = normalizedDriver.split(' ').filter(part => part.length > 1); // Only consider parts with length > 1
			  return driverParts.every(part => normalizedItemName.includes(part));
			})();
			
			return hasTruckType && hasDriver && hasRateType;
		  });
		  
		  console.log('Found matching QB item:', matchingItem);
		  return matchingItem;
	}
    const handleFieldChange = ( fieldName,value ) => {
        console.log('tryina set fieldname = '+ fieldName + ' equal to value = ', value)
         setInputValues((prev) => ({ ...prev, [fieldName]: value }));
         let tempLineItem={...inputValues};
         tempLineItem[fieldName]=value;
        toggleOnHold(tempLineItem, value);
     };

    const handleQBItemChange = (fieldName, value) => {
        console.log('Selected QuickBooks item:', value);
		lineItem.QBItem=value;
        setSelectedQBItem(value);
        
        // You can add additional logic here if you want to save this selection
        // For example, update the lineItem in the parent component
    };

    const openFreightBill = (lineitem)=>{
        var win = window.open('/freightbill/freightbill/'+lineitem.FreightID+'/'+lineItem.dispatchID, '_blank');
        win.focus();
    
    }
  
    let borderStyle= borderWidth ? borderWidth: "1px 1px 1px 1px"

   
    const renderCell = (columnId, values, col) => {
        switch(columnId) {
            case 'fbno':
                return (
                    <td 
                        data-label='FB NO' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        {values.isEditable && ( <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 

                                    width:"90%" 
                                }} 
                                onClick={(e) => openExpensePopUp(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                               Open Expense
                            </button>)}
                        {!values.hideFBNO && (values.Type === 'Freight Bill' || values.firstWeight || values.Type === 'Expense' || values.Type === 'Material') && (
                            <button 
                                style={{ 
                                    margin: '0', 
                                    height:"90%", 
                                    marginTop:".1em", 
                                    padding: '.1em', 
                                    marginLeft:".5em", 
                                    width:"90%" 
                                }} 
                                onClick={(e) => openFreightBill(lineItem)} 
                                className="mbsc-ios mbsc-btn-primary mbsc-btn"
                            >
                                {values.FBNO}
                            </button>
                        )}
                    </td>
                );
            case 'truck':
                return !values.hideFBNO ? 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value={values.truck} 
                        borderStyle={borderStyle} 
                        dataLabel='Truck'
                    /> : 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value='' 
                        borderStyle={borderStyle} 
                        dataLabel='Truck'
                    />;
            case 'driver':
                return !values.hideFBNO ? 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value={values.Driver} 
                        borderStyle={borderStyle} 
                        dataLabel='Driver'
                    /> : 
                    <TableCell 
                        width={col.width} 
                        isNumber={false} 
                        value='' 
                        borderStyle={borderStyle} 
                        dataLabel='Driver'
                    />;
            case 'qbItem':
                return (
                    <td
                        data-label='QB Item'
                        style={{
                            width: col.width,
                            padding: '0',
                            borderWidth: borderStyle,
                            borderColor: '#bcbcd1',
                            borderStyle: 'solid'
                        }}
                    >
                        <div style={{ padding: '0.2em' }}>
                            <AutoCompleteInput
                                showLabel={false}
                                fieldName="QBItem"
								hideAddCustom={true}
                                field="Name"
                                value={selectedQBItem}
                                suggestions={sortedQuickbooksItemList || []}
                                setValue={(updaterFn) => {
                                    // Extract the value by simulating the state update
                                    const dummyPrev = { QBItem: selectedQBItem };
                                    const updated = updaterFn(dummyPrev);
                                    const newValue = updated.QBItem;
                                    
                                    setSelectedQBItem(newValue);
                                    console.log('Selected QB item:', newValue);
                                }}
                                handleFieldChange={handleQBItemChange}
                            />
                        </div>
                    </td>
                );
            case 'missing':
                return invoice.previewing && (
                    <td 
                        data-label='Missing' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <Checkbox style={{height:"1.75em ", padding:"0 ", display: "flex", justifyContent: "center" }} checked={values.missing} disabled={true} onChange={(e) => handleFieldChange( 'missing', e.checked)}/>
                    </td>
                );
            case 'onHold':
                return invoice.previewing && (
                    <td 
                        data-label='On Hold' 
                        style={{ 
                            width: col.width, 
                            padding: '0', 
                            borderWidth: borderStyle, 
                            borderColor:'#bcbcd1', 
                            borderStyle:'solid' 
                        }}
                    >
                        <Checkbox style={{ height:"1.75em", padding:"0 ", display: "flex", justifyContent: "center" }} disabled={lineItem.Type==='Scale Tag' && lineItem.hideFBNO} checked={values.onHold} onChange={(e) => handleFieldChange( 'onHold', e.checked)}/>
                    </td>
                );
            case 'description':
                return invoice.calcByLoad && (
                    <TableCell 
                        width={col.width} 
                        value={values.Description} 
                        borderStyle={borderStyle} 
                        dataLabel='Description'
                    />
                );
            case 'rateType':
                return <TableCell 
                    width={col.width} 
                    value={values.RateType} 
                    borderStyle={borderStyle} 
                    dataLabel='Rate Type'
                />;
            case 'qty':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    notCurrency={true} 
                    value={values.Qty} 
                    borderStyle={borderStyle} 
                    dataLabel='Qty'
                />;
            case 'rate':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    value={values.Rate} 
                    borderStyle={borderStyle} 
                    dataLabel='Rate' 
                />;
            case 'tax':
                return invoice.hasTax && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.TaxTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Tax'
                    />
                );
            case 'fuelCharge':
                return invoice.hasFuelCharge && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.FuelTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Fuel Charge'
                    />
                );
            case 'brokerFee':
                return invoice.hasBrokerFee && (
                    <TableCell 
                        width={col.width} 
                        isNumber={true} 
                        value={values.BrokerTotal} 
                        borderStyle={borderStyle} 
                        dataLabel='Broker Fee'
                    />
                );
            case 'total':
                return <TableCell 
                    width={col.width} 
                    isNumber={true} 
                    value={values.Total} 
                    borderStyle={borderStyle} 
                    dataLabel='Total'
                />;
            case 'selectedItem':
                return (
                    <td
                        data-label='Selected Item'
                        style={{
                            width: col.width,
                            padding: '0.5em',
                            borderWidth: borderStyle,
                            borderColor: '#bcbcd1',
                            borderStyle: 'solid'
                        }}
                    >
                        {selectedQBItem.Name || 'None'}
                    </td>
                );
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {inputValues && !inputValues.FreightHold && (<>
            {lineItem.isEditable && invoice.previewing ? (
				<EditableLineItem   lineItem={lineItem} columns={columns}
					onUpdate={(updatedLineItem) => {
						setInputValues(updatedLineItem);
						toggleOnHold(updatedLineItem);
					}}
				  
				/>
			) : (
			<tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em'}}>
				{columns.map(col => (
					<React.Fragment key={col.id}>
						{renderCell(col.id, inputValues, col)}
					</React.Fragment>
				))}
			</tr>)}

</>  )}
        </React.Fragment>
    )
}

export default LineItemLine;