import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Textarea} from '@mobiscroll/react';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';

import { UserAuth } from '../../context/AuthContext'; 

import { usePrevailingWageReport } from './context/PrevailingWageReportContext';
import { usePrevailingWageWeek } from './context/PrevailingWageWeekContext';

import DriverListItem from './Lists/DriverListItem';
import OutsideDriverListItem from './Lists/OutsideDriverListItem';
import AutoSelectInputNumber from '../InputComponents/AutoSelectInputNumber'; 
import AutoCompleteInput from 'components/InputComponents/AutoCompleteInput';
import SignaturePopUp from '../PopUpComponents/SignaturePopUp';
import InputTextParent from '../InputComponents/InputTextParent'; 



import { usePrevailingWagePDFs } from './hooks/usePrevailingWagePDFs';
import { useFindWeekDates } from './hooks/useFindWeekDates';
import { useXML } from './hooks/useXML';

import {useParams } from 'react-router-dom';
const PrevailingWageWeek = () => {
  
    const { formatDate, accounts,  updateDocument } = UserAuth();
    const { updateReportWeekDocs,fetchPrevailingWageReport,prevailingWageReport } = usePrevailingWageReport();
	const { reportWeek, setReportWeek, fetchPrevailingWageWeek,weekFreightBillsRef, updateReportWeekDoc } = usePrevailingWageWeek();
	const { createXML } = useXML();
    const { createPrevailingWagePDFs, createNonPerformancePDF } = usePrevailingWagePDFs();
    const { createReportWeeks } = useFindWeekDates();

    const { id, weekDate} = useParams();
    const [signatureVisible, setSignatureVisible] = useState(false);
        


    useEffect(()=>{
        startReportPage();
    },[])


    const startReportPage = async ()=>{
        if(!prevailingWageReport)    await fetchPrevailingWageReport(id);
		fetchPrevailingWageWeek(id, weekDate);
    }

    const handleUpdateDriver =  (index, driverArrayName, updatedDriver) => {

       let tempReportWeek={...reportWeek};
       tempReportWeek[driverArrayName][index]={...updatedDriver}
	
       setReportWeek(tempReportWeek);
	   updateReportWeekDoc({[driverArrayName]: tempReportWeek[driverArrayName]})
    };

    const handleInputChange = (field, value) => { 
        console.log('value= ', value)
        console.log('field= ', field)
       
		setReportWeek({ ...reportWeek, [field]: value});  
		updateReportWeekDoc({[field]: value})
     
    };

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
     
		setReportWeek((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
		updateReportWeekDoc({[fieldName]: formattedDate, [fieldName+'Value']: value })
	}
    const handleCheckboxChange = (field, value) => {
		console.log('field for checkbox= ', field)
		console.log('value for checkbox= ', value)
		setReportWeek({ ...reportWeek, [field]: value });
		updateReportWeekDoc({[field]: value})
    };

    const handleDeleteDoc = (rowData, index) => {
        if (window.confirm("Are you sure you want to delete this document?")) {
            let tempReportWeek = { ...reportWeek };
            tempReportWeek.Docs.splice(index, 1);
            setReportWeek(tempReportWeek);
			updateReportWeekDoc({Docs: tempReportWeek.Docs})
      
        }
    };

    const deleteBodyTemplate = (rowData, options) => {
        return (
            <button 
                type="button" 
                onClick={() => handleDeleteDoc(rowData, options.rowIndex)}
                className="p-button p-button-danger p-button-text"
                style={{ padding: '0' }}
            >
                <i className="pi pi-trash" style={{ color: 'red' }}></i>
            </button>
        );
    };

    const handleSaveSignature = (signature) => {
        createPrevailingWagePDFs(signature, reportWeek, prevailingWageReport);
    };
	const reportHeader= 'Report Details for '+reportWeek?.name;
    return (
        <React.Fragment>
            {prevailingWageReport && reportWeek &&(<>
				<div className="mbsc-grid mbsc-row">
					<div className="mbsc-col-lg-6">
						<Panel header={ reportHeader}>
						
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Name</span>
								<InputTextParent value={reportWeek.Name} onChange={(e) => handleInputChange( 'Name',e.target.value)} />
							</div>
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Title</span>
								<InputTextParent value={reportWeek.Title} onChange={(e) => handleInputChange( 'Title',e.target.value)} />
							</div>
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Project Name</span>
								<InputTextParent value={reportWeek.ProjectName} onChange={(e) => handleInputChange( 'ProjectName',e.target.value)} />
							</div>
							<div className="p-inputgroup ">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Project and Location</span>
								<InputTextParent value={reportWeek.ProjectLocation} onChange={(e) => handleInputChange( 'ProjectLocation',e.target.value)} />
							</div>
							<div className="p-inputgroup">
								<span className="p-inputgroup-addon dispatch-small-inputgroup">Signature Date:</span>
								<Calendar 
									pt={{
										tableHeader:{'className':'dontResize'},
										tableHeaderCell:{'className':'dontResize'},
										day:{'className':'dontResize'}
									}} 
									value={reportWeek.SignatureDateValue} 
									style={{width:"100%"}} 
									onChange={(e) => handleDateChange( 'SignatureDate',e.value )} 
								/>
							</div>
							{!reportWeek.nonPerformance &&  (<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  > Rate</span> 
								<AutoSelectInputNumber   value={reportWeek.PrevailingWageRate} isCurrency={true}     onChange={(e) => handleInputChange( 'PrevailingWageRate',e.value)} />
							</div>)}
							{!reportWeek.nonPerformance &&  (<div className='mbsc-row' style={{margin:'0'}} >
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
									<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Overtime Rate</span> 
									<AutoSelectInputNumber   value={reportWeek.OvertimePrevailingWageRate} isCurrency={true}     onChange={(e) => handleInputChange( 'OvertimePrevailingWageRate',e.value)} />
								</div>
								<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
									<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Owner Op Rate</span> 
									<AutoSelectInputNumber   value={reportWeek.OwnerOpPrevailingWageRate} isCurrency={true}   onChange={(e) => handleInputChange( 'OwnerOpPrevailingWageRate',e.value)} />
								</div>
							
							</div>)}
							<Panel header='Week Details'>
								{!reportWeek.nonPerformance && (<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon " style={{flexBasis:'80%'}}>Create EmployeeDocs</span>
										<Checkbox checked={reportWeek.createEmployeeDocs} onChange={(e) => handleCheckboxChange('createEmployeeDocs',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon  " style={{flexBasis:'80%'}}>Create Owner Op Docs</span>
										<Checkbox checked={reportWeek.createOwnerOpDocs} onChange={(e) => handleCheckboxChange('createOwnerOpDocs',e.checked)}  />
									</div>
								</div>)}
							</Panel>
						</Panel>
						{!reportWeek.nonPerformance && (<>
							{reportWeek.createOwnerOpDocs &&( <Panel header='Owner Operator Details'>
							
								<div className="p-inputgroup ">
									<span className="p-inputgroup-addon dispatch-small-inputgroup">Payroll Number</span>
										
										<InputTextParent value={reportWeek.OwnerOpPayrollNumber} onChange={(e) => handleInputChange( 'OwnerOpPayrollNumber', e.target.value,)} />
									</div>
									<div className="p-inputgroup ">
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Owner Op 4(a)</span>
								
										<Checkbox checked={reportWeek.OwnerOp4a} onChange={(e) => handleCheckboxChange( 'OwnerOp4a', e.checked)} />
									</div>
									<div className="p-inputgroup ">
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Owner Op 4(b)</span>
								
										<Checkbox checked={reportWeek.OwnerOp4b} onChange={(e) => handleCheckboxChange( 'OwnerOp4b', e.checked)} />
									</div>
									<div className="p-inputgroup " style={{height:'4em'}}>
										<span className="p-inputgroup-addon text-area-inputgroup">Remarks</span>
								
										<Textarea rows={2} value={reportWeek.OwnerOpRemarks} onChange={(e) => handleInputChange('OwnerOpRemarks', e.target.value, )} />
									</div>
								
							</Panel>)}
							
						</>)}
					</div>
                    {reportWeek.nonPerformance ? (
                        <Panel header='Report Details' className='mbsc-col-lg-6'>
							<AutoCompleteInput 
								labelClass="p-inputgroup-addon dispatch-small-inputgroup" 
								fieldName="Contractor" 
								field="Name" 
								value={reportWeek.Contractor} 
								suggestions={accounts} 
								setValue={setReportWeek}  
								handleFieldChange={handleInputChange}  
								databaseList={'Accounts'} 
							/>
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Project and Location</span>
                                <InputTextParent value={reportWeek.ProjectLocation} onChange={(e) => handleInputChange( 'ProjectLocation',e.target.value)} />
                            </div>
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Contract Number</span>
                                <InputTextParent value={reportWeek.ContractNumber} onChange={(e) => handleInputChange( 'ContractNumber',e.target.value)} />
                            </div>
                            <div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Project Number</span>
                                <InputTextParent value={reportWeek.ProjectNumber} onChange={(e) => handleInputChange( 'ProjectNumber',e.target.value)} />
                            </div>
							<div className="p-inputgroup ">
                                <span className="p-inputgroup-addon dispatch-small-inputgroup">Job Number</span>
                                <InputTextParent value={reportWeek.JobNumber} onChange={(e) => handleInputChange( 'JobNumber',e.target.value)} />
                            </div>
                        </Panel>):(
						<div className='mbsc-col-lg-6'>
                        	{reportWeek.createEmployeeDocs &&(  <Panel header='Driver Details' >
								<div className="mbsc-row">
								
									<div className="p-inputgroup mbsc-col-lg-10" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup">Payroll Number</span>
										<AutoSelectInputNumber  isCurrency={false}  placeholder="Enter a number" value={reportWeek.DriverPayrollNumber}    onChange={(e) => handleInputChange( 'DriverPayrollNumber',e.value)}  />

									</div>
								</div>
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Contractor Payroll</span>
										<Checkbox  checked={reportWeek.ContractorPayroll}     onChange={(e) => handleCheckboxChange('ContractorPayroll',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Subcontractor Payroll</span>
										<Checkbox checked={reportWeek.SubcontractorPayroll} onChange={(e) => handleCheckboxChange('SubcontractorPayroll',e.checked)}  />
									</div>
								</div>
							
							
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 2(a)</span>
										<Checkbox  checked={reportWeek.Driver2a}     onChange={(e) => handleCheckboxChange( 'Driver2a',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 2(b)</span>
										<Checkbox  checked={reportWeek.Driver2b}     onChange={(e) => handleCheckboxChange('Driver2b',e.checked)}  />
									</div>
								</div>
								<div className="mbsc-row">
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 4(a)</span>
										<Checkbox  checked={reportWeek.Driver4a}     onChange={(e) => handleCheckboxChange('Driver4a',e.checked)}  />
									</div>
									<div className="p-inputgroup mbsc-col-lg-6" style={{padding:0}}>
										<span className="p-inputgroup-addon dispatch-small-inputgroup" style={{flexBasis:'80%'}}>Driver 4(b)</span>
										<Checkbox  checked={reportWeek.Driver4b}     onChange={(e) => handleCheckboxChange('Driver4b',e.checked)}  />
									</div>
								</div>
							
								<div className="p-inputgroup " style={{height:'4em'}}>
									<span className="p-inputgroup-addon text-area-inputgroup">Remarks</span>
							
									<Textarea rows={2} value={reportWeek.DriverRemarks} onChange={(e) => handleInputChange('DriverRemarks',e.target.value)} />
								</div>
                        
                        
                        	</Panel>)}
					
                        </div>
                        )}
				</div>
				{reportWeek && reportWeek.createOwnerOpDocs && ( <Panel header='Owner Op Deductions' >
                    
					<table style={{ marginBottom: "5px", width: "100%" }}>
						<thead>
							<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
								<th style={{ width: "20%" }}>Name</th>
								<th style={{ width: "15%"}}>Equip/License #</th>
								<th style={{ width: "15%"}}>Check #</th>
								<th style={{ width: "15%"}}>Exception(Craft)</th>
								<th style={{ width: "15%"}}>Gross Pay</th>
								<th style={{ width: "20%"}}>Explanation</th>
							</tr>
						</thead>
						<tbody>
							{reportWeek.OwnerOps?.map((outsidedriver,index) => (
								<OutsideDriverListItem   key={index} index={index} outsidedriver={outsidedriver} onUpdateDriver={(updatedDriver) => handleUpdateDriver(index,'OwnerOps', updatedDriver)}   />
							))}
						</tbody>
					</table>
				</Panel>)}
                        {!reportWeek.nonPerformance && reportWeek.createEmployeeDocs && (
                            <Panel header='Driver Deductions' className='mbsc-col-12'>
                                <table style={{ marginBottom: "5px", width: "100%" }}>
                                    <thead>
                                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
											<th style={{ width: "3%" }}>FBs</th>
                                            <th style={{ width: "10%" }}>Name</th>
                                            <th style={{ width: "7%" }}>Fed Tax</th>
                                            <th style={{ width: "7%" }}>FICA</th>
                                            <th style={{ width: "7%" }}>SDI</th>
                                            <th style={{ width: "7%" }}>Local Tax</th>
                                            <th style={{ width: "7%"}}>Other Tax</th>
                                            <th style={{ width: "7%"}}>Other Ded.</th>
                                            <th style={{ width: "7%"}}>Total Ded.</th>
                                            <th style={{ width: "7%"}}>This Project</th>
                                            <th style={{ width: "7%"}}>All Projects</th>
                                            <th style={{ width: "7%"}}>Net Wages</th>
                                            <th style={{ width: "8%"}}>Check #</th>
                                            <th style={{ width: "9%"}}>Ded. Notes</th>
                                    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reportWeek.Drivers?.map((driver, index) => (
                                    
                                            <DriverListItem   
                                                key={index} 
                                                index={index} 
                                                driver={driver} 
                                                weekFreightBills={weekFreightBillsRef?.current}
                                                reportWeek={reportWeek}
                                                onUpdateDriver={(updatedDriver) => handleUpdateDriver(index,'Drivers', updatedDriver)}   
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </Panel>)}
                  
                        <div className="mbsc-col-12" style={{paddingBottom:'5em'}}>
                        {!reportWeek.nonPerformance ? ( <Button label='Create Reports' onClick={() => setSignatureVisible(true)} />
                        ) : (<Button label='Create Non Performance' onClick={() => createNonPerformancePDF(reportWeek, prevailingWageReport)} />   )}
                        <Button label='Create XML ' onClick={() => createXML(reportWeek, prevailingWageReport)} />  
                        <SignaturePopUp 
                            visible={signatureVisible}
                            setVisible={setSignatureVisible}
                            handleSaveSignature={handleSaveSignature}
                        />
                        
                        <DataTable value={reportWeek.Docs} responsiveLayout="scroll">
                            <Column 
                                header="Delete" 
                                body={deleteBodyTemplate} 
                                style={{ width: '4rem', textAlign: 'center' }}
                            />
                            {!reportWeek.nonPerformance  &&( <Column header="Driver Report" body={(rowData) => ( <Button label={`Driver - ${rowData.VNum}`} onClick={() => window.open(rowData.driverURL)} /> )} />)}
                            {!reportWeek.nonPerformance  &&( <Column header="Compliance Report" body={(rowData) => ( <Button label={`Comp - ${rowData.VNum}`} onClick={() => window.open(rowData.compURL)} /> )} />)}
                            {reportWeek.nonPerformance  && (<Column header="Non Performance Report" body={(rowData) => ( <Button label={`Non Performance - ${rowData.VNum}`} onClick={() => window.open(rowData.nonPerformanceURL)} /> )} />)}
                        </DataTable>	
                    </div>
			</>)}
        </React.Fragment>
    );
};

export default PrevailingWageWeek;
