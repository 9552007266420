import React, { useState, useEffect, useRef } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { Checkbox } from 'primereact/checkbox';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Calendar } from 'primereact/calendar';
import { Button} from 'primereact/button';
import { Toast } from 'primereact/toast';


import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle, faLink } from '@fortawesome/free-solid-svg-icons';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';

import LoadingOverlay from '../MiscComponents/LoadingOverlay';

import IIFDownloadComponent from '../MiscComponents/IIFDownloadComponent';

import { db } from '../../firebase';
import {   query, collection, onSnapshot, where, doc, getDoc } from 'firebase/firestore';
import { InputText } from 'primereact/inputtext';
import InvoiceHomeLineItemList from './LineItemComponents/InvoiceHomeLineItemList';

const InvoicesHomeQuickbooks = (props) => {
    const { deleteDocument, gearedUser,companies,company, formatDate, accounts, formatMoney,setDocument, truckTypes, quickbooksItemList } = UserAuth();
	const [iifInvoices, setIifInvoices] = useState([]);
    
        const sortedQuickbooksItemList = quickbooksItemList
            .filter(item => item.Status === 'Active')
            .map(item => ({ ...item }))
            .sort((a, b) => (a.Name < b.Name ? -1 : a.Name > b.Name ? 1 : 0));
        
        // Add description to name for display
        sortedQuickbooksItemList.forEach(item => {
            item.itemName = item.Name;
            item.Name = item.Name + " - " + item.Description;
        });
    const [isLoading, setIsLoading] = useState(false);

    const [invoices, setInvoices] = useState([]);
 

    const [homeObject, setHomeObject]= useState({Company:{...company}})
    const [queryRange, setQueryRange]= useState('ThreeMonths');
    const [searchDisabled, setSearchDisabled] = useState(false);


    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);
    
    const [inputValues, setInputValues]= useState({
        Account:{ID:'', Name:'No Account' }, 
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD'), 
        Company:{...company}
    });


    const unsubscribeInvoicesRef = useRef([])
    const invoicesRef=useRef(null);
    const toast = useRef(null);

    console.log('home ojb company on load = ', homeObject.Company)
    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);

    useEffect(() => {
        if (inputValues?.StartDate) {
            queryInvoices();
           
        }
    }, []);

 

    let tempInvoices = [...invoices];
    if (inputValues.Company)   tempInvoices =  tempInvoices.filter(invoice => invoice.Company && invoice.Company.ID === inputValues.Company.ID);
    const sortedInvoices =  tempInvoices.sort((a, b) => {
        if (a.QueryDate >= b.QueryDate) return -1;
        if (a.QueryDate <= b.QueryDate) return 1;
        return 0;
    });        

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS}, 
        'Account.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Paid: { value: null, matchMode: FilterMatchMode.EQUALS },
   
        // Don't set anything here for Account.Name initially
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
  
   
    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({
            ...prevFilters,
            global: { ...prevFilters.global, value }
        }));
        setGlobalFilterValue(value);
    };
    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
      
    };
    const changeAccount= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    }; 
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
     
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const queryInvoices = () => {
        invoicesRef.current = [];
        if(unsubscribeInvoicesRef.current.length > 0) {
            for(var u = 0; u < unsubscribeInvoicesRef.current.length; u++) {
                unsubscribeInvoicesRef.current[u]();
            }
        }
        unsubscribeInvoicesRef.current = [];

        console.log('homeObject.Company = ', inputValues);
        setIsLoading(true);
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Invoices`;

        let invoiceQuery = query(collection(db, queryName), 
            where("QueryDate", ">=", inputValues.StartDate), 
            where("QueryDate", "<=", inputValues.EndDate)
        );
        
        if (inputValues.Account.ID) {
            invoiceQuery = query(invoiceQuery, where("Account.ID", "==", inputValues.Account.ID));
        }

        unsubscribeInvoicesRef.current.push(onSnapshot(invoiceQuery, (querySnapshot) => {
            console.log('Invoice HOME SNAPSHOT FIRING');
            querySnapshot.docChanges().forEach((change) => {
                const tempInvoice = change.doc.data();
                tempInvoice.ID = change.doc.id;
                tempInvoice.realInvoiceDate = new Date(tempInvoice.InvoiceDate);
                tempInvoice.realJobDate = new Date(tempInvoice.StartDate);
                
                // Ensure LineItems exists
                if (!tempInvoice.LineItems) {
                    tempInvoice.LineItems = [];
                }

                if (change.type === "added") {
                    invoicesRef.current.push(tempInvoice);
                }
                if (change.type === "modified") {
                    const invoiceIndex = invoicesRef.current.findIndex((d) => d.ID === tempInvoice.ID);
                    invoicesRef.current[invoiceIndex] = tempInvoice;
                }
                if (change.type === "removed") {
                    const invoiceIndex = invoicesRef.current.findIndex((d) => d.ID === tempInvoice.ID);
                    invoicesRef.current.splice(invoiceIndex, 1);
                }
            });

            console.log('setting full home Invoices and invoicesRef.current = ', invoicesRef.current);
            setIsLoading(false);
            setInvoices([...invoicesRef.current]);
        }));
    };
   


    const updateInvoice = (Invoice)=>{
        let tempInvoice ={...Invoice};
        delete tempInvoice.ChildInvoices;
        delete tempInvoice.Dates;
        delete tempInvoice.Dispatches;
        delete tempInvoice.Dispatch;
        delete tempInvoice.Locations;
        delete tempInvoice.LineItemWrapper;
        delete tempInvoice.LineItemTables;
        tempInvoice.Expenses = [];
        tempInvoice.FreightBills = [];
        let dumpSite = {
            ID:tempInvoice.DumpSite.ID,
            Name: tempInvoice.DumpSite.Name ? tempInvoice.DumpSite.Name :'',
            Address:tempInvoice.DumpSite.Address ? tempInvoice.DumpSite.Address  :'',
            Address2:tempInvoice.DumpSite.Address2 ? tempInvoice.DumpSite.Address2:'',
            City:tempInvoice.DumpSite.City ? tempInvoice.DumpSite.City:'',
            State:tempInvoice.DumpSite.State ? tempInvoice.DumpSite.State:'',
            ZipCode:tempInvoice.DumpSite.ZipCode ?tempInvoice.DumpSite.ZipCode   :'',
          
            fullAddress:tempInvoice.DumpSite.fullAddress ? tempInvoice.DumpSite.fullAddress :'',
        }
        let loadSite = {
            ID:tempInvoice.LoadSite.ID,
            Name: tempInvoice.LoadSite.Name ? tempInvoice.LoadSite.Name :'',
            Address:tempInvoice.LoadSite.Address ? tempInvoice.LoadSite.Address  :'',
            Address2:tempInvoice.LoadSite.Address2 ? tempInvoice.LoadSite.Address2:'',
            City:tempInvoice.LoadSite.City ? tempInvoice.LoadSite.City:'',
            State:tempInvoice.LoadSite.State ? tempInvoice.LoadSite.State:'',
            ZipCode:tempInvoice.LoadSite.ZipCode ?tempInvoice.LoadSite.ZipCode   :'',
     
            fullAddress:tempInvoice.LoadSite.fullAddress ? tempInvoice.LoadSite.fullAddress :'',
        }
        tempInvoice.DumpSite = dumpSite;
        tempInvoice.LoadSite = loadSite;
        tempInvoice.QBUpdated = true;
        console.log('tem,p Inovice = ' , tempInvoice)
        setDocument(tempInvoice, tempInvoice.ID, "Invoices")
    }
    const updateInvoices = () =>{
        let tempInvoices = [];
        for(let i=0; i<invoicesRef.current.length;i++)updateInvoice(invoicesRef.current[i])
    }
    const handleChangeInvoiceCompany = (fieldName, value) =>{
        setHomeObject((prev) => ({ ...prev, [fieldName]: value }));
    }
    const renderHeader = () => (
        <div className="mbsc-grid mbsc-row ">
            <span className="mbsc-col-xl-1">Invoices</span>
            <div className="mbsc-col-xl-3">
                <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon search-auto-input'  suggestions={companies} setValue={setInputValues} handleFieldChange={changeCompany} />
            </div> 
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                </div> 
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} disabled={searchDisabled} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                </div>
            </div>
               
             
            <div className="mbsc-col-xl-4" style={{paddingRight:".5em"}}>  
                    <AutoCompleteInput label="Customer" fieldName="Account" field="Name" hideAddCustom={true} value={inputValues.Account} labelClass='p-inputgroup-addon search-auto-input'  disabled={searchDisabled} databaseList={'Accounts'} suggestions={accounts} setValue={setInputValues} handleFieldChange={changeAccount} />
                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: '0.5em', marginTop: '0.5em'}}>
                        <Button  
                            color="primary" 
                            disabled={searchDisabled} 
                            onClick={(e) => queryInvoices()} 
                            style={{height:"50%", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em"}}
                        >
                            Search Invoices
                        </Button>
                        <Button  
                            color="success" 
                            onClick={downloadSelectedInvoices} 
                            style={{height:"50%", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em"}}
                        >
                            Download Selected IIF
                        </Button>
                    </div>
                </div>


              {/**   <div className="p-inputgroup mbsc-col-3 mbsc-offset-4">
                    <span className="p-inputgroup-addon">Range:</span>
                    <Dropdown value={queryRange} onChange={(e) => setQueryRange( e.value)} options={queryRanges} optionLabel="text" placeholder="Select a Range" className="w-full md:w-14rem" />
            </div>
            <div className="mbsc-col-3">
                <AutoCompleteInput fieldName="Company" field="CompanyName" value={homeObject.Company} suggestions={companies}  setValue={setHomeObject}  handleFieldChange={handleChangeInvoiceCompany}   />
            </div>*/}

        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit = (rowData) => {
      
       var win = window.open('/invoice/invoice/'+rowData.ID, '_blank');
       win.focus();
 
   
    };
    

       
   

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
             <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

    const quickbooksBodyTemplate = (rowData) => (
     
        <IIFDownloadComponent invoices={[rowData]} /> 
    );
    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this Invoice?")) {
               await deleteDocument(rowData,'Invoices' )
            }
            
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

    const booleanFilterTemplate = (options) => {
        return (
          <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
            value={options.value !== null ? options.value : undefined}
            onChange={(e) => options.filterApplyCallback(e.value)}
          />
        );
      };
      const booleanBodyTemplate = (rowData, field) => {
        let color = rowData[field] ? 'green' : 'red';
        return (
          <i style={{color:color}}
            className={classNames('pi', {
              'true-icon pi-check-circle': rowData[field],
              'false-icon pi-times-circle': !rowData[field]
            })}
          />
        );
      };
    const dateBodyTemplate = (rowData, fieldName) => {
    
        return formatDate( rowData[fieldName], '/', 'MM/DD/YYYY');
    };
    const currencyBodyTemplate = (rowData, fieldOne, fieldTwo) => {
  
        let formattedNumber = Number(Number(rowData[fieldOne][fieldTwo]).toFixed(2)).formatMoney(2)
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
	const handleTruckTypeChange = async (rowData, newName) => {
		console.log('handleTruckTypeChange called with rowData:', rowData);	
		console.log('newName = ', newName)
        try {
            // Create a copy of the invoice with updated truck type
            const updatedInvoice = {
                ...rowData,
                TruckTypeName: newName
            };
            
            // Update the invoice in Firestore
            await updateInvoice(updatedInvoice);
            
            // Update local state
            const updatedInvoices = invoices.map(invoice => {
                if (invoice.ID === rowData.ID) {
                    return updatedInvoice;
                }
                return invoice;
            });
            setInvoices(updatedInvoices);
            
            // Show success toast
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Truck type name updated to ' + newName,
                    life: 3000
                });
            }
        } catch (error) {
            console.error('Error updating truck type name:', error);
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update truck type name',
                    life: 3000
                });
            }
        }
    };
    // Add this new function to handle account name updates
    const handleAccountNameChange = async (rowData, newName) => {
        try {
            // Create a copy of the invoice with updated account name
            const updatedInvoice = {
                ...rowData,
                Account: {
                    ...rowData.Account,
                    Name: newName
                }
            };
            
            // Update the invoice in Firestore
            await updateInvoice(updatedInvoice);
            
            // Show success toast
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Account name updated',
                    life: 3000
                });
            }
        } catch (error) {
            console.error('Error updating account name:', error);
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update account name',
                    life: 3000
                });
            }
        }
    };

    // Add this new state handler for truck type changes
    const handleTruckTypeInputChange = (rowData, value) => {
        console.log('handleTruckTypeInputChange called with value:', value);
        
        // Find and update the invoice in the invoices array
        const updatedInvoices = invoices.map(invoice => {
            if (invoice.ID === rowData.ID) {
                return {
                    ...invoice,
                    TruckTypeName: value
                };
            }
            return invoice;
        });
        setInvoices(updatedInvoices);
    };

    // Add a new state to store the selected QBItem for each invoice
    const [invoiceQBItems, setInvoiceQBItems] = useState({});

    // Add a new state to store the temporary input values while typing
    const [qbItemInputValues, setQBItemInputValues] = useState({});

    // Add a function to handle QB item selection at the invoice level
    const handleInvoiceQBItemChange = async (invoiceId, value) => {
        console.log('Selected Invoice-level QuickBooks item:', value);
        
        try {
            // Update the state for this invoice
            setInvoiceQBItems(prev => ({
                ...prev,
                [invoiceId]: value
            }));
            
            // Find the invoice in the sorted list
            const invoice = sortedInvoices.find(inv => inv.ID === invoiceId);
            if (!invoice) return;
            
            // Create a copy of the invoice with the updated QBItem
            const updatedInvoice = {
                ...invoice,
                QBItem: value
            };
            
            // Update all line items with the same QBItem
            if (updatedInvoice.LineItems && updatedInvoice.LineItems.length > 0) {
                updatedInvoice.LineItems = updatedInvoice.LineItems.map(lineItem => ({
                    ...lineItem,
                    QBItem: value
                }));
            }
            
            // Update the invoice in Firestore
            await updateInvoice(updatedInvoice);
            
            // Update local state
            const updatedInvoices = invoices.map(inv => {
                if (inv.ID === invoiceId) {
                    return updatedInvoice;
                }
                return inv;
            });
            setInvoices(updatedInvoices);
            
            // Show success toast
            if (toast.current) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'QuickBooks item updated for invoice and all line items',
                    life: 3000
                });
            }
        } catch (error) {
            console.error('Error updating QuickBooks item:', error);
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to update QuickBooks item',
                    life: 3000
                });
            }
        }
    };

    // Add a new state to track selected invoices for export
    const [selectedInvoicesForExport, setSelectedInvoicesForExport] = useState({});
    const iifDownloadRef = useRef(null);

    // Add a function to handle checkbox changes
    const handleExportCheckboxChange = (invoiceId, checked) => {
        setSelectedInvoicesForExport(prev => ({
            ...prev,
            [invoiceId]: checked
        }));
    };
    
    // Add a function to download selected invoices
    const downloadSelectedInvoices = () => {
        // Get all selected invoice IDs
        const selectedIds = Object.keys(selectedInvoicesForExport).filter(id => selectedInvoicesForExport[id]);
        
        if (selectedIds.length === 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'No Invoices Selected',
                detail: 'Please select at least one invoice to export',
                life: 3000
            });
            return;
        }
        
        // Find the selected invoices from the sorted invoices list
        const selectedInvoices = sortedInvoices.filter(invoice => selectedIds.includes(invoice.ID));
		setIifInvoices(selectedInvoices)
        // Call the IIF download component with the selected invoices
        if (iifDownloadRef.current) {
           setTimeout(() => {
            iifDownloadRef.current.downloadIIF();
           }, 1000);
        }
    };
    
    // Replace the quickbooksBodyTemplate with exportCheckboxTemplate
    const exportCheckboxTemplate = (rowData) => (
        <div className="flex justify-content-center">
			 <Checkbox checked={selectedInvoicesForExport[rowData.ID] }   onChange={(e) => handleExportCheckboxChange(rowData.ID, e.checked)}/>
          
        </div>
    );

    const header = renderHeader();

    const [expandedRows, setExpandedRows] = useState({});

    // Add a new state to store dispatch data for expanded rows
    const [dispatchData, setDispatchData] = useState({});

    // Modify the onRowToggle function to fetch dispatch data
    const onRowToggle = (e) => {
        console.log('onRowToggle called with event:', e);
        setExpandedRows(e.data);
        
        // Find which row was just expanded
        const expandedRowKeys = Object.keys(e.data);
        for (const rowId of expandedRowKeys) {
            // If this row is newly expanded and we don't have its dispatch data yet
            if (e.data[rowId] === true && !dispatchData[rowId]) {
                // Find the invoice data
                const invoice = sortedInvoices.find(inv => inv.ID === rowId);
                if (invoice && invoice.ParentID) {
                    fetchDispatchData(invoice.ID, invoice.ParentID);
                }
            }
        }
    };

    // Modify the fetchDispatchData function to initialize the invoice QB item
    const fetchDispatchData = async (invoiceId, dispatchId) => {
        try {
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches/${dispatchId}`;
            const docRef = doc(db, queryName);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
                const dispatch = docSnap.data();
                console.log(`Fetched dispatch for invoice ${invoiceId}:`, dispatch);
                
                // Update the dispatch data state
                setDispatchData(prev => ({ ...prev,  [invoiceId]: dispatch  }));
                
                // Initialize the invoice QB item if not already set
                const invoice = sortedInvoices.find(inv => inv.ID === invoiceId);
                if (invoice && invoice.QBItem && !invoiceQBItems[invoiceId]) {
                    setInvoiceQBItems(prev => ({  ...prev,    [invoiceId]: invoice.qbItem }));
                } else if (!invoiceQBItems[invoiceId]) {
                    setInvoiceQBItems(prev => ({...prev,  [invoiceId]: { ID: '', Name: 'Select QuickBooks Item' }   }));
                }
            } else {
                console.log(`No dispatch found with ID: ${dispatchId}`);
                // Set a placeholder to avoid repeated fetch attempts
                setDispatchData(prev => ({ ...prev,  [invoiceId]: { notFound: true }  }));
            }
        } catch (error) {
            console.error(`Error fetching dispatch ${dispatchId}:`, error);
        }
    };

    // Update the rowExpansionTemplate to include the invoice-level QuickBooks item autocomplete
    const rowExpansionTemplate = (data) => {
        console.log('Expanding invoice:', data);
        const invoice = data;
    

        
        return (
            <div className="p-3">
            
                {/* Line items table */}
                <table style={{ width: "100%", padding: "1.5em" }}>
                    <thead>
                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                            <th style={{ width: "8%" }}>FB #</th>
                            <th style={{ width: "8%" }}>Truck</th>
                            <th style={{ width: "15%" }}>Driver</th>
                            <th style={{ width: "15%" }}>QB Item</th>
                            <th style={{ width: "15%" }}>Selected Item</th>
                            <th style={{ width: "8%" }}>Rate Type</th>
                            <th style={{ width: "8%" }}>Qty</th>
                            <th style={{ width: "8%" }}>Rate</th>
                            <th style={{ width: "8%" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.LineItems && invoice.LineItems.length > 0 ? (
                            invoice.LineItems.map((lineItem, index) => (
                                <InvoiceHomeLineItemList 
                                    key={index}
                                    invoice={invoice}
                                    lineItem={lineItem}
                                    toggleOnHold={() => {}}
                                    borderWidth="1px 1px 1px 1px"
                                    columns={[
                                        { id: 'fbno', width: '8%' },
                                        { id: 'truck', width: '8%' },
                                        { id: 'driver', width: '15%' },
                                        { id: 'qbItem', width: '15%' },
                                        { id: 'selectedItem', width: '15%' },
                                        { id: 'rateType', width: '8%' },
                                        { id: 'qty', width: '8%' },
                                        { id: 'rate', width: '8%' },
                                        { id: 'total', width: '8%' }
                                    ]}
                                    openExpensePopUp={() => {}}
                                />
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9" style={{ textAlign: 'center' }}>
                                    No line items found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    useEffect(() => {
        console.log('Current invoices:', sortedInvoices);
    }, [sortedInvoices]);

    return (
        <div className="card">
            <Toast ref={toast} />
            {/* Hidden IIF Download Component */}
            <div style={{ display: 'none' }}>
                <IIFDownloadComponent ref={iifDownloadRef} invoices={iifInvoices} showButton={false} />
            </div>
            <DataTable 
                value={sortedInvoices} 
                rows={25} 
                paginator 
                dataKey="ID" 
                stripedRows 
                filters={filters} 
                header={header} 
                filterDisplay="row" 
                emptyMessage="No invoices found."
                expandedRows={expandedRows}
                onRowToggle={(e) => onRowToggle(e)}
                rowExpansionTemplate={rowExpansionTemplate}
             
                expandableRowsIcon="pi pi-chevron-right"
                onRowClick={(e) => console.log('Row clicked:', e.data)}
            >
                <Column 
                    expander 
                    style={{ width: '3rem' }}
                    headerStyle={{ width: '3rem' }}
                />
                <Column pt={{root: { 'data-label': 'Open' }}} header="Open" body={editBodyTemplate} />
                <Column pt={{root: { 'data-label': 'Delete' }}} style={{textAlign:"center"}} header="Delete" body={deleteBodyTemplate} />
                <Column pt={{root: { 'data-label': 'Job Number' }}} field="JobNumber"  style={{ maxWidth: '11rem' }} sortable header="Job Number" filter filterPlaceholder="Search by job number" />
                <Column pt={{root: { 'data-label': 'Invoice Number' }}} field="InvoiceNumber" style={{ maxWidth: '11rem' }} sortable header="Invoice Number" filter filterPlaceholder="Search by invoice number" />
				<Column pt={{root: { 'data-label': 'Truck Type' }}}   style={{ maxWidth: '11rem' }} filterField="TruckTypeName" header="Truck Type"  field="TruckTypeName" />

				{/*<Column 
                    pt={{root: { 'data-label': 'Truck Type' }}} 
                    header="Truck Type" 
                    style={{ maxWidth: '11rem' }}  
                    body={(rowData) => {
                        // Create a wrapper function that extracts the value from the state updater
                        const truckTypeWrapper = {
                            TruckTypeName: rowData.TruckTypeName || '',
                            TruckType: rowData.TruckTypeName || ''
                        };
                        
                        const customSetValue = (updaterFn) => {
                            // Extract the value by simulating the state update
                            const dummyPrev = { TruckType: rowData.TruckTypeName || '' };
                            const updated = updaterFn(dummyPrev);
                            const newValue = updated.TruckType;
                            
                            console.log('Extracted value:', newValue);
                            handleTruckTypeInputChange(rowData, newValue);
                        };
                        
                        return (
                            <AutoCompleteInput 
                                label="Truck Type" 
                                fieldName="TruckType" 
                                field="Name" 
                                value={truckTypeWrapper.TruckTypeName} 
                                suggestions={truckTypes}   
                                setValue={customSetValue}
                                handleFieldChange={(fieldName, value) => handleTruckTypeChange(rowData, value.Name)}
                            />
                        );
                    }}
                />*/}


				<Column pt={{root: { 'data-label': 'Account' }}}  filtefilterField="Account.Name" header="Account" style={{ maxWidth: '11rem' }}  body={(rowData) => (
                    <InputText
                        value={rowData.Account?.Name || ''}
                        onChange={(e) => handleAccountNameChange(rowData, e.target.value)}
                        style={{ width: '100%' }}
                        placeholder="Enter account name"
                    />
                )}/>
                <Column pt={{root: { 'data-label': 'Invoice Date' }}} field="realInvoiceDate" header="Invoice Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'realInvoiceDate')}   filterPlaceholder="Search by Invoice Date" />
                <Column pt={{root: { 'data-label': 'Job Date' }}} field="realJobDate" header="Job Date" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData, 'realJobDate')}    filterPlaceholder="Search by Job Date" />
                <Column pt={{root: { 'data-label': 'Paid' }}} field="Paid" header="Paid" dataType="boolean" style={{ minWidth: '6rem' }}  body={(rowData) => booleanBodyTemplate(rowData, 'Paid')} filter filterElement={booleanFilterTemplate}/>
                <Column pt={{root: { 'data-label': 'Total' }}} header="Total" field="Total.Total" body={(rowData) => currencyBodyTemplate(rowData, 'Total','Total')} sortable dataType="number"  style={{ minWidth: '6rem' }}/>
                <Column pt={{root: { 'data-label': 'Balance' }}} header="Balance" field="Balance.Total"   body={(rowData) => currencyBodyTemplate(rowData, 'Balance', 'Total')} sortable style={{ minWidth: '6rem' }}/>
                <Column 
                    pt={{root: { 'data-label': 'Export' }}}
                    header="Export" 
                    body={exportCheckboxTemplate} 
                    style={{ textAlign: 'center', width: '5rem' }}
                />
            </DataTable>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    );
};

export default InvoicesHomeQuickbooks;
