import React, { useState, useEffect, useRef} from 'react';
import { Dialog } from 'primereact/dialog';

import { TabView, TabPanel } from 'primereact/tabview';

import { useInvoice } from './context/InvoiceContext';
import { usePrint } from './context/PrintContext';
import { UserAuth } from '../../context/AuthContext';
import InvoiceComponent from './InvoiceComponent';
import IIFDownloadComponent from '../MiscComponents/IIFDownloadComponent';



const InvoicePopUp = (props) => {
    const [activeTab, setActiveTab]= useState(0);
	const { company} = UserAuth();
    const { invoiceVisible,  setInvoiceVisible, invoice, setInvoice,  invoiceRef, invoicesRef, invoices   } = useInvoice();

    const {  setShowPrintTags,showPrintPopUp,startCreateInvoices } = usePrint();
    const iifDownloadRef = useRef();
    useEffect(()=>{
		console.log(' setting invoicesRef.current = ', invoicesRef.current)
		console.log(' to the invoices = ', invoices)
        invoicesRef.current=invoices;
    },[invoices])
    const closeInvoicePopUp = () => {
        setInvoiceVisible(false); 
    };

  const createInvoices = () =>{
	console.log('company = ', company)
    if(company.downloadIIF )   iifDownloadRef.current.downloadIIF();
    startCreateInvoices();
  }
    const renderHeader = () => {
        return (
            <div  style={{paddingTop:".25em", paddingLeft:".5em"}}>
            <span>Invoice Details </span>
           <button style={{ float:'right', margin: '0', padding: '.5em', marginRight:'6em', width:"8em" }}   onClick={(e) => createInvoices()}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Create Invoices</button>
            </div>
        );
    };
    const changeInvoiceTab =(e)=>{
        console.log('tab clicked = ' ,e)
        if(e.index!==invoices.length){
            setInvoice({...invoices[e.index]});
            setShowPrintTags(false);
            
            setActiveTab(e.index)
            invoiceRef.current={...invoices[e.index]};
        }else showPrintPopUp(invoicesRef.current[0], true)
    }
    const tabHeader =(index)=>{
        console.log('SETING TAB EHADER = ', index)
        index+=1;
        return "Invoice - "+index.toString();
    }
    const header =renderHeader();
    console.log('invoices = ', invoices)
	console.log('invoices ref in pop up= ', invoicesRef.current)
	console.log('invoicesRef.current  in the pop up re render = ' +  invoicesRef.current[0]?.LineItems.length)

return(
    <React.Fragment>
    {invoice.Account && (
        
            <Dialog header={header} headerStyle={{padding:"0"}} visible={invoiceVisible} style={{ width: '100vw', height:"100%", maxHeight:"98%" }} breakpoints={{ '960px': '90vw', '641px': '100vw' }}  onHide={closeInvoicePopUp}>
         
                {invoicesRef.current.length>1 && (<div>
                    <TabView  style={{margin:"0"}} activeIndex={activeTab} onTabChange={(e) => changeInvoiceTab(e)}  >
                        {invoicesRef.current.map(( inv, index ) => (   <TabPanel header={tabHeader(index)} key={index}  style={{marginTop:"0"}}  />))}
                        <TabPanel header="Print All" style={{marginTop:"0"}}  />
                    </TabView> 
                  
               </div> )}
			   <IIFDownloadComponent  ref={iifDownloadRef}  invoices={invoicesRef.current}  showButton={false} />
                <InvoiceComponent/>
        </Dialog>)}
    </React.Fragment>
);
};

export default InvoicePopUp;