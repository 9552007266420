import React from 'react';


const FreightStatusSpan = ({ freight, fieldName, driverFieldName}) => {
    if(!driverFieldName) driverFieldName='driver'+fieldName;

    return(
        <React.Fragment>
            {freight[driverFieldName] || freight[driverFieldName]===0 ? (<span>{freight[driverFieldName]}</span>):(<span>{freight[fieldName]}</span>)}
        </React.Fragment>
    )
}
export default FreightStatusSpan;