import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import RowExpansionComponent from '../../Invoice/LineItemComponents/RowExpansionComponent';
import debounce from 'lodash/debounce';
import { db } from '../../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';
import { useGlobal } from '../../../context/GlobalContext';

const ReportTable = ({reportTable,repParent, setReportParents, parentIndex,reportParents, openFreightBill, setReportTotals,inputValues, formatDate, openPayStatement, openInvoice} ) => {
    const width= reportTable.Columns.length<=7 ? (reportTable.Columns.length*14.2).toString() +'%' : '100%';
	const { gearedUser} = UserAuth();
	const { setExpensePopUpVisible, setExpense } = useGlobal();
    const [expandedRows, setExpandedRows] = useState(null);
    const [virtualScrollerEnabled, setVirtualScrollerEnabled] = useState(true);

    const [itemSize, setItemSize] = useState(window.innerWidth > 767 ? 46 : reportTable.Columns.length *35);
	console.log('itemSize = ', itemSize)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jobDateValue : { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LoadSiteName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        DumpSiteName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        AccountName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName:{ value: null, matchMode: FilterMatchMode.CONTAINS },
        DPSNum:{ value: null, matchMode: FilterMatchMode.CONTAINS },
		Balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        Total: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        paid: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    
    const dt = useRef(null); 

    useEffect(() => {
        console.log('we are setting filtered Data !!' ,repParent.reportData )
       resetFilters();
    }, [repParent.reportData,  inputValues.Company.ID, inputValues.Account.ID]);


    const resetFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            jobDateValue: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            JobNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            InvoiceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
            LoadSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            DumpSiteName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            AccountName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            driverName: { value: null, matchMode: FilterMatchMode.CONTAINS },
            DPSNum: { value: null, matchMode: FilterMatchMode.CONTAINS },
			Balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
			Total: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            paid: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
    
        if (dt.current) {
            dt.current.reset();  // Reset the table filters
        }
    };
  
    const originalData = useMemo(() => {
        return repParent.reportData.filter(item => 
            item.CompanyID === inputValues.Company.ID && 
            (inputValues.Account.ID === '' || item.AccountID === inputValues.Account.ID)
        );
    }, [repParent.reportData, inputValues.Company.ID, inputValues.Account.ID]);

    useEffect(() => {
		console.log('originalData = ', originalData)
        onTableFilter(originalData, parentIndex);
    }, [originalData, parentIndex]);
   
 
    useEffect(() => {
        const handleResize = () => {  setItemSize(window.innerWidth > 767 ? 46 : 200);  };
        window.addEventListener('resize', handleResize);
        
        let tempColumns = [...reportTable.Columns];
        for(let i=0; i<tempColumns.length; i++){
            if(tempColumns[i].field!=='jobDateValue' && tempColumns[i].field!=='Balance' && tempColumns[i].field!=='Total' ) tempColumns[i].filterable=true;
			else {
				tempColumns[i].maxWidth="10em";
			}
			if(tempColumns[i].field==='JobNumber' || tempColumns[i].field==='InvoiceNumber') tempColumns[i].maxWidth="8em";
			if(tempColumns[i].field==='LoadSiteName' || tempColumns[i].field==='DumpSiteName') tempColumns[i].maxWidth="25em";
			if(tempColumns[i].field==='jobDate') tempColumns[i].maxWidth="15em";
        }
        return () => {  window.removeEventListener('resize', handleResize);   };
    },[]);

    useEffect(() => {
        let totals = {};

        reportTable.Columns.forEach(col => {
            if (col.showTotal) {
                totals[col.field] = sumValues(repParent.filteredData, col.field);
            }
        });
        console.log('setting reportTotals = ',originalData)
        setReportTotals(prevTotals => {
            const updatedTotals = { ...prevTotals, [repParent.header]: totals };
            return updatedTotals;
        });
    }, [repParent.filteredData]);
 

    const sumValues = (items, field) =>items.reduce((sum, item) => {
        if (item.finalRow) return sum; // Skip the final row
        const value = Number(item[field] || 0);
        const newTotal = sum + value;
        return Math.round((newTotal + Number.EPSILON) * 100) / 100;
    }, 0);
    
    const memoizedBodyTemplate = useMemo(() => {
        return (rowData, col) => {
            if(col.Type==='date') 
                return <div style={{ fontWeight: rowData.finalRow && 'bold', fontSize: rowData.finalRow && '1.1em'}} >
                    {formatDate(rowData[col.field], '/', 'MM/DD/YYYY')}
                </div>;
                
            if(col.Type==='number'){
                let value = rowData[col.field] != null && !isNaN(rowData[col.field]) ? 
                    Number(rowData[col.field]) : null;
                if (value !== null) 
                    value = col.title !== 'Hours'? 
                        value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 
                        value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                else value = ''; 
                return(
                    <div style={{textAlign: 'right', paddingRight:".5em"}}>
                        {col.title !== 'Hours' && col.title !== 'Weight' && 
                            (<span >$</span>)} 
                        <span>{value}</span>
                    </div>
                );
            }
            
            if(col.title==='FBNO' && !rowData.finalRow )
                return(
                    <button 
                        style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  
                        onClick={() => openFreightBill(rowData)}   
                        className="mbsc-ios mbsc-btn-primary mbsc-btn"
                    > 
                        {rowData[col.field]} 
                    </button>
                );
                
            if(col.title==='Invoice Number' && !rowData.finalRow )return(<button style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  onClick={(e) => openInvoice(rowData.ID)}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {rowData[col.field]} </button>)
            else if(col.title==='Pay Statement Number' && !rowData.finalRow )return(<button style={{ margin: '0', padding: '.5em', width:"80%", marginLeft:'.5em'}}  onClick={(e) => openPayStatement(rowData.ID)}   className="mbsc-ios mbsc-btn-primary mbsc-btn"     > {rowData[col.field]} </button>)
            else return(<div style={{ fontWeight: rowData.finalRow && 'bold', fontSize: rowData.finalRow && '1.1em'}} >{rowData[col.field]}</div>)
        };
    }, [formatDate, openFreightBill, openInvoice, openPayStatement]);

	const openExpensePopUp = async (lineItem) => {
        try {
            const expenseDoc = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`, lineItem.ID);
            const expenseSnap = await getDoc(expenseDoc);
            console.log('lineitem we opening  = ', lineItem)
            if (expenseSnap.exists()) {
				console.log('expenseSnap.data() = ', expenseSnap.data())
                const expense = {...expenseSnap.data(), ID: expenseSnap.id};
                setExpense(expense);
                setExpensePopUpVisible(true);
            } else {
                console.error('No expense found with ID:', lineItem.ID);
            }
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };


	const rowExpansionTemplate = (data) => (<RowExpansionComponent invoice={data} openExpensePopUp={openExpensePopUp}/>)

    const onTableFilter = (e, index) => {
        console.log('report parents when we are filtering = ', reportParents);
		console.log('and e = ', e)
		
        let tempReportParents = [...reportParents];
        tempReportParents[index].filteredData = [...e];
        setReportParents(tempReportParents);
    };

	const columnPassThrough = (label) =>{
		return {
			root: { 
				'data-label': label   // Add a unique className
			},
			columnFilter:{
				style:{ width:"100%"}
			},
			filterInput: {    // Target the filter input specifically
				className: 'full-width-filter',
				style: {
					width: '100%'
				}
			},
			headerFilterClearButton: {  // Hide the menu button
				style: {
					display: 'none'
				}
			}
		};
	}


    console.log('repotable = ', reportTable)
    console.log('report parents = ', reportParents)

    const createFooter = useMemo(() => {
        return (
            <ColumnGroup>
                <Row>
				<Column key='empty expander footer column'/>
                    {reportTable.Columns.map((col, index) => {
                        const total = col.showTotal ? sumValues(repParent.filteredData, col.field) : null;
                        const columnDataLabel = col.showTotal ? col.title : '';
                        let formattedValue = '';
                        if (total !== null) {
                            if (col.title === 'Hours') formattedValue = total.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
                            else if(col.title === 'Weight') formattedValue = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            else formattedValue = `$${total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                        }
                        console.log('itemSize = ' + itemSize)
                        if(columnDataLabel || itemSize===46) return (
                           
                           <Column
                                key={index}
                                pt={{root: { 'data-label':columnDataLabel }}}
                                footer={formattedValue}
                                footerStyle={{ textAlign: col.Type === 'number' ? 'right' : 'left', padding: '0', paddingRight:".5em", fontWeight: 'bold' }}
                            />
                          
                       
                        );
                    })}
                </Row>
            </ColumnGroup>
        );
    }, [repParent.filteredData, reportTable.Columns]);
	
    const debouncedFilter = useCallback(
        debounce((value, index) => {
            console.log('we filtering with value = ', value)
            onTableFilter(value, index);
        }, 100),
        [reportParents]
    );

    const handleFilterChange = (e, parentIndex) => {
        console.log('handle Filter change  = ', e);
        console.log('parent index = ', parentIndex);
        
		
        debouncedFilter(e, parentIndex);
   
        
    };
    console.log('repParent.filteredData.length = ', reportParents[0].filteredData.length)

	const onRowToggle = (e) => {
        setExpandedRows(e.data);
        setVirtualScrollerEnabled(Object.keys(e.data || {}).length === 0);
    };


   
	console.log('virtualScrollerEnabled = ', virtualScrollerEnabled)
    return(
        <div style={{width:width}}>
          
            <DataTable 
                value={originalData} 
                scrollable 
                scrollHeight="600px"
                ref={dt}
				pt={{header: {style: {padding: '.5em'}}}}
                header={repParent.header}    
                footerColumnGroup={createFooter} 
			    onValueChange={(e)=>handleFilterChange(e, parentIndex)} 
  
                filters={filters}
                style={{ padding: '.5em', width: '100%' }}    
                filterDisplay="row"
			
				tableStyle={{ tableLayout: "fixed" }}
				virtualScrollerOptions={{ 
                    itemSize: 46,
                    delay: 150,
                    cache: true,
               
              
                }}
				expandedRows={expandedRows}
				onRowToggle={onRowToggle}
				rowExpansionTemplate={rowExpansionTemplate}
            > 
                <Column   expander  style={{ maxWidth: '2.5rem' }}    headerStyle={{ width: '2.5rem' }}  />
                {reportTable.Columns.map((col, index) => {
                 
                    return (
                        <Column 
                            pt={columnPassThrough(col.title)}
				
                            key={index} 
                            field={col.field}  
                            dataType={col.field === 'Balance' ? 'numeric' : col.Type}
                            filter={col.filterable} 
							bodyStyle={{width: col.maxWidth}}
							headerStyle={{ width: col.maxWidth}}
                            header={col.title} 
                            sortable   
							showFilterMenu={false} 
                            body={(rowData) => memoizedBodyTemplate(rowData, col)}
                        /> 
                    );
                })}
            </DataTable>
                     
    </div>
    );
}
export default ReportTable;
