import React, {useState,useContext,useRef, createContext,useEffect} from 'react'
import { db } from '../../../firebase';
import {  query,  collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';

const NewPrevailingWageContext = createContext();

export const NewPrevailingWageContextProvider = ({ children }) => {
    const { gearedUser} = UserAuth();


    const [prevailingWageReport, setPrevailingWageReport] = useState(null);
    const [prevailingWageReports, setPrevailingWageReports] = useState(null);
	const [isLoading, setIsLoading] = useState(false);


    const prevailingWageReportsRef = useRef(null);
    const prevailingWageReportListenerRef = useRef(null);
	

    const queryPrevailingWageReports= ()=>{
        console.log('we are running the query prevailing wage reports')
        if (prevailingWageReportListenerRef.current) prevailingWageReportListenerRef.current();
        prevailingWageReportsRef.current=[];
        const queryName = `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports`;
        const q = query(collection(db, queryName));

        prevailingWageReportListenerRef.current= onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempPrevailingWageReport = change.doc.data();
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log(' source = '+ source);
                console.log('found at emp report =  ', tempPrevailingWageReport);
                tempPrevailingWageReport.prevailEndDateValue= tempPrevailingWageReport.prevailEndDate ? new Date(tempPrevailingWageReport.prevailEndDate) :
				tempPrevailingWageReport.EndDate ? new Date(tempPrevailingWageReport.EndDate) : '';
                tempPrevailingWageReport.prevailStartDateValue= tempPrevailingWageReport.prevailStartDate ? new Date(tempPrevailingWageReport.prevailStartDate) :
				tempPrevailingWageReport.StartDate ? new Date(tempPrevailingWageReport.StartDate) : '';
				tempPrevailingWageReport.latestDispatchDateValue= tempPrevailingWageReport.latestDispatchDate ? new Date(tempPrevailingWageReport.latestDispatchDate) : '';
                if (change.type === "added") {
                    tempPrevailingWageReport.ID = change.doc.id;
                    prevailingWageReportsRef.current.push(tempPrevailingWageReport);
                }
                if (change.type === "modified") {
                    const reportIndex = prevailingWageReportsRef.current.findIndex(d => d.ID === tempPrevailingWageReport.ID);
                    prevailingWageReportsRef.current[reportIndex] = tempPrevailingWageReport;
                }
                if (change.type === "removed") {
                    const reportIndex = prevailingWageReportsRef.current.findIndex(d => d.ID === tempPrevailingWageReport.ID);
                    prevailingWageReportsRef.current.splice(reportIndex,1);
                }
            });
			console.log('prevailingWageReportsRef.current = ', prevailingWageReportsRef.current)
                setPrevailingWageReports([...prevailingWageReportsRef.current])
        
        });
    }



   
    return (
        <NewPrevailingWageContext.Provider value={{
      	isLoading, setIsLoading, prevailingWageReport, setPrevailingWageReport,  queryPrevailingWageReports, prevailingWageReports,
		
        }}>
            {children}
        </NewPrevailingWageContext.Provider>
    );
}
export const usePrevailingWage= () => {
    return useContext(NewPrevailingWageContext);
};