import React, { useRef, useEffect, useState, useCallback } from 'react';

import { UserAuth } from '../../context/AuthContext';
import { useReport } from './context/ReportContext';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode} from 'primereact/api';

import { TabView, TabPanel } from 'primereact/tabview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faMinusCircle} from '@fortawesome/free-solid-svg-icons';

import CustomizeTable from './CustomizeTable'; 


import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const ReportHome= () => {
    const navigate = useNavigate();
    const { reportTabType, listType } = useParams();
    const { gearedUser,deleteDocument} = UserAuth();
    const {setSelectFields, showSelectFields, setShowSelectFields, setTableType,reportTable, setReportTable, freightBillFields,invoiceFields, payStatementFields,driverFields, setReportName, getDefaultReports, reportList,  getReports} = useReport();

   

    const [activeTab, setActiveTab]= useState(0);
    const [reportType, setReportType]= useState(reportTabType);
    const reportTypes = ['Drivers', 'FreightBills', 'Invoices', 'PayStatements'];

    const reportTableRef = useRef(null);

    const reportTypeRef = useRef(null);



    useEffect(()=>{
        if(reportTabType==='Drivers')setActiveTab(0);
        if(reportTabType==='FreightBills')setActiveTab(1);
        if(reportTabType==='Invoices') setActiveTab(2);
        if(reportTabType==='PayStatements') setActiveTab(3);
        reportTypeRef.current=reportTabType;
        setReportType(reportTabType);
    },[reportTabType]);
    
    useEffect(()=>{
        reportTableRef.current=reportTable;
    },[reportTable]);

    useEffect(()=>{
        if(gearedUser){
            setTableType(reportType)
           if(listType==='create') getDefaultReports();
            else getReports();

        }
    },[gearedUser, listType])


    const changeReportType=(e)=>{
        if(e.index===0) navigate('/report/reportHome/'+listType+'/Drivers', { replace: true });
        if(e.index===1) navigate('/report/reportHome/'+listType+'/FreightBills', { replace: true });
        if(e.index===2) navigate('/report/reportHome/'+listType+'/Invoices', { replace: true });
        if(e.index===3) navigate('/report/reportHome/'+listType+'/PayStatements', { replace: true });
        setActiveTab(e.index);
    }

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit =  (rowData) => {
   
       if(activeTab===0) navigate('/report/reportBuilder/'+listType+'/Drivers/'+ rowData.ID);
       if(activeTab===1) navigate('/report/reportBuilder/'+listType+'/FreightBills/'+ rowData.ID);
       if(activeTab===2) navigate('/report/reportBuilder/'+listType+'/Invoices/'+ rowData.ID);
       if(activeTab===3) navigate('/report/reportBuilder/'+listType+'/PayStatements/'+ rowData.ID);
   
    };

    const deleteBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleDelete(rowData)}>
             <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
        </button>
    );

    const handleDelete = async (rowData) => {
        try {
            if (window.confirm("Are you sure you want delete this Report?")) {
                deleteDocument(rowData,'DefaultReports' )
            }  
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

 
    const customizeTable = () =>{
        setReportName(reportType+' Report')
        if(reportType==='FreightBills')  setSelectFields([...freightBillFields])
        if(reportType==='Invoices')  setSelectFields([...invoiceFields])
        if(reportType==='PayStatements')  setSelectFields([...payStatementFields])
        if(reportType==='Drivers')  setSelectFields([...driverFields])
        setShowSelectFields(true);
    }
    const createNewReport=()=>{
        setReportTable({ID:''});
        customizeTable();
    }
 

    return (
        <div >
            {!showSelectFields ? (<div className="mbsc-grid mbsc-justify-content-center">
                <TabView  style={{margin:"0"}} activeIndex={activeTab} onTabChange={(e) => changeReportType(e)}  >
                    {reportTypes.map(( rep, index ) => (   <TabPanel header={rep} key={index}  style={{marginTop:"0"}}  />))}
                </TabView>  
                <div className="mbsc-row">
                    <button style={{ margin: '0', padding: '.5em', width:"10em", marginLeft:'1em'}}  onClick={(e) =>createNewReport()} className="mbsc-ios mbsc-btn-primary mbsc-btn" > New Report </button>
                </div>
                {reportList && (
                    <DataTable key={'reportListTable'} value={reportList.filter(report => report.Type===reportType) } showGridlines sortField="Name" sortOrder={1} style={{ paddingTop: '0em', width: '100%' }} filterDisplay="row" >
                        <Column pt={{root: { 'data-label': 'Run Report' }}} style={{textAlign:"center"}} header="Run Report" body={editBodyTemplate} />
                        <Column pt={{root: { 'data-label': 'Delete' }}} style={{textAlign:"center"}} header="Delete" body={deleteBodyTemplate} />
                        <Column  pt={{root: { 'data-label': 'Name' }}} field='Name' header='Name' sortable  filter/> 
                    </DataTable>

                )}
          
            </div>):(<CustomizeTable tableType={'report'} reportTable={reportTable} reportType={reportType} ></CustomizeTable>)} 
        </div>
    );
}
export default ReportHome;

