import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { useGlobal } from '../../context/GlobalContext';
import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit,faMinusCircle} from '@fortawesome/free-solid-svg-icons';

const EmployeesHome = (props) => {
    const { employees, deleteDocument } = UserAuth();
    const { showEmployeePopUp } = useGlobal();
    const [sortedEmployees, setSortedEmployees] = useState([]);
    useEffect(() => {
        // Sort the employees array by Name
        const sorted = [...employees].sort((a, b) => {
            if (a.Name < b.Name) return -1;
            if (a.Name > b.Name) return 1;
            return 0;
        });
        setSortedEmployees(sorted);
    }, [employees]);



  const deleteBodyTemplate = (rowData) => (
    <button type="button" onClick={() => handleDelete(rowData)}>
         <FontAwesomeIcon className="fas" icon={faMinusCircle} style={{color:"red", height:"1.25em", textAlign:"center", width:"1.25em !important"}}/> 
    </button>
);

const handleDelete = async (rowData) => {
    try {
        if (window.confirm("Are you sure you want delete this Employee?")) {
            deleteDocument(rowData,'Employees' )
        }  
    } catch (error) {  console.error("Error removing document: ", error);  }
   
};
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
       
        // Don't set anything here for  initially
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [departments] = useState(['Admin', 'Dispatch', 'Foreman', 'Billing', 'Estimating']);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value || '';
        setFilters((prevFilters) => ({
            ...prevFilters,
            global: { ...prevFilters.global, value }
        }));
        setGlobalFilterValue(value);
    };

    const renderHeader = () => (
     
        <div className="flex justify-content-end mbsc-row ">
            <span>Employees</span>
            <IconField iconPosition="left" className="homeSearch">
                <InputIcon className="pi pi-search" />
                <InputText style={{ paddingLeft: '2.5rem' }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </IconField>
        </div>
    );

    const editBodyTemplate = (rowData) => (
        <button type="button" onClick={() => handleEdit(rowData)}>
            <FontAwesomeIcon icon={faEdit} />
        </button>
    );

    const handleEdit = (rowData) => {
        showEmployeePopUp(rowData);
    };

    const departmentBodyTemplate = (rowData) => (
        <Tag value={rowData.Department} severity={rowData.Department} />
    );

    const departmentItemTemplate = (option) => (
        <Tag value={option} severity={option} />
    );

    const booleanBodyTemplate = (rowData, field) => (
        <i className={classNames('pi', {
            'true-icon pi-check-circle': rowData[field],
            'false-icon pi-times-circle': !rowData[field]
        })} />
    );

    const departmentRowFilterTemplate = (options) => (
        <Dropdown 
			value={options.value || undefined} 
			options={departments} 
			onChange={(e) => options.filterApplyCallback(e.value)} 
			itemTemplate={departmentItemTemplate} 
			placeholder="Select One" 
			className="p-column-filter" 
			showClear style={{ minWidth: '12rem' }} 
		/>
    );

    const booleanFilterTemplate = (options) => (
        <TriStateCheckbox 
			style={{ borderColor: "#d1d5db", background: "white" }} 
			value={options.value || undefined} 
			onChange={(e) => options.filterApplyCallback(e.value)} 
		/>
    );



    const header = renderHeader();

    return (
        <div className="card">
            <DataTable value={sortedEmployees} paginator rows={25} dataKey="ID" filters={filters} header={header} filterDisplay="row" emptyMessage="No employees found.">
                <Column  pt={{root: { 'data-label': 'Edit' }}} header="Edit" body={editBodyTemplate} />
                <Column  pt={{root: { 'data-label': 'Delete' }}} header="Delete" body={deleteBodyTemplate} />
                <Column  pt={{root: { 'data-label': 'Name' }}} field="Name" sortable header="Name" filter filterPlaceholder="Search by name" />
				<Column  pt={{root: { 'data-label': 'Phone' }}} field="displayPhone" sortable header="Phone" filter filterPlaceholder="Search by phone" />
                <Column  pt={{root: { 'data-label': 'Email' }}}field="Email" header="Email" filter filterPlaceholder="Search by Email" />
                <Column  pt={{root: { 'data-label': 'Department' }}} field="Department" header="Department" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} body={departmentBodyTemplate} filter filterElement={departmentRowFilterTemplate} />
            </DataTable>
        </div>
    );
};

export default EmployeesHome;
