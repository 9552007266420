import React, { forwardRef, useImperativeHandle, useRef} from "react";
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const IIFDownloadComponent = forwardRef(({ invoices, showButton = true }, ref) => {
	const toast = useRef(null);
	
	const checkForQBItem = (invoiceList) => {
		// If there are no invoices, return false
		if (!invoiceList || invoiceList.length === 0) {
			return false;
		}
		
		// Check each invoice
		for (const invoice of invoiceList) {
			// If there are no line items, return false
			if (!invoice.LineItems || invoice.LineItems.length === 0) {
				return false;
			}
			
			// Check each line item
			for (const item of invoice.LineItems) {
				// If any line item doesn't have a QBItem, return false
				if (!item.QBItem) {
					console.log('Line item missing QBItem:', item);
					return false;
				}else console.log('Line item has QBItem:', item);
			}
		}
		
		// If we've checked all invoices and all line items have QBItems, return true
		console.log('All line items have QBItems');
		return true;
	};
	
	const generateIIFContent = (invoiceList) => {
        // Header section - only needed once
        let iifContent = `!ACCNT\tNAME\tACCNTTYPE\tDESC\tACCNUM\tEXTRA\n`;
        iifContent += `!INVITEM\tNAME\tINVITEMTYPE\tDESC\tPURCHASEDESC\tACCNT\tASSETACCNT\tCOGSACCNT\tPRICE\tCOST\tTAXABLE\n`;

        // Collect all unique inventory items across all invoices
        const processedItems = new Set();
        invoiceList.forEach(invoice => {
            // Add inventory items
            invoice.Broker.Total = invoice.Broker.Total * -1;
       
            
            // Only define each QuickBooks item once
            invoice.LineItems.forEach(item => {
                if (!processedItems.has(item.QBItem.itemName)) {
                    const formattedRate = parseFloat(item.Rate).toFixed(2);
                    // Define the item with a generic description
                    iifContent += `INVITEM\t${item.QBItem.itemName}\tSERV\t${item.QBItem.Description}\t\t${item.QBItem.Account}\t\t\t${formattedRate}\t0\tN\n`;
                    processedItems.add(item.QBItem.itemName);
                }
            });

            // Add broker fee item if not already added
            if (invoice.Broker?.Total != 0 && !processedItems.has('Broker Fee')) {
                const formattedBrokerTotal = parseFloat(invoice.Broker.Total).toFixed(2);
                iifContent += `INVITEM\tBroker Fee\tSERV\tBroker Fee\t\tIncome:Gross Trucking Income\t\t\t${formattedBrokerTotal}\t0\tN\n`;
                processedItems.add('Broker Fee');
            }

            // Add fuel charge item if not already added
            if (invoice.Fuel?.Total != 0 && !processedItems.has('Fuel Charge')) {
                const formattedFuelTotal = parseFloat(invoice.Fuel.Total).toFixed(2);
                iifContent += `INVITEM\tFuel Surcharge\tSERV\tFuel Surcharge\t\tFuel Expense/Resale:Fuel Cost charged to customer\t\t\t${formattedFuelTotal}\t0\tN\n`;
                processedItems.add('Fuel Charge');
            }
        });

        // Customer section
        iifContent += `!CUST\tNAME\tCOMPANYNAME\tBADDR1\tBADDR2\tBADDR3\tBADDR4\tBADDR5\tPHONE1\n`;
        
        // Add unique customers
        const processedCustomers = new Set();
        invoiceList.forEach(invoice => {
            if (!processedCustomers.has(invoice.Account.Name)) {
                iifContent += `CUST\t${invoice.Account.Name}\t${invoice.Account.Name}\t${invoice.Account.Address || ""}\t${invoice.Account.City || ""}\t${invoice.Account.State || ""} ${invoice.Account.Zip || ""}\t${invoice.Account.Country || ""}\t${invoice.Account.Phone || ""}\n`;
                processedCustomers.add(invoice.Account.Name);
            }
        });

        // Transactions section
        iifContent += `!TRNS\tTRNSID\tTRNSTYPE\tDATE\tACCNT\tNAME\tAMOUNT\tDOCNUM\tPONUM\tTOPRINT\tMEMO\n`;
        iifContent += `!SPL\tSPLID\tTRNSTYPE\tDATE\tACCNT\tNAME\tAMOUNT\tDOCNUM\tQNTY\tPRICE\tINVITEM\tSERVICEDATE\tMEMO\tOTHER2\n`;
        iifContent += `!ENDTRNS\n`;

        // Process each invoice's transactions
        invoiceList.forEach(invoice => {
            const totalAmount = invoice.LineItems.reduce((sum, item) => sum + item.Amount, 0) + 
                              (invoice.Broker?.Total || 0) + 
                              (invoice.Fuel?.Total || 0);

            // Format the total amount to 2 decimal places
            const formattedTotalAmount = parseFloat(totalAmount).toFixed(2);

            // Main transaction
            iifContent += `TRNS\t\tINVOICE\t${invoice.JobDate}\tAccounts Receivable\t${invoice.Account.Name}\t${formattedTotalAmount}\t${invoice.InvoiceNumber}\t${invoice.JobNumber || ''}\tY\t${invoice.JobNumber || ''}\n`;

            // Line items
            invoice.LineItems.forEach(item => {
                // Format the amount, qty, and rate to 2 decimal places
                const formattedAmount = parseFloat(item.Amount).toFixed(2);
                const formattedQty = parseFloat(item.Qty).toFixed(2);
                const formattedRate = parseFloat(item.Rate).toFixed(2);
                
                iifContent += `SPL\t\tINVOICE\t${invoice.JobDate}\t${item.QBItem.Account}\t\t-${formattedAmount}\t${invoice.InvoiceNumber}\t-${formattedQty}\t-${formattedRate}\t${item.QBItem.itemName}\t${item.JobDate || invoice.JobDate}\t${item.FBNO || ''}\t${item.FBNO || ''}\n`;
            });

            // Broker fee
            if (invoice.Broker?.Total != 0) {
                const formattedBrokerTotal = parseFloat(invoice.Broker.Total).toFixed(2);
                iifContent += `SPL\t\tINVOICE\t${invoice.JobDate}\tIncome:Gross Trucking Income\t\t${formattedBrokerTotal}\t${invoice.InvoiceNumber}\t\t\tBroker Fee\t${invoice.JobDate}\tBroker Fee\n`;
            }

            // Fuel charge
            if (invoice.Fuel?.Total != 0) {
                const formattedFuelTotal = parseFloat(invoice.Fuel.Total).toFixed(2);
                iifContent += `SPL\t\tINVOICE\t${invoice.JobDate}\tIncome:Gross Trucking Income\t\t-${formattedFuelTotal}\t${invoice.InvoiceNumber}\t\t\tFuel Surcharge\t${invoice.JobDate}\tFuel Surcharge\n`;
            }

            iifContent += `ENDTRNS\n`;
        });

        return iifContent;
    };

    const downloadIIFFile = () => {
        // Handle both single invoice and array of invoices
        const invoiceList = Array.isArray(invoices) ? invoices : [invoices];
		console.log('invoiceList = ', invoiceList)
        if(checkForQBItem(invoiceList)){
			const content = generateIIFContent([...invoiceList]);
			const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			
			// Generate filename based on number of invoices
			const filename = invoiceList.length === 1 
				? `Invoice_${invoiceList[0].InvoiceNumber}.iif`
				: `Invoices_Batch_${new Date().toISOString().split('T')[0]}.iif`;
				
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
		}else{
			//toast.current.show({severity:'error', summary: 'Error', detail: 'Please select a QuickBooks item for each line item', life: 3000});
			alert('Please select a QuickBooks item for each line item ' )
		}
    };

    // Expose the download function to parent components
    useImperativeHandle(ref, () => ({
        downloadIIF: downloadIIFFile
    }));

    return showButton ? (<>
	   <Toast ref={toast} />
	        <Button 
            	style={{ margin: '0',  padding: '.5em',  width:"12em" }}
            	label={`Download IIF ${Array.isArray(invoices) && invoices.length > 1 ? 'Files' : 'File'}`}
            	onClick={()=>downloadIIFFile()}
        />
    </>) : null;
});

export default IIFDownloadComponent;