import React, {useState,useContext,useRef, createContext,useEffect} from 'react'
import { db } from '../../../firebase';
import {  doc, updateDoc,query,setDoc,  collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from '../../../context/AuthContext';

import { useDriverCalculations } from '../hooks/useDriverCalculations';
import { useOwnerOpCalculations } from '../hooks/useOwnerOpCalculations';
import { usePrevailingWage } from './PrevailingWageContext';
import { usePrevailingWageReport } from './PrevailingWageReportContext';

const PrevailingWageWeekContext = createContext();

export const PrevailingWageWeekContextProvider = ({ children }) => {
	const { gearedUser, drivers, subhaulers,  formatDate } = UserAuth();
    const { setIsLoading } = usePrevailingWage();
	const { prevailingWageReport, setPrevailingWageReport, prevailingWageReportRef } = usePrevailingWageReport();
    const [reportWeek, setReportWeek]=useState({});


    const weekFreightBillsRef = useRef(null);
    const weekFreightBillListenerRef = useRef(null);

    const reportWeekRef = useRef(null);
	const reportWeekListenerRef = useRef(null);

	const foundDriversRef = useRef(false);
	const driversRef = useRef(null);
	const needToCreateReportWeekRef = useRef(false);
    const { calculateDriverTotals } = useDriverCalculations();
    const { calculateOwnerOpTotals } = useOwnerOpCalculations();

	useEffect(()=>{
        reportWeekRef.current=reportWeek;
    },[reportWeek])

	useEffect(()=>{
		driversRef.current = drivers;
		if(drivers?.length > 0 && !foundDriversRef.current) {
		
			foundDriversRef.current = true;
			if(prevailingWageReportRef.current?.ID && needToCreateReportWeekRef.current && reportWeekRef?.current?.weekStartDate){
				needToCreateReportWeekRef.current = false;
				createReportWeek(reportWeekRef.current.weekStartDate);
			}
	
		
		}
	},[drivers])
	useEffect(()=>{
		if(prevailingWageReportRef.current?.ID && needToCreateReportWeekRef.current && reportWeekRef?.current?.weekStartDate){
			needToCreateReportWeekRef.current = false;

			createReportWeek(reportWeekRef.current.weekStartDate);
		}
		
	},[prevailingWageReportRef?.current])

	const updateReportWeekDoc = (updateObject)=>{
     
		console.log('prevailingWageReport = ' + prevailingWageReport.ID)
        let updateDocRef =  doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/PrevailingWageReports/'+prevailingWageReport.ID+'/Weeks/', reportWeek.ID);
		console.log('updateObject = ', updateObject)
        updateDoc(updateDocRef,updateObject);
    }
 
	const createReportWeek = (startDate)=>{
		let endDate = new Date(startDate);
		endDate.setDate(endDate.getDate()+6);
		const formattedStartDate = formatDate(startDate,'/','YYYY/MM/DD');
		const formattedEndDate = formatDate(endDate,'/','YYYY/MM/DD');
		const weekID = formatDate(startDate,'-','MM/DD/YYYY');
		console.log('creating report week for ' + weekID)
		var newWeek={
			name: formatDate(startDate,'/','MM/DD/YYYY') + ' - ' + formatDate(endDate,'/','MM/DD/YYYY'),
			Name:prevailingWageReportRef.current.Name,
			Title:prevailingWageReportRef.current.Title,
			ProjectName:prevailingWageReportRef.current.ProjectName,
			ProjectLocation:prevailingWageReportRef.current.ProjectLocation,
			PrevailingWageRate:prevailingWageReportRef.current.PrevailingWageRate,
			OvertimePrevailingWageRate:prevailingWageReportRef.current.OvertimePrevailingWageRate,
			OwnerOpPrevailingWageRate:prevailingWageReportRef.current.OwnerOpPrevailingWageRate,
			weekStartDate:formattedStartDate,
			weekEndDate: formattedEndDate,
			SignatureDate: formatDate(new Date(), '/', 'MM/DD/YYYY'),
			SignatureDateValue: new Date(),
			ID:weekID,
			runReportButton:'Run Report',
			JobFreightCount: 0,
			TotalFreightCount: 0, 
			DriverCount: 0,
			DocCount:0,
			Reported:false,
			Drivers:[],
			OwnerOps:[],
			Docs:[],
			VNum:1
		}
		
		queryWeekFreightBills(newWeek);
		setDoc(doc(db, 'Organizations/' + gearedUser.selectedOrgName + '/PrevailingWageReports/'+prevailingWageReportRef.current.ID+'/Weeks/', weekID), newWeek);
		console.log('prevailingWageReportRef.current when queryWeekFreightBills is called = ',prevailingWageReportRef.current)
	}

    const calculateReportWeek = (reportWeek, tempPrevailingWageReport)=>{
        const weekFreightBills = weekFreightBillsRef.current;
        console.log('tempPRevailignWAGepreopt.prevailingWageRate= ',  tempPrevailingWageReport)
		console.log('weekFreightBills.length = ' + weekFreightBills.length)
		console.log('drivers.length = ' +driversRef.current.length)
        // Process drivers
        driversRef.current.forEach(driver => {
			foundDriversRef.current = true;
			console.log('driver = ', driver)
            const calculatedDriver = calculateDriverTotals(  
				driver,  
				weekFreightBills, 
				tempPrevailingWageReport.Jobs,   
				reportWeek.PrevailingWageRate,
				reportWeek.OvertimePrevailingWageRate
			);

            if (calculatedDriver.projectPaid !== 0) {
                const existingDriverIndex = reportWeek.Drivers.findIndex(d => d.ID === calculatedDriver.ID);
                
                if (existingDriverIndex !== -1) {
                    // Update only calculated fields for existing drivers
                    const existingDriver = reportWeek.Drivers[existingDriverIndex];
                    reportWeek.Drivers[existingDriverIndex] = {
                        ...existingDriver,
                        projectPaid: calculatedDriver.projectPaid,
                        hoursPerDay: calculatedDriver.hoursPerDay,
						overtimePerDay: calculatedDriver.overtimePerDay,
                   
                    };
                } else {
                    // Add new driver with all fields
                    reportWeek.Drivers.push(calculatedDriver);
                }
            }
        });

        for (let q = 0; q < subhaulers.length; q++) {
            const calculatedOwnerOp = calculateOwnerOpTotals(
                subhaulers[q],
                weekFreightBills,
                tempPrevailingWageReport.Jobs,
                tempPrevailingWageReport.OwnerOpPrevailingWageRate
            );

            if (calculatedOwnerOp.addToReport) {
                const existingOwnerOpIndex = reportWeek.OwnerOps.findIndex(o => o.ID === calculatedOwnerOp.ID);
                if (existingOwnerOpIndex !== -1) {
					console.log('calculatedOwnerOp = ', calculatedOwnerOp)
					const existingOwnerOp= reportWeek.OwnerOps[existingOwnerOpIndex];
                    reportWeek.OwnerOps[existingOwnerOpIndex] = {
                        ...existingOwnerOp,
                        projectPaid: calculatedOwnerOp.projectPaid,
                        hoursPerDay: calculatedOwnerOp.hoursPerDay
      
                    };
                  
                } else {
                    reportWeek.OwnerOps.push(calculatedOwnerOp);
                }
            }
        }
		console.log('reportWeek after createing = ', reportWeek)
		reportWeek.createEmployeeDocs= reportWeek.Drivers.length>0 || false;
		reportWeek.createOwnerOpDocs= reportWeek.OwnerOps.length>0 || false;
	
        setPrevailingWageReport({...tempPrevailingWageReport});
        setReportWeek({...reportWeek});
        setIsLoading(false);
        return reportWeek;
    }
	

    const queryWeekFreightBills = (reportWeek)=>{
		setIsLoading(true);
		weekFreightBillsRef.current = [];
		let hasReceivedServerData = false;
		if ( weekFreightBillListenerRef.current)  weekFreightBillListenerRef.current();
		let startDate = reportWeek.weekStartDate;
		let endDate = reportWeek.weekEndDate;

		console.log('we are now querying the freight bills for the week of ' + startDate + ' to ' + endDate+ ' and gearedUser.selcedted organe m - ' + gearedUser.selectedOrgName)
		const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
		const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
		weekFreightBillListenerRef.current = onSnapshot(q, { includeMetadataChanges: true   },(querySnapshot) => {
			querySnapshot.docChanges().forEach((change) => {
				let tempFB = change.doc.data(); 
				tempFB.ID = change.doc.id;
			
				if (change.type === "added")  weekFreightBillsRef.current.push(tempFB);
				
				if (change.type === "modified") { 
					const freightIndex = weekFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
					weekFreightBillsRef.current[freightIndex] = tempFB;
				}
				if (change.type === "removed") {
					const freightIndex = weekFreightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
					weekFreightBillsRef.current.splice(freightIndex, 1);
				}
			});


			console.log('weekFreightBillsRef.current =- ', weekFreightBillsRef.current);
		
		
			if (!querySnapshot.metadata.fromCache && !hasReceivedServerData) {
				hasReceivedServerData = true;
				calculateReportWeek( reportWeek, prevailingWageReportRef.current);
			}
	});
		
	}


	const fetchPrevailingWageWeek = async (id, weekDate) => {
		
		return new Promise((resolve, reject) => {
		
			if (reportWeekListenerRef.current) reportWeekListenerRef.current();
			console.log('weeekdate = ' + weekDate)
			reportWeekRef.current = {};
			const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrevailingWageReports/${id}/Weeks/`, formatDate(weekDate,'-','MM/DD/YYYY'));
			reportWeekListenerRef.current = onSnapshot(docRef, async (docSnap) => {
				if(docSnap.exists()){
					const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
					if (source === "Server") {
						let tempReportWeek = docSnap.data();
						tempReportWeek.ID = docSnap.id;
						tempReportWeek.SignatureDateValue = tempReportWeek.SignatureDate ? new Date(tempReportWeek.SignatureDate) : new Date();
						reportWeekRef.current= {...tempReportWeek};
						console.log('we are setting the prevailing wagge report now = ', tempReportWeek)
						setReportWeek({ ...tempReportWeek});
						queryWeekFreightBills(tempReportWeek);
						resolve(); 
					}
				}else {
					
					if(prevailingWageReportRef?.current?.ID)createReportWeek(weekDate)
					else needToCreateReportWeekRef.current = true;
					resolve();
				}

			});
		});
	};

   
    return (
        <PrevailingWageWeekContext.Provider value={{
			queryWeekFreightBills,fetchPrevailingWageWeek, reportWeek, reportWeekRef, setReportWeek, weekFreightBillsRef,updateReportWeekDoc
		
        }}>
            {children}
        </PrevailingWageWeekContext.Provider>
    );
}
export const usePrevailingWageWeek= () => {
    return useContext(PrevailingWageWeekContext);
};