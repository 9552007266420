

import React, {useRef} from 'react';
import {  Route, Routes } from 'react-router-dom';
import {PrevailingWageReportContextProvider } from './context/PrevailingWageReportContext';
import {PrevailingWageWeekContextProvider } from './context/PrevailingWageWeekContext';
import PrevailingWageCreate from './PrevailingWageCreate';
import PrevailingWageReport from './PrevailingWageReport';
import PrevailingWageList from './PrevailingWageList';
import PrevailingWageWeek from './PrevailingWageWeek';
import ProtectedRoute from '../ProtectedRoute';
import  {  Page } from '@mobiscroll/react';
import { Toast } from 'primereact/toast';
import { usePrevailingWage } from './context/PrevailingWageContext';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';


function PrevailingWageParent() {
    const { isLoading } = usePrevailingWage();


    console.log('FREIGHT BILL PARENT RE RENDERING')

    const toast = useRef(null);

 
return ( 
    <Page>
   
        <React.Fragment>
        <Toast ref={toast} />
            <PrevailingWageReportContextProvider>
                <Routes>
                    <Route path='create' element={<ProtectedRoute><PrevailingWageCreate/></ProtectedRoute>} />
					<Route path='list' element={<ProtectedRoute><PrevailingWageList/></ProtectedRoute>} />
                    <Route path='report/:id' element={<ProtectedRoute><PrevailingWageReport/></ProtectedRoute>} />
			
				</Routes>
				<PrevailingWageWeekContextProvider>
					<Routes>
						<Route path='week/:id/:weekDate' element={<ProtectedRoute><PrevailingWageWeek/></ProtectedRoute>} />
					</Routes>
				</PrevailingWageWeekContextProvider>
               
				<LoadingOverlay isLoading={isLoading} message="Please wait..." />
                </PrevailingWageReportContextProvider>
        </React.Fragment>
    </Page>
  );
}

export default PrevailingWageParent;