import React, { useState, createContext, useContext } from 'react';
import imageCompression from 'browser-image-compression';
import {useInvoice} from './InvoiceContext';
import pdfMake from 'pdfmake/build/pdfmake';
import { useNavigate } from 'react-router-dom';

const PrintContext = createContext();

export const PrintContextProvider = ({ children }) => {
    const { createInvoice,   setInvoiceVisible, checkInvoiceNumber,  invoicesRef, setIsLoading } = useInvoice();
  
    const navigate = useNavigate();

    const [printVisible, setPrintVisible] = useState(false);
    const [printButtonLabel, setPrintButtonLabel] = useState('Print');
    const [printFBs, setPrintFBs] = useState(false);
    const [printAllInvoices, setPrintAllInvoices] = useState(false);
    const [printTags, setPrintTags] = useState(false);
    const [showPrintTags, setShowPrintTags] = useState(false);
    const [showPrintFBImages, setShowPrintFBImages] = useState(false);

    const [printFBImages, setPrintFBImages] = useState(false);
   
    
    
    const showPrintPopUp = (Invoice, printInvoices)=>{
        console.log('showPrintPopUp = ', Invoice, printInvoices)
        setPrintFBs(true);
        setPrintAllInvoices(printInvoices);
        console.log('printInvoices = ' + printInvoices)
        if(printInvoices){
            for( let p=0; p<invoicesRef.current.length; p++)checkFBsForPrintPopUp(invoicesRef.current[p]);
        }else checkFBsForPrintPopUp(Invoice);
      
          
        setPrintButtonLabel('Print');
        setPrintVisible(true)
    }
    const closePrintPopUp =()=>{
        setPrintVisible(false);
    }
    const checkFBsForPrintPopUp=(Invoice)=>{

        let printFreightBills=[];
        console.log('showprint tags = ', showPrintTags)
        for (var k = 0; k < Invoice.LineItems.length; k++) {
            if (!Invoice.LineItems[k].onHold) {   
                var foundFreightBill = false;
                for (var q = 0; q <printFreightBills.length; q++)if (printFreightBills[q].ID === Invoice.LineItems[k].FreightID)foundFreightBill = true;
                if (!foundFreightBill) {
                    for (var y = 0; y < Invoice.FreightBills.length; y++){
                        console.log('checking FBNP = ' +Invoice.FreightBills[y].FBNO +' to see if its weights have a TagURL' );
                        if (Invoice.FreightBills[y].ID === Invoice.LineItems[k].FreightID){
                            console.log('for FBNO = ' +Invoice.FreightBills[y].FBNO +' we found the line item = ', Invoice.LineItems[k] );
                            Invoice.FreightBills[y].tagCount=0;
                            if ((Invoice.FreightBills[y].Picture)) {
                                console.log('for FBNO we have a picture baby!! = ' +Invoice.FreightBills[y].FBNO);
                                setPrintFBImages(true);
                                setShowPrintFBImages(true);
                                setPrintFBImages(true);
                            }
                            console.log('AND NOW WE GONNA CHEKC THE WEIGHTS= ' ,Invoice.FreightBills[y].Weights );
                            for (var j = 0; j < Invoice.FreightBills[y].Weights.length; j++){
                                if (Invoice.FreightBills[y].Weights[j].TagUrl) {
                                    Invoice.FreightBills[y].tagCount++;
                                    console.log('setting print tags is true for this weight ',Invoice.FreightBills[y].Weights[j] )
                                    setShowPrintTags(true);
                                    setPrintTags(true);
                                }
                            }
                            printFreightBills.push({...Invoice.FreightBills[y]});
                        }
                    }
                }
            }
        }
    }
    const compressImage = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
       
        const options = {
            maxSizeMB: 1.0, // Maximum file size in MB
            maxWidthOrHeight: 1024, // Resize to this width/height (keeping aspect ratio)
            useWebWorker: true, 
            initialQuality: 0.9,
        };

        const jpegBlob = new Blob([blob], { type: 'image/jpeg' });
        console.log('jpegBlob = ', jpegBlob)
        return await imageCompression( jpegBlob , options);
    };

    const getCompressedBase64 = async (imageUrl) => {
        console.log('getting compressed base64 for imageUrl = ', imageUrl)
        const compressedBlob = await compressImage(imageUrl);
        console.log('compressedBlob = ', compressedBlob)
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(compressedBlob);
        });
    };

    const getWeightAttachments = async (FreightBill, docDefinition) => {
        if (FreightBill.Weights.length === 0) {
            return docDefinition;
        }
        console.log('getting weight attachmenstt for fb =  ',FreightBill)
        const images = [];
        for (const weight of FreightBill.Weights) {
            console.log('weight.TagUrl= ' + weight.TagUrl)
            if (weight.TagUrl) {
                const TagBase64 = await getCompressedBase64(weight.TagUrl);
                images.push({ pageBreak: 'before', width: 520, image: TagBase64 });
            }
        }
        console.log('images = ', images)
        docDefinition.content.push(...images);
        return docDefinition;
    };
    const checkWeightAttachments = (FreightBill) => {
        let tagCount = 0;
        let TagWarningString = "Missing Scale Tag Attachments for Loads: ";
        let totalTagMissingCount = 0;
    
        console.log('Checking weight attachments for driver =', FreightBill.driverName);
        console.log('FreightBill.Weights =', FreightBill.Weights);
    
        FreightBill.Weights.forEach((weight, index) => {
            if (!weight.TagUrl || weight.TagUrl === '') {
                // Update the warning string based on the count of missing tags
                if (tagCount !== 0) TagWarningString += ', ' + weight.loadNumber;
                else  TagWarningString += "(" + weight.loadNumber;
                tagCount++;
                totalTagMissingCount++;
            }
        });
    
        if (tagCount > 0)  TagWarningString += " on " + FreightBill.FBNO + ")";
        
        console.log('TagWarningString =', TagWarningString);
        console.log('Total Missing Tag Count =', totalTagMissingCount);
    
        // Optionally, return the results if needed for further processing
        return { TagWarningString, totalTagMissingCount };
    };
    const getFreightBillAttachment = async (FreightBill, docDefinition) => {
        return new Promise(async (resolve, reject) => {
			console.log('CHECKING FREIGHT BILL ATTACHMENT FOR FB = ', FreightBill)
            if (printFBImages) {
                if (FreightBill.Picture) {
                    try {
                        const FBPic = await getCompressedBase64(FreightBill.Picture);
                        const FreightPicture = { 
                            pageBreak: 'before', 
                            width: 590, 
                            height: 600, 
                            image: FBPic, 
                            alignment: 'center', 
                            margin: [0, 0, 0, 0] 
                        };
                        docDefinition.content.push(FreightPicture);
                        
                        if (printFBs) docDefinition = addFreightToPDF(docDefinition, FreightBill); 
                        if (printTags) resolve(await getWeightAttachments(FreightBill, docDefinition)); 
                        else resolve(docDefinition);
                    } catch (error) {
                        console.error('Error fetching FB picture:', error);
                        reject(error);
                    }
                } else {
                    try {
                        if (printFBs) docDefinition = addFreightToPDF(docDefinition, FreightBill); 
                        console.log('we have gotten past addFreightToPDF and are now heading ot getWeightATtachemtnes');
                        if (printTags) resolve(await getWeightAttachments(FreightBill, docDefinition));
                        else resolve(docDefinition);
                    } catch (error) {
                        reject(error);
                    }
                }
            } else {
                try {
                    if (printFBs) docDefinition = addFreightToPDF(docDefinition, FreightBill);
                    if (printTags) resolve(await getWeightAttachments(FreightBill, docDefinition));
                    else resolve(docDefinition);
                } catch (error) {
                    reject(error);
                }
            }
        });
    };

    const getFreightBillAttachments = async (freightIndex, Invoice,FreightBills, docDefinition ) => {
        return new Promise(async (resolve, reject) => {
            console.log('Invoice= ', Invoice)
            const printFreightBills = FreightBills.filter(item => item.onHold !== true).sort((a, b) => Number(a.FBNO) - Number(b.FBNO));
            const getFreightAttach = async (Freight, freightIndex) => {
                try {
                    docDefinition = await getFreightBillAttachment(Freight, docDefinition, true, true); // Ensure getFreightBillAttachment is defined
                    //console.log('DONE WITH ONE FREIGHT AND ITS INDEX =', freightIndex, 'AND invoice.printFreightBills.length =', Invoice.printFreightBills.length);
                
                    if ((printFreightBills.length - 1) === freightIndex)  resolve(docDefinition);
                    else   resolve(await getFreightBillAttachments(freightIndex + 1, Invoice, FreightBills, docDefinition, true, true));

                } catch (error) {
                    console.error('Error in getFreightAttach:', error);
                    reject(error);
                }
            };
      
            // Initialize or fetch Freight Bills if they are not already present

          if(printFreightBills.length)  await getFreightAttach(printFreightBills[freightIndex], freightIndex); else resolve( docDefinition);
           
        });
    };
    const checkFBAttachments = async (Invoice, FreightBills) => {
        return new Promise(async (resolve, reject) => {
          console.log('Checking the FB attachments');
      
            const finishCheckFreightBills = async (Invoice, FreightBills) => {
                let WarningString = "";
                let FreightWarningString = "";
                let TagWarningString = "Missing Scale Tag Attachments for Loads: ";
                let count = 0;
                let totalTagMissingCount = 0;
        
                for (let i = 0; i < FreightBills.length; i++) {
                    if (printTags)  checkWeightAttachments(FreightBills[i]); // Ensure checkWeightAttachments is defined

                    if ((!FreightBills[i].Picture || FreightBills[i].Picture === '') &&  (!FreightBills[i].PDFurl || FreightBills[i].PDFurl === '')) {
                        if (count !== 0)  FreightWarningString += ', ' + FreightBills[i].FBNO;
                        else  FreightWarningString += FreightBills[i].FBNO;
                        count++;
                    }
                }
        
                if (printFBs && count > 0) {
                    WarningString += "WARNING Missing Attachments for Freight Bills: ";
                    WarningString += FreightWarningString;
                    if (printTags && totalTagMissingCount > 0)  WarningString += "\n Also ";
                    
                }
        
                if (printTags && totalTagMissingCount > 0)  WarningString += TagWarningString;
                WarningString += ". Would you like to create Anyway?";
                console.log('Checked print tags and total tag missing count =', totalTagMissingCount);
        
                if (printTags && totalTagMissingCount > 0) {
                    if (window.confirm(WarningString)) {
                        Invoice.readyToPrint = true;
                        resolve(Invoice.readyToPrint);
                    } else {
                        Invoice.readyToPrint = false;
                        // Replace any popups with proper React-based solutions if needed
                        resolve(Invoice.readyToPrint);
                    }
                } else {
                    Invoice.readyToPrint = true;
                    console.log('Returning readyToPrint =', Invoice.readyToPrint);
                    resolve(Invoice.readyToPrint);
                }
            };
      
            finishCheckFreightBills(Invoice, FreightBills);
        });
    };
    const startCreateInvoices  = async()=>{
        setPrintButtonLabel('Create');
        setPrintFBs(true);
        setPrintAllInvoices(true);
        let invoiceNumberExists = false; 
      
        for( let p=0; p<invoicesRef.current.length; p++){
            let tempInvNumberExists = await checkInvoiceNumber(invoicesRef.current[p]) ;
            invoiceNumberExists = invoiceNumberExists ? invoiceNumberExists :  tempInvNumberExists &&  tempInvNumberExists;
            console.log('setting invoicenumber exists = ' + invoiceNumberExists)
            checkFBsForPrintPopUp(invoicesRef.current[p]);
        }
    
       if(!invoiceNumberExists) setPrintVisible(true)
    }
    const checkInvoices = async () => {
        console.log('invoicesRef.current  and setting is loading to true= ', invoicesRef.current);
        // Create an array of promises for all the checkInvoice calls
        setIsLoading(true);
        setPrintVisible(false);
        const checkFBAttach = async (Invoice,FreightBills) => {
			
			console.log('iinside checkfb attach and setting loadingto ture' );
            Invoice.printFreightBills=[];
            console.log('Invoice.LineItems before inside of hte checking of invoices =', Invoice.LineItems);
            const printFreightBills = FreightBills.filter(item => item.onHold !== true).sort((a, b) => Number(a.FBNO) - Number(b.FBNO));
            Invoice.readyToPrint = await checkFBAttachments(Invoice, printFreightBills);
            console.log('Ready to print =', Invoice.readyToPrint);
      
        };
		setIsLoading(true);
		setPrintVisible(false);
        const printPromises =   invoicesRef.current.map(async (invoice) => { return checkFBAttach(invoice,[...invoice.FreightBills]);   });
        const invoicePromises = invoicesRef.current.map(async (invoice) => { return checkInvoice(invoice);   });
        const printInvoicePromise = printInvoice(invoicesRef.current[0]);
        await Promise.all([...invoicePromises, ...printPromises, printInvoicePromise]);
       
        console.log('we are now done creating and checking all invoices');
 
        setInvoiceVisible(false);
        setIsLoading(false);
        navigate('/invoice/list');
    };
    const checkInvoice = async (Invoice) => {
        let InvoiceWarning = false;
        for (var j = 0; j < Invoice.LineItems.length; j++) {
            if (Invoice.LineItems[j].Total === 0 && !Invoice.LineItems[j].onHold) InvoiceWarning = true;
            if (Invoice.LineItems[j].missing && !Invoice.LineItems[j].onHold) InvoiceWarning = true;
        }
    
        if (InvoiceWarning) {
            if (window.confirm('Warning: You are about to create an Invoice with a line item that is missing or has a total 0, are you sure you want to continue?')) {
                await createInvoice(Invoice); // Ensure createIn voice is async
            }
        }else  await createInvoice(Invoice); // Ensure createIn voice is async
        
    };
    const attachFreightBills = async (Invoice) => {
        return new Promise(async (resolve, reject) => {
            const checkFBAttach = async (Invoice) => {
              //  setIsLoading(true);
                Invoice.printFreightBills=[];
                console.log('Invoice.LineItems before =', Invoice.LineItems);
                const printFreightBills = Invoice.FreightBills.filter(item => item.onHold !== true).sort((a, b) => Number(a.FBNO) - Number(b.FBNO));
                Invoice.readyToPrint = await checkFBAttachments(Invoice, printFreightBills);
        
                console.log('Ready to print =', Invoice.readyToPrint);
               	if(Invoice.readyToPrint) printInvoice(Invoice); else   setIsLoading(false);
               	setPrintVisible(false);
            };
			 
            if(printButtonLabel==='Create')checkInvoices(); else  checkFBAttach(Invoice);
          
        });
    };

 
    const printInvoice =(Invoice)=>{


        let CompanyHeader = Invoice.Company.Address + ', ' + Invoice.Company.address2 + '\n' + 'Office: ' + Invoice.Company.CompanyPhone + '   Fax: ' + Invoice.Company.Fax;
        let termsAndCond ='';
        if (Invoice.Account.InvoiceNotes === '') termsAndCond = Invoice.Company.TermsAndCond; else termsAndCond = Invoice.Account.InvoiceNotes;

     

        let docDefinition = {
            compress:true,
            content: [],
            pageMargins: [40, 85, 40, 115],
            pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
                return currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0;
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5]
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                lineItemTable: {
                    margin: [0, 10, 0, 15]
                },
                headerTable: {
                    margin: [0, 0, 0, -10],
                    border:[true,true,true,false]
                },
                tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black'
                }

            },
            footer: function (currentPage, pageCount) {
                return [
                    {
                        alignment: 'center',
                        fontSize: 9,
                        margin: [150, 0, 150, 0],
                        text: termsAndCond
                    }, {
                        alignment: 'center',
                        margin: [50, 0, 50, 0],
                        text: 'Page ' + currentPage.toString() + ' of ' + pageCount
                    }
                ]
            }
        };

        let headerColumns =[];
        headerColumns.push({ width: 50, height: 50, text: '' });
        headerColumns.push([{ width: 200, text: Invoice.Company.CompanyName, bold: true, alignment: 'center', fontSize: 18, margin: [0, 10, 0, 0] }, { text: CompanyHeader, margin: [0, 0, 0, 0], bold: true, alignment: 'center', fontSize: 11 }]);
        headerColumns.push({ width: 100, text: '' });
        docDefinition.header = [{ columns: headerColumns }];

        console.log('invoice we are tryign to rpiont ', invoicesRef.current[0])
        if(printAllInvoices)addInvoicetoPDF(invoicesRef.current[0], 0, invoicesRef.current[0].FreightBills,docDefinition)
        else addInvoicetoPDF(Invoice, 0,Invoice.FreightBills, docDefinition)
    }

	const createSeparateStandByLineItem = (lineItem, lineItemRow, lineItemRowTable, Invoice) => {
		let border = [true, true, true, true];

		var loadStandByLineItem = {...lineItemRow};
		if (Invoice.calcByLoad) loadStandByLineItem.push({ colSpan: 2, text: lineItem.Description }, { text: "" });
		loadStandByLineItem.push({ colSpan: 2, fontSize: 10, text: 'Stand By - Load' }, { text: "" });
		loadStandByLineItem.push({ colSpan: 2, alignment: 'right', text: Number(lineItem.loadQty).toString() }, { text: "" });
		loadStandByLineItem.push({ colSpan: 2, alignment: 'right', text: lineItem.RateString }, { text: "" });
		loadStandByLineItem.push({ colSpan: 2, alignment: 'right', text: '$' + Number(lineItem.loadTotal).formatMoney(2) }, { text: "" });
		if ( lineItem.dumpTotal > 0) border = [true, false, true, false]; else border = [true, false, true, true];
		for (var q = 0; q < loadStandByLineItem.length; q++) loadStandByLineItem[q].border = border;
		if (lineItem.loadTotal > 0) lineItemRowTable.body.push(loadStandByLineItem);

		lineItemRow.push({ colSpan: 2, text: lineItem.Description }, { text: "" });
		lineItemRow.push({ colSpan: 2, fontSize: 10, text: 'Stand By - Dump' }, { text: "" });
		lineItemRow.push({ colSpan: 2, alignment: 'right', text: Number(lineItem.dumpQty).toString() }, { text: "" });
		lineItemRow.push({ colSpan: 2, alignment: 'right', text: lineItem.RateString }, { text: "" });
		lineItemRow.push({ colSpan: 2, alignment: 'right', text: '$' + Number(lineItem.dumpTotal).formatMoney(2) }, { text: "" });

	}

	const addLineItemsToPDF = (Invoice, lineItemTableBody, processedItems) => {
		// Group items by FBNO to ensure they go in the same nested table
		const groupedByFBNO = {};	
		processedItems.forEach(item => {
			
			if (item.tableLength > 0) {
				if (!groupedByFBNO[item.FBNO]) {
					groupedByFBNO[item.FBNO] = {
						items: [],
						table: {
							headerRows: 1,
							dontBreakRows: true,
							alignment: 'center',
							keepWithHeaderRows: item.tableLength,
							widths: Invoice.calcByLoad 
								? [30, 25, 25, 25, 20, 15, 35, 30, 20, 20, 15, 15, 30, 25, 35, 35]
								: [30, 25, 25, 25, 20, 15, 35, 30, 15, 15, 30, 25, 35, 35],
							body: []
						}
					};
				}
				groupedByFBNO[item.FBNO].items.push(item);
			} else if (groupedByFBNO[item.FBNO]) groupedByFBNO[item.FBNO].items.push(item);
			else console.warn('Found item with tableLength=0 but no parent table', item);	
		});
		
		// Process each group
		Object.values(groupedByFBNO).forEach(group => {
			group.items.forEach((item, index) => {
				const isLastItem = index === group.items.length - 1;
				const border = [true, item.tableLength !== 0, true, isLastItem];
				let lineItemRow = createLineItemRow(Invoice, item, border);
				group.table.body.push(lineItemRow);
			});
			
			// Create the row object that contains the nested table
			const border = [true, true, true, true];
			const lineItemRowObject = Invoice.calcByLoad
				? [{ margin: [-5, -3, -5, -3], colSpan: 16, border, table: group.table }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
				: [{ margin: [-5, -3, -5, -3], colSpan: 14, border, table: group.table }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
				
			lineItemTableBody.push(lineItemRowObject);
		});
		console.log('lineItemTableBody = ', lineItemTableBody)
		return lineItemTableBody;
	}

	// Helper functions
	function formatQtyString(lineItem) {
		if(!lineItem.Qty)return '';
		if (lineItem.RateType === 'Ton') return Number(lineItem.Qty).formatMoney(2);
		else if (lineItem.RateType === 'Hour') return Number(lineItem.Qty).formatMoney(1);
		else return Number(lineItem.Qty).toString();
	}

	// Helper function to create a line item row with proper borders
	function createLineItemRow(Invoice, lineItem, border) {
		const qtyString = formatQtyString(lineItem);
		let lineItemRow = [];
	
		// Helper function to add a cell with colspan and appropriate empty cells
		function addCellWithColSpan(text, colSpan, alignment = null) {
			const cellProps = { colSpan: colSpan, text: text,border: border 	};
			if (alignment) cellProps.alignment = alignment;
			lineItemRow.push(cellProps);
			for (let i = 1; i < colSpan; i++) lineItemRow.push({ text: "", border });
			
		}
		
		// Add the first cells based on whether it's a parent or child item
		if (lineItem.Type === 'Scale Tag' && lineItem.tableLength === 0) {
			addCellWithColSpan('', 2);
			addCellWithColSpan('', 2);
			addCellWithColSpan('', 2);
		} else if(Invoice.isFloatingExpenseInvoice){
			addCellWithColSpan(lineItem.tableLength === 0 ? "" : lineItem.JobDate, 3);
		} else {
			addCellWithColSpan(lineItem.tableLength === 0 ? "" : lineItem.JobDate, 2);
			addCellWithColSpan(lineItem.tableLength === 0 ? "" : lineItem.FBNO, 2);
			addCellWithColSpan(lineItem.tableLength === 0 ? "" : lineItem.truck, 2);
		}
		
		// Add Description column if calcByLoad is true
		if (Invoice.calcByLoad) addCellWithColSpan(lineItem.Description, 2);
		
		
		// Determine the colSpan for RateType
		const rateTypeColSpan = Invoice.isFloatingExpenseInvoice ? 5 : 2;
		
		// Add the remaining cells
		addCellWithColSpan(lineItem.RateType, rateTypeColSpan);
		addCellWithColSpan(qtyString, 2, 'right');
		console.log('lineItem.Rate = ', lineItem.Rate)
		addCellWithColSpan(lineItem.Rate ? ('$' + lineItem.Rate.formatMoney(2)) : '', 2, 'right');
		addCellWithColSpan(lineItem.Total ? ('$' + lineItem.Total.formatMoney(2)) : '', 2, 'right');
		
		return lineItemRow;
	}
	const createLineItemHeader = (Invoice, lineItemTableBody) => {
		
		let columns = []; 
		function addCellWithColSpan(text, colSpan, alignment = null) {
			const cellProps = { colSpan: colSpan, text: text	};
			if (alignment) cellProps.alignment = alignment;
			columns.push(cellProps);
			for (let i = 1; i < colSpan; i++) columns.push({});
			
		}
		
		if(!Invoice.isFloatingExpenseInvoice){
			addCellWithColSpan('Job Date', 2);
			addCellWithColSpan('FB #', 2);
			addCellWithColSpan('Truck', 2);
			if (Invoice.calcByLoad) addCellWithColSpan('Tickets',2)
			addCellWithColSpan('Bill Type', 2);

		}else{
			addCellWithColSpan('Date', 3);
			addCellWithColSpan('Description', 5);
		} 
		
		addCellWithColSpan('Qty', 2);
		addCellWithColSpan('Bill Rate', 2);
		addCellWithColSpan('Total', 2);
	
		
		lineItemTableBody.push(columns);
		return lineItemTableBody;
	}
	const createLocationHeader = (Invoice) => {
		let headerTableBody =[];

	
		let tableHeaderRow = [{ colSpan: 7, text: [{ bold: true, text: 'Load Site: ' }, Invoice.LoadSite.Name] }, {}, {}, {}, {}, {}, {}];
			if (Invoice.calcByLoad) {
				tableHeaderRow[0].colSpan += 1;
				tableHeaderRow.push({});
			}

			tableHeaderRow.push({ colSpan: 7, text: [{ bold: true, text: 'Dump Site: ' },  Invoice.DumpSite.Name] }, {}, {}, {}, {}, {}, {});
			if (Invoice.calcByLoad) {
				tableHeaderRow[8].colSpan += 1;
				tableHeaderRow.push({});
			}

			headerTableBody.push(tableHeaderRow);
			tableHeaderRow = [{ colSpan: 7, rowSpan: 2, border:[true,true,true,false], text: [{ bold: true, text: 'Address: ' }, Invoice.LoadSite.fullAddress] }, {}, {}, {}, {}, {}, {}];

			if (Invoice.calcByLoad) {
				tableHeaderRow[0].colSpan += 1;
				tableHeaderRow.push({});
			}

			tableHeaderRow.push({ colSpan: 7, rowSpan: 2, border:[true,true,true,false], text: [{ bold: true, text: 'Address: '},  Invoice.DumpSite.fullAddress] }, {}, {}, {}, {}, {}, {});

			if (Invoice.calcByLoad) {
				tableHeaderRow[8].colSpan += 1;
				tableHeaderRow.push({});
			}

			headerTableBody.push(tableHeaderRow);
		
		tableHeaderRow = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
		if (Invoice.calcByLoad) {
			tableHeaderRow.push({});
			tableHeaderRow.push({});
		}

		headerTableBody.push(tableHeaderRow);
		return headerTableBody;
	}

    const addInvoicetoPDF = async (Invoice, InvoiceIndex,FreightBills, docDefinition) => {
        let separateStandBy = false;
        let pageBreak = false;
        var lineItemTableBody = [];
   
        if(InvoiceIndex!=0)   pageBreak = true;
            
        console.log('invoice.company = ' ,Invoice.Company);
              

            let InvoiceDateRow = [{ text: [{ bold: true, text: 'Date: ' }, Invoice.InvoiceDate] }];
            let JobRow = [{ text: [{ bold: true, text: 'Job #: ' }, Invoice.JobNumber] }];
            var InvoiceNumber = Invoice.InvoiceNumber;
            let InvoiceNumRow = [{ text: [{ bold: true, text: 'Invoice #: ' }, InvoiceNumber] }];
            let PORow={};
            if (Invoice.PONumber != '' && Invoice.PONumber) PORow = [{ text: [{ bold: true, text: 'PO #: ' }, Invoice.PONumber] }]
            
            
            const filteredLineItems= Invoice.LineItems.filter(item => item.onHold !== true).sort((a, b) => {
                // Primary sorting by type to ensure 'Freight Bill' is first
                // Secondary sorting by FBNO
                if (Number(a.FBNO) < Number(b.FBNO)) return -1;
                if (Number(a.FBNO) > Number(b.FBNO)) return 1;
                if (a.Type === 'Freight Bill' && b.Type !== 'Freight Bill') return -1;
                if (a.Type !== 'Freight Bill' && b.Type === 'Freight Bill') return 1;
                
                if (a.Type === 'Scale Tag' && b.Type !== 'Scale Tag') return -1;
                if (a.Type !== 'Scale Tag' && b.Type === 'Scale Tag') return 1;
                if (a.Type === 'Material' && b.Type !== 'Material') return -1;
                if (a.Type !== 'Material' && b.Type === 'Material') return 1;
                if (a.Type === 'Stand By' && b.Type !== 'Stand By') return -1;
                if (a.Type !== 'Stand By' && b.Type === 'Stand By') return 1;
                if (a.Type === 'Expense' && b.Type !== 'Expense') return -1;
                if (a.Type !== 'Expense' && b.Type === 'Expense') return 1;
                
            });


            console.log('filteredLineItems = ',filteredLineItems);
            
            if (filteredLineItems.length > 0) {
                const processedItems = []; // Array to store the processed line items
                const fbnoSeen = new Set(); // Track FBNOs already processed
				for (var i = 0; i < filteredLineItems.length; i++) {
					if(!Invoice.isFloatingExpenseInvoice) {
						const currentFBNO = filteredLineItems[i].FBNO;
						if (!fbnoSeen.has(currentFBNO) ) {
							fbnoSeen.add(currentFBNO);
							const childCount = filteredLineItems.slice(i + 1).filter(item => item.FBNO === currentFBNO).length;
							processedItems.push({...filteredLineItems[i],tableLength: childCount+1, });
						} else processedItems.push({ ...filteredLineItems[i], tableLength: 0, });
					} else processedItems.push({ ...filteredLineItems[i], tableLength: 1, });
                }

                console.log('processedItems = ' , processedItems)


				lineItemTableBody= createLineItemHeader(Invoice, lineItemTableBody);
				lineItemTableBody=	addLineItemsToPDF(Invoice, lineItemTableBody, processedItems);
           
              
            }
         
            let totalsTable = [];
            let totalsHeader = [
				{ bold: true, text: 'Type' }, 
				{ bold: true, text: 'Qty' },
				{ bold: true, text: 'Total' }
			];

            totalsTable.push(totalsHeader);
            
            let InvoiceTotalRow={};

            for (var k = 0; k < Invoice.Totals.length; k++) {

                console.log('Invoice.Totals[k].Type = ' + Invoice.Totals[k].Type);
            
                if (Invoice.Totals[k].Type === 'Invoice Total' || Invoice.Totals[k].Type === 'Amount Due') {
                    InvoiceTotalRow = [{ colSpan: 2, bold: true, text: 'Total ' }, {}, { alignment: 'right', text: '$' + Number(Invoice.Totals[k].Total).formatMoney(2) }];
                } else {
                  
                    if (Number(Invoice.Totals[k].Total) != 0) {
                        var totalsTableRow = [{ text: Invoice.Totals[k].Type }];
                        var QtyString;
                        if (Invoice.Totals[k].Type === 'Broker' || Invoice.Totals[k].Type === 'Fuel' || Invoice.Totals[k].Type === 'Tax' ) QtyString = Invoice.Totals[k].Qty + '%';
                        if (Invoice.Totals[k].Type === 'Loads') QtyString = Number(Invoice.Totals[k].Qty).formatMoney(0);
                        if (Invoice.Totals[k].Type === 'Stand By') {
                            if (separateStandBy) {
                                totalsTableRow = [{ text: 'Load - Stand By' }];
                                QtyString = Number(Invoice.Totals[k].loadQty).formatMoney(0);
                                totalsTableRow.push({ alignment: 'right', text: QtyString });
                                totalsTableRow.push({ alignment: 'right', text: '$' + Number(Invoice.Totals[k].loadTotal).formatMoney(2) });
                                if (Number(Invoice.Totals[k].loadTotal) > 0) totalsTable.push(totalsTableRow);
                                totalsTableRow = [{ text: 'Dump - Stand By' }];
                                QtyString = Number(Invoice.Totals[k].dumpQty).formatMoney(0);
                            } else QtyString = Number(Invoice.Totals[k].Qty).formatMoney(0);
                        }
                
                        if (Invoice.Totals[k].Type === 'Expenses') QtyString = Number(Invoice.Totals[k].Qty).formatMoney(2);
                        if (Invoice.Totals[k].Type === 'Tons') QtyString = Number(Invoice.Totals[k].Qty).formatMoney(2);
                        if (Invoice.Totals[k].Type === 'Hours') QtyString = Number(Invoice.Totals[k].Qty).formatMoney(1);
                        if (Invoice.Totals[k].Type === 'Amount Due') QtyString = Invoice.Totals[k].Qty;
                        if (Invoice.Totals[k].Type === 'Material') QtyString = Number(Invoice.Totals[k].Qty).formatMoney(1);
                        totalsTableRow.push({ alignment: 'right', text: QtyString });
                        if (separateStandBy && Invoice.Totals[k].Type === 'Stand By') totalsTableRow.push({ alignment: 'right', text: '$' + Number(Invoice.Totals[k].dumpTotal).formatMoney(2) });
                        else totalsTableRow.push({ alignment: 'right', text: '$' + Number(Invoice.Totals[k].Total).formatMoney(2) });
                        if (separateStandBy) {
                            if (Invoice.Totals[k].Type === 'Stand By') {
                                if (Number(Invoice.Totals[k].dumpTotal) > 0) totalsTable.push(totalsTableRow);
                            } else totalsTable.push(totalsTableRow);
                        } else totalsTable.push(totalsTableRow);
                        console.log('totalsTableRow = ', totalsTableRow);
                    }
                }
                if (k === (Invoice.Totals.length - 1)) totalsTable.push(InvoiceTotalRow);
            }

            console.log('totalsTable = ', totalsTable);
            if (Invoice.Totals.length === 0) totalsTable.push(InvoiceTotalRow);


            let JobandPONumber = [];
            JobandPONumber.push(InvoiceNumRow);
            JobandPONumber.push(InvoiceDateRow);
            JobandPONumber.push(JobRow);
            if (Invoice.PONumber != '' && Invoice.PONumber) JobandPONumber.push(PORow);

            let InvoicePDFAccount = { 
                columns: [
                    { width: 25, text: '' },

                    {
						width: 200, 
						margin: [0, 57, 0, 27], 
						text: [
							{ fontSize: 15, bold: true, text: Invoice.Account.Name },
							{ fontSize: 12, bold: true, text: '\n ' + Invoice.Account.Address + '\n ' + Invoice.Account.custAddress2 }
						] 
					},
                    { width: 140, text: '' },

                    { 
						width: 120, 
						margin: [0, -20, 0, 0], 
						table: { 
							alignment: 'right', style: 'dateTableHeader', 
							widths: ['*'], 
							body: JobandPONumber 
						} 
					}
                ]
            };

            if (pageBreak) InvoicePDFAccount.pageBreak = 'before';
            docDefinition.content.push(InvoicePDFAccount);

            
			if(!Invoice.isFloatingExpenseInvoice){
				var headerTable = {
					dontBreakRows: true,
					alignment: 'center',
					widths: [30, 25, 25, 25, 20, 15, 35, 30, 15, 15, 30, 25, 35, 35],
					body: createLocationHeader(Invoice)
				};
				
				if (Invoice.calcByLoad) headerTable.widths = [30, 25, 25, 25, 20, 15, 35, 30, 20, 20, 15, 15, 30, 25, 35, 35];

				console.log('headerTable =  = ', headerTable)
				docDefinition.content.push({ style: 'headerTable', table:  headerTable});
			}
        
            let lineItemTable = {
                headerRows:1,
                alignment: 'center',
                dontBreakRows:true,
                widths: [30, 25, 25, 25, 20, 15, 35, 30, 15, 15, 30, 25, 35, 35],
                body: lineItemTableBody
            };
            if (Invoice.calcByLoad) lineItemTable.widths = [30, 25, 25, 25, 20, 15, 35, 30, 20, 20, 15, 15, 30, 25, 35, 35];
        
      
            docDefinition.content.push({ style: 'lineItemTable', table: lineItemTable });
        
            var firstColumnOffset = 266;
            if (Invoice.calcByLoad) firstColumnOffset = 325;
            let InvoicePDFTotals = {
                columns: [
                    { width: firstColumnOffset, text: [{ bold: true, text: 'Notes: ' }, Invoice.CustomNotes], alignment: 'center' },
                    { width: 220, table: { headerRows: 1, keepWithHeaderRows: 10, dontBreakRows: true, widths: ['*', '*', 78], body: totalsTable } }
                ]
            };
            if (Invoice.Payments) {
                if (Invoice.Payments.length > 0) {
                    var paymentsTable = [];
                    var paymentsHeader = [{ bold: true, text: 'Date' }, { bold: true, text: 'Amount' }];
                    paymentsTable.push(paymentsHeader);
                    for (var j = 0; j < Invoice.Payments.length; j++) {
                        var paymentRow = [{ text: Invoice.Payments[j].formattedDate }, { alignment: 'right', text: Invoice.Payments[j].displayAmount }];
                        paymentsTable.push(paymentRow);
                    }
                    var paymentRow = [{ text: 'Amount Due', bold: true }, { alignment: 'right', text: '$' + Number(Invoice.Balance.Total).formatMoney(2) }];

                    paymentsTable.push(paymentRow);
                    var middleWidthOffset = firstColumnOffset - 180;
                    InvoicePDFTotals.columns = [
                        { width: 180, table: { headerRows: 1, keepWithHeaderRows: 10, dontBreakRows: true, widths: ['*', '*',], body: paymentsTable } },
                        { width: middleWidthOffset, text: '' },
                        { width: 215, table: { headerRows: 1, keepWithHeaderRows: 10, dontBreakRows: true, widths: ['*', '*', 78], body: totalsTable } }
                    ]
                }
            }
            //  if (Invoice.InvoiceNotes != '') InvoicePDFTotals.columns[0].text = [{ bold: true, text: 'Notes: ' }, Invoice.InvoiceNotes];
            docDefinition.content.push(InvoicePDFTotals);
            console.log('docdefinition look like dis = ' , docDefinition);
       
            if (printFBs || printTags || printFBImages){
                console.log('ABOUT OT URN GET FRIEGHT ATTACHMENTS')
                docDefinition = await getFreightBillAttachments(0, Invoice, FreightBills, docDefinition, true, true);
                if (printAllInvoices){
                    if((invoicesRef.current.length - 1) === InvoiceIndex) {
                      //  setIsLoading(false);
                        return pdfMake.createPdf(docDefinition).download();
                    }  else  return addInvoicetoPDF(invoicesRef.current[InvoiceIndex+1], InvoiceIndex+1,invoicesRef.current[InvoiceIndex+1].FreightBills, docDefinition);
                }  else{
                   // setIsLoading(false);
                    return pdfMake.createPdf(docDefinition).download();
                } 
              
   
            }else {
               if (printAllInvoices){
                    if((invoicesRef.current.length - 1) === InvoiceIndex) {
                     //   setIsLoading(false);
                        return pdfMake.createPdf(docDefinition).download();
                    }  else  return addInvoicetoPDF(invoicesRef.current[InvoiceIndex+1], InvoiceIndex+1,invoicesRef.current[InvoiceIndex+1].FreightBills, docDefinition);
                } else{
                  //  setIsLoading(false);
                    return pdfMake.createPdf(docDefinition).download();
                } 
                 
            }

    }
   
    const addFreightToPDF =(docDefinition, FreightBill)=>{
      
        var truckname, trailername;
   
            
      
            if(FreightBill.Trailer){if(FreightBill.Trailer.Name==='No Trailer') trailername=''; else trailername=FreightBill.Trailer.Name;}else trailername='';
            if(FreightBill.Truck) {if(FreightBill.Truck.Name==='No Truck')  truckname=''; else truckname= FreightBill.Truck.Name;} else  truckname=''; 
            var haulerText= [{ bold:true, text:'Driver Name: '  }, FreightBill.driverName];
            if(FreightBill.Subhauler)  haulerText= [{ bold:true, text:'Sub-Hauler: ' },FreightBill.haulerName]; 
            let firstRow=[{colSpan: 2, rowSpan:2, fontSize: 15, text:haulerText}, {}, {  text: [{bold: true,text: 'FB #: '}, FreightBill.FBNO]}];
           
            let secondRow=[  {}, {}, { text:[{bold:true,text: 'Job #: ' }, FreightBill.jobNO]} ]
            let thirdRow=[{ text:[{bold:true,text:'Rate Type: '}, FreightBill.PayType]}, { text:[{bold:true,text: 'Number of Loads: '}, FreightBill.loads]}, { text:[{bold:true,text:'Date: '}, FreightBill.JobDate]}];
            let shipperAndReceiverRow=[
                {fontSize: 15, text:[{bold:true,text:'Shipper: ' }, FreightBill.Shipper.Name]},
                {fontSize: 15, text:[{bold:true, text:'Receiver: ' }, FreightBill.Receiver.Name]}
            ];
      
            let fourthRow=[ {text:[{bold:true, text:'Material: '}, FreightBill.Material.Name]},  {text:[{bold:true, text:'TruckType: ' }, FreightBill.TruckType.Name]},   {text: [{bold: true,text: 'Truck: '}, truckname+'  ',{bold: true,text: 'Trailer: '}, trailername]}];
            let sixthRow=[{text:[{bold:true, text:'Origin: '}, FreightBill.LoadSite.Name]}, {text:[{bold:true, text:'Destination: ' }, FreightBill.DumpSite.Name]}];
            let seventhRow=[{text:[{bold:true, text:'Address: ' }, FreightBill.LoadSite.fullAddress]}, {text:[{bold:true, text:'Address: ' }, FreightBill.DumpSite.fullAddress]}];
            
            let weightTable=[];
            let expenseTable=[];
            let weightTableHeaders=[
                {colSpan: 3, text: 'Weights', fontSize:20, style: 'tableHeader', alignment: 'center'},
                {},
                {},
                {colSpan: 3, text: 'Load', fontSize:20, style: 'tableHeader', alignment: 'center'},
                {},
                {},
                {colSpan: 3, text: 'Dump', fontSize:20, style: 'tableHeader', alignment: 'center'},
                {},
                {}
            ];
            let weightTableHeaders2=[
                {text: 'Material', style: 'tableHeader', alignment: 'center'},
                {text: 'Scale Tag', style: 'tableHeader', alignment: 'center'},
                {text: 'Weight', style: 'tableHeader', alignment: 'center'},
                {text:'Arrive', style: 'tableHeader', alignment: 'center'},
                {text: 'Depart', style: 'tableHeader', alignment: 'center'},
                {text: 'Stand By', style: 'tableHeader', alignment: 'center'},
                {text: 'Arrive', style: 'tableHeader', alignment: 'center'},
                {text: 'Depart', style: 'tableHeader', alignment: 'center'},
                {text: 'Stand By', style: 'tableHeader', alignment: 'center'}
            ];
            let expenseTableHeaders=[
                {text: 'Description', style: 'tableHeader', alignment: 'center'},
                {text: 'Qty', style: 'tableHeader', alignment: 'center'},
                {text: 'Rate', style: 'tableHeader', alignment: 'center'},
                {text: 'Total', style: 'tableHeader', alignment: 'center'}
                
            ];
        
            console.log('FreightBill.approveShipperSignature = ', FreightBill.approveShipperSignature)
            var driverSignatureImage={ width:250,text: '',  height:50}; 
            var approveSignatureImage={ width:250,text: '',  height:50};
            
            if(FreightBill.driverSignature) driverSignatureImage={ width:250,image: FreightBill.driverSignature, height:50};

           if(FreightBill.approveShipperSignature) approveSignatureImage={ width:250,image: FreightBill.approveShipperSignature, height:50};
           else if(FreightBill.approveReceiverSignature)approveSignatureImage={ width:250,image: FreightBill.approveReceiverSignature, height:50};

            weightTable.push(weightTableHeaders);
            weightTable.push(weightTableHeaders2);
            expenseTable.push(expenseTableHeaders);
            
            let weightTableWidths=['*',55,55,45,45,40,45,45,40];
            let expenseTableWidths=['*','*','*','*'];
            for(var i=0; i<FreightBill.Weights.length; i++){
                var materialName='';
              
                if(FreightBill.Weights[i].Material){
                  if(FreightBill.Weights[i].Material.Name) materialName=FreightBill.Weights[i].Material.Name;
                  else materialName=FreightBill.Weights[i].Material;
                } 
        
                let weightTableRow=[
                    {text: materialName, alignment:'center' },
                    {text: FreightBill.Weights[i].tagNO, alignment:'center' },
                    {text: FreightBill.Weights[i].weight, alignment:'right' },
                    {text: FreightBill.Weights[i].loadStart, alignment:'center' },
                    {text: FreightBill.Weights[i].loadEnd, alignment:'center' },
                    {text: FreightBill.Weights[i].excessLoad, alignment:'right' },
                    {text: FreightBill.Weights[i].dumpStart, alignment:'center' },
                    {text: FreightBill.Weights[i].dumpEnd, alignment:'center' },
                    {text: FreightBill.Weights[i].excessDump, alignment:'right' }
                ];
                weightTable.push(weightTableRow);
            }
            
            let totalWeightRow=[
                {colSpan: 2,bold:true, text: 'Total Weight:',  alignment: 'right'},
                {},
                {text:FreightBill.tWeight,alignment: 'right'},
                {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
                {},
                {text: FreightBill.totalExcessLoad,alignment: 'right'},
                {colSpan: 2, bold:true, text: 'Total:', alignment: 'right'},
                {},
                {text: FreightBill.totalExcessDump,alignment: 'right'}
            ];
            weightTable.push(totalWeightRow);
            console.log('weightTable = ', weightTable);
            
            let expenseRowCount=0;
            for(var j=0; j<FreightBill.Expenses.length; j++){
                let expenseTableRow=[
                    FreightBill.Expenses[j].Name.Name,
                    FreightBill.Expenses[j].qty,
                    FreightBill.Expenses[j].rate,
                    FreightBill.Expenses[j].total
                ];
            if(FreightBill.Expenses[j].Name!=='Stand By'){
                expenseRowCount++;
                expenseTable.push(expenseTableRow);
            } 
            }
            console.log('expenseTable = ',expenseTable);
  
            let timeRows=[[{text:[{bold:true, text:'Start Time: '},FreightBill.startTime]},{text:[{bold:true, text:'End Time: ' }, FreightBill.endTime]}, {text:[{bold:true, text:'Total Hours: ' }, FreightBill.grossHours]}, {text:[{bold:true, text:'Deduction: ' }, FreightBill.lunch]}]];
            var timeRow2=[{},{}, {},{text:[{bold:true, text:'Hours: '}, FreightBill.tHours]}  ];
            if(FreightBill.PayType==='Hour' || FreightBill.PayType==='Hour/Percent') timeRow2=[{text:[{bold:true, text:'Depart Load: '},FreightBill.departRoundTrip]},{text:[{bold:true, text:'Arrive Dump: ' }, FreightBill.arriveRoundTrip]}, {text:[{bold:true, text:'Running Time: ' }, FreightBill.runningTime]},{text:[{bold:true, text:'Hours: '}, FreightBill.tHours]}  ];
            timeRows.push(timeRow2);          
        
            // docDefinition.content.push(FreightBillHeader2);
            
            let freightBillInfo= {
                pageBreak:'before',
                style: 'tableExample',
                table: { widths: ['*',  '*', '*'], body: [firstRow,secondRow,thirdRow,fourthRow]}
            };
            docDefinition.content.push(freightBillInfo);
            
            let freightBillShipperAndReceiver={
                style: 'tableExample',
                table: { widths: ['*',  '*'], body: [shipperAndReceiverRow,sixthRow,seventhRow ]}
            };
            docDefinition.content.push(freightBillShipperAndReceiver);
            
            let freightBillWeightTable={
                style: 'tableExample',
                table: {widths: weightTableWidths, body: weightTable } 
            }
            docDefinition.content.push( freightBillWeightTable);        
            let freightBillExpenseTable={
                style: 'tableExample',
                table: {widths: expenseTableWidths, body: expenseTable } 
            }
            if(expenseRowCount>0)docDefinition.content.push(freightBillExpenseTable);        
                    
            let freightBillTimes={
                style: 'tableExample',
                table: {widths: ['*',  '*', '*', '*' ], body: timeRows } 
            }
            docDefinition.content.push(freightBillTimes);        
                    
            let freightBillSignatureLabels={
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{ text: [{ bold: true, text: 'Driver Name: ' }, FreightBill.driverName] }, { text: [{ bold: true, text: 'Consignor Name: ' }, FreightBill.signatureName] }],
                        [{ border: [false, false,false,false],  bold: true, text: 'Driver Signature:' }, { border: [false, false,false,false], bold: true, text: 'Consignor Signature:' }]
                    ]
                }
            }
            docDefinition.content.push(freightBillSignatureLabels);        
                    
            let freightBillSignatures={
                columns: [
                    { width: 5, text: '' },
                    driverSignatureImage,
                    { width: 20, text: '' },
                    approveSignatureImage
                ], 
            }
           docDefinition.content.push(freightBillSignatures);  
            if(!FreightBill.approverComments)FreightBill.approverComments='';
            
            let freightBillComments={
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{text:[{bold:true, text:'Driver Comments: '},   FreightBill.Comments ], alignment:'center' },
                        {text:[{bold:true, text:'Approver Comments: '},   FreightBill.approverComments ], alignment:'center' }]
                    ]
                }
            }
            
            docDefinition.content.push(freightBillComments);
           
            console.log('docDefinition = ' , docDefinition);
      
           return(docDefinition);
      
          //  savePDF(action); 
      
    }

    return (
        <PrintContext.Provider value={{
            printVisible,
            setPrintVisible,
            showPrintTags,
            setShowPrintTags,
            printTags,
            setPrintTags,
            showPrintFBImages,
            setShowPrintFBImages,
            printButtonLabel,
            setPrintButtonLabel,
            showPrintPopUp,
            closePrintPopUp,
            printFBs,
            setPrintFBs,
            printFBImages,
            setPrintFBImages,
            printInvoice,
            getFreightBillAttachments,
            addFreightToPDF,
            attachFreightBills,
            startCreateInvoices,
        }}>
            {children}
        </PrintContext.Provider>
    );
};

export const usePrint = () => useContext(PrintContext); 