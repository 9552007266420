import React, {useEffect, useState} from 'react';

import { InputNumber } from 'primereact/inputnumber';
import InputTextParent from '../InputComponents/InputTextParent'; 

import { Calendar } from 'primereact/calendar';
import  { Button} from 'primereact/button';
import {Textarea} from '@mobiscroll/react';

import { useInvoice } from './context/InvoiceContext';
import { UserAuth } from '../../context/AuthContext'
import { useGlobal } from '../../context/GlobalContext';
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import LineItemList from './LineItemComponents/LineItemList';
import InvoiceHomeLineItemList from './LineItemComponents/InvoiceHomeLineItemList';
import TotalItemList from '../ListComponents/TotalItemList';
import PaymentItemList from '../ListComponents/PaymentItemList';

import IIFDownloadComponent from '../MiscComponents/IIFDownloadComponent';

import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import { usePrint } from './context/PrintContext';
import { useInvoiceCreate } from './context/InvoiceCreateContext';

import { useLineItem} from './hooks/useLineItem';
import { useInvoiceCalc } from './hooks/useInvoiceCalc';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';


const InvoiceComponent = (props) => {
 
    const {companies, company, updateDocument, formatDate, accounts, deleteDocument, truckTypes,gearedUser} = UserAuth();
	const {setExpensePopUpVisible,setExpense } = useGlobal();
    const navigate = useNavigate()
    const { invoice, setInvoice,  setInvoices,  invoiceRef, invoicesRef,
     invoiceExpenseListenersRef,  isLoading,showPrintFBImages,  } = useInvoice();
	const { calcInvoiceTotal } = useInvoiceCalc();
    const {  showPrintPopUp } = usePrint();
	const { openInvoiceExpenseListener } = useInvoiceCreate();
    const { createEmptyLineItem, makeFreightBillLineItem, makeExpenseLineItem, getColumnConfig } = useLineItem();

    
    const [currentToggleValue, setCurrentToggleValue]=useState(true);
    const [inputValues, setInputValues] = useState({});
		console.log('invoicesRef.current on component rerender = '+ invoicesRef.current[0]?.LineItems.length)
		console.log('inputValues.ID = '+	 inputValues.ID)
    const invoiceIndex =  invoicesRef.current.findIndex(obj => obj.ID === inputValues.ID);
	console.log('invoiceIndex = ', invoiceIndex)
    const scaleTagButton = inputValues.calcByLoad ? 'Invoice By Full Weight' : 'Invoice By Scale Tag';
 
    

    let totalWidth = 10;
    totalWidth = !inputValues.calcByLoad ? totalWidth +4 : totalWidth;

    useEffect(()=>{
		
			invoicesRef.current[invoiceIndex]={...inputValues};
			invoiceRef.current={...inputValues};
			console.log('and    invoicesRef[invoiceIndex] = ',     invoicesRef.current[invoiceIndex])
		
    },[invoiceIndex, inputValues])

    useEffect(() => {
        console.log('INVOICE COMPONENT WITH THE INVOICE CHANGE USE EFFECT ', invoice)
		console.log('invoiceindex  '+  invoiceIndex)
        if (invoice && Object.keys(invoice).length > 0) {
            let tempInvoice = { ...invoice };
            tempInvoice.JobDateValue = new Date(tempInvoice.JobDate);
            tempInvoice.InvoiceDateValue = new Date(tempInvoice.InvoiceDate);
            console.log('StempInvoice.LineItems.length = ', tempInvoice.LineItems);
            tempInvoice.hasBrokerFee = false;
            tempInvoice.hasFuelCharge = false;
            tempInvoice.hasTax = false;
			if(invoiceIndex!==-1){
            	if (tempInvoice.LineItems.length > 0) {
                // Add an index to each line item for stable sorting
                	const sorted = tempInvoice.LineItems.map((item, index) => ({ ...item, originalIndex: index })) // Attach original index
                    .sort((a, b) => {
                        // Put editable items last
                        if (a.isEditable && !b.isEditable) return 1;
                        if (!a.isEditable && b.isEditable) return -1;
                        
                        if (Number(a.FBNO) < Number(b.FBNO)) return -1;
                        if (Number(a.FBNO) > Number(b.FBNO)) return 1;
                        if (a.Type === 'Freight Bill' && b.Type !== 'Freight Bill') return -1;
                        if (a.Type !== 'Freight Bill' && b.Type === 'Freight Bill') return 1;
                        
                        if (a.Type === 'Scale Tag' && b.Type !== 'Scale Tag') return -1;
                        if (a.Type !== 'Scale Tag' && b.Type === 'Scale Tag') return 1;
                        if (a.Type === 'Material' && b.Type !== 'Material') return -1;
                        if (a.Type !== 'Material' && b.Type === 'Material') return 1;
                        if (a.Type === 'Stand By' && b.Type !== 'Stand By') return -1;
                        if (a.Type !== 'Stand By' && b.Type === 'Stand By') return 1;
                        // Tertiary sorting by original index for stability
                        return a.originalIndex - b.originalIndex;
                    })
                    .map(({ originalIndex, ...item }) =>{
                        if(Number(item.TaxTotal)!==0) tempInvoice.hasTax = true;
                        if(Number(item.FuelTotal)!==0) tempInvoice.hasFuelCharge = true; 
                        if(Number(item.BrokerTotal)!==0) tempInvoice.hasBrokerFee = true;
                        return item;
                    });

                console.log('sorted = ', sorted);
                tempInvoice.LineItems = [...sorted];
                console.log('first line items = ',      tempInvoice.LineItems );
            }
            
            tempInvoice.Payments =  tempInvoice.Payments.map(payment => {
				payment.Amount = Number(payment.Amount).toFixed(2);
                payment.formattedDate = formatDate(new Date(payment.dateCreated),'/','MM/DD/YYYY');
                payment.link = 'https://app.qbo.intuit.com/app/recvpayment?txnId=' + payment.ID;
                console.log('makign apyemtn = ', payment)
                return payment;
            });
		}
            
            setInputValues({ ...tempInvoice });
            console.log('Setting inputValues = ', tempInvoice);
        }
    }, [invoice, invoiceIndex]);
    

     const handleFieldChange = (fieldName, value) => {
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        invoicesRef.current[invoiceIndex][fieldName] = value;
      
    };
    const changeInvoiceCompany= (fieldName, value) => {
        let qbCustomerID ='';
        for(let p=0; p<inputValues.Account.Quickbooks.length; p++)
			if(inputValues.Account.Quickbooks[p].realmID===value.realmID)qbCustomerID = inputValues.Account.Quickbooks[p].QBCustomerID;
      
        setInputValues((prev) => ({ ...prev, realmID:value.realmID, [fieldName]: value, QBCustomerID:qbCustomerID }));
      
    };

	const updateAccountForExpenses = (account) => {
		for(let i=0; i<inputValues.Expenses.length; i++)updateDocument({BillTo:{ID:account.ID, Name:account.Name}}, inputValues.Expenses[i].ID, "Expenses");
	}
    const changeInvoiceAccount= (fieldName, value) => {
        let qbCustomerID ='';
        for(let p=0; p<value.Quickbooks.length; p++)if(value.Quickbooks[p].realmID===inputValues.realmID)qbCustomerID = value.Quickbooks[p].QBCustomerID;
        console.log('qbCustomerID = ', qbCustomerID)
        var updateDoc= {
            Account: {
				ID: value.ID,
				Name: value.Name,
				Address: value.Address,
				City: value.City,
				State: value.State,
				ZipCode: value.ZipCode,
				Quickbooks: value.Quickbooks,
				InvoiceNotes: value.InvoiceNotes,
				custAddress2: value.custAddress2 ? value.custAddress2 : value.City + ', ' + value.State + ' ' + value.ZipCode
            },
			LineItems:[],
			AccountID: value.ID,
            QBUpdated:false,
            QBCustomerID:qbCustomerID,
            ParentID:value.ID,
            ParentName:value.Name
        };
		var ExpenseDispatch = {
            ID: 'custom',
            Job:'',
            Account:value,
            isExpenseDispatch: true,
            unBilledItems: 0,
			isFloatingExpenseDispatch: true,
            realJobDate: new Date(),
            PONumber: '',
            ContractNumber: '',
			TruckTypeName: '',
            JobNumber: '',
            JobDate: formatDate( new Date(), '/', 'MM/DD/YYYY'),
            Company: inputValues.Company,
            QueryDate: formatDate( new Date(), '/', 'YYYY/MM/DD'),
            LoadSite: {Name:'', ID:'', fullAddress:''},
            DumpSite: {Name:'', ID:'', fullAddress:''},
            FreightBills: [],
            Expenses: [],
        };
		for(let q=0; q<invoiceExpenseListenersRef.current.length; q++)invoiceExpenseListenersRef.current[q]();
		setInputValues((prev) => ({ ...prev,  ...updateDoc }));
		openInvoiceExpenseListener(inputValues, ExpenseDispatch, invoicesRef.current);
		updateAccountForExpenses(value)
     
            console.log('this is umm te updateDoc = ', updateDoc)
        //  updateDocument(updateDoc, inputValues.ID, "Invoices");
    };

    const openExpensePopUp = async (lineItem) => {
        try {
            const expenseDoc = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`, lineItem.ID);
            const expenseSnap = await getDoc(expenseDoc);
            console.log('lineitem we opening  = ', lineItem)
            if (expenseSnap.exists()) {
				console.log('expenseSnap.data() = ', expenseSnap.data())
                const expense = {...expenseSnap.data(), ID: expenseSnap.id};
                setExpense(expense);
                setExpensePopUpVisible(true);
            } else {
                console.error('No expense found with ID:', lineItem.ID);
            }
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };

    const invoiceByScaleTag = function (Invoice) {

		Invoice.LineItems = [];
		if(scaleTagButton==='Invoice By Scale Tag') Invoice.calcByLoad = true;
		else  Invoice.calcByLoad = false;
		for (var i = 0; i < Invoice.FreightBills.length; i++) {
			if(scaleTagButton==='Invoice By Scale Tag')  Invoice.FreightBills[i].calcByLoad = true;
			else    Invoice.FreightBills[i].calcByLoad = false;
			Invoice.FreightBills[i].truckingBilled = false;
			// Invoice.FreightBills[i].standByIsBilled = false;
			console.log('we doin the the new make firehgit bill ine item with invoice.FreightBills[i]', Invoice.FreightBills[i])
			makeFreightBillLineItem(Invoice.FreightBills[i], Invoice.LineItems, Invoice);
		}
		console.log('Invoice.Expenses = ', Invoice.Expenses)
		for (var j = 0; j < Invoice.Expenses.length; j++)   Invoice.LineItems.push( makeExpenseLineItem(Invoice.Expenses[j]));
	
		let calcInvoice = calcInvoiceTotal({...Invoice});
		calcInvoice.Balance.Total=calcInvoice.Total.Total;
		invoicesRef.current[invoiceIndex]= {...calcInvoice};
		setInputValues({...calcInvoice})
		setInvoice({...calcInvoice})
    }
    
    const toggleAllOnHold = ()=>{
        for(let k=0;k<inputValues.LineItems.length; k++){
            let tempLineItem={...inputValues.LineItems[k]};
            tempLineItem.onHold=currentToggleValue;
            toggleOnHold(tempLineItem, currentToggleValue)
        }
        if(currentToggleValue)setCurrentToggleValue(false); else setCurrentToggleValue(true);
        console.log('togglin')
    }

    const toggleOnHold = (lineItem, value) =>{
        let tempInvoice ={...inputValues};
        let updateDoc ={onHold:value}
        console.log('lineItem.Type == '+ lineItem.Type);
        for(let q=0; q<tempInvoice.LineItems.length; q++){
            if(lineItem.Type==='Expense'){
                if(tempInvoice.LineItems[q].ID===lineItem.ID){
                    tempInvoice.LineItems[q]=lineItem;
                    updateDocument(updateDoc, lineItem.ID, "Expenses");
                }
            }else if(tempInvoice.LineItems[q].FreightID===lineItem.FreightID){
                console.log('I found a lineitem that matches the freight id and it looks like this =', tempInvoice.LineItems[q])
                if(lineItem.Type==='Stand By' && tempInvoice.LineItems[q].Type===lineItem.Type){
                    tempInvoice.LineItems[q]=lineItem;
                    for(let i=0; i<tempInvoice.FreightBills.length; i++){
                        if(tempInvoice.FreightBills[i].ID===lineItem.FreightID) tempInvoice.FreightBills[i].standByOnHold=value;
                        
                    }
                }
                if(lineItem.Type==='Material' && tempInvoice.LineItems[q].Type===lineItem.Type){
                    tempInvoice.LineItems[q]=lineItem;
                    for(let i=0; i<tempInvoice.FreightBills.length; i++){
                        if(tempInvoice.FreightBills[i].ID===lineItem.FreightID) tempInvoice.FreightBills[i].materialOnHold=value;
                        
                    }
                }
                if(lineItem.Type==='Scale Tag' && tempInvoice.LineItems[q].Type===lineItem.Type){
                    console.log('FOUDN THE SCALE TAG ITEM', tempInvoice.LineItems[q]);
                    let tempLineItem = {...tempInvoice.LineItems[q]}
                    tempLineItem.onHold =value;
                    tempInvoice.LineItems[q]= tempLineItem;
                }
                if(lineItem.Type==='Freight Bill'){
                    console.log('tempInvoice.LineItems[q].Type = '+ tempInvoice.LineItems[q].Type)
                    if(tempInvoice.LineItems[q].Type==='Expense') tempInvoice.LineItems[q].onHold=value;                                  
                    if(tempInvoice.LineItems[q].Type===lineItem.Type){
                        tempInvoice.LineItems[q]=lineItem;
                        updateDocument(updateDoc, lineItem.ID, "FreightBills");
                    } 
                }
              
                 
                    
                
            }
         }
         let calcInvoice = calcInvoiceTotal({...tempInvoice});
        invoicesRef.current[invoiceIndex]= {...calcInvoice};
        invoicesRef.current[invoiceIndex].Balance.Total =  invoicesRef.current[invoiceIndex].Total.Total;
        setInvoices(invoicesRef.current)
        console.log('this is where im setting Invoice to ', calcInvoice.LineItems)
        setInvoice({...calcInvoice})
    }

    const markInvoiceUnpaid = ()=>{
        let updateObject= {
            ID:inputValues.ID,
            Balance:inputValues.Balance,
            Paid:inputValues.Paid,
            QBUpdated:inputValues.QBUpdated
        }
      
        updateObject.Balance.Total =inputValues.Total.Total;
        for(let i=0; i<inputValues.Payments.length; i++){
            updateObject.Balance.Total-=Number(inputValues.Payments[i].Amount)
        }
        updateObject.Paid = false;
        updateObject.QBUpdated=true;
        setInputValues((prev) => ({ ...prev,Balance:  updateObject.Balance,Paid :false}));
        updateDocument(updateObject, updateObject.ID, "Invoices");
    }

    const markInvoicePaid = ()=>{
        let updateObject= {
            ID:inputValues.ID,
            Balance:inputValues.Balance,
            Paid:inputValues.Paid,
            QBUpdated:inputValues.QBUpdated
        }
        updateObject.Balance.Total = 0;
        updateObject.Paid = true;
        updateObject.QBUpdated=true;
        setInputValues((prev) => ({ ...prev,Balance:  updateObject.Balance,Paid :true}));
        updateDocument(updateObject, updateObject.ID, "Invoices");
    }

    const handleOpenQuickbooks = (invoice)=>{
        var win = window.open('https://qbo.intuit.com/app/invoice?txnId=' +invoice.QBInvoiceID, '_blank');
    }  

    const syncWithQuickbooks = ()=>{
		let qbCustomerID ='';
		let tempInvoice ={...inputValues};
		if(tempInvoice.Company?.ID){
			let tempAccount ={};
			if(!inputValues.Account){
				for(let p=0; p<accounts.length; p++){
					if(inputValues.AccountID===accounts[p].ID) tempAccount={...accounts[p]}
				}
			}else tempAccount = {...inputValues.Account}
			console.log('tempAccount = ', tempAccount)
			console.log('tempInvoice.Company.realmID = ', tempInvoice.Company.realmID)
			for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===tempInvoice.Company.realmID)qbCustomerID = tempAccount.Quickbooks[p].QBCustomerID;
			setInputValues((prev) => ({ ...prev, QBCustomerID:qbCustomerID }));
			tempInvoice.realmID=tempInvoice.Company.realmID;
			tempAccount={
				ID: tempAccount.ID,
				Name: tempAccount.Name, 
				Address: tempAccount.Address,
				City: tempAccount.City,
				State: tempAccount.State,
				custAddress2 :tempAccount.City + ', ' + tempAccount.State + ' ' + tempAccount.ZipCode,
				ZipCode: tempAccount.ZipCode,
				InvoiceNotes:tempAccount.InvoiceNotes,
				Quickbooks:tempAccount.Quickbooks
			}
			setInputValues((prev) => ({ ...prev, Account:tempAccount, QBCustomerID:qbCustomerID, Editing:false }));
			tempInvoice.QBCustomerID=qbCustomerID;
			invoicesRef.current[invoiceIndex]={...tempInvoice};

			let updateObject= {
				Account:tempAccount,
				AccountID:tempAccount.ID,
				ParentName:tempAccount.Name,
				ID:inputValues.ID,
				QBCustomerID:qbCustomerID,
				ParentID:tempAccount.ID,
				realmID:inputValues.realmID,
				QBUpdated:false
			}

			console.log('updateObject = ', updateObject)
			updateDocument(updateObject, updateObject.ID, "Invoices");
		}else alert("Please select a company to sync with");
    }

    const handleDelete = async () => {
        try {
            if (window.confirm("Are you sure you want delete this Invoice?")) {
               await deleteDocument(inputValues,'Invoices' )
                  navigate('/invoice/list')
            }
            
        } catch (error) {  console.error("Error removing document: ", error);  }
       
    };

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate)
        
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));
        invoicesRef.current[invoiceIndex][fieldName]= formattedDate;
        invoicesRef.current[invoiceIndex][fieldName+'Value']= value;
    };

    const handleAddLineItem = () => {
        createEmptyLineItem(invoiceRef.current);
    };

    console.log('showPrintFBImages= '+ showPrintFBImages)
    console.log('invoicesRef.current inside component rerender = ', invoicesRef.current);

   
	const truckTypeWrapper = {
		TruckTypeName: inputValues.TruckTypeName || '',
		TruckType: inputValues.TruckTypeName || ''
	};
	
	const customSetValue = (updaterFn) => {
		// Extract the value by simulating the state update
		const dummyPrev = { TruckType: inputValues.TruckTypeName || '' };
		const updated = updaterFn(dummyPrev);
		const newValue = updated.TruckType;
		
		console.log('Extracted value:', newValue);
		handleTruckTypeInputChange(inputValues, newValue);
	};

	const handleTruckTypeInputChange = (rowData, value) => {
        console.log('handleTruckTypeInputChange called with value:', value);
        
        let tempInputValues = {...inputValues}
		tempInputValues.TruckTypeName=value;
        setInputValues(tempInputValues);

    };

    const columns = getColumnConfig(inputValues);

	console.log('columns = ', columns)

return(
    <React.Fragment>
        {inputValues.AccountID &&(<div className='mbsc-grid' >
            <div className=' mbsc-row'>
                <div className="mbsc-col-lg-4 mbsc-col-12">
            
            
					<AutoCompleteInput 
						label="Account" 
						fieldName="Account" 
						field="Name" 
						disabled={!inputValues.isFloatingExpenseInvoice || !inputValues.previewing} 
						value={inputValues.Account} 
						labelClass='p-inputgroup-addon freight-inputgroup' 
						showLabel={false} 
						suggestions={accounts} 
						setValue={setInputValues} 
						handleFieldChange={changeInvoiceAccount} 
					/>

                 
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon freight-inputgroup">Invoice Number</span>
                        <InputNumber  useGrouping={false} disabled={!inputValues.previewing} value={inputValues.InvoiceNumber  || ''} onChange={(e) => handleFieldChange('InvoiceNumber', e.value)} />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon freight-inputgroup"> Job Number</span>
                        <InputTextParent value={inputValues.JobNumber  || ''} disabled={!inputValues.previewing} onChange={(e) => handleFieldChange('JobNumber', e.target.value)} />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon freight-inputgroup"> PO Number</span>
                        <InputTextParent value={inputValues.PONumber  || ''}  disabled={!inputValues.previewing} onChange={(e) => handleFieldChange('PONumber', e.target.value)} />
                    </div>
                </div>
                <div className='mbsc-col-lg-4  mbsc-col-md-12'>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon freight-inputgroup"> Job Date</span>
                        <Calendar value={inputValues.JobDateValue} disabled={!inputValues.previewing} style={{width:"100%"}} onChange={(e) => handleDateChange( 'JobDate',e.value )} />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon freight-inputgroup">Invoice Date</span>
                        <Calendar value={inputValues.InvoiceDateValue} disabled={!inputValues.previewing} style={{width:"100%"}} onChange={(e) => handleDateChange( 'InvoiceDate',e.value )} />
                    </div>
                    
                    <AutoCompleteInput 
						label="Company" 
						fieldName="Company" 
						field="CompanyName" 
						disabled={!inputValues.previewing}
						value={inputValues.Company} 
						labelClass='p-inputgroup-addon freight-inputgroup' 
						showLabel={false} 
						suggestions={companies}
						setValue={setInputValues} 
						handleFieldChange={changeInvoiceCompany} 
					/>
					{!inputValues.isFloatingExpenseInvoice &&(
						<AutoCompleteInput 
							labelClass='p-inputgroup-addon freight-inputgroup'
							label="Truck Type" 
							fieldName="TruckType" 
							field="Name" 
							value={truckTypeWrapper.TruckTypeName} 
							suggestions={truckTypes}   
							setValue={customSetValue}
							handleFieldChange={(fieldName, value) =>handleTruckTypeInputChange(inputValues, value.Name)}
						/>
					)}
                </div>
                <div className="mbsc-col-lg-4  mbsc-col-md-12">
					<div className="mbsc-row">
						<Button  
							style={{ margin: '0', padding: '.5em', marginBottom:'.5em',marginRight:'.5em', width:"12em"}}  
							label=" Print"  
							onClick={(e) =>showPrintPopUp(inputValues, false)}      
						/>
					
						{!inputValues.previewing && !inputValues.Paid && (
							<Button  
								style={{ margin: '0', padding: '.5em', marginBottom:'.5em',  width:"12em"}}  
								label=" Mark Paid"  
								onClick={(e) =>markInvoicePaid()}       
							/>
						)}
						{!inputValues.previewing && inputValues.Paid && (
							<Button  
								style={{ margin: '0', padding: '.5em',  marginBottom:'.5em',width:"12em"}}  
								label="Mark Unpaid"  onClick={(e) =>markInvoiceUnpaid()}     
							/>
						)}
					</div>
                    {/*<button style={{ margin: '0',  marginLeft:'.5em', padding: '.5em',, width:"12em" }}   onClick={(e) =>    setInputValues((prev) => ({ ...prev,Editing: true}))}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Edit Invoice</button>*/}
					<div className="mbsc-row">
						{!inputValues.previewing && (
							<Button  
								style={{ margin: '0', padding: '.5em', marginBottom:'.5em',  marginRight:'.5em', width:"12em"}}  
								label="Delete"  
								onClick={(e) =>handleDelete()}      
							/>
						)}
						{!inputValues.previewing && inputValues.QBInvoiceID && (<>
							{!inputValues.Editing ? (<Button 
								style={{ margin: '0',  padding: '.5em', marginBottom:'.5em',  width:"12em" }} 
								label="Open Quickbooks"  
								onClick={(e) => handleOpenQuickbooks(inputValues)}       
							/>
							):(<Button 
								style={{ margin: '0',  padding: '.5em', marginBottom:'.5em', width:"12em" }}  
								label="Sync to Quickbooks"  
								onClick={(e) => syncWithQuickbooks()}      
							/>)}

						</>)} 
						{!inputValues.previewing  && !inputValues.QBInvoiceID && (
							<Button 
								className="mbsc-row"  
								style={{ margin: '0', padding: '.5em',  marginBottom:'.5em',width:"12em"}}
								label="Sync to Quickbooks"  
								onClick={(e) => syncWithQuickbooks()}   
							/> 
						)}
					</div>
                    {inputValues.previewing && inputValues.showCalcByLoad && (
                        <Button  
							className="mbsc-row" 
							style={{ margin: '0',  padding: '.5em', marginBottom:'.5em', width:"12em" }}   
							label={scaleTagButton}
							onClick={(e) =>invoiceByScaleTag(inputValues)}        
						/> 
                   )}
                    {!inputValues.previewing &&  company.downloadIIF && (
						<div >
                        	<IIFDownloadComponent invoices={[inputValues]} />
						</div>
                    )}
                 
         
                </div>
            </div>
            <div className='mbsc-row'>
                <div className="p-inputgroup mbsc-col-lg-6" style={{ marginTop:'1em'}}>
                    <span className="p-inputgroup-addon " > Load Site</span>
                    <InputTextParent value={inputValues.LoadSite.Name || ''} disabled={true}  />
                </div>
                
                <div className="p-inputgroup mbsc-col-lg-6" style={{ marginTop:'1em'}}>
                    <span className="p-inputgroup-addon " > Dump Site</span>
                    <InputTextParent value={inputValues.DumpSite.Name || ''} disabled={true}  />
                </div>
               
            </div>
            <div className='mbsc-row'>
                <div className="p-inputgroup text-area-inputgroup mbsc-col-lg-6" style={{height:'4em !important', marginTop:'.25em', marginBottom:'1em' }} >
                    <span className="p-inputgroup-addon "   >Address: </span> 
                    <Textarea disabled={true}  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px", height:'100% !important'}} value={inputValues.LoadSite.fullAddress}  id="loadSite"  />
                </div>
                <div className="p-inputgroup text-area-inputgroup mbsc-col-lg-6" style={{height:'4em !important', marginTop:'.25em', marginBottom:'1em' }} >
                    <span className="p-inputgroup-addon  "   >Address: </span> 
                    <Textarea disabled={true}  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px", height:'100% !important' }} value={inputValues.DumpSite.fullAddress}  id="loadSite"  />
                </div>
            </div>
            {inputValues.previewing && (
                <div className="mbsc-row" style={{marginBottom:".5em"}}>
                    <Button 
                        color="primary" 
                        onClick={() => handleAddLineItem()} 
                        style={{
                            margin:"0", 
                            marginLeft:".5em", 
                            paddingLeft:"1em !important", 
                            paddingBottom: ".2em", 
                            paddingTop: ".2em", 
                            height:"100%"
                        }}
                    >
                        Add Line Item
                    </Button>
                </div>
            )}
            {inputValues.LineItems && ( <div className='mbsc-row'>
                <table style={{ marginBottom: "5px", width: "100%", padding:"1.5em"}}>
                    <thead>
                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                            {columns.map(col => col.visible && (
                                <th key={col.id} style={{ width: col.width }}>
                                    {col.header}
                                    {col.id === 'onHold' && inputValues.previewing && (
                                        <button style={{ margin: '0', padding: '.4em', width:"95%" }}  onClick={toggleAllOnHold}  className="mbsc-ios mbsc-btn-primary mbsc-btn"   >
                                            Toggle on Hold
                                        </button>
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            const lineItems = inputValues.LineItems;
                            return lineItems.map((lineItem, index) => {
                                const prevFBNO = index > 0 ? lineItems[index - 1].FBNO : null; // Previous FBNO
                                const nextFBNO =index < lineItems.length - 1 ? lineItems[index + 1].FBNO : null; // Next FBNO

                                const borderTop = lineItem.FBNO !== prevFBNO || lineItem.isEditable ? "1px" : "0px";
                                const borderBottom = lineItem.FBNO !== nextFBNO || lineItem.isEditable ? "1px" : "0px";
                                lineItem.hideFBNO = borderTop==="0px" ? true : false;
                                const borderWidth = `${borderTop} 1px ${borderBottom} 1px`;
								console.log('company.downloadIIF = ', company.downloadIIF)
                                if(!company.downloadIIF){
                                    return (
                                        <LineItemList 
										key={index} 
										invoice={inputValues} 
										lineItem={lineItem}   
										toggleOnHold={toggleOnHold}   
										borderWidth={borderWidth}   
										columns={columns}   
										openExpensePopUp={openExpensePopUp}
										 />
                                    );
                                }else{
                                    return (
                                        <InvoiceHomeLineItemList key={index}  invoice={inputValues} lineItem={lineItem}   toggleOnHold={toggleOnHold}   borderWidth={borderWidth}   columns={columns}   openExpensePopUp={openExpensePopUp} />
                                    );
                                }
                            });
                        })()}
                </tbody>
                </table>
            </div>)} 
            {inputValues.Totals && ( <div className='mbsc-row'>
                <div className='mbsc-col-lg-4 mbsc-col-6'>
                    {inputValues.Payments &&  (
                        <table style={{ marginBottom: "5px", width: "100%", paddingBottom:"1.5em"}}>
                            <thead>
                                <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                    <th style={{ width: "50%" }}>Payment Date</th>
                                    <th style={{ width: "50%" }}>Amount</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {inputValues.Payments.map(( paymentItem, index ) => (
                                    <PaymentItemList key={index} paymentItem={paymentItem}  />
                                ))}
                                <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
                                    <td data-label='Balance' style={{ fontWeight:'bold', width: '50%',  textAlign:'center', margin:'0 auto', borderWidth: '1px 1px 1px 1px ', borderColor:'#bcbcd1', borderStyle:'solid' }}>
                                        <span style={{ paddingRight:'.5em'}}>Balance</span>
                                    </td>
                                    <td data-label='Amount' style={{ fontWeight:'bold', width: '50%', padding: '0', borderWidth: '1px 1px 1px 1px ', borderColor:'#bcbcd1', borderStyle:'solid' }}>
                                        <span style={{display:'block', textAlign:'right', paddingRight:'1em'}}><span>$</span>{Number(inputValues.Balance.Total).formatMoney(2)}</span>
                                    </td>
                                </tr>    
                            </tbody>
                        </table>
                    )} 
                </div>
                <div className='mbsc-col-lg-4 mbsc-offset-lg-4 mbsc-col-6 '>
                    <table style={{ marginBottom: "5px", width: "100%", paddingBottom:"1.5em"}}>
                        <thead>
                            <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                <th style={{ width: "33%" }}>Type</th>
                                <th style={{ width: "33%" }}>Qty</th>
                                <th style={{ width: "33%" }}>Total</th>            
                            </tr>
                        </thead>
                        <tbody>
                            {inputValues.Totals.map(( totalItem, index ) => (
                                <TotalItemList key={index} totalItem={totalItem} isPercent={totalItem.Type === 'Broker' || totalItem.Type === 'Fuel' || totalItem.Type === 'Tax'}/>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>)}
        </div>)}

        <LoadingOverlay isLoading={isLoading} message="Please wait..." />
    </React.Fragment>
);
};

export default InvoiceComponent;