import React from 'react';

import  TableCell  from './TableCell';

const TotalItemList = ({ totalItem, isPercent}) => {
   


    return (
        <React.Fragment>
           {totalItem?.Total!==0 && (
            <tr className="mbsc-row" style={{ width: '100%', marginLeft: '1.1em' }}>
                <TableCell width='33%' isNumber={false} value={totalItem.Type}  dataLabel='Type' />
                {totalItem?.Qty!=='N/A' ? ( <TableCell width='33%'  isNumber={true} value={totalItem.Qty} notCurrency={true} isPercent={isPercent} dataLabel='Qty' />):
                    (<TableCell width='33%' isNumber={false} value={totalItem.Qty}  dataLabel='Qty' />)}
                <TableCell width='33%'  isNumber={true} value={totalItem.Total}  dataLabel='Total'/>
            </tr>  )}            
        </React.Fragment>
    )
}

export default TotalItemList;