import React, {useState} from 'react';

import DedicatedTruck from '../../ListComponents/DedicatedTruck';
const DedicatedTruckTable = ({handleAddTruck, accountTrucks, capabilities, truckTypes, gearedUser, trailers}) => {
	const [showInactiveTrucks, setShowInactiveTrucks] = useState(false);

	return(
		<React.Fragment>
			<div className="mbsc-row" style={{ width: "100%" }}>
				<div className="mbsc-col-md-2 mbsc-col-6"><button  className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button"  onClick={(e) =>handleAddTruck()}    >Add Truck</button></div>
				<div className="mbsc-col-md-2 mbsc-col-6">{showInactiveTrucks ? (<button  className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button"  onClick={(e) =>setShowInactiveTrucks(false)}    >Show Only Active Trucks</button>):
				(<button  className="mbsc-ios mbsc-btn-primary mbsc-btn" type="button"  onClick={(e) =>setShowInactiveTrucks(true)}    >Show Inactive Trucks</button>)}</div>
			</div>
			<div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
				<table style={{ marginBottom: "5px", width: "100%" }}>
					<thead>
						<tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
							<th style={{ width: "10%" }}>Delete</th>
							<th style={{ width: "15%" }}>Truck Types</th>
							<th style={{ width: "15%" }}>Capabilities</th>
							<th style={{ width: "10%" }}>Driver Name</th>
							<th style={{ width: "10%" }}>Truck</th>
							<th style={{ width: "10%"  }}> Trailer</th>
							<th style={{ width: "5%"}}>Trailer Fee</th>
							<th style={{ width: "15%" }}>Phone Number</th>
							<th style={{ width: "10%" }}>Status</th>
						</tr>
					</thead>
					<tbody>
						{accountTrucks.map(({ outsideTruck, originalIndex }) => {
							if (outsideTruck.Status === 'Active' || showInactiveTrucks) {
								return (
									<DedicatedTruck 
										key={originalIndex} 
										outsideTruck={outsideTruck} 
										capabilities={capabilities} 
										truckTypes={truckTypes} 
										gearedUser={gearedUser} 
										trailers={trailers} 
									/> 
								);
							}
							return null;
						})}
					</tbody>
				</table>
			</div>
			</React.Fragment>
	)
}
export default DedicatedTruckTable;