import React, {useEffect, useState} from 'react';

import InputTextParent from '../InputComponents/InputTextParent'; 
import { InputNumber} from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

import { usePayStatement } from './context/PayStatementContext';
import { useLineItem } from './context/PayStatementLineItemContext';
import { UserAuth } from '../../context/AuthContext'
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 
import DPSLineItemList from './LineItemComponents/DPSLineItemList';
import TotalItemList from '../ListComponents/TotalItemList';
import ExpensePopUp from '../Expenses/ExpensePopUp';
import LoadingOverlay from '../MiscComponents/LoadingOverlay';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
const PayStatementComponent = ({inputValues, setInputValues}) => {

    const {companies, updateDocument, formatDate, accounts,gearedUser} = UserAuth();
      
    const {payStatement, setPayStatement,  calcDPSTotal, payStatementsRef,payStatementRef, setPayStatements, isLoading} = usePayStatement();
    const {createEmptyLineItem} = useLineItem();
    const [currentToggleValue, setCurrentToggleValue]=useState(true);
    const [selectedExpense, setSelectedExpense] = useState(null);
    const [expensePopupVisible, setExpensePopupVisible] = useState(false);
  
    const payStatementIndex =  payStatementsRef.current.findIndex(obj => obj?.ParentID === payStatementRef.current?.ParentID);

    console.log('paySTatementindex = ' ,payStatement)

    
    useEffect(() => {
        if (payStatement && Object.keys(payStatement).length > 0) {
            let tempPayStatement = { ...payStatement };
            tempPayStatement.DPSDateValue = new Date(tempPayStatement.DPSDate);
            tempPayStatement.StartDateValue = new Date(tempPayStatement.StartDate);
            tempPayStatement.EndDateValue = new Date(tempPayStatement.EndDate);
            

            const shouldSort = !inputValues || !inputValues.LineItems || 
            payStatement.LineItems.length !== inputValues.LineItems.length ||
            payStatement.LineItems.some((item, index) => 
                !inputValues.LineItems[index] || 
                item.QueryDate !== inputValues.LineItems[index].QueryDate ||
                item.FBNO !== inputValues.LineItems[index].FBNO ||
                item.Type !== inputValues.LineItems[index].Type
            );

            console.log('shouldSort = ', shouldSort)
            

            if (tempPayStatement.LineItems.length > 0 && shouldSort) {
                console.log('Sorting line items...');
                const sorted = tempPayStatement.LineItems
                    .map((item, index) => ({ ...item, originalIndex: index }))
                    .sort((a, b) => {
                        // Your existing sort logic
                        if (a.isEditable && !b.isEditable) return 1;
                        if (!a.isEditable && b.isEditable) return -1;
                        if (a.QueryDate < b.QueryDate) return -1;
                        if (a.QueryDate > b.QueryDate) return 1;
                        if (Number(a.FBNO) < Number(b.FBNO)) return -1;
                        if (Number(a.FBNO) > Number(b.FBNO)) return 1;
                        if (a.Type === 'Freight Bill' && b.Type !== 'Freight Bill') return -1;
                        if (a.Type !== 'Freight Bill' && b.Type === 'Freight Bill') return 1;
                        if (a.Type === 'Travel Time' && b.Type !== 'Travel Time') return -1;
                        if (a.Type !== 'Travel Time' && b.Type === 'Travel Time') return 1;
                        if (a.Type === 'Overtime' && b.Type !== 'Overtime') return -1;
                        if (a.Type !== 'Overtime' && b.Type === 'Overtime') return 1;
                        if (a.Type === 'Stand By' && b.Type !== 'Stand By') return -1;
                        if (a.Type !== 'Stand By' && b.Type === 'Stand By') return 1;
                        if (a.Description < b.Description) return -1;
                        if (a.Description > b.Description) return 1;
                        // Tertiary sorting by original index for stability
                        return a.originalIndex - b.originalIndex;
                    })
                    .map(({ originalIndex, ...item }) => item);
                
                tempPayStatement.LineItems = [...sorted];
                payStatementsRef.current[payStatementIndex]={...tempPayStatement};
                setPayStatement({...tempPayStatement})
               
            } else {
                // If we don't need to sort, just use the existing line items
                tempPayStatement.LineItems = [...payStatement.LineItems];
            }
            setInputValues({ ...tempPayStatement });
           
        }
    }, [payStatement]);
    
    const handleFieldChange = (fieldName, value) => {
        let tempPayStatement ={...inputValues};
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        tempPayStatement[fieldName]=value;
        payStatementsRef.current[payStatementIndex]={...tempPayStatement};
      
    };

    const changePayStatementCompany= (fieldName, value) => {
        let qbVendorID ='';
        let tempPayStatement ={...inputValues};
        let tempAccount ={};
        if(!inputValues.Account){
            for(let p=0; p<accounts.length; p++){
                if(inputValues.AccountID===accounts[p].ID) tempAccount={...accounts[p]}
            }
        }else tempAccount = {...inputValues.Account}

        for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===value.realmID)qbVendorID = tempAccount.Quickbooks[p].QBVendorID;
        setInputValues((prev) => ({ ...prev, realmID:value.realmID, [fieldName]: value, QBVendorID:qbVendorID }));
        tempPayStatement.realmID=value.realmID;
        tempPayStatement[fieldName]=value;
        tempPayStatement.QBVendorID=qbVendorID;
        payStatementsRef.current[payStatementIndex]={...tempPayStatement};
    };
    const toggleAllOnHold = ()=>{
        for(let k=0;k<inputValues.LineItems.length; k++){
            let tempLineItem={...inputValues.LineItems[k]};
            tempLineItem.onHold=currentToggleValue;
            toggleOnHold(tempLineItem)
        }
        if(currentToggleValue)setCurrentToggleValue(false); else setCurrentToggleValue(true);
        console.log('togglin')
    }
    const openExpensePopUp = async (lineItem) => {
        try {
            const expenseDoc = doc(db, `Organizations/${gearedUser.selectedOrgName}/Expenses`, lineItem.ID);
            const expenseSnap = await getDoc(expenseDoc);
            
            if (expenseSnap.exists()) {
                const expense = {...expenseSnap.data(), ID: expenseSnap.id};
                setSelectedExpense(expense);
                setExpensePopupVisible(true);
            } else {
                console.error('No expense found with ID:', lineItem.ID);
            }
        } catch (error) {
            console.error('Error fetching expense:', error);
        }
    };
    const toggleOnHold = (lineItem) =>{
        let tempPayStatement ={...inputValues};
        let updateDoc ={onHold:lineItem.onHold}
        console.log('lineItem.Type == '+ lineItem.Type);
        for(let q=0; q<tempPayStatement.LineItems.length; q++){
            if(lineItem.Type==='Expense'){
                if(tempPayStatement.LineItems[q].ID===lineItem.ID){
                    tempPayStatement.LineItems[q]=lineItem;
                    updateDocument(updateDoc, lineItem.ID, "Expenses");
                }
            }else if(tempPayStatement.LineItems[q].FreightID===lineItem.FreightID){
                console.log('I found a lineitem that matches the freight id and it looks like this =', tempPayStatement.LineItems[q])
                if(lineItem.Type==='Stand By' && tempPayStatement.LineItems[q].Type===lineItem.Type){
                    tempPayStatement.LineItems[q]=lineItem;
                    for(let i=0; i<tempPayStatement.FreightBills.length; i++) if(tempPayStatement.FreightBills[i].ID===lineItem.FreightID)tempPayStatement.FreightBills[i].standByOnHold=lineItem.onHold;
                }
                if(lineItem.Type==='Freight Bill'){
                    if(tempPayStatement.LineItems[q].Type==='Expense') tempPayStatement.LineItems[q].FreightHold=lineItem.onHold;
                    if(tempPayStatement.LineItems[q].Type===lineItem.Type){
                        tempPayStatement.LineItems[q]=lineItem;
                        updateDocument(updateDoc, lineItem.ID, "FreightBills");
                    } 
                }
            }
        }
        
        payStatementsRef.current[payStatementIndex]={...tempPayStatement};
        let calcPayStatement = calcDPSTotal({...tempPayStatement});
        setPayStatements(payStatementsRef.current)
        console.log('this is where im setting PayStatement to ', calcPayStatement.LineItems)
        setPayStatement({...calcPayStatement})
    }
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        let tempPayStatement ={...inputValues};
     
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));
        tempPayStatement[fieldName+'Value']=value
        tempPayStatement[fieldName]=formattedDate;
        payStatementsRef.current[payStatementIndex]={...tempPayStatement};
       
    };
    const markDPSUnpaid = ()=>{
        let updateObject= {
            ID:inputValues.ID,
            Balance:inputValues.Balance,
            Paid:inputValues.Paid,
            QBUpdated:inputValues.QBUpdated
        }
        updateObject.Balance.Total =inputValues.Total.Total;
        updateObject.Paid = false;
        updateObject.QBUpdated=true;
        setInputValues((prev) => ({ ...prev,Balance:  updateObject.Balance,Paid :false}));
        updateDocument(updateObject, updateObject.ID, "PayStatements");
    }
    const markDPSPaid = ()=>{
        let updateObject= {
            ID:inputValues.ID,
            Balance:inputValues.Balance,
            Paid:inputValues.Paid,
            QBUpdated:inputValues.QBUpdated
        }
        updateObject.Balance.Total = 0;
        updateObject.Paid = true;
        updateObject.QBUpdated=true;
        setInputValues((prev) => ({ ...prev,Balance:  updateObject.Balance,Paid :true}));
        updateDocument(updateObject, updateObject.ID, "PayStatements");
    }
    const syncWithQuickbooks = ()=>{
      let qbVendorID ='';
        let tempPayStatement ={...inputValues};
        if(tempPayStatement.Company?.ID){
            
            let tempAccount ={};
            if(!inputValues.Account){
                for(let p=0; p<accounts.length; p++){
                    if(inputValues.AccountID===accounts[p].ID) tempAccount={...accounts[p]}
                }
            }else tempAccount = {...inputValues.Account}
            for(let p=0; p<tempAccount.Quickbooks.length; p++)if(tempAccount.Quickbooks[p].realmID===tempPayStatement.Company.realmID)qbVendorID = tempAccount.Quickbooks[p].QBVendorID;
            setInputValues((prev) => ({ ...prev, QBVendorID:qbVendorID }));
            tempPayStatement.realmID=tempPayStatement.Company.realmID;
    
            tempPayStatement.QBVendorID=qbVendorID;
            payStatementsRef.current[payStatementIndex]={...tempPayStatement};

            let updateObject= {
                ID:inputValues.ID,
                QBVendorID:qbVendorID,
                realmID:inputValues.realmID,
                QBUpdated:false
            }
            console.log('updateObject = ', updateObject)
            updateDocument(updateObject, updateObject.ID, "PayStatements");
        }else alert("Please select a company to sync with");
    }
    const updatePayStatement = ()=>{

        let tempPayStatement ={...inputValues};
        if(tempPayStatement.Company?.ID){
              
              let updateObject= {
                  ID:inputValues.ID,
                  Company:inputValues.Company,
             
              }
              console.log('updateObject = ', updateObject)
              updateDocument(updateObject, updateObject.ID, "PayStatements");
      }else alert("Please select a company to sync with");
    } 
    const handleAddLineItem = () => {
        createEmptyLineItem(payStatementRef.current);
      
    };
    const handleOpenQuickbooks = (dps)=>{
        var win = window.open('https://qbo.intuit.com/app/bill?txnId=' +dps.QBPayStatementID, '_blank');
    }  
    const getColumnConfig = (invoice) => {
        // Base widths calculation
   
        
        let rateTypeAndAmountWidth = 6;
        let qtyAndRateWidth =5;
        let brokerAndTrailerWidth=8;
        // Dynamic width adjustments
   
        rateTypeAndAmountWidth = !inputValues.showTrailerFee ? rateTypeAndAmountWidth +2: rateTypeAndAmountWidth;
        rateTypeAndAmountWidth = !inputValues.showBrokerFee ? rateTypeAndAmountWidth +2: rateTypeAndAmountWidth;
        rateTypeAndAmountWidth = !inputValues.showDriverPercent ?  rateTypeAndAmountWidth+3 : rateTypeAndAmountWidth;
        rateTypeAndAmountWidth =  !inputValues.showBrokerFee  && !inputValues.showTrailerFee ?  rateTypeAndAmountWidth+1 : rateTypeAndAmountWidth;
        rateTypeAndAmountWidth = !inputValues.Company?.ShowDPSHours ? rateTypeAndAmountWidth+1: rateTypeAndAmountWidth;
        qtyAndRateWidth = !inputValues.Company?.ShowDPSHours ?  qtyAndRateWidth+1:  qtyAndRateWidth;
        qtyAndRateWidth = !inputValues.showTrailerFee ? qtyAndRateWidth +1: qtyAndRateWidth;
        qtyAndRateWidth = !inputValues.showBrokerFee ? qtyAndRateWidth +1: qtyAndRateWidth;
        qtyAndRateWidth = !inputValues.showBrokerFee  && !inputValues.showTrailerFee ? qtyAndRateWidth +1 :qtyAndRateWidth;
    
    
        qtyAndRateWidth  = !inputValues.showDriverPercent ? qtyAndRateWidth +2 :qtyAndRateWidth ;
        brokerAndTrailerWidth = !inputValues.showTrailerFee || !inputValues.showBrokerFee ? brokerAndTrailerWidth + 2 : brokerAndTrailerWidth;
   
        let baseWidths = [6, 5, 18];
        baseWidths =  !inputValues.Company?.ShowDPSPONumber ?  [baseWidths[0]+ 2, baseWidths[1]+1, baseWidths[2]+3] : baseWidths;
        baseWidths =  !inputValues.Company?.ShowDPSJobNumber ?  [baseWidths[0]+ 2, baseWidths[1]+1, baseWidths[2]+3] : baseWidths;
        baseWidths =  inputValues.ID ?  [baseWidths[0]+ 2, baseWidths[1]+2, baseWidths[2]+3] : baseWidths;
        // Base widths array

        return [
            {
                id: 'jobdate',
                header: 'Job Date',
                width: `${baseWidths[0]}%`,
                visible: true
            },
            {
                id: 'fbno',
                header: 'FB #',
                width: `${baseWidths[1]}%`,
                visible: true
            },
            {
                id: 'jobnumber',
                header: 'Job Number',
                width: '6%',
                visible: inputValues?.Company?.ShowDPSJobNumber
            },
            {
                id: 'ponumber',
                header: 'PO Number',
                width: '6%',
                visible: inputValues?.Company?.ShowDPSPONumber
            },
            {
                id: 'description',
                header: 'Description',
                width: `${baseWidths[2]}%` ,
                visible: true
            },
            {
                id: 'hours',
                header: 'Hours',
                width: '4%',
                visible: inputValues?.Company?.ShowDPSHours && !inputValues.Subhauler
            },
            {
                id: 'onHold',
                header: 'On Hold',
                width: '7%',
                visible: !inputValues?.ID
            },
           
            {
                id: 'rateType',
                header: 'Rate Type',
                width: `${rateTypeAndAmountWidth}%`,
                visible: true
            },
            {
                id: 'qty',
                header: 'Qty',
                width: `${qtyAndRateWidth}%`,
                visible: true
            },
            {
                id: 'rate',
                header: 'Rate',
                width: `${qtyAndRateWidth}%`,
                visible: true
            },
            {
                id:'amount',
                header: 'Amount',
                width: `${rateTypeAndAmountWidth}%`,
                visible: true
            },
            {
                id: 'trailerFee',
                header: 'Trailer Fee',
                width: `${brokerAndTrailerWidth}%`,
                visible: inputValues?.showTrailerFee
            },

            {
                id: 'brokerFee',
                header: 'Broker Fee',
                width: `${brokerAndTrailerWidth}%`,
                visible: inputValues?.showBrokerFee
            },
            {
                id: 'driverPercent',
                header: 'Driver %',
                width: `4%`,
                visible: inputValues?.showDriverPercent
            },
           
            {
                id: 'total',
                header: 'Total',
                width: `6%`,
                visible: inputValues?.showDriverPercent
            }
        ];
    };
    const updateLineItem = (lineItem)=>{
        let  tempPayStatement = {...inputValues};
        console.log('lineItem  in the pay statement component = ', lineItem)
        tempPayStatement.LineItems[lineItem.index]=lineItem;
        tempPayStatement=calcDPSTotal(tempPayStatement);
        payStatementsRef.current[payStatementIndex]={...tempPayStatement};
        payStatementRef.current={...tempPayStatement};
        setPayStatement({...tempPayStatement});
        setInputValues({...tempPayStatement});
    }
    const columns = getColumnConfig(inputValues);

    return(
        <React.Fragment>
             <div className='mbsc-row mbsc-grid' style={{paddingLeft:"0"}}>
                <div className='mbsc-col-xl-4'>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"> Driver:</span>
                        <InputTextParent value={inputValues.ParentName} disabled={true} onChange={(e) => handleFieldChange('ParentName', e.target.value)} />
                    </div>
                  
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Pay Statement Number:</span>
                        <InputNumber  useGrouping={false} disabled={inputValues.ID} value={inputValues.DPSNum} onChange={(e) => handleFieldChange('DPSNum', e.value)} />
                    </div>
                    <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon dispatch-small-inputgroup' showLabel={false} suggestions={companies} setValue={setInputValues} handleFieldChange={changePayStatementCompany} />

                </div>
                <div className='mbsc-col-xl-4'>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"> Pay Statement  Date:</span>
                        <Calendar value={inputValues.DPSDateValue} style={{width:"100%"}}  disabled={inputValues.ID}onChange={(e) => handleDateChange( 'DPSDate',e.value )} />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Start Date:</span>
                        <Calendar value={inputValues.StartDateValue} style={{width:"100%"}} disabled={inputValues.ID} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                    </div>
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup">End Date:</span>
                        <Calendar value={inputValues.EndDateValue} style={{width:"100%"}} disabled={inputValues.ID}  onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                    </div>
                </div>
                <div className='mbsc-col-xl-3' >
                    {inputValues.ID  && !inputValues.Paid && (<div className="p-inputgroup"  style={{padding:"1em"}}>
                        <button style={{ margin: '0', padding: '.5em', paddingBottom:'1.5em', width:"12em"}}   onClick={(e) =>markDPSPaid()}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Mark Paid</button>
                    </div>)}
                    {inputValues.ID && inputValues.Paid && (<div className="p-inputgroup"  style={{padding:"1em"}}>
                        <button style={{ margin: '0', padding: '.5em', paddingBottom:'1.5em', width:"12em"}}   onClick={(e) =>markDPSUnpaid()}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Mark Unpaid</button>
                    </div>)}
                    {inputValues.ID  && inputValues.QBPayStatementID && (<div className="p-inputgroup" style={{padding:"1em"}}>
                        <button style={{ margin: '0',  padding: '.5em', paddingBottom:'1.5em', width:"12em" }}   onClick={(e) => handleOpenQuickbooks(inputValues)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Open Quickbooks</button>
                    </div>)}
                    {/*{inputValues.ID && (<div className="p-inputgroup" style={{padding:"1em"}}>
                        <button style={{ margin: '0',  padding: '.5em', paddingBottom:'1.5em', width:"12em" }}   onClick={(e) => updatePayStatement()}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Save Pay Statement</button>
                    </div>)}*/}
                    {inputValues.ID  && !inputValues.QBPayStatementID && (<div className="p-inputgroup" style={{padding:"1em"}}>
                        <button style={{ margin: '0',  padding: '.5em', paddingBottom:'1.5em', width:"12em" }}   onClick={(e) => syncWithQuickbooks()}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Sync to Quickbooks</button>
                    </div>)}
                </div>
            </div>
            {!inputValues.ID && (
                <div className="mbsc-row" style={{marginBottom:".5em"}}>
                    <Button 
                        color="primary" 
                        onClick={() => handleAddLineItem()} 
                        style={{
                            margin:"0", 
                            marginLeft:"1em",
                            marginTop:".5em",
                            paddingLeft:"1em !important", 
                            paddingBottom: ".2em", 
                            paddingTop: ".2em", 
                            height:"100%"
                        }}
                    >
                        Add Line Item
                    </Button>
                </div>
            )}
            {inputValues.LineItems && ( <div className='mbsc-row'>
                <table style={{ marginBottom: "5px", marginLeft:"1em", width: "95%", padding:"1.5em"}}>
                    <thead>
                 
                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                            {columns.map(col => col.visible && (
                                <th key={col.id} style={{ width: col.width }}>
                                    {col.header}
                                    {col.id === 'onHold' && inputValues.previewing && (
                                        <button style={{ margin: '0', padding: '.4em', width:"95%" }}  onClick={toggleAllOnHold}  className="mbsc-ios mbsc-btn-primary mbsc-btn"   >
                                            Toggle on Hold
                                        </button>
                                    )}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>

                    {(() => {
                            const lineItems = inputValues.LineItems;
                            return lineItems.map((lineItem, index) => {
                                const prevFBNO = index > 0 ? lineItems[index - 1].FBNO : null; // Previous FBNO
                                const nextFBNO = index < lineItems.length - 1 ? lineItems[index + 1].FBNO  : null; // Next FBNO

                                const borderTop = lineItem.FBNO !== prevFBNO ? "1px" : "0px";
                                const borderBottom = lineItem.FBNO !== nextFBNO ? "1px" : "0px";
                                lineItem.hideFBNO = borderTop==="0px" ? true : false;
                                const borderWidth = `${borderTop} 1px ${borderBottom} 1px`;
                                //console.log('index = ' + index)
                                return (
                                    <DPSLineItemList key={index} index={index} DPS={inputValues} company={inputValues.Company} lineItem={lineItem}  columns={columns}  toggleOnHold={toggleOnHold} borderWidth={borderWidth}  openExpensePopUp={openExpensePopUp}    updateLineItem={updateLineItem}    />
                                );
                            });
                        })()}
                    
                    </tbody>
                </table>
            </div>)}
            {inputValues.Totals && ( <div className='mbsc-row mbsc-grid'>
                <div className='mbsc-col-xl-5 mbsc-offset-xl-7'>
                    <table style={{ marginTop:"1em", marginBottom: "5px", width: "100%", paddingBottom:"1.5em", border:"2px, solid black"}}>
                        <thead>
                            <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                <th style={{ width: "33%" }}>Type</th>
                                <th style={{ width: "33%" }}>Qty</th>
                                <th style={{ width: "33%" }}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inputValues.Totals.map(( totalItem, index ) => (
                                <TotalItemList key={index} totalItem={totalItem} isPercent={totalItem.Type === 'Broker' || totalItem.Type==='Trailer Fees' } />
                            ))}
                        </tbody>
                    </table>
                </div>

            </div>)}
            {selectedExpense && ( <ExpensePopUp expense={selectedExpense}  visible={expensePopupVisible}  setVisible={setExpensePopupVisible}   />   )}
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </React.Fragment>
    );
};

export default PayStatementComponent;