import React, { useEffect, useState, useCallback, useRef } from 'react';



import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import  {  Page} from '@mobiscroll/react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';  
import { Panel } from 'primereact/panel';      

import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { UserAuth } from '../../../../context/AuthContext';
import { useGlobal } from '../../../../context/GlobalContext';

import {useDispatch } from '../../context/DispatchContext';
import { Button } from 'primereact/button';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { db } from '../../../../firebase';
import { doc, writeBatch} from 'firebase/firestore';

import InputTextParent from '../../../InputComponents/InputTextParent'; 
import AutoCompleteInput from '../../../InputComponents/AutoCompleteInput'; 
import LoadingOverlay from '../../../MiscComponents/LoadingOverlay';
import AutoSelectInputNumber from '../../../InputComponents/AutoSelectInputNumber'; 
import useFieldSpecificDebounce from '../../../../hooks/useFieldSpecificDebounce';
import useEffectNonNull from '../../../../hooks/useEffectNonNull';
import MapComponent from '../../../MiscComponents/MapComponent'; 
import NoteComponent from '../../../MiscComponents/NoteComponent'; 
import LocationInput from '../../../InputComponents/LocationInput';
import ExtraSitesPopUp from '../../PopUps/ExtraSitesPopUp';
import FreightSummaryLine from '../../Lists/FreightSummaryLine';
import AttachmentList from '../../Lists/AttachmentList';

import axios from 'axios';
import debounce from 'lodash/debounce';
import { useNavigate } from 'react-router-dom';

function DispatchEdit(props) {
    const navigate = useNavigate();
    const { showAccountPopUp, showMaterialPopUp, showTruckTypePopUp,showContactPopUp, showLocationPopUp} = useGlobal();
    const { gearedUser, locations, contacts, materials,truckTypes, accounts,companies,deepEqual,company, updateDocument, addAuditLog} = UserAuth();
    const { dispatch, handleUpdateFreightBills, deleteFreightBills, setDispatch, homeFreightBills, dispatchObjectRef,  isLoading,
 		jobDispatches, handleFreightClick} = useDispatch();
    const [inputValues, setInputValues] = useState(null);
   
    const [extraSitesVisible, setExtraSitesVisible] =useState(false);
    const [selectedDispatchDate, setSelectedDispatchDate] = useState(null);
    const freightBillsRef = useRef(null);

    
    const previousDumpSiteRef = useRef(null);
    const dispatchFreightBills = homeFreightBills.filter(freightBill => freightBill.dispatchID === dispatch?.ID);
    const mapComponentRef = useRef(null); // Create a ref for the MapComponent


    let selectDispatches = jobDispatches  ? jobDispatches.map(({ JobDate, ID, QueryDate }) => ({ QueryDate: QueryDate, name:JobDate, id:ID, text: JobDate, value: ID }))  : [];
    selectDispatches = selectDispatches.sort((a, b) => new Date(a.QueryDate) - new Date(b.QueryDate));


    let freightBills = dispatchFreightBills.sort((a, b) => a.loadOrder - b.loadOrder);
    console.log('freightBills = ', freightBills)
    const dispatchRef = useRef(null);

    useEffect(() => {
        if (dispatch?.ID) dispatchRef.current = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, dispatch.ID); 
    }, [dispatch?.ID]);
    useEffect(() => {
        if(inputValues){
         
            const fieldsToCheck= ['Shipper','Receiver','TruckType', 'Material'];
            const collections = { Shipper:accounts, Receiver:accounts, TruckType: truckTypes, Material: materials };
            let fieldsToUpdate = [];
            let valuesToUpdate=[];
            fieldsToCheck.forEach(field => {
                const selectedItem = inputValues[field];
                if (selectedItem) {
                    const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                    if (updatedItem)if(updatedItem.Name!==selectedItem.Name) {
                        fieldsToUpdate.push(field);
                        valuesToUpdate.push({ ...updatedItem });
                    } 
                }
            });
            console.log('fields to update NON LOCATIONS= ', fieldsToUpdate)
            console.log('fvaluesToUpdate NON LOCATIONS= ', valuesToUpdate)
            if(fieldsToUpdate.length>0) handleFieldsChange(fieldsToUpdate,valuesToUpdate , true);
            
        }
    }, [truckTypes, accounts,  materials]);

	useEffect(() => {
        if(inputValues){
         
            const fieldsToCheck= ['LoadSite','DumpSite'];
            const collections = { LoadSite:locations, DumpSite:locations};
            let fieldsToUpdate = [];
            let valuesToUpdate=[];
            fieldsToCheck.forEach(field => {
                const selectedItem = inputValues[field];
                if (selectedItem) {
                    const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
                    if (updatedItem)if(updatedItem.Name!==selectedItem.Name || updatedItem.fullAddress!==selectedItem.fullAddress) {
                        fieldsToUpdate.push(field);
                        valuesToUpdate.push({ ...updatedItem });
                    } 
                }
            });
            console.log('fields to update = ', fieldsToUpdate)
            console.log('fvaluesToUpdate= ', valuesToUpdate)
            if(fieldsToUpdate.length>0) handleFieldsChange(fieldsToUpdate,valuesToUpdate , true);
            if(company?.ExtraSites){
                console.log('WE ARE UPDAING THE EXTRA SITES = ',inputValues.ExtraSites)
                const locsToUpdate = ['LoadSite','DumpSite'];
                const locCollections = { LoadSite:locations, DumpSite:locations,};
                let tempExtraSites = inputValues.ExtraSites ? [...inputValues.ExtraSites]:[];
                let needToUpdate = false;
                for(let l=0; l<tempExtraSites.length; l++){
                    locsToUpdate.forEach(field => {
                        const selectedItem = tempExtraSites[l][field];
                        if (selectedItem) {
                            const updatedItem = locCollections[field]?.find(item => item.ID === selectedItem.ID);
							if (updatedItem)if(updatedItem.Name!==selectedItem.Name || updatedItem.fullAddress!==selectedItem.fullAddress) {
                                tempExtraSites[l][field]=updatedItem;
                                needToUpdate=true;
                            }
                        }
                    });
                }
                if(needToUpdate) handleFieldChange('ExtraSites',tempExtraSites , true);
            }
        }
    }, [locations]);
    useEffectNonNull(()=>{
        let unbilledMaterialCount = 0;
        let billable = true;
   
        if(inputValues?.MaterialCompany?.ID){
            if(inputValues?.SellMaterial && inputValues?.MaterialCompany?.ID !== inputValues?.Company.ID){
            
                for(let f=0; f<dispatchFreightBills.length; f++)if(!dispatchFreightBills[f].materialBilled && dispatchFreightBills[f].MaterialCompany?.ID!==dispatch.Company.ID)unbilledMaterialCount++;
            } 
            if((dispatch.unBilledExpenses + dispatch.unBilledFreights +unbilledMaterialCount)===0) billable = false;
            if(dispatch.unBilledMaterials!==unbilledMaterialCount || dispatch.Billable!==billable){
                console.log('WE ARE RUNNING THE USE EFFECT TO CHANGE UNBILLED MATERIAL COUNT AND BILLABLE ' + dispatch.Billable + ' count = ' + dispatch.unBilledMaterials);
                setDispatch((prev) => ({ ...prev, unBilledMaterials:unbilledMaterialCount, Billable:billable}));
                updateDocument({ unBilledMaterials:unbilledMaterialCount, Billable:billable}, dispatch.ID,'Dispatches')
            }
        }
      
    },[inputValues?.SellMaterial, inputValues?.MaterialCompany])
    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth < 767);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        console.log('things have changed should we get' + inputValues?.MaterialTaxable + ' and sell amt = ' + inputValues?.SellMaterial)
        if(inputValues?.SellMaterial && inputValues?.MaterialTaxable){
            if (inputValues?.DumpSite?.fullAddress !== null && inputValues?.DumpSite?.fullAddress !== undefined && previousDumpSiteRef?.current!== undefined && previousDumpSiteRef?.current!== null){
                console.log('things are not null, but we checkin this if statemnet ')
                if(inputValues?.DumpSite?.fullAddress!==previousDumpSiteRef?.current || !inputValues?.MaterialTaxRate) getTaxRate();
            }
            if (inputValues?.DumpSite?.fullAddress !== null && inputValues?.DumpSite?.fullAddress !== undefined)   previousDumpSiteRef.current = inputValues?.DumpSite?.fullAddress; 
            console.log('ok so we int eh iff stateemtn and inputValues?.DumpSite?.fullAddress = '  + inputValues?.DumpSite?.fullAddress )
        }
        
       
    }, [inputValues?.DumpSite?.fullAddress, inputValues?.MaterialTaxable, inputValues?.SellMaterial]);


    useEffect(() => {
        if (dispatch){
            console.log('we are runnign the dispatch use effect and its jobdate = ',dispatch)
            let tempDispatch={...dispatch};
            if(company.ExtraSites){
                if(!tempDispatch.ExtraSites){
                    tempDispatch.ExtraSites= [{LoadSite:{...tempDispatch.LoadSite}, DumpSite:{...tempDispatch.DumpSite}}];
                
                }else  tempDispatch.ExtraSites[0]={LoadSite:{...tempDispatch.LoadSite}, DumpSite:{...tempDispatch.DumpSite}};
             
            }
            console.log('we setInputValues = '+ dispatch.PONumber)
            setInputValues((prev) => ({ ...prev, ...tempDispatch}));
            dispatchObjectRef.current=dispatch;
            setSelectedDispatchDate(dispatch.ID);
        } 
    }, [dispatch, company]);

    useEffect(()=>{
        freightBillsRef.current=freightBills;
    },[freightBills])


    const foremanContacts  = inputValues && contacts ? contacts.filter(contact => contact.Account?.ID === inputValues.Shipper?.ID).map((contact) => (contact)) : [];
    const receiverForemanContacts  =  inputValues && contacts ? contacts.filter(contact => contact.Account?.ID === inputValues.Receiver?.ID).map((contact) => (contact)) : [];
    const billTypes=[{text:'Hour', value:'Hour'},{text:'Load', value:'Load'},{text:'Ton', value:'Ton'}]
    const materialBillTypes=[{text:'Ton', value:'Ton'},{text:'Load', value:'Load'},{text:'Yard', value:'Yard'}]
    const isDisabled= inputValues?.Released && !inputValues?.isChanging;
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 767);
    
    const onDragEnd = (result) => {
        if (!result.destination) return;  
  
        const reorderedItems = Array.from(dispatchFreightBills);
        const [removed] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, removed);
        handleUpdateFreightBills(dispatch, reorderedItems)
 
    };

    const updateDispatchField =async (fieldName, value, updateFreightBills) => {

        
        console.log('updating the disaptch field = ' + fieldName + ' and value = ' , value );
        const batch = writeBatch(db);
        batch.update(dispatchRef.current, { [fieldName]: value });
        if(updateFreightBills){
            console.log('upfreightBills.length = ' ,freightBillsRef.current);
            for(var i=0; i<freightBillsRef.current.length; i++){
                console.log('upfreightBills fbno = ' + freightBillsRef.current[i].FBNO + ' and its missing =  ' + freightBillsRef.current[i].missing  );
                let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   freightBillsRef.current[i].ID);
                let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',  freightBillsRef.current[i].ID);
            
               batch.update(freightRef, { "timestamp": Date.now(), [fieldName]: value  });
               batch.update(driverFreightRef, { "timestamp": Date.now(),[fieldName]: value, fromOfficeUser:true });
            }
        }
        await batch.commit();
    
       
    }

    const updateDispatchFields = async (fields, values, updateFreightBills) => {
        console.log('runnign teh update dispathc fields ',fields)
   
        console.log('values= ',values)
          const updateObject = {};
          fields.forEach((field, index) => {
            updateObject[field] = values[index];
          });
          updateObject.fromOfficeUser = true;
          updateObject.timestamp=Date.now();
          const batch = writeBatch(db);
          batch.update(dispatchRef.current, updateObject);
          if(updateFreightBills){
            console.log('the update object for the freights = ', updateObject)
              for(var i=0; i<freightBillsRef.current.length; i++){
           
                  let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   freightBillsRef.current[i].ID);
                  let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills', freightBillsRef.current[i].ID);
                  batch.update(freightRef,  updateObject);
                  batch.update(driverFreightRef, updateObject);
              }
          }
          await batch.commit();
     
        
       
    }

    const formatStartTime =useCallback(async  (starttime) =>{
         starttime= starttime.replace(/\./g,':');
         if (/^([01][0-9]|2[0-3])[0-5][0-9]$/.test(starttime)) starttime = starttime.substr(0, 2) + ':' + starttime.substr(2);
         else if (/^([0-9]|[0-3])[0-5][0-9]$/.test(starttime)) starttime= '0' + starttime.substr(0, 1) + ':' + starttime.substr(1);
         else if (/^([0-9]|[0-3]):[0-5][0-9]$/.test(starttime)) starttime = '0' + starttime.substr(0, 1) + ':' + starttime.substr(2, 3);
        assignLoadOrders('StartTime', starttime);
     },[dispatch]);


   

    const debouncedFieldUpdater = useFieldSpecificDebounce(500);
    const debouncedFormatTime = useCallback(debounce(formatStartTime, 500), [formatStartTime]);
 
    const handleFieldsChange = (fields, values, updateFreightBills) => {
        console.log('changing fields in dispatc!h')
        setInputValues(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++){
                newState[fields[i]] = values[i]; 
                console.log('values[i] = ', values[i])
            }

            return newState;
          });

        setDispatch(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
        });
      // updateDispatchFields(fields, values, updateFreightBills)
        const debouncedUpdate = debouncedFieldUpdater(fields, updateDispatchFields);
        debouncedUpdate(fields, values, updateFreightBills); // Pass `fields` and `values` dynamically
     
    };

    const handleFieldChange = (fieldName, value, updateFreightBills) => {
        console.log('fieldName= '+fieldName)
        console.log('value= ',value)
        console.log('updateFreightBills = '+ updateFreightBills)
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        setDispatch((prev) => ({ ...prev,  [fieldName]:  value }));
        const debouncedUpdate = debouncedFieldUpdater([fieldName], updateDispatchField);
        debouncedUpdate(fieldName, value, updateFreightBills); // Pass `fields` and `values` dynamically
        
    };

    const handleCheckBoxChange = (fieldName, value, updateDispatches) =>{
        console.log('checkbox chagning 11');
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
        setDispatch((prev) => ({ ...prev,  [fieldName]:  value }));
        updateDispatchField(fieldName, value, updateDispatches);
    }
  

    const handlePayTypeChange = async(payType)=>{
        handleFieldChange('PayType', payType, false)
        const batch = writeBatch(db);
        console.log('RUNNING CHANGE PAY TYPE ');
        for (var i = 0; i < freightBills.length; i++) {
            if (freightBills[i].Subhauler) freightBills[i].PayType = payType;
            else if (freightBills[i].PayType.includes("Percent")) freightBills[i].PayType = payType + '/Percent';
            let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   freightBills[i].ID);
            let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',  freightBills[i].ID);
            batch.update(freightRef, { "timestamp": Date.now(), PayType:payType });
            batch.update(driverFreightRef, { "timestamp": Date.now(),PayType: payType, fromOfficeUser:true });
        }
        await batch.commit();
     
    }
    
    const handleOutsidePayRateChange = useCallback(debounce(async(outsidePayRate)=>{
        console.log('changing the outside pay rateeeee!!')
        handleFieldChange('OutsidePayRate', outsidePayRate, false)
        const batch = writeBatch(db);
        for (var i = 0; i < freightBills.length; i++) {
            if (freightBills[i].Subhauler){
                 freightBills[i].PayRate = outsidePayRate;
                let freightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/FreightBills',   freightBills[i].ID);
                let driverFreightRef = doc(db, 'Organizations/'+ gearedUser.selectedOrgName +'/DriverFreightBills',  freightBills[i].ID);
                batch.update(freightRef, { "timestamp": Date.now(), PayRate:outsidePayRate });
                batch.update(driverFreightRef, { "timestamp": Date.now(),PayRate: outsidePayRate, fromOfficeUser:true });
            }
        }
        await batch.commit();
     
    },500),[])
    
    const handleDelete = async (id) => {
        try {
            // Immediately update the UI
            let updatedfreightBills = dispatchFreightBills.filter(fb => fb.ID !== id);
            let tempFreightbill = dispatchFreightBills.filter(fb => fb.ID === id);
            tempFreightbill = {...tempFreightbill[0]};
            let billable = false;
            let unbilledCount = 0;
            let unbilledMaterialCount = 0;
      
            for(let f=0; f<updatedfreightBills.length; f++){
                if(!updatedfreightBills[f].truckingBilled)unbilledCount++
                if(!updatedfreightBills[f].materialBilled && updatedfreightBills[f].MaterialCompany?.ID!==dispatch.Company.ID)unbilledMaterialCount++;
            }
            if((unbilledCount+unbilledMaterialCount)>0)billable=true;
            
            let fields =['TrucksAssigned','unBilledFreights', 'unBilledMaterials', 'Billable'];
            let values = [updatedfreightBills.length,unbilledCount, unbilledMaterialCount, billable];
           // setIsLoading(true);
            // Update local state immediately
            setInputValues(prevState => {
                let newState = { ...prevState };
                for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
                return newState;
            });

            setDispatch(prevState => {
                let newState = { ...prevState };
                for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
                return newState;
            });

            // Handle backend updates
            const batch = writeBatch(db);
            
            // Delete the freight bill
            const freightBillRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/FreightBills`, id);
            batch.delete(freightBillRef);
            
            // Update dispatch fields
            const dispatchRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, dispatch.ID);
            const updates = {};
            fields.forEach((field, index) => {
                updates[field] = values[index];
            });
            batch.update(dispatchRef, { ...updates, timestamp: Date.now() });
            addAuditLog('DeleteFreightBillFromDispatch', 'FreightBills', id, tempFreightbill);
            // Commit the batch and update freight bills
             batch.commit();
            await handleUpdateFreightBills(dispatch,updatedfreightBills);
         
            
        } catch (error) {
            console.error("Error during deletion: ", error);
            // Optionally handle error by reverting the UI or showing an error message
        }
    };

    const handleNoteChange =(noteField, text, quillNoteField, quill)=>{
        console.log('how does quill value get read = ', quill)
        let fields =[noteField,quillNoteField];
        let values = [text,quill];
    
       if(noteField==='Notes') handleFieldsChange(fields, values, true ); else handleFieldsChange(fields, values, false )
        //  updateDoc(dispatchRef, { [noteField]: text, [quillNoteField]:quill }).then(() => {}).catch((error) => { console.log('error updating driver =', error);   });
    }

    const changeDispatchDate = (e)=>{
        console.log('this is the value', e);
        let tempDispatch;
        for( let d=0; d<jobDispatches.length; d++){
            if(jobDispatches[d].ID===e.value)tempDispatch={...jobDispatches[d]}
        }
        if(tempDispatch){
            previousDumpSiteRef.current=null;
            const encodedDate = encodeURIComponent(tempDispatch.QueryDate); 
            navigate(`/dispatch/edit/${tempDispatch.ID}/${tempDispatch.JobID}/${encodedDate}/DispatchEdit`);
            setDispatch(tempDispatch);
            

        }
        setSelectedDispatchDate(e.value)
    }

    const changeStartTime = (starttime) =>{
        setInputValues((prev) => ({ ...prev, StartTime: starttime }));
        debouncedFormatTime(starttime);
    }

    const changeMaterialRateType = (e)=>{
        if(e.value==='Ton' || e.value==='Yard'){
            let fields=  ['WeightType','MaterialRateType'];
            let values = [e.value,e.value];
            handleFieldsChange(fields,values , true);
        }else   handleFieldChange('MaterialRateType', e.value, true);
       
      
        handleDropdownChange(e);
    }
    const assignLoadOrders = (fieldName, value) =>{
        console.log('fifty fifth shtreet', dispatch)
        console.log('fromated value = '+ value);
        console.log('fieldnAme = '+ fieldName)
        if(dispatch){
            let updatedDispatch = { ...dispatch, [fieldName]: value };   
            setInputValues((prev) => ({ ...prev, [fieldName]: value }));
            handleUpdateFreightBills(updatedDispatch,freightBillsRef.current)
            setDispatch((prev) => ({ ...prev, [fieldName]: value }));
            updateDispatchField(fieldName,value, false);
        }
    }
    const checkDeleteFreightBills = ()=>{
        if (window.confirm('Are you sure you would like to delete ALL Freight Bills on this Dispatch?')) {
            deleteFreightBills();
        }
    }
    const focusNextElement = (currentElement) => {
        console.log('nmext element', currentElement)
        const focusableElements = ' input:not([disabled]), select:not([disabled]), textarea:not([disabled]) ';
        const elements = Array.from(document.querySelectorAll(focusableElements));
        const currentIndex = elements.indexOf(currentElement);
        if (currentIndex > -1 && currentIndex < elements.length - 1) {
            elements[currentIndex + 1].focus();
        }
    };

    const handleKeyDown = (event) => {

        if (event.key === 'Enter' ) {
            focusNextElement(event.target);
            event.preventDefault();
        }
    };

    const clickUpload = () =>{
        console.log(' document.getElementById(freightUpload) =' , document.getElementById('attachUpload'))
        document.getElementById('attachUpload').click();
    }
  

    const getTaxRate = async () => {
        const object = { ...inputValues };
        console.log('Starting the get tax rate boiiis! and object = ', inputValues);
        const tempAccount = accounts.filter(acc => acc.ID === object.Account.ID);
        const tempQuickbook = tempAccount[0].Quickbooks.filter(qb=> qb.realmID === company.realmID);
        console.log('tempAccount[0]= ', tempAccount[0]);
        const QBCustomerID= tempQuickbook[0] ? tempQuickbook[0].QBCustomerID ? tempQuickbook[0].QBCustomerID: '' : '';
        const data = {
            DumpSite: object.DumpSite,
            QBCustomerID:QBCustomerID,
            JobNumber: inputValues.JobNumber,
            OrganizationName: gearedUser.selectedOrgName,
            realmID: company.realmID
        };

        try {
            const response = await axios.post('https://geared.tech/getTaxRate', data, {
                headers: { 'Content-Type': 'application/json' }
            });

            if (response.data.info) {
                const fakeInvoice = response.data.info.Invoice;
                let tempTaxRate = 0;

                for (const taxLine of fakeInvoice.TxnTaxDetail.TaxLine) {
                    tempTaxRate += taxLine.TaxLineDetail.TaxPercent;
                    console.log('TaxLineDetail = ', taxLine.TaxLineDetail);
                }

                if (tempTaxRate > 0)   handleFieldChange( 'MaterialTaxRate',tempTaxRate, true)
                
            
            }

            console.log('Finished getting that tax rate boiis! Response = ', response);

        } catch (error) {
            console.error('Error getting Tax Rate = ', error);
        }
    };

    const accordionHeader = (headerText, button) => (options) => {
       // const className = `${options.className} `;
        let style ={padding:"0"};
        if(inputValues.Released){
            if(inputValues.Cancelled)style.backgroundColor='#d93025';
            else if(inputValues.isChanging) style.backgroundColor='#62a8f5';
            else style.backgroundColor='#c5ddba';
        }
        return (
            <>
                {button ? (

                    <div className="mbsc-row" style={{display:"flex", alignItems:"center"}}>
                        <div className="mbsc-col-7">
                            {headerText} 
                            {inputValues.Released && !inputValues.Cancelled && (<span> (Released)</span>)}
                            {inputValues.Cancelled && (<span> (Cancelled)</span>)}
                        </div>
                       {headerText==='Assigned Drivers' ? ( <div  className="mbsc-col-5"><button   className="md-btn " id="removeAllDriverButton"  onClick={() =>  checkDeleteFreightBills()}  >Remove All Drivers</button> </div>):
                       (<div  className="mbsc-col-5"><button   className="md-btn "  onClick={() =>  clickUpload()}  >Upload Attachment</button> </div>)}

                    </div>
                    ):(  
                        <span style={{display:"flex", alignItems:"center"}}>
                            {headerText} 
                            {inputValues.Released && !inputValues.Cancelled && (<span> (Released)</span>)}
                            {inputValues.Cancelled && (<span> (Cancelled)</span>)}
                        </span>
　　 　 　 　
                    )}
                 
            </>
             
        );
    }

    const panelHeader = (headerText, button) => (options) => {
        const className = `${options.className} justify-content-space-between`;
        let style ={padding:".5em", paddingLeft:"1.5em"};
        if(inputValues.Released){
            if(inputValues.Cancelled)style.backgroundColor='#d93025';
            else if(inputValues.isChanging) style.backgroundColor='#62a8f5';
            else style.backgroundColor='#c5ddba';
        }
        return (
            <div className={className} style={style} >
                <div className="mbsc-row" style={{width:"100%"}}>
                    <h6 className="m-0 font-weight-bold text-secondary mbsc-col-8" style={{ paddingLeft:"1.5em", fontSize:"1.1em",margin:"0", fontWeight:"700"}}>
                        {headerText} 
                        {inputValues.Released && !inputValues.Cancelled && (<span> (Released)</span>)}
                        {inputValues.Cancelled && (<span> (Cancelled)</span>)}
                    </h6>
                   {button && (<div  className="mbsc-col-4" style={{padding:"0"}}>
                    {headerText==='Assigned Drivers' ? (  <button  style={{cursor:"pointer"}} className="md-btn " id="removeAllDriverButton"  onClick={() =>  checkDeleteFreightBills()}  >Remove All Drivers</button> ):
                       
                       (<> {headerText==='Attachments' ? ( <button    className="md-btn "  style={{cursor:"pointer"}} onClick={() =>  clickUpload()}  >Upload Attachment</button>):
                       ( <Button style={{float:"right", fontSize:".8em",height:"2em"}} label="Edit Sites" icon="pi pi-pencil"  onClick={() => setExtraSitesVisible(true)} />)}</>)}
                  

                    
                    </div>)}
             
                </div>
            </div>
        );
    };

    const handleDropdownChange = (e)=>{
     
        const allElements = Array.from(document.querySelectorAll('div, input, select, textarea'));
        let nextElement =  document.getElementById(e.target.id).parentElement.nextElementSibling;

        const currentIndex = allElements.indexOf(nextElement);
        if (currentIndex > -1 && currentIndex < allElements.length - 1) {
            // Iterate through the elements starting from the next index
            for (let i = currentIndex; i < allElements.length; i++) {
                const element = allElements[i];
    
                // Check if the element is focusable
                if (element.matches('input:not([disabled]), select:not([disabled]), textarea:not([disabled])')) {
                    element.focus(); // Focus the element
                    console.log('Focused element =', element);
                    break; // Stop once a focusable element is found
                }
            }
        }
    }
    const dispatchDetailsContent = () =>{
        return(
            <div  className="mbsc-form-group" style={{margin:"0"}}> 
                <div className="p-inputgroup" >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Date</span> 
                    <Dropdown value={selectedDispatchDate} onChange={(e) => changeDispatchDate(e)}  options={selectDispatches} optionLabel="text"
                        placeholder="Job Date" className="w-full md:w-14rem" />
                </div>

                <AutoCompleteInput label="Customer" fieldName="Account" field="Name" value={inputValues.Account} suggestions={accounts} setValue={setInputValues} 
                handleFieldChange={handleFieldChange}  databaseList={'Accounts'} disabled={true} />

                <div className="p-inputgroup" >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job # </span> 
                        <InputTextParent  value={inputValues.JobNumber}  onChange={(e) => handleFieldChange('JobNumber', e.target.value, true)} disabled={isDisabled} />
                </div>
                <div className="p-inputgroup" >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Job Type </span> 
                        <InputTextParent  value={inputValues.JobType}  onChange={(e) => handleFieldChange('JobType', e.target.value, false)}  disabled={isDisabled}/>
                </div>
                <AutoCompleteInput fieldName="Company" field="CompanyName" value={inputValues.Company} suggestions={companies}  setValue={setInputValues}  handleFieldChange={handleFieldChange}   />
                <AutoCompleteInput label="Truck Type" fieldName="TruckType" field="Name" value={inputValues.TruckType} suggestions={truckTypes}   setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckTypePopUp(inputValues.TruckType)}  databaseList={'TruckTypes'}  disabled={isDisabled}/>
                
                {company.ExtraSites && inputValues.ExtraSites ? (
                    <Panel style={{margin:"0"}} headerTemplate={panelHeader("Load and Dump Sites", true)}>
                        {inputValues.ExtraSites.length>1 ? (<>
                        {inputValues.ExtraSites.map((item, index) => (
                            <div  key={index}>
                                <div className="p-inputgroup " style={{padding:"0", fontSize:".8em"}}>
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Load </span> 
                                        <InputTextParent  value={item.LoadSite.Name}   disabled={true}/>
                                </div>
                                <div className="p-inputgroup" style={{padding:"0", fontSize:".8em"}}>
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Dump </span> 
                                        <InputTextParent  value={item.DumpSite.Name}   disabled={true}/>
                                </div>
                            </div>  
                        ))}
                    </>):( <>
                        <LocationInput object={inputValues} label="Load Site" fieldName="LoadSite" field="Name"  setValue={setInputValues} handleFieldChange={handleFieldChange} showPopUp={()=> showLocationPopUp(inputValues.LoadSite)}></LocationInput>
                        <LocationInput object={inputValues} label="Dump Site" fieldName="DumpSite" field="Name"  setValue={setInputValues} handleFieldChange={handleFieldChange} showPopUp={()=> showLocationPopUp(inputValues.DumpSite)}></LocationInput>
                    </>)}
                    </Panel>):(
                <>
                    <LocationInput object={inputValues} label="Load Site" fieldName="LoadSite" field="Name"  setValue={setInputValues} handleFieldChange={handleFieldChange} showPopUp={()=> showLocationPopUp(inputValues.LoadSite)}></LocationInput>
                    <LocationInput object={inputValues} label="Dump Site" fieldName="DumpSite" field="Name"  setValue={setInputValues} handleFieldChange={handleFieldChange} showPopUp={()=> showLocationPopUp(inputValues.DumpSite)}></LocationInput>

                </>)}
                {company.SellMaterial? (
                    <div className='mbsc-row' style={{margin:'0'}} >
                        <div className="mbsc-col-lg-8" style={{padding:'0'}}>
                        <AutoCompleteInput labelClass="material-label" label="Material" fieldName="Material" field="Name" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)}   databaseList={'Materials'} />

                        </div>
                        <div className="p-inputgroup mbsc-col-lg-4" style={{padding:'0'}}>
                            <span className="p-inputgroup-addon " style={{flexBasis:"80%"}}>Sell Material</span>
                            <Checkbox style={{ width: '100%' }} onChange={(e) => handleCheckBoxChange('SellMaterial', e.checked, true)}  checked={inputValues.SellMaterial}   />
                        </div>
                    </div>):( <AutoCompleteInput label="Material" fieldName="Material" field="Name" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)}   disabled={isDisabled} databaseList={'Materials'} />)}

                    {inputValues.SellMaterial &&(<Panel style={{margin:".75em"}} headerTemplate={panelHeader("Sell Material Details")}>
                        {sellMaterialContent()}
                    </Panel>)}
                <AutoCompleteInput fieldName="Shipper" field="Name" value={inputValues.Shipper} suggestions={accounts} setValue={setInputValues} handleFieldChange={handleFieldChange}  editClick={() => showAccountPopUp(inputValues.Shipper)}  databaseList={'Accounts'} disabled={isDisabled}/>
                <AutoCompleteInput fieldName="Receiver" field="Name" value={inputValues.Receiver} suggestions={accounts} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showAccountPopUp(inputValues.Receiver)}   databaseList={'Accounts'} disabled={isDisabled}/>
                
                {foremanContacts.length!==0 && (<AutoCompleteInput label="Shipper Foreman" fieldName="Foreman" field="Name" value={inputValues.Foreman} suggestions={foremanContacts} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showContactPopUp(inputValues.Foreman)}   databaseList={'Contacts'} disabled={isDisabled}/>)}
                {inputValues.Foreman?.displayPhone && ( 
                    
                    <div className="p-inputgroup " >
                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Ship. Foreman Phone</span> 
                        <InputTextParent  value={inputValues.Foreman.displayPhone}   disabled={true} />
                    </div>
                )}
                {receiverForemanContacts.length!==0 && (<AutoCompleteInput label="Rec. Foreman" fieldName="ReceiverForeman" field="Name" value={inputValues.ReceiverForeman} suggestions={receiverForemanContacts} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showContactPopUp(inputValues.ReceiverForeman)}   databaseList={'Contacts'} disabled={isDisabled}/>)}
                {inputValues.ReceiverForeman?.displayPhone && ( 
                    <div className="p-inputgroup " >
                        <span className="p-inputgroup-addon dispatch-inputgroup"  >Rec. Foreman Phone</span> 
                        <InputTextParent  value={inputValues.ReceiverForeman.displayPhone}   disabled={true}  />
                    </div>
                )}
            
                <div className="mbsc-row" style={{ margin: "0px" }}>
                    <div className="mbsc-col-lg-6 mbsc-col-12" style={{padding:"0"}}> 
                        <div className="p-inputgroup " id="billTypeInputGroup">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup">Bill Type</span>
                            <Dropdown value={inputValues.BillType} id="billTypeDropdown" onChange={(e) => {handleFieldChange('BillType', e.value, true); handleDropdownChange(e)}}  options={billTypes} optionLabel="text" disabled={isDisabled}
                                placeholder="Bill Type" className="w-full md:w-14rem" />
                        </div>
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Bill Rate</span> 
                            <AutoSelectInputNumber  isCurrency={true}  placeholder="Enter a number" value={inputValues.BillRate}   onChange={(e) => handleFieldChange('BillRate', e.value, true)}  disabled={isDisabled} />
                        </div>
                        

                        <div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup">Pay Type</span>
                            <Dropdown value={inputValues.PayType} id="payTypeDropdown" onChange={(e) =>{ handlePayTypeChange( e.value); handleDropdownChange(e)}} options={billTypes} optionLabel="text" disabled={isDisabled}
                                placeholder="Pay Type" className="w-full md:w-14rem" />
                        </div>
                
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Rate</span> 
                            <AutoSelectInputNumber isCurrency={true} value={inputValues.PayRate}   onChange={(e) => handleFieldsChange(['PayRate', 'OutsidePayRate'], [e.value, e.value], true)}  disabled={isDisabled}/>
                        </div>
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Outside Pay Rate</span> 
                            <AutoSelectInputNumber  isCurrency={true}  value={inputValues.OutsidePayRate}   onChange={(e) => handleOutsidePayRateChange(e.value)}  disabled={isDisabled}/>
                        </div>
                        
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Start Time</span> 
                            <InputTextParent  value={inputValues.StartTime} maxLength={5} onChange={(e) =>changeStartTime(e.target.value)} disabled={isDisabled} />
                        </div> 
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Min Between Load</span> 
                            <AutoSelectInputNumber   value={inputValues.MinBetLoad}  onChange={(e) => assignLoadOrders('MinBetLoad', e.value)} disabled={isDisabled} />
                        </div> 
                        <div className="p-inputgroup" >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >
                                <FontAwesomeIcon className="fas" icon={faTruck} style={{  width:"1em", paddingRight:".2em"}}/>
                                Per Load
                            </span> 
                            <AutoSelectInputNumber    value={inputValues.SimLoad}  onChange={(e) => assignLoadOrders('SimLoad', e.value)}  disabled={isDisabled}/>
                        </div> 

                        <div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup" >Hide Map</span>
                            <Checkbox style={{ width: '100%' }}  onKeyDown={(e) => handleKeyDown(e)}  onChange={(e) => handleCheckBoxChange('hideMapTab', e.checked, true)}  checked={inputValues.hideMapTab}  disabled={isDisabled} />
                        </div> 
                    </div>
                    <div className="mbsc-col-lg-6 mbsc-col-12" style={{padding:"0"}}> 
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Broker Fee %</span> 
                            <AutoSelectInputNumber    value={inputValues.BrokerFee}   onChange={(e) => handleFieldsChange(['BrokerFee','billedBrokerPercent'], [e.value,e.value], true)}  disabled={isDisabled}/>
                        </div>
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Fuel Charge %</span> 
                            <AutoSelectInputNumber   value={inputValues.FuelCharge}   onChange={(e) => handleFieldChange('FuelCharge', e.value, true)}  disabled={isDisabled}/>
                        </div>
                        <div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup" style={{ flexBasis: '65%'}}>Apply Broker Fee to Fuel</span>
                            <Checkbox style={{ width: '100%' }}  onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => handleCheckBoxChange('billBrokerFuel', e.checked, true)}  checked={inputValues.billBrokerFuel}  disabled={isDisabled} />
                        </div> 
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Hour Min</span> 
                            <AutoSelectInputNumber   value={inputValues.HourMin}   onChange={(e) => handleFieldChange('HourMin', e.value, true)}  disabled={isDisabled}/>
                        </div>
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Show Up</span> 
                            <AutoSelectInputNumber   value={inputValues.ShowUp}   onChange={(e) => handleFieldChange('ShowUp', e.value, true)}  disabled={isDisabled}/>
                        </div>
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  >
                                <FontAwesomeIcon className="fas" icon={faTruck} style={{  width:"1em", paddingRight:".2em"}}/> 
                                Ordered
                            </span> 
                            <AutoSelectInputNumber    value={inputValues.TrucksOrdered}   onChange={(e) => handleFieldChange('TrucksOrdered', e.value, false)} disabled={isDisabled} />
                        </div>
                        <div className="p-inputgroup " >
                            <span className="p-inputgroup-addon dispatch-small-inputgroup"  > <FontAwesomeIcon className="fas" icon={faTruck} style={{  width:"1em", paddingRight:".2em"}}/> Assigned  </span> 
                            <AutoSelectInputNumber  value={inputValues.TrucksAssigned}  disabled={true} onChange={(e) => handleFieldChange('TrucksAssigned', e.value, false)} />
                        </div>
                        <div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup" >On Site</span>
                            <Checkbox style={{ width: '100%' }} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => handleCheckBoxChange('OnSite', e.checked, true)}  checked={inputValues.OnSite}  disabled={isDisabled} />
                        </div>  
                        <div className="p-inputgroup ">
                            <span className="p-inputgroup-addon dispatch-small-inputgroup" >On Hold</span>
                            <Checkbox style={{ width: '100%' }} onKeyDown={(e) => handleKeyDown(e)} onChange={(e) => handleCheckBoxChange('OnHold', e.checked, true)}  checked={inputValues.OnHold}   disabled={isDisabled}/>
                        </div> 
                    </div>   
                </div>     
            </div>
        )
    }
    const standByContent = () => {
        return(
            <div  className="mbsc-form-group mbsc-row" style={{margin:"0"}}> 
                <div className="mbsc-col-lg-6 mbsc-col-12" style={{padding:"0"}}> 
                    <div className="p-inputgroup " >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Load Allowed</span> 
                        <AutoSelectInputNumber   value={inputValues.standLA}   onChange={(e) => handleFieldChange('standLA', e.value, true)}  disabled={isDisabled}/>
                    </div>
                    <div className="p-inputgroup " >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Dump Allowed</span> 
                        <AutoSelectInputNumber   value={inputValues.standDA}   onChange={(e) => handleFieldChange('standDA', e.value, true)} disabled={isDisabled} />
                    </div>
                </div>
                <div className="mbsc-col-lg-6 mbsc-col-12" style={{padding:"0"}}> 
                    <div className="p-inputgroup " >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Bill Rate</span> 
                        <AutoSelectInputNumber   value={inputValues.standBR}   onChange={(e) => handleFieldChange('standBR', e.value, true)}  disabled={isDisabled}/>
                    </div>
                
                    <div className="p-inputgroup" >
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Rate</span> 
                        <AutoSelectInputNumber   value={inputValues.standPR}   onChange={(e) => handleFieldChange('standPR', e.value, true)}  disabled={isDisabled}/>
                    </div>
                </div>
        </div>
        )
    }
    const sellMaterialContent = () => {
        return(
            <div  className="mbsc-form-group" style={{margin:"0"}}> 
                    <AutoCompleteInput label="Mat. Company" fieldName="MaterialCompany" field="CompanyName" value={inputValues.MaterialCompany} suggestions={companies}  setValue={setInputValues}  handleFieldChange={handleFieldChange}   />
                    <div className="mbsc-row">
               
                    <div className="mbsc-col-xl-6 p-inputgroup" style={{padding:"0"}}  id="materialBillTypeInputGroup">
                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate Type</span>
                        <Dropdown value={inputValues.MaterialRateType} id="materialBillTypeDropdown" onChange={(e) => {changeMaterialRateType(e)}}  options={materialBillTypes} optionLabel="text" disabled={isDisabled}
                            placeholder="Rate Type" className="w-full md:w-14rem" />
                    </div>
            
                    <div className="p-inputgroup mbsc-col-xl-6" style={{padding:"0"}}>
                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  > Rate</span> 
                        <AutoSelectInputNumber   value={inputValues.MaterialRate}   onChange={(e) => handleFieldChange('MaterialRate', e.value, true)}  disabled={isDisabled}/>
                    </div>
                
            </div>
            <div className="mbsc-row">
                <div className="p-inputgroup mbsc-col-xl-6" style={{padding:'0'}}>
                    <span className="p-inputgroup-addon " style={{flexBasis:"75%"}}>Taxable</span>
                    <Checkbox style={{ width: '100%' }} onChange={(e) => handleCheckBoxChange('MaterialTaxable', e.checked, true)}  checked={inputValues.MaterialTaxable}   />
                </div>
                {inputValues.MaterialTaxable &&(<div className="p-inputgroup mbsc-col-xl-6" style={{padding:"0"}}>
                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Tax Rate</span> 
                    <AutoSelectInputNumber   value={inputValues.MaterialTaxRate}   onChange={(e) => handleFieldChange('MaterialTaxRate', e.value, true)}  disabled={isDisabled}/>
                </div>
                )}
            </div>
        
        </div>
        )
    }
    const assignedDriversContent = () => {
        return(
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="freightBills">
                {(provided) => (
                    <ul className='tableList' style={{width:"100%"}} ref={provided.innerRef} {...provided.droppableProps}>
                        {freightBills.map((item, index) => (
                            <Draggable  key={item.ID} draggableId={item.ID} index={index} isDragDisabled={inputValues.Released && !inputValues.isChanging}>
                                {(provided) => (
                                     
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                          
                                        <FreightSummaryLine dispatch={inputValues} freight={{ item }} index={{ index }} onDelete={handleDelete} onClick={handleFreightClick} />
                                    </div>
                                )}
                            </Draggable>
                      

                        ))}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
        )
    }

    const renderPanels = () => (
        <>
            <Panel headerTemplate={panelHeader("Dispatch Details")}>
                {dispatchDetailsContent()}
            </Panel>
            <Panel headerTemplate={panelHeader("Stand By")}>
                {standByContent()}
            </Panel>
         
              </>
         
    );
    const renderSecondaryPanels = () => (
        <>
           <Panel headerTemplate={panelHeader("Assigned Drivers", true)}>
                {assignedDriversContent()}
              </Panel>
            <Panel headerTemplate={panelHeader("Driver Notes")}>
                <NoteComponent  parent={inputValues} noteType={'Notes'} quillName={'QuillDriverNotes'} onNoteChange={handleNoteChange}  disabled={isDisabled}></NoteComponent>      
            </Panel>
            <Panel headerTemplate={panelHeader("Attachments", true)}>
                <AttachmentList dispatch={dispatch}/>
            </Panel>
            <Panel headerTemplate={panelHeader("Internal Notes")}>
                <NoteComponent parent={inputValues} noteType={'InternalNotes'} quillName={'QuillInternalNotes'} onNoteChange={handleNoteChange}  disabled={isDisabled}></NoteComponent>      
            </Panel>
            <Panel headerTemplate={panelHeader("Map")}>
                <MapComponent ref={mapComponentRef} dispatch={dispatch} handleFieldsChange={handleFieldsChange}></MapComponent>      
            </Panel>
        </>
    );
    const renderAccordionTabs = () => (
        <Accordion multiple activeIndex={[0,1,2,3,4,5,6]}>
            <AccordionTab headerTemplate={accordionHeader("Dispatch Details")}>
                {dispatchDetailsContent()}
            </AccordionTab>
            <AccordionTab headerTemplate={accordionHeader("Stand By")}>
                {standByContent()}
            </AccordionTab>
            <AccordionTab  headerTemplate={accordionHeader("Assigned Drivers", true)}>
                {assignedDriversContent()}
            </AccordionTab>
            <AccordionTab headerTemplate={accordionHeader("Driver Notes")}>
                <NoteComponent parent={inputValues} noteType={'Notes'} quillName={'QuillDriverNotes'} onNoteChange={handleNoteChange}  disabled={isDisabled}></NoteComponent>   
            </AccordionTab>
            <AccordionTab headerTemplate={accordionHeader("Attachments" , true)}>
                <AttachmentList dispatch={dispatch}/>
            </AccordionTab>
            <AccordionTab headerTemplate={accordionHeader("Internal Notes")}>
                <NoteComponent parent={inputValues} noteType={'InternalNotes'} quillName={'QuillInternalNotes'} onNoteChange={handleNoteChange}  disabled={isDisabled}></NoteComponent>    
            </AccordionTab>
            <AccordionTab headerTemplate={accordionHeader("Map")}>
                <MapComponent ref={mapComponentRef} dispatch={dispatch} handleFieldsChange={handleFieldsChange}></MapComponent>      
            </AccordionTab>
        </Accordion>
    );
    if (!inputValues ) {
        return <div>Loading...</div>;
    }

    return (
    <Page>
        <div className="mbsc-grid" style={{padding:"0"}}> 
            <div className="mbsc-row"  style={{margin:"0"}}>
            {isMobileView ? (
                <div className="mbsc-col-12" style={{ padding: "0" }}> {renderAccordionTabs()} </div>): 
                (<>
                    <div className="mbsc-col-lg-6 mbsc-md-12" style={{ padding: "0" }}>
                        {/* Show the cancelled image conditionally */}
                        {dispatch?.Cancelled && (<img style={{ top: "200px", left: "0px", position: "absolute", zIndex: "9999" }} src="https://firebasestorage.googleapis.com/v0/b/alianza-47fa6.appspot.com/o/Cancelled.png?alt=media&token=57d5f24e-e280-4083-b21e-e1505b5cb430" alt="Cancelled"/>)}
                        {renderPanels()}
                    </div>
                    <div className="mbsc-col-lg-6 mbsc-md-12" style={{ padding: "0" }}>
                        {renderSecondaryPanels()}
                    </div>
                </>)}
            </div>
        </div>
        {inputValues.ExtraSites &&(<ExtraSitesPopUp setVisible={setExtraSitesVisible} handleFieldsChange={handleFieldsChange} setInputValues={setInputValues} inputValues={inputValues} visible={extraSitesVisible} />)}
        <LoadingOverlay isLoading={isLoading} message="Please wait..." />
    </Page>
    );
}

export default DispatchEdit;