import React from 'react';
import { InputNumber } from 'primereact/inputnumber';

const AutoSelectInputNumber = ({ onFocus, isCurrency, value, dontUseGrouping, ...props }) => {
  //  console.log('we loading an auto select input number');
    const minFractionDigits = isCurrency ? 2 : 0;
    
    const handleFocus = (e) => {
        e.target.select();
        if (onFocus) {
            onFocus(e); // Call any existing onFocus if provided
        }
    };
    const focusNextElement = (currentElement) => {
        const focusableElements = 'button, [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])';
        const elements = Array.from(document.querySelectorAll(focusableElements));
        const currentIndex = elements.indexOf(currentElement);
        if (currentIndex > -1 && currentIndex < elements.length - 1) {
            elements[currentIndex + 1].focus();
        }
    };

    const handleKeyDown = (event) => {

     if (event.key === 'Enter' ) {
            focusNextElement(event.target);
            event.preventDefault();
        }
    };
    // Convert empty string to null
    const processedValue = value !== "" ? value : null;

    return <InputNumber {...props} useGrouping={!dontUseGrouping} value={processedValue}  onKeyDown={(e) => handleKeyDown(e)} minFractionDigits={minFractionDigits} onFocus={handleFocus} />;
};

export default AutoSelectInputNumber;
