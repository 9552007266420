import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { UserAuth } from '../../../context/AuthContext';
import { getStorage, ref, uploadString, getDownloadURL } from "firebase/storage";

export const useXML = () => {
	const { gearedUser, company, formatDate } = UserAuth();
	const storage = getStorage();

	const uploadXML = async (xmlDoc, reportWeek, prevailingWageReport) => {
		try {
			// Create a reference to the storage location
			const xmlStorageRef = ref(storage, `attachments/${gearedUser.selectedOrgName}/PrevailingWage/${prevailingWageReport.ID}/XML_${reportWeek.Docs.length}.xml`);

			// Upload the XML string
			await uploadString(xmlStorageRef, xmlDoc, 'raw');

			// Get the download URL
			const downloadURL = await getDownloadURL(xmlStorageRef);

			// Update the reportWeek document with the new XML URL
			const tempReportWeek = {...reportWeek};
			if (!tempReportWeek.Docs) {
				tempReportWeek.Docs = [];
			}
			
			// Add the XML URL to the docs array
			tempReportWeek.Docs.push({
				VNum: tempReportWeek.Docs.length + 1,
				xmlURL: downloadURL
			});

			// Update the document in Firestore
			const setDocRef = doc(db, 'Organizations',	gearedUser.selectedOrgName,	'PrevailingWageReports',	prevailingWageReport.ID,'Weeks',	reportWeek.ID);
			console.log('downloadURL = ', downloadURL)
			await setDoc(setDocRef, tempReportWeek);
			return {
				success: true,
				downloadURL,
				message: 'XML file created and uploaded successfully'
			};

		} catch (error) {
			console.error('Error creating/uploading XML:', error);
			return {
				success: false,
				error: error.message,
				message: 'Failed to create/upload XML file'
			};
		}

	}
	
	const createXML = async (reportWeek, prevailingWageReport) => {
		try {
			// Create XML structure
			let xmlDoc = `<?xml version="1.0" encoding="UTF-8"?>
			<CPR xmlns:CPR="http://www.dir.ca.gov/dlse/CPR-Prod-Test/CPR.xsd">
				<CPR:contractorInfo>
					<CPR:contractorName>${company.CompanyName}</CPR:contractorName>
					<CPR:contractorLicense>
						<CPR:licenseType>OTHER</CPR:licenseType>
						<CPR:licenseNum>California Motor Carrier Permit: ${prevailingWageReport.ContractNumber || ''}</CPR:licenseNum>
					</CPR:contractorLicense>
					<CPR:contractorPWCR>NA</CPR:contractorPWCR>
					<CPR:contractorFEIN>${company.FEIN || ''}</CPR:contractorFEIN>
					<CPR:contractorAddress>
						<CPR:street>${company.Address || ''}</CPR:street>
						<CPR:city>${company.City || ''}</CPR:city>
						<CPR:state>${company.State || ''}</CPR:state>
						<CPR:zip>${company.ZipCode || ''}</CPR:zip>
					</CPR:contractorAddress>
					<CPR:insuranceNum></CPR:insuranceNum>
					<CPR:contractorEmail>${company.SupportEmail || ''}</CPR:contractorEmail>
				</CPR:contractorInfo>
				<CPR:projectInfo>
					<CPR:awardingBody/>
					<CPR:contractAgencyID/>
					<CPR:contractAgency>${prevailingWageReport.Account.Name || ''}</CPR:contractAgency>
					<CPR:projectName></CPR:projectName>
					<CPR:projectID></CPR:projectID>
					<CPR:awardingBodyID/>
					<CPR:projectNum/>
					<CPR:contractID></CPR:contractID>
					<CPR:projectLocation>
						<CPR:description>${prevailingWageReport.ProjectLocation || ''}</CPR:description>
						<CPR:street/>
						<CPR:city/>
						<CPR:county/>
						<CPR:state/>
						<CPR:zip/>
					</CPR:projectLocation>
				</CPR:projectInfo>
				<CPR:payrollInfo>
					<CPR:statementOfNP>false</CPR:statementOfNP>
					<CPR:payrollNum></CPR:payrollNum>
					<CPR:amendmentNum/>
					<CPR:forWeekEnding>${formatDate(new Date(reportWeek.weekEndDate),'-','YYYY/MM/DD')}</CPR:forWeekEnding>
					<CPR:employees>`;

					// Add each employee
					reportWeek.Drivers.forEach(driver => {
						xmlDoc += `
						<CPR:employee>
							<CPR:name id="${driver.SSN || ''}">${driver.Name}</CPR:name>
							<CPR:address>
								<CPR:street>${driver.Address || ''}</CPR:street>
								<CPR:city>${driver.City || ''}</CPR:city>
								<CPR:state>${driver.State || ''}</CPR:state>
								<CPR:zip>${driver.ZipCode || ''}</CPR:zip>
							</CPR:address>
							<CPR:ssn>${driver.SSN || ''}</CPR:ssn>
							<CPR:workClass>DRIVER (ON/OFF-HAULING TO/FROM CONSTRUCTION SITE)-Driver: Dump Truck</CPR:workClass>
							<CPR:payroll>
								<CPR:hoursWorkedEachDay>`;

							// Add each day's hours
							driver.hoursPerDay.forEach((hours, index) => {
								xmlDoc += `
										<CPR:day id="${index + 1}">
											<CPR:date>${formatDayDate(reportWeek.weekStartDate, index)}</CPR:date>
											<CPR:straightTime>${hours || 0.0000}</CPR:straightTime>
											<CPR:overtime>${driver.overtimePerDay[index] || 0.0000}</CPR:overtime>
											<CPR:doubletime>0.0000</CPR:doubletime>
										</CPR:day>`;
							});

							xmlDoc += `
								</CPR:hoursWorkedEachDay>
								<CPR:totals>
									<CPR:hoursWorkedStraightTime>${driver.totalHours || 0.0000}</CPR:hoursWorkedStraightTime>
									<CPR:hoursWorkedOvertime>${driver.overtimeTotalHours || 0.0000}</CPR:hoursWorkedOvertime>
									<CPR:hoursWorkedDoubletime>0.0000</CPR:hoursWorkedDoubletime>
								</CPR:totals>
								<CPR:hourlyPayRates>
									<CPR:hourlyPayRateStraightTime>${prevailingWageReport.PrevailingWageRate || 0.00}</CPR:hourlyPayRateStraightTime>
									<CPR:hourlyPayRateOvertime>${prevailingWageReport.OvertimePrevailingWageRate || 0.00}</CPR:hourlyPayRateOvertime>
									<CPR:hourlyPayRateDoubletime>0.00</CPR:hourlyPayRateDoubletime>
								</CPR:hourlyPayRates>
								<CPR:grossAmountEarned>
									<CPR:thisProject>${driver.projectPaid || 0.00}</CPR:thisProject>
									<CPR:allWork>${driver.allProjectsTotal || 0.00}</CPR:allWork>
								</CPR:grossAmountEarned>
								 <CPR:deductionsContribPay>
									<CPR:fedTax>${driver.FedTax || 0.00}</CPR:fedTax>
									<CPR:FICA>${driver.FICA || 0.00}</CPR:FICA>
									<CPR:SDI>${driver.SDI || 0.00}</CPR:SDI>
							
									<CPR:vacationHoliday>0.00</CPR:vacationHoliday>
									<CPR:healthWelfare>0.00</CPR:healthWelfare>
									<CPR:pension>0.00</CPR:pension>
									<CPR:subsistence>0.00</CPR:subsistence>
									<CPR:training>0.00</CPR:training>
									<CPR:fundAdmin>0.00</CPR:fundAdmin>
									<CPR:travelSubs>0.00</CPR:travelSubs>
									<CPR:savings>0.00</CPR:savings>
									<CPR:other>${driver.OtherTax || 0.00}</CPR:other>
									<CPR:total>${driver.TotalDed|| 0.00}</CPR:total>
									<CPR:notes>${driver.Exception || ''}</CPR:notes>
								</CPR:deductionsContribPay>
					
								<CPR:netWagePaidWeek>${driver.NetWages || 0.00}</CPR:netWagePaidWeek>
								<CPR:checkNum>${driver.CheckNo || ''}</CPR:checkNum>
							</CPR:payroll>
						</CPR:employee>`;
					});

					// Close the XML document
					xmlDoc += `
					</CPR:employees>
				</CPR:payrollInfo>
			</CPR>`;
			
			return uploadXML(xmlDoc, reportWeek, prevailingWageReport);

		} catch (error) {
			console.error('Error creating/uploading XML:', error);
			return {
				success: false,
				error: error.message,
				message: 'Failed to create/upload XML file'
			};
		}
	};

	// Helper function to format dates

	// Helper function to format day dates
	const formatDayDate = (weekStartDate, dayIndex) => {
		const date = new Date(weekStartDate);
		date.setDate(date.getDate() + dayIndex);
		return  formatDate(date,'-','YYYY/MM/DD');
	};

	return {
		createXML
	};
}; 