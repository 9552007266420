
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import ExtraSiteInput from '../Lists/ExtraSiteInput';

const ExtraSitesPopUp = ({inputValues,setVisible, visible,  setInputValues, showLocationPopUp, handleFieldsChange}) => {
	console.log('input values = ', inputValues)
	const handleExtraSiteUpdate = (updatedSite, index) => {
        let tempExtraSites = [...inputValues.ExtraSites];
        tempExtraSites[index] = updatedSite;
        let fields=['ExtraSites', 'LoadSite', 'DumpSite'];
        let values = [tempExtraSites, tempExtraSites[0].LoadSite, tempExtraSites[0].DumpSite];
    
        handleFieldsChange(fields,  values, true);
      
	};

 
    const addExtraSite = () =>{
        let tempExtraSites = [...inputValues.ExtraSites];
        let newSite = {...tempExtraSites[tempExtraSites.length-1]};
        tempExtraSites.push(newSite);
        let fields=['ExtraSites', 'LoadSite', 'DumpSite'];
        let values = [tempExtraSites, tempExtraSites[0].LoadSite, tempExtraSites[0].DumpSite];
        handleFieldsChange(fields,  values, true);
    };

    const deleteExtraSite = (index) =>{
        let tempExtraSites = [...inputValues.ExtraSites]
        tempExtraSites.splice(index,1);
    
        setInputValues(prev => {
            return { ...prev, ExtraSites:tempExtraSites };
          });
          let fields=['ExtraSites', 'LoadSite', 'DumpSite'];
          let values = [tempExtraSites, tempExtraSites[0].LoadSite, tempExtraSites[0].DumpSite];
          handleFieldsChange(fields,  values, true);
    };

    
    const closeExtraSitesPopUp =()=>{
        setVisible(false); 
    }
   
    const footerContent = (
  
        <div style={{paddingTop:'.25em', textAlign:'center'}}  className="flex align-items-center gap-2">
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => setVisible(false)} />
        </div>
    
    );
return(
    <Dialog header="Load and Dump Sites" visible={visible} headerStyle={{padding:".5em"}}  style={{ width: '80vw' }} breakpoints={{ '1400px': '75vw', '641px': '100vw' }} footer={footerContent} onHide={closeExtraSitesPopUp}>
        <div style={{   marginTop:'2em', marginBottom:'2em'}} className='mbsc-grid'>
            {inputValues.ExtraSites.map((site, idx) => (
              
                <ExtraSiteInput key={idx} siteData={site}  onUpdate={(updatedSite) => handleExtraSiteUpdate(updatedSite, idx)} onDelete={()=>deleteExtraSite(idx)} showDelete={inputValues.ExtraSites.length>1} showLocationPopUp={showLocationPopUp} />
               
            ))}
                 <Button style={{width:"50%",left:"25%"}} label="Add Sites" icon="pi pi-plus"  onClick={() => addExtraSite()} />
        </div> 

    </Dialog>
);
};

export default ExtraSitesPopUp;