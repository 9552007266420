import React, { useEffect, useState, useCallback,useRef } from 'react';
import {useParams } from 'react-router-dom';

import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';   
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import {Calendar } from 'primereact/calendar';

import debounce from 'lodash/debounce';
import { Badge } from 'primereact/badge';

import { getStorage, ref, uploadBytes,getDownloadURL} from "firebase/storage";

import { useGlobal } from '../../../context/GlobalContext';
import { UserAuth } from '../../../context/AuthContext';
import { useFreightBillEdit } from '../context/FreightBillEditContext';

import { useFreightCalculation } from '../hooks/useFreightCalculation';
import { useFreightTime } from '../hooks/useFreightTime';

import AutoSelectInputNumber from '../../InputComponents/AutoSelectInputNumber'; 
import InputTextParent from '../../InputComponents/InputTextParent'; 
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import NoteComponent from '../../MiscComponents/NoteComponent'; 
import OvertimeDialog from './OvertimeDialog'; 
import LoadList from '../../ListComponents/LoadList';
import ExpenseList from '../../ListComponents/ExpenseList';
import { parse } from 'date-fns';



function FreightBillEdit({toast}) {

    const { id, dispatchID } = useParams();
    const storage = getStorage();

    const {  topMenuOptions, showMaterialPopUp, showTruckPopUp, showTrailerPopUp, setTopMenuOptions, globalFreightBillRef, globalFormDirty } = useGlobal();
    const {  gearedUser, expenseNames, addDocument, company, updateDocument, trucks, trailers, materials, formatDate} = UserAuth();

	const { freightBill, setFreightBill, fetchFreightBill, freightBillIDRef,
		driverFreightBill, setDriverFreightBill, fetchDriverFreightBill,
		dispatchExpenses, setDispatchExpenses, fetchDispatchExpenses, dispatchExpensesRef,
		checkFBNO, createPDF } = useFreightBillEdit();
 
    
    const freightTypeonLoad= globalFreightBillRef?.current?.isDriverFreight ? 'Driver' : 'Office';
    const tabIndex = globalFreightBillRef?.current?.isDriverFreight ? 0: 1;
	
    const [activeTab, setActiveTab]= useState(tabIndex);
    const [subActiveTab, setSubActiveTab]= useState(0)


    const [inputValues, setInputValues] = useState(null);
   
    const [freightType, setFreightType]= useState(freightTypeonLoad);
    const [displayTab, setDisplayTab] = useState('FreightBill');
    const [showOvertime, setShowOvertime]=useState(false);

    const materialBillTypes=[
		{text:'Ton', value:'Ton'},
		{text:'Load', value:'Load'},
		{text:'Yard', value:'Yard'}
	]

    const billTypes=[
		{text:'Hour', value:'Hour'},
		{text:'Load', value:'Load'},
		{text:'Ton', value:'Ton'}
	]

    const payTypes=[
		{text:'Hour', value:'Hour'},
		{text:'Load', value:'Load'},
		{text:'Ton', value:'Ton'},
		{text:'Hour/Percent', value:'Hour/Percent'},
		{text:'Load/Percent', value:'Load/Percent'},
		{text:'Ton/Percent', value:'Ton/Percent'}
	]

    const header = freightBill ? "Freight Bill for " + freightBill.driverName + " on " + freightBill.JobDate : '';
    const inputValuesRef = useRef(null);
    const freightTypeRef = useRef(null);
    const optionsRef= useRef(null);
    const freightImageRef = useRef(null);

    const isFormDirty = useRef(false);
	if(globalFormDirty?.current)isFormDirty.current=true;

    const weightType = inputValues?.WeightType ? " " + inputValues.WeightType + "s" : " Tons";
    const expenseDriver = freightBill && (!freightBill.Subhauler ? { ID: freightBill.Driver, Name: freightBill.driverName, subhauler:false } : { ID: freightBill.subhaulerID, Name: freightBill.haulerName, subhauler:true });
 

    const expenses = dispatchExpenses ? dispatchExpenses.filter(e => e.FreightBill === id) : [];
    const expenseWidths = company.SellMaterial ? ["5%",  "5%","12%", "10%", "6%", "6%", "4%",  "12%", "6%", "4%", "12%", "8%", "6%", "9%"] :
	["5%",  "5%", "12%","13%", "8%", "8%", "4%",  "13%", "6%", "4%", "13%", "8%", "8%", "9%"] ;
	console.log('expensses= ', expenses)
	const { 
		
		setQtyandCalc, 
		calcTotal,
		calcMaterial

	 } = useFreightCalculation(  company, expenses);

	const {

		calcExcessStandBy, 
		calcStandBy, 
		calcFreightTime, 
		calcRunningTime, 
		getDifferenceInMinutes

	} = useFreightTime(company,expenses,setInputValues);

	//Add the check for isformDirty and using ctrl+s to save the freight bill
    useEffect(() => {
        startEditPage();
        const handleBeforeUnload = (event) => {
            if (isFormDirty.current) {
                event.preventDefault();
                event.returnValue = ""; // Required for some browsers
            }
        };
		const handleCtrlS = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 's') {
                e.preventDefault();
                saveFreightBill();
            }
        };
		window.addEventListener('keydown', handleCtrlS);
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
			window.removeEventListener('keydown', handleCtrlS);
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);
 
	useEffect(() => {
		if(inputValues){
			const fieldsToUpdate = ['Truck', 'Trailer', 'Material'];
			const collections = {  Truck: trucks, Trailer: trailers, Material: materials };
			fieldsToUpdate.forEach(field => {
				const selectedItem = inputValues[field];
				if (selectedItem) {
					const updatedItem = collections[field]?.find(item => item.ID === selectedItem.ID);
					if (updatedItem) if(updatedItem.Name!==selectedItem.Name) handleFieldChange(field, { ...updatedItem }, false);

				}
			});
		}
	}, [ trucks, trailers, materials]);
   
    useEffect(() => {
       optionsRef.current = topMenuOptions;
    }, [topMenuOptions]);

    useEffect(() => {
        console.log('isFormDirty.current in the useffect =  '+ isFormDirty.current);
        globalFormDirty.current = isFormDirty.current;
     }, [isFormDirty?.current]);

    useEffect(() => {
        inputValuesRef.current = inputValues;
        globalFreightBillRef.current=inputValues;
		
    }, [inputValues]);

  

	const initializeFromGlobalFreightBill = (options)=>{
		console.log('initializing from global freight bill = '+ id)
		initalizeFreightBill(globalFreightBillRef?.current, options);

		if(globalFreightBillRef.current.isDriverFreight) {
			setDriverFreightBill({...globalFreightBillRef?.current});
			showDriverFreight({...globalFreightBillRef?.current});
			options.push({label: 'Approve and Save', icon: 'pi pi-save', function:saveFreightBill});

		}else{
			setFreightBill({...globalFreightBillRef?.current});
			options.push({	label: 'Save Freight Bill', icon: 'pi pi-save', function:saveFreightBill});
		} 
		setInputValues({...globalFreightBillRef?.current});  
	   
	}

	const initalizeFreightBill = async(tempFreightBill, options)=>{
		let standByLabel = tempFreightBill.UseStandBy ? 'Hide Stand By' : 'Show Stand By';
		let attachPictureObj = tempFreightBill.Picture ? {label:'Show Picture', function:(e) =>openFBPicture()} :  {label: 'Attach Picture', function:clickUpload};

		options[0].items=[
			attachPictureObj,
			{label: 'Open Mobile Freight', function:openMobileFreight},
			{label: 'Create PDF', function:startCreatePDF},
			{label: 'Invoice by Scale Tag'},
		];

		if(tempFreightBill.billed){
			if(tempFreightBill.markedBilled)options[0].items.push({label: 'Unmark Freight Bill Billed', function:markFBUnbilled} )
		}else options[0].items.push({label: 'Mark Freight Bill Billed', function:markFBBilled} )

		if(tempFreightBill.paid){
			if(tempFreightBill.markedPaid) options[0].items.push({label: 'Unmark Freight Bill Paid', function:markFBUnpaid} )
		}else options[0].items.push({label: 'Mark Freight Bill Paid', function:markFBPaid})
		tempFreightBill.WeightType = tempFreightBill.WeightType ? tempFreightBill.WeightType : "Ton";

		options[0].items.push( {label:  standByLabel, function:toggleStandBy});
		console.log('i jsut pusehd teh last option item  ',options)
	}

	const fetchFreightBillData = async(options)=>{
		let promises=[];
		let fetchedFreightBill = null;
		let fetchedDriverFreightBill = null;
		let fetchedExpenses = [];
		console.log('fetching freight bill data for id = '+ id)
	  
	    promises.push(fetchFreightBill(id).then((bill) => { 
			fetchedFreightBill = bill;
			fetchedFreightBill.fbTurnInValue=new Date( fetchedFreightBill.fbTurnIn); 
		}));

		promises.push(fetchDispatchExpenses(dispatchID).then((tempExpenses) => {fetchedExpenses = tempExpenses; }));
		promises.push(fetchDriverFreightBill(id).then((driverBill) => { fetchedDriverFreightBill = driverBill; }));
		if(promises.length)  await Promise.all(promises);
   
		const currentFreightBill = fetchedFreightBill;
		const currentDriverFreightBill = fetchedDriverFreightBill;
		const currentExpenses = fetchedExpenses ?  fetchedExpenses.filter(e => e.FreightBill === id) : [];

		if(currentDriverFreightBill.SellMaterial){
		
			calcMaterial(currentDriverFreightBill); 
			if(currentDriverFreightBill.MaterialTotal>0){
				currentDriverFreightBill.bTotal = parseFloat(Math.round(Number(currentDriverFreightBill.bTotal + currentDriverFreightBill.MaterialTotal)*100)/100);
			}
		}
		console.log('current expenses = ', currentExpenses)
		initalizeFreightBill(currentFreightBill, options);
		finishInitFreightBill(currentFreightBill, currentDriverFreightBill, currentExpenses, options);

	}
	const finishInitFreightBill = (FreightBill, DriverFreightBill, Expenses, options)=>{
		if(FreightBill.dSubmitted && !FreightBill.approved){
			showDriverFreight(DriverFreightBill)
			updateDumpFeeExpenses(Expenses, DriverFreightBill);
			options.push({label: 'Approve and Save', icon: 'pi pi-save', function:saveFreightBill });
		}else{
			updateDumpFeeExpenses(Expenses, FreightBill);
			options.push({label: 'Save Freight Bill',	icon: 'pi pi-save',function:saveFreightBill	});
			setInputValues({...FreightBill}); 
		}
		freightBillIDRef.current=id; 
	}

    const startEditPage = async()=>{
       
       
        let options = [{ label: 'Options',  icon: 'pi pi-clock', items: [] }];
        if(globalFreightBillRef?.current)initializeFromGlobalFreightBill(options);
        else if(freightBillIDRef.current!==id)await fetchFreightBillData(options);
		else {
			initalizeFreightBill(freightBill, options);
			finishInitFreightBill(freightBill, driverFreightBill, expenses, options);
			freightBillIDRef.current=id; 
		}
        console.log('top menu options b4 setting item  ',options)
        setTopMenuOptions(options);
    }


    const handleFieldsChange = (fields, values)=> {
        console.log('fieldNames= ',fields)
        console.log('values= ',values)
        setInputValues(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
          });
    };

    const handleFieldChange = (fieldName, value, runCalc) => {
        isFormDirty.current = true;
        if(fieldName==='onHold') setInputValues((prev) => ({ ...prev, realOnHold:value, [fieldName]:value })); 
        else if(fieldName==='Material'){
            let tempInputValues={...inputValues}
            for(let q=0; q< tempInputValues.Weights.length; q++) tempInputValues.Weights[q].Material=value;
            setInputValues((prev) => ({ ...prev, [fieldName]: value, Weights:tempInputValues.Weights })); 
        }else if(fieldName==='Truck.Name')  setInputValues((prev) => ({ ...prev, Truck: {Name:value} })); 
        else if(runCalc)setInputValues((prev) => ({ ...prev, missing:false, onHold:inputValues.realOnHold, [fieldName]:value })); 
        else setInputValues((prev) => ({ ...prev, [fieldName]:value })); 
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName]=value;
        if(fieldName==='tHours' || fieldName==='tWeight' || fieldName==='totalYardHours') setInputValues(setQtyandCalc({...tempInputValues}));
        else if(runCalc && !inputValues.billingOverride) setInputValues(calcTotal({...tempInputValues}));
        
    };

    const markFBUnpaid = ()=>{
        let updateObject= {
            ID:id,
            truckingPaid:false,
            standByIsPaid:false,
            markedPaid:false,
            paid:false
        }
        inputValuesRef.current.truckingPaid = false;
        inputValuesRef.current.markedPaid = false;
        inputValuesRef.current.standByIsPaid = false;
        inputValuesRef.current.paid = false;
        updateOptions(inputValuesRef.current);
        setInputValues((prev) => ({ ...prev,truckingPaid: false , markedPaid:false, paid :false, standByIsPaid:false}));
        updateDocument(updateObject, id, "FreightBills");
    }
   
    const markFBPaid = ()=>{
        let updateObject= {
            ID:id,
            truckingPaid:true,
            standByIsPaid:true,
            markedPaid:true,
            paid:true
        }
        inputValuesRef.current.truckingPaid = true;
        inputValuesRef.current.markedPaid = true;
        inputValuesRef.current.standByIsPaid = true;
        inputValuesRef.current.paid = true;
        updateOptions(inputValuesRef.current);
        setInputValues((prev) => ({ ...prev,truckingPaid: true , markedPaid:true, paid :true, standByIsPaid:true}));
        updateDocument(updateObject, id, "FreightBills");
    }

    const markFBUnbilled = ()=>{
        let updateObject= {
            ID:id,
            truckingBilled:false,
            standByIsBilled:false,
            markedBilled:false,
            billed:false
        }
        inputValuesRef.current.truckingBilled = false;
        inputValuesRef.current.markedBilled = false;
        inputValuesRef.current.standByIsBilled = false;
        inputValuesRef.current.billed = false;
        updateOptions(inputValuesRef.current);
        setInputValues((prev) => ({ ...prev,truckingBilled: false ,     markedBilled:false,billed :false, standByIsBilled:false}));
        updateDocument(updateObject, id, "FreightBills");
    }

    const markFBBilled = ()=>{
        let updateObject= {
            ID:id,
            truckingBilled:true,
            standByIsBilled:true,
            markedBilled:true,
            billed:true
        }
        inputValuesRef.current.truckingBilled = true;
        inputValuesRef.current.markedBilled = true;
        inputValuesRef.current.standByIsBilled = true;
        inputValuesRef.current.billed = true;
        updateOptions(inputValuesRef.current);
        setInputValues((prev) => ({ ...prev,truckingBilled: true ,     markedBilled:true, billed :true, standByIsBilled:true}));
        updateDocument(updateObject, id, "FreightBills");
    }
    
    const updateOptions = (optionFreightBill) =>{
        let attachPictureObj = optionFreightBill.Picture ? {label:'Show Picture', function:(e) =>openFBPicture()} :  {label: 'Attach Picture', function:clickUpload};
        let standByLabel = !optionFreightBill.UseStandBy ? 'Show Stand By' : 'Hide Stand By';

        let optionItems = [
            attachPictureObj,
            {label: 'Open Mobile Freight', function:openMobileFreight},
            {label: 'Create PDF', function:startCreatePDF},
            {label: 'Invoice by Scale Tag'},
        ];

        if(optionFreightBill.billed){
            if(optionFreightBill.markedBilled) optionItems.push({label: 'Unmark Freight Bill Billed', function:markFBUnbilled} )
        }else optionItems.push({label: 'Mark Freight Bill Billed', function:markFBBilled} )
        if(optionFreightBill.paid){
            if(optionFreightBill.markedPaid) optionItems.push({label: 'Unmark Freight Bill Paid', function:markFBUnpaid} )
        }else optionItems.push({label: 'Mark Freight Bill Paid', function:markFBPaid})

        optionItems.push( {label:  standByLabel, function:toggleStandBy});

        setTopMenuOptions([{
            label: 'Options',
            icon: 'pi pi-clock',
            items: optionItems,
        }, optionsRef.current[1]]);
    }
    

    const handleFBNOChange= (value)=>{
        setInputValues((prev) => ({ ...prev, 'FBNO': value })); 
        let tempFB = {...inputValues};
        tempFB.FBNO = value;
        checkFBNO( tempFB );
        
    }

    const changeStandByField =(fieldName, value, calcExcess)=>{
  
        let tempInputValues = {...inputValues};
        tempInputValues[fieldName]=value;
        if(fieldName === 'totalExcessDump' || fieldName ==='totalExcessLoad'){
            tempInputValues.standExMin = parseFloat(Number((tempInputValues.totalExcessLoad ? tempInputValues.totalExcessLoad:0) + (tempInputValues.totalExcessDump ? tempInputValues.totalExcessDump : 0 )));
            tempInputValues.paidStandExMin = parseFloat(Number((tempInputValues.totalExcessLoad ? tempInputValues.totalExcessLoad:0 )+ (tempInputValues.totalExcessDump ? tempInputValues.totalExcessDump : 0)));
        }

       if( calcExcess) calcExcessStandBy({...tempInputValues});
       else     calcStandBy({...tempInputValues});
    
    }

    const changeMaterialRateType = (e)=>{
        if(e.value==='Ton' || e.value==='Yard'){
            let fields=  ['WeightType','MaterialRateType'];
            let values = [e.value,e.value];
            handleFieldsChange(fields,values );
        }else   handleFieldChange('MaterialRateType', e.value, true);
       
      
        handleDropdownChange(e);
    }

    const handleDropdownChange = (e)=>{
     
        const allElements = Array.from(document.querySelectorAll('div, input, select, textarea'));
        let nextElement =  document.getElementById(e.target.id).parentElement.nextElementSibling;

        const currentIndex = allElements.indexOf(nextElement);
        if (currentIndex > -1 && currentIndex < allElements.length - 1) {
            // Iterate through the elements starting from the next index
            for (let i = currentIndex; i < allElements.length; i++) {
                const element = allElements[i];
    
                // Check if the element is focusable
                if (element.matches('input:not([disabled]), select:not([disabled]), textarea:not([disabled])')) {
                    element.focus(); // Focus the element
                    console.log('Focused element =', element);
                    break; // Stop once a focusable element is found
                }
            }
        }
    }

    const debouncedUpdateDriverName = useCallback(
        debounce((driverName) => {
            for(let i=0; i < expenses.length; i++) {
                expenses[i].driverName = driverName;
                updateDocument(expenses[i], expenses[i].ID, "Expenses");
            }
        }, 500),
        [expenses]
    );

    const changeDriverName = (value)=>{
        isFormDirty.current = true;
        setInputValues(prev => ({ ...prev, driverName: value }));
        debouncedUpdateDriverName(value);
    };

	const updateDumpFeeExpenses = (tempExpenses, FreightBill)=>{
		for(let q=0; q<tempExpenses.length; q++){
			if(tempExpenses[q].Name.Name==='Dump Fee'){
				tempExpenses[q].qty=FreightBill.loads;
				tempExpenses[q].total=Number( tempExpenses[q].qty) * Number( tempExpenses[q].rate);
				if( tempExpenses[q].total!==0) tempExpenses[q].onHold=false;
				 updateDocument(  tempExpenses[q],   tempExpenses[q].ID, "Expenses");
			}
		}
		console.log('updating dispatch expenses = ', tempExpenses)
		setDispatchExpenses([...tempExpenses]);
	}
    const updateLoadsField = useCallback((FreightBill)=>{

        if (FreightBill.loads) {
            if (FreightBill.Weights.length < FreightBill.loads) {
                let difference = FreightBill.loads - FreightBill.Weights.length;
                for (var i = 0; i < difference; i++){
					FreightBill.Weights.push({
						TagUrl:'', 
						tagNO:'', 
						Material:FreightBill.Material, 
						loadStart:'',
						loadEnd:'',
						dumpStart:'', 
						dumpEnd:'', 
						FBNO: FreightBill.FBNO, 
						loadNumber:Number(FreightBill.Weights.length+1), weight:''
					});
				}
			}
            if (FreightBill.Weights.length > FreightBill.loads) {
                let difference = FreightBill.Weights.length - FreightBill.loads;
                for (let j = 0; j < difference; j++) {
                    let tempPosition = FreightBill.Weights.length - 1;
                    FreightBill.Weights.splice(FreightBill.Weights.indexOf(tempPosition), 1);
                }
            }
            let tempExpenses=[...expenses];
			updateDumpFeeExpenses(tempExpenses, FreightBill);
         
			setInputValues(setQtyandCalc(FreightBill));
            console.log('UPDATING LOADS FIELD!! ', FreightBill.Weights);
    }
   
    },[freightBill]);


    const debouncedUpdateLoads = useCallback(debounce(updateLoadsField, 500), [updateLoadsField]);

    const changeLoads = (value)=>{
        isFormDirty.current = true;
        setInputValues((prev) => ({ ...prev,missing:false, onHold: inputValues.realOnHold, loads: value })); 
        let tempInputValues = {...inputValues};
        tempInputValues.loads=value;
        debouncedUpdateLoads({...tempInputValues});
    }
  
    const updateFreightBillFields=(fields, values)=>{
        setInputValues(prevState => {
            let newState = { ...prevState };
            for (let i = 0; i < fields.length; i++) newState[fields[i]] = values[i]; 
            return newState;
          });
    }

    const setFreightWeights = (Weights)=>{
        let tempInputValues = {...inputValues};
        tempInputValues.Weights=[...Weights];
        setInputValues((prev) => (  tempInputValues)); 
    }

    const formatTime =useCallback(async  (copyInputValues,time, timeName) =>{

        time= time.replace(/\./g,':');
        if (/^([01][0-9]|2[0-3])[0-5][0-9]$/.test(time)) time = time.substr(0, 2) + ':' + time.substr(2);
        else if (/^([0-9]|[0-3])[0-5][0-9]$/.test(time)) time= '0' + time.substr(0, 1) + ':' + time.substr(1);
        else if (/^([0-9]|[0-3]):[0-5][0-9]$/.test(time)) time = '0' + time.substr(0, 1) + ':' + time.substr(2, 3);

        setInputValues((prev) => ({ ...prev, [timeName]: time }));
    
        
        if(timeName!=='dispatchTime'){
            let tempInputValues ={...copyInputValues};
            tempInputValues[timeName] = time;
            calcFreightTime({...tempInputValues});
        }
    },[freightBill]);

    const changeLunch = (value)=>{
        let tempInputValues ={...inputValues};
        tempInputValues.lunch = value;
        setInputValues((prev) => ({ ...prev, lunch:value })); 
        calcFreightTime({...tempInputValues});
    }

    const debouncedFormatTime = useCallback(debounce(formatTime, 800), [formatTime]);
    const debouncedUpdateFreightBillFields = useCallback(debounce(updateFreightBillFields, 500), [updateFreightBillFields]);

    const handleNoteChange =(noteField, text, quillNoteField, quill)=>{
        
        isFormDirty.current = true;
        let fields =[noteField,quillNoteField];
        let values = [text,quill];
        console.log('fields = ', fields);
        console.log('values = ', values)
       if(noteField==='Notes') debouncedUpdateFreightBillFields(fields, values ); else debouncedUpdateFreightBillFields(fields, values )
     
    }


    const checkGearedTime = (time) => {
        // Check if time matches HH:MM format and is a valid time
        return /^([01]\d|2[0-3]):[0-5]\d$/.test(time);
    };


   

    const saveFreightBill = async() => {
        let updateDoc = inputValuesRef.current; // Use ref to get the latest inputValues
       // let FBNumberExists = await checkFBNO(updateDoc);
       
		console.log('freightType = ' + freightTypeRef.current)
		isFormDirty.current=false;
		globalFormDirty.current = false;
		if(freightTypeRef.current==='Driver'){
			updateDoc.approved=true;
			updateDoc.missing=false;
			updateDoc.onHold=false;
		}
		console.log('uypdate doc = ', updateDoc)
		updateDoc.fbTurnInValue='';
		setFreightBill({...updateDoc});
		await updateDocument(updateDoc, updateDoc.ID, "FreightBills");

		if(toast?.current) toast.current.show({severity:'success', summary: 'Freight Bill Saved', life: 3000});
		//   console.log('window.histor.lenbght = ' +window.history.length )
		if(window.history.length!==1)window.history.back();	else window.close();
        
    };

    const clickUpload = () =>{
        console.log(' document.getElementById(freightUpload) =' , document.getElementById('freightUpload'))
        document.getElementById('freightUpload').click();
    }
    const startUpload=(event)=>{
        console.log('we have started an uplaod and event ', event)
        uploadFile(event.target.files[0])
    }

    const uploadFile = async(file)=>{
        console.log('file = ', file.name)

        let storageRef= ref(storage, 'attachments/' + freightBill.companyID+'/FreightBills/' +freightBill.ID+'/Pictures/'+file.name);

        uploadBytes(storageRef, file).then((snapshot) => {
            getDownloadURL(storageRef).then((url) => {
                inputValuesRef.current.Picture=url;
                handleFieldChange( 'Picture', url,false);
                updateOptions(inputValuesRef.current);
        
          });
        });
    }
   
    const openMobileFreight = ()=>{
       

        let orgName  = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27')
        var win = window.open('/mobile/freightbill/'+orgName+'/'+ inputValuesRef.current.ID, '_blank');
        win.focus();
    
        
    }
    const changeTimeField = (time, timeName) =>{
        isFormDirty.current = true;
        setInputValues((prev) => ({ ...prev, [timeName]: time }));
        let copyInputValues ={...inputValues};
        copyInputValues[timeName]=time;
        debouncedFormatTime(copyInputValues,time, timeName);
    }

    const changeDisplayTab = (e) =>{
        if(e.index===0) setDisplayTab('FreightBill');
        if(e.index===1) setDisplayTab('Loads');
        if(e.index===2) setDisplayTab('Expenses');
        setSubActiveTab(e.index)
    }
    function formatTo24HourTime(date) {
        if(!checkGearedTime(date) && date){
       
            const tempDate= date.toDate();
            const hours = tempDate.getHours().toString().padStart(2, '0'); // Get hours and pad with leading zero if needed
            const minutes = tempDate.getMinutes().toString().padStart(2, '0'); // Get minutes and pad with leading zero if needed
        
            return `${hours}:${minutes}`;
        }else return date;
    
    }

    const showDriverFreight = (FreightBill) =>{
        console.log('we are showing driverfrieht for fReight bi ill = ', FreightBill)
        FreightBill.startTime =  formatTo24HourTime(FreightBill.startTime);
        FreightBill.startTimePaid =  formatTo24HourTime(FreightBill.startTimePaid);
        FreightBill.endTime =  formatTo24HourTime(FreightBill.endTime);
        FreightBill.endTimePaid =  formatTo24HourTime(FreightBill.endTimePaid);
    
        FreightBill.arriveRoundTrip =  formatTo24HourTime(FreightBill.arriveRoundTrip);
        FreightBill.departRoundTrip =  formatTo24HourTime(FreightBill.departRoundTrip);
        setActiveTab(0);
        setFreightType('Driver');
        freightTypeRef.current='Driver';
        for(let i=0; i<FreightBill.Weights.length; i++){
            FreightBill.Weights[i].loadStart = formatTo24HourTime(FreightBill.Weights[i].loadStart);
            FreightBill.Weights[i].loadEnd =  formatTo24HourTime(FreightBill.Weights[i].loadEnd);
            FreightBill.Weights[i].dumpStart =  formatTo24HourTime(FreightBill.Weights[i].dumpStart);
            FreightBill.Weights[i].dumpEnd =   formatTo24HourTime(FreightBill.Weights[i].dumpEnd);
        }
        console.log('setting inputValues = ', FreightBill);
       
        setInputValues({...FreightBill})
    }

    const startCreatePDF = () =>{
        createPDF(inputValuesRef.current);
    }

    const toggleStandBy =()=>{
        console.log('about to toggle stand by and use stand by = ' + inputValuesRef.current.UseStandBy)
    
        if(inputValuesRef.current.UseStandBy){
            setInputValues((prev) => ({ ...prev,UseStandBy: false})); 
            inputValuesRef.current.UseStandBy=false;
            updateOptions(inputValuesRef.current);
            setInputValues(calcTotal(inputValuesRef.current));
         } else{
            setInputValues((prev) => ({ ...prev,UseStandBy: true})); 
            inputValuesRef.current.UseStandBy=true;
            updateOptions(inputValuesRef.current);
            setInputValues(calcTotal(inputValuesRef.current));
        } 
    }

    const changeFreightBill = (e) =>{

        console.log('e.index = =' + e.index)
   
        if(e.index===0 && freightType==='Office') {
            setFreightBill({...inputValues});
            console.log('drsiver freight bill = ', driverFreightBill)
            showDriverFreight({...driverFreightBill})
            setTopMenuOptions(  [optionsRef.current[0],{
                label: 'Approve and Save',
                icon: 'pi pi-save',
                function:saveFreightBill
            }]);  
        }
        if(e.index===1 && freightType==='Driver') {
            setDriverFreightBill({...inputValues});
            setInputValues({...freightBill})
            setActiveTab(1);
            setFreightType('Office');
            freightTypeRef.current='Office';
            setTopMenuOptions([optionsRef.current[0],{
                label: 'Save Freight Bill',
                icon: 'pi pi-save',
                function:saveFreightBill 
            }]);
        } 
 
     
    }
	const updateDispatchExpenses = (expense, expenseID)=>{
		console.log('updating dispatch expenses for expense= ', expense)
		let tempExpenses = [...dispatchExpensesRef.current];
		for(let i=0; i<tempExpenses.length; i++){
			if(tempExpenses[i].ID===expenseID){
				tempExpenses[i]={...tempExpenses[i], ...expense};
			
			}	
		}
		dispatchExpensesRef.current = [...tempExpenses];
		setDispatchExpenses([...tempExpenses]);
		
	}

    const handleAddExpense = (event)=>{
        let truckName='';
         truckName=freightBill.Truck?.Name ? freightBill.Truck.Name : '';
        console.log('freightBill.Truck = ', freightBill.Truck)
 
        var Expense = {
            ID: "",
            Name: expenseNames[0],
            parentExpense:'',
            description: expenseNames[0].Name,
            qty: Number(0),
            rate: Number(0),
            total: Number(0),
            addToFreights:false,
            FBNO:freightBill.FBNO,
            QueryDate:freightBill.QueryDate,
            JobDate:freightBill.JobDate,
            driverID:freightBill.Driver,
            driverName:freightBill.driverName,
            BillType:freightBill.BillType,
            Truck:truckName,
            Company:freightBill.Company,
            FreightBill:freightBill.ID,
            dispatchID:freightBill.dispatchID,
            reDriver: false,
            bCustomer: false,
            reCustomer: false,
            bDriver: false,
            bill:false,
            billed:false,
            paid:false,
            pay:false,
            onHold:true,
            BillTo:{Name:freightBill.Account?.Name, ID:freightBill.Account?.ID},
            
            MaterialExpense: false
        };

        if(!freightBill.Subhauler)Expense.PayTo={ID:freightBill.Driver,Name:freightBill.driverName	};
        else Expense.PayTo={ID:freightBill.subhaulerID,Name:freightBill.haulerName	};
        console.log('aabout to run add Expense for ', Expense) 
        addDocument(Expense, 'Expenses');

    }

    const handleDateChange = ( fieldName, value) => {
    
        let formattedDate = formatDate(value, '/', 'YYYY/MM/DD'); // Assuming this gives a string in 'YYYY/MM/DD'
        let queryDate = freightBill.QueryDate; // Assuming this is also in 'YYYY/MM/DD' format

        // Parse both dates into JavaScript Date objects
        let parsedFormattedDate = parse(formattedDate, 'yyyy/MM/dd', new Date());
        let parsedQueryDate = parse(queryDate, 'yyyy/MM/dd', new Date());

        // Calculate the difference in time (milliseconds)
        let timeDifference = parsedFormattedDate - parsedQueryDate;

        // Convert the time difference to days
        let daysLate = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        console.log(`Days late: ${daysLate}`);
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value, DaysLate:daysLate }));   
    };


    const openInvoice = ()=>{
        var win = window.open('/invoice/invoice/'+freightBill.Invoice, '_blank');
        win.focus();
    }

    const openPayStatement = ()=>{
        var win = window.open('/paystatement/paystatement/'+freightBill.DPS, '_blank');
        win.focus();
    }

    const openFBPicture = () =>{
        if(freightImageRef?.current) freightImageRef.current.show();
    }

    const expenseHeaderTemplate = (options) =>{
        const countNotAbsent =expenses.filter(obj => obj.onHold).length;
        return tabHeaderTemplate('Expenses', countNotAbsent, options, 'faTruck')
    }

    const tabHeaderTemplate = ( text, value, options, iconName) => {
        return (
            <div style={{ cursor: 'pointer', height: "100%", backgroundColor: "#f7f7f7"}} onClick={options.onClick}>
                <a className="p-tabview-nav-link" style={{ paddingTop:".2em",paddingBottom:".2em", paddingLeft:"0", justifyContent:'center', paddingRight:"0", display: "flex", flexDirection: "column", alignItems: "center", fontSize: "clamp(.7rem, 0.8vw + 0.3rem, 1rem)", width: "100%", height: "100%" }}>

                    <div className='mbsc-row' style={{margin:"0", width: "100%", display: "flex", justifyContent:'center', }}>
                        {text}     {value > 0 && (<Badge style={{ display:'flex', flexDirection:'column',  marginLeft:"-.1em", width:'1.5em',height:'1.7em',  paddingRight:"0", paddingLeft:"0", backgroundColor: "red" }}  value={value} />)}
                    </div>
                </a>
            </div>
        );
    };

 
    
    return (
        <div>
            
            {inputValues?.Account && (<div className="mbsc-grid" style={{margin:"0", padding:"0"}}>
             
                <TabView activeIndex={activeTab} onTabChange={(e) => changeFreightBill(e)}   >
                  {inputValues.dSubmitted && (  <TabPanel header="Driver Freight Bill" style={{marginTop:"0", padding:"0"}}  />  )}
                    <TabPanel header="Office Freight Bill" style={{marginTop:"0", padding:"0"}}  />   
                </TabView>
                <TabView   onTabChange={(e) => changeDisplayTab(e)}  activeIndex={subActiveTab}  >
                        <TabPanel header="Freight Bill" style={{marginTop:"0"}}  />
                        <TabPanel header="Loads"  style={{marginTop:"0"}}  />  
                        <TabPanel  headerTemplate={expenseHeaderTemplate} style={{marginTop:"0"}}  />   
                </TabView>
                {displayTab==='FreightBill' && (
                    <Panel header={header} className="freight-panel"> 
                        <div  className="mbsc-row" style={{margin:"0"}}> 
                            <div className="mbsc-col-xl-4">
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Driver </span> 
                                    <InputTextParent  value={inputValues.driverName} disabled={!inputValues.Subhauler} onChange={(e) => changeDriverName(e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >FB # </span> 
                                    <InputTextParent  value={inputValues.FBNO}  onChange={(e) =>  handleFBNOChange(e.target.value)} />
                                </div>
                                
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  ># of Loads</span> 
                                    <AutoSelectInputNumber   value={inputValues.loads}   onChange={(e) => changeLoads( e.value)} disabled={inputValues.truckingBilled} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Dispatch Start</span> 
                                    <InputTextParent  value={inputValues.dispatchTime} maxLength={5} onChange={(e) =>changeTimeField(e.target.value, 'dispatchTime')} />
                                </div> 
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Job Start</span> 
                                    <InputTextParent  value={inputValues.startTime} maxLength={5} onChange={(e) =>changeTimeField(e.target.value, 'startTime')}  onBlur={() => formatTime(inputValues, inputValues.startTime, 'startTime')} disabled={inputValues.truckingBilled} />
                                </div>   
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Job End</span> 
                                    <InputTextParent  value={inputValues.endTime} maxLength={5} onChange={(e) =>changeTimeField(e.target.value, 'endTime')}  onBlur={() => formatTime(inputValues, inputValues.endTime, 'endTime')} disabled={inputValues.truckingBilled} />
                                </div> 
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Deduction</span> 
                                    <AutoSelectInputNumber   value={inputValues.lunch}   onChange={(e) => changeLunch(e.value)} disabled={inputValues.truckingBilled}/>
                                </div>
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Running Time</span> 
                                    <AutoSelectInputNumber   value={inputValues.runningTime}   onChange={(e) => handleFieldChange('runningTime', e.value, true)} disabled={inputValues.truckingBilled}/>
                                </div>
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Total Hours</span> 
                                    <AutoSelectInputNumber   value={inputValues.tHours}   onChange={(e) => handleFieldChange('tHours', e.value, true)} disabled={inputValues.truckingBilled}/>
                                </div>

                            </div>
                            <div className="mbsc-col-xl-4">

                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon search-auto-input">Turned In </span>
                                    <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.fbTurnInValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'fbTurnIn',e.value )} />
                                </div> 
                       

                               {!freightBill.Subhauler ? (<AutoCompleteInput fieldName="Truck" field="Name" labelClass='p-inputgroup-addon freight-inputgroup' value={inputValues.Truck} suggestions={trucks} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTruckPopUp(inputValues.Truck)} databaseList={'Trucks'}/>):
                                ( <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Truck </span> 
                                    <InputTextParent  value={inputValues.Truck.Name}  onChange={(e) => handleFieldChange('Truck.Name', e.target.value, false)} />
                                </div>)}
                                <AutoCompleteInput fieldName="Trailer" field="Name" labelClass='p-inputgroup-addon freight-inputgroup' value={inputValues.Trailer} suggestions={trailers} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showTrailerPopUp(inputValues.Trailer)} databaseList={'Trailers'}/>

                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Show Up</span> 
                                    <AutoSelectInputNumber   value={inputValues.ShowUp}   onChange={(e) => handleFieldChange('ShowUp', e.value, false)} />
                                </div>
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Hour Min</span> 
                                    <AutoSelectInputNumber   value={inputValues.HourMin}   onChange={(e) => handleFieldChange('HourMin', e.value, true)} />
                                </div>
                            
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Yard Start </span> 
                                    <InputTextParent  value={inputValues.startTimePaid} maxLength={5} onChange={(e) =>changeTimeField(e.target.value, 'startTimePaid')}  onBlur={() => formatTime(inputValues, inputValues.startTimePaid, 'startTimePaid')}  disabled={inputValues.truckingPaid}/>
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Yard End </span> 
                                    <InputTextParent  value={inputValues.endTimePaid} maxLength={5} onChange={(e) =>changeTimeField(e.target.value, 'endTimePaid')}   onBlur={() => formatTime(inputValues, inputValues.endTimePaid, 'endTimePaid')} disabled={inputValues.truckingPaid}/>
                                </div>
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Yard Hours</span> 
                                    <AutoSelectInputNumber   value={inputValues.totalYardHours}   onChange={(e) => handleFieldChange('totalYardHours', e.value, true)}  disabled={inputValues.truckingPaid}/>
                                </div>
                                <div className="p-inputgroup " >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Travel Time</span> 
                                    <AutoSelectInputNumber   value={inputValues.travelTime}   onChange={(e) => handleFieldChange('travelTime', e.value, true)} disabled={inputValues.truckingPaid} />
                                </div>

                            </div>
                            <div className="mbsc-col-xl-4">
                                     
                            	<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Days Late </span> 
                                    <AutoSelectInputNumber   value={inputValues.DaysLate}   onChange={(e) => handleFieldChange('DaysLate', e.value, false)} />
                                </div>
                                <div className="mbsc-row" >
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Missing</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('missing', e.checked, false)}  checked={inputValues.missing}  disabled={inputValues.truckingBilled}/>
                               
                                    </div>   
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}> On Hold</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('onHold', e.checked, false)}  checked={inputValues.onHold} disabled={inputValues.truckingBilled} />
                                    </div>
                                </div>
                                <div className="mbsc-row" >
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '60%'}}>Invoice</span>
                                        {inputValues.Invoice && (<Button onClick={(e)=>openInvoice()}  >Invoice</Button>)}
                                    </div>   
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '60%'}}>Pay Statement</span>
                                        {inputValues.DPS && (  <Button onClick={(e)=>openPayStatement()}  >Pay Statement </Button>)}
                                    </div>
                                </div>
                                <div className="mbsc-row" >
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Submitted</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('dSubmitted', e.checked, false)} disabled={true} checked={inputValues.dSubmitted}  />
                                    </div>   
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Manual Billing</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('billingOverride', e.checked, true)}  checked={inputValues.billingOverride} disabled={inputValues.truckingBilled} />
                                    </div>
                                </div>
                                <div className="mbsc-row" >
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Apply Broker Fee to Fuel</span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) => handleFieldChange('billBrokerFuel', e.checked, true)}  checked={inputValues.billBrokerFuel} disabled={inputValues.truckingBilled} />
                                    </div>   
                                    <div className="p-inputgroup mbsc-col" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Billing Approved</span>
                                        <Checkbox style={{ width: '100%' }} disabled={true} onChange={(e) => handleFieldChange('approved', e.checked)}  checked={inputValues.approved}  />
                                    </div>
                                </div>
                                
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Odometer Start</span> 
                                    <AutoSelectInputNumber   value={inputValues.odStart}   onChange={(e) => handleFieldChange('odStart', e.value)} />
                                </div>
                        
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Odometer End</span> 
                                    <AutoSelectInputNumber   value={inputValues.odEnd}   onChange={(e) => handleFieldChange('odEnd', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Odometer Diff</span> 
                                    <AutoSelectInputNumber   value={inputValues.odDiff}   onChange={(e) => handleFieldChange('odDiff', e.value)} />
                                </div>

                                <AutoCompleteInput fieldName="Material" field="Name" labelClass="p-inputgroup-addon freight-inputgroup" value={inputValues.Material} suggestions={materials} setValue={setInputValues} handleFieldChange={handleFieldChange} editClick={() => showMaterialPopUp(inputValues.Material)} databaseList={'Materials'}/>

                            </div>
                        </div>
                        <div className='mbsc-row'>
                            <Panel className='mbsc-col freight-panel' header="Note to Driver">
                                <NoteComponent parent={inputValues} noteType={'driverNote'} quillName={'FBNoteToDriverQuill'} onNoteChange={handleNoteChange} ></NoteComponent>      
                            </Panel>
                            <Panel className='mbsc-col freight-panel' header="Comments" >
                                <NoteComponent parent={inputValues} noteType={'Comments'} quillName={'QuillFBNotes'} onNoteChange={handleNoteChange} ></NoteComponent>      
                            </Panel>
                        </div>
                    </Panel>
                )}
                   {displayTab==='Loads' && (<Panel header="Loads" className="freight-panel">
                   
                        <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                  
                                <table style={{ marginBottom: "5px", width: "100%" }}>
                                    <thead>
                                        <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                            <th style={{ width: "12%" }}>Tag #</th>
                                            <th style={{ width: "13%" }}>Load Start</th>
                                            <th style={{ width: "13%" }}>Load End</th>
                                            <th style={{ width: "13%" }}>Dump Start</th>
                                            <th style={{ width: "13%"}}>Dump End</th>
                                            <th style={{ width: "12%"}}>Weight</th>
                                            <th style={{ width: "12%" }}>Upload Tag</th>
                                            <th style={{ width: "12%" }}>Tag Picture</th>
                                        </tr>
                                    </thead>
                                
                                    <tbody>  
                               
                                   
                                        {inputValues.Weights.map((item, index) => (
                                            <LoadList
                                                key={index}
                                                load={item}
                                                weightIndex={index}
                                                FreightBill={inputValues}
                                                setFreightWeights={setFreightWeights}
                                                setQtyandCalc={setQtyandCalc}
                                                calcStandBy={calcStandBy}
                                                getDifferenceInMinutes={getDifferenceInMinutes}
                                                calcRunningTime={calcRunningTime}
                                                calcFreightTime={calcFreightTime}
												setFreightState={setInputValues}
                                            />
                                        ))}                                
                                      
                                    </tbody>
                                </table>
                            </div>
                            <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                                
                            <div className=" mbsc-col-xl-3 mbsc-offset-xl-8 p-inputgroup" >
                                    <span className="p-inputgroup-addon freight-inputgroup"  >Total Weight</span> 
                                    <AutoSelectInputNumber   value={inputValues.tWeight}   onChange={(e) => handleFieldChange('tWeight', e.value, true)}  disabled={freightBill.truckingBilled | freightBill.truckingPaid} suffix={weightType} />
                                </div>
                          
                            </div>
                    </Panel>)}
                    {displayTab==='Expenses' && (<Panel header="Expenses" className="freight-panel">
                        <button style={{ margin: '0', padding: '.5em', width:"10%" }}  onClick={(e) =>handleAddExpense(e)}    className="mbsc-ios mbsc-btn-primary mbsc-btn"     > Add Expense </button>
                        <div className="mbsc-row" style={{ height: "100%", width: "100%" }}>
                            <table style={{ marginBottom: "5px", width: "100%" }}>
                                <thead>
                                    <tr className="mbsc-row" style={{ width: "100%", marginLeft: "1em" }}>
                                        <th style={{ width: expenseWidths[0] }}>Delete</th>
                                     
                                        <th style={{ width: expenseWidths[1] }}>On Hold</th>
                                       	{ company.SellMaterial &&(<th style={{ width: expenseWidths[2] }}>Company</th>)} 
                                        <th style={{ width: expenseWidths[3] }}>Description</th>
                                        <th style={{ width: expenseWidths[4] }}>QTY</th>
                                        <th style={{ width: expenseWidths[5] }}>Rate</th>
                                        <th style={{ width: expenseWidths[6] }}>Bill</th>
                                        <th style={{ width: expenseWidths[7] }}>Bill To</th> 
                                        <th style={{ width: expenseWidths[8] }}>Invoice</th>
                                        <th style={{ width: expenseWidths[9] }}>Pay </th>
                                        <th style={{ width: expenseWidths[10] }}>Pay To</th>
                                        <th style={{ width: expenseWidths[11] }}>Pay Statement</th>
                                        <th style={{ width: expenseWidths[12] }}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>  
                                    {expenses.map((item,index) => (
                                        <ExpenseList key={index} expense={item} showAddToFreights={false}  driver={expenseDriver} customerID={freightBill.Account.ID} updateDispatchExpenses={updateDispatchExpenses} />
                                    ))}
                                </tbody>
                           </table>
                       </div>
                    </Panel>)}
                 {(displayTab==='FreightBill' || displayTab==='Loads')  && (
                    <div className="mbsc-row">
                        {inputValues.SellMaterial && (  <Panel header="Stand By" className="mbsc-col-xl freight-panel">
                            <div  className="mbsc-row" style={{margin:"0"}}> 
                                <div className="mbsc-col-xl-6" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Qty</span> 
                                        <AutoSelectInputNumber   value={inputValues.MaterialQty}   onChange={(e) =>handleFieldChange('MaterialQty', e.value, true)} disabled={inputValues.materialBilled}/>
                                    </div>
                                  
                                   
                                    <div className="p-inputgroup mbsc-col-xl" style={{padding:'0'}}>
                                        <span className="p-inputgroup-addon " style={{  flexBasis: '80%'}}>Taxable</span>
                                        <Checkbox  onChange={(e) => handleFieldChange('MaterialTaxable', e.checked, true)}  checked={inputValues.MaterialTaxable}  disabled={inputValues.materialBilled}/>
                               
                                    </div> 
                                    <div className="p-inputgroup" style={{padding:"0"}}  id="materialBillTypeInputGroup">
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate Type</span>
                                        <Dropdown value={inputValues.MaterialRateType} id="materialBillTypeDropdown" onChange={(e) => {changeMaterialRateType(e)}}  options={materialBillTypes} optionLabel="text" disabled={inputValues.materialBilled}
                                            placeholder="Rate Type" className="w-full md:w-14rem" />
                                    </div> 
                                </div>
                            
                                <div className="mbsc-col-xl-6" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate</span> 
                                        <AutoSelectInputNumber   value={inputValues.MaterialRate}   onChange={(e) => handleFieldChange('MaterialRate', e.value, true)} disabled={inputValues.materialBilled} />
                                    </div>
                                    
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Tax Rate</span> 
                                        <AutoSelectInputNumber   value={inputValues.MaterialTaxRate}   onChange={(e) => handleFieldChange('MaterialTaxRate', e.value, true)} disabled={inputValues.materialBilled} />
                                    </div>
                                      
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Total</span> 
                                        <AutoSelectInputNumber   onChange={(e) => handleFieldChange('MaterialTotal', e.value, true)}  value={inputValues.MaterialTotal}  disabled={inputValues.materialBilled || !inputValues.billingOverride} />
                                    </div>
                                </div>
                              
                            </div>
                        </Panel>)}
                        {inputValues.UseStandBy && (<Panel header="Stand By" className="mbsc-col-xl freight-panel">
                            
                            {inputValues.SellMaterial ? ( <div  className="mbsc-row" style={{margin:"0"}}> 
                                <div className="mbsc-col-xl-6" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Load Allowed</span> 
                                        <AutoSelectInputNumber   value={inputValues.standLA}   onChange={(e) =>changeStandByField('standLA', e.value, true)} disabled={inputValues.standByIsBilled}/>
                                    </div>
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Dump Allowed</span> 
                                        <AutoSelectInputNumber   value={inputValues.standDA}   onChange={(e) => changeStandByField('standDA', e.value, true)}  disabled={inputValues.standByIsBilled} />
                                    </div>
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Bill Excess Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.standExMin}   onChange={(e) => handleFieldChange('standExMin', e.value, true)}  disabled={inputValues.standByIsBilled}/>
                                    </div>
                                </div>
                            
                                <div className="mbsc-col-xl-6" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Excess Load Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.totalExcessLoad}   onChange={(e) => changeStandByField('totalExcessLoad', e.value, false)}  disabled={inputValues.standByIsPaid || inputValues.standByIsBilled} />
                                    </div>
                                    
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Excess Dump Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.totalExcessDump}   onChange={(e) => changeStandByField('totalExcessDump', e.value, false)}  disabled={inputValues.standByIsPaid || inputValues.standByIsBilled}/>
                                    </div>
                                      
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Excess Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.paidStandExMin}   onChange={(e) =>   changeStandByField('paidStandExMin', e.value, false)} disabled={inputValues.standByIsPaid}/>
                                    </div>
                                </div>
                              
                            </div>):(<div  className="mbsc-row" style={{margin:"0"}}> 
                                <div className="mbsc-col-xl-4" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Load Allowed</span> 
                                        <AutoSelectInputNumber   value={inputValues.standLA}   onChange={(e) =>changeStandByField('standLA', e.value, true)} disabled={inputValues.standByIsPaid || inputValues.standByIsBilled} />
                                    </div>
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Dump Allowed</span> 
                                        <AutoSelectInputNumber   value={inputValues.standDA}   onChange={(e) => changeStandByField('standDA', e.value, true)} disabled={inputValues.standByIsPaid || inputValues.standByIsBilled}/>
                                    </div>
                                </div>
                            
                                <div className="mbsc-col-xl-4" style={{padding:"0", paddingRight:"1em"}}> 
                               
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup">Excess Load Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.totalExcessLoad}   onChange={(e) => changeStandByField('totalExcessLoad', e.value, false)} disabled={inputValues.standByIsBilled}/>
                                    </div>
                                       
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Excess Dump Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.totalExcessDump}   onChange={(e) => changeStandByField('totalExcessDump', e.value, false)} disabled={inputValues.standByIsBilled}/>
                                    </div>
                                </div>
                                <div className="mbsc-col-xl-4" style={{padding:"0", paddingRight:"1em"}}> 
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Bill Excess Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.standExMin}   onChange={(e) => handleFieldChange('standExMin', e.value, true)} disabled={inputValues.standByIsBilled}/>
                                    </div>
                                    
                                    <div className="p-inputgroup " >
                                        <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Excess Stand By</span> 
                                        <AutoSelectInputNumber   value={inputValues.paidStandExMin}   onChange={(e) => changeStandByField('paidStandExMin', e.value, false)} disabled={inputValues.standByIsPaid} />
                                    </div>
                                </div>
                            </div>)}
                        </Panel>)}
                    </div>
                )}
                
                <input type='file' id={'freightUpload'}    onChange={(event,inst) => startUpload(event)} style={{display:'none'}} base-sixty-four-input="true"/>
                <div  className="mbsc-row" style={{margin:"0"}}> 
                    <Panel header="Billing Totals" className="mbsc-col-xl-6 freight-panel">
                        <div  className="mbsc-row" style={{margin:"0"}}> 
                            <div className="mbsc-col-xl-6" style={{paddingLeft:"0"}}>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Qty </span> 
                                    <AutoSelectInputNumber   value={inputValues.billedQty}   onChange={(e) => handleFieldChange('billedQty', e.value, true)} disabled={inputValues.truckingBilled} />
                                </div>
                                <div className="p-inputgroup ">
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate Type</span>
                                    <Dropdown value={inputValues.BillType}  onChange={(e) => handleFieldChange('BillType', e.value, true)} options={billTypes} optionLabel="text"
                                      disabled={inputValues.truckingBilled}   placeholder="Pay Type" className="w-full md:w-14rem" />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Rate </span> 
                                    <AutoSelectInputNumber   value={inputValues.BillRate}   onChange={(e) => handleFieldChange('BillRate', e.value, true)} disabled={inputValues.truckingBilled}  />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Stand by Rate </span> 
                                    <AutoSelectInputNumber   value={inputValues.standBR}  isCurrency={true} onChange={(e) => handleFieldChange('standBR', e.value, true)} disabled={inputValues.standByIsBilled} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Fuel Surcharge %</span> 
                                    <AutoSelectInputNumber   value={inputValues.FuelCharge}   onChange={(e) => handleFieldChange('FuelCharge', e.value, true)} disabled={inputValues.truckingBilled}  />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Broker Fee % </span> 
                                    <AutoSelectInputNumber   value={inputValues.billedBrokerPercent}   onChange={(e) => handleFieldChange('billedBrokerPercent', e.value, true)} disabled={inputValues.truckingBilled} />
                                </div>
                            </div>
                            <div className="mbsc-col-xl-6" style={{paddingLeft:"0"}}>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Trucking </span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.truckingBilled}  value={inputValues.tBilled}   onChange={(e) => handleFieldChange('tBilled', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Expenses</span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingBilled} isCurrency={true} value={inputValues.billedExpenses}   onChange={(e) => handleFieldChange('billedExpenses', e.value)} />
                                </div>
                                {inputValues.SellMaterial && (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Material</span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.materialBilled}  isCurrency={true} value={inputValues.MaterialTotal}   onChange={(e) => handleFieldChange('MaterialTotal', e.value)} />
                                </div>)}
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Stand by  </span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.standByIsBilled}  isCurrency={true} value={inputValues.standBilled}   onChange={(e) => handleFieldChange('standBilled', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Fuel </span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.truckingBilled}  isCurrency={true}  value={inputValues.fuelBilled}   onChange={(e) => handleFieldChange('fuelBilled', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Broker</span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.truckingBilled} isCurrency={true} value={inputValues.bFee}   onChange={(e) => handleFieldChange('bFee', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Total</span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingBilled} isCurrency={true} value={inputValues.bTotal}   onChange={(e) => handleFieldChange('bTotal', e.value)} />
                                </div>
                            </div>
                        </div>
                    </Panel>
                    <Panel header="Pay Totals" className="mbsc-col-xl-6 freight-panel">
                        <div  className="mbsc-row" style={{margin:"0"}}> 
                            <div className="mbsc-col-xl-6" style={{paddingLeft:"0"}}>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Qty </span> 
                                    <AutoSelectInputNumber   value={inputValues.paidQty}   onChange={(e) => handleFieldChange('paidQty', e.value, true)}  disabled={inputValues.truckingPaid} />
                                </div>
                                   
                                <div className="p-inputgroup ">
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup">Rate Type</span>
                                    <Dropdown value={inputValues.PayType}  onChange={(e) => handleFieldChange('PayType', e.value, true)} options={payTypes} optionLabel="text"
                                      disabled={inputValues.truckingPaid}  placeholder="Pay Type" className="w-full md:w-14rem" />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Rate </span> 
                                    <AutoSelectInputNumber   value={inputValues.PayRate}   onChange={(e) => handleFieldChange('PayRate', e.value, true)} disabled={inputValues.truckingPaid}/>
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Stand by Rate </span> 
                                    <AutoSelectInputNumber   value={inputValues.standPR} isCurrency={true}   onChange={(e) => handleFieldChange('standPR', e.value, true)} disabled={inputValues.standByIsPaid}/>
                                </div>
                               {!freightBill.Subhauler && !freightBill.PayType.includes('Percent') && (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Travel Rate</span> 
                                    <AutoSelectInputNumber   value={inputValues.travelRate}   onChange={(e) => handleFieldChange('travelRate', e.value, true)} disabled={inputValues.truckingPaid}/>
                                </div>)} 
                              {freightBill.PayType.includes('Percent') && (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Percent</span> 
                                    <AutoSelectInputNumber   value={inputValues.driverPercent}   onChange={(e) => handleFieldChange('driverPercent', e.value, true)} disabled={inputValues.truckingPaid}/>
                                </div>)}
                                {freightBill.Subhauler && (  <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Broker % </span> 
                                    <AutoSelectInputNumber   value={inputValues.paidBrokerPercent}   onChange={(e) => handleFieldChange('paidBrokerPercent', e.value, true)} disabled={inputValues.truckingPaid} />
                                </div>)}
                                {freightBill.Subhauler && ( <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Trailer % </span> 
                                    <AutoSelectInputNumber   value={inputValues.trailerPercent}   onChange={(e) => handleFieldChange('trailerPercent', e.value, true)} disabled={inputValues.truckingPaid} />
                                </div>)}
                            </div>
                            <div className="mbsc-col-xl-6" style={{paddingLeft:"0"}}>

                                {inputValues.PayType==='Hour' &&  !inputValues.Subhauler && inputValues.overtimeTotal>0 &&( <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  ><Button onClick={(e)=>setShowOvertime(true)}  >Overtime</Button> </span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.truckingPaid}  value={inputValues.overtimeTotal}  isCurrency={true}  onChange={(e) => handleFieldChange('overtimeTotal', e.value)} />
                                </div>)}
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Trucking </span> 
                                    <AutoSelectInputNumber disabled={!inputValues.billingOverride || inputValues.truckingPaid}  value={inputValues.totalDriverPay}  isCurrency={true}  onChange={(e) => handleFieldChange('totalDriverPay', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Expenses</span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride} value={inputValues.paidExpenses} isCurrency={true}  onChange={(e) => handleFieldChange('paidExpenses', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Stand by  </span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.standByIsPaid} value={inputValues.standPaid} isCurrency={true}  onChange={(e) => handleFieldChange('standPaid', e.value)} />
                                </div>
                                {!freightBill.Subhauler && !freightBill.PayType.includes('Percent') && (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Travel  </span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingPaid} value={inputValues.paidTravelTime} isCurrency={true}  onChange={(e) => handleFieldChange('paidTravelTime', e.value)} />
                                </div>)}
                                {freightBill.Subhauler &&  (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Broker </span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingPaid} value={inputValues.paidBrokerFee} isCurrency={true}  onChange={(e) => handleFieldChange('paidBrokerFee', e.value)} />
                                </div>)}
                                {freightBill.Subhauler &&  (<div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Trailer </span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingPaid} value={inputValues.tFee} isCurrency={true}  onChange={(e) => handleFieldChange('tFee', e.value)} />
                                </div>)}
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Total</span> 
                                    <AutoSelectInputNumber  disabled={!inputValues.billingOverride || inputValues.truckingPaid} isCurrency={true} value={inputValues.tPaid}   onChange={(e) => handleFieldChange('tPaid', e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-small-inputgroup"  >Net Profit</span> 
                                    <AutoSelectInputNumber disabled={true} isCurrency={true}  value={inputValues.profit}   onChange={(e) => handleFieldChange('profit', e.value)} />
                                </div>
                            </div>
                        </div>
                    </Panel>
                </div>
                <Image downloadable={true} src={inputValues.Picture} ref={freightImageRef} alt="Image" zoomSrc={inputValues.Picture} height="100"  style={{display:"none",margin:0, width:100}}  preview/>
                <OvertimeDialog visible={showOvertime} setVisible={setShowOvertime} freightbill={inputValues}  header='Overtime' />
            </div>)}
        </div>
    )
}


export default FreightBillEdit