import React from 'react';
import { InputText} from 'primereact/inputtext';

const InputTextParent = ({ onFocus, isCurrency, value, dontUseGrouping, ...props }) => {
  //  console.log('we loading an auto select input number');

    
    const handleFocus = (e) => {
        e.target.select();
   
        if (onFocus) {
            onFocus(e); // Call any existing onFocus if provided
        }
    };
    const focusNextElement = (currentElement) => {
        const focusableElements = 'button, [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled])';
        const elements = Array.from(document.querySelectorAll(focusableElements));
        const currentIndex = elements.indexOf(currentElement);
        if (currentIndex > -1 && currentIndex < elements.length - 1) {
            elements[currentIndex + 1].focus();
        }
    };

    const handleKeyDown = (event) => {

     if (event.key === 'Enter' ) {
            focusNextElement(event.target);
            event.preventDefault();
        }
    };
    // Convert empty string to null
  

    return <InputText {...props} value={ value ? value : ''}  onKeyDown={(e) => handleKeyDown(e)} onFocus={handleFocus} />;
};

export default InputTextParent;
