import React from 'react';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { useGlobal } from '../context/GlobalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faGear,faMoneyCheckDollar, faMagnifyingGlass, faUsersGear, faBuildingUser, faUserGroup, faCubesStacked, faHouse, faGasPump,
    faFileInvoiceDollar, faReceipt, faFileInvoice, faTableList, faTrailer,faClipboardList, faMoneyCheck, faLandmark, faTruckRampBox
} from '@fortawesome/free-solid-svg-icons';

const TopMenuComponent = (props) => {
    const navigate = useNavigate();
    const { quickbooks, company} = UserAuth();
    const { newAccount,  newLocation, newMaterial, newContact, 
		newDriver, newTruckType, newDefaultName, newTruck, newTrailer, 
		newCapability,  globalDispDate, globalFreightDate, topMenuOptions,
		newFuelCard, newExpense,logout, newEmployee} = useGlobal();

  
    const encodedDispDate = encodeURIComponent( globalDispDate); 
    const encodedFreightDate = encodeURIComponent( globalFreightDate); 
 
    const iconMapping = { faTrailer:faTrailer, faUsersGear: faUsersGear, faTruck: faTruck, faGear:faGear, faBuildingUser: faBuildingUser, faFileInvoiceDollar:faFileInvoiceDollar,
        faTableList:faTableList, faClipboardList:faClipboardList,faMoneyCheck:faMoneyCheck, faLandmark: faLandmark, faCubesStacked: faCubesStacked, faTruckRampBox: faTruckRampBox,
        faMoneyCheckDollar: faMoneyCheckDollar,faMagnifyingGlass:faMagnifyingGlass, faUserGroup:faUserGroup, faReceipt:faReceipt, faFileInvoice: faFileInvoice, faGasPump: faGasPump}; 
    const itemRenderer = (item) => {

        let className = "flex align-items-center p-menuitem-link";
        className = item.className ? "flex align-items-center p-menuitem-link": "flex align-items-center p-menuitem-link submenu-item";
        if(item.label==='New' || item.label==='List' || item.label==='Search')  className= "flex align-items-center p-menuitem-link sub-submenu-item";
      
        return(
        <a className={className}  onClick={(event) => {if (item.function)  item.function(event); else if (item.url)  navigate(item.url); }}
            style={{  cursor: 'pointer'  }} >
            
            {item.icon  ? (<span className={item.icon} />):
            (<div>
                {item.fontAwesomeIcon  && (<FontAwesomeIcon className="fas"  icon={iconMapping[item.fontAwesomeIcon]} style={{ color: item.fontAwesomeColor,  marginTop:'.1em', paddingRight:"0", height: "1.1em", width: "1.2em" }} />)}
            </div>)}

            <span className="mx-2">{item.label}</span>
            {item.quickbooks?.length>0 && (<>
                <span style={{paddingLeft:".5em", color:'red', marginRight:"0"}} className="p-menuitem-icon pi pi-bell" />
                <span style={{color:'red'}}>{quickbooks.length}</span>
                </>
            )}
            {item.items && (
                <span style={{paddingLeft:".5em"}} className="p-menuitem-icon pi pi-chevron-down ml-auto" />
            )}
        </a>
    )};
    const quickbookItems = company.downloadIIF ?  [
						
	
		{
			label: 'Customers',
			icon: 'pi pi-search',
			template: itemRenderer,
			url:'/quickbooks/customers'
		},
		{
			label: 'Vendors',
			icon: 'pi pi-search',
			template: itemRenderer,
			url:'/quickbooks/vendors'
		},
		{
			label: 'Items',
			icon: 'pi pi-search',
			template: itemRenderer,
			url:'/quickbooks/items'
		},
	]: [];
    quickbooks.forEach(qb => {
        let url = qb.Type==='Invoice' ? '/invoice/invoice/'+qb.ID : '/paystatement/paystatement/'+qb.ID;
        let QBItem = {
            label: qb.Type + ' - ' +qb.TransactionNum,
            icon: 'pi pi-calendar',
            template: itemRenderer,
            url: url 
        
        }
        quickbookItems.push(QBItem);
       
    })
    const items = [
        
        {
            label: 'Jobs/Dispatches',
             icon:"pi pi-globe",
             template: itemRenderer,
             className:'jobsdispatches',
            items: [
                {
                    label: 'Jobs',
                    template: itemRenderer,
                  
                    icon: 'pi pi-building',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            url: '/dispatch/NewJob'
                        },
                        {
                            label: 'Search',
                            icon: 'pi pi-search',
                            template: itemRenderer,
                            url:'/dispatch/search/Jobs'
                        }
                    ]
                }, {
                    label: 'Dispatches',
                    template: itemRenderer,
                    icon: 'pi pi-map',
                    items: [
                        {
                            label: 'Calendar',
                            icon: 'pi pi-calendar',
                            template: itemRenderer,
                            url: '/dispatch/home/'+encodedDispDate
                        
                        },
                        {
                            label: 'Search',
                            icon: 'pi pi-search',
                            template: itemRenderer,
                              url:'/dispatch/search/Dispatches'
                        }
                    ]
                }, {
                    label: 'Prelims Search',
                    icon: 'pi pi-search',
                    template: itemRenderer,
                     url:'/dispatch/prelimSearch'
                }
            ]
        },{
            label: 'Directory',
            icon: 'pi pi-book',
            template: itemRenderer,
            className:'jobsdispatches',
            items: [
                {
                    label: 'Accounts',
                    template: itemRenderer,
                    fontAwesomeIcon: 'faBuildingUser',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: newAccount
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                            url:'/accounts'
                        }
                    ]
                },
                {
                    label: 'Contacts',
                    template: itemRenderer,
                    icon: 'pi pi-users',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,  
                            function: newContact
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                             url:'/contacts'
                        }
                    ]
                },
                {
                    label: 'Locations',
                    template: itemRenderer,
                    icon: 'pi pi-map-marker',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: newLocation
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                              url:'/locations'
                        }
                    ]
                },
                {
                    label: 'Drivers',
                    template: itemRenderer,
                  	fontAwesomeIcon:'faUserGroup',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function:newDriver
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,   
                            url:'/drivers'
                        },
                        {
                            label: 'Priority',
                            icon: 'pi pi-sort-alt',
                            template: itemRenderer,
                             url:'/priority'
                         
                        }
                    ]
                },
				{
                    label: 'Employees',
                    template: itemRenderer,
                    icon: 'pi pi-users',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,  
                            function: newEmployee
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                             url:'/employees'
                        }
                    ]
                },
            ]
        },
        {
            label: 'Billing',
            template: itemRenderer,
            fontAwesomeIcon:'faLandmark',
            className:'billing',
            items: [
                {
                    label: 'Freight Bills',
                    template: itemRenderer,
                    fontAwesomeIcon:'faReceipt',
                    items: [
                        {
                            label: 'Dashboard',
                            icon: 'pi pi-calendar',
                            template: itemRenderer,
                            url:'/freightbill/dashboard/'+encodedFreightDate
                        },
                        {
                            label: 'Search',
                            icon: 'pi pi-search',
                            template: itemRenderer,
                            url:'/freightbill/search'
                        }
                    ]
                },
                {
                    label: 'Invoices',
                    template: itemRenderer,
                    fontAwesomeIcon: 'faFileInvoice',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            url:'/invoice/create',
                            template: itemRenderer
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            url:company.downloadIIF ? '/invoice/listQuickbooks' : '/invoice/list',
                            template: itemRenderer
                        }
                    ]
                },
                {
                    label: 'Pay Statements',
                    template: itemRenderer,
                    fontAwesomeIcon:'faMoneyCheck',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            url:'/paystatement/create',
                            template: itemRenderer
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            url:'/paystatement/list',
                            template: itemRenderer
                        }
                    ]
                },
              
                {
                    label: 'Expenses',
                    template: itemRenderer,
                    fontAwesomeIcon:'faTableList',
                    items: [
						{
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function:newExpense
                        },
                        {
                            label: 'Search',
                            icon: 'pi pi-search',
                            template: itemRenderer,
                            url: '/expenses'
                        }
                    ]
                },
            ]
        },
        {
            label: 'Inventory',
            template: itemRenderer,
            className:'Inventory',
             fontAwesomeIcon:'faClipboardList',
            items: [
                {
                    label: 'Trucks',
                    template: itemRenderer,
                    fontAwesomeIcon:'faTruck',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function:newTruck
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                            url:"/trucks"
                        }
                    ]
                },
                {
                    label: 'Trailers',
                    template: itemRenderer,
                    fontAwesomeIcon:'faTrailer',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function:newTrailer
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                              url:"/trailers"
                        }
                    ]
                },
                {
                    label: 'Truck Types',
                    template: itemRenderer,
                    fontAwesomeIcon:'faTruckRampBox',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: newTruckType
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                            url:"/trucktypes"
                        }
                    ]
                },
                {
                    label: 'Materials',
                    template: itemRenderer,
                    fontAwesomeIcon:'faCubesStacked',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: newMaterial
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                            url:"/materials"
                        }
                    ]
                },
				{
					label: 'Fuel Cards',
					template: itemRenderer,
					fontAwesomeIcon:'faGasPump',
					items: [
						{
							label: 'New',
							icon: 'pi pi-plus',
							template: itemRenderer,
							function: newFuelCard
						},
						{
							label: 'List',
							icon: 'pi pi-list',
							template: itemRenderer,
							url:"/fuelcards"
						}
					]	
				}
            ]
        },
        {
            label: 'Miscellaneous',
            template: itemRenderer,
             className:'jobsdispatches',
            icon: 'pi pi-ellipsis-v',
            items: [
                {
                    label: 'Expense Names',
                    template: itemRenderer,
                    fontAwesomeIcon:'faTableList',
                    items: [
                        {
                            label: 'New ',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: () => newDefaultName('Expense')
                       
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                            url:"/expensenames"
                        }
                    ]
                },
                {
                    label: 'Compliance Names',
                    template: itemRenderer,
                    icon: 'pi pi-palette',
                    items: [
                     
                        {
                            label: 'Driver',
                            template: itemRenderer,
                            items:[
                                {
                                    label: 'New',
                                    icon: 'pi pi-palette',
                                    template: itemRenderer,
                                    function: () => newDefaultName('DriverCompliance')
                              
                                },
                                {
                                    label: 'List',
                                    icon: 'pi pi-palette',
                                    template: itemRenderer,
                                    url:'/compliancenames/driver'
                                },
                            ]
                        },
                        {
                            label: 'Truck',
                            template: itemRenderer,
                            items:[
                                {
                                    label: 'New',
                                    icon: 'pi pi-palette',
                                    template: itemRenderer,
                                    function: () => newDefaultName('TruckCompliance')
                              
                                },
                                    {
                                        label: 'List',
                                        icon: 'pi pi-palette',
                                        template: itemRenderer,
                                        url:'/compliancenames/truck'
                                    },
                            ]
                        },
                        {
                            label: 'Trailer',
                            template: itemRenderer,
                            items:[
                                {
                                    label: 'New',
                                    icon: 'pi pi-palette',
                                    template: itemRenderer,
                                    function: () => newDefaultName('TrailerCompliance')
                              
                                },
                                    {
                                        label: 'List',
                                        icon: 'pi pi-palette',
                                        template: itemRenderer,
                                        url:'/compliancenames/trailer'
                                    },
                            ]
                        },
                    ]
                },
                {
                    label: 'Compliances',
                    template: itemRenderer,
                    icon: 'pi pi-palette',
                    items: [
                     
                        {
                            label: 'Driver Compliances',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                            url:'/report/compliance/Driver'
                        },
                        {
                            label: 'Outside Driver Compliances',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                             url:'/report/compliance/OutsideDriver'
                            
                            
                        },
                        {
                            label: 'Truck Compliances',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                              url:'/report/compliance/Truck'
                          
                        },
                        {
                            label: 'Trailer Compliances',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                             url:'/report/compliance/Trailer'
                            
                            
                        },
                       
                     
                    ]
                },
                {
                    label: 'Capabilities',
                    template: itemRenderer,
                    icon: 'pi pi-palette',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                            template: itemRenderer,
                            function: newCapability
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                             url:"/capabilities"
                        }
                    ]
                },
                {
                    label: 'Reports',
                    template: itemRenderer,
                    icon: 'pi pi-palette',
                    items: [
                        {
                            label: 'New',
                            icon: 'pi pi-plus',
                             template: itemRenderer,
                             url:'/report/reportHome/create/Drivers'
                        },
                        {
                            label: 'List',
                            icon: 'pi pi-list',
                            template: itemRenderer,
                               url:'/report/reportHome/list/Drivers'
                        }, 
                        {
                            label: 'Jobs - Profit',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                                url:'/jobProfit/home'
                        }, 
                        {
                            label: 'Jobs - Prevailing Wage',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
                            url:'/prevailingwage/home'
                        },
						{
                            label: 'Jobs - New Prevailing Wage',
                            icon: 'pi pi-palette',
                            template: itemRenderer,
							items: [
								{
									label: 'New',
									icon: 'pi pi-plus',
									template: itemRenderer,
									url:'/newprevailingwage/create'
								},
								{
									label: 'List',
									icon: 'pi pi-list',
									template: itemRenderer,
									 url:'/newprevailingwage/list'
								}
							]
                           
                        }
                    ]
                },
            ]
        },{
            label: 'Administration',
            template: itemRenderer,
            className:'Administration',
            quickbooks:quickbooks,
            fontAwesomeIcon:'faUsersGear',
            items: [
                {
                    label: 'Tablets',     
                    icon: 'pi pi-palette',
                    template: itemRenderer,
                    url:"/tablets"
                },
                {
                    label: 'Settings',     
                    icon: 'pi pi-palette',
                    template: itemRenderer,
                    url:"/settings"
                },
                
				{
					label: 'Quickbooks',
					icon: 'pi pi-palette',
					items:quickbookItems,
					template: itemRenderer,
					quickbooks:quickbooks
				},
						
             
                {
                    label: 'Logout',
                    icon: 'pi pi-palette',
                    template: itemRenderer,
                    function: logout
                }
            ]
        }
        
    ];
    for (let q = 0; q < topMenuOptions.length; q++) {
        topMenuOptions[q].template = itemRenderer;
        topMenuOptions[q].className = 'top-menu-options'; // Add this line to identify items
        for (let k = 0; k < topMenuOptions[q].items?.length; k++) {
            topMenuOptions[q].items[k].template = itemRenderer;
        }
       
    }

    const goToDispatchHome = () =>{
        navigate('/dispatch/home/'+ encodedDispDate)
    }
    const topMenuOptionsWrapper = (
    
            <div>
                {topMenuOptions?.length ? (<Menubar  model={topMenuOptions}  breakpoint="1600px" className="top-menu-options-wrapper" pt={{root: { 'data-label': 'Edit' }}}   style={{padding:'0'}}/>):<div></div>} 
            </div>
       )
    

       const homeButton = (
            <FontAwesomeIcon className="fas" onClick={(event) => {goToDispatchHome(); }}  icon={faHouse} style={{  cursor:'pointer', marginTop:'.1em',paddingLeft:'.5em', paddingRight:"0", height: "1.1em", width: "1.2em" }} />
       );



return (
<div className="menubar-container">
    <Menubar model={items}  start={homeButton} className="custom-menubar" style={{padding:"0px"}}  end={topMenuOptionsWrapper} />
</div>
)
};

export default TopMenuComponent;