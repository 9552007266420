
import React, {useState, useEffect, useCallback} from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../context/AuthContext';
import { Button}  from 'primereact/button';

import { useJobsProfit } from '../../context/JobsProfitContext';


import debounce from 'lodash/debounce';
import {useParams, useNavigate } from 'react-router-dom';
const DispatchList = () => {

    const {formatDate,formatMoney } = UserAuth();
    const {queryWeekFreightBills,   queryJobDispatches,  jobDispatches, dispatches, dispatchRef, profitHomeFreights} = useJobsProfit();

    const {jobID, startDate, endDate } = useParams();
    const startDateValue =  new Date(startDate);
    const endDateValue = new Date(endDate);
    const [inputValues, setInputValues]= useState({StartDateValue:startDateValue, EndDateValue: endDateValue});
    const navigate = useNavigate();
    console.log('dispatches on load = ,', dispatches)
    useEffect(()=>{
    
    if(!dispatches)startJobsProfitPage(inputValues.StartDateValue, inputValues.EndDateValue);
    },[]);

    const addNewJob = (jobList, dispatch)=>{
        let newJob={
            ID:dispatch.JobID,
            Account:dispatch.Account,
            LoadSite:dispatch.LoadSite,
            DumpSite:dispatch.DumpSite,
            JobNumber:dispatch.JobNumber,
            jobDateValue: new Date(dispatch.JobDate),
            netPercent:dispatch.netPercent,
            netPercentNumber:dispatch.netPercentNumber,
            totalBilledNumber:dispatch.totalBilledNumber,
            totalPaidNumber:dispatch.totalPaidNumber,
            profitNumber:dispatch.profitNumber,
            totalPaid:dispatch.totalPaid,
            totalBilled:dispatch.totalBilled,
            profit:dispatch.profit,
            dispatches:[],
            dispatchCount:1
        }
        newJob.dispatches.push(dispatch);
        jobList.push(newJob);
    }
    const calculateDispatchProfit = (jobList, dispatch)=>{
        dispatch.totalBilledNumber = 0;
        dispatch.totalPaidNumber = 0;

        const dispatchFreightBills = profitHomeFreights.filter(freightBill => freightBill.dispatchID === dispatch.ID);
        for(var k=0; k<dispatchFreightBills.length; k++){
            dispatch.totalBilledNumber+=Number(dispatchFreightBills[k].bTotal);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidQty)*Number(dispatchFreightBills[k].PayRate);

            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].standPaid);
            if(dispatchFreightBills[k].paidExpenses) dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidExpenses);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].paidBrokerFee);
            dispatch.totalPaidNumber+=Number(dispatchFreightBills[k].tFee)
        
      
        }
        dispatch.profitNumber=dispatch.totalBilledNumber-dispatch.totalPaidNumber;
        if(dispatch.profitNumber!==0) dispatch.netPercentNumber =  Number(dispatch.profitNumber/dispatch.totalBilledNumber)*100;
        
        let foundJob = false;
        for(let j=0; j<jobList.length; j++){
            if(dispatch.JobID===jobList[j].ID){
                foundJob=true;
                jobList[j].totalBilledNumber+=Math.round((dispatch.totalBilledNumber + Number.EPSILON) * 100) / 100;
                jobList[j].totalPaidNumber+= Math.round((dispatch.totalPaidNumber+ Number.EPSILON) * 100) / 100;
                jobList[j].dispatchCount++;
            }
        }
        if(!foundJob)addNewJob(jobList, dispatch);

    }

    let jobList = [];
    for(let d=0; d<dispatches?.length; d++)calculateDispatchProfit(jobList, dispatches[d]);
    
    let sortedDispatches = [...jobDispatches].sort((a, b) => {
        if (a.QueryDate < b.QueryDate) return -1;
        if (a.QueryDate > b.QueryDate) return 1;
        return 0;
    });
  const [filteredDispatches, setFilteredDispatches]=useState(sortedDispatches);

    const [filters, setFilters] = useState({
         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'LoadSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'DumpSite.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    jobDateValue: { 
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    'Account.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
    BillType: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    useEffect(()=>{
            setFilteredDispatches([...sortedDispatches])
            setFilters({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                'LoadSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
                'DumpSite.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
                jobDateValue: { 
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
                },
                'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
                BillType:{ value: null, matchMode: FilterMatchMode.CONTAINS },
            })
        },[dispatches])
    const startJobsProfitPage = async(startDate, endDate)=>{
 
        queryWeekFreightBills(startDate, endDate);
        queryJobDispatches(startDate, endDate,jobID);
     

    }


   
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.jobDateValue, '/', 'MM/DD/YYYY');
    };   

    const buttonBodyTemplate = (rowData) => {
   
       
        return <Button color="primary"  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}} onClick={(e) => openDispatch(rowData)}>
          <span>Open</span></Button>;
    };
    const currencyBodyTemplate = (rowData, field) => {
       const formattedNumber =  Number(rowData[field]).formatMoney(2);
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
    const percentBodyTemplate = (rowData, field) => {
        const formattedNumber = rowData[field] ? Number(rowData[field]).formatMoney(0) : 0;
         return (
            <span style={{paddingRight:".5em", float:"right"}}>{formattedNumber}%</span>
         );
     };
    const openDispatch =(rowData) => {
            console.log('RUNNIGN DISPATCH SEARCH OPEN DISPATCH')
            dispatchRef.current = {...rowData};
            navigate('/jobProfit/dispatchView/'+rowData.ID);
    }
 
    const totalColumn = (fieldName)=>{
        let grandTotal = 0;
        filteredDispatches.forEach(job => {
            grandTotal+= job[fieldName] ? job[fieldName] : 0;
        });
    
       return '$'+ Number(grandTotal).formatMoney(2);
    }

          const debouncedFilter = useCallback(
                debounce((value) => {
                setFilteredDispatches([...value])
                }, 100),
                []
            );
        
            const handleFilterChange = (e) => {  debouncedFilter(e);   };
    return(
        <div className="card mbsc-grid mbsc-row">
            <div className="mbsc-col-xl-3 mbsc-offset-xl-2" style={{paddingRight:".5em"}}>  
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'} ,tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} disabled={true} />
                </div> 
            
            </div>
            <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
            
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                    <Calendar pt={{tableHeader:{'className':'dontResize'}, tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} disabled={true}/>
                </div>
            </div>
            <DataTable value={sortedDispatches} paginator rows={25}      onValueChange={(e)=>handleFilterChange(e)}  dataKey="index" filters={filters} header='Dispatches' filterDisplay="row" emptyMessage="No Dispatches found.">
                <Column  header="Job #"  body={(rowData) =>buttonBodyTemplate(rowData)}  />
                <Column 
                    field="jobDateValue" 
                    header="Job Date" 
                    dataType="date" 
                    sortable 
                    body={dateBodyTemplate}
                    filter 
                    filterField="jobDateValue"
                
                    filterElement={(options) => (
                        <Calendar 
                            value={options.value} 
                            onChange={(e) => options.filterCallback(e.value)} 
                            dateFormat="mm/dd/yy" 
                            placeholder="Search by Date"
                            className="p-column-filter"
                            pt={{
                                root: { className: 'w-full' }
                            }}
                        />
    )}  />
                <Column header="Customer" filter filterField="Account.Name" filterPlaceholder="Search by Customer"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>
            
                <Column header="Load Site" filter filterField="LoadSite.Name" filterPlaceholder="Search by Load Site"  body={(rowData) => rowData.LoadSite?.Name || 'N/A'}/>
                <Column header="Dump Site" filter filterField="DumpSite.Name" filterPlaceholder="Search by Dump Site"  body={(rowData) => rowData.DumpSite?.Name || 'N/A'}/>
                <Column header="Billed" sortable field="totalBilledNumber" footer={totalColumn('totalBilledNumber')} body={(rowData) => currencyBodyTemplate(rowData, 'totalBilledNumber')}  />
                <Column header="Paid" sortable field="totalPaidNumber" footer={totalColumn('totalPaidNumber')} body={(rowData) => currencyBodyTemplate(rowData, 'totalPaidNumber')}  />
                <Column header="Net" sortable field="profitNumber"  footer={totalColumn('profitNumber')} body={(rowData) => currencyBodyTemplate(rowData, 'profitNumber')}  />
                <Column  style={{ minWidth: '6rem' }} header="Net %" sortable field="netPercentNumber" body={(rowData) => percentBodyTemplate(rowData, 'netPercentNumber')}  />
            </DataTable>
    </div>
    )
}


export default DispatchList;
