import React, {useEffect, useRef, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import InputTextParent from '../InputComponents/InputTextParent'; 

import { Button } from 'primereact/button';


import { useGlobal } from '../../context/GlobalContext'
import { UserAuth } from '../../context/AuthContext'
import AutoCompleteInput from '../InputComponents/AutoCompleteInput'; 


const FuelCardPopUp = (props) => {

    const { fuelCardVisible, setFuelCardVisible, fuelCard, setFuelCard, formatDate} = useGlobal();
    const { updateDocument, addDocument, fuelCards, drivers, employees} = UserAuth();
 
    const [inputValues, setInputValues] = useState({});
    const saveButton = inputValues.ID ? 'Save' : 'Add';
    const isFormDirty = useRef(null);

	const createFuelDriverSuggestions = (drivers, employees) => {
		// Map drivers to the simplified format
		const driverSuggestions = drivers.filter(driver=> driver.Status==='Active').map(driver => ({
			ID: driver.ID,
			Name: driver.Name
		}));
		// Map employees to the simplified format
		const employeeSuggestions = employees.map(employee => ({
			ID: employee.ID,
			Name: employee.Name
		}));
		
		// Combine both arrays
		return [...driverSuggestions, ...employeeSuggestions];
	};

	const [fuelDriverSuggestions, setFuelDriverSuggestions] = useState([]);

	useEffect(() => {
		// Assuming drivers and employees are available from props or context
		const combinedSuggestions = createFuelDriverSuggestions(drivers, employees);
		setFuelDriverSuggestions(combinedSuggestions);
	}, [drivers, employees]); // Re-run when drivers or employees change

    const closeFuelCardPopUp = () => {

        console.log('about to close isFormDirty = '+ isFormDirty.current)
        if(isFormDirty.current){
            if (window.confirm("Changes you made are not saved. Are you sure you want to close?"))  {
                isFormDirty.current=false;
                setFuelCardVisible(false);
            }  
        }else  setFuelCardVisible(false);
           
      
    };
  
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'MM/DD/YYYY');
        console.log('formattedDate = ', formattedDate) 
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };

    useEffect(()=>{
        const handleBeforeUnload = (event) => {
            if (isFormDirty.current) {
                event.preventDefault();
                event.returnValue = ""; // Required for some browsers
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    },[]);


    useEffect(() => {
        if (fuelCard && Object.keys(fuelCard).length > 0) {
           
            setInputValues({
            
                ID:fuelCard.ID,
				CardNo:fuelCard.CardNo ? fuelCard.CardNo : '',
				Driver:fuelCard.Driver ? fuelCard.Driver : {ID:'', Name:''},
				Pin:fuelCard.Pin ? fuelCard.Pin : '',
				DieselOrGas:fuelCard.DieselOrGas ? fuelCard.DieselOrGas : '',
				IssuedDate:fuelCard.IssuedDate ? fuelCard.IssuedDate : '',
				IssuedDateValue:fuelCard.IssuedDate ? new Date(fuelCard.IssuedDate) : '',
		

            });

           
        }
    }, [fuelCard]);

     const handleFieldChange = (fieldName, value) => {
        isFormDirty.current=true;
        setInputValues((prev) => ({ ...prev, [fieldName]: value }));
      
    };

    const handleSaveFuelCard = async()=>{


        const fuelCardName = inputValues.CardNo?.trim();

        if (!fuelCardName) {
            alert('Please enter a name before saving.');
            return;
        }
        const fuelCardExists = fuelCards.some(
            (fuelCard) => fuelCard.Name.toLowerCase() === fuelCardName.toLowerCase() && fuelCard.ID!==inputValues.ID
        );

        if (fuelCardExists) {
            alert('An fuelCard with this name already exists.');
            return;
        }
        if(fuelCard.ID){
            updateDocument(inputValues, fuelCard.ID, 'FuelCards');
            isFormDirty.current=false;
            setFuelCardVisible(false);
        } 
        else {
            try {
                isFormDirty.current=false
                let tempID = await  addDocument(inputValues,  'FuelCards');
                setInputValues((prev) => ({ ...prev, ID: tempID }));
                let updatedFuelCard = inputValues;
                updatedFuelCard.ID=tempID;
                setFuelCard(updatedFuelCard);
				isFormDirty.current=false;
                setFuelCardVisible(false);
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        }
        
    
       
    }
   
    const footerContent = (
  
        <div style={{paddingTop:'1em', textAlign:'center'}}  className="flex align-items-center gap-2">
                <Button style= {{fontSize:'1.5em', width:'9em'}} label="Close" icon="pi pi-times"  onClick={() => closeFuelCardPopUp()} />
                <Button style= {{fontSize:'1.5em', width:'9em'}} label={saveButton} icon="pi pi-check" onClick={() => handleSaveFuelCard()}  />
     
        </div>
    
    );
return(
		<Dialog 
			header="Fuel Card Details" 
			visible={fuelCardVisible} 
			style={{ width: '45vw' }} 
			breakpoints={{ '960px': '55vw', '641px': '100vw' }} 
			footer={footerContent} 
			onHide={closeFuelCardPopUp}
		>
			
		
			<AutoCompleteInput 
				label="Driver" 
				fieldName="Driver" 
				field="Name" 
				value={inputValues.Driver} 
				suggestions={fuelDriverSuggestions}   
				setValue={setInputValues} 
				handleFieldChange={handleFieldChange} 
				hideAddCustom={true}
			/>

			<div className="p-inputgroup">
				<span className="p-inputgroup-addon">Card #</span>
				<InputTextParent value={inputValues.CardNo} onChange={(e) => handleFieldChange('CardNo', e.target.value)} />
			</div>
			<div className="p-inputgroup">
				<span className="p-inputgroup-addon">Pin #</span>
				<InputTextParent value={inputValues.Pin} onChange={(e) => handleFieldChange('Pin', e.target.value)} />
			</div>
			
			<div className="p-inputgroup">
				<span className="p-inputgroup-addon">Diesel Or Gas </span>
				<InputTextParent value={inputValues.DieselOrGas} onChange={(e) => handleFieldChange('DieselOrGas', e.target.value)} />
			</div>
			 <div className="p-inputgroup"  >
                <span className="p-inputgroup-addon " >Issued Date </span> 
                <Calendar 
					pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}} 
					value={inputValues.IssuedDateValue} 
					style={{width:"100%"}} 
					panelStyle={{minWidth: '300px', width: '300px'}} 
					onChange={(e) => handleDateChange( 'IssuedDate',e.value )} 
				/>
            </div>
			


					
		</Dialog>
	);
	};

export default FuelCardPopUp;