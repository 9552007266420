import React, {useState, useEffect, useRef} from 'react'

import { useDispatch } from '../context/DispatchContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../../context/AuthContext';
import { Button} from '@mobiscroll/react';
import PrelimList from '../Lists/PrelimList';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import AutoCompleteInput from '../../InputComponents/AutoCompleteInput'; 
import LoadingOverlay from '../../MiscComponents/LoadingOverlay';
import { db } from '../../../firebase';
import { query,  collection,onSnapshot, where, doc, updateDoc } from 'firebase/firestore';
import { classNames } from 'primereact/utils';

const PrelimSearch = () => {
    const { setDispatchState} = useDispatch();
    const {companies,accounts, locations, formatDate, gearedUser, deleteDocument} = UserAuth();
    const [searchJobs, setSearchJobs] = useState([]);
	const [searchPrelims, setSearchPrelims] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
	const [expandedRows, setExpandedRows] = useState({});
	

    const searchJobsRef = useRef(null);
	const searchPrelimsRef = useRef(null);

	const jobListenerRef = useRef(null);
	const prelimListenerRef = useRef(null);

    var todaysDate = new Date();
    let startDate =  new Date();
    startDate.setDate(todaysDate.getDate() - 90);

    const [inputValues, setInputValues]= useState({
        Account:{ID:'', Name:'No Account' }, 
        Location:{ID:'', Name:'No Location'},
        EndDateValue:todaysDate,
        StartDateValue:startDate ,
        EndDate:formatDate(todaysDate, '/', 'YYYY/MM/DD'),
        StartDate:formatDate(startDate, '/', 'YYYY/MM/DD')
    });


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        realJobDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
		realLatestDispatchDate: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] }  ,
        'Account.Name':{ value: null, matchMode: FilterMatchMode.CONTAINS },
       
		hasPrelimNotice: { value: null, matchMode: FilterMatchMode.EQUALS },
    });


	let sortedJobs = [...searchJobs].map(job => ({
		...job,
		hasPrelimNotice: searchPrelims.some(prelim => 
			prelim.JobID === job.ID && 
			prelim.text && 
			prelim.text.includes('Preliminary Notice')
		)
	})).sort((a, b) => {
        if (a.QueryDate > b.QueryDate) return -1;
        if (a.QueryDate < b.QueryDate) return 1;
        return 0;
    });

   
   

    useEffect(() => {
        if(companies.length>0){
            for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({ ...prev,  Company: companies[i] }));
        }
    }, [companies]);

    useEffect(()=>{setDispatchState('DispatchSearch')},[])

    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        setInputValues((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
    };
	const handleDeletePrelim = async (prelim) => {
        try {
            if (window.confirm("Are you sure you want to delete this Prelim?")) {
                deleteDocument(prelim, 'PrelimHistory');
            }
        } catch (error) {
            console.error("Error removing document: ", error);
        }
    };
    const dateBodyTemplate = (rowData, fieldName) => {
        return formatDate(rowData[fieldName], '/', 'MM/DD/YYYY');
    };  

    const buttonBodyTemplate = (rowData) => {

        const buttonText = rowData.JobNumber  ? rowData.JobNumber : 'Open';
         
        return <Button 
				color="primary"  
				style={{
					margin:"0", 
					marginTop:".5em", 
					marginLeft:".5em", 
					borderRadius:".5em", 
					paddingLeft:"1em !important", 
					paddingBottom: ".2em", 
					paddingTop: ".2em", 
					height:"100%"
				}} 
				onClick={(e) => openJob(rowData)
			}>
          	<span>{buttonText}</span>
		</Button>;
    };

    const changeCompany= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
      
    };
    const changeAccount= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
       
    };  
    const changeLocation= (fieldName, value) => {
        setInputValues((prev) => ({ ...prev,  [fieldName]: value }));
    
    };  

    const queryObjects = ()=>{
        queryJobsandPrelims(inputValues)
    }

    const openJob =(rowData) => {
		const encodedDate = encodeURIComponent(rowData.QueryDate); 
      
        console.log('RUNNIGN DISPATCH SEARCH OPEN prelim', rowData)
		let win;
		setDispatchState('JobPrelim');
      	 win = window.open(`/dispatch/edit/none/${rowData.ID}/${encodedDate}/JobPrelim`, '_blank'); 
		win.focus();
    }

	const rowExpansionTemplate = (data) => {
		const jobID = data.ID;
		const sortedPrelims = [...searchPrelims].filter(prelim => prelim.JobID === jobID).sort((a, b) => {
			return new Date(a.sortDate) - new Date(b.sortDate);
		}); 
		return (<div className="p-3">
			<div className="mb-3 p-2" style={{ backgroundColor: '#f8f9fa', borderRadius: '4px', display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
				{sortedPrelims.map((item, index) => (
					<div key={index} className="mbsc-row">
						< PrelimList  prelim={item} deletePrelim={handleDeletePrelim} />
						</div>
						))}
					
				</div>
			</div>)
	}
	const onRowToggle = (e) => {
        console.log('onRowToggle called with event:', e);
        setExpandedRows(e.data);
        
        const expandedRowKeys = Object.keys(e.data);
        for (const rowId of expandedRowKeys) {
            // If this row is newly expanded and we don't have its dispatch data yet
        
        }
    };

    const queryJobsandPrelims = (object) => {
		queryJobs(object);
		queryPrelims(object);
    }

    const queryJobs = (object) => {
       
        searchJobsRef.current = [];

        if (jobListenerRef.current) jobListenerRef.current();
        setIsLoading(true);
        let jobQuery  = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/Jobs`));
		jobQuery =   query(jobQuery, where("latestDispatchDate", ">=", object.StartDate), where("latestDispatchDate", "<=", object.EndDate), where("TrackPrelim", "==", true)) 
        if (object.Account.ID) jobQuery = query(jobQuery, where("Account.ID", "==", object.Account.ID));
     
        let unsubscribeDumpSite, unsubscribeLoadSite;


       	const handleJobChange = (change) => {
           	const tempJob = change.doc.data();
      
           	tempJob.ID = change.doc.id;
           	tempJob.Description = tempJob.LoadSite?.Name 
               ? (tempJob.LoadSite?.Name + ' - ' + (tempJob.DumpSite?.Name ? tempJob.DumpSite?.Name : 'No Dump Site')) 
               : ('No Load Site' + ' - ' + (tempJob.DumpSite?.Name ? tempJob.DumpSite?.Name : 'No Dump Site'));
           	tempJob.realJobDate=new Date(  tempJob.JobDate);

    		 tempJob.realLatestDispatchDate=new Date(  tempJob.latestDispatchDate);

           	tempJob.TrucksUnassigned = tempJob.TrucksOrdered 
			? tempJob.TrucksOrdered - (tempJob.TrucksAssigned ? tempJob.TrucksAssigned : 0) 
			: 0 -(tempJob.TrucksAssigned ? tempJob.TrucksAssigned : 0);

           	if (change.type === "added") {
				console.log('found a job' , tempJob)
               	const jobIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
			   	console.log('jobIndex = ', jobIndex)
               	if (jobIndex === -1) searchJobsRef.current.push(tempJob);
           	}
           	if (change.type === "modified") {
               	const jobIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
               	if (jobIndex !== -1) searchJobsRef.current[jobIndex] = tempJob;
           	}
           	if (change.type === "removed") {
               	const jobIndex = searchJobsRef.current.findIndex(d => d.ID === tempJob.ID);
               	if (jobIndex !== -1) searchJobsRef.current.splice(jobIndex, 1);
           	}	
   
       	};
   
        const processQuerySnapshot = (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => handleJobChange(change));
            
            // After processing all job changes, update with HasNotice property
            const updatedJobs = searchJobsRef.current.map(job => ({
                ...job,
                hasPrelimNotice: searchPrelims.some(prelim => 
                    prelim.JobID === job.ID && 
                    prelim.text && 
                    prelim.text.includes('Preliminary Notice')
                )
            }));
            
            searchJobsRef.current = updatedJobs;
            setSearchJobs([...searchJobsRef.current]);
            
            setIsLoading(false);
        };

       	if (object.Location.ID) {
           	const dumpSiteQuery = query(jobQuery, where("DumpSite.ID", "==", object.Location.ID));
           	unsubscribeDumpSite = onSnapshot(dumpSiteQuery, processQuerySnapshot);
   
           	const loadSiteQuery = query(jobQuery, where("LoadSite.ID", "==", object.Location.ID));
           	unsubscribeLoadSite = onSnapshot(loadSiteQuery, processQuerySnapshot);
   
           	jobListenerRef.current = () => {
               	unsubscribeDumpSite();
               	unsubscribeLoadSite();
           	};

		} else jobListenerRef.current = onSnapshot(jobQuery, processQuerySnapshot);
		
   	}
	
	const queryPrelims = (object) => {
		searchPrelimsRef.current=[];
		let prelimQuery = query(collection(db, `Organizations/${gearedUser.selectedOrgName}/PrelimHistory`));
		prelimQuery =  query(prelimQuery, where("QueryDate", ">=", object.StartDate), where("QueryDate", "<=", object.EndDate)) 
		
		const handlePrelimChange = (change) => {
			const tempPrelim = change.doc.data();
   
			tempPrelim.ID = change.doc.id;

			if (change.type === "added") {
			 console.log('found a prelim' , tempPrelim)
				const prelimIndex = searchPrelimsRef.current.findIndex(d => d.ID === tempPrelim.ID);
				console.log('prelimIndex = ', prelimIndex)
				if (prelimIndex === -1) searchPrelimsRef.current.push(tempPrelim);
			}
			if (change.type === "modified") {
				const prelimIndex = searchPrelimsRef.current.findIndex(d => d.ID === tempPrelim.ID);
				if (prelimIndex !== -1) searchPrelimsRef.current[prelimIndex] = tempPrelim;
			}
			if (change.type === "removed") {
				const prelimIndex = searchPrelimsRef.current.findIndex(d => d.ID === tempPrelim.ID);
				if (prelimIndex !== -1) searchPrelimsRef.current.splice(prelimIndex, 1);
			}	

		};
		const processQuerySnapshot = (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => handlePrelimChange(change));
            console.log('setting full home searchJobsRef.currents = ', searchPrelimsRef.current);
            setSearchPrelims([...searchPrelimsRef.current]);
      
            setIsLoading(false);
         
        };
		prelimListenerRef.current = onSnapshot(prelimQuery, processQuerySnapshot);
	}
	
    // Add this function to update the prelim document with formatted date
    const updatePrelimDate = async (rowData) => {
        try {
            setIsLoading(true);
            
            // Convert the sortDate to a YYYY/MM/DD format
            const date = new Date(rowData.PrelimDate);
            const formattedDate = formatDate(date, '/', 'YYYY/MM/DD');
            
            // Update the document in Firestore
            const prelimRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PrelimHistory`, rowData.ID);
            await updateDoc(prelimRef, {
                QueryDate: formattedDate
            });
            
            console.log(`Updated prelim ${rowData.ID} with formatted date: ${formattedDate}`);
            setIsLoading(false);
        } catch (error) {
            console.error("Error updating prelim date:", error);
            setIsLoading(false);
            // You might want to add error handling UI here
        }
    };
    
    // Add this template for the date conversion button
    const dateConversionButtonTemplate = (rowData) => {
        // Only show button if sortDate exists
        if (!rowData.PrelimDate) return null;
        
        return (
            <Button 
                color="secondary" 
                style={{
                    margin: "0", 
                    marginTop: ".5em", 
                    marginLeft: ".5em", 
                    borderRadius: ".5em", 
                    paddingLeft: "1em !important", 
                    paddingBottom: ".2em", 
                    paddingTop: ".2em", 
                    height: "100%"
                }} 
                onClick={() => updatePrelimDate(rowData)}
            >
                Format Date
            </Button>
        );
    };

    // Add this function to count prelims for each job
    const getPrelimCountForJob = (jobId) => {
        return searchPrelims.filter(prelim => prelim.JobID === jobId).length;
    };

    // Add this template for displaying the prelim count
    const prelimCountTemplate = (rowData) => {
        const count = getPrelimCountForJob(rowData.ID);
        return (
            <div className="text-center">
                <span >{count}</span>
            </div>
        );
    };

  
    const booleanFilterTemplate = (options) => {
        return (
          <TriStateCheckbox style={{borderColor:"#d1d5db", background:"white"}}
            value={options.value !== null ? options.value : undefined}
            onChange={(e) => options.filterApplyCallback(e.value)}
          />
        );
      };
    // Add this template for displaying the prelim notice indicator
	const booleanBodyTemplate = (rowData, field) => {
		let color = rowData[field] ? 'green' : 'red';
		return (
		<i style={{color:color}}
			className={classNames('pi', {
			'true-icon pi-check-circle': rowData[field],
			'false-icon pi-times-circle': !rowData[field]
			})}
		/>
		);
	};


    return(
        <div className="card mbsc-grid " >
            <div className=" mbsc-row" >
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <AutoCompleteInput label="Company" fieldName="Company" field="CompanyName" value={inputValues.Company} labelClass='p-inputgroup-addon search-auto-input'  suggestions={companies} setValue={setInputValues} handleFieldChange={changeCompany} />
                    <AutoCompleteInput label="Customer" fieldName="Account" field="Name" value={inputValues.Account} labelClass='p-inputgroup-addon search-auto-input'   databaseList={'Accounts'} suggestions={accounts} setValue={setInputValues} handleFieldChange={changeAccount} />
                    <AutoCompleteInput label="Load/Dump Site" fieldName="Location" field="Name" value={inputValues.Location} labelClass='p-inputgroup-addon search-auto-input' databaseList={'Locations'} suggestions={locations} setValue={setInputValues} handleFieldChange={changeLocation} />
                </div>
                <div className="mbsc-col-xl-3" style={{paddingRight:".5em"}}>  
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input">Start Date:</span>
                        <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}}  value={inputValues.StartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'StartDate',e.value )} />
                    </div> 
                    <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input"> End Date:</span>
                        <Calendar pt={{tableHeader:{'className':'dontResize'},tableHeaderCell:{'className':'dontResize'},day:{'className':'dontResize'}}}  value={inputValues.EndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'EndDate',e.value )} />
                    </div>
                    <div className="flex justify-content-end">
                        <Button  color="primary"  onClick={(e) => queryObjects()} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Search Prelims</Button> 
                        
                        {/* <Button  color="primary" onClick={(e) =>resetSearch() } style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Search Fields</Button> 
                       {searchedObject && (<Button  color="primary" onClick={(e) => setInputValues({...searchedObject})} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Reset Filters</Button> )}*/}

                    </div>
                </div>
            </div>
            <div className=" mbsc-row" >
                <DataTable 
					value={sortedJobs} 
					paginator 
					rows={25} 
					dataKey="ID" 
					filters={filters} 
					header='Jobs' 
					filterDisplay="row" 
					emptyMessage="No prelims found."
					expandedRows={expandedRows}
					onRowToggle={(e) => onRowToggle(e)}
					rowExpansionTemplate={rowExpansionTemplate}
				
				>
					<Column 
                    expander 
                    style={{ width: '3rem' }}
                    headerStyle={{ width: '3rem' }}
                />
				<Column pt={{root: { 'data-label': 'Job #' }}}  header="Job #"  body={(rowData) =>buttonBodyTemplate(rowData)}  />
				<Column pt={{root: { 'data-label': 'Job Date' }}}   field="realJobDate" header='Start Date' dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData,'realJobDate')}   filterPlaceholder="Search by Date" />
				<Column pt={{root: { 'data-label': 'Latest Dispatch' }}}   field="realLatestDispatchDate" header="Latest Dispatch" dataType="date" sortable body={(rowData) => dateBodyTemplate(rowData,'realLatestDispatchDate')}   filterPlaceholder="Search by Date" />
				<Column header="Customer" pt={{root: { 'data-label': 'Customer' }}}  filter filterField="Account.Name" filterPlaceholder="Search by Customer"  body={(rowData) => rowData.Account?.Name || 'N/A'}/>

				<Column pt={{root: { 'data-label': 'Description' }}}  header="Description" filter filterField="Description" filterPlaceholder="Search by Load Site"  body={(rowData) => rowData.Description || 'N/A'}/>
                <Column pt={{root: { 'data-label': 'Documents' }}} header="Documents" body={prelimCountTemplate} style={{ width: '120px', textAlign: 'center' }} />
                <Column 
                    pt={{root: { 'data-label': 'Has Notice' }}} 
                    header="Has Notice" 
					filter 
					dataType="boolean" 
					field="hasPrelimNotice"
					filterElement={booleanFilterTemplate}
					body={(rowData) => booleanBodyTemplate(rowData, 'hasPrelimNotice')} 
                    style={{ width: '120px', textAlign: 'center' }} 
                />

                </DataTable>
            </div>
            <LoadingOverlay isLoading={isLoading} message="Please wait..." />
        </div>
    )
}


export default PrelimSearch;
