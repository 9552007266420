import React, { useEffect, useState, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { InputNumber } from 'primereact/inputnumber';
import { db } from '../../../../firebase';
import { query, collection, onSnapshot, where } from 'firebase/firestore';

import { UserAuth } from '../../../../context/AuthContext';
import MapComponent from '../../../../components/MiscComponents/MapComponent'; 
import FreightStatusCard from '../../Lists/FreightStatusCard'
//import InputNumber from '../InputComponents/InputNumber'; 
import { useNavigate } from 'react-router-dom';

const DispatchStatus = ({ dispatchID, dispatch }) => {
    const [tabletList, setTabletList] = useState(null);
    const [updateState, setUpdateState]= useState(false);
    const { gearedUser, drivers, company } = UserAuth();
    const [driverFreightBills, setDriverFreightBills]=useState([]);
    const driverFBListenerRef = useRef(null);
    const driverFreightBillsRef = useRef(null);

    const fullTabletsRef = useRef(null);
    const dispatchTabletsRef = useRef(null);
    const deviceListenerRef = useRef(null);
    const dispatchRef = useRef(null);
    const mapComponentRef = useRef(null); // Create a ref for the MapComponent
    const navigate = useNavigate();

    useEffect(()=>{
        console.log('WE RUNNIN TEH DISPATHC STATUS USE EFECT BOIS');
        queryDriverFreightBills();
        queryTablets();
    },[])
    useEffect(()=>{
        dispatchRef.current = dispatch ? dispatch : {};
        if(dispatchRef.current?.QueryDate)syncDevicesWithFreights();
    },[dispatch?.QueryDate, dispatch?.StartTime])


    let HoursDelivered = 0, LoadsDelivered = 0, TonsDelivered = 0, LoadsProgress = 0, TonsProgress = 0;
    let AverageRoundTripCount = 0, FullAverageRoundTrip = 0, totalAverageRoundTripTime = 0, totalFullAverageRountTripTime = 0;
    let AverageRoundTrip = 0, AverageLoadTime = 0,  AverageDumpTime = 0, AverageHourlyBillRate = 0, AverageHourlyPayRate = 0;
    let totalLoadTime = 0, totalDumpTime = 0, totalLoadCount= 0, totalPaid = 0,  totalBilled = 0;

	const formatNumberField = (driverVariable)=>{
		return driverVariable ? Number(driverVariable) : 0;
	}
    const getFullAverageRoundTrip = (freightBill)=>{
        freightBill.FullAverageRoundTrip = freightBill.AverageRoundTrip || freightBill.AverageRoundTrip===0 ? Number(freightBill.AverageRoundTrip) : Number(freightBill.AverageRoundTrip);
        freightBill.FullAverageRoundTrip +=freightBill.AverageLoadTime || freightBill.AverageLoadTime===0 ? Number(freightBill.AverageLoadTime) : Number(freightBill.AverageLoadTime);
        freightBill.FullAverageRoundTrip +=freightBill.AverageDumpTime || freightBill.AverageDumpTime===0 ? Number(freightBill.AverageDumpTime) : Number(freightBill.AverageDumpTime);
    }
	console.log('driverFreightBills = ', driverFreightBills)
    for(let i= 0; i<driverFreightBills.length; i++){
        
        getFullAverageRoundTrip(driverFreightBills[i])
       
        HoursDelivered+= formatNumberField(driverFreightBills[i].tHours);
        LoadsProgress+=formatNumberField(driverFreightBills[i].progressLoads);
        LoadsDelivered+= formatNumberField(driverFreightBills[i].deliveredLoads);
        TonsProgress+= formatNumberField(driverFreightBills[i].progressTons);
        TonsDelivered+=formatNumberField(driverFreightBills[i].deliveredTons);

        if(Number(driverFreightBills[i].AverageRoundTrip)>0 || Number(driverFreightBills[i].AverageRoundTrip)>0){
            AverageRoundTripCount+=driverFreightBills[i].loads ? driverFreightBills[i].loads : driverFreightBills[i].loads;
            let freightTotalRoundTrip = driverFreightBills[i].loads && driverFreightBills[i].AverageRoundTrip ? driverFreightBills[i].loads * driverFreightBills[i].AverageRoundTrip :  driverFreightBills[i].loads* driverFreightBills[i].AverageRoundTrip;
            let freightTotalFullRoundTrip = driverFreightBills[i].loads ? driverFreightBills[i].loads * driverFreightBills[i].FullAverageRoundTrip : driverFreightBills[i].loads * driverFreightBills[i].FullAverageRoundTrip;
            totalAverageRoundTripTime += freightTotalRoundTrip;
            totalFullAverageRountTripTime += freightTotalFullRoundTrip;
        }
        if(Number(driverFreightBills[i].loads)>0){
            totalLoadTime+=Number(driverFreightBills[i].loads * driverFreightBills[i].AverageLoadTime);
            totalDumpTime+=Number(driverFreightBills[i].loads * driverFreightBills[i].AverageDumpTime);
            totalLoadCount+=Number(driverFreightBills[i].loads);
        }
        else if(Number(driverFreightBills[i].loads)>0){
            totalLoadTime+=Number(driverFreightBills[i].loads * driverFreightBills[i].AverageLoadTime);
            totalDumpTime+=Number(driverFreightBills[i].loads * driverFreightBills[i].AverageDumpTime);
            totalLoadCount+=Number(driverFreightBills[i].loads);
        }
        totalBilled+=formatNumberField(driverFreightBills[i].bTotal) 
        totalPaid+=formatNumberField(driverFreightBills[i].tPaid);
		console.log('adding tpaid ' + driverFreightBills[i].tPaid + ' form driver  = ' + driverFreightBills[i].driverName)
        driverFreightBills[i].billedHourlyRate= driverFreightBills[i].bTotal ? driverFreightBills[i].bTotal/driverFreightBills[i].driverHours : driverFreightBills[i].bTotal/driverFreightBills[i].tHours;
        driverFreightBills[i].paidHourlyRate= driverFreightBills[i].tPaid ? driverFreightBills[i].tPaid/driverFreightBills[i].driverHours: driverFreightBills[i].tPaid/driverFreightBills[i].tHours;

    }

    AverageHourlyBillRate=Math.round(totalBilled/HoursDelivered);
    AverageHourlyPayRate=Math.round(totalPaid/HoursDelivered);

    AverageDumpTime=Math.round(totalDumpTime/totalLoadCount);
    AverageLoadTime=Math.round(totalLoadTime/totalLoadCount);
    AverageRoundTrip=Math.round(totalAverageRoundTripTime/AverageRoundTripCount);
    FullAverageRoundTrip=Math.round(totalFullAverageRountTripTime/AverageRoundTripCount);

    const syncDevicesWithFreights = () =>{
        dispatchTabletsRef.current=[];
        if( fullTabletsRef.current &&  fullTabletsRef.current.length>0){
            for(let i=0; i<drivers.length; i++){
                for(let q=0; q<fullTabletsRef.current.length; q++){
                    if(drivers[i].deviceID===fullTabletsRef.current[q].ID){
                        fullTabletsRef.current[q].Driver=drivers[i];
                        fullTabletsRef.current[q].driverName=drivers[i].Name;
                        fullTabletsRef.current[q].driverID=drivers[i].ID;
                        if(drivers[i].Initials)fullTabletsRef.current[q].Initials=drivers[i].Initials;else fullTabletsRef.current[q].Initials='N/A';
                            
                        for (let j = 0; j <  driverFreightBillsRef.current.length; j++) {
                            if (driverFreightBillsRef.current[j].Driver === drivers[i].ID) {
                                let freightBill =  driverFreightBillsRef.current[j];
                                freightBill.lat = fullTabletsRef.current[q].location.lat;
                                freightBill.lng = fullTabletsRef.current[q].location.lng;
                                freightBill.locationTime = fullTabletsRef.current[q].lastUpdatedFormatted;
                                console.log('setting the time difference and the gps status = ', dispatchRef.current?.QueryDate)
                                if(dispatchRef.current?.QueryDate){
                                 
                                    const correctedDate = dispatchRef.current.QueryDate.replace(/\//g, '-');
                                    const dispatchTime = new Date(`${correctedDate}T${dispatchRef.current.StartTime}:00`);
                                    const freightTime = new Date(freightBill.locationTime);
                                    const timeDifference = dispatchTime - freightTime;
                                    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60);

                                    if (timeDifferenceInHours > 1)   freightBill.GPSStatus = 'Disabled';else freightBill.GPSStatus = 'Enabled';
                                }else freightBill.GPSStatus = 'Disabled';
                                dispatchTabletsRef.current.push({...fullTabletsRef.current[q]})
                            }
                        }
                      
                    }
                }
            }
            
            console.log('SETTING TALBETS LIST = ', fullTabletsRef.current)
            setTabletList([...fullTabletsRef.current])
            setDriverFreightBills([...driverFreightBillsRef.current]);

        }
    }

    const queryDriverFreightBills = () => {
        if (driverFBListenerRef.current) driverFBListenerRef.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/DriverFreightBills`;

        const q = query(collection(db, queryName), where("dispatchID", "==", dispatchID));
        driverFreightBillsRef.current = [];
        driverFBListenerRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var tempFB = change.doc.data();
                tempFB.ID = change.doc.id;
        
                const foundIndex = driverFreightBillsRef.current ? driverFreightBillsRef.current.findIndex(obj => obj.ID === tempFB.ID) : -1;
                if (change.type === "added") driverFreightBillsRef.current.push({ ...tempFB });
                if (change.type === "modified") driverFreightBillsRef.current[foundIndex] = { ...tempFB };
                if (change.type === "removed") driverFreightBillsRef.current.splice(foundIndex, 1);
            });
            console.log('Finished querying', driverFreightBillsRef.current);
            driverFreightBillsRef.current =  driverFreightBillsRef.current.sort((a, b) => a.loadOrder - b.loadOrder);
          
            syncDevicesWithFreights();
          
    
        });
    }
    const queryTablets = () => {
        if (deviceListenerRef.current) deviceListenerRef.current();
        const queryName = `Organizations/${gearedUser.selectedOrgName}/ScalefusionDevices`;
        fullTabletsRef.current = [];
        const deviceQuery = query(collection(db, queryName));
        deviceListenerRef.current = onSnapshot(deviceQuery, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                var tempDevice = change.doc.data();
                tempDevice.ID = change.doc.id;
                const formattedDate = formatTabletDate(tempDevice.location.created_at);
              
                tempDevice.lastUpdated = formattedDate.dateObject;  // Keep the Date object for sorting
                tempDevice.lastUpdatedFormatted = formattedDate.formattedDate;  // Store the formatted date for display
                const foundIndex = fullTabletsRef.current ? fullTabletsRef.current.findIndex(obj => obj.ID === tempDevice.ID) : -1;
                if (change.type === "added") fullTabletsRef.current.push({ ...tempDevice });
                if (change.type === "modified") fullTabletsRef.current[foundIndex] = { ...tempDevice };
                if (change.type === "removed") fullTabletsRef.current.splice(foundIndex, 1);
            });
            console.log('Finished querying');
            syncDevicesWithFreights();
          
    
        });
    }
    const formatTabletDate = (dateString) => {
        // Convert the string into a Date object
  
        dateString+='.000Z';
        const dateObject = new Date(dateString);

        // Format the date to the desired output in PST (Pacific Standard Time)
        const formattedDate = dateObject.toLocaleString('en-US', {
            timeZone: 'PST', // Set to PST
            dateStyle: 'short', // Formats as MM/DD/YY
            timeStyle: 'short'  // Formats time as hh:mm AM/PM
        });
   
        return { dateObject, formattedDate };
    };

    const getDistance = async(freight)=>{
        freight.Distance= await mapComponentRef.current.setDistanceForFreight(freight);
        console.log('freight.distance = ', freight.Distance)
        setUpdateState({...!updateState})
    }
    const openMobileFreight = (freight)=>{
   
        let orgName  = encodeURI(gearedUser.selectedOrgName).replace(/'/g, '%27')
        console.log('orgnaem =- '+ orgName);
        navigate(`/mobile/freightbill/${orgName}/${freight.ID}`);
    }
    return (
        <div className="mbsc-grid" style={{backgroundColor:'#efeff4'}} >
        {/* Dispatch Summary Section */}
        {dispatch?.Account?.Name && (
            <div className="mbsc-col-12 mbsc-col-xl-10 mbsc-offset-xl-1" style={{ padding: '0px', paddingRight: '10px' }}>
            <Panel header="Dispatch" style={{backgroundColor:'#efeff4'}}>
                <div className="mbsc-row mbsc-justify-content-start" style={{ color:'black'}}>
                <div className="mbsc-col-12 mbsc-col-md-3" style={{ padding: '0px', paddingLeft: '5px' }}>
                    <div style={{ fontWeight: 'bold', padding: '.5em' }}>{dispatch.Account.Name}</div>
                    <div style={{ padding:'.5em'}}>
                    {dispatch.JobDate} - {dispatch.StartTime}
                    </div>
                    <div style={{ padding:'.5em'}}>{dispatch.LoadSite?.Name}</div>
                    <div style={{ padding:'.5em'}}>{dispatch.DumpSite?.Name}</div>
                    <div style={{ padding:'.5em'}}>{dispatch.TruckType?.Name}</div>
                    <div style={{ padding:'.5em'}}>{dispatch.Material?.Name}</div>
                </div>
                <div className="mbsc-col-12 mbsc-col-md-9" style={{ padding: '0px', paddingLeft: '5px', paddingTop: '1em' }}>
                    {/* Load Information */}
                    <div className="mbsc-row" style={{ fontSize: '.75em' }}>
                    <div className="mbsc-col-12 mbsc-col-md-4">
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Loads Delivered</span>
                            <InputNumber value={LoadsDelivered || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Loads in Progress</span>
                            <InputNumber value={LoadsProgress || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Tons Delivered</span>
                            <InputNumber value={TonsDelivered || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Tons in Progress</span>
                            <InputNumber value={TonsProgress || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Hours Delivered</span>
                            <InputNumber value={HoursDelivered || 0} disabled />
                        </div>
                    </div>
                    <div className="mbsc-col-12 mbsc-col-md-4">
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Avg Round Trip (Total)</span>
                            <InputNumber value={FullAverageRoundTrip || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Avg Round Trip (Driving)</span>
                            <InputNumber value={AverageRoundTrip || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Avg Load Time</span>
                            <InputNumber value={AverageLoadTime || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Avg Dump Time</span>
                            <InputNumber value={AverageDumpTime || 0} disabled />
                        </div>
                    </div>
                    <div className="mbsc-col-12 mbsc-col-md-4">
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Hourly Bill Rate</span>
                            <InputNumber value={AverageHourlyBillRate || 0} disabled />
                        </div>
                        <div className="p-inputgroup dispatch-status-inputgroup">
                            <span className="p-inputgroup-addon dispatch-status-inputgroup-addon">Hourly Pay Rate</span>
                            <InputNumber value={AverageHourlyPayRate || 0} disabled />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </Panel>
            <Panel header="Drivers" style={{backgroundColor:'#efeff4'}}>
                <div className="mbsc-grid" style={{ padding: '0px' }}>
                    <div className="mbsc-row" style={{ paddingTop: '.5em', margin: '0', backgroundColor: '#efeff4'}}>
                        {driverFreightBills.map((disp) => (
                        <FreightStatusCard key={disp.ID} freight={disp} openMobileFreight={openMobileFreight}  getDistance={getDistance}/>
                        ))}
                    </div>
                </div>
            </Panel>

            <Panel header="Map" style={{marginBottom:'2em'}}>
                        <MapComponent dispatch={dispatch} tabletList={dispatchTabletsRef.current} company={company} ref={mapComponentRef}></MapComponent>      
                </Panel>

            </div>
        )}
        </div>
  );
};

export default DispatchStatus;
