import React, {useState, useEffect} from 'react'

import { usePrevailingWage } from './context/PrevailingWageContext';
import { usePrevailingWageReport } from './context/PrevailingWageReportContext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar} from 'primereact/calendar';
import { Button} from 'primereact/button';
import { Panel } from 'primereact/panel';

import { UserAuth } from '../../context/AuthContext';
import { useFindWeekDates } from './hooks/useFindWeekDates';
import AutoSelectInputNumber from '../InputComponents/AutoSelectInputNumber'; 
import InputTextParent from '../InputComponents/InputTextParent'; 
import { useNavigate } from 'react-router-dom';
import {useParams } from 'react-router-dom';
const PrevailingWageReport = () => {
    const { prevailingWageReport, setPrevailingWageReport }= usePrevailingWage();
    const { 
		prevailingWageReportRef, queryFreightBills,freightBillsRef,
		fetchPrevailingWageReport,  fetchPrevailingWageWeeks ,
		reportWeeks, setReportWeeks,  reportWeeksRef 
	} = usePrevailingWageReport();

	const navigate = useNavigate();
    const {createReportWeeks} = useFindWeekDates();
    const {formatDate ,updateDocument} = UserAuth();
    const { id} = useParams();

    console.log('reportWeeks in list= ', reportWeeks)
 
  
 
    useEffect(()=>{
        startReportListPage();
    },[])


    const startReportListPage = async () => {
        console.log('we are starting the report list page', prevailingWageReport?.ID);
        console.log('and id is ', id);
        setReportWeeks([]);
        reportWeeksRef.current = [];
        
        try {
            // First wait for both report and weeks to load
            await Promise.all([
                fetchPrevailingWageReport(id), 
                fetchPrevailingWageWeeks(id)
            ]);
            
            // Then wait for all freight bills to load
            await queryFreightBills();
            
            console.log('prevailingWageReportRef = ', prevailingWageReportRef.current);
            console.log('freightBillsRef.current = ', freightBillsRef.current);
            
            // Now that everything is loaded, run findFirstSunday
            createReportWeeks(
                prevailingWageReportRef.current.prevailStartDateValue, 
                prevailingWageReportRef.current.prevailEndDateValue, 
                freightBillsRef.current, 
                id
            );
            
            console.log('we sent the first sunday query');
        } catch (error) {
            console.error('Error loading report data:', error);
        }
    };
    const handleDateChange = ( fieldName, value) => {
        let formattedDate= formatDate(value, '/', 'YYYY/MM/DD');
        let databaseDate = formatDate(value, '/', 'MM/DD/YYYY');
        setPrevailingWageReport((prev) => ({ ...prev,[fieldName]: formattedDate, [fieldName+'Value']: value }));   
        updateDocument({[fieldName]:databaseDate}, prevailingWageReport.ID,'PrevailingWageReports');
    };


    const buttonBodyTemplate = (rowData, buttonText) => {
        if(rowData.nonPerformance)buttonText='Show Non Performance';
        return <Button 
			color="primary" 
			onClick={() => viewReport(rowData)}  
			style={{
				margin:"0", 
				marginTop:".5em", 
				marginLeft:".5em", 
				borderRadius:".5em", 
				paddingLeft:"1em !important", 
				paddingBottom: ".2em", 
				paddingTop: ".2em", 
				height:"100%"
			}}
		>
        <span>{buttonText}</span></Button>;
       
    };

    const viewReport = (reportWeek)=>{
		let encodedDate=  encodeURIComponent(formatDate(reportWeek.ID,'/','YYYY/MM/DD'));
		navigate('/newprevailingwage/week/'+ prevailingWageReport.ID+'/'+encodedDate)
        console.log('reportWeek b4 querying fbs = ', reportWeek)
     //  queryWeekFreightBills(reportWeek, prevailingWageReport);
     
    }

	const handleReportFieldChange = (fieldName, value) => {
		setPrevailingWageReport((prev) => ({ ...prev, [fieldName]: value }));
		updateDocument({[fieldName]:value}, prevailingWageReport.ID,'PrevailingWageReports');
	}


    return(
        <div className="card mbsc-grid">
          
          
          {prevailingWageReport && (
			<Panel header='Prevailing Wage Report'>
				<div className="mbsc-row">
					<div className="mbsc-col-4" style={{paddingRight:".5em"}}>  
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon dispatch-small-inputgroup">Start Date:</span>
							<Calendar value={prevailingWageReport.prevailStartDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'prevailStartDate',e.value )} />
						</div> 
						<div className="p-inputgroup">
							<span className="p-inputgroup-addon dispatch-small-inputgroup"> End Date:</span>
							<Calendar value={prevailingWageReport.prevailEndDateValue} style={{width:"100%"}} onChange={(e) => handleDateChange( 'prevailEndDate',e.value )} />
						</div>
						<div className="p-inputgroup ">
							<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Name</span>
							<InputTextParent value={prevailingWageReport.Name} onChange={(e) => handleReportFieldChange( 'Name',e.target.value)} />
						</div>
						<div className="p-inputgroup ">
							<span className="p-inputgroup-addon dispatch-small-inputgroup">Your Title</span>
							<InputTextParent value={prevailingWageReport.Title} onChange={(e) => handleReportFieldChange( 'Title',e.target.value)} />
						</div>
					
					</div>
					<div className="mbsc-col-4" style={{paddingRight:".5em"}}>  
					<div className="p-inputgroup ">
							<span className="p-inputgroup-addon ">Project Name</span>
							<InputTextParent value={prevailingWageReport.ProjectName} onChange={(e) => handleReportFieldChange( 'ProjectName',e.target.value)} />
						</div>
						<div className="p-inputgroup ">
							<span className="p-inputgroup-addon ">Project Location</span>
							<InputTextParent value={prevailingWageReport.ProjectLocation} onChange={(e) => handleReportFieldChange( 'ProjectLocation',e.target.value)} />
						</div>
						<div className='mbsc-row' style={{margin:'0'}} >
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  >  Project ID</span> 
								<InputTextParent value={prevailingWageReport.ProjectID} onChange={(e) => handleReportFieldChange( 'ProjectID',e.target.value)} />
							</div>
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  > Rate</span> 
								<AutoSelectInputNumber   value={prevailingWageReport.PrevailingWageRate} isCurrency={true}     onChange={(e) => handleReportFieldChange( 'PrevailingWageRate',e.value)} />
							</div>
						</div>
						<div className='mbsc-row' style={{margin:'0'}} >
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Overtime Rate</span> 
								<AutoSelectInputNumber   value={prevailingWageReport.OvertimePrevailingWageRate} isCurrency={true}     onChange={(e) => handleReportFieldChange( 'OvertimePrevailingWageRate',e.value)} />
							</div>
							<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
								<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Owner Op Rate</span> 
								<AutoSelectInputNumber   value={prevailingWageReport.OwnerOpPrevailingWageRate} isCurrency={true}   onChange={(e) => handleReportFieldChange( 'OwnerOpPrevailingWageRate',e.value)} />
							</div>
								
						</div>
					</div>
				</div>
			</Panel>
            )}

                    <DataTable value={reportWeeks} paginator rows={25}  header='Report Weeks' filterDisplay="row" emptyMessage="No dispatches found.">
                        <Column  header="Show Report"  body={(rowData) =>buttonBodyTemplate(rowData, 'Show Report')}  />
                        <Column  pt={{root: { 'data-label': 'Week' }}} field="name" header="Week"  />
						<Column  pt={{root: { 'data-label': 'Freight Bill Count' }}} field="JobFreightCount" header="Job Freight Bill Count"  />
                        <Column  pt={{root: { 'data-label': 'Driver Count' }}} field="DriverCount" header="Driver Count"  />
						<Column  pt={{root: { 'data-label': 'Document Count' }}} field="DocCount" header="Document Count"  />
                        
                    </DataTable>
          
    </div>
    )
}


export default PrevailingWageReport;
