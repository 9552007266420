
import React, {useState, useEffect, useRef} from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InputTextParent from '../InputComponents/InputTextParent'; 
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { UserAuth } from '../../context/AuthContext';
import { Button} from '@mobiscroll/react';

import { useJobsProfit } from '../../context/JobsProfitContext';
import {useParams } from 'react-router-dom';
const DispatchProfit = () => {

    const {formatDate,formatMoney } = UserAuth();

    const {queryDispatchFreightBills, queryDispatchExpenses,  expenses, fetchDispatch,profitHomeFreights, dispatchRef, freightBillsRef } = useJobsProfit();

    const {dispID} = useParams();
    const [profitRows, setProfitRows ]= useState([]);
    const [inputValues, setInputValues]= useState();

    const profitRowsRef = useRef();

    
    useEffect(()=>{
        console.log(' starting dispathc profit page dispatchRef = ,', dispatchRef.current)
       startDispatchProfitPage();
    },[]);
    useEffect(()=>{
        console.log('settomg inputvalues to= ,', dispatchRef.current)
        if(dispatchRef.current)setInputValues({...dispatchRef.current})
    },[dispatchRef?.current]);
    useEffect(()=>{
        console.log('CREATING EXPENSES REF OR SOEMTHIG= ,', expenses)
        if(expenses)createExpenseProfitRows();
    },[expenses]);
    useEffect(()=>{
        console.log('CREATING EFREIGHT BILLS  REF OR SOEMTHIG= ,', profitHomeFreights)
        if(profitHomeFreights)createFreightBillProfitRows();
    },[profitHomeFreights]);

    let dispatchFreightBills =profitHomeFreights? [...profitHomeFreights.filter(freightBill => freightBill.dispatchID === dispID)] : [];
    let sortedProfitRows = profitRows.sort((a, b) => {
        // Primary sorting: prioritize 'Hour', 'Ton', or 'Load'
        const priorityA = ['Hour', 'Ton', 'Load'].includes(a.Description) ? 0 : 1;
        const priorityB = ['Hour', 'Ton', 'Load'].includes(b.Description) ? 0 : 1;
    
        if (priorityA !== priorityB) {
            // Rows with a higher priority (0) come first
            return priorityA - priorityB;
        }
    
      
        return 0;
    });
    
    console.log(' freightBillsRef?.current = ,', freightBillsRef?.current )
    const createProfitTableRow = function(description, qty, billrate, billtotal, payrate, paytotal){
        var newProfitTableRow ={
            Description: description,
            Qty:  qty,
            BillRate: billrate,
            BillTotal:  billtotal,
            PayRate: payrate,
            PayTotal:  paytotal,
            billExpense:false,
            payExpense:false
        };

        newProfitTableRow.profitNumber =  newProfitTableRow.BillTotal-newProfitTableRow.PayTotal;
        if( newProfitTableRow.profitNumber!==0){
            if(newProfitTableRow.BillTotal!==0) newProfitTableRow.netPercentNumber = (Number(newProfitTableRow.profitNumber) / Number(newProfitTableRow.BillTotal)) * 100 ;
            else newProfitTableRow.netPercentNumber = -100;
        }else newProfitTableRow.netPercentNumber=0;
        profitRowsRef.current.push(newProfitTableRow);
    }
    const calcFreightTotal = (freight)=>{
        let foundProfitTotal = false, 
        foundBilledStandBy = true, 
        foundPaidStandBy = true, 
        foundBilledBrokerFee = true, 
        foundPaidBrokerFee = true, 
        foundFuel = true, 
        foundTrailerFee = true,
        payType= freight.PayType;


        if(freight.standBilled>0)foundBilledStandBy = false;
        if(freight.standPaid>0)foundPaidStandBy = false;

        if(freight.bFee>0) foundBilledBrokerFee = false;
        if(freight.paidBrokerFee!==0)foundPaidBrokerFee = false;

        if(freight.fuelBilled>0) foundFuel = false;
        if(freight.tFee!==0)foundTrailerFee = false;

        if (freight.PayType.includes("Percent"))   payType=freight.PayType.substring(0,3);
           
        for(var q=0; q<profitRowsRef.current.length; q++){
            if(profitRowsRef.current[q].Description === payType && freight.PayRate== profitRowsRef.current[q].PayRate){
                foundProfitTotal=true;   
                profitRowsRef.current[q].BillTotal+=freight.tBilled;
                profitRowsRef.current[q].PayTotal+=Number(freight.paidQty*freight.PayRate);
                profitRowsRef.current[q].Qty+=freight.paidQty;
            }

            if(profitRowsRef.current[q].Description==='Stand By' && freight.standBR === profitRowsRef.current[q].BillRate){
                foundBilledStandBy = true;
                profitRowsRef.current[q].Qty+= Number(freight.standExMin);
                profitRowsRef.current[q].PayTotal+=freight.standBilled;
            }
    
            if(profitRowsRef.current[q].Description==='Stand By' && freight.standPR === profitRowsRef.current[q].PayRate){
                foundPaidStandBy = true;
                profitRowsRef.current[q].Qty+= Number(freight.paidStandExMin);
                profitRowsRef.current[q].PayTotal+=freight.standPaid;
            }     
    
            if(profitRowsRef.current[q].Description==='Broker Fee' && freight.billedBrokerPercent === profitRowsRef.current[q].BillRate){
                foundBilledBrokerFee = true;
                profitRowsRef.current[q].BillTotal+=freight.bFee;
            }
            
            if(profitRowsRef.current[q].Description==='Broker Fee' && freight.paidBrokerPercent === profitRowsRef.current[q].PayRate){
                foundPaidBrokerFee = true;
                profitRowsRef.current[q].PayTotal+=freight.paidBrokerFee;
            }
            
            if(profitRowsRef.current[q].Description==='Fuel' && freight.FuelCharge === profitRowsRef.current[q].BillRate){
                foundFuel = true;
                profitRowsRef.current[q].BillTotal+=freight.fuelBilled;
            }
            
            if(profitRowsRef.current[q].Description==='Trailer Fee' && freight.trailerPercent === profitRowsRef.current[q].PayRate){
                foundTrailerFee = true;
                profitRowsRef.current[q].PayTotal+=freight.tFee;
            }    
            if( profitRowsRef.current[q].profitNumber!==0){
                if(profitRowsRef.current[q].BillTotal!==0) profitRowsRef.current[q].netPercentNumber = (Number(profitRowsRef.current[q].profitNumber) / Number(profitRowsRef.current[q].BillTotal)) * 100 ;
                else profitRowsRef.current[q].netPercentNumber = -100;
            }else profitRowsRef.current[q].netPercentNumber=0;       
            profitRowsRef.current[q].profitNumber =  profitRowsRef.current[q].BillTotal-profitRowsRef.current[q].PayTotal;
            if( profitRowsRef.current[q].profitNumber!==0){
                if(profitRowsRef.current[q].BillTotal!==0) profitRowsRef.current[q].netPercentNumber = (Number(profitRowsRef.current[q].profitNumber) / Number(profitRowsRef.current[q].BillTotal)) * 100 ;
                else profitRowsRef.current[q].netPercentNumber = -100;
            }else profitRowsRef.current[q].netPercentNumber=0;
        }      

        
        if(!foundProfitTotal){
            var newProfitTableRow ={
                Description: payType,
                Qty:  freight.paidQty,
                BillRate: freight.BillRate,
                BillTotal:  freight.tBilled,
                PayRate: freight.PayRate,
                PayTotal:  Number(freight.PayRate*freight.paidQty),
                billExpense:false,
               
                payExpense:false
            };
    
            newProfitTableRow.profitNumber =  newProfitTableRow.BillTotal-newProfitTableRow.PayTotal;
            if( newProfitTableRow.profitNumber!==0){
                if(newProfitTableRow.BillTotal!==0) newProfitTableRow.netPercentNumber = (Number(newProfitTableRow.profitNumber) / Number(newProfitTableRow.BillTotal)) * 100 ;
                else newProfitTableRow.netPercentNumber = -100;
            }else newProfitTableRow.netPercentNumber=0;
            profitRowsRef.current.push(newProfitTableRow);
        }

       
        if(!foundBilledStandBy)createProfitTableRow('Stand By',freight.standExMin, freight.standBR,freight.standBilled,0,0);
        if(!foundPaidStandBy)createProfitTableRow('Stand By',freight.paidStandExMin, 0,0,freight.standPR,freight.standPaid);
    
        if(!foundBilledBrokerFee)createProfitTableRow('Broker Fee', 'N/A', freight.billedBrokerPercent,freight.bFee,0,0);
        if(!foundPaidBrokerFee)createProfitTableRow('Broker Fee', 'N/A', 0,0,freight.paidBrokerPercent,freight.paidBrokerFee);
    
        if(!foundFuel)createProfitTableRow('Fuel','N/A', freight.FuelCharge,freight.fuelBilled,0,0);
        if(!foundTrailerFee)createProfitTableRow('Trailer Fee','N/A', 0,0,freight.trailerPercent,freight.tFee);
        setProfitRows([...profitRowsRef.current]);
        freight.profitNumber= freight.bTotal-freight.tPaid;
        if(freight.profitNumber!==0){
            if(freight.bTotal!==0)freight.netPercentNumber = (Number(freight.profitNumber) / Number(freight.bTotal)) * 100 ;
            else freight.netPercentNumber = -100;
        }else freight.netPercentNumber=0;
    }
    const createFreightBillProfitRows = ()=>{
        dispatchFreightBills.forEach(freight => {
            calcFreightTotal(freight);
        });
    }
   
  
    let sortedFreights = dispatchFreightBills ? [...dispatchFreightBills].sort((a, b) => {
        if (a.FBNO < b.FBNO) return -1;
        if (a.FBNO > b.FBNO) return 1;
        return 0;
    }) : [];
 
    const createExpenseProfitRows = () =>{
        console.log('we crteateing expense profit rows???' + expenses.length)
        for(var p=0; p<profitRowsRef.current.length; p++){
            if(profitRowsRef.current[p].billExpense || profitRowsRef.current[p].payExpense){
                profitRowsRef.current.splice(p,1);
                p--;
            }
        }
        for(var j=0; j<expenses.length; j++){
            var foundProfitTotal = false;
            if(expenses[j].FreightBill){
                console.log('EXPENSE  ' , expenses[j]);
                console.log('expenses[j].Name.Name = ' + expenses[j].Name.Name);
                for(var q=0; q<profitRowsRef.current.length; q++){
                    console.log('profitRowsRef.current[q].Description  = ' + profitRowsRef.current[q].Description);

                    if(profitRowsRef.current[q].Description === expenses[j].Name.Name && profitRowsRef.current[q].billExpense ===expenses[j].bill &&  profitRowsRef.current[q].payExpense ===expenses[j].pay ){
                    
                        if(expenses[j].bill   &&  profitRowsRef.current[q].BillRate===expenses[j].rate){
                            console.log('so for the first time I itereate through these epxese = ', expenses[j]);
                            console.log('I ma setting foundprofit total true due to this profitrow = ', profitRowsRef.current[q]);
                            foundProfitTotal=true;
                            profitRowsRef.current[q].BillTotal += expenses[j].total;
                            profitRowsRef.current[q].Qty += expenses[j].qty;
                        }
                        if(expenses[j].pay &&  profitRowsRef.current[q].PayRate===expenses[j].rate) {
                            console.log('so for the first time I itereate through these epxese = ', expenses[j]);
                            console.log('I ma setting foundprofit total true due to this profitrow = ', profitRowsRef.current[q]);
                            foundProfitTotal=true;
                            profitRowsRef.current[q].PayTotal += expenses[j].total;
                            profitRowsRef.current[q].Qty += expenses[j].qty;
                            
                        }
                        profitRowsRef.current[q].profitNumber =  profitRowsRef.current[q].BillTotal-profitRowsRef.current[q].PayTotal;
                        if( profitRowsRef.current[q].profitNumber!==0){
                            if(profitRowsRef.current[q].BillTotal!==0) profitRowsRef.current[q].netPercentNumber = (Number(profitRowsRef.current[q].profitNumber) / Number(profitRowsRef.current[q].BillTotal)) * 100 ;
                            else profitRowsRef.current[q].netPercentNumber = -100;
                        }else profitRowsRef.current[q].netPercentNumber=0;
                        
                    } 
                    

                }
        
                if(!foundProfitTotal){
                    console.log('for expense = ', expenses[j]);
                  
                    var newProfitTableRow={
                        Description: expenses[j].Name.Name,
                        Qty: expenses[j].qty,
                        BillRate:  0,
                        BillTotal:  0,
                        PayRate:  0,
                        PayTotal:  0,
                        billExpense:false,
                        payExpense:false
                    };
                    if(expenses[j].bill){
                        newProfitTableRow.billExpense=true;
                        newProfitTableRow.BillRate=expenses[j].rate;
                        newProfitTableRow.BillTotal = expenses[j].total;
                    } 
                    if(expenses[j].pay){
                        newProfitTableRow.payExpense=true;    
                        newProfitTableRow.PayRate=expenses[j].rate;
                        newProfitTableRow.PayTotal = expenses[j].total;
                    } 
                    console.log(' I did not find a profit total row and BillTotal = ' + newProfitTableRow.BillTotal + ' and the payTotal = '+newProfitTableRow.PayTotal)
                    newProfitTableRow.profitNumber =  newProfitTableRow.BillTotal-newProfitTableRow.PayTotal;
                    if( newProfitTableRow.profitNumber!==0){
                        if(newProfitTableRow.BillTotal!==0) newProfitTableRow.netPercentNumber = (Number(newProfitTableRow.profitNumber) / Number(newProfitTableRow.BillTotal)) * 100 ;
                        else newProfitTableRow.netPercentNumber = -100;
                    }else newProfitTableRow.netPercentNumber=0;
            
                    profitRowsRef.current.push(newProfitTableRow);
                }
            }
        }
        console.log(' I AM SETTING PROFIT ROWS TO THIS TEMP PROFIT ROWS = ', profitRowsRef.current);
        setProfitRows([...profitRowsRef.current]);
    }
 
    console.log('profitRows NUMBER 2 = ', profitRows)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        driverName:{ value: null, matchMode: FilterMatchMode.CONTAINS }

    });
    
    const startDispatchProfitPage = async()=>{
        let promises=[];
        if(!dispatchRef.current){
            promises.push(queryDispatchFreightBills(dispID));
            promises.push(fetchDispatch(dispID));
        }
        promises.push(queryDispatchExpenses(dispID));
        profitRowsRef.current=[];
        await Promise.all(promises);
   
    
     
    }


    
    const buttonBodyTemplate = (rowData) => {
   
        let buttonText = rowData.FBNO ? rowData.FBNO : 'Open'
        return <Button color="primary"  style={{margin:"0", marginTop:".5em", marginLeft:".5em", borderRadius:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}} onClick={(e) => openFreightBill(rowData)}>
          <span>{buttonText}</span></Button>;
    };

    const currencyBodyTemplate = (rowData, field) => {
       const formattedNumber =  Number(rowData[field]).formatMoney(2);
        return (
           <span style={{paddingRight:".5em", float:"right"}}>${formattedNumber}</span>
        );
    };
    const numberBodyTemplate = (rowData, field) => {
        const formattedNumber =  Number(rowData[field]).formatMoney(2);
         return (
            <span style={{paddingRight:".5em", float:"right"}}>{formattedNumber}</span>
         );
     };

    const percentBodyTemplate = (rowData, field) => {
        const formattedNumber = rowData[field] ? Number(rowData[field]).formatMoney(0) : 0;
         return (  <span style={{paddingRight:".5em", float:"right"}}>{formattedNumber}%</span>  );
     };

     const openFreightBill = (rowData)=>{
        var win = window.open('/freightbill/freightbill/'+rowData.ID+'/'+rowData.dispatchID, '_blank');
        win.focus();
  
    }
 
    const totalColumn = (array, fieldName)=>{
        let grandTotal = 0;
        array.forEach(job => {
            grandTotal+= job[fieldName] ? job[fieldName] : 0;
        });
    
       return '$'+ Number(grandTotal).formatMoney(2);
    }
    const totalNetPercent = (array, billField)=>{
        let billTotal = 0,
        netTotal =0,
        netPercentTotal=0;
    
        array.forEach(job => {
            console.log('job[billField] =' +job[billField])
            billTotal+= job[billField] ? job[billField] : 0;
            netTotal+= job.profitNumber ? job.profitNumber : 0;
        });
        if(netTotal!==0){
            if(billTotal!==0) netPercentTotal= (Number(netTotal) / Number(billTotal)) * 100 ;
            else netPercentTotal= -100;
        }else netPercentTotal=0;
        console.log('netPecent total = ', netPercentTotal)
       return <span style={{paddingRight:".5em", float:"right"}}>{Number(netPercentTotal).formatMoney(0)}%</span> ;
    }
    return(
        <React.Fragment>
       
            {inputValues?.Account && (<div className="card mbsc-grid ">
                <div className ="mbsc-row">
                    <div className="mbsc-col-xl-3 mbsc-offset-xl-2" style={{paddingRight:".5em"}}>  
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon search-auto-input">Customer:</span>
                            <InputTextParent  value={inputValues.Account.Name} disabled={true} />
                        </div> 
                    </div>
                    <div className="mbsc-col-xl-3 " style={{paddingRight:".5em"}}>  
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon search-auto-input">Job Date:</span>
                            <InputTextParent  value={inputValues.JobDate} disabled={true} />
                        </div> 
                    </div>
                    <div className="mbsc-col-xl-3 " style={{paddingRight:".5em"}}>  
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon search-auto-input">Load Site:</span>
                            <InputTextParent  value={inputValues.LoadSite.Name} disabled={true} />
                        </div> 
                    </div>
                </div>
                <div className ="mbsc-row">
                    <div className="mbsc-col-xl-3 mbsc-offset-xl-2" style={{paddingRight:".5em"}}>  
                    
                        <div className="p-inputgroup">
                        <span className="p-inputgroup-addon search-auto-input">Job #:</span>
                            <InputTextParent  value={inputValues.JobNumber} disabled={true} />
                        </div>
                    </div>
                    <div className="mbsc-col-xl-3 " style={{paddingRight:".5em"}}>  
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon search-auto-input">Material:</span>
                            <InputTextParent  value={inputValues.Material.Name} disabled={true} />
                        </div> 
                    </div>
                    <div className="mbsc-col-xl-3 " style={{paddingRight:".5em"}}>  
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon search-auto-input">Dump Site:</span>
                            <InputTextParent  value={inputValues.DumpSite.Name} disabled={true} />
                        </div> 
                    </div>
                </div>
                <div className="mbsc-col-xl-8 mbsc-offset-xl-2" style={{padding:"0", paddingTop:"2em"}}>
                    <DataTable value={sortedProfitRows} paginator rows={25} dataKey="index" filters={filters} header='Summary' filterDisplay="row" emptyMessage="No billing details found.">
                        <Column  header="Description"  field="Description" />
                        <Column header="Qty" sortable field="Qty" body={(rowData) => numberBodyTemplate(rowData, 'Qty')}  />
                        <Column header="Bill Rate" sortable field="BillRate"  body={(rowData) => currencyBodyTemplate(rowData, 'BillRate')}  />
                        <Column header="Bill Total" sortable field="BillTotal" footer={totalColumn(profitRows,'BillTotal')} body={(rowData) => currencyBodyTemplate(rowData, 'BillTotal')}  />
                        <Column header="Pay Rate" sortable field="PayRate" body={(rowData) => currencyBodyTemplate(rowData, 'PayRate')}  />
                        <Column header="Pay Total" sortable field="PayTotal" footer={totalColumn(profitRows, 'PayTotal')} body={(rowData) => currencyBodyTemplate(rowData, 'PayTotal')}  />
                        <Column header="Net" sortable field="profitNumber"  footer={totalColumn(profitRows, 'profitNumber')} body={(rowData) => currencyBodyTemplate(rowData, 'profitNumber')}  />
                        <Column  style={{ minWidth: '6rem' }} header="Net %" footer={totalNetPercent(profitRows, 'BillTotal')} sortable field="netPercentNumber" body={(rowData) => percentBodyTemplate(rowData, 'netPercentNumber')}  />
                    </DataTable>
                </div>
                <div className="mbsc-col-xl-8 mbsc-offset-xl-2" style={{padding:"0", paddingTop:"2em"}}>
                    <DataTable value={sortedFreights} paginator rows={25} dataKey="index" filters={filters} header='Detailed' filterDisplay="row" emptyMessage="No Freight Bills found.">
                        <Column  header="FB #"  body={(rowData) =>buttonBodyTemplate(rowData)}  />
                        <Column header="Driver Name" sortable field="driverName"  />
                        <Column header="Billed" sortable field="bTotal" footer={totalColumn(sortedFreights,'bTotal')} body={(rowData) => currencyBodyTemplate(rowData, 'bTotal')}  />
                        <Column header="Paid" sortable field="tPaid" footer={totalColumn(sortedFreights,'tPaid')} body={(rowData) => currencyBodyTemplate(rowData, 'tPaid')}  />
                        <Column header="Net" sortable field="profitNumber"  footer={totalColumn(sortedFreights,'profitNumber')} body={(rowData) => currencyBodyTemplate(rowData, 'profitNumber')}  />
                        <Column  style={{ minWidth: '6rem' }} header="Net %" sortable field="netPercentNumber" footer={totalNetPercent(sortedFreights, 'bTotal')}  body={(rowData) => percentBodyTemplate(rowData, 'netPercentNumber')}  />
                    </DataTable>
                </div>
        </div>)}
    </React.Fragment>

    )
}


export default DispatchProfit;
