import React, { createContext, useContext } from 'react';
import { UserAuth } from '../../../context/AuthContext';
import { usePayStatement } from './PayStatementContext';

const PayStatementLineItemContext = createContext();

export const PayStatementLineItemContextProvider = ({ children }) => {
   
    const { expenseNames,addDocument, formatDate } = UserAuth();
    const {inputValues} = usePayStatement();
    const makeFreightBillLineItem = (DPS, FreightBill) =>{
      
        var newLineItem = {};
        console.log('making Frieghtbill for line item =', FreightBill)
        if(FreightBill.loadSite) newLineItem.LoadSite = FreightBill.loadSite; else newLineItem.LoadSite = 'No Location';
        if(FreightBill.dumpSite) newLineItem.DumpSite = FreightBill.dumpSite; else newLineItem.DumpSite = 'No Location';
        console.log('newLineItem.DumpSite =',newLineItem.LoadSite)
        if(FreightBill.Account)newLineItem.Description = newLineItem.LoadSite; else newLineItem.Description = newLineItem.LoadSite;

        newLineItem.ID=FreightBill.ID;
  
        newLineItem.Type = 'Freight Bill';
        if (FreightBill.hourMinTotalPaid>0)newLineItem.Qty = FreightBill.paidQty-FreightBill.hourMinPaidQty; else newLineItem.Qty = FreightBill.paidQty;
        if(newLineItem.Qty === '')newLineItem.Qty =0;
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.VNum = FreightBill.VNum ? FreightBill.VNum  : 1;
        if(FreightBill.amountPaid<0)FreightBill.amountPaid=0;
        newLineItem.TotalWeight = Number(FreightBill.tWeight).formatMoney(2);
        newLineItem.RateString = '$' + Number(FreightBill.PayRate).formatMoney(2);
      
        newLineItem.tWeight = FreightBill.tWeight;
        newLineItem.tHours = FreightBill.tHours;
        if(FreightBill.totalYardHours)newLineItem.totalYardHours = FreightBill.totalYardHours; else newLineItem.totalYardHours='';
        
        newLineItem.missing=FreightBill.missing;
  
        newLineItem.loads = FreightBill.loads;
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.FreightID = FreightBill.ID;
        newLineItem.JobDate = FreightBill.JobDate;
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber; else newLineItem.PONumber = '';
        if (FreightBill.JobNumber) newLineItem.JobNumber = FreightBill.JobNumber; else newLineItem.JobNumber = '';
        newLineItem.FBNO = FreightBill.FBNO;
     
        newLineItem.FBNumber=Number(FreightBill.FBNO);
        newLineItem.QueryDate= FreightBill.QueryDate;
        if(FreightBill.Truck){ 
            if (FreightBill.Truck.Name && FreightBill.Truck.Name!=='No Truck') newLineItem.truck = FreightBill.Truck.Name; 
            else newLineItem.truck = '';
        } else newLineItem.truck = '';
        newLineItem.Driver = FreightBill.Driver;
        newLineItem.FreightTotal = FreightBill.tPaid;
        newLineItem.Rate = FreightBill.PayRate;
        newLineItem.RateType = FreightBill.PayType;
        console.log('newLineItem.RateType= ' + newLineItem.RateType)
      
        
      
        if(FreightBill.paidBrokerPercent>0 )DPS.BrokerPercent=FreightBill.paidBrokerPercent;
        if(FreightBill.trailerPercent>0 )DPS.TrailerPercent=FreightBill.trailerPercent; 
        
        newLineItem.Amount =Math.round(Number(newLineItem.Qty * newLineItem.Rate)*100)/100;
        newLineItem.amountString='$' + Number(newLineItem.Amount).formatMoney(2);
        
    
    
        if(FreightBill.totalDriverPay){
            if(FreightBill.paidTruckingBrokerTotal)newLineItem.BrokerTotal=FreightBill.paidTruckingBrokerTotal;
            else if(FreightBill.paidBrokerFee && FreightBill.paidBrokerFee!=='' && FreightBill.paidBrokerPercent)newLineItem.BrokerTotal=Math.round(-1*(FreightBill.totalDriverPay* (FreightBill.paidBrokerPercent / 100))*100)/100;
            else newLineItem.BrokerTotal=0; 
            
            if(FreightBill.fuelPaid)newLineItem.FuelTotal=FreightBill.fuelPaid;
            else newLineItem.FuelTotal=0;
            console.log('newLineItem.FuelTotal = ' +newLineItem.FuelTotal);

            if(FreightBill.truckingTrailerTotal)newLineItem.TrailerTotal=FreightBill.truckingTrailerTotal;
            else if(FreightBill.tFee && FreightBill.tFee!=='' && FreightBill.trailerPercent)newLineItem.TrailerTotal=Math.round(-1*(FreightBill.totalDriverPay* (FreightBill.trailerPercent / 100))*100)/100;
            else newLineItem.TrailerTotal=0;
            
            if (FreightBill.hourMinPaid>0) newLineItem.Total = Number(FreightBill.totalDriverPay-FreightBill.hourMinPaid); 
            else newLineItem.backTotal=newLineItem.Amount+newLineItem.BrokerTotal+newLineItem.TrailerTotal;
        }else {
            newLineItem.FuelTotal= 0;
            newLineItem.backTotal= 0;
            newLineItem.BrokerTotal=0;
            newLineItem.TrailerTotal=0;
        }

        
        newLineItem.BrokerTotalString = '$' + Number(newLineItem.BrokerTotal).formatMoney(2);
        newLineItem.TrailerTotalString = '$' + Number(newLineItem.TrailerTotal).formatMoney(2);
        newLineItem.FuelTotalString = '$' + Number(newLineItem.FuelTotal).formatMoney(2);

        newLineItem.background = 'background-color : transparent!important';
        newLineItem.printBackground = 'background-color : transparent!important';
       
        newLineItem.BrokerPercent = FreightBill.paidBrokerPercent + '%';
        newLineItem.isFreightBill = true;
        newLineItem.dispatchID = FreightBill.dispatchID;
        if (FreightBill.dispatchName) newLineItem.dispatchName = FreightBill.dispatchName;else newLineItem.dispatchName = '';
        
        if(newLineItem.BrokerTotal<0) DPS.showBrokerFee=true;
        if(newLineItem.TrailerTotal<0) DPS.showTrailerFee=true;
        if(newLineItem.FuelTotal>0) DPS.showFuelCharge=true;
        console.log(' FreightBill.FBNO = ' +  FreightBill.FBNO );
        if(!FreightBill.PayType)FreightBill.PayType='Ton';
        if (FreightBill.PayType.includes('Percent')){
            newLineItem.DriverPercent = FreightBill.driverPercent;
            DPS.showDriverPercent=true;
            newLineItem.Total=Math.round(newLineItem.Amount*( newLineItem.DriverPercent /100)*100)/100;
            newLineItem.backTotal=newLineItem.Total;
        } else{
            newLineItem.DriverPercent = 100;
            newLineItem.Total=newLineItem.Amount;
        } 
     
      
        newLineItem.TotalString = '$' + Number(newLineItem.Total).formatMoney(2);
        if(!FreightBill.onHold && FreightBill.amountPaid===0)FreightBill.amountPaid=newLineItem.backTotal; 
        if(!FreightBill.onHold && !FreightBill.truckingAmountPaid)FreightBill.truckingAmountPaid=newLineItem.backTotal; 
        console.log('adding a freight bill with and so far the amount paid = '+ FreightBill.amountPaid);
        if (FreightBill.UseStandBy && FreightBill.standPaid > 0 && !FreightBill.standByIsPaid){
        
            FreightBill.hasStandBy = true;
            newLineItem.hasStandBy = true;
            newLineItem.tableLength++;
            makeStandByLineItem(FreightBill, DPS.LineItems);
        
        }  else{
            FreightBill.standByIsPaid=true; 
            newLineItem.hasStandBy = false;
            FreightBill.hasStandBy = false;
        }  
        if(!FreightBill.truckingPaid ){
            DPS.LineItems.push(newLineItem);
           
            if(FreightBill.JobOvertimeTotal>0 ){
                newLineItem.tableLength++;
                makeOvertimeLineItem(FreightBill, 'Job', DPS.LineItems);
            }
            if(FreightBill.paidTravelTime>0){
                newLineItem.tableLength++;
                makeTravelTimeLineItem (FreightBill, DPS.LineItems);
            }
        }   
      
        if (FreightBill.hourMinPaid>0 && !FreightBill.hourMinIsPaid)  makeHourMinLineItem(FreightBill, DPS.LineItems); else FreightBill.hourMinIsPaid=true;
      
    }
    const makeExpenseLineItem = ( Expense, LineItems)=> {
       
        console.log('Making expense line item ', Expense);
        var newLineItem = {};
      
        newLineItem.isFreightBill = false;
        newLineItem.dispatchID = Expense.dispatchID;
      
        newLineItem.Qty = Expense.qty;
        newLineItem.ExpenseID = Expense.ID;
        newLineItem.hideFBNO = false;
    
        newLineItem.QtyString = Number(Expense.Qty).formatMoney(2);
        newLineItem.totalYardHours='';
        newLineItem.Total = 0 + Expense.total;
        newLineItem.Rate = 0 + Expense.rate;
        newLineItem.Type = 'Expense';
        newLineItem.RateType = Expense.Name.Name;
        newLineItem.tableLength=1;
        newLineItem.TrailerTotal=0;
    
        newLineItem.RateString = '$' + Number(Expense.rate).formatMoney(2)
        newLineItem.TotalString = '$' + Number(Expense.total).formatMoney(2);
        newLineItem.Qty = Expense.qty;
        newLineItem.backTotal= newLineItem.Total;
        newLineItem.ID = Expense.ID;
        newLineItem.FBNO = Number(Expense.FBNO);
        newLineItem.TaxTotal= 0;
        newLineItem.Taxable=false;
        newLineItem.FreightID = Expense.FreightBill;
        newLineItem.ParentFreight = Expense.FreightBill;
        newLineItem.Weights = [];
        newLineItem.truck=	Expense.Truck;
        newLineItem.JobDate = Expense.JobDate;
        newLineItem.FBNO = Expense.FBNO;
        newLineItem.FBNumber=Number(Expense.FBNO);
        newLineItem.VNum = 1;
        newLineItem.Description = Expense.Description ? Expense.Description : Expense.Name.Name;
        newLineItem.onHold = Expense.onHold;
        newLineItem.isEditable = Expense.isEditable ? Expense.isEditable : false;
        newLineItem.Driver = Expense.driverName ? Expense.driverName : Expense.PayTo?.Name ? Expense.PayTo?.Name : '';
        newLineItem.BrokerPercent = '';
       // if(Expense.applyBrokerFee)newLineItem.BrokerTotal = Math.round(-1*(Expense.total* (FreightBill.billedBrokerPercent / 100))*100)/100;else newLineItem.BrokerTotal = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = '';
        newLineItem.tWeight = '';
        newLineItem.JobDateValue = new Date(Expense.JobDate);
        newLineItem.TrailerTotal= Expense.TrailerTotal ? Expense.TrailerTotal : 0;
        newLineItem.BrokerTotal= Expense.BrokerTotal ? Expense.BrokerTotal : 0;
        newLineItem.Amount = Number(Number(newLineItem.Rate) * Number(newLineItem.Qty))
        console.log( '  new expense line item = ',   newLineItem);

        newLineItem.amountString='$' + newLineItem.Amount.formatMoney(2);
        console.log( '   newLineItem.amountString= ' +    newLineItem.amountString);
        console.log( 'newLineItem expsense.Total= ' + newLineItem.Total);
        return newLineItem;
      
            
   
    }
    const makeOvertimeLineItem = (FreightBill, OTType, LineItems) =>{
        var newLineItem = {};
        newLineItem.Type = 'Overtime';
        newLineItem.Description = OTType+ ' Overtime';
        newLineItem.RateType = OTType+ ' Overtime';
        newLineItem.hideFBNO =true;
        if (FreightBill.PayType.includes('Percent')) {
            newLineItem.DriverPercent = FreightBill.driverPercent;
        }else  newLineItem.DriverPercent = 100;
        newLineItem.totalYardHours='';
        newLineItem.LoadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.DumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
        
        newLineItem.FreightID = FreightBill.ID;
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.VNum = 1;
        newLineItem.JobDate = FreightBill.JobDate;
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.FreightHold= FreightBill.onHold;
        newLineItem.FBNO = FreightBill.FBNO;
        newLineItem.FBNumber=Number(FreightBill.FBNO);
        newLineItem.QueryDate= FreightBill.QueryDate;
        newLineItem.Driver = FreightBill.Driver;
        newLineItem.TrailerTotal='';
        newLineItem.tHours = '';
        if(OTType==='Job'){
            newLineItem.Qty = FreightBill.JobOvertimeQty;
            newLineItem.Rate = FreightBill.JobOvertimeRate;
            newLineItem.Total = FreightBill.JobOvertimeTotal;
        }else{
             newLineItem.Qty = FreightBill.TravelOvertimeQty;
            newLineItem.Rate = FreightBill.TravelOvertimeRate;
            newLineItem.Total = FreightBill.TravelOvertimeTotal;
        } 
        
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.Qty = Number( newLineItem.Qty).formatMoney(2);
        newLineItem.RateString = '$' + Number(newLineItem.Rate).formatMoney(2);
        newLineItem.TotalString = '$' + Number( newLineItem.Total).formatMoney(2);
        if (FreightBill.Truck.Name && FreightBill.Truck.Name!=='No Truck') newLineItem.truck = FreightBill.Truck.Name; 
        else newLineItem.truck = '';
        
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber;else newLineItem.PONumber = '';
        if (FreightBill.JobNumber) newLineItem.JobNumber = FreightBill.JobNumber; else newLineItem.JobNumber = '';
    
        newLineItem.BrokerPercent = '';
        newLineItem.BrokerTotal=0;
        newLineItem.BrokerTotalString = '$' + Number(newLineItem.BrokerTotal).formatMoney(2);
        newLineItem.FuelPercent = '';
        newLineItem.Overtime=0;
        newLineItem.OvertimeQty=0;
        newLineItem.FuelTotal = '';
        newLineItem.tWeight = '';
        newLineItem.Amount = Number(newLineItem.Qty *  newLineItem.Rate);
        if(!FreightBill.onHold) FreightBill.amountPaid+=newLineItem.Total;
        newLineItem.amountString='$' + Number(newLineItem.Amount).formatMoney(2);
        newLineItem.backTotal=newLineItem.Total;
        console.log('FreightBill.amountPaid after overtime item' +  FreightBill.amountPaid) ;
        LineItems.push(newLineItem);
    }
    const makeHourMinLineItem = (FreightBill, LineItems)=> {
        var newLineItem = {};
        newLineItem.Type = 'Hour Min';
        newLineItem.Description = 'Hour Min';
        newLineItem.RateType = 'Hour Min';
        newLineItem.TrailerTotal='';
        if (FreightBill.PayType.includes('Percent'))  newLineItem.DriverPercent = FreightBill.driverPercent;
        else  newLineItem.DriverPercent = 100;
        newLineItem.totalYardHours='';
        newLineItem.LoadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.DumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
       
        newLineItem.Qty = FreightBill.hourMinPaidQty;
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.Rate = FreightBill.PayRate;
        newLineItem.Total = FreightBill.hourMinPaid;
        newLineItem.RateString = '$' + Number(FreightBill.PayRate).formatMoney(2);
        newLineItem.TotalString = '$' + Number(FreightBill.hourMinPaid).formatMoney(2);
        newLineItem.FreightID = FreightBill.ParentFreight;
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.tHours = '';
        if (FreightBill.Truck.Name && FreightBill.Truck.Name!=='No Truck') newLineItem.truck = FreightBill.Truck.Name; else newLineItem.truck = '';
        newLineItem.VNum =1;
        newLineItem.JobDate = FreightBill.JobDate;
        newLineItem.hideFBNO =true;
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.FreightHold= FreightBill.onHold;
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber;else newLineItem.PONumber = '';
        if (FreightBill.JobNumber) newLineItem.JobNumber = FreightBill.JobNumber; else newLineItem.JobNumber = '';
        newLineItem.FBNO = FreightBill.FBNO;
        newLineItem.FBNumber=Number(FreightBill.FBNO);
        newLineItem.QueryDate= FreightBill.QueryDate;
        newLineItem.Overtime=0;
        newLineItem.OvertimeQty=0;
        newLineItem.Driver = FreightBill.Driver;
        newLineItem.BrokerPercent = '';
        newLineItem.BrokerTotal = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = '';
        newLineItem.tWeight = '';
        newLineItem.Amount = Number(newLineItem.Qty *  newLineItem.Rate);
        newLineItem.amountString='$' + Number(newLineItem.Amount).formatMoney(2);
         FreightBill.amountPaid+=newLineItem.Total;
         console.log('FreightBill.amountPaid after hourMIN item' +  FreightBill.amountPaid) ;
     if(!FreightBill.hourMinIsPaid )    LineItems.push( newLineItem);
    }
    const makeTravelTimeLineItem = (FreightBill, LineItems)=> {
        console.log('making travel line item')
        var newLineItem = {};
        newLineItem.Type = 'Travel Time';
        newLineItem.Description = 'Travel Time';
        newLineItem.RateType = 'Travel Time';
        newLineItem.TrailerTotal='';
        if (FreightBill.PayType.includes('Percent'))   newLineItem.DriverPercent = FreightBill.driverPercent;
        else  newLineItem.DriverPercent = 100;
        newLineItem.totalYardHours='';
        newLineItem.tHours = '';
        newLineItem.hasStandBy=FreightBill.hasStandBy;
        newLineItem.LoadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.DumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
       
        newLineItem.Qty = FreightBill.travelTime;
        newLineItem.Rate = FreightBill.travelRate;
        newLineItem.Total = FreightBill.paidTravelTime;
        newLineItem.FreightID = FreightBill.ID;
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.hideFBNO =true;
        if (FreightBill.Truck.Name && FreightBill.Truck.Name!=='No Truck') newLineItem.truck = FreightBill.Truck.Name; else newLineItem.truck = '';
        newLineItem.VNum =1;
        newLineItem.JobDate = FreightBill.JobDate;
       
        if(FreightBill.TravelOvertimeTotal>0 ) newLineItem.Qty-=FreightBill.TravelOvertimeQty;
        newLineItem.QtyString = Number( newLineItem.Qty).formatMoney(2);
        newLineItem.Qty = Number( newLineItem.Qty).formatMoney(2);
        newLineItem.RateString = '$' + Number(FreightBill.travelRate).formatMoney(2);
        newLineItem.TotalString = '$' + Number(FreightBill.paidTravelTime).formatMoney(2);
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.FreightHold= FreightBill.onHold;
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber;else newLineItem.PONumber = '';
        if (FreightBill.JobNumber) newLineItem.JobNumber = FreightBill.JobNumber; else newLineItem.JobNumber = '';
        newLineItem.FBNO = FreightBill.FBNO;
        newLineItem.FBNumber=Number(FreightBill.FBNO);
        newLineItem.QueryDate= FreightBill.QueryDate;
        newLineItem.Driver = FreightBill.Driver;
        newLineItem.BrokerPercent = '';
        newLineItem.BrokerTotal = '';
        newLineItem.FuelPercent = '';
        newLineItem.FuelTotal = '';
        newLineItem.tWeight = '';
        newLineItem.Amount = Number(newLineItem.Qty *  newLineItem.Rate);
        newLineItem.amountString='$' + Number(newLineItem.Amount).formatMoney(2);
        newLineItem.backTotal=newLineItem.Total;
        FreightBill.amountPaid+=newLineItem.Total;
        console.log('FreightBill.amountPaid after the travel time ' +  FreightBill.amountPaid) ;
        LineItems.push(newLineItem);
        if(FreightBill.TravelOvertimeTotal>0 ) makeOvertimeLineItem(FreightBill, 'Travel', LineItems);
    }
    const makeStandByLineItem =(FreightBill, LineItems) =>{
        var newLineItem = {};
        newLineItem.Type = 'Stand By';
        newLineItem.Description = 'Stand By';
        newLineItem.RateType = 'Stand By';
        console.log('making stand by line item!')
        if (FreightBill.PayType.includes('Percent')) newLineItem.DriverPercent = FreightBill.driverPercent;   
        else  newLineItem.DriverPercent = 100;
        newLineItem.LoadSite = FreightBill.loadSite;
        newLineItem.isFreightBill = false;
        newLineItem.DumpSite = FreightBill.dumpSite;
        newLineItem.dispatchID = FreightBill.dispatchID;
        newLineItem.FreightTotal = '';
    
        newLineItem.Qty = FreightBill.paidStandExMin;
        newLineItem.QtyString = Number(newLineItem.Qty).formatMoney(2);
        newLineItem.Rate = FreightBill.standPR;
        newLineItem.Total = FreightBill.standPaid;
        newLineItem.RateString = '$' + Number(FreightBill.standPR).formatMoney(2);
        newLineItem.TotalString = '$' + Number(FreightBill.standPaid).formatMoney(2);
        newLineItem.FreightID = FreightBill.ID;
        newLineItem.ParentFreight = FreightBill.ParentFreight;
        newLineItem.totalYardHours='';
        if (FreightBill.Truck.Name && FreightBill.Truck.Name!=='No Truck') newLineItem.truck = FreightBill.Truck.Name; 
        else newLineItem.truck = '';
        newLineItem.VNum = 1
        newLineItem.JobDate = FreightBill.JobDate;
        if(FreightBill.truckingPaid) newLineItem.hideFBNO=false; else newLineItem.hideFBNO=true;
 
        newLineItem.onHold = FreightBill.onHold;
        newLineItem.FreightHold= FreightBill.onHold;
        console.log('newlineimte.freighthold =' + newLineItem.FreightHold)
        if (FreightBill.PONumber) newLineItem.PONumber = FreightBill.PONumber; else newLineItem.PONumber = '';
        if (FreightBill.JobNumber) newLineItem.JobNumber = FreightBill.JobNumber; else newLineItem.JobNumber = '';
        newLineItem.FBNO = FreightBill.FBNO;
        newLineItem.FBNumber=Number(FreightBill.FBNO);
        newLineItem.QueryDate= FreightBill.QueryDate;
        newLineItem.Driver = FreightBill.Driver;
        newLineItem.BrokerPercent = '';
        if(FreightBill.paidBrokerPercent>0 ){
            newLineItem.BrokerTotal = -1* Number(newLineItem.Total * (FreightBill.paidBrokerPercent / 100));
          //  newLineItem.TotalString ='$' + Number(newLineItem.Total+   newLineItem.BrokerTotal ).formatMoney(2);
       } else newLineItem.BrokerTotal=0;
        if(FreightBill.trailerPercent>0 ){
            newLineItem.TrailerTotal = -1* Number(newLineItem.Total * (FreightBill.trailerPercent / 100));
           // newLineItem.TotalString ='$' + Number(newLineItem.Total+   newLineItem.BrokerTotal ).formatMoney(2);
       } else newLineItem.TrailerTotal=0;
       if(FreightBill.onHold )FreightBill.standByOnHold=true; else FreightBill.standByOnHold=false;
        newLineItem.BrokerTotalString = '$' + Number(newLineItem.BrokerTotal).formatMoney(2);
        newLineItem.TrailerTotalString = '$' + Number(newLineItem.TrailerTotal).formatMoney(2);
        newLineItem.FuelPercent = '';
        newLineItem.Overtime=0;
        newLineItem.OvertimeQty=0;
        newLineItem.FuelTotal = '';
        newLineItem.tWeight = '';
        newLineItem.tHours = '';
        newLineItem.Amount = Number(newLineItem.Qty *  newLineItem.Rate);
        newLineItem.amountString='$' + Number(newLineItem.Amount).formatMoney(2);
        newLineItem.backTotal=newLineItem.Total+newLineItem.BrokerTotal+newLineItem.TrailerTotal;
        if(FreightBill.onHold)FreightBill.standByIsPaid=false; 
        else{
            FreightBill.standByAmountPaid=newLineItem.backTotal;
            FreightBill.amountPaid+= newLineItem.backTotal;
            FreightBill.standByIsPaid=true; 
        }  
        console.log('AFTER STAND BY ADDED AMOUNT PAID = '+ FreightBill.amountPaid);
        LineItems.push( newLineItem);
        console.log(' LineItems = ', LineItems);
    }
    const createEmptyLineItem = (payStatement) => {
        let newDate = new Date(inputValues.EndDate);
        let queryDate = formatDate(newDate,'/', 'YYYY/MM/DD');
        let jobDate = formatDate(newDate, '/','MM/DD/YYYY');

        let Expense = {
            ID: "",
            Name: expenseNames[0],
            parentExpense:'',
            description: '',
            qty: Number(0),
            rate: Number(0),  
            total: Number(0),
            addToFreights:false,
            FBNO:'',
            floatingBillExpense:false,
            floatingPayExpense:false,
            QueryDate:queryDate,
            jobDateValue:newDate,
            JobDate:jobDate,
            Truck:'',
            Company:payStatement.Company,
            FreightBill:'',
            dispatchID:'none',
            driverName:payStatement.ParentName,
            driverID:payStatement.expenseDriverID,
            reDriver: false,
            bCustomer: false,
            reCustomer: false,
            bDriver: false,
            bill:false,
            billed:false,
            paid:false,
            pay:true,
            onHold:false,
            BillTo:{Name:payStatement.ParentName, ID:payStatement.expenseDriverID},
            PayTo:{Name:payStatement.ParentName, ID:payStatement.expenseDriverID},
            isEditable: true,
            MaterialExpense: false
        };

        addDocument(Expense, 'Expenses');
    };

    return (
        <PayStatementLineItemContext.Provider value={{
            makeFreightBillLineItem,
            makeExpenseLineItem,
            makeStandByLineItem,
            makeOvertimeLineItem,
            makeTravelTimeLineItem,
            makeHourMinLineItem,
  
            createEmptyLineItem
        }}>
            {children}
        </PayStatementLineItemContext.Provider>
    );
};

export const useLineItem = () => useContext(PayStatementLineItemContext); 