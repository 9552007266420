import React, { useState, useEffect } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from 'primereact/panel';
import InputTextParent from '../InputComponents/InputTextParent'; 
import AutoSelectInputNumber from '../InputComponents/AutoSelectInputNumber'; 
import { Dropdown } from 'primereact/dropdown';
import { Checkbox} from 'primereact/checkbox';
import {Textarea} from '@mobiscroll/react';
import {useNavigate } from 'react-router-dom'
import { Button} from '@mobiscroll/react';

const Settings = (props) => {
    const navigate = useNavigate();
    const { companies, updateDocument} = UserAuth();
    const [inputValues, setInputValues]=useState(null);
    const [activeTab, setActiveTab]=useState(0);
    const header =  inputValues ? "Company Info - " + inputValues.CompanyName : '';
    const calcRunningTimeOptions = [{text:'First Load', value:'First Load'},{text:'Last Load', value:'Last Load'}]
    const timeZones = [{text:'PST', value:'PST'},{text:'EST', value:'EST'},{text:'MST', value:'MST'},{text:'CST', value:'CST'},{text:'HST', value:'HST'},{text:'AKST', value:'AKST'},];
    const client_id = 'Q0piOnp6Bj1zJwDClqXqQtPQZlERUB4P9OUcPT48MnfCNGsCaW';
    const scope = 'com.intuit.quickbooks.accounting';
    const redirect_uri='https://geared.tech/QBAuthenticate';
    const state = '1234';

    useEffect(() => {
        if(companies && !inputValues){
            if(companies.length>0){
                for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({...companies[i] }));
            }
        }
    },[companies])
    useEffect(() => {
        console.log('comapnies = ', companies);
        console.log('inputValues = ', inputValues)
        if(companies && !inputValues){
            if(companies.length>0){
                for(let i=0; i<companies.length; i++)if(companies[i].mainCompany)  setInputValues((prev) => ({...companies[i] }));
            }
        }
    },[])
    const changeTab =(e)=>{
        console.log('tab clicked = ' ,e)
        setInputValues({...companies[e.index]});
        setActiveTab(e.index)
 
    }
    const QBAuthenticate = () =>{
        for(let i =0; i<companies.length; i++){
            if(companies[i].settingQuickBooks && inputValues.ID!==companies[i].ID )   updateDocument({settingQuickBooks:false}, companies[i].ID, "Preferences");
            if(inputValues.ID===companies[i].ID && !inputValues.settingQuickBooks) updateDocument({settingQuickBooks:true}, companies[i].ID, "Preferences");
        }
 
            window.location.href = ' https://appcenter.intuit.com/connect/oauth2?client_id='+client_id+ '&response_type=code&scope='+ scope +'&redirect_uri='+ redirect_uri +'&state='+state ;
      
    }
    const handleFieldChange = (fieldName, value)=>{
        setInputValues((prev) => ({ ...prev, [fieldName]: value})); 
    }
    const tabHeader =(index)=>{
      
        index+=1;
        return "Company Info - "+index.toString();
    }
    const saveCompany = () =>{
        updateDocument(inputValues, inputValues.ID, "Preferences");
        navigate('/');
    }
    return (
        <div>
            <TabView  style={{margin:"0"}} activeIndex={activeTab} onTabChange={(e) => changeTab(e)}  >
                {companies.map(( inv, index ) => (   <TabPanel header={tabHeader(index)} key={index}  style={{marginTop:"0"}}  />))}
            </TabView>  
            {inputValues && (<div>
                <Panel className="mbsc-grid" header={header}>
                    <div  className="mbsc-row" style={{margin:"0"}}> 
                        <div className="mbsc-col-lg-6">
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Name:</span> 
                                <InputTextParent  value={inputValues.CompanyName}  onChange={(e) => handleFieldChange('CompanyName', e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Address:</span> 
                                <InputTextParent  value={inputValues.Address}  onChange={(e) =>  handleFieldChange('Address',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >City:</span> 
                                <InputTextParent  value={inputValues.City}  onChange={(e) => handleFieldChange('City',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >State:</span> 
                                <InputTextParent  value={inputValues.State}  onChange={(e) =>  handleFieldChange('State',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Zip Code:</span> 
                                <InputTextParent  value={inputValues.ZipCode}  onChange={(e) =>  handleFieldChange('ZipCode',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Phone:</span> 
                                <InputTextParent  value={inputValues.CompanyPhone}  onChange={(e) =>  handleFieldChange('CompanyPhone',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Fax:</span> 
                                <InputTextParent  value={inputValues.Fax}  onChange={(e) =>  handleFieldChange('Fax',e.target.value)} />
                            </div>
                            {inputValues.mainCompany ? (<div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Sales Tax:</span> 
                                    <AutoSelectInputNumber   value={inputValues.SalesTax}   onChange={(e)=> handleFieldChange('SalesTax',e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Broker Fee:</span> 
                                    <AutoSelectInputNumber   value={inputValues.DefaultBrokerFee}   onChange={(e)=> handleFieldChange('DefaultBrokerFee',e.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Administrative E-Mail:</span> 
                                    <InputTextParent  value={inputValues.SupportEmail}  onChange={(e) =>  handleFieldChange('SupportEmail',e.target.value)} />
                                </div>
                                <div className="p-inputgroup" >
                                    <span className="p-inputgroup-addon dispatch-inputgroup"  >Email Domain:</span> 
                                    <InputTextParent  value={inputValues.EmailDomain}  onChange={(e) =>  handleFieldChange('EmailDomain',e.target.value)} />
                                </div>
                                <div className="p-inputgroup ">
                                    <span className="p-inputgroup-addon dispatch-inputgroup">Calculate Round Trip:</span>
                                    <Dropdown value={inputValues.CalcRunningTime}  onChange={(e) =>  handleFieldChange('CalcRunningTime',e.value)}  options={calcRunningTimeOptions} optionLabel="text"
                                        placeholder="Calculate Round Trip" className="w-full md:w-14rem" />
                                </div>
                                <div  className="mbsc-row" style={{margin:"0"}}> 
                                    <div className="mbsc-col-lg-6" style={{padding:"0"}}>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon settings-checkbox-input">Sell Material: </span>
                                            <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('SellMaterial',e.checked)}  checked={inputValues.SellMaterial}  />
                                        </div>  
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon settings-checkbox-input">Use Preliminary Notices: </span>
                                            <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('Prelim',e.checked)}  checked={inputValues.Prelim}  />
                                        </div>  
                                    </div>
                                    <div className="mbsc-col-lg-6" style={{padding:"0"}}>
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon settings-checkbox-input">Show Material on Invoice: </span>
                                            <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('ShowInvoiceMaterial',e.checked)}  checked={inputValues.ShowInvoiceMaterial}  />
                                        </div>  
                                        {inputValues.Prelim && ( <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon settings-checkbox-input">Default Prelims: </span>
                                            <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('PrelimDefaulted',e.checked)}  checked={inputValues.PrelimDefaulted}  />
                                        </div>  )}
                                    </div>
                                </div>
                                <div  className="mbsc-row" style={{margin:"0"}}> 
                                    <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Upload Terms and Conditions: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('UploadAttach',e.checked)}  checked={inputValues.UploadAttach}  />
                                     </div>  
                                     <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Material Company: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('MaterialCompany',e.checked)}  checked={inputValues.MaterialCompany}  />
                                     </div> 
                                </div> 
                                <div  className="mbsc-row" style={{margin:"0"}}> 
                                    <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Show Hours on Statements: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('ShowDPSHours',e.checked)}  checked={inputValues.ShowDPSHours}  />
                                     </div>  
                                     <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Show PO # on Statements: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('ShowDPSPONumber',e.checked)}  checked={inputValues.ShowDPSPONumber}  />
                                     </div>  
                                  
                                
                                 </div>
                                <div  className="mbsc-row" style={{margin:"0"}}> 
                                    <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Show Job # on Statements: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('ShowDPSJobNumber',e.checked)}  checked={inputValues.ShowDPSJobNumber}  />
                                     </div>  
                                     <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Pay Job Hours: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('payByJobHours',e.checked)}  checked={inputValues.payByJobHours}  />
                                     </div>  
                                </div>
                                <div  className="mbsc-row" style={{margin:"0"}}> 
                                    <div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Multiple Load/Dump Sites: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('ExtraSites',e.checked)}  checked={inputValues.ExtraSites}  />
                                     </div>  
                                     <div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Download Invoice for QB: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('downloadIIF',e.checked)}  checked={inputValues.downloadIIF}  />
                                     </div>  
                                    
                                </div>
                            </div>):(   <div className="p-inputgroup " style={{padding:"0"}}>
                                        <span className="p-inputgroup-addon settings-checkbox-input">Material Company: </span>
                                        <Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('MaterialCompany',e.checked)}  checked={inputValues.MaterialCompany}  />
                                     </div>   )}
                        </div>
                        <div className="mbsc-col-lg-6">
                           
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Yard Address:</span> 
                                <InputTextParent  value={inputValues.YardAddress}  onChange={(e) =>  handleFieldChange('YardAddress',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Yard City:</span> 
                                <InputTextParent  value={inputValues.YardCity}  onChange={(e) => handleFieldChange('YardCity',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Yard State:</span> 
                                <InputTextParent  value={inputValues.YardState}  onChange={(e) =>  handleFieldChange('YardState',e.target.value)} />
                            </div>
                            <div className="p-inputgroup" >
                                <span className="p-inputgroup-addon dispatch-inputgroup"  >Yard Zip Code:</span> 
                                <InputTextParent  value={inputValues.YardZipCode}  onChange={(e) =>  handleFieldChange('YardZipCode',e.target.value)} />
                            </div>
                          
                            {inputValues.mainCompany && (<div>
								<div className="p-inputgroup ">
                                    <span className="p-inputgroup-addon dispatch-inputgroup">Time Zone:</span>
                                    <Dropdown value={inputValues.TimeZone}  onChange={(e) =>  handleFieldChange('TimeZone',e.value)}  options={timeZones} optionLabel="text"
                                        placeholder="PST" className="w-full md:w-14rem" />
                                </div>

								
								<Panel  header='Billing Info' style={{marginTop:".5em"}}>
									<div  className="mbsc-row" style={{margin:"0"}}> 
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon settings-checkbox-input">Auto FB Numbers: </span>
											<Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('UseAutoFBNum',e.checked)}  checked={inputValues.UseAutoFBNum}  />
										</div>  
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon settings-checkbox-input">Auto Job Numbers: </span>
											<Checkbox style={{ width: '100%' }} onChange={(e) =>  handleFieldChange('UseAutoJobNum',e.checked)}  checked={inputValues.UseAutoJobNum}  />
										</div>                                  
									</div>   
								
									<div  className="mbsc-row" style={{margin:"0"}}> 
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >FB Number:</span> 
											<AutoSelectInputNumber   value={inputValues.CurrentFBNumber}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('CurrentFBNumber',e.value)} />
										</div>
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Job Number:</span> 
											<AutoSelectInputNumber   value={inputValues.CurrentJobNumber}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('CurrentJobNumber',e.value)} />
										</div>
									</div>
									<div  className="mbsc-row" style={{margin:"0"}}> 
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Invoice Number:</span> 
											<AutoSelectInputNumber   value={inputValues.CurrentInvoiceNumber}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('CurrentInvoiceNumber',e.value)} />
										</div>
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Pay Statement Number:</span> 
											<AutoSelectInputNumber   value={inputValues.CurrentDPSNumber}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('CurrentDPSNumber',e.value)} />
										</div>
									</div>
									<div className="p-inputgroup text-area-inputgroup" >
										<span className="p-inputgroup-addon"   style={{width:"12em"}}>Terms on Invoice:</span> 
										<Textarea  style={{border:".5px solid #d1d5db", borderBottomRightRadius:"6px", borderTopRightRadius:"6px"}}  value={inputValues.TermsAndCond} onChange={(e) =>  handleFieldChange('TermsAndCond',e.target.value)}  />
									</div>
								</Panel>
								<Panel  header='Prevailing Wage Info' style={{marginTop:".5em"}}>
									<div  className="mbsc-row" style={{margin:"0"}}> 
										<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Motor Carrier Permit:</span> 
											<AutoSelectInputNumber   value={inputValues.MotorCarrierPermit}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('MotorCarrierPermit',e.value)} />
										</div>
										<div className="p-inputgroup mbsc-col-lg-6" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Federal Tax Number:</span> 
											<AutoSelectInputNumber   value={inputValues.FEIN}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('FEIN',e.value)} />
										</div>
									
									</div>
									<div  className="mbsc-row" style={{margin:"0"}}> 
									<div className="p-inputgroup mbsc-col-lg" style={{padding:"0"}}>
											<span className="p-inputgroup-addon dispatch-small-inputgroup"  >Public Works Contractor Number (PWCR):</span> 
											<AutoSelectInputNumber   value={inputValues.PWCR}  dontUseGrouping={true} onChange={(e)=> handleFieldChange('PWCR',e.value)} />
										</div>
										
									</div>
								</Panel>
                            </div>)}
                            <div className="mbsc-row">
                                
                                <Button  color="primary"  className="mbsc-col-lg-4" onClick={(event) =>saveCompany()} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Save Company</Button> 
                                <Button  color="primary"  className="mbsc-col-lg-4" onClick={(event) =>QBAuthenticate()} style={{margin:"0", marginTop:".5em", marginLeft:".5em", paddingLeft:"1em !important", paddingBottom: ".2em", paddingTop: ".2em", height:"100%"}}>Sync Quickboooks</Button> 

                            </div>
                        </div>
                    </div>
                </Panel>
            </div>)}
        </div>
       
    )
};

export default Settings;
