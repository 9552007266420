
import React, {useState,useContext,useRef, createContext} from 'react'
import { db } from '../firebase';
import { doc,  query,  collection,onSnapshot, where } from 'firebase/firestore';
import { UserAuth } from './AuthContext';


const JobsProfitContext = createContext();

export const JobsProfitContextProvider = ({ children }) => {
    const { gearedUser, formatDate} = UserAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [jobDispatches, setJobDispatches]=useState([]);
    const [freightBills, setFreightBills]= useState(null);
    const [profitHomeFreights, setProfitHomeFreights] = useState(null);
    const [dispatches, setDispatches]= useState(null);
    const [expenses, setExpenses] = useState(null);
    const freightBillListenerRef = useRef(null);
    const expenseListenerRef = useRef(null);
    const freightBillsRef = useRef(null);
    const expensesRef = useRef(null);



    const jobsRef = useRef(null);
    const weekJobListenerRef = useRef(null);


    const dispatchesRef = useRef(null);
    const weekDispatchListenerRef = useRef(null);
    const dispatchRef = useRef(null);
    const [jobs, setJobs] = useState([]);
    const jobRef = useRef(null);
    const jobListenerRef = useRef(null);







    const queryFreightBills = (jobID)=>{
        if (freightBillListenerRef.current) freightBillListenerRef.current();
        freightBillsRef.current=[];
  
        const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
        const q = query(collection(db, queryName), where("JobID", "==",jobID));
        console.log('runnign query fbs for jobid= '+ jobID)
        freightBillListenerRef.current= onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                const tempFreight = change.doc.data();
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                console.log(' source = '+ source);
                console.log('found at emp freight =  ', tempFreight);
             
                if (change.type === "added") {
                    tempFreight.ID = change.doc.id;
                    freightBillsRef.current.push(tempFreight);
                }
                if (change.type === "modified") {
                    const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                    freightBillsRef.current[jobIndex] = tempFreight;
                }
                if (change.type === "removed") {
                    const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                    freightBillsRef.current.splice(jobIndex,1);
                }

                
            });

            setFreightBills([...freightBillsRef.current])
    
        
        });
    }
    const queryDispatchFreightBills = async(dispatchID)=>{
        return new Promise((resolve, reject) => {
            if (freightBillListenerRef.current) freightBillListenerRef.current();
            freightBillsRef.current=[];
            console.log('WTF IS THE PROBLEM HERE???')
            const freightQueryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
            const freightQuery = query(collection(db, freightQueryName), where("dispatchID", "==",dispatchID));
            console.log('runnign query fbs for ispatchID= '+ dispatchID)
            freightBillListenerRef.current= onSnapshot(freightQuery, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const tempFreight = change.doc.data();
              
                    console.log('found at emp freight =  ', tempFreight);
                
                    if (change.type === "added") {
                        tempFreight.ID = change.doc.id;
                        freightBillsRef.current.push(tempFreight);
                    }
                    if (change.type === "modified") {
                        const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                        freightBillsRef.current[jobIndex] = tempFreight;
                    }
                    if (change.type === "removed") {
                        const jobIndex = freightBillsRef.current.findIndex(d => d.ID === tempFreight.ID);
                        freightBillsRef.current.splice(jobIndex,1);
                    }

                    
                });
                console.log('freightbillsREf.current  in the context= ', freightBillsRef.current)
                setFreightBills([...freightBillsRef.current])
                resolve();
            });
         
        });
    }

    const queryDispatchExpenses = async(dispatchID)=>{
        return new Promise((resolve, reject) => {
            if (expenseListenerRef.current) expenseListenerRef.current();
            expensesRef.current=[];
    
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Expenses`;
            const q = query(collection(db, queryName), where("dispatchID", "==",dispatchID));
            console.log('runnign query EXPENSES for ispatchID= '+ dispatchID)
            expenseListenerRef.current= onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    const tempExpense = change.doc.data();
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    console.log(' source = '+ source);
                    console.log('found at emp Expense =  ', tempExpense);
                
                    if (change.type === "added") {
                        tempExpense.ID = change.doc.id;
                        expensesRef.current.push(tempExpense);
                    }
                    if (change.type === "modified") {
                        const expenseIndex = expensesRef.current.findIndex(d => d.ID === tempExpense.ID);
                        expensesRef.current[expenseIndex] = tempExpense;
                    }
                    if (change.type === "removed") {
                        const expenseIndex = expensesRef.current.findIndex(d => d.ID === tempExpense.ID);
                        expensesRef.current.splice(expenseIndex,1);
                    }

                    
                });

                setExpenses([...expensesRef.current])
                resolve();
            });
         
        });
    }


    const fetchJob = async (id) => {
     
        if(jobListenerRef.current) jobListenerRef.current();
         
        console.log('WE ARE RUNNIGN TEH FETCH  PREVAILING WAGE')
        jobRef.current = [];
        console.log(' jobsProfitWeeksRef.current B4= '+  jobRef.current.length)
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Jobs`, id);
            jobListenerRef.current = onSnapshot(docRef, async (docSnap) => {
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('source =' +source)
              
                    if ( docSnap.exists() &&source === "Server") {
                        let tempJob = docSnap.data();
                        tempJob.ID=docSnap.id;
                  
                    
                    }
                
            });
            
      
    };
    const fetchDispatch = async (id) => {
        return new Promise((resolve, reject) => {
            dispatchRef.current = {};
            if (weekDispatchListenerRef.current)   weekDispatchListenerRef.current();
          
                const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/Dispatches`, id);
                weekDispatchListenerRef.current = onSnapshot(docRef, async (docSnap) => {
                    const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                    console.log('source =' +source)
                
                    if ( docSnap.exists() &&source === "Server") {
                        let tempDispatch = docSnap.data();
                        tempDispatch.ID=docSnap.id;
                        dispatchRef.current =tempDispatch;
                        
                    }
                    resolve();
                });
            });
            
      
    };
    const queryJobDispatches = async(startDate, endDate, jobID)=>{
        setIsLoading(true);
            dispatchesRef.current = [];
            if (weekDispatchListenerRef.current)   weekDispatchListenerRef.current();
            startDate = formatDate(startDate,'/', 'YYYY/MM/DD');
            endDate  = formatDate(endDate ,'/', 'YYYY/MM/DD');
            console.log('I AM OPENING A LISTENER TO THISE Dispatches!! ' + startDate )
            console.log('I AM OPENING A LISTENER TO THISE  Dispatches!! ' + endDate )
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            weekDispatchListenerRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    let tempDispatch = change.doc.data(); 
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    tempDispatch.ID = change.doc.id;
                    if(jobID===tempDispatch.JobID){
                        tempDispatch.jobDateValue = new Date(tempDispatch.JobDate)
                        if (change.type === "added")  dispatchesRef.current.push(tempDispatch);
                        
                        if (change.type === "modified") { 
                            const dispatchIndex = dispatchesRef.current.findIndex(f => f.ID === tempDispatch.ID);
                            dispatchesRef.current[dispatchIndex] = tempDispatch;
                        }
                        if (change.type === "removed") {
                            const dispatchIndex = dispatchesRef.current.findIndex(f => f.ID === tempDispatch.ID);
                            dispatchesRef.current.splice(dispatchIndex, 1);
                        }
                    }
                });
                setDispatches([...dispatchesRef.current]);
                if(isLoading)    setIsLoading(false);
                setJobDispatches([...dispatchesRef.current]);
         
        
        });
        
    }
    const queryWeekDispatches = (startDate, endDate)=>{
            setIsLoading(true);
            dispatchesRef.current = [];
            if (weekDispatchListenerRef.current)   weekDispatchListenerRef.current();
            startDate = formatDate(startDate,'/', 'YYYY/MM/DD');
            endDate  = formatDate(endDate ,'/', 'YYYY/MM/DD');
            console.log('Setting up dispatch listener for dates:', startDate, endDate)
            const queryName = `Organizations/${gearedUser.selectedOrgName}/Dispatches`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            weekDispatchListenerRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    let tempFB = change.doc.data(); 
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    tempFB.ID = change.doc.id;
                    if (change.type === "added")  dispatchesRef.current.push(tempFB);
                    
                    if (change.type === "modified") { 
                        const dispatchIndex = dispatchesRef.current.findIndex(f => f.ID === tempFB.ID);
                        dispatchesRef.current[dispatchIndex] = tempFB;
                    }
                    if (change.type === "removed") {
                        const dispatchIndex = dispatchesRef.current.findIndex(f => f.ID === tempFB.ID);
                        dispatchesRef.current.splice(dispatchIndex, 1);
                    }
                });

               setDispatches([...dispatchesRef.current]);
                if(isLoading)    setIsLoading(false);
                console.log('dispatchesRef.current= ',dispatchesRef.current)
           
        });
        
    }
 
    const queryWeekFreightBills = async(startDate, endDate)=>{
            setIsLoading(true);
            freightBillsRef.current = [];
            if (freightBillListenerRef.current)  freightBillListenerRef.current();
            startDate = formatDate(startDate,'/', 'YYYY/MM/DD');
            endDate  = formatDate(endDate ,'/', 'YYYY/MM/DD');
             
            console.log('Setting up freight bills listener for dates:', startDate, endDate);
        
            const queryName = `Organizations/${gearedUser.selectedOrgName}/FreightBills`;
            const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
            freightBillListenerRef.current = onSnapshot(q, (querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    let tempFB = change.doc.data(); 
                    let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                    tempFB.ID = change.doc.id;
                    if (change.type === "added")  freightBillsRef.current.push(tempFB);
                    
                    if (change.type === "modified") { 
                        const freightIndex = freightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
                        freightBillsRef.current[freightIndex] = tempFB;
                    }
                    if (change.type === "removed") {
                        const freightIndex = freightBillsRef.current.findIndex(f => f.ID === tempFB.ID);
                        freightBillsRef.current.splice(freightIndex, 1);
                    }
                });
                setProfitHomeFreights([...freightBillsRef.current]);
                setIsLoading(false);
            // setHomeFreightBills( [...freightBillsRef.current]);
            });
    
    }

    const queryWeekJobs = async(startDate, endDate)=>{
        
        jobsRef.current = [];
        if (weekJobListenerRef.current)  weekJobListenerRef.current();
    

        startDate = formatDate(startDate,'/', 'YYYY/MM/DD');
        endDate  = formatDate(endDate ,'/', 'YYYY/MM/DD');
        console.log('I AM OPENING A LISTENER TO THISE Jobs!! ' + startDate )
        console.log('I AM OPENING A LISTENER TO THISE Jobs!! ' + endDate )
        const queryName = `Organizations/${gearedUser.selectedOrgName}/Jobs`;
        const q = query(collection(db, queryName), where("QueryDate", ">=", startDate), where("QueryDate", "<=", endDate));
        weekJobListenerRef.current = onSnapshot(q, (querySnapshot) => {
            querySnapshot.docChanges().forEach((change) => {
                let tempJob = change.doc.data(); 
                let source = change.doc.metadata.hasPendingWrites ? "Local" : "Server";
                tempJob.ID = change.doc.id;
                tempJob.jobDateValue = new Date(tempJob.JobDate);
                if (change.type === "added")  jobsRef.current.push(tempJob);
                
                if (change.type === "modified") { 
                    const jobIndex = jobsRef.current.findIndex(f => f.ID === tempJob.ID);
                    jobsRef.current[jobIndex] = tempJob;
                }
                if (change.type === "removed") {
                    const jobIndex = jobsRef.current.findIndex(f => f.ID === tempJob.ID);
                    jobsRef.current.splice(jobIndex, 1);
                }
            });
       
            setJobs([... jobsRef.current]);
           // setHomeFreightBills( [...freightBillsRef.current]);
        });
    
    }

 
    return (
        <JobsProfitContext.Provider value={{
           freightBills, queryFreightBills,fetchJob,queryWeekFreightBills, queryWeekDispatches, queryWeekJobs, jobs, setJobs,  dispatches, profitHomeFreights, jobsRef,
           jobDispatches, setJobDispatches,queryJobDispatches, fetchDispatch, queryDispatchFreightBills, dispatchRef, expensesRef, queryDispatchExpenses, expenses, isLoading, setIsLoading,
        }}>
            {children}
        </JobsProfitContext.Provider>
    );
}
export const useJobsProfit= () => {
    return useContext(JobsProfitContext);
};