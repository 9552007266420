import React, {useState, useEffect} from 'react'
import {  useNavigate } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext';

import { InputText } from 'primereact/inputtext';

import { Password } from 'primereact/password';

import { Card } from 'primereact/card';
import  {  Button } from '@mobiscroll/react';

import mobiscroll from '@mobiscroll/react4';

const Signin = () => {
  

  const {signIn, user, formatDate, resetPassword} = UserAuth();


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [optIn, setOptIn] = useState(true);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const navigate = useNavigate()
  var confirmationPromise;

  useEffect(()=>{
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const encodedDate = encodeURIComponent(formatDate(tomorrow,'/','YYYY/MM/DD'));

  
    if(user) return    navigate('/dispatch/home/'+ encodedDate)

},[user])





function validateEmail(email) {
    var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return re.test(email);
}

function onSignInSubmit() {
    
    console.log('email = ',email);
    console.log('password = ',password);
    if(validateEmail(email)){
        confirmationPromise = signIn(email,password);
        confirmationPromise.then((confirmationRes) => {
       
        }).catch((error) => {
            console.log('error = ', error);
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === 'auth/wrong-password') alert('Wrong password for this E-mail.');
            if (errorCode === 'auth/user-not-found') alert('No user with this E-mail exists.');
            if (errorCode === 'auth/too-many-requests') alert('Too many failed attempts, please wait and try again.');
         
            return 0;
        });
    }else alert("Please enter a valid E-mail");

}

const sendPasswordEmail = ()=>{
    if(validateEmail(email)){
        let emailPromise = resetPassword(email);
        emailPromise.then(() => {
            // Password reset email sent!
            // ..
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
          });
    }else alert("Please enter a valid E-mail");
}

const changeEmail = (e)=>{
    let email = e.target.value;
    setEmail(email);
}

const changePassword = (e)=>{
  let password= e.target.value;
  console.log('e = ', e)
  setPassword(password);
}
  const handleSubmit = async(e)=>{
    e.preventDefault();

      try{

         await onSignInSubmit();
          
      }catch(e){
        
          console.log(e);
      }
 
}
const cancelSignIn = () =>{
  setShowConfirmation(false)
  console.log('ok wtf no one clicked this why is it running?!?!?!')
/*
     <Checkbox  onChange={e => setOptIn(e.checked)} checked={optIn} ></Checkbox>
            
    
            <label  className="ml-2"  style={{paddingLeft:".5em"}}>   
              I agree to receive notification messaging from Alianza Technologies at the phone number provided above. 
              I understand that I will receieve messages when notified, data rates may apply, reply STOP  to opt out
             </label>
             */
}


  return ( 
  
      <div className="mbsc-grid mbsc-justify-content-center "  style={{padding:"0"}}> 
      <div className="mbsc-col-sm-12 mbsc-col-lg-4  mbsc-offset-lg-4" style={{padding:"0"}}>
        <Card style={{ paddingLeft:'1em',paddingRight:'1em'}} >  
          <mobiscroll.CardHeader >
                <mobiscroll.CardTitle style={{ textAlign: 'center',fontSize: '30px'}}>Alianza Technologies</mobiscroll.CardTitle>
                {showConfirmation ? (     <mobiscroll.CardSubtitle style={{ textAlign: 'center',fontSize: '15px'}}>   Sign in for Alianza Technologies </mobiscroll.CardSubtitle>):(<div></div>)}
             
          </mobiscroll.CardHeader>
         
        
           
              <div className="p-inputgroup"  >
                <span className="p-inputgroup-addon" style={{flexBasis:'30%'}} >Email:</span> 
                < InputText onChange={(e) => changeEmail(e)} />
              </div>
              <div className="p-inputgroup"  >
                <span className="p-inputgroup-addon" style={{flexBasis:'30%'}} >Password:</span> 
                < Password  onChange={(e) => changePassword(e)} />
              </div>
              <Button id="send-text-button" color="primary" onClick={handleSubmit} style={{ paddingBottom: ".5em", paddingTop: ".5em", width:"10em", marginRight: "0.5em"}}>Sign In</Button>
              <Button id="reset-button" color="primary" onClick={sendPasswordEmail} style={{ paddingBottom: ".5em", paddingTop: ".5em", width:"10em"}}>Reset Password</Button>
            
            
       
        
        </Card  >
        <div style={{ marginTop: "1.5em", textAlign: "center" }}>
          <a 
            href="https://geared.alianzatechnologies.com" 
            style={{ color: "#4F46E5", textDecoration: "underline" }}
          >
            Looking for driver login page?
          </a>
        </div>
        </div>
    </div>

)



}

export default Signin