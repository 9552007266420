import React,{useEffect, useRef, useState} from 'react';

import { usePayStatement } from './context/PayStatementContext';
import PayStatementComponent from './PayStatementComponent';
import { db } from '../../firebase';
import { doc,  onSnapshot} from 'firebase/firestore';
import { UserAuth } from '../../context/AuthContext';
   
import {useParams } from 'react-router-dom';
const PayStatementEdit = (props) => {
    const { id } = useParams();  
    const { gearedUser } = UserAuth();

    const {payStatement, setPayStatement,  payStatementsRef} = usePayStatement();
    const [inputValues, setInputValues] = useState({});
    let payStatementListener = useRef(null);

    useEffect(()=>{
   
        fetchPayStatement(id);
  
    },[])
    const fetchPayStatement= async (id) => {
        console.log('runnign fetchPayStatement ya bois!!')
        if(payStatementListener.current)payStatementListener.current();
        return new Promise((resolve, reject) => {
            console.log(' runnign fetch PayStatement = ', payStatement)
            console.log('id = ', id)
            if (id === payStatement?.ID)   return resolve(payStatement);
            const docRef = doc(db, `Organizations/${gearedUser.selectedOrgName}/PayStatements`, id);
            payStatementListener.current= onSnapshot(docRef, async (docSnap) => {
         
                const source = docSnap.metadata.hasPendingWrites ? "Local" : "Server";
                console.log('source =' +source)
                if (docSnap.exists()) {
             
                    let tempPayStatement= docSnap.data();
                    tempPayStatement.ID=docSnap.id;
                    payStatementsRef.current=[{ ...tempPayStatement}];
                    setPayStatement({ ...tempPayStatement});
                    setInputValues({ ...tempPayStatement});
                    console.log("set inovie ",{ ...tempPayStatement });
                    return resolve(tempPayStatement);
                }
            });
        });
    };

    
return(
    <React.Fragment>
    {payStatement?.ParentID && (<PayStatementComponent   inputValues={inputValues} setInputValues={setInputValues}/>
    )}
    </React.Fragment>
);
};

export default PayStatementEdit;